import React, { useState, useEffect, useContext, Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((styles) => ({
  container: {
    [styles.breakpoints.down("xs")]: {},
    [styles.breakpoints.up("sm")]: {},
  },
}));

export default function SectionSpaceTop(props) {
  const classes = useStyles();
  useEffect(() => {}, []);
  return (
    <div id="div1">
      <div className={classes.container}></div>
    </div>
  );
}
