import React, {
  useContext,
  createContext,
  useState,
  useRef,
  useEffect,
  Fragment,
} from "react";
import { userService } from "../../../_services/user.service";
import { Link, useHistory } from "react-router-dom";

import CartHeader from "views/Cart/CartHeader.js";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SectionBreadcrumb from "views/Home/Sections/SectionBreadcrumb.js";
import { useAuth } from "hooks/useAuth";
// @material-ui/
import Typography from "@material-ui/core/Typography";
import { Box, Button, Divider, Tooltip } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import Avatar from '@material-ui/core/Avatar';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import TextField from "@material-ui/core/TextField";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";


// core components
import useCheckMobileScreen from "components/Mobile/Mobile.js";

import Container from "@material-ui/core/Container";
import { IconButton } from "@mui/material";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getPriceSapFormat } from "helpers/formatPrice";

const useStyles = makeStyles((theme) => ({
  hide: {
    display: "none !important",
  },
  //alerts
  alerts: {
    color: "#FFFFFF",
    backgroundColor: "#40DF00",
    fontFamily: "'Centrale Sans Light'",
    fontSize: 22,
    "& .MuiAlert-icon": {
      color: "#FFFFFF",
    },
    "&.MuiAlert-standardError": {
      backgroundColor: "#DF2B2B",
    },
  },
  imageProd:{
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    justifyContent: "center",
  },
  root: {
    backgroundColor: "#FFFFFF",
    margin: "auto",
    width: "95%",
    paddingBottom: 30,
  },
  spaceDiv: {
    marginTop: 15,
    marginBottom: 52,
  },
  linkBack: {
    fontFamily: "'Centrale Sans Medium'",
    color: "#0B3196",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "19px",
    textAlign: "center",
    paddingLeft: "30px",
    position: "relative",
  },
  linkBackArrow: {
    position: "absolute",
    left: "5px",
    bottom: "-2px",
  },
  title: {
    fontFamily: "'Centrale Sans Bold'",
    textTransform: "uppercase",
    fontSize: 26,
    lineHeight: "32px",
    textAlign: "justify",
    color: "#0C2D9C",
    paddingTop: "39.8px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  titleDivider: {
    border: "1px solid #0B3196",
    width: "95%",
    margin: "auto",
  },
  gridContent: {
    paddingBottom: 30,
  },
  buttonUpdate: {
    border: "1px solid",
    color: "#0C2D9C",
    backgroundColor: "transparent",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  buttonUpdateSecond: {
    border: "1px solid",
    backgroundColor: "#0C2D9C",
    color: "#ffffff",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  inputs: {
    width: "100%",
    border: "1px solid #EAEAEA",
    borderRadius: "2px",
    backgroundColor: "#FFFFFF",
  },
  // Search
  searchDiv: {
    width: "100%",
    backgroundColor: "#F5F5F5",
    border: "1px solid #EAEAEA",
    borderRadius: "2px",
    marginBottom: "39px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "6px",
  },
  margin: {
    paddingLeft: "6px",
    paddingRight: "13px",
  },
  inlineDiv: {
    display: "inline-block",
    verticalAlign: "middle",
    [theme.breakpoints.down("sm")]:{
      marginTop: "15px",
    }
  },
  textField: {
    display: "inline-block",
    paddingLeft: "6px",
    paddingRight: "13px",
    [theme.breakpoints.down("sm")]:{
      marginTop: "15px",
    }
  },
  textField2: {
   
    paddingLeft: "6px",
    paddingRight: "13px",
    [theme.breakpoints.down("sm")]:{
      marginTop: "15px",
    }
  },
  btnSearch: {
    height: "44.8px",
    width: "120px",
    borderRadius: "2px",
    backgroundColor: "#40DF00",
    paddingTop: "12px",
    paddingLeft: "35px",
    paddingBottom: "9px",
    paddingRight: "34px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  btnSearchLbl: {
    color: "#FFFFFF",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "19px",
  },
  // Table
  tableHeader: {
    backgroundColor: "#0C2D9C",
    borderRadius: "2px",
    height: "51px",
  },
  tableHeaderTxt: {
    color: "#ffffff",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "18px",
    lineHeight: "23px",
    fontWeght: "500",
  },
  filterSelect: {
    color: "#0C2D9C",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "19px",
    marginLeft: "6px",
  },
  rowDataProd:{
    color: "#000000",
    fontFamily: "'Centrale Sans Regular'",
    fontSize: "14px",
    letterSpacing: "0",
    lineHeight: "23px",
    width: '11%',
  },

  dataCenter:{
    textAlign:"center"
  },
  rowData: {
    color: "#000000",
    fontFamily: "'Centrale Sans Regular'",
    fontSize: "18px",
    letterSpacing: "0",
    lineHeight: "23px",
  },
  iconAction: {
    width: "36px",
    marginRight: "5px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  iconActionImg:{
    width: "36px",
    marginRight: "5px",
    marginBottom: "18px !important",
    "&:hover": {
      cursor: "pointer",
    },
  },
  iconDeleteAction: {
    width: "17px",
    height: "22px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
  },
  modalHeader: {
    width: "540px",
    backgroundColor: "#EF0002",
    textAlign: "center",
    paddingTop: "24.8px",
    paddingBottom: "16px",
  },
  modalHeaderTxt: {
    color: "#FFFFFF",
    fontFamily: "'Centrale Sans Bold'",
    fontSize: "26px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "32px",
  },
  modalBody: {
    marginTop: "53.2px",
    marginBottom: "78px",
  },
  modalBodyTxt: {
    color: "#DF2B2B",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "24px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "29px",
    textAlign: "center",
  },
  modalBtns: {
    marginLeft: "65px",
    marginBottom: "22px",
  },
  modalBtn: {
    boxSizing: "border-box",
    height: "28px",
    width: "204px",
    border: "1px solid #0C2D9C",
    borderRadius: "2px",
    textAlign: "center",
    color: "#0C2D9C",
    marginRight: "7px",
    display: "inline-block",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#0C2D9C",
      color: "#FFFFFF",
    },
  },
  modalBtnTxt: {
    fontFamily: "'Centrale Sans Regular'",
    fontSize: "14px",
    letterSpacing: "0",
    lineHeight: "18px",
    marginTop: "4px",
  },
  /// Detalles
  titleDiv: {
    display: "block",
  },
  mainDataDiv: {
    backgroundColor: "#F2F2F2",
    paddingTop: "27.6px",
    paddingLeft: "18px",
    paddingRight: "6px",
    paddingBottom: "22px",
  },
  cotDetalleTitle: {
    color: "#0C2D9C",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "22px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "27px",
    display: "inline-block",
  },
  iconActionEdit: {
    height: "24px",
    width: "28px",
    float: "right",
    display: "inline-block",
    "&:hover": {
      cursor: "pointer",
    },
  },
  cotDetailsDataDiv: {
    paddingLeft: "2px",
    marginTop: "17px",
    width: "100%",
  },
  cotDetailsDataRow: {
    display: "block",
    marginBottom: "12px",
  },
  cotDetailsDataSmall: {
    display: "inline-block",
    width: "239px",
    verticalAlign: "top",
  },
  cotDetalleDataTitle: {
    color: "#444444",
    fontFamily: "'Centrale Sans Bold'",
    fontSize: "18px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "23px",
    display: "inline-block",
  },
  cotDetalleDataTxt: {
    color: "#000000",
    fontFamily: "'Centrale Sans Regular'",
    fontSize: "16px",
    letterSpacing: "0",
    lineHeight: "19px",
    display: "inline-block",
    marginLeft: "110px",
  },
  cotDetallePersonalTitle: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "16px",
    display: "block",
  },
  cotDetallePersonalData: {
    color: "#000000",
    fontFamily: "'Centrale Sans Regular'",
    fontSize: "16px",
    letterSpacing: "0",
    lineHeight: "20px",
    display: "block",
  },
  divTableProducts: {
    marginTop: "44px",
    marginBottom: "36px",
    backgroundColor: "#F5F5F5",
  },
  divSubtotals: {
    width: "445px",
    border: "1px solid #EAEAEA",
    backgroundColor: "#F5F5F5",
    paddingTop: "12.8px",
    paddingLeft: "18px",
    paddingRight: "24px",
    paddingBottom: "13px",
    float: "right",
    marginBottom: "41px",
    [theme.breakpoints.down("sm")]:{
      width: "325px"
    } 
  },
  divSumatorias: {
    display: "block",
    width: "100%",
    minHeight: "275px",
  },
  divSubtotalesRow: {
    display: "block",
    marginBottom: "17px",
  },
  divLineTotal: {
    height: "1px",
    width: "100%",
    border: "1px solid #EAEAEA",
    marginTop: "-20px",
    marginBottom: "12px",
  },
  subtotalTitle: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "16px",
    display: "inline-block",
    verticalAlign: "top",
  },
  subtotalData: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "16px",
    display: "inline-block",
    textAlign: "right",
    float: "right",
    verticalAlign: "top",
  },
  totalTitle: {
    color: "#000000",
    fontFamily: "'Centrale Sans Bold'",
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "16px",
    verticalAlign: "top",
    display: "inline-block",
  },
  totalData: {
    color: "#000000",
    fontFamily: "'Centrale Sans Bold'",
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "16px",
    verticalAlign: "top",
    display: "inline-block",
    textAlign: "right",
    float: "right",
  },
  btnFinalizarCompra: {
    borderRadius: "3px",
    backgroundColor: "#0C2D9C",
    color: "#FFFFFF",
    float: "right",
    paddingTop: "11.8px",
    paddingBottom: "12px",
    paddingLeft: "60px",
    paddingRight: "54px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  btnFinalizarCompraMob: {
    borderRadius: "3px",
    backgroundColor: "#0C2D9C",
    color: "#FFFFFF",
    //float: "right",
    paddingTop: "11.8px",
    paddingBottom: "12px",
    paddingLeft: "60px",
    paddingRight: "54px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  divBtnFinalizar: {
    float: "right",
    marginBottom: "47px",
    display: "inline-block",
  },
  btnsDiv: {
    display: "block",
    width: "100%",
    marginBottom: "47px",
  },
  btnFinalizarCompraTxt: {
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "19px",
    textAlign: "center",
  },
  divBtnRegresar: {
    display: "inline-block",

  },
  btnRegresar: {
    borderRadius: "3px",
    backgroundColor: "#FFFFFF",
    color: "#0C2D9C",
    border: "1px solid #0C2D9C",
    paddingTop: "11.8px",
    paddingBottom: "12px",
    paddingLeft: "60px",
    paddingRight: "54px",
    width: "250px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#0C2D9C",
      color: "#FFFFFF",
    },
    [theme.breakpoints.down("sm")]: {
      width: "180px",
    },
  },

  btnRegresarMob: {
    borderRadius: "3px",
    backgroundColor: "#FFFFFF",
    color: "#0C2D9C",
    border: "1px solid #0C2D9C",
    paddingTop: "11.8px",
    paddingBottom: "12px",
    paddingLeft: "60px",
    paddingRight: "54px",
    marginTop:"15px",
    //width: "250px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#0C2D9C",
      color: "#FFFFFF",
    },
  },

  formData: {
    backgroundColor: "#F5F5F5",
    borderRadius: 2,
    margin: "10px 0",
    border: "1px solid #EAEAEA",
    "& *": {
      margin: "3px 8px 2px 3px",
      padding: 0,
      fontFamily: "'Centrale Sans Regular'",
      border: "none",
    },
  },

  formDataSubTitleDes: {
    color: "#0C2D9C",
    textTransform: "uppercase",
  },
  inputs: {
    width: "100%",
    border: "1px solid #EAEAEA",
    borderRadius: "2px",
    backgroundColor: "#FFFFFF",
  },
  dataContainer:{
    marginTop:"15px"
  },
  btnRegistro:{
    marginTop: "15px",
    color: "#FFFFFF",
    with: "100%",
    backgroundColor: "#0C2D9C",
    "& :hover":{
      backgroundColor: "#0C2D9C",
      color: "#FFFFFF",
    },
    "& .MuiButton-root":{
      backgroundColor: "#0C2D9C !important",
      color: "#FFFFFF !important",
      "& :hover":{
        backgroundColor: "#0C2D9C !important",
        color: "#FFFFFF !important",
      }
    } 
  }
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#ffffff",
    border: "1px solid #ffffff",
    color: "#0C2D9C",
    fontSize: "16px",
    lineHeight: "19px",
    paddingLeft: "5px",
    paddingRight: "54px",
    paddingTop: "10.8px",
    paddingBottom: "13px",
    width: "230px",
    // Use the system font instead of the default Roboto font.
    fontFamily: ["'Centrale Sans Medium'"].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ProfileCotizaciones(props) {
  const classes = useStyles();
  const history = useHistory();
  const {setOpenAlert} = useAuth();
  const [user, setUser] = useState({});
  const [client, setClient] = useState({});
  const [dataBreadCrumb, setDataBreadCrumb] = useState([]);

  useEffect(() => {
    if (!localStorage.getItem("user")) {
        //redirect to login
        history.push("/login");
    } else {
        setUser(JSON.parse(localStorage.getItem("user")).usuario);
    }

    if (localStorage.getItem("client")) {
        // getSaldoAndCredito();
        setClient(JSON.parse(localStorage.getItem("client"))?.listaSnbyid[0]);
    }
}, []);

  let today = new Date();
  let fullDate =
    today.getFullYear() +
    "-" +
    ("0" + (today.getMonth() + 1)).slice(-2) +
    "-" +
    today.getDate();
  let prevDate = new Date();
  prevDate.setDate(prevDate.getDate() - 30);
  prevDate =
    prevDate.getFullYear() +
    "-" +
    ("0" + (prevDate.getMonth() + 1)).slice(-2) +
    "-" +
    prevDate.getDate();
  const [rows, setRows] = useState([]);
  const [rowsBU, setRowsBU] = useState([]);
  const [filtro, setFiltro] = useState(1);
  const [noCotInput, setNoCotInput] = useState(classes.hide);
  const [fechaInput, setFechaInput] = useState(classes.hide);
  const [preciosInput, setPreciosInput] = useState(classes.hide);
  const [dateFilterI, setDateFilterI] = useState("");
  const [dateFilterF, setDateFilterF] = useState("");
  const [noCotizacion, setNoCotizacion] = useState("");
  const [precioDesde, setPrecioDesde] = useState();
  const [precioHasta, setPrecioHasta] = useState();
  const [valuePrecioTextDesde, setValuePrecioTextDesde] = useState("");
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [cotDelete, setCotDelete] = useState(0);
  const [bool, setBool] = useState(false);
  const [cartView, setCartView] = useState(null);
  const [validCharacterDesde, setValidCharacterDesde] = useState(false);
  const [validCharacterHasta, setValidCharacterHasta] = useState(false);
  const [valuePrecioTextHasta, setValuePrecioTextHasta] = useState("");

  const [tituloSeccion, setTituloSeccion] = useState("MIS COTIZACIONES");
  const [cotDetalle, setCotDetalle] = useState(0);
  const [cotSelected, setCotSelected] = useState([]);
  const [prodCod, setProdCod] = useState([]);
  const [seccionListado, setSeccionListado] = useState(null);
  const [seccionDetalle, setSeccionDetalle] = useState(classes.hide);
  const [seccionEditar, setSeccionEditar] = useState(classes.hide);
  const [contEncontradas, setCotEncontradas] = useState(false);
  const [qDivisa, setQDivisa] = useState("mxn");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setDataBreadCrumb(() => [
      {
        name: "INICIO",
        link: "/",
      },
      {
        name: "MIS COTIZACIONES",
        link: "",
      }
    ]);
  }, []);

  let isMobile = useCheckMobileScreen();



  const selectFilter = (val) => {
    setFiltro(val);
    setNoCotInput(classes.hide);
    setFechaInput(classes.hide);
    setPreciosInput(classes.hide);
    switch (val) {
      case 1:
        setNoCotInput(classes.hide);
        setFechaInput(classes.hide);
        setPreciosInput(classes.hide);
        getCotizaciones();
        break;
      case 2:
        getCotizaciones();
        setNoCotInput(null);
        break;
      case 3:
        getCotizaciones();
        setFechaInput(null);
        setDateFilterI(prevDate);
        setDateFilterF(fullDate);
        break;
      case 4:
        getCotizaciones();
        setFechaInput(null);
        setDateFilterI(prevDate);
        setDateFilterF(fullDate);
        break;
      case 5:
        getCotizaciones();
        setPreciosInput(null);
        break;
    }
  };

  const getCotizaciones = async () => {
    let _sn = JSON.parse(localStorage.getItem("client")).listaSnbyid[0]
      .sn_socios_negocio_id;
    const _c = await userService.getCotizacionesBySN(_sn);
    if (_c.data.constCotizaciones.length > 0) {
      let _d = [];
      for (let i = 0; i < _c.data.constCotizaciones.length; i++) {
        let _r = _c.data.constCotizaciones[i];
        _d.push({
          numCot: _r.cot_cotizacion_id,
          f_elab: _r.createdAt,
          f_venc: _r.cot_fecha_vencimiento,
          importe: (_r.cot_total_cotizacion + _r.cot_costo_envio),
          acciones: "",
        });
      }
      setRows(_d.sort(function (a, b) {
        if (a.numCot < b.numCot) {
          return 1;
        }
        if (a.numCot > b.numCot) {
          return -1;
        }
        // a must be equal to b
        return 0;
      }));
      setRowsBU(_d);
      setBool(!bool);
      setCotEncontradas(false);
    }else{
      setCotEncontradas(true);
    }
  };

  const searchFilters = () => {
    let _r = [];
    for (let i = 0; i < rowsBU.length; i++) {
      switch (filtro) {
        case 1:
          getCotizaciones();
          break;
        case 2:
          if (
            noCotizacion === "" ||
            parseInt(rowsBU[i].numCot) === parseInt(noCotizacion)
          ) {
            _r.push(rowsBU[i]);
            setCotEncontradas(false);
          }else{
            setCotEncontradas(true);
          }
          break;

        case 3:
          let _d = Date.parse(rowsBU[i].f_elab);
          let _i = Date.parse(dateFilterI);
          let _f = Date.parse(dateFilterF) + 24 * 60 * 60 * 1000;
          if (_i <= _d && _f >= _d) {
            _r.push(rowsBU[i]);
            setCotEncontradas(false);
          }else{
            setCotEncontradas(true);
          }
          break;

        case 4:
          let _df = Date.parse(rowsBU[i].f_venc);
          let _if = Date.parse(dateFilterI);
          let _ff = Date.parse(dateFilterF);
          if (_if <= _df && _ff >= _df) {
            _r.push(rowsBU[i]);
            setCotEncontradas(false);
          }else{
            setCotEncontradas(true);
          }
          break;

        case 5:
          if (
            (precioDesde === "" ||
              parseFloat(rowsBU[i].importe) >= parseFloat(precioDesde)) &&
            (precioHasta === "" ||
              parseFloat(rowsBU[i].importe) <= parseFloat(precioHasta))
          ) {
            _r.push(rowsBU[i]);
            setCotEncontradas(false);
          }else{
            setCotEncontradas(true);
          }
          break;
      }
    }
    setRows(_r);
  };

  const formatDate = (date) => {
    let _d = date.split("T");
    let _a = _d[0].split("-");
    let _m = "";
    switch (_a[1]) {
      case "01":
        _m = "Ene";
        break;
      case "02":
        _m = "Feb";
        break;
      case "03":
        _m = "Mar";
        break;
      case "04":
        _m = "Abr";
        break;
      case "05":
        _m = "May";
        break;
      case "06":
        _m = "Jun";
        break;
      case "07":
        _m = "Jul";
        break;
      case "08":
        _m = "Ago";
        break;
      case "09":
        _m = "Sep";
        break;
      case "10":
        _m = "Oct";
        break;
      case "11":
        _m = "Nov";
        break;
      case "12":
        _m = "Dic";
        break;
    }
    return _a[2] + "/" + _m + "/" + _a[0];
  };

  const formatDateFull = (date) => {
    let _d = date.split("T");
    let _a = _d[0].split("-");
    let _m = "";
    switch (_a[1]) {
      case "01":
        _m = "Enero";
        break;
      case "02":
        _m = "Febrero";
        break;
      case "03":
        _m = "Marzo";
        break;
      case "04":
        _m = "Abril";
        break;
      case "05":
        _m = "Mayo";
        break;
      case "06":
        _m = "Junio";
        break;
      case "07":
        _m = "Julio";
        break;
      case "08":
        _m = "Agosto";
        break;
      case "09":
        _m = "Septiembre";
        break;
      case "10":
        _m = "Octubre";
        break;
      case "11":
        _m = "Noviembre";
        break;
      case "12":
        _m = "Diciembre";
        break;
    }
    return _a[2] + "/" + _m + "/" + _a[0];
  };


  const deleteCotQuestion = (idCot) => {
    setCotDelete(idCot);
    handleModalDelete();
  };

  const handleModalDelete = () => {
    setOpenModalDelete(!openModalDelete);
  };

  const deleteCot = async () => {
    let _d = await userService.deleteCotizacion(
      cotDelete,
      JSON.parse(localStorage.getItem("client")).listaSnbyid[0]
        .sn_socios_negocio_id
    );
    getCotizaciones();
    handleModalDelete();
    if(_d){
      setOpenAlert({
        message:
          "Cotización eliminada correctamente",
        type: "success",
        open: true,
      });
    } else {
      setOpenAlert({
        message:
          "Hubo un problema, no se ha podido eliminar la cotización",
        type: "error",
        open: true,
      });
    }
  };

  const closeCartDiv = () => {
    setCartView(null);
  };
  const showCart = () => {
    setCartView(<CartHeader closeCartDiv={closeCartDiv} />);
  };

  useEffect(() => {
    if(Object.keys(user).length > 0) {
      getCotizaciones();
    }
  }, [user]);

  useEffect(() => {}, [bool]);

 



  const handleOnclickDesde = () =>{
    const valueInputDesde = window.document.getElementById('precioDesdeInput');
    valueInputDesde.setAttribute('type', 'number');
    setValidCharacterDesde(false);
  }

  const handleOnChangeDesde = (e) =>{
    const newValuePrecioText = getPriceSapFormat(e.target.value);
    setValuePrecioTextDesde(newValuePrecioText)
    setPrecioDesde(e.target.value); 
  }

  const handleOnBlurDesde = () =>{
    const valueInputDesde = window.document.getElementById('precioDesdeInput');
    valueInputDesde.setAttribute('type', 'text');
    setValidCharacterDesde(true);
  }

  const handleOnclickHasta = () =>{
    const valueInputHasta = window.document.getElementById('precioHastaInput');
    valueInputHasta.setAttribute('type', 'number');
    setValidCharacterHasta(false);
  }

  const handleOnChangeHasta = (e) => {
    const newValuePrecioTextHasta =getPriceSapFormat(e.target.value);
    setValuePrecioTextHasta(newValuePrecioTextHasta)
    setPrecioHasta(e.target.value); 
  }

  const handleOnBlurHasta = () => {
    const valueInputHasta = window.document.getElementById('precioHastaInput');
    valueInputHasta.setAttribute('type', 'text');
    setValidCharacterHasta(true);
  }
  
  const handleDuplicateQuote = async (id) => {
    const respond = await userService.createDuplicateQuote(
      id,
      JSON.parse(localStorage.getItem("client")).listaSnbyid[0]
        .sn_socios_negocio_id
    );
    if(!respond.error){
      setOpenAlert({
        message:
          "Cotización duplicada correctamente",
        type: "success",
        open: true,
      });
      setTimeout(() => {
        setOpenAlert({
          message:
            "Edita tu cotización",
          type: "info",
          open: true,
        });
      }, 3000);
      setTimeout(() => {
        history.push(`/myprofile/cotizaciones/editar/${respond.data}`);
      }, 2500);
    } else {
      setOpenAlert({
        message:
          "Hubo un problema, no se ha podido duplicar la cotización",
        type: "error",
        open: true,
      });
    }
  }
  
  return (
    <div className={classes.root}>
      <Collapse in={alert.open}>
       
      </Collapse>

      <div className={classes.spaceDiv}>
        <SectionBreadcrumb route={dataBreadCrumb} />
        <Typography className={classes.title}>{tituloSeccion}</Typography>

        <div>
            {/* <Button onClick={handleOpen}>Crear proyecto</Button>*/}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Solicitud de registro de proyecto
                </Typography>
                <Grid item xs={12}>
                  <Grid container className={classes.dataContainer}>
                    <Grid item xs={12} md={4} style={{paddingTop:"11px"}}>
                      <Typography className={classes.formDataSubTitleDes}>Integrador</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        className={classes.inputs}
                        size="small"
                        //onChange={handleChange}
                        //value={alias}
                        id="integrador"
                        name="integrador"
                        type="text"
                        label=""
                      />
                    </Grid>
                  </Grid>
                  <Grid container className={classes.dataContainer}>
                    <Grid item xs={12} md={4} style={{paddingTop:"11px"}}>
                      <Typography className={classes.formDataSubTitleDes}>Contacto</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        className={classes.inputs}
                        size="small"
                        //onChange={handleChange}
                        //value={alias}
                        id="contacto"
                        name="contato"
                        type="text"
                        label=""
                      />
                    </Grid>
                  </Grid>
                  <Grid container className={classes.dataContainer}>
                    <Grid item xs={12} md={4} style={{paddingTop:"11px"}}>
                      <Typography className={classes.formDataSubTitleDes}>Teléfono</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        //className={classes.inputs}
                        size="small"
                        //onChange={handleChange}
                        //value={alias}
                        id="telefono"
                        name="telefono"
                        type="text"
                        label=""
                      />
                    </Grid>
                  </Grid>
                  <Grid container className={classes.dataContainer}>
                    <Grid item xs={12} md={4} style={{paddingTop:"11px"}}>
                      <Typography className={classes.formDataSubTitleDes}>Email</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        className={classes.inputs}
                        size="small"
                        //onChange={handleChange}
                        //value={alias}
                        id="email"
                        name="email"
                        type="text"
                        label=""
                      />
                    </Grid>
                  </Grid>
                  <Grid container className={classes.dataContainer}>
                    <Grid item xs={12} md={4}>
                      <Typography className={classes.formDataSubTitleDes}>Usuario final</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        className={classes.inputs}
                        size="small"
                        //onChange={handleChange}
                        //value={alias}
                        id="usuario_final"
                        name="usuario_final"
                        type="text"
                        label=""
                      />
                    </Grid>
                  </Grid>
                  <Grid container className={classes.dataContainer}>
                    <Grid item xs={12} md={4} style={{paddingTop:"11px"}}>
                      <Typography className={classes.formDataSubTitleDes}>Nombre</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        className={classes.inputs}
                        size="small"
                        //onChange={handleChange}
                        //value={alias}
                        id="nombre"
                        name="nombre"
                        type="text"
                        label=""
                      />
                    </Grid>
                  </Grid>
                  <Grid container className={classes.dataContainer}>
                    <Grid item xs={12} md={4} style={{paddingTop:"11px"}}>
                      <Typography className={classes.formDataSubTitleDes}>Ciudad</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        className={classes.inputs}
                        size="small"
                        //onChange={handleChange}
                        //value={alias}
                        id="ciudad"
                        name="ciudad"
                        type="text"
                        label=""
                      />
                    </Grid>
                  </Grid>
                  <Grid className={classes.dataContainer}>
                    <Grid item xs={12}>
                      <Button className={classes.btnRegistro} style={{width: "100%", marginTop:"15px"}}> Crear solicitud de registro </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
          </div>


        {isMobile ? <Divider className={classes.titleDivider} /> : ""}
      </div> 

      <div className={seccionListado}>
        
        <Grid container className={classes.searchDiv}>
        <Grid xs={12} sm={12} md={4}> 
        <FormControl className={classes.margin}>
            <Select
              id="select-filter"
              value={filtro}
              onChange={(ev) => { 
                selectFilter(ev.target.value);
              }}
              input={<BootstrapInput />}
              style={{width:'200px'}}
            >
              <MenuItem selected  value={1}><em>Filtrar por:</em></MenuItem>
              <MenuItem value={2}>No. Cotización</MenuItem>
              <MenuItem value={3}>Fecha de elaboración</MenuItem>
              <MenuItem value={4}>Fecha de vencimiento</MenuItem>
              <MenuItem value={5}>Importe</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} md={3} className={fechaInput}>
        <TextField
              id="dateI"
              label="Desde"
              type="date"
              value={dateFilterI}
              className={classes.textField2}
              onChange={(ev) => {
                setDateFilterI(ev.target.value);
              }}
              style={{width:'170px'}}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
           
        </Grid>
        <Grid xs={12} sm={6} md={3} className={fechaInput}>
            <TextField
              id="dateF"
              label="Hasta"
              type="date"
              value={dateFilterF}   style={{width:'170px'}}
              className={classes.textField2}
              onChange={(ev) => {
                setDateFilterF(ev.target.value);
              }}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />  
        </Grid>
        <Grid xs={12} sm={6} md={6} className={noCotInput}>
        <TextField
              id="noCotInput"
              label="No. Cotización"
              type="text"
              value={noCotizacion}
              onChange={(ev) => {
                setNoCotizacion(ev.target.value);
              }}
              className={classes.textField}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
        </Grid>
        <Grid xs={12} sm={6} md={3} className={preciosInput}> 
        <TextField
              id="precioDesdeInput"
              label="Desde"
              type="number"
              placeholder="$0.00"
              value={validCharacterDesde ? valuePrecioTextDesde:precioDesde}
              onClick={handleOnclickDesde}
              onChange={(e) => {handleOnChangeDesde(e)}}
              onBlur={handleOnBlurDesde}
              className={classes.textField}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
        </Grid>
        <Grid xs={12} sm={6} md={3} className={preciosInput}> 
            <TextField
              id="precioHastaInput"
              label="Hasta"
              type="number"
              placeholder="$0.00"
              value={validCharacterHasta ? valuePrecioTextHasta:precioHasta}
              onClick={handleOnclickHasta}
              onChange={(e) => {handleOnChangeHasta(e)}}
              onBlur={handleOnBlurHasta}
              className={classes.textField}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
        </Grid>
        <Grid xs={12} sm={12} md={2} className={classes.inlineDiv} > 
           <div
              className={classes.btnSearch}
              onClick={() => {
                searchFilters();
              }}
            >
              <Typography className={classes.btnSearchLbl}>Buscar</Typography>
            </div>
        </Grid>
        </Grid>
        
        <div>
        {isMobile ? (

          <Grid item xs={12} className={classes.gridContent}>
          {rows.map((rows) => {
            return (
              <div className={classes.formData}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography className={classes.formDataSubTitleDes}>
                          No. Cotización:
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>{`${
                         rows.numCot
                        }`}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography className={classes.formDataSubTitleDes}>
                          Fecha de elaboración:
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>{formatDate(rows.f_elab)}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography className={classes.formDataSubTitleDes}>
                          Fecha de venc
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>{formatDate(rows.f_venc)}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography className={classes.formDataSubTitleDes}>
                          Importe
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>{getPriceSapFormat(rows.importe)}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Tooltip title="Duplicar cotización">
                      <IconButton
                        style={{
                          position: "relative",
                          bottom: 10,
                        }}
                        aria-label="duplicate"
                        onClick={() => {
                          window.scrollTo(0, 0);
                          handleDuplicateQuote(rows.numCot);
                        }}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Editar">
                      <IconButton
                        style={{
                          position: "relative",
                          bottom: 10,
                        }}
                        aria-label="edit"
                        onClick={() => {
                          window.scrollTo(0, 0);
                          history.push(`/myprofile/cotizaciones/editar/${rows.numCot}`)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Ver">
                      <IconButton
                        style={{
                          position: "relative",
                          bottom: 10,
                        }}
                        aria-label="edit"
                        onClick={() => {
                          window.scrollTo(0, 0);
                          history.push(`/myprofile/cotizaciones/detail/${rows.numCot}`)
                        }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Eliminar">
                      <IconButton
                        style={{
                          position: "relative",
                          bottom: 10,
                        }}
                        aria-label="delete"
                        onClick={() => {
                          deleteCotQuestion(rows.numCot);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </div>
            );
          })}
          </Grid>
        ):(
          rows.length > 0 ? (
            <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell className={classes.tableHeaderTxt}>
                    No. Cotización
                  </TableCell>
                  <TableCell className={classes.tableHeaderTxt}>
                    Fecha de elab.
                  </TableCell>
                  <TableCell className={classes.tableHeaderTxt}>
                    Fecha de Venc.
                  </TableCell>
                  <TableCell className={classes.tableHeaderTxt}>
                    Importe
                  </TableCell>
                  <TableCell className={classes.tableHeaderTxt} align="right">
                    Acciones
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.numCot}>
                    <TableCell className={classes.rowData}>
                      {row.numCot}
                    </TableCell>
                    <TableCell className={classes.rowData}>
                      {formatDate(row.f_elab)}
                    </TableCell>
                    <TableCell className={classes.rowData}>
                      {formatDate(row.f_venc)}
                    </TableCell>
                    <TableCell className={classes.rowData}>
                      {getPriceSapFormat(row.importe)}
                    </TableCell>
                    <TableCell align="right">
                      <div>
                        <Tooltip title="Duplicar cotización">
                          <IconButton
                            style={{
                              position: "relative",
                              bottom: 10,
                            }}
                            aria-label="duplicate"
                            onClick={() => {
                              window.scrollTo(0, 0);
                              handleDuplicateQuote(row.numCot);
                            }}
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Editar">
                          <IconButton
                            style={{
                              position: "relative",
                              bottom: 10,
                            }}
                            aria-label="edit"
                            onClick={() => {
                              window.scrollTo(0, 0);
                              history.push(`/myprofile/cotizaciones/editar/${row.numCot}`)
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Ver">
                          <IconButton
                            style={{
                              position: "relative",
                              bottom: 10,
                            }}
                            aria-label="edit"
                            onClick={() => {
                              window.scrollTo(0, 0);
                              history.push(`/myprofile/cotizaciones/detail/${row.numCot}`)
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Eliminar">
                          <IconButton
                            style={{
                              position: "relative",
                              bottom: 10,
                            }}
                            aria-label="delete"
                            onClick={() => {
                              deleteCotQuestion(row.numCot);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                      {row.acciones}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          ):(
            <Container fixed className={classes.containerProducts} style={{textAlign: "center", marginBottom:50, marginTop: 50}}>
              <h3>{contEncontradas && rows.length == 0 ? "Disculpa, no encontramos resultados con tus criterios de búsqueda":""} </h3>
            </Container>
  
          )
        )}  
          
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openModalDelete}
            onClose={handleModalDelete}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openModalDelete}>
              <div className={classes.paperModal}>
                <div className={classes.modalHeader}>
                  <Typography className={classes.modalHeaderTxt}>
                    ELIMINAR COTIZACIÓN
                  </Typography>
                </div>
                <div className={classes.modalBody}>
                  <Typography className={classes.modalBodyTxt}>
                    ¿Está seguro de eliminar
                    <br />
                    la cotización {cotDelete}?
                  </Typography>
                </div>
                <div className={classes.modalBtns}>
                  <div className={classes.modalBtn}>
                    <Typography
                      className={classes.modalBtnTxt}
                      onClick={() => {
                        handleModalDelete();
                      }}
                    >
                      Cancelar
                    </Typography>
                  </div>
                  <div className={classes.modalBtn}>
                    <Typography
                      className={classes.modalBtnTxt}
                      onClick={() => {
                        deleteCot();
                      }}
                    >
                      Eliminar cotización
                    </Typography>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>
        </div>
      </div>
      
     
    </div>
  );
}
