import React, { createContext, useEffect, useState, Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';

import { API_URL } from "_services/api.url.back.img";
import useCheckMobileScreen from "components/Mobile/Mobile.js";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
// @material-ui/icons
import ViewQuiltIcon from "@material-ui/icons/ViewQuilt";
// core components
import Loading from "../../utils/loading/Loading.js";

import "assets/custom/ProductInput.css";
 
import SectionSpaceTop from "views/Home/Sections/SectionSpaceTop.js";
import SectionBreadcrumb from "views/Home/Sections/SectionBreadcrumb.js";
import SectionTitle from "views/Categories/CategoryProductCard/CategoryTitle.js";

import CategoryProductCard from "views/Categories/CategoryProductCard/CategoryProductCard.js";
import CategoryProductSmall from "views/Categories/CategoryProductCard/CategoryProductSmall.js";

import img001 from "assets/img/Image-Not-Available.png";

import { searchServices } from "../../_services/search.services";
import { useParams } from 'react-router-dom';
import { useAuth } from "hooks/useAuth.js";

const useStyles = makeStyles((styles) => ({
  hide: {
    display: "none !important",
  },
  divNoResults: {
    width: "100%",
    textAlign: "center",
    paddingTop: "50px",
    paddingBottom: "50px",
  },
  textNoResults: {
    fontSize: "14px",
    fontFamily: "'Centrale Sans Medium'",
  },
  mainBackground: {
    backgroundColor: "#FFF",
  },
  leftDiv: {
    width: "21%",
    marginLeft: "2%",
    display: "inline-block",
    verticalAlign: "top",
    paddingRight: "2%",
    [styles.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mainDiv: {
    backgroundColor: "#fff",
    /*width: "75%",*/
    width: "100%",
    display: "inline-block",
    padding: "24px",
    paddingTop: 0,
    verticalAlign: "top",
    [styles.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  headerDiv: {
    width: "100%",
    paddingBottom: "10px",
    borderBottom: "2px solid #0b3196",
  },
  numProductsDiv: {
    width: "30%",
    display: "inline-block",
    verticalAlign: "bottom",
    textAlign: "left",
    [styles.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  totalProdTxt: {
    color: "#000000",
    fontSize: "12px",
    fontFamily: "'Centrale Sans Light'",
    fontWeight: "300",
  },
  titleSectionDiv: {
    width: "40%",
    display: "inline-block",
    verticalAlign: "bottom",
    textAlign: "center",
    [styles.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  orderPagesizeDiv: {
    width: "30%",
    display: "inline-block",
    verticalAlign: "bottom",
    textAlign: "right",
  },
  orderByForm: {
    marginRight: "15px",
    width: 140,
    height: 30,
    textAlign: "center",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 14,
  },
  perPageForm: {
    marginRight: "15px",
    width: "20%",
    textAlign: "center",
  },
  /// Filters
  filtersMainDiv: {
    backgroundColor: "#f5f5f5",
    border: "1px solid #eaeaea",
    width: "100%",
    verticalAlign: "top",
  },
  filtersMainTitleDiv: {
    width: "90%",
    marginLeft: "5%",
    paddingTop: "5%",
    borderBottom: "2px solid #0b3196",
  },
  filtersMainTitleTxt: {
    fontSize: "24px",
    color: "#0b3196",
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "300",
  },
  attributeMainDiv: {
    width: "90%",
    marginLeft: "5%",
    marginRight: "5%",
    paddingTop: "15px",
    paddingBottom: "15px",
    marginBottom: "30px",
    borderBottom: "1px solid #eaeaea",
  },
  attributeTitle: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "24px",
    fontWeight: "600",
  },
  attributeValueDiv: {
    width: "100%",
  },
  attributeValue: {
    color: "#0b3196",
    display: "inline",
    width: "100%",
  },
  btnAplicarFiltrosDiv: {
    width: "100%",
    paddingLeft: "5%",
    paddingRight: "5%",
    paddingBottom: "30px",
  },
  btnAplicarFiltros: {
    fontFamily: "'Centrale Sans Medium'",
    width: "100%",
    backgroundColor: "#FFFFFF",
    color: "#0b3196",
    border: "1px solid #0b3196",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#0b3196",
    },
  },
  gridIcon: {
    color: "#0b3196",
    fontSize: "42px",
    "&:hover": {
      cursor: "pointer",
    },
    ["@media (max-width: 898.98px)"]: {
      // eslint-disable-line no-useless-computed-key
      display: "none",
    },
  },
  wordSearchDiv: {
    color: "#0b3196",
    fontFamily: "'Centrale Sans Bold'",
    fontSize: "24px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "29px",
    textAlign: "center",
  },
  pagination: {
    width: "100%",
  },
  divVerMas: {
    width: "100%",
    textAlign: "center",
    paddingTop: "65px",
    paddingBottom: "70px",
    paddingLeft: "calc(50% - 130px)",
  },
  btnVerMas: {
    paddingLeft: "52px",
    paddingRight: "52px",
    paddingTop: "12px",
    paddingBottom: "12px",
    border: "1px solid #0C2D9C",
    color: "#0C2D9C",
    borderRadius: "3px",
    width: "261px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#0C2D9C",
      color: "#FFFFFF",
    },
  },
  txtVerMas: {
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "19px",
    textAlign: "center",
  },
  boxPaginadoBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "25px",
    paddingBottom: "10px",
    "& button": {
      all: "unset",
      margin: "0",
      borderRadius: "7px",
      cursor: "pointer",
      height: "45px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0",
      fontSize: "16px",
      width: "261px",
      border: "1px solid #003773",
      color: "#003773",
      fontFamily: "'Centrale Sans Medium'",
    },
  },
}));

let productDetailslocal = {
  name: "",
  page: 1,
  res_per_page: 20,
  totalProductos: -1,
  products_show: {
    products: [],
    title: "",
  },
};

const getParametroBusqueda = (parameterName) => {
  var result = null,
    tmp = [];
  window.location.search
    .substr(1)
    .split("&")
    .forEach(function (item) {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
};

let productDetails = createContext(productDetailslocal); /// Variable global
let user = 1; //// Change for logged user

export default function QuickSearchView(props) {
  const {isLogged} = useAuth()
  const classes = useStyles();
  const { ...rest } = props;
  let isMobile = useCheckMobileScreen();

  const [wordSearch, setWordSearch] = useState("");
  const [breadcrumb, setBreadcrumb] = useState(null);
  const [page, setPage] = useState(0);
  const [pagesize, setPagesize] = useState(12);
  const [order, setOrder] = useState("fecha lanzamienta");
  const [orderType, setOrderType] = useState("asc");
  const [orderBy, setOrderBy] = useState("fecha lanzamienta.asc");
  const [atributos, setAtributos] = useState([]);
  const [atrBool, setAtrBool] = useState(false);
  const [bool, setBool] = useState(false);
  const [viewGrid, setViewGrid] = useState(true);
  const [paging, setPaging] = useState(1);

  const [loading, setLoading] = useState(false);
  const [skus, setSkus] = useState([]);
  const [totalPaginado, setTotalPaginado] = useState(0);
  const [userIsLogged, setUserIsLogged] = useState(false);
  const [paginado, setPaginado] = useState(0);

  // const [ esPrimerPagina, setEsPrimerPagina ] = useState(true);
  const [totalSkus, setTotalSkus] = useState(null);
  const [orderQuantity, setOrderQuantity] = useState(12);
  const [partidas, setPartidas] = useState(null);
  const [skusToRender, setSkusToRender] = useState([]);

  const [esPartida, setEsPartida] = useState(true);
  const [tipoDePaginado, setTipoDePaginado] = useState(null);
  const [contadorPartidas, setContadorPartidas] = useState(0);
  const [contadorPaginas, setContadorPaginas] = useState(0);

  // let { userId } = useParams();
  console.log('useParams ', useParams());

  useEffect(() => {
    const esPrimerPagina = true;
    getAdvancedSearchData(0, esPrimerPagina);
  }, [orderBy, orderQuantity]);

  useEffect(() => {
    window.scrollTo(0,0);
  },[])

  const getParameters = () => {
    const queryString = window.location.search.replace("?", "");
    const _p = queryString.split("&");
    let _a = [];
    for (let i = 0; i < _p.length; i++) {
      let _b = _p[i].split("=");
      _a[_b[0]] = _b[1];
    }
    return _a;
  };

  const getPrecioLista = (idLista, lista) => {
    let _p = 0;
    for (let i = 0; i < lista.length; i++) {
      if (lista[i].pl_listp_lista_de_precio_id === idLista) {
        _p = lista[i].pl_precio_producto;
      }
    }
    return _p;
  };

  const getAdvancedSearchData = async (pagina, esPrimerPagina) => {
    let skusArray = [];
    setLoading(true);
    /// Get data
    const _p = getParameters();
    const _nombre = _p.name.replaceAll("%20", " ");
    const _nombre_extranjero = _p.nombre_extranjero.replaceAll("%20", " ");
    const _sku = _p.sku.replaceAll("%20", " ");
    const _descripcion = atob(_p.description).replaceAll("%20", " ");
    const _marca = _p.brand.replaceAll("%20", " ");
    const _categoria = _p.category.replaceAll("%20", " ");
    const _minPrice = _p.minPrice.replaceAll("%20", " ");
    const _maxPrice = _p.maxPrice.replaceAll("%20", " ");
    let id = 0;
    setPaging(1);

    setWordSearch("BÚSQUEDA AVANZADA");

    //const _cate = getCat(_c.tree, id);
    let _d = [];
    let buscarProductosRes = [];

    const cliente = JSON.parse(localStorage.getItem("client"));
    console.log(cliente)
    const idSocioNegocio = cliente !=null? cliente.listaSnbyid[0].sn_socios_negocio_id : process.env.REACT_APP_API_USER_B2B;
    /// Products
    productDetails._currentValue.products_show.products = [];
    productDetails._currentValue.products_show.title = getParametroBusqueda(
      "name"
    );
    productDetails._currentValue.products_show.nombre_extranjero = getParametroBusqueda(
      "nombre_extranjero"
    );
    productDetails._currentValue.products_show.sku = getParametroBusqueda(
      "sku"
    );
    productDetails._currentValue.products_show.description = atob(getParametroBusqueda(
      "description"
    ));
    productDetails._currentValue.products_show.brand = getParametroBusqueda(
      "brand"
    );
    productDetails._currentValue.products_show.category = getParametroBusqueda(
      "category"
    );

    if (pagina === 0) {
      skusArray = [];
      setPaginado(0);
    } else {
      skusArray = skus;
    }

    buscarProductosRes = await searchServices.getAdvancedSearch(
      pagina,
      orderQuantity,
      _nombre,
      _nombre_extranjero,
      idSocioNegocio,
      _descripcion,
      _sku,
      _marca,
      _categoria, 
      order,
      orderType,
      _minPrice,
      _maxPrice
    );

    console.log(buscarProductosRes)
    const productos = buscarProductosRes.mainConsultaProductos.rows;
    const countProductos = buscarProductosRes.mainConsultaProductos.count;
    setTotalSkus(countProductos);
    const calcularPaginado = Math.ceil(countProductos / orderQuantity);
    // if(calcularPaginado > 1) {
    //     setTipoDePaginado(true);
    // }
    setTotalPaginado(calcularPaginado);

    productos.forEach((sku) => {
      // if(producto.ListaHijos.length) {
      //     for (let index = 0; index < producto.ListaHijos.length; index++) {
      //         producto.ListaHijos[index].order = (producto.ListaHijos[index].prod_nombre_extranjero == _nombre_extranjero || producto.ListaHijos[index].prod_sku == _sku || producto.ListaHijos[index].prod_nombre == _nombre) ? 1 : 2;
      //     }
      //     producto.ListaHijos.sort(function(a,b){return a.order - (b.order);});
      //     for (let index = 0; index < producto.ListaHijos.length; index++) {
      //         const sku = producto.ListaHijos[index];
      let urlImagen =
        "http://wws.com.pa/wp-content/plugins/wordpress-ecommerce/marketpress-includes/images/default-product.png";

      if (sku.imagen_productos.length) {
        let _skuimg = sku.imagen_productos;
        _skuimg.sort(function (a, b) {
          return a.imgprod_nombre_archivo.localeCompare(
            b.imgprod_nombre_archivo
          );
        });
       
        if(_skuimg[0].imgprod_ruta_archivo.includes('https://ftp3.syscom.mx')){
          urlImagen=_skuimg[0].imgprod_ruta_archivo
        }else{
        urlImagen = `${API_URL}${ _skuimg[0].imgprod_ruta_archivo.split("public")[1]}`;
        }

      }
      let descuento = 0;
      if (sku.precioFinal < sku.precioBasePorListaPrecio) {
        descuento = sku.precioBasePorListaPrecio - sku.precioFinal;
      }
      const skuInfo = {
        productoId: sku.productoPadreId,
        idSKU: sku.prod_producto_id,
        imagen: urlImagen,
        // 'nombre': sku.prod_nombre ,
        nombre: sku.prod_nombre.toLowerCase(),
        sku: sku.prod_sku,
        nombre_extranjero: sku.prod_nombre_extranjero,
        stock: sku.prod_total_stock,
        precioLista: sku.precioBasePorListaPrecio,
        descuento: descuento,
        precioFinal: sku.precioFinal,
        precioListaDls: sku.precioFinaldls,
        precioFinalDls: sku.precioMenosDescuentodls,
        skuId: sku.prod_producto_id,
        prod_tipo_precio_base: sku.prod_tipo_precio_base,
        precioPromocionDielsaBool: sku.precioPromocionDielsaBool,
        tipoPromocionFinal: sku.tipoPromocionFinal,
        precioMenosDescuento: sku.precioMenosDescuento,
        DescuentoDielsaFijo: sku.DescuentoDielsaFijo,
        brand: sku.mar_nombre,
        bullets: sku.prod_viñetas,
        backorder: sku.aplicaBackOrder,
        prod_altura: sku.prod_altura,
        prod_ancho: sku.prod_ancho,
        prod_longitud: sku.prod_longitud,
        prod_peso: sku.prod_peso,
        prod_volumen: sku.prod_volumen,
        externalProductId: sku.externalProductId
      };
      skusArray.push(skuInfo);
      //     }
      // } else {
      // }
    });

    setSkus(skusArray);

    if (countProductos > skusArray.length) {
      if (esPrimerPagina) {
        getPrimerPaginaDeProductos(skusArray);
      } else {
        getSiguientePaginaDeProductos(skusArray);
      }
    } else {
      setSkusToRender(skusArray);
      setTipoDePaginado(null);
    }

    setLoading(false);

    // setSkus(skusArray);
    // productDetails._currentValue.products_show.products = skusArray;
    // productDetails._currentValue.totalProductos = buscarProductosRes.mainConsultaProductos.count;

    // productDetails._currentValue.totalProductos = _d.mainConsultaProductos.count;
    // for (let i = 0; i < _d.mainConsultaProductos.rows.length; i++) {
    //     let _prd = _d.mainConsultaProductos.rows[i];
    //     if (_prd.ListaHijos[0] !== undefined) {
    //         let _img = (_prd.ListaHijos[0].imagen_productos[0] !== undefined) ? API_URL + _prd.ListaHijos[0].imagen_productos[0].imgprod_ruta_archivo.replace("./public", "") : img001;
    //         let _stock = 0;
    //         for (let j = 0; j < _prd.ListaStock.length; j++) {
    //             _stock += (_prd.ListaStock[j].sp_cantidad !== undefined) ? parseInt(_prd.ListaStock[j].sp_cantidad) : 0;
    //         }
    //         let _precioCliente = (JSON.parse(localStorage.getItem("client")) !== undefined
    //             && JSON.parse(localStorage.getItem("client")) !== null
    //             && JSON.parse(localStorage.getItem("client")).listaSnbyid[0].sn_lista_precios !== null)
    //             ?
    //             getPrecioLista(JSON.parse(localStorage.getItem("client")).listaSnbyid[0].sn_lista_precios, _prd.ListaPrecios[0])
    //             :
    //             _prd.ListaHijos[0].prod_precio;
    //         let _bull = _prd.ListaHijos[0].prod_viñetas;
    //         let _prod = { 'id': _prd.prod_producto_id, 'idSKU': _prd.ListaHijos[0].prod_producto_id, 'brand': _prd.mar_nombre, 'sku': _prd.ListaHijos[0].prod_sku, 'num_parte': _prd.ListaHijos[0].prod_producto_id, 'name': _prd.prod_nombre, 'stock': _stock, 'priceList': _prd.ListaHijos[0].prod_precio, 'priceClient': _precioCliente, 'image': _img, 'link': '/product?prod=' + _prd.prod_producto_id, bullets: _bull, discount: 0, disponible: true, rating: _prd.prod_calificacion_promedio };
    //         _productos_temp.push(_prod);
    //     }
    // }
    // productDetails._currentValue.products_show.products = _productos_temp;

    /// Breadcrumb
    let breadcrumbRoute = [];

    breadcrumbRoute.push({
      name: "INICIO",
      link: "/",
    });
    breadcrumbRoute.push({
      name: "BÚSQUEDA AVANZADA",
      link: "",
    });
    setBreadcrumb(<SectionBreadcrumb route={breadcrumbRoute} />);
  };

  const getPrimerPaginaDeProductos = (skusArray) => {
    const getPartidas = Math.floor(skusArray.length / orderQuantity);
    setPartidas(getPartidas);
    setContadorPartidas(1);
    setContadorPaginas(1);
    setTipoDePaginado("partida");
    const getSkus = [];
    for (let i = 0; i < orderQuantity; i++) {
      const item = skusArray[i];
      getSkus.push(item);
    }

    setSkusToRender(getSkus);
  };

  const getSiguientePaginaDeProductos = (skusArray) => {
    const getPartidas = Math.floor(skusArray.length / orderQuantity);
    setPartidas(getPartidas);
    setTipoDePaginado("partida");
    const skusToIterate = skusToRender.length + orderQuantity;
    const tempSkus = [];
    for (let i = 0; i < skusToIterate; i++) {
      const item = skus[i];
      tempSkus.push(item);
    }
    setSkusToRender(tempSkus);
  };

  const getProductosPartidaSiguiente = () => {
    let tempContadorPartidas = contadorPartidas;
    tempContadorPartidas++;
    setContadorPartidas(tempContadorPartidas);

    const skusArray = [];

    const skusToIterate = skusToRender.length + orderQuantity;

    if (tempContadorPartidas <= partidas) {
      for (let i = 0; i < skusToIterate; i++) {
        const item = skus[i];
        skusArray.push(item);
      }
      setSkusToRender(skusArray);
    } else {
      if (contadorPaginas < totalPaginado) {
        buscarProductosPaginaSiguiente();
      } else {
        const tempSkus = [];
        for (let i = 0; i < skus.length; i++) {
          const item = skus[i];
          tempSkus.push(item);
        }
        setSkusToRender(tempSkus);
        setTipoDePaginado(null);
      }
    }
  };

  const buscarProductosPaginaSiguiente = () => {
    let tempContadorPaginas = contadorPaginas;
    getAdvancedSearchData(tempContadorPaginas, false);
    tempContadorPaginas++;
    setContadorPaginas(tempContadorPaginas);
  };

  const getNextPage = async () => {
    const _p = getParameters();
    const _nombre = _p.name.replaceAll("%20", " ");
    const _nombre_extranjero = _p.nombre_extranjero.replaceAll("%20", " ");
    const _sku = _p.sku.replaceAll("%20", " ");
    const _descripcion = _p.description.replaceAll("%20", " ");
    const _marca = _p.brand.replaceAll("%20", " ");
    const _categoria = _p.category.replaceAll("%20", " ");
    const _minPrice = _p.minPrice.replaceAll("%20", " ");
    const _maxPrice = _p.maxPrice.replaceAll("%20", " ");
    let id = 0;
    let _pg = paging;
    let _d = [];

    const cliente = JSON.parse(localStorage.getItem("client"));
    const idSocioNegocio = isLogged ? cliente.listaSnbyid[0].sn_socios_negocio_id : process.env.REACT_APP_API_USER_B2B;

    let _productos_temp = productDetails._currentValue.products_show.products;
    _d = await searchServices.getAdvancedSearch(
      _pg,
      pagesize,
      _nombre,
      _nombre_extranjero,
      idSocioNegocio,
      _descripcion,
      _sku,
      _marca,
      _categoria,
      order,
      orderType,
      _minPrice,
      _maxPrice
    );
    productDetails._currentValue.totalProductos =
      _d.mainConsultaProductos.count;
    for (let i = 0; i < _d.mainConsultaProductos.rows.length; i++) {
      let _prd = _d.mainConsultaProductos.rows[i];
      if (_prd.ListaHijos[0] !== undefined) {
        let _img =
          _prd.ListaHijos[0].imagen_productos[0] !== undefined
            ? API_URL +
              _prd.ListaHijos[0].imagen_productos[0].imgprod_ruta_archivo.replace(
                "./public",
                ""
              )
            : img001;


            
        let _stock = 0;
        for (let j = 0; j < _prd.ListaStock.length; j++) {
          _stock +=
            _prd.ListaStock[j].sp_cantidad !== undefined
              ? parseInt(_prd.ListaStock[j].sp_cantidad)
              : 0;
        }
        let _precioCliente =
          JSON.parse(localStorage.getItem("client")) !== undefined &&
          JSON.parse(localStorage.getItem("client")) !== null &&
          JSON.parse(localStorage.getItem("client")).listaSnbyid[0]
            .sn_lista_precios !== null
            ? getPrecioLista(
                JSON.parse(localStorage.getItem("client")).listaSnbyid[0]
                  .sn_lista_precios,
                _prd.ListaPrecios[0]
              )
            : _prd.ListaHijos[0].prod_precio;
        let _bull = _prd.ListaHijos[0].prod_viñetas;
        let _prod = {
          id: _prd.prod_producto_id,
          idSKU: _prd.ListaHijos[0].prod_producto_id,
          brand: _prd.mar_nombre,
          sku: _prd.ListaHijos[0].prod_sku,
          num_parte: _prd.ListaHijos[0].prod_producto_id,
          name: _prd.prod_nombre,
          stock: _stock,
          priceList: _prd.ListaHijos[0].prod_precio,
          priceClient: _precioCliente,
          image: _img,
          link: "/product?prod=" + _prd.prod_producto_id,
          bullets: _bull,
          discount: 0,
          disponible: true,
          rating: _prd.prod_calificacion_promedio,
        };
        _productos_temp.push(_prod);
      }
    }
    productDetails._currentValue.products_show.products = _productos_temp;
    setPaging(_pg + 1);
    setBool(!bool);
  };

  const handlePageSize = (val) => {
    productDetails._currentValue.products_show.products = [];
    setPagesize(val);
  };

  const handleOrderBy = (val) => {
    setOrderBy(val);
    productDetails._currentValue.products_show.products = [];
    let _v = val.split(".");
    setOrder(_v[0]);
    setOrderType(_v[1]);
  };

  const setChecked = async (name, id) => {
    let _a = atributos;
    for (let i = 0; i < _a.length; i++) {
      if (_a[i].name === name) {
        for (let j = 0; j < _a[i].values.length; j++) {
          if (_a[i].values[j].pav_valor === id) {
            _a[i].values[j].checked = !_a[i].values[j].checked;
          }
        }
      }
    }
    setAtributos(_a);
    setAtrBool(!atrBool);
  };

  const aplicarFiltros = () => {
    // getAdvancedSearchData();
  };

  const handleChangeGrid = () => {
    setViewGrid(!viewGrid);
  };

  return (
    <div className={classes.mainBackground}>
      <Container maxWidth={"xl"} fixed style={{paddingTop: isMobile ? 25 : 0 }} >
        <Loading loading={loading} />
     
        <SectionSpaceTop />
        {breadcrumb}
        <div className={classes.fullDiv}>
          <div className={classes.leftDiv + " " + classes.hide}>
            {/*LEFT PANEL*/}
            <div className={classes.filtersMainDiv}>
              <div className={classes.filtersMainTitleDiv}>
                <Typography className={classes.filtersMainTitleTxt}>
                  Filtros
                </Typography>
              </div>
              {atributos.map((a, index) => (
                <div
                  key={`atribuo-id-${index}`}
                  className={classes.attributeMainDiv}
                >
                  <Typography className={classes.attributeTitle}>
                    {a.name}
                  </Typography>
                  {a.values.map((v, index2) => (
                    <div
                      key={`atributes-values-id${index2}`}
                      className={classes.attributeValueDiv}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={v.checked}
                            onChange={() => {
                              setChecked(a.name, v.pav_valor);
                            }}
                            name="checkedA"
                          />
                        }
                        label={v.pav_valor}
                        className={classes.attributeValue}
                      />
                    </div>
                  ))}
                </div>
              ))}
              <div className={classes.btnAplicarFiltrosDiv}>
                <Button
                  variant="contained"
                  className={classes.btnAplicarFiltros}
                  disableElevation
                  onClick={() => {
                    aplicarFiltros();
                  }}
                >
                  Aplicar Filtros
                </Button>
              </div>
            </div>
          </div>
          <div className={classes.mainDiv}>
            {/*HEADER*/}

            <div className={classes.headerDiv}>
              <div className={classes.numProductsDiv}>
                <Typography className={classes.totalProdTxt}>
                  {skusToRender.length} de {totalSkus} productos
                </Typography>
                {/* <Typography className={classes.totalProdTxt}>{productDetails._currentValue.totalProductos} productos</Typography> */}
              </div>
              <div className={classes.titleSectionDiv}>
                {/* <Typography className={classes.wordSearchDiv}>{wordSearch}</Typography> */}
                <SectionTitle
                  category={productDetails._currentValue.products_show}
                />
              </div>
              <div className={classes.orderPagesizeDiv} style={{ paddingTop:10, width: isMobile?  '100%': '30%', textAlign: isMobile? 'center':''}}>
                <FormControl variant="outlined" className={classes.orderByForm}>
                  <InputLabel id="orderByLab">Ordenar por</InputLabel>
                  <Select
                    id="orderBySel"
                    value={orderBy}
                    onChange={(ev) => {
                      handleOrderBy(ev.target.value);
                    }}
                    label="Ordenar Por"
                  >
                    <MenuItem value={"az-za.asc"}>{'A->Z'}</MenuItem>
                    <MenuItem value={"az-za.desc"}>{'Z->A'}</MenuItem>
                    <MenuItem value={"precio.asc"}>Menor Precio</MenuItem>
                    <MenuItem value={"precio.desc"}>Mayor Precio</MenuItem>
                    <MenuItem value={"mas vendido.asc"}>Menos Vendido</MenuItem>
                    <MenuItem value={"mas vendido.desc"}>Más Vendido</MenuItem>
                    <MenuItem value={"mejores valorados.asc"}>
                      Menos Valorados
                    </MenuItem>
                    <MenuItem value={"mejores valorados.desc"}>
                      Mejores Valorados
                    </MenuItem>
                    <MenuItem value={"fecha lanzamienta.asc"}>
                      Más Nuevos
                    </MenuItem>
                    <MenuItem value={"fecha lanzamienta.desc"}>
                      Más Antiguos
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.perPageForm}>
                <InputLabel id="orderByLab">Mostrar de </InputLabel>
                  <Select
                    id="page-size"
                    value={orderQuantity}
                    onChange={(ev) => {
                      setOrderQuantity(ev.target.value);
                    }}
                  >
                    <MenuItem value={12}>12</MenuItem>
                    <MenuItem value={24}>24</MenuItem>
                    <MenuItem value={36}>36</MenuItem>
                    <MenuItem value={48}>48</MenuItem>
                  </Select>
                </FormControl>
                <ViewQuiltIcon
                  className={classes.gridIcon}
                  onClick={handleChangeGrid}
                />
              </div>
            </div>
            {/*GRID*/}
            {totalSkus != null && totalSkus == 0 ? (
              <div className={classes.divNoResults}>
                <Typography className={classes.textNoResults}>
                  No hay resultados para esta búsqueda
                </Typography>
              </div>
            ) : (
              skusToRender.length > 0 &&
              skusToRender.map((r, index) =>
                viewGrid === false
                  ? r && (
                      <CategoryProductCard
                        product={r}
                        key={index}
                        gridView={viewGrid}
                        userIsLogged={userIsLogged}
                      
                      />
                    )
                  : r && (
                      <CategoryProductSmall
                        product={r}
                        key={index}
                        gridView={viewGrid}
                        userIsLogged={userIsLogged}
                       
                      />
                    )
              )
            )}

            {tipoDePaginado === "partida" && (
              <div className={classes.boxPaginadoBtn}>
                <button
                  onClick={() => {
                    getProductosPartidaSiguiente();
                  }}
                >
                  Ver más productos
                </button>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
