import React, { useEffect, useState } from 'react';
import { ButtonBase, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Splide, SplideSlide  } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import './styleSplide.css';
import { makeStyles } from "@material-ui/core/styles";
import useCheckMobileScreen from 'components/Mobile/Mobile';

const useStyles = makeStyles((styles) => ({
  mainImg: {
    [styles.breakpoints.up("sm")]: {
      width: "100%",
    },
    [styles.breakpoints.down("sm")]: {
      height: "100%",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  sliders: {
    width: "100%",
    [styles.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  sideDiv: {
    display: "flex",
    verticalAlign: "top",
    ["@media (max-width: 959px)"]: {
      padding: 0,
      width: "100%",
    },
    ["@media (max-width: 768px)"]: {
      display: "none",
    },
  },
  imgSideDiv: {
    width: "100%",
    display: "block",
  },
  imgSide: {
    width: "100%",
  },
}));

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 340,
  // width: '100%',
  [theme.breakpoints.down('sm')]: {
    // width: '100% !important', // Overrides inline-style
    height: 200,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  //backgroundPosition: 'center 40%',
});

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  //backgroundColor: theme.palette.common.white,
  opacity: 1.0,
  transition: theme.transitions.create('opacity'),
  ":hover":{backgroundColor: theme.palette.common.black},
}));

const SectionHomeMainBannerNew = (props) => {
  const classes = useStyles();
  const isMobile = useCheckMobileScreen();
  const [mobileState, setMobileState] = useState(false);

  const goTo = (link) => {
    if (link) {
      window.location.href = link;
    }
  };

  useEffect(() => {
    if(isMobile) {
      setMobileState(true);
    } else {
      setMobileState(false);
    }
  }, [isMobile]);

  return (
    <Grid>
        {mobileState &&
        <Grid item xs={12} sx={{ padding: 0, margin: 0, height: '100%' }}>
          <Splide
            id="splide"
            aria-labelledby="autoplay-example-heading"
            hasTrack={ false }
            options={{
              rewind: true,
              perPage: 1,
              perMove: 1,
              padding: 0,
              arrows: true,
              gap: "0.75rem",
              fixedWidth: "100%",
              ficedHeight: "100%",
              type         : 'loop',
              gap          : '1rem',
              autoplay     : true,
              pauseOnHover : false,
              resetProgress: false,
            }}
          >
            {props.mainBanners.banners.map((slide) => (
              <SplideSlide
                key={slide.id}
              >
                <ImageButton
                  focusRipple
                  key={slide.id}
                  style={{
                    width: "100%",
                    // height: "100%",
                  }}
                  onClick={() => goTo(slide.link)}
                >
                  <ImageSrc style={{ backgroundImage: `url(${slide.img})` }} />
                  <ImageBackdrop className="MuiImageBackdrop-root" />
                </ImageButton>
              </SplideSlide>
            ))}
          </Splide>
        </Grid>}
        {!mobileState &&
        <Grid item xs={12} sx={{ padding: 0, margin: 0, height: '260px' }}>
          <Splide
            id="splide"
            aria-labelledby="autoplay-example-heading"
            hasTrack={ false }
            options={{
              rewind: true,
              perPage: 1,
              perMove: 1,
              padding: 0,
              arrows: true,
              gap: "0.75rem",
              fixedWidth: "100%",
              height: "260px",
              type         : 'loop',
              gap          : '1rem',
              autoplay     : true,
              pauseOnHover : false,
              resetProgress: false,
            }}
          >
            {props.mainBanners.banners.map((slide) => (
              <SplideSlide
                key={slide.id}
              >
                <ImageButton
                  focusRipple
                  key={slide.id}
                  style={{
                    width: "100%",
                    // height: "100%",
                  }}
                  onClick={() => goTo(slide.link)}
                >
                  <ImageSrc style={{ backgroundImage: `url(${slide.img})` }} />
                  <ImageBackdrop className="MuiImageBackdrop-root" />
                </ImageButton>
              </SplideSlide>
            ))}
          </Splide>
        </Grid>}
      <Grid item xs={12} sx={{ paddingTop: 2 }}>
        <div className={classes.sideDiv}>
          <div className={classes.imgSideDiv}>
            <img
              src={props.mainBanners.side1.img}
              alt="..."
              className={classes.imgSide}
              width="100%"
             // height= "235px"
              style={{
                borderRadius: '8px',
                cursor: !props.mainBanners.side1.link ? "default" : "pointer",
                
              }}
              onClick={() => goTo(props.mainBanners.side1.link)}
            />
          </div>
          <div className={classes.imgSideDiv}>
            <img
              src={props.mainBanners.side2.img}
              alt="..."
              className={classes.imgSide}
              width="100%"
              //height= "235px"
              style={{ marginLeft: 4, borderRadius: '8px' }}
              onClick={() => goTo(props.mainBanners.side2.link)}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default SectionHomeMainBannerNew;