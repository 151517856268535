import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Box, List, ListItem, ListItemButton, ListItemText, MenuItem, MenuList, Paper } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { makeStyles } from "@material-ui/core/styles";
import { accountService } from "services/account/account.service";
import { homeServices } from "_services/home.services";
import { useAuth } from "hooks/useAuth";
const useStyles = makeStyles((styles) => ({
    hover: {
        "&:hover": {
          backgroundColor: 'rgb(7, 177, 77, 0.42)'
        }
      }
}));

const CategoriesMenu = ({ anchoTotalGrid }) => {
    const classes = useStyles();
    const [categoriesData, setCategoriesData] = useState({
        categories: null,
        subcategories: null,
    });
    const [selectedItem, setSelectedItem] = useState(null);
    const {isLogged,getUserData } = useAuth();
    const dataUser = getUserData()
    const getCategories = async () => {
        const categories = await homeServices.getListadoCategorias();
        setCategoriesData(e => ({
            ...e, 
            categories: categories.tree.filter((item) => item.cat_cmm_estatus_id == 1000010)
        }));
    }

    const handleOpenMenu = (event, item, id) => {
        setSelectedItem(id);
    };

    const handleCloseMenu = (id) => {
        setSelectedItem(null);
    };
    

    useEffect(() => {
        getCategories();
      if(isLogged) accountService.addSessionCountTime({type: 2,userId: dataUser.usuario.snu_usuario_snu_id});
    }, [])

  return (
    <Box sx={{ width: '100%' }}>
      <nav aria-label="main" style={{marginLeft:'0px'}}>
        <List
            sx={{
                borderRadius: '10px 10px 10px 10px',
                border: '1px solid #CCCCCC',
                paddingTop: 0,
                paddingBottom: 0,
                background: '#fff'
            }}
        >
            {categoriesData.categories &&
            categoriesData.categories.map((item, i) => {
                return (
                    <React.Fragment key={i}>
                        <ListItem
                            disablePadding
                            onMouseEnter={(event) => handleOpenMenu(event, item.hijos, item.cat_categoria_id)}
                            onMouseLeave={() => handleCloseMenu(item.cat_categoria_id)}
                            sx={{
                                borderBottom: i < (categoriesData.categories.length - 1)
                                    ? '1px solid #CCCCCC'
                                    : '0px solid #CCCCCC',
                            }}
                        >
                            <ListItemButton
                                sx={{
                                    marginLeft: '3rem',
                                    borderRadius: '10px 0px 0px 10px',
                                    color: selectedItem === item.cat_categoria_id ? '#fff !important' : '#0b3196 !important',
                                    backgroundColor: selectedItem === item.cat_categoria_id ? '#0b3196' : 'transparent',
                                    '&:hover': {
                                        color: '#fff !important',
                                        backgroundColor: '#0b3196 !important',
                                    },
                                }}
                                component={Link}
                                to={`filters?category=${item.cat_nombre_tienda}&cat=${item.cat_categoria_id}&type=cat`}
                            >
                                <ListItemText
                                    primary={item.cat_nombre_tienda} style={{height:'27px', fontSize:'14px'}}
                                />
                                {item.hijos.length > 0 && <ArrowForwardIosIcon sx={{ color: '#40df00' }} />}
                            </ListItemButton>
                            {selectedItem === item.cat_categoria_id && 
                            <Paper
                                elevation={0}
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    //left: `${anchoTotalGrid}px`,
                                    backgroundColor: '#0b3196',
                                    color: '#fff !important',
                                    zIndex: 2,
                                     marginLeft:'250px'
                                }}
                            >
                                <MenuList  >
                                    {item.hijos?.map((row) =>
                                    row.cat_cmm_estatus_id == 1000010 &&
                                        <MenuItem
                                            key={row.cat_categoria_id}
                                            onClick={() => handleCloseMenu(item.cat_categoria_id)}
                                            component={Link}
                                            to={`filters?category=${item.cat_nombre_tienda}&cat=${item.cat_categoria_id}&sub=${row.cat_categoria_id}&subcategory=${row.cat_nombre_tienda}&type=subc`}
                                            sx={{
                                                color: '#fff !important',
                                                '&:hover': {
                                                    color: 'rgb(64, 223, 0) !important',
                                                    backgroundColor: '#0b3196 !important',
                                                    textDecoration:'underline'
                                                },
                                            }}
                                           
                                          
                                        >
                                            {row.cat_nombre_tienda}
                                        </MenuItem>
                                    )}
                                </MenuList>
                            </Paper>}
                        </ListItem>
                    </React.Fragment>
                )
            })}
        </List>
      </nav>
    </Box>
  )
}

export default CategoriesMenu