import { authHeader } from "../_helpers/auth-header.js";
import { API_URL } from "./api.url.back";

export const shoppingcartServices = {
  createCartSN,
  createCartNoSN,
  getCart,
  addProduct,
  deleteCart,
  deleteProductCart,
};

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    const responseData = {
      error: false,
      data: data,
    };
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

//
function createCartSN(idUser, idProd, amount) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_sn_socio_de_negocio_id: idUser, // opcional
      pcdc_prod_producto_id: idProd,
      pcdc_producto_cantidad: amount,
    }),
  };
  return fetch(`${API_URL}/carrito_de_compras/create`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      localStorage.setItem("shoppingcart", JSON.stringify(jsonData));
      return jsonData;
    });
}
//
function createCartNoSN(idProd, amount) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      pcdc_prod_producto_id: idProd,
      pcdc_producto_cantidad: amount,
    }),
  };
  return fetch(`${API_URL}/carrito_de_compras/create`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      localStorage.setItem("shoppingcart", JSON.stringify(jsonData));
      return jsonData;
    });
}
//
function getCart(idClient) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: {
      cdc_sn_socio_de_negocio_id: idClient
    }
  };
  return fetch(
    `${API_URL}/v2_carrito_compras/get_cart`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      //localStorage.setItem("shoppingcart", JSON.stringify(jsonData));
      return jsonData;
    });
}
//
function addProduct(idClient, idProduct, amount) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      cdc_sn_socio_de_negocio_id: idClient,
      prod_producto_id: idProduct,
      cantidad: amount,
    }),
  };
  return fetch(`${API_URL}/v2_carrito_compras/add_product_to_cart`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function deleteProductCart(idCart, idProduct) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      pcdc_carrito_de_compra_id: idCart,
      pcdc_prod_producto_id: idProduct,
    }),
  };

  return fetch(`${API_URL}/carrito_de_compras/delete_product`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function deleteCart(idCart) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      cdc_carrito_de_compra_id: idCart,
    }),
  };

  return fetch(`${API_URL}/carrito_de_compras/delete_carrito`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}
