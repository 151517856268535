import React, { useState } from "react";
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((styles) => ({
    figure: {
        maxHeight: "100%",
        backgroundRepeat: "no-repeat",
        margin: 0,
        display: "flex",
        justifyContent: "center",
        '&:hover': {
            '& img': {
                // border: '1px solid black',
                opacity: 0,
            }
        }
    },
    img: {
        display: "block",
        maxHeight: "400px",
        pointerEvents: "none",
    },
      
}));

const SectionProductCarouselImage = ({img}) => {
    const src = img.urlA;
    const [classes, setClasses] = useState();
    const clases = useStyles();

    // setClasses(useStyles);

    const handleMouseMove = (event) => {
        const { left, top, width, height } = event.target.getBoundingClientRect();
        const x = (event.pageX - left) / width * 100;
        const y = (event.pageY - top) / height * 100;
        setClasses({
            backgroundImage: `url(${src})`,
            backgroundPosition: `${x}% ${y}%` 
        });
    }

    const handleHoverOff = () => {
        setClasses({
            backgroundImage: `url()`,
            backgroundPosition: `0% 0%` 
        });
    }

    return (
        // <div id="img-zoomer-box">
        //     <img id="img-1" src={src} onMouseMove={(e) => handleMouseMove(e.target)} alt="Zoom Image on Mouseover"  />
        //     <div id="img-2"></div>
        // </div> 
        <figure key={img.id} 
                className={clases.figure} 
                onMouseMove={(e) => handleMouseMove(e)} 
                onMouseLeave={handleHoverOff}
                style={classes} 
        >
            <img className={clases.img} src={src} />
        </figure>
    );
}

export default SectionProductCarouselImage;