import { makeStyles } from "@material-ui/core/styles";

export const Styles = makeStyles((styles) => ({
    hide: {
      display: "none !important",
    },
    filtersName:{
      width: "100%",
      borderBottom: "2px solid #0b3196",
      paddingBottom: "10px",
      padding:"0px 5px 5px 10px"
    }
    ,
    divNoResults: {
      width: "100%",
      textAlign: "center",
      paddingTop: "50px",
      paddingBottom: "50px",
    },
    textNoResults: {
      fontSize: "14px",
      fontFamily: "'Centrale Sans Medium'",
    },
    mainBackground: {
      backgroundColor: "#FFF",
    },
    leftDiv: {
      width: "21%",
      marginLeft: "2%",
      display: "inline-block",
      verticalAlign: "top",
      paddingRight: "2%",
    },
    mainDiv: {
      backgroundColor: "#fff",
      /*width: "75%",*/
      width: "100%",
      display: "inline-block",
      verticalAlign: "top",
      paddingLeft: "2%",
      paddingRight: "2%",
    },
    headerDiv: {
      width: "100%",
      paddingBottom: "10px",
      borderBottom: "2px solid #0b3196",
    },
    numProductsDiv: {
      width: "30%",
      display: "inline-block",
      verticalAlign: "bottom",
      textAlign: "left",
    },
    totalProdTxt: {
      color: "#000000",
      fontSize: "12px",
      fontFamily: "'Centrale Sans Light'",
      fontWeight: "300",
    },
    titleSectionDiv: {
      width: "40%",
      display: "inline-block",
      verticalAlign: "bottom",
      textAlign: "center",
    },
    orderPageSizeDiv: {
      width: "30%",
      display: "inline-block",
      verticalAlign: "bottom",
      textAlign: "right",
    },
    filtersSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
    },
    customSelectOrder: {
      minWidth: "121px",
      height: "30px",
    },
    customSelectCount: {
      width: "73px",
      height: "30px",
    },
    customSelect: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      marginRight: "10px",
      background: "#F5F5F5",
      border: "1px solid #EAEAEA",
      borderRadius: "2px",
      color: "#0B3196",
      fontSize: "14px",
      fontFamily: "'Centrale Sans Regular'",
      padding: 8,
      "& select": {
        all: "unset",
        textAlign: "left",
        zIndex: 2,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        paddingTop: "3px",
        width: "100%",
      },
      "& img": {
        position: "absolute",
        right: 8,
      },
    },
    orderByForm: {
      marginRight: "15px",
      width: 121,
      height: 30,
      textAlign: "center",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: 14,
    },
    fs14: {
      fontFamily: "'Centrale Sans Medium'",
      fontSize: 14,
    },
    perPageForm: {
      marginRight: "15px",
      width: "20%",
      textAlign: "center",
    },
    /// Filters
    filtersMainDiv: {
      backgroundColor: "#f5f5f5",
      border: "1px solid #eaeaea",
      width: "100%",
      verticalAlign: "top",
    },
    filtersMainTitleDiv: {
      width: "90%",
      marginLeft: "5%",
      paddingTop: "5%",
      borderBottom: "2px solid #0b3196",
    },
    filtersMainTitleTxt: {
      fontSize: "24px",
      color: "#0b3196",
      fontFamily: "'Centrale Sans Medium'",
      fontWeight: "300",
    },
    attributeMainDiv: {
      width: "90%",
      marginLeft: "5%",
      marginRight: "5%",
      paddingTop: "15px",
      paddingBottom: "15px",
      marginBottom: "30px",
      borderBottom: "1px solid #eaeaea",
    },
    attributeTitle: {
      color: "#000000",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: "24px",
      fontWeight: "600",
    },
    attributeValueDiv: {
      width: "100%",
    },
    attributeValue: {
      color: "#0b3196",
      display: "inline",
      width: "100%",
    },
    btnAplicarFiltrosDiv: {
      width: "100%",
      paddingLeft: "5%",
      paddingRight: "5%",
      paddingBottom: "30px",
    },
    cursorPointer: {
      cursor: "pointer",
    },
    btnAplicarFiltros: {
      fontFamily: "'Centrale Sans Medium'",
      width: "100%",
      backgroundColor: "#FFFFFF",
      color: "#0b3196",
      border: "1px solid #0b3196",
      "&:hover": {
        color: "#FFFFFF",
        backgroundColor: "#0b3196",
      },
    },
    gridIcon: {
      color: "#0b3196",
      fontSize: "42px",
      "&:hover": {
        cursor: "pointer",
      },
      ["@media (max-width: 898.98px)"]: {
        // eslint-disable-line no-useless-computed-key
        display: "none",
      },
    },
    wordSearchDiv: {
      color: "#0b3196",
      fontFamily: "'Centrale Sans Bold'",
      fontSize: "24px",
      fontWeight: "bold",
      letterSpacing: "0",
      lineHeight: "29px",
      textAlign: "center",
    },
    pagination: {
      width: "100%",
    },
    divVerMas: {
      width: "100%",
      textAlign: "center",
      paddingTop: "65px",
      paddingBottom: "70px",
      paddingLeft: "calc(50% - 130px)",
    },
    btnVerMas: {
      paddingLeft: "52px",
      paddingRight: "52px",
      paddingTop: "12px",
      paddingBottom: "12px",
      border: "1px solid #0C2D9C",
      color: "#0C2D9C",
      borderRadius: "3px",
      width: "261px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#0C2D9C",
        color: "#FFFFFF",
      },
    },
    txtVerMas: {
      fontFamily: "'Centrale Sans Medium'",
      fontSize: "16px",
      fontWeight: "500",
      letterSpacing: "0",
      lineHeight: "19px",
      textAlign: "center",
    },
    btn: {
      width: "200px",
      backgroundColor: "#ffffff",
      color: "#0b3196",
      border: "2px solid #0b3196",
      fontWeight: "600",
      "&:hover": {
        backgroundColor: "#0b3196",
        color: "#ffffff",
      },
    },
    boxPaginadoBtn: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "25px",
      paddingBottom: "10px",
      "& button": {
        all: "unset",
        margin: "0",
        borderRadius: "7px",
        cursor: "pointer",
        height: "45px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0",
        fontSize: "16px",
        width: "261px",
        border: "1px solid #003773",
        color: "#003773",
        fontFamily: "'Centrale Sans Medium'",
      },
    },
  }));
  