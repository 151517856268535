import React, { useState, useEffect, useRef, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { userService } from "_services/user.service";
import CheckoutCheck1 from "./CheckoutCheck1";
import CheckoutShipping2 from "./CheckoutShipping2";
import CheckoutPayment3 from "./CheckoutPayment3";
import CheckoutOrder4 from "./CheckoutOrder4";
import CheckoutComplete5 from "./CheckoutComplete5";
import { checkoutService } from "_services/checkout.service";
import conektaHelper from "_helpers/conekta";
import "./Checkout.css";
import { useConfirm } from "material-ui-confirm";

import Loading from "utils/loading/Loading.js";

import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useAuth } from "hooks/useAuth";
import SectionBreadcrumb from "views/Home/Sections/SectionBreadcrumb.js";
import { addItemCartByIdProduct } from "utils/addCartBusinnesPartner";
import { isAccessor } from "typescript";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  flowButtons: {
    display: "flex",
    flexDirection: "column",
    width: 400,
    float: "right",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      float: "none",
    },
    "& button": {
      textTransform: "none",
      fontFamily: "'Centrale Sans Bold'",
      fontSize: 16,
      borderRadius: 2,
    },
  },
  linkBack: {
    fontFamily: "'Centrale Sans Medium'",
    color: "#0B3196",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "19px",
    textAlign: "center",
    paddingTop: 10,
    position: "relative",
    width: 120,
    margin: "auto",
    "&:visited, &:active": {
      color: "#0B3196",
    },
  },
  linkBackArrowColor: {
    color: "#0B3196",
    "&:visited, &:active": {
      color: "#0B3196",
    },
  },
  linkBackArrow: {
    position: "absolute",
    left: "-8px",
    bottom: "4px",
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  titleMobile: {
    fontFamily: "'Centrale Sans Bold'",
    fontSize: 24,
    lineHeight: "29px",
    textAlign: "center",
    color: "#0b3196",
    paddingTop: 20,
  },
  spaceDiv: {
    marginTop: 15,
    marginBottom: 15,
  },
  //alerts
  alerts: {
    color: "#FFFFFF",
    backgroundColor: "#40DF00",
    fontFamily: "'Centrale Sans Light'",
    fontSize: 22,
    fontWeight: "bold",
    "& .MuiAlert-icon": {
      color: "#FFFFFF",
    },
    "&.MuiAlert-standardError": {
      backgroundColor: "#DF2B2B",
    },
  },
  //btnquote
  btn: {
    width: "100%",
    backgroundColor: "#ffffff",
    color: "#0b3196",
    border: "2px solid #0b3196",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#0b3196",
      color: "#ffffff",
    },
  },
}));

function getSteps() {
  return [
    "Revisa tu carrito",
    "Datos de envio",
    "Forma de pago",
    "Confirmación del pedido",
  ];
}

function getStepContent(
  stepIndex,
  isMobile,
  history,
  user,
  cartCheckoutData,
  handleUpdateCartCheckoutData,
  getCartByClientId
) {
  switch (stepIndex) {
    case 0:
      return (
        <CheckoutCheck1
          isMobile={isMobile}
          history={history}
          user={user}
          cartCheckoutData={cartCheckoutData}
          handleUpdateCartCheckoutData={handleUpdateCartCheckoutData}
          getCartByClientId={getCartByClientId}
          stepNumber={stepIndex}
        />
      );
    case 1:
      return (
        <CheckoutShipping2
          isMobile={isMobile}
          history={history}
          user={user}
          cartCheckoutData={cartCheckoutData}
          handleUpdateCartCheckoutData={handleUpdateCartCheckoutData}
          getCartByClientId={getCartByClientId}
          stepNumber={stepIndex}
        />
      );
    case 2:
      return (
        <CheckoutPayment3
          isMobile={isMobile}
          history={history}
          user={user}
          cartCheckoutData={cartCheckoutData}
          handleUpdateCartCheckoutData={handleUpdateCartCheckoutData}
          getCartByClientId={getCartByClientId}
          stepNumber={stepIndex}
        />
      );
    case 3:
      return (
        <CheckoutOrder4
          isMobile={isMobile}
          history={history}
          user={user}
          cartCheckoutData={cartCheckoutData}
          handleUpdateCartCheckoutData={handleUpdateCartCheckoutData}
          getCartByClientId={getCartByClientId}
          stepNumber={stepIndex}
        />
      );
    default:
      return "Paso Desconocido";
  }
}

export default function CheckoutSteps(props) {
  const classes = useStyles();
  const confirm = useConfirm();
  const steps = getSteps();
  const history = useHistory();
  const { setOpenAlert, setCarritoItems, carritoItems, setDataQuote, dataQuote, isTransferPay, setIsTransferPay, isShippingHome, setIsCreditDielsa} = useAuth();
  const [activeStep, setActiveStep] = useState(0);
  
  const [loading, setLoading] = useState(false);
  const [dataProductsQ, setDataProductsQ]=useState([])
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [tipoCotizacion, setTipoCotizacion] = useState("cotizacion");
  const [userLogged, setUserLogged] = useState(false);
  const [accessCreateCotiz, setAccessCreateCotiz] = useState(false);
  const [posibleProyecto, setPosibleProyecto] = useState(false);
  const [nombreProyecto, setNombreProyecto] = useState("");
  const [openCotiza, setOpenCotiza] = useState(false);
  const [dataBreadCrumb, setDataBreadCrumb] = useState([]);
  const [showMessageWarning, setShowMessageWarning] = useState({
    productOnlyQuotation: false,
    productGreaterThanStock: false,
    productWithBackOrder: false
  });

  const [errorCart, setErrorCart] = useState(false);
  const [cartCheckoutData, setCartCheckoutData] = useState({
    cart_id: null,
    client_id: null,
    user_client_id: null,
    client_cardcode: null,
    client: {},
    cart: {},

    cart_subtotal: 0,
    cart_discount: 0,
    cart_subtotal_after_discount: 0,
    cart_shipping: 0,
    cart_iva_type: 0,
    cart_iva_ammount: 0,
    cart_total: 0,

    order_payment_method: null,
    order_payment_method_info: {},
    order_shipping_method: null,
    order_shipping_method_info: {},
    order_shipping_address: 0,
    order_shipping_address_info: {},
    order_warehouse: 0,
    order_warehouse_info: {},

    fletera: 0,
    order_payment_formas_sap: [],
    order_payment_metodos_sap: [],

    order_complete_id: null,
    coupon: null,
    referencia: null,
    token_card: null,

    cardnumber: null,
    cardname: null,
    cardexpmonth: null,
    cardexpyear: null,
    cardcvc: null,
  });
  const [orderId, setOrderId] = useState(null);

  const getCartByClientId = async () => {
    let client_id;
    if (props.user.usuario) {
      client_id = props.user.usuario.snu_sn_socio_de_negocio_id;
    } else {
      client_id = JSON.parse(localStorage.getItem("user")).usuario
        .snu_sn_socio_de_negocio_id;
    }
    const response = await checkoutService.getCartByClientIdCheckout(client_id);

    
    if (response.error) {
      setOpenAlert({
        message:
          "Hubo un problema al intentar consultar el carrito del cliente, inténtelo más tarde",
        type: "error",
        open: true,
      });
      setErrorCart(true);
      
      let newCartCheckoutData = { ...cartCheckoutData };
      newCartCheckoutData.cart_id = null;
      newCartCheckoutData.client_id = null;
      newCartCheckoutData.user_client_id = null;
      newCartCheckoutData.client_cardcode = null;
      newCartCheckoutData.client = {};
      newCartCheckoutData.cart = {};
      setCartCheckoutData({ ...newCartCheckoutData });
    } else {
      setErrorCart(false);
      setIsCreditDielsa(response.data.hasExternalProducts)
      let newCartCheckoutData = { ...cartCheckoutData };
      newCartCheckoutData.cart_id = JSON.parse(
        localStorage.getItem("cart")
      ).cdc_carrito_de_compra_id.cdc_carrito_de_compra_id;
      newCartCheckoutData.client_id = JSON.parse(
        localStorage.getItem("user")
      ).usuario.snu_sn_socio_de_negocio_id;
      newCartCheckoutData.user_client_id = JSON.parse(
        localStorage.getItem("user")
      ).usuario.snu_usuario_snu_id;
      newCartCheckoutData.client_cardcode = JSON.parse(
        localStorage.getItem("user")
      ).usuario.snu_cardcode;
      newCartCheckoutData.client = JSON.parse(
        localStorage.getItem("client")
      ).listaSnbyid[0];
      newCartCheckoutData.cart = JSON.parse(
        localStorage.getItem("cart")
      ).cdc_carrito_de_compra_id;
      newCartCheckoutData.referencia = JSON.parse(
        localStorage.getItem("cart")
      ).cdc_carrito_de_compra_id.cdc_referencia;

      // validate if cart has a coupon apply and in case is true then get coupon data to be show below form and prefill form
      if (
        JSON.parse(localStorage.getItem("cart")).cdc_carrito_de_compra_id
          .cdc_promcup_promociones_cupones_id !== null
      ) {
        JSON.parse(
          localStorage.getItem("cart")
        ).cdc_carrito_de_compra_id.productos.map((prod) => {
          if (prod.cupon.length > 0) {
            newCartCheckoutData.coupon = prod.cupon[0].promcup_cupon_codigo;
          }
        });
      }

      // subtotal with products with prices bases without discount
      let cart_subtotal = parseFloat(
        JSON.parse(localStorage.getItem("cart")).cdc_carrito_de_compra_id
          .precioTotal
      );
      newCartCheckoutData.cart_subtotal = cart_subtotal;

      // total discount ammount in products
      let cart_discount = parseFloat(
        JSON.parse(localStorage.getItem("cart")).cdc_carrito_de_compra_id
          .totalDescuentos
      );
      newCartCheckoutData.cart_discount = cart_discount;

      // subtotal after rest discount ammount
      let cart_subtotal_after_discount = parseFloat(
        JSON.parse(localStorage.getItem("cart")).cdc_carrito_de_compra_id
          .precioFinalTotal
      );
      newCartCheckoutData.cart_subtotal_after_discount = cart_subtotal_after_discount;

      // shipping cost
      let cart_shipping = JSON.parse(localStorage.getItem("cart"))
        .cdc_carrito_de_compra_id.cdc_costo_envio;
      newCartCheckoutData.cart_shipping = cart_shipping;

      // IVA type according to client if %8 or %16
      let cart_iva_type = JSON.parse(localStorage.getItem("cart"))
        .cdc_carrito_de_compra_id.tipoImpuesto;
      newCartCheckoutData.cart_iva_type = cart_iva_type;

      // IVA cost, the formula is the porcentage of cart_subtotal_after_discount (16% or 8%)
      let cart_iva_ammount = JSON.parse(localStorage.getItem("cart"))
        .cdc_carrito_de_compra_id.TotalImpuesto;
      newCartCheckoutData.cart_iva_ammount = cart_iva_ammount;

      let _subtotalsSplit = await checkoutService.getCartCurrencySubtotals(
        client_id
      );
      newCartCheckoutData.subtotalsSplit = _subtotalsSplit.data;
      let _p = null;

      // total = (subtotal - discount) + IVA + shipping
      let cart_total = newCartCheckoutData.subtotalsSplit.resume_MXN.TotalFinal;
      newCartCheckoutData.cart_total = cart_total;

      newCartCheckoutData.cart.productos = newCartCheckoutData.cart.productos.filter((item) => {
        item.producto.prod_precio = (item.projectProduct && (item.projectProductPrice < item.producto.prod_precio|| item.producto.prod_precio===0)?
        item.projectProductPrice : item.producto.prod_precio
        )
        if((item.producto.prod_precio === 0
          || item.producto.prod_volumen === 0
          || item.producto.prod_peso === 0) && activeStep < 2) {
          setShowMessageWarning((e) => ({...e, productOnlyQuotation: true}));
        }
        if((item.producto.prod_precio > 0
          || item.producto.prod_volumen > 0
          || item.producto.prod_peso > 0) && item.pcdc_producto_cantidad > item.prod_total_stock) {
          setShowMessageWarning((e) => ({...e, productGreaterThanStock: true}));
        }
        if((item.producto.prod_precio > 0
          || item.producto.prod_volumen > 0
          || item.producto.prod_peso > 0)
          && item.pcdc_producto_cantidad > item.prod_total_stock && item.aplicaBackOrder) {
            setShowMessageWarning((e) => ({...e, productWithBackOrder: true}));
        }
        return item.producto.prod_precio > 0
          && item.producto.prod_volumen > 0
          && item.producto.prod_peso > 0
      });

      newCartCheckoutData.cart.productos = newCartCheckoutData.cart.productos.map((item) => {
        let cantidad = 0;
        if (item.aplicaBackOrder) {
          cantidad = item.pcdc_producto_cantidad;
        } else {
          if (item.pcdc_producto_cantidad > item.prod_total_stock) {
              cantidad = item.prod_total_stock;
            } else {
              cantidad = item.pcdc_producto_cantidad;
            }
        }
        return {...item, pcdc_producto_cantidad: cantidad,
          totalCantidadReal: item.pcdc_producto_cantidad };
      });

      if (
        newCartCheckoutData.cart.cdc_cmm_tipo_envio_id !== undefined &&
        newCartCheckoutData.cart.cdc_cmm_tipo_envio_id !== null
      ) {
        let _prodsSplit = await checkoutService.getCartSplitProductsByClientIdCheckout(
          client_id
        );
        _p = _prodsSplit.data.getPreOrdenDividida;
      }

      let _productosSplit =
        _p !== null
          ? matchProductsData(newCartCheckoutData.cart.productos, _p)
          : newCartCheckoutData.cart.productos;
      newCartCheckoutData.productsSplit = _productosSplit;
      setDataProductsQ(newCartCheckoutData.cart.productos)
      setCartCheckoutData(newCartCheckoutData);
    }
  };

  const convertDate = (date) => {
    let _d = date.split("/");
    let _m = "";
    switch (_d[1]) {
      case "01":
      case "1":
        _m = "Enero";
        break;
      case "02":
      case "2":
        _m = "Febrero";
        break;
      case "03":
      case "3":
        _m = "Marzo";
        break;
      case "04":
      case "4":
        _m = "Abril";
        break;
      case "05":
      case "5":
        _m = "Mayo";
        break;
      case "06":
      case "6":
        _m = "Junio";
        break;
      case "07":
      case "7":
        _m = "Julio";
        break;
      case "08":
      case "8":
        _m = "Agosto";
        break;
      case "09":
      case "9":
        _m = "Septiembre";
        break;
      case "10":
        _m = "Octubre";
        break;
      case "11":
        _m = "Noviembre";
        break;
      case "12":
        _m = "Diciembre";
        break;
    }
    return _d[2] + "/" + _m + "/" + _d[0];
  };

  useEffect(() => {
    setDataBreadCrumb(() => [
      {
        name: "INICIO",
        link: "/",
      },
      {
        name: "CARRITO",
        link: "",
      }
    ]);
  }, []);

  const matchProductsData = (origin, prod) => {
    let _prod = [];
    for (let i = 0; i < prod.length; i++) {
      for (let j = 0; j < origin.length; j++) {
        if (prod[i].pcf_prod_producto_id == origin[j].pcdc_prod_producto_id) {
          let _p = prod[i];
          _p.fechaEntrega = convertDate(prod[i].dateFinal);
          _p.detalle = origin[j];
          _prod.push(_p);
          break;
        }
      }
    }
    return _prod;
  };

  useEffect(() => {
    getCartByClientId();
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.conekta.io/js/latest/conekta.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const successHandler = async (token) => {
    setActiveStep(3); //put in last step checkout before complete
    await handleUpdateCartCheckoutData("token_card", token);
  };

  const errorHandler = async (err) => {
    setActiveStep(2); //put in payment step
    if (err.object === "error") {
      setOpenAlert({
        message: err.message_to_purchaser,
        type: "error",
        open: true,
      });
    }
    await handleUpdateCartCheckoutData("token_card", null);
  };

  const tokenizar = async () => {
    await conektaHelper.initConekta();
    conektaHelper.tokenize(
      cartCheckoutData.cardnumber,
      cartCheckoutData.cardname,
      cartCheckoutData.cardexpmonth,
      cartCheckoutData.cardexpyear,
      cartCheckoutData.cardcvc,
      await successHandler,
      await errorHandler
    );
  };

  const successHandlerPayment = async (token) => {
    setActiveStep(3); //put in last step checkout before complete
    handleUpdateCartCheckoutData("token_card", token);

    paymentConekta(token);
  };

  const tokenizarBeforePayment = async () => {
    await conektaHelper.initConekta();
    const tokenizar_response = await conektaHelper.tokenize(
      cartCheckoutData.cardnumber,
      cartCheckoutData.cardname,
      cartCheckoutData.cardexpmonth,
      cartCheckoutData.cardexpyear,
      cartCheckoutData.cardcvc,
      successHandlerPayment,
      errorHandler
    );
  };
  const handleUpdateCartCheckoutData = (name, value) => {
    //let newCartCheckoutData = { ...cartCheckoutData };
    let newCartCheckoutData = cartCheckoutData;
    newCartCheckoutData[name] = value;
    //setCartCheckoutData({ ...newCartCheckoutData });
    setCartCheckoutData(newCartCheckoutData);
  };

  const handleNext = () => {
    //refresh call cart in every step back and forward in the navigation in checkout
    if (activeStep < 3) {
      getCartByClientId(); 

    }

    setOpenAlert({ message: "", type: "", open: false });

    let passValidation = true;

    if (activeStep === 0) {
      //validate general data 1 step shipping method
    } else if (activeStep === 1) {
      // validate where data 2 step
      
      if (
        cartCheckoutData.order_shipping_method === 0 ||
        cartCheckoutData.order_shipping_method === null
      ) {
        setOpenAlert({
          type: "error",
          message: "Debe seleccionar un método de envío",
          open: true,
        });
        passValidation = false;
      } else if (
        cartCheckoutData.order_shipping_method == 16 &&
        (cartCheckoutData.order_shipping_address === 0 ||
          cartCheckoutData.order_shipping_address === null)
      ) {
        setOpenAlert({
          type: "error",
          message:
            "Debe seleccionar un domicilio de envío o agregar uno nuevo y seleccionarlo",
          open: true,
        });
        passValidation = false;
      } else if (
        cartCheckoutData.order_shipping_method == 16 &&
        (cartCheckoutData.fletera === 0 || cartCheckoutData.fletera === null || isShippingHome)
      ) {
        setOpenAlert({
          type: "error",
          message:
            "Seleccione otro domicilio o verifique que los datos de la misma no se encuentren incorrectos o incompletos.",
          open: true,
        });
        passValidation = false;
      } else if (
        cartCheckoutData.order_shipping_method == 17 &&
        (cartCheckoutData.order_warehouse === 0 ||
          cartCheckoutData.order_warehouse === null)
      ) {
        setOpenAlert({
          type: "error",
          message: "Debe seleccionar un almacén donde recoger su pedido",
          open: true,
        });
        passValidation = false;
      }
    } else if (activeStep === 2) {
      // validate where data 3 step
      if (
        cartCheckoutData.order_payment_method === 0 ||
        cartCheckoutData.order_payment_method === null
        
      ) {
        setOpenAlert({
          type: "error",
          message: "Debe seleccionar un método de pago",
          open: true,
        });
        passValidation = false;
      } else if (
        cartCheckoutData.cart.cdc_cfdi === "" ||
        cartCheckoutData.cart.cdc_cfdi === "0" ||
        cartCheckoutData.cart.cdc_cfdi === 0 ||
        cartCheckoutData.cart.cdc_cfdi === null
      ) {
        setOpenAlert({
          type: "error",
          message: "Debe seleccionar un tipo de CFDI",
          open: true,
        });
        passValidation = false;
      } else if (
        cartCheckoutData.order_payment_method_info.sfp_clave === "04" &&
        (cartCheckoutData.token_card === "" ||
          cartCheckoutData.token_card === null)
      ) {
        /* setOpenAlert({
          type: "error",
          message: "Debe validar la tarjeta",
          open: true,
        }); */
        tokenizar();
        passValidation = false;
      }else if(cartCheckoutData.order_payment_method==27){
        if(!isTransferPay){
        setOpenAlert({
          type: "error",
          message: "Debe cargar el comprobante de la transferencia",
          open: true,
        });
        passValidation = false;
      
      }

      }
      //window.alert(JSON.stringify(cartCheckoutData.order_payment_method ))
    }

    if (passValidation) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // if is the last step and press next button to complete order and all validation is correct then submit Order
    if (passValidation && activeStep === 3) {
      prevalidateCart();
    }
  };

  const handleBack = () => {
    getCartByClientId();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const prevalidateCart = async () => {
    //Validar pedido en syscom.

    
    setLoading(true);
    //still in last step, dont put in 4 until complete propperly all actions
    setActiveStep(3);

    const response = await checkoutService.prevalidateCart(
      cartCheckoutData.client_id
    );

    if (response.error) {
      setLoading(false);

      setOpenAlert({
        message: `Hubo un error al intentar insertar el pedido. Contácte al administrador para más detalles. ${response.data.message}`,
        type: "error",
        open: true,
      });
    } else {
      if (cartCheckoutData.order_payment_method_info.sfp_clave == "04") {
        tokenizarBeforePayment();
        //paymentConekta();
      } else {
        completeCart();
      }
    }
  };

  const paymentConekta = async (token) => { 
    //logos dielsa
    const response = await checkoutService.conectaTokenSend(
      token.id,
      cartCheckoutData.client_id,
      cartCheckoutData.user_client_id
    );

    if (response.error) {
      setLoading(false);
      setActiveStep(2);
      setOpenAlert({
        message:
          "Hubo un error con los datos de la tarjeta insertado y no se pudo efectuar el pago. Verifique sus datos y pruebe nuevamente.",
        type: "error",
        open: true,
      }); 
      handleUpdateCartCheckoutData("token_card", null);
    } else {
      if (response.data.err) {
        setLoading(false);
        setActiveStep(2);
        setOpenAlert({
          message: `${response.data.err} Seleccione otro método de pago o pruebe nuevamente con otra tarjeta.`,
          type: "error",
          open: true,
        });
        handleUpdateCartCheckoutData("token_card", null);
      } else if (response.data.result === "paid") {
        completeCart();
      }
    }
  };

  const completeCart = async () => {
    const response = await checkoutService.completeOrderCheckout(
      cartCheckoutData.client_id,
      cartCheckoutData.user_client_id
    );

    if (response.error) {
      setLoading(false);

      setOpenAlert({
        message:
          "Hubo un error al intentar insertar el pedido. Contácte a su vendedor para más detalles.",
        type: "error",
        open: true,
      });
    } else {
      setLoading(false);

      setOrderId(response.data.cf_compra_finalizada_id);
      setActiveStep(4);
      setCarritoItems(0);


     
      if(dataQuote.idCotizacion !=0){
        let newProductosCotizacion = []

       // window.alert(JSON.stringify(dataProductsQ))
        //setDataProductsQ

       // pcdc_prod_producto_id
       const cartBackup = JSON.parse( localStorage.getItem("cartBackup"));
       cartBackup.productos.map((item)=>{
          //let isAgregarProd= dataProductsQ.filter((itemx) => itemx.pcdc_prod_producto_id==item.pcdc_prod_producto_id)

          item.producto.prod_precio = (item.projectProduct && (item.projectProductPrice < item.producto.prod_precio|| item.producto.prod_precio===0)?
      item.projectProductPrice : item.producto.prod_precio)
      if((!item.aplicaBackOrder && item.pcdc_producto_cantidad > item.prod_total_stock)
      || (item.producto.prod_precio === 0
      || item.producto.prod_volumen === 0
      || item.producto.prod_peso === 0)) {
       
      }else{
        newProductosCotizacion.push({idProducto: item.pcdc_prod_producto_id})
      }
          
        })
      
        const body = 
        {
          sn_socios_negocio_id:dataQuote.idSocioNegocio,
          idCotizacion:dataQuote.idCotizacion,
          productos: newProductosCotizacion,
          idCompra:response.data.cf_compra_finalizada_id
         }
      const resulJson = await userService.finishCotizacionCart(body);

      if(resulJson.status ==="success"){
       
        setDataQuote({
          idCotizacion:0,
          idSocioNegocio:0,
          productos:[]
        })
      }

      }else{
      
       const respondRestore = await restoreCartBackup();

       localStorage.removeItem("cartBackup");
       if(respondRestore) {
         setTimeout(() => {
           history.push('/quotes/request');
         }, 4000);
       }
      }
      

      if (cartCheckoutData.order_payment_method_info.sfp_clave == "03") {
        setOpenAlert({
          message:
            "¡Listo, tu pedido será confirmado una vez confirmado tu pago mediante la transferencia realizada!",
          type: "success",
          open: true,
        });
        setIsTransferPay(false)
        
      } else {
        setOpenAlert({
          message: "¡Listo, tu pedido ha sido confirmado!",
          type: "success",
          open: true,
        });
      }

     
    }
  };
  
  const restoreCartBackup = async () => {
    let respondData = false;
    let countProduct = 0;
    const cartBackup = JSON.parse(
      localStorage.getItem("cartBackup")
    );
    await cartBackup.productos.map( async (item, index) => {
      console.log(item)
      item.producto.prod_precio = (item.projectProduct && (item.projectProductPrice < item.producto.prod_precio|| item.producto.prod_precio===0)?
      item.projectProductPrice : item.producto.prod_precio)
      if((!item.aplicaBackOrder && item.pcdc_producto_cantidad > item.prod_total_stock)
      || (item.producto.prod_precio === 0
      || item.producto.prod_volumen === 0
      || item.producto.prod_peso === 0)) {
        respondData = true;
        countProduct = countProduct + 1;
        await addItemCartByIdProduct(cartBackup.cdc_sn_socio_de_negocio_id,
          item.pcdc_prod_producto_id, (item.pcdc_producto_cantidad > item.prod_total_stock)
            ? item.pcdc_producto_cantidad - item.prod_total_stock
            : item.pcdc_producto_cantidad)
          .then(() => {
          });
        }
      });
      setCarritoItems(countProduct);
    return respondData;
  }

  const validPriceStockCart = () =>{
    let hideCheckoutBtn = false;
    const cart = JSON.parse(localStorage.getItem('cart'));
    if(cart !== null && cart !== undefined && cart.cdc_carrito_de_compra_id.productos !== undefined){
      console.log('Entré x2!');
     cart.cdc_carrito_de_compra_id.productos.map((prod)=>{
      console.log('Entré x3!');
       if((prod.prod_total_stock === 0 && prod.aplicaBackOrder === true) || (prod.prod_precio === 0 )){
         hideCheckoutBtn = true;
         console.log(hideCheckoutBtn);
         return
       }
     })
    }
    return hideCheckoutBtn;
   }

  return (
    <div className={classes.root}>
      <div className={classes.spaceDiv}>
        <SectionBreadcrumb route={dataBreadCrumb} />
        <Typography className={classes.titleMobile}>
          {activeStep === 0 ? "Resumen de tu carrito" : ""}
          {activeStep === 1 ? "Dirección de envío" : ""}
          {activeStep === 2 ? "Método de pago" : ""}
          {activeStep === 3 ? "Confirmar pedido" : ""}
        </Typography>
      </div>
      {(showMessageWarning.productOnlyQuotation || showMessageWarning.productGreaterThanStock) && (
      <div className={classes.spaceDiv}>
        <Alert severity="info">
          {showMessageWarning.productOnlyQuotation && ("- Los productos que no se muestran a continuación solo estarán disponible para Cotización.")}
          {(showMessageWarning.productOnlyQuotation && showMessageWarning.productGreaterThanStock) && (<br/>) }
          {showMessageWarning.productGreaterThanStock && ("- Algunos productos solo se prodrá comprar la cantidad disponible del stock, el restante se quedará en carrito para que puedas Cotizar.")}
          {(showMessageWarning.productOnlyQuotation || showMessageWarning.productGreaterThanStock) && (<br/>) }
          {showMessageWarning.productWithBackOrder && ("- Algunos productos están disponible para pedir más productos de los que se encuentran en stock.")}
        </Alert>
      </div>
      )}

      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

 

      <Loading loading={loading} />

      <div>
        {activeStep === steps.length ? (
          <div>
            {/* after complete all the steps show the success message and the order resume */}
            <CheckoutComplete5
              cartCheckoutData={cartCheckoutData}
              isMobile={props.isMobile}
              orderId={orderId}
            />
            <div className={classes.flowButtons}>
              <Link 
                onClick={()=>{
                  window.location.href='/';
                
                }}
              >
                <span className={classes.linkBackArrowColor}>
                  <Button
                    variant="contained"
                    color="primary"
                    /* onClick={() => props.props.history.push("/search?search=")} */
                  >
                    Realizar una nueva compra
                  </Button>
                </span>
              </Link>
            </div>
          </div>
        ) : (
          <div>
            <div className={classes.instructions}>
              {cartCheckoutData.cart_id !== null &&
                getStepContent(
                  activeStep,
                  props.isMobile,
                  props.props.history,
                  props.user,
                  cartCheckoutData,
                  handleUpdateCartCheckoutData,
                  getCartByClientId
                )}
            </div>

            {!errorCart &&
            cartCheckoutData.cart.productos &&
            cartCheckoutData.cart.productos.length > 0 ? (
              <div className={classes.flowButtons}>
                {/* {validPriceStockCart() === true ? (
                  <div className={classes.flowButtons}>
                    <Button
                      variant="contained"
                      style={{marginTop: 15}}
                      className={classes.btn}
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href='/quotes/request';
                      }}
                    >
                      Solicitar cotización
                    </Button>
                    <Button
                      type="button"
                      disabled={activeStep === 0}
                      onClick={() => {
                        handleBack();
                        window.scrollTo(0, 0);
                      }}
                      className="btnOutline"
                    >
                      <ChevronLeftIcon className={classes.linkBackArrow} /> Regresar
                    </Button>
                  </div>
                ):(
                  <div className={classes.flowButtons}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleNext();
                        window.scrollTo(0, 0);
                      }}
                    >
                      {activeStep === 0 && "Continuar con la compra"}
                      {activeStep === 1 && "Ir a forma de pago"}
                      {activeStep === 2 && "Confirmar datos de pago"}
                      {activeStep === 3 && "Finalizar compra"}
                      {activeStep === 4 && "Realizar una nueva compra"}
                    </Button>
                    <Button
                      variant="contained"
                      style={{marginTop: 15}}
                      className={classes.btn}
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href='/quotes/request';
                      }}
                    >
                      Solicitar cotización
                    </Button>
                    <Button
                      type="button"
                      disabled={activeStep === 0}
                      onClick={() => {
                        handleBack();
                        window.scrollTo(0, 0);
                      }}
                      className="btnOutline"
                    >
                      <ChevronLeftIcon className={classes.linkBackArrow} /> Regresar
                    </Button>
                  </div>
                )} */}
                <div className={classes.flowButtons}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleNext();
                        window.scrollTo(0, 0);
                      }}
                    >
                      {activeStep === 0 && "Continuar con la compra"}
                      {activeStep === 1 && "Ir a forma de pago"}
                      {activeStep === 2 && "Confirmar datos de pago"}
                      {activeStep === 3 && "Finalizar compra"}
                      {activeStep === 4 && "Realizar una nueva compra"}
                    </Button>
                    <Button
                      variant="contained"
                      style={{marginTop: 15}}
                      className={classes.btn}
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href='/quotes/request';
                      }}
                    >
                      Solicitar cotización
                    </Button>
                    <Button
                      type="button"
                      disabled={activeStep === 0}
                      onClick={() => {
                        handleBack();
                        window.scrollTo(0, 0);
                      }}
                      className="btnOutline"
                    >
                      <ChevronLeftIcon className={classes.linkBackArrow} /> Regresar
                    </Button>
                  </div>
              </div>
            ) : (
              <div className={classes.flowButtons}>
                <Link to={`/search?search=`}>
                  <Button className={classes.linkBack}>
                    <span className={classes.linkBackArrowColor}>
                      <ChevronLeftIcon className={classes.linkBackArrow} /> Ir
                      al catálogo
                    </span>
                  </Button>
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
