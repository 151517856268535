import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "hooks/useAuth";
import { getPriceSapFormat } from "helpers/formatPrice";
const useStyles = makeStyles(() => ({
  skuListPrice: {
    textDecoration: "line-through",
    textAlign: "left",
    height: "20px",
    fontFamily: "'Centrale Sans Regular'",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "#767676",
  },
  skuFinalPrice: {
    textAlign: "left",
    height: "25px",
    color: "#0C2D9C",
    fontSize: "24px",
    fontFamily: "Centrale Sans Bold",
    fontWeight: "bold",
    lineHeight: "29px",
    letterSpacing: "0",
    position: "absolute",
    bottom: "0px",
  },
  txtPrecioEspecial: {
    fontSize: 12,
    fontFamily: "'Centrale Sans Regular'",
    color: "#767676",
  },
  divPrices: {
    height: "50px",
    position: "relative",
  },
  typeGridView: {
    marginTop: "40px",
  },
}));

export default function SectionProductPrice(props) {
  const classes = useStyles();
  const { isDollar} = useAuth();


    return (
      <div
        className={
          classes.divPrices + " " + (props.gridView && classes.typeGridView)
        }
      >
        {props.productDetails.externalProductId != null ? "":
        <div className={classes.skuListPrice} style={{visibility: (props.productDetails.precioListaDls ===0?'hidden': '')}}>
          {getPriceSapFormat(isDollar ? props.productDetails.precioListaDls : props.productDetails.precioLista)} ({isDollar ? 'USD': 'MXN' })
        </div>
        
        }
        <div className={classes.skuFinalPrice} style={{visibility: (props.productDetails.precioFinalDls ===0?'hidden': '')}}>
          {getPriceSapFormat(isDollar ?  props.productDetails.precioFinalDls :  props.productDetails.precioFinal)} 
          {props.productDetails.prod_tipo_precio_base === "Stock Inactivo" ? (
            <span className={classes.txtPrecioEspecial}>Precio Especial ({isDollar ? 'USD': 'MXN' })</span>
          ) : (
            <span className={classes.txtPrecioEspecial}> Precio Unitario ({isDollar ? 'USD': 'MXN' }) </span>
          )}
        </div>
      </div> 
    );
 
}
