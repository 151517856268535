import React, { useState, useEffect, useContext, Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components

import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarHalfIcon from '@material-ui/icons/StarHalf';


const useStyles = makeStyles((styles) => ({
    iconColor: {
        color: "#ffe720",
        fontSize: 18,
        [styles.breakpoints.down("sm")]: {
            fontSize: 18,
        },
    },
    space: {
        width: "100%",
        display: "inline-block",
        textAlign: "left",
        [styles.breakpoints.down("sm")]: {
            width: "30%",
            display: "inline-block",
            verticalAlign: "top",
            textAlign: "right",
        },
    }
}));

export default function SectionProductClientRating(props) {
    const classes = useStyles();
    const [rateStars, setRateStars] = useState(null);
    useEffect(() => {
        setStars();
    }, []);
    const setStars = () => {
        let stars = [];
        for (let i = 1; i <= Math.floor(props.productDetails.rating); i++) {
            stars.push(<StarIcon className={classes.iconColor} />);
        }
        if (props.productDetails.rating > Math.floor(props.productDetails.rating)) {
            stars.push(<StarHalfIcon className={classes.iconColor} />);
        }
        for (let i = Math.ceil(props.productDetails.rating); i < 5; i++) {
            stars.push(<StarBorderIcon className={classes.iconColor} />);
        }
        setRateStars(stars);
    }
    return (
        <div className={classes.space}>
            <div className={classes.container}>
                {rateStars}
            </div>
        </div>
    );
}
