import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((styles) => ({
    root: {
      flexGrow: 1,
      zIndex: 1,
      //overflow: "hidden",
      position: "relative",
      display: "flex",
      
    },
    hide: {
      display: "none !important",
    },
    container: {
      flex: 1,
      width: "100%",
      height: "128px",
      position: "fixed",
      backgroundColor: "#fff",
      zIndex: "1000",
      top: 0,
    },
    /// Main div
    mainDiv: {
      width: "100%",
      height: "50px",
    },
    /// Logo
    logoDiv: {
      // width: "27%",
      display: "inline-block",
      verticalAlign: "middle",
      paddingTop: "35px",
      textAlign: "center",
    },
    headerImgLogo: {
      height: "30px",
      width: "85px",
    },
    /// Search Input
    searchDiv: {
      width: "100%",
      display: "inline-block",
      verticalAlign: "top",
      paddingLeft: "12px",
      paddingRight: "13px",
     // marginTop: "14px",
      padding:"8px",
      backgroundColor:"#ffff"
    },
    inputSearchDiv: {
      width: "100%",
      display: "inline-block",
      "& .css-10ceblr-MuiStack-root":{
        width: "100%"
      },
      "& .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root":{
        padding: "0px 5px !important"
      },
      "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root":{
        marginTop: "-6px !important",
        color: "#0B3196",
        fontWeight:400,
      },
      "& .MuiAutocomplete-root":{
        backgroundColor: "#F2F2F2",
      },
      
    },
    
    inputSearch: {
      display: "inline-block",
      verticalAlign: "top",
     
      opacity: "0.58",
      color: "#0B3196",
      fontFamily: "'Centrale Sans Regular'",
      fontSize: "14px",
      letterSpacing: "0",
      lineHeight: "7px",
      textAlign: "justify",
    },
    imgLupa: {
      width: "18.86px",
      height: "18.78px",
      filter:
        "brightness(0) invert(17%) sepia(70%) saturate(3459%) hue-rotate(220deg) brightness(73%) contrast(99%)",
    },
    /// Links
    linksDivLeft: {
      // width: "33%",
      display: "inline-block",
      verticalAlign: "top",
    },
    linksDivRight: {
      width: "40%",
      display: "inline-block",
      verticalAlign: "top",
      textAlign: "right",
    },
    linksDivRight3: {
      //width: "40%",
      display: "inline-block",
      verticalAlign: "top",
      textAlign: "right",
    },
    linksDivRight2: {
      //width: "40%",
      display: "inline-block",
      verticalAlign: "top",
     // textAlign: "right",
    },
    headerCustomIcon: {
      filter:
        "brightness(0) invert(17%) sepia(70%) saturate(3459%) hue-rotate(220deg) brightness(73%) contrast(99%)",
      width: "27px",
    },
    headerCustomIconCart: {
      filter:
        "brightness(0) invert(17%) sepia(70%) saturate(3459%) hue-rotate(220deg) brightness(73%) contrast(99%)",
      width: "30px",
    },
    truenoCusomIcon: {
      width: "38px",
      height: "38px",
    },
    miCuentaDiv: {
      // marginLeft: "21px",
      // marginTop: "33px",
      display: "inline-block",
      verticalAlign: "middle",
      // marginTop: '43px',
      marginLeft: '20px',
    },
    miCuentaDiv2: {
      // marginLeft: "21px",
      // marginTop: "33px",
      display: "inline-block",
      verticalAlign: "middle",
      marginTop: '43px',
     // marginLeft: '20px',
    },
    miCuentaDiv4: {
      // marginLeft: "21px",
      // marginTop: "33px",
      display: "inline-block",
      verticalAlign: "middle",
      marginTop: '43px',
      marginLeft: '10px',
    },
    carritoDiv: {
      position: "relative",
      // marginLeft: "8px",
      marginRight: "10px",
      marginTop: "40px",
      display: "inline-block",
      verticalAlign: "bottom",
      "&:hover": {
        cursor: "pointer",
      },
    },
    carritoDiv2: {
      position: "relative",
      // marginLeft: "15px",
      marginRight: "10px",
      marginTop: "40px",
      display: "inline-block",
      verticalAlign: "bottom",
      "&:hover": {
        cursor: "pointer",
      },
    },
    cartBadge: {
      position: "absolute",
      right: "0px",
      top: "-8px",
      width: "16px",
      height: "16px",
      backgroundColor: "#40DF00",
      borderRadius: "50%",
      fontWeight: "600",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1,
      "& span": {
        color: "#fff",
        fontSize: "12px",
      },
    },
    menuDiv: {
      marginLeft: "12px",
      marginTop: "43px",
      display: "inline-block",
      verticalAlign: "middle",
    },
    /// Menu
    normalIcon: {
      color: "#0b3196",
      // width: "20px",
      // height: "16px",
    },
    menuMob: {
      //position: "fixed",
      width: "310px",
      backgroundColor: "#0b3196",
      height: "100vh",
      top: 0,
      //zIndex: 1002,
      overflowY:'scroll',
      //flexGrow: 1,
      zIndex: 1,
      //overflow: "hidden",
      position: "relative",
     // display: "flex",
  
      //overflow:'hidden'
      
    },
  
    closeDiv: {
      width: "100%",
      paddingRight: "8px",
      paddingTop: "8px",
      textAlign: "right",
    },
    closeMenu: {
      color: "#ffffff",
      fontFamily: "'Centrale Sans Regular' !important",
      fontSize: "14px !important",
      letterSpacing: "0",
      lineHeight: "18px !important",
      display: "inline-block",
      marginRight: "9px !important",
    },
    closeX: {
      color: "#ffffff",
      fontFamily: "'Centrale Sans Regular' !important  ",
      fontSize: "14px !important",
      letterSpacing: "0 !important",
      lineHeight: "18px !important ",
      display: "inline-block",
    },
    imgLogoMenuDiv: {
      width: "100%",
      textAlign: "center",
      marginTop: "20px",
      marginBottom: "55px",
    },
    imgLogoMenu: {
      height: "30px",
      width: "86px",
    },
    mainMenuDiv: {
      width: "100%",
      borderTop: "1px solid #ffffff",
     
    },
    currencyDiv: {
      // width: '25%',
      display: 'inline-block',
      // marginTop: '28px',
      marginTop: '43px'
      // textAlign: 'right'
    },
    innerMenuDiv: {
      borderBottom: "1px solid #ffffff",
      width: "100%",
      overflow: "hidden",
    },
    innerMenuTxt: {
      color: "#FFFFFF",
      fontFamily: "'Centrale Sans Medium' !important",
      fontSize: "20px !important",
      fontWeight: "500 !important",
      letterSpacing: "0 !important",
      lineHeight: "24px !important",
      display: "inline-block !important",
      paddingTop: "18px !important",
      paddingBottom: "19px !important",
      paddingLeft: "10px ",
    },
    innerMenuIcon: {
      display: "inline-block",
      float: "right",
      marginTop: "22px",
      color: "#0b3196",
      backgroundColor: "#ffffff",
      fontSize: "18px",
      borderRadius: "30px",
      marginRight: "5px",
    },
    innerMenuIcon2: {
      display: "inline-block",
      float: "right",
      marginTop: "22px",
      color: "#0b3196",
      backgroundColor: "#ffffff",
      fontSize: "18px",
      borderRadius: "30px",
      marginRight: "5px",
      transform: 'rotate(180deg)'
    },
    subcategoriasDiv: {
      backgroundColor: "#061C57",
      width: "310px",
      transform: "scaleX(-1)",
      padding: '5px 10px 10px 5px'
    },
    cateHeaderMainDivSel: {
      paddingTop: "13px",
      paddingBottom: "14.5px",
      borderBottom: "1px solid #ffffff",
      transform: "scaleX(-1)",
    },
    cateHeaderMainDivSel3: {
      paddingTop: "13px",
      paddingBottom: "14.5px",
      backgroundColor: '#061C57',
      padding: '5px 10px 10px 20px'
    },
    cateHeaderMainDivSel2: {
      paddingTop: "13px",
      paddingBottom: "14.5px",
      backgroundColor: '#061C57',
      padding: '5px 10px 10px 20px ',
      display:'none'
    },
    cateHeaderMainArrow: {
      display: "inline-block",
      color: "#ffffff",
      float: "right",
      marginRight: "20px !important",
      transform: 'rotate(90deg)',
      cursor:'pointer'
    },
    cateHeaderMainArrow2: {
      display: "inline-block",
      color: "#ffffff",
      float: "right",
      marginRight: "20px !important",
      transform: 'rotate(270deg)',
      cursor:'pointer'
    },
    cateHeaderMain: {
      color: "#FFFFFF",
      fontFamily: "'Centrale Sans Medium' !important",
      fontSize: "16px ",
      fontWeight: "500",
      letterSpacing: "0", 
      lineHeight: "19px",
      display: "inline-block",
    },
}));

export default useStyles;
