import React, { useEffect, useState } from "react";
/**Styles */
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  Grid,
  Paper,
  FormControlLabel,
  Box,
  Slider,
} from "@mui/material";
import "assets/custom/ProductInput.css";
import toggleCardsGridIcon from "assets/icons/toggle_cards_icon.svg";
import arrowBottomIcon from "assets/icons/arrow_bottom_icon.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { Styles } from "./styles/Styles";
import imgDummy from "assets/img/dummy/dummy.jpg";
/**Loading */
import Loading from "utils/loading/Loading";
/**Breamcumb */
import SectionBreadcrumb from "views/Home/Sections/SectionBreadcrumb";
/**Title */
import { Title } from "layouts/filters/components/Title";
/**Responsive */
import useCheckMobileScreen from "components/Mobile/Mobile";
/**Rutes */
import { useLocation } from "react-router-dom";
/**Services */
import { API_URL } from "_services/api.url.back.img";
import { filtersServices } from "services/filters/filters.services";
/**Componentes list products */
import CategoryProductCard from "views/Categories/CategoryProductCard/CategoryProductCard";
import CategoryProductSmall from "views/Categories/CategoryProductCard/CategoryProductSmall";
/**Menu Site */
import { ButtonCategoriesMenu } from "components/index.js";
/**Hooks */
import { useAuth } from "hooks/useAuth";
/**Componentes */
import { Banners } from "./components/Banners";
import { Carrusel } from "./components/Carrusel";
/**Utilidades */
import { transformJson, transformedValues } from "helpers/nameFilters";
import { Button } from "@material-ui/core";
import { accountService } from "services/account/account.service";

export const Filters = () => {
  const classes = Styles();
  const [expanded, setExpanded] = useState({
    panel1: true,
    panel2: true,
    panel3: true,
    panel4: false,
    panel5: false,
    panel6: false,
    panel7: false,
  });
  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(window.innerWidth >= 1920 ? 15 : 12);
  let isMobile = useCheckMobileScreen();
  const [loading, setLoading] = useState(false);
  const [breadcrumb, setBreadcrumb] = useState(null);
  const [searchParams, setSearchParams] = useState({});
  const [title, setTitle] = useState("");
  const [skus, setSkus] = useState([]);
  const [totalSkus, setTotalSkus] = useState(null);
  const { isLogged, isDollar, getUserData } = useAuth();
  const dataUser = getUserData();
  const [totalPaginado, setTotalPaginado] = useState(0);
  const [viewGrid, setViewGrid] = useState(true);
  //Filtros seleccionados
  const [valueSelectedCategory, setValueSelectedCategory] = useState();
  const [valueSelectedSubCategory, setValueSelectedSubCategory] = useState();
  const [valueSelectedBrand, setValueSelectedBrand] = useState();
  const [valueSelectedVariant, setValueSelectedVariant] = useState();
  const [valueSelectAvailability, setValueSelectAvailability] = useState();
  const [valueSelectDiscount, setValueSelectDiscount] = useState();
  const [valueSelectOrderBy, setValueSelectOrderBy] = useState();

  //Fin filtros seleccionados

  //Data para los filtros
  const [marcasList, setMarcasList] = useState([]);
  const [categoriasList, setCategoriasList] = useState([]);
  const [subcategoriasList, setSubcategoriasList] = useState([]);
  const [attributesList, setAttributesList] = useState([]);
  const [availabilityTypeList, setAvailabilityTypeList] = useState([
    { id: 1, name: "Productos entrega inmediata", value: "d", checked: false },
    { id: 2, name: "Productos bajo pedido", value: "bp", checked: false },
  ]);
  const [orderTypeList, setOrderTypeList] = useState([
    { id: 1, name: "Más Nuevos", value: "newest", checked: false },
    { id: 2, name: "Más Antiguos", value: "oldest", checked: false },
    { id: 3, name: "Menor Precio", value: "lowestPrice", checked: false },
    { id: 4, name: "Mayor Precio", value: "highestPrice", checked: false },
    { id: 5, name: "Más Vendido", value: "mostSold", checked: false },
    { id: 6, name: "Menos Vendido", value: "leastSold", checked: false },
    { id: 8, name: "Menos Valorados", value: "leastRated", checked: false },
    { id: 9, name: "Mejores Valorados", value: "mostRated", checked: false },
  ]);
  const [discountTypeList, setDiscountTypeList] = useState([
    { id: 1, name: "Descuentos", value: true, checked: false },
  ]);
  const [exchangeRate, setExchangeRate] = useState(0);
  const [rangePrice, setRangePrice] = useState();
  const [rangePriceGlobal, setRangePriceGlobal] = useState([0, 100]);
  const [minDistance, setMinDistance] = useState(10);
  const [clean, setClean] = useState(0);
  const location = useLocation();
  useEffect(() => {
    const query = new URLSearchParams(location.search);

    const newParams = {
      search: query.get("search") || "",
      category: query.get("category") || "",
      name: query.get("name") || "",
      description: query.get("description") || "",
      subcategory: query.get("subcategory") || "",
      brand: query.get("brand") || "",
      sku: query.get("sku") || "",
      type: query.get("type") || "",
      cat: query.get("cat") || "",
      sub: query.get("sub") || "",
      bran: query.get("bran") || "",
    };

    // Only update state if there's an actual change
    setSearchParams((prevParams) => {
      if (JSON.stringify(prevParams) !== JSON.stringify(newParams)) {
        return newParams;
      }
      return prevParams;
    });
  }, [location.search]);
  useEffect(() => {
    getBreadcrumb(searchParams);

    if (
      searchParams.type &&
      (searchParams.name ||
        searchParams.category ||
        searchParams.subcategory ||
        searchParams.search ||
        searchParams.description ||
        searchParams.brand ||
        searchParams.sku ||
        searchParams.cat ||
        searchParams.sub ||
        searchParams.bran)
    ) {
      setPage(1);
      setSkus([]);
      //setCategorySelected([]);
      setCategoriasList([]);
      setSubcategoriasList([]);
      setMarcasList([]);
      //setAttributesList([])
      setAvailabilityTypeList([
        {
          id: 1,
          name: "Productos entrega inmediata",
          value: "d",
          checked: false,
        },
        { id: 2, name: "Productos bajo pedido", value: "bp", checked: false },
      ]);
      setOrderTypeList([
        { id: 1, name: "Más Nuevos", value: "newest", checked: false },
        { id: 2, name: "Más Antiguos", value: "oldest", checked: false },
        { id: 3, name: "Menor Precio", value: "lowestPrice", checked: false },
        { id: 4, name: "Mayor Precio", value: "highestPrice", checked: false },
        { id: 5, name: "Más Vendido", value: "mostSold", checked: false },
        { id: 6, name: "Menos Vendido", value: "leastSold", checked: false },
        { id: 8, name: "Menos Valorados", value: "leastRated", checked: false },
        {
          id: 9,
          name: "Mejores Valorados",
          value: "mostRated",
          checked: false,
        },
      ]);
      setDiscountTypeList([
        { id: 1, name: "Descuentos", value: true, checked: false },
      ]);
      setValueSelectedCategory();
      setValueSelectedSubCategory();
      setValueSelectedBrand();
      setValueSelectedVariant();
      setValueSelectAvailability();
      setValueSelectDiscount();
      setValueSelectOrderBy();
      setRangePrice();
      if (searchParams.cat && searchParams.sub == "") {
        setValueSelectedCategory(searchParams.cat);
        getProductsFilter(
          searchParams.cat,
          null,
          null,
          1,
          pagesize,
          searchParams.type,
          null,
          isDollar,
          0,
          0,
          null,
          null,
          valueSelectOrderBy
        );
      } else if (searchParams.sub && searchParams.cat) {
        setValueSelectedCategory(searchParams.cat);
        setValueSelectedSubCategory(searchParams.sub);
        getProductsFilter(
          searchParams.cat,
          searchParams.sub,
          null,
          1,
          pagesize,
          searchParams.type,
          null,
          isDollar,
          0,
          0,
          null,
          null,
          valueSelectOrderBy
        );
      } else if (searchParams.bran) {
        setValueSelectedBrand(searchParams.bran);
        getProductsFilter(
          null,
          null,
          searchParams.bran,
          1,
          pagesize,
          searchParams.type,
          null,
          isDollar,
          0,
          0,
          null,
          null,
          valueSelectOrderBy
        );
      } else {
        //handleFunctions();
        getProductsFilter(
          null,
          null,
          null,
          1,
          pagesize,
          searchParams.type,
          null,
          isDollar,
          0,
          0,
          null,
          null,
          valueSelectOrderBy
        );
      }
    }
    window.scrollTo(0, 0);
  }, [isDollar, searchParams, valueSelectOrderBy, clean]);

  const handleClean = () => {
    setClean(clean + 1);
  };
  //Breadcrumbs
  const getBreadcrumb = (parameters) => {
    // Breadcrumb
    let breadcrumbRoute = [];
    breadcrumbRoute.push({
      name: "INICIO",
      link: "/",
    });
    if (parameters.type) {
      if (parameters.type == "cat") {
        //categories
        breadcrumbRoute.push({
          name: `${searchParams.category}`,
          link: "",
        });
        setTitle(searchParams.category);
      } else if (parameters.type == "ads") {
        // advanced search
        breadcrumbRoute.push({
          name: `BÚSQUEDA AVANZADA`,
          link: `/advancedSearchForm`,
        });

        breadcrumbRoute.push({
          name: `${searchParams.name}${searchParams.search}${searchParams.brand}${searchParams.category}${searchParams.subcategory}${searchParams.description}${searchParams.sku}`,
          link: "",
        });
        setTitle(
          `${searchParams.name}${searchParams.search}${searchParams.brand}${searchParams.category}${searchParams.subcategory}${searchParams.description}${searchParams.sku}`
        );
      } else if (parameters.type == "sh") {
        // search
        breadcrumbRoute.push({
          name: `BÚSQUEDA`,
          link: `/filters?search=${searchParams.search}&type=sh`,
        });
        breadcrumbRoute.push({
          name: searchParams.search,
          link: "",
        });
        setTitle(searchParams.search);
      } else if ((parameters.type = "subc")) {
        //subcategories
        breadcrumbRoute.push({
          name: `${searchParams.category}`,
          link: `/filters?category=${searchParams.category}&type=cat`,
        });

        breadcrumbRoute.push({
          name: `${searchParams.subcategory}`,
          link: "",
        });
        setTitle(searchParams.subcategory);
      }
    }

    setBreadcrumb(<SectionBreadcrumb route={breadcrumbRoute} />);
  };
  //Al entrar a la vista, este el primer método que ejecuta y que obtiene los datos para repartir en el Menu de filtros y la lista de productos
  const getProductsFilter = async (
    cat = null,
    sub = null,
    bran = null,
    page = 1,
    pageSize = 15,
    type,
    variant = null,
    isDollar = false,
    minPrice = 0,
    maxPrice = 0,
    discount = null,
    available = null,
    orderBy = null
  ) => {
    setLoading(true);
    let searchValue = searchParams.search;
    if (searchParams.type == "ads") {
      searchValue = `${searchParams.name}${searchParams.search}${searchParams.brand}${searchParams.category}${searchParams.subcategory}${searchParams.description}${searchParams.sku}`;
    }

    let idSocioNegocio = isLogged
      ? dataUser.usuario.snu_sn_socio_de_negocio_id
      : process.env.REACT_APP_API_USER_B2B;

    const responseJson = await filtersServices.getProductsFilters(
      page,
      pageSize,
      searchValue,
      cat,
      sub,
      bran,
      idSocioNegocio,
      type,
      variant,
      isDollar,
      minPrice,
      maxPrice,
      discount,
      available,
      orderBy
    );
    resultProducts(responseJson);
    resultFilters(responseJson.data);
    setLoading(false);
  };
  //Obtiene la estructura de productos para enviar a los componentes de categoryproductcard y categoryproductsmall
  const resultProducts = (data) => {
    const skusArray = [];
    const productos = data.data.products;
    const countProductos = data.data.totalRecords;

    const calcularPaginado = Math.ceil(countProductos / pagesize);
    setTotalSkus(countProductos);
    setTotalPaginado(calcularPaginado);
    for (let index = 0; index < productos.length; index++) {
      const sku = productos[index];

      let urlImagen = imgDummy;
      if (sku.images.length) {
        let _skuimg = sku.images;

        // Ordena las imágenes alfabéticamente por URL
        _skuimg.sort(function (a, b) {
          return a.localeCompare(b);
        });

        // Verifica si alguna imagen contiene el dominio "https://ftp3.syscom.mx"
        if (_skuimg.some((img) => img.includes("https://ftp3.syscom.mx"))) {
          urlImagen = _skuimg; // Si alguna imagen es del dominio específico, asigna todas las imágenes
        } else {
          // Si no hay imágenes del dominio específico, construye la URL usando API_URL
          urlImagen = `${API_URL}${_skuimg[0].split("public")[1]}`; // Tomamos la primera imagen como ejemplo
        }
      }

      let descuento = 0;
      if (sku.prices.mxn.priceWithDiscount < sku.price) {
        descuento = sku.price - sku.prices.mxn.priceWithDiscount;
      }
      //accountService.getClientsInteractionsProduct({type:3, productId: sku.id})

      const skuInfo = {
        skuId: sku.id, // ID producto
        productoId: sku.parentProductId ?? null, // Id Producto padre
        sku: sku.sku, // Sku producto
        nombre: sku.name.toLowerCase(), // Nombre producto
        nombre_extranjero: sku.model, //Num Parte
        externalProductId: sku.externalProductId, // Producto Externo
        prod_altura: sku.height,
        prod_ancho: sku.width,
        prod_longitud: sku.length,
        prod_peso: sku.weight,
        prod_volumen: sku.height * sku.width * sku.length,
        stock: sku.stock,
        bullets: sku.bullets,
        prod_tipo_precio_base: sku.basePrice,
        imagen: urlImagen,
        brand: sku.brand,
        backorder: sku.isBackOrder,
        precioPromocionDielsaBool: sku.bestDiscount?.type ? true : false,
        DescuentoDielsaFijo: sku.bestDiscount?.value,
        precioBase: sku.price,
        precioLista: sku.prices.mxn.price,
        precioFinal: sku.prices.mxn.priceWithDiscount,
        precioListaDls: sku.prices.usd.price,
        precioFinalDls: sku.prices.usd.priceWithDiscount,
        precioMenosDescuento: sku.prices.mxn.discount,
        descuento: descuento,
        tipoPromocionFinal: sku.bestDiscount?.type,
      };
      skusArray.push(skuInfo);
    }
    setSkus((e) => [...e, ...skusArray]);
    // setSkus(skusArray);
  };
  //Obtiene los filtros del menu
  const resultFilters = (getFiltersData) => {
    //Marcas
    const brands = transformJson(getFiltersData?.filters?.brands ?? []);
    const marcasData =
      brands && brands.map((item) => ({ ...item, marcaChecked: false }));
    if (marcasData) setMarcasList(marcasData);
    //Categorias
    const categories = transformJson(getFiltersData?.filters?.categories ?? []);
    const categoriesData =
      categories &&
      categories.map((item) => ({
        ...item,
        categoriaChecked: false,
      }));
    if (categoriesData) setCategoriasList(categoriesData);
    //SubCategorias
    const subCategories = transformJson(
      getFiltersData?.filters?.subCategories ?? null
    );
    const subCategoriesData =
      subCategories &&
      subCategories.map((item) => ({
        ...item,
        subcategoriaChecked: false,
      }));
    if (subCategoriesData) setSubcategoriasList(subCategoriesData);
    //Variantes
    const filters = getFiltersData?.filters?.attributes
      .map((item) =>
        item.name !== "MARCA" && item.values.length > 1
          ? {
              id: item.id,
              filter: item.name,
              list: transformedValues(item.values),
            }
          : null
      )
      .filter(Boolean);

    if (filters) setAttributesList(filters);
    //Tipo de cambio
    const exchangeRate = getFiltersData?.exchangeRate ?? 0;
    setExchangeRate(exchangeRate);
    //Precios max y min
    const maxRangePriceUSD = isDollar
      ? getFiltersData?.filters?.maxPrice ?? 100
      : 0;
    const maxRangePriceMXN = !isDollar
      ? getFiltersData?.filters?.maxPrice ?? 2000
      : 0;
    const minRangePriceUSD = isDollar
      ? getFiltersData?.filters?.minPrice ?? 0
      : 0;
    const minRangePriceMXN = !isDollar
      ? getFiltersData?.filters?.minPrice ?? 0
      : 0;
    const range = isDollar
      ? [Math.floor(minRangePriceUSD), Math.floor(maxRangePriceUSD)]
      : [Math.floor(minRangePriceMXN), Math.floor(maxRangePriceMXN)];
    //setRangePrice(range);

    const rangeGlobal = isDollar
      ? [Math.floor(minRangePriceUSD), Math.floor(maxRangePriceUSD)]
      : [Math.floor(minRangePriceMXN), Math.floor(maxRangePriceMXN)];
    setMinDistance(rangeGlobal[1] > 100 ? 10 : 1);
    setRangePriceGlobal(rangeGlobal);
  };

  //Expandir y ocultar el Panel del Filtro.
  const handleChange = (panel) => {
    const value = !expanded[panel];
    setExpanded((e) => ({
      ...e,
      [panel]: value,
    }));
  };
  //Cambio de Categoria
  const handleCheckCategorias = async (data) => {
    setPage(1);
    setSkus([]);
    setLoading(true)
    const dataCategoria = categoriasList.map((item) => {
      const valueChecked =
        item.id === data.target.value
          ? !item.categoriaChecked
          : item.categoriaChecked;
      return { ...item, categoriaChecked: valueChecked };
    });

    setCategoriasList(dataCategoria);

    const categoriaSelected = dataCategoria
      .filter((item) => item.categoriaChecked && item)
      .map((item) => item.id);

    //setCategorySelected(categoriaSelected);

    const valueCategoriaSelected =
      categoriaSelected.length > 0
        ? categoriaSelected
        : null;

    setValueSelectedCategory(valueCategoriaSelected);
    let searchValue = searchParams.search;
    if (searchParams.type == "ads") {
      searchValue = `${searchParams.name}${searchParams.search}${searchParams.brand}${searchParams.category}${searchParams.subcategory}${searchParams.description}${searchParams.sku}`;
    }
    let idSocioNegocio = isLogged
      ? dataUser.usuario.snu_sn_socio_de_negocio_id
      : process.env.REACT_APP_API_USER_B2B;

    const responseJson = await filtersServices.getProductsFilters(
      // page,
      1,
      pagesize,
      searchValue ? searchValue : null,
      valueCategoriaSelected,
      valueSelectedSubCategory,
      valueSelectedBrand,
      idSocioNegocio,
      searchParams.type,
      valueSelectedVariant,
      isDollar,
      rangePrice ? rangePrice[0]:null,
      rangePrice ? rangePrice[1]:null, 
      valueSelectDiscount,
      valueSelectAvailability,
      valueSelectOrderBy
    );
    resultProducts(responseJson);
   // resultFilters(responseJson.data)
    setLoading(false)
  };
  //Cambio de subcategorias
  const handleCheckSubCategorias = async (data) => {
    setPage(1);
    setSkus([]);
    setLoading(true)
    const dataSubcategoria = subcategoriasList.map((item) => {
      const valueChecked =
        item.id === data.target.value
          ? !item.subcategoriaChecked
          : item.subcategoriaChecked;
      return { ...item, subcategoriaChecked: valueChecked };
    });

    setSubcategoriasList(dataSubcategoria);
    const subcategoriaSelected = dataSubcategoria
      .filter((item) => item.subcategoriaChecked && item)
      .map((item) => item.id);

    const valueSubcategoriaSelected =
      subcategoriaSelected.length > 0
        ? subcategoriaSelected
        : null;

    setValueSelectedSubCategory(valueSubcategoriaSelected);

    let searchValue = searchParams.search;
    if (searchParams.type == "ads") {
      searchValue = `${searchParams.name}${searchParams.search}${searchParams.brand}${searchParams.category}${searchParams.subcategory}${searchParams.description}${searchParams.sku}`;
    }
    let idSocioNegocio = isLogged
      ? dataUser.usuario.snu_sn_socio_de_negocio_id
      : process.env.REACT_APP_API_USER_B2B;
    const responseJson = await filtersServices.getProductsFilters(
      //page,
      1,
      pagesize,
      searchValue ? searchValue : null,
      valueSelectedCategory,
      valueSubcategoriaSelected,
      valueSelectedBrand,
      idSocioNegocio,
      searchParams.type,
      valueSelectedVariant,
      isDollar,
      rangePrice ? rangePrice[0]:null,
      rangePrice ? rangePrice[1]:null, 
      valueSelectDiscount,
      valueSelectAvailability,
      valueSelectOrderBy
    );
    resultProducts(responseJson);
    //resultFilters(responseJson.data)
    setLoading(false)
  };
  //Cambio de marcas
  const handleCheckMarcas = async (data) => {
    setPage(1);
    setSkus([]);
    setLoading(true)
    const dataMarca = marcasList.map((item) => {
      const valueChecked =
        item.id === data.target.value ? !item.marcaChecked : item.marcaChecked;
      return { ...item, marcaChecked: valueChecked };
    });
    setMarcasList(dataMarca);

    const marcaSelected = dataMarca
      .filter((item) => item.marcaChecked && item)
      .map((item) => item.id);

    const valueMarcaSelected =
      marcaSelected.length > 0
        ? marcaSelected
        : null;
    setValueSelectedBrand(valueMarcaSelected);
    let searchValue = searchParams.search;
    if (searchParams.type == "ads") {
      searchValue = `${searchParams.name}${searchParams.search}${searchParams.brand}${searchParams.category}${searchParams.subcategory}${searchParams.description}${searchParams.sku}`;
    }
    let idSocioNegocio = isLogged
      ? dataUser.usuario.snu_sn_socio_de_negocio_id
      : process.env.REACT_APP_API_USER_B2B;

    const responseJson = await filtersServices.getProductsFilters(
      //page,
      1,
      pagesize,
      searchValue ? searchValue : null,
      valueSelectedCategory,
      valueSelectedSubCategory,
      valueMarcaSelected,
      idSocioNegocio,
      searchParams.type,
      valueSelectedVariant,
      isDollar,
      rangePrice ? rangePrice[0]:null,
      rangePrice ? rangePrice[1]:null, 
      valueSelectDiscount,
      valueSelectAvailability,
      valueSelectOrderBy
    );
    resultProducts(responseJson);
   // resultFilters(responseJson.data)
    setLoading(false)

  };
  //Cambio de variantes
  const handleCheckVariantes = async (e, data) => {
    e.preventDefault();
    setPage(1);
    setSkus([]);
    setLoading(true)


    // Actualizar el estado de los filtros
    const updatedAttributesList = attributesList.map((filter) => {
      return {
        ...filter,
        list: filter.list.map((item) => {
          if (item.id === data.id && item.name === data.name) {
            return { ...item, checked: !item.checked };
          }
          return item;
        }),
      };
    });

    setAttributesList(updatedAttributesList);

    // Obtener las variantes seleccionadas
    const variantSelected = updatedAttributesList
      .flatMap((filter) => filter.list)
      .filter((item) => item.checked)
      .map((item) => item.name);

    setValueSelectedVariant(variantSelected);

    const valueVariantSelected =
      variantSelected.length > 0
        ? variantSelected
        : null;

    setValueSelectedVariant(valueVariantSelected);

    let searchValue = searchParams.search;
    if (searchParams.type == "ads") {
      searchValue = `${searchParams.name}${searchParams.search}${searchParams.brand}${searchParams.category}${searchParams.subcategory}${searchParams.description}${searchParams.sku}`;
    }
    let idSocioNegocio = isLogged
      ? dataUser.usuario.snu_sn_socio_de_negocio_id
      : process.env.REACT_APP_API_USER_B2B;

    try {
      const responseJson = await filtersServices.getProductsFilters(
        //page,
        1,
        pagesize,
        searchValue ? searchValue : null,
        valueSelectedCategory,
        valueSelectedSubCategory,
        valueSelectedBrand,
        idSocioNegocio,
        searchParams.type,
        valueVariantSelected,
        isDollar,
        rangePrice ? rangePrice[0]:null,
        rangePrice ? rangePrice[1]:null, 
        valueSelectDiscount,
        valueSelectAvailability,
        valueSelectOrderBy
      );
      resultProducts(responseJson);
     // resultFilters(responseJson.data)
      setLoading(false)

    } catch (error) {
      console.error("Error fetching filtered products:", error);
    }
  };
  //Rango Min y Max de Precios
  const handleChangeRange = (e, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setRangePrice([
        Math.min(newValue[0], (rangePrice ? rangePrice[1] : rangePriceGlobal[1]) - minDistance),
        (rangePrice? rangePrice[1] : rangePriceGlobal[1]),
      ]);
    } else {
      setRangePrice([(rangePrice?rangePrice[0]:rangePriceGlobal[0]),Math.max(newValue[1], ((rangePrice ? rangePrice[0] : rangePriceGlobal[0]) + minDistance)),
      ]);
    }
  };
  //Peticion para filtrar por precios
  const handleUpdateByRange = async () => {
    setPage(1);
    setSkus([]);
    setLoading(true)
    const precioRango = isDollar ? [rangePrice[0], rangePrice[1]] : rangePrice;
    setRangePrice(precioRango);

    let searchValue = searchParams.search;
    if (searchParams.type === "ads") {
      searchValue = `${searchParams.name}${searchParams.search}${searchParams.brand}${searchParams.category}${searchParams.subcategory}${searchParams.description}${searchParams.sku}`;
    }
    let idSocioNegocio = isLogged
      ? dataUser.usuario.snu_sn_socio_de_negocio_id
      : process.env.REACT_APP_API_USER_B2B;
    const responseJson = await filtersServices.getProductsFilters(
      //page,
      1,
      pagesize,
      searchValue ? searchValue : null,
      valueSelectedCategory,
      valueSelectedSubCategory,
      valueSelectedBrand,
      idSocioNegocio,
      searchParams.type,
      valueSelectedVariant,
      isDollar,
      precioRango ? precioRango[0]:null,
      precioRango ? precioRango[1]:null, 
      valueSelectDiscount,
      valueSelectAvailability,
      valueSelectOrderBy
    );
    resultProducts(responseJson);
    //resultFilters(responseJson.data)
    setLoading(false)

  };
  function valuetext(value) {
    return `$ ${value}`;
  }
  //Cambio de Disponibilidad
  const handleAvailability = async (data) => {
    setPage(1);
    setSkus([]);
    setLoading(true)
    // window.alert(data.target.id)
    const dataAvailability = availabilityTypeList.map((item) => {
      const valueChecked =
        item.id == data.target.value ? !item.checked : item.checked;
      return { ...item, checked: valueChecked };
    });
    setAvailabilityTypeList(dataAvailability);
    const availabilitySelected = dataAvailability
      .filter((item) => item.checked)
      .map((item) => item.value);
    let available =
      availabilitySelected.length > 0 ? availabilitySelected : null;
    setValueSelectAvailability(available);
    let searchValue = searchParams.search;
    if (searchParams.type == "ads") {
      searchValue = `${searchParams.name}${searchParams.search}${searchParams.brand}${searchParams.category}${searchParams.subcategory}${searchParams.description}${searchParams.sku}`;
    }
    let idSocioNegocio = isLogged
      ? dataUser.usuario.snu_sn_socio_de_negocio_id
      : process.env.REACT_APP_API_USER_B2B;

    const responseJson = await filtersServices.getProductsFilters(
      //page,
      1,
      pagesize,
      searchValue ? searchValue : null,
      valueSelectedCategory,
      valueSelectedSubCategory,
      valueSelectedBrand,
      idSocioNegocio,
      searchParams.type,
      valueSelectedVariant,
      isDollar,
      rangePrice ? rangePrice[0]:null,
      rangePrice ? rangePrice[1]:null, 
      valueSelectDiscount,
      available,
      valueSelectOrderBy
    );
    resultProducts(responseJson);
   // resultFilters(responseJson.data)

    setLoading(false)


  };
  //Cambio de descuento
  const handleDiscounts = async (data) => {
    setPage(1);
    setSkus([]);
    setLoading(true)

    // window.alert(data.target.id)
    const dataDiscount = discountTypeList.map((item) => {
      const valueChecked =
        item.id == data.target.value ? !item.checked : item.checked;
      return { ...item, checked: valueChecked };
    });
    setDiscountTypeList(dataDiscount);
    const discountSelected = dataDiscount
      .filter((item) => item.checked)
      .map((item) => item.value);
    let discount = discountSelected.length > 0 ? discountSelected : null;
    setValueSelectDiscount(discount);
    let searchValue = searchParams.search;
    if (searchParams.type == "ads") {
      searchValue = `${searchParams.name}${searchParams.search}${searchParams.brand}${searchParams.category}${searchParams.subcategory}${searchParams.description}${searchParams.sku}`;
    }
    let idSocioNegocio = isLogged
      ? dataUser.usuario.snu_sn_socio_de_negocio_id
      : process.env.REACT_APP_API_USER_B2B;

    const responseJson = await filtersServices.getProductsFilters(
      //page,
      1,
      pagesize,
      searchValue ? searchValue : null,
      valueSelectedCategory,
      valueSelectedSubCategory,
      valueSelectedBrand,
      idSocioNegocio,
      searchParams.type,
      valueSelectedVariant,
      isDollar,
      rangePrice ? rangePrice[0]:null,
      rangePrice ? rangePrice[1]:null, 
      discount,
      valueSelectAvailability,
      valueSelectOrderBy
    );
    resultProducts(responseJson);
   // resultFilters(responseJson.data)
    setLoading(false)

  };

  //Falta implementar ordenamiento
  const handleOrderBy = (value) => {
    setValueSelectOrderBy(value);
  };

  //CAmbiar Pagina y Tamaño
  const handleChangePagesize = async (ev) => {
    setSkus([]);
    setPagesize(ev.target.value);
    setLoading(true)

    let pagaSizeValue = ev.target.value;
    //TODO aqui llama ala api ordenamiento
    //fillResults(res);
    let searchValue = searchParams.search;
    if (searchParams.type == "ads") {
      searchValue = `${searchParams.name}${searchParams.search}${searchParams.brand}${searchParams.category}${searchParams.subcategory}${searchParams.description}${searchParams.sku}`;
    }
    let idSocioNegocio = isLogged
      ? dataUser.usuario.snu_sn_socio_de_negocio_id
      : process.env.REACT_APP_API_USER_B2B;

    const responseJson = await filtersServices.getProductsFilters(
      page,
      pagaSizeValue,
      searchValue ? searchValue : null,
      valueSelectedCategory,
      valueSelectedSubCategory,
      valueSelectedBrand,
      idSocioNegocio,
      searchParams.type,
      valueSelectedVariant,
      isDollar,
      rangePrice ? rangePrice[0]:null,
      rangePrice ? rangePrice[1]:null, 
      valueSelectDiscount,
      valueSelectAvailability,
      valueSelectOrderBy
    );
    resultProducts(responseJson);
    //resultFilters(responseJson.data)
    setLoading(false)
  };
  const handleChangeGrid = () => {
    setViewGrid(!viewGrid);
  };

  /*Paginacion*/
  const getProductosPartidaSiguiente = async () => {
    setLoading(true)

    let _p = page;
    _p++;
    setPage(_p);

    let searchValue = searchParams.search;
    if (searchParams.type == "ads") {
      searchValue = `${searchParams.name}${searchParams.search}${searchParams.brand}${searchParams.category}${searchParams.subcategory}${searchParams.description}${searchParams.sku}`;
    }
    let idSocioNegocio = isLogged
      ? dataUser.usuario.snu_sn_socio_de_negocio_id
      : process.env.REACT_APP_API_USER_B2B;

    const responseJson = await filtersServices.getProductsFilters(
      _p,
      pagesize,
      searchValue ? searchValue : null,
      valueSelectedCategory,
      valueSelectedSubCategory,
      valueSelectedBrand,
      idSocioNegocio,
      searchParams.type,
      valueSelectedVariant,
      isDollar,
      rangePrice ? rangePrice[0]:null,
      rangePrice ? rangePrice[1]:null, 
      valueSelectDiscount,
      valueSelectAvailability,
      valueSelectOrderBy
    );
    resultProducts(responseJson);
    //resultFilters(responseJson.data)
    setLoading(false)


  };

  return (
    <div className={classes.mainBackground}>
      <Container maxWidth={"xl"} fixed style={{ paddingTop: isMobile ? 2 : 0 }}>
        <Loading loading={loading} />
        <div
          style={{
            paddingTop: isMobile ? 15 : 5,
            paddingBottom: isMobile ? 15 : 5,
            display: "flex",
            alignItems: "center",
          }}
        >
          {!isMobile && <ButtonCategoriesMenu />}
          {breadcrumb}
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <Paper
              sx={{
                backgroundColor: "#f7f7f7",
                paddingTop: "0.5rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                paddingBottom: "1.5rem",
                marginLeft: "-2rem",
                borderRadius: "8px",
              }}
              elevation={0}
            >
              <h4 style={{ color: "#0b3196" }} className={classes.filtersName}>
                Filtros
              </h4>
              {attributesList && attributesList.length > 0 && (
                <Accordion
                  sx={{
                    backgroundColor: "#fff0",
                  }}
                  elevation={0}
                  expanded={expanded.panel7}
                  onChange={() => handleChange("panel7")}
                >
                  {attributesList.map((item, index) => (
                    <React.Fragment key={index}>
                      <AccordionSummary
                        expandIcon={
                          index === 0 && (
                            <>
                              {attributesList.length > 1 ? (
                                <ControlPointIcon />
                              ) : (
                                ""
                              )}{" "}
                              <ExpandMoreIcon />{" "}
                            </>
                          )
                        }
                        aria-controls={`panel${index + 1}a-content`}
                        id={`panel${index + 1}a-header`}
                      >
                        <Typography variant="button">{item.filter}</Typography>
                      </AccordionSummary>

                      <AccordionDetails sx={{ padding: "0px" }}>
                        <Box
                          sx={{
                            paddingLeft: "0.6rem",
                            overflow: "auto",
                            height: item.list.length <= 1 ? "50px" : "200px",
                            overflowY: "scroll",
                            "&::-webkit-scrollbar": {
                              width: "4px !important",
                            },
                          }}
                        >
                          <FormGroup>
                            {item.list.map((it, subIndex) => (
                              <FormControlLabel
                                key={subIndex}
                                control={
                                  <Checkbox
                                    value={it.id}
                                    checked={it.checked}
                                    onChange={(e) =>
                                      handleCheckVariantes(e, it)
                                    }
                                  />
                                }
                                label={it.name}
                                sx={{
                                  color: "#0b3196",
                                  "& .MuiSvgIcon-root": {
                                    color: "#0b3196",
                                  },
                                }}
                              />
                            ))}
                          </FormGroup>
                        </Box>
                      </AccordionDetails>
                    </React.Fragment>
                  ))}
                </Accordion>
              )}
              {categoriasList &&
                categoriasList.length > 1 &&
                searchParams.type != "cat" &&
                searchParams.type != "subc" && (
                  <Accordion
                    sx={{
                      backgroundColor: "#fff0",
                    }}
                    elevation={0}
                    expanded={expanded.panel2}
                    onChange={() => handleChange("panel2")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="button">Categorías</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "0px" }}>
                      <Box
                        sx={{
                          paddingLeft: "0.6rem",
                          overflow: "auto",
                          height: categoriasList.length <= 4 ? "50px" : "200px",
                          overflowY: "scroll",
                          "&::-webkit-scrollbar": {
                            width: "4px !important",
                          },
                        }}
                      >
                        <FormGroup>
                          {categoriasList.map((item) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={item.id}
                                  checked={item.categoriaChecked}
                                  onChange={handleCheckCategorias}
                                />
                              }
                              label={item.name}
                              sx={{
                                color: "#0b3196",
                                "& .MuiSvgIcon-root": { color: "#0b3196" },
                              }}
                            />
                          ))}
                        </FormGroup>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                )}
              {subcategoriasList && subcategoriasList.length > 1 && (
                <Accordion
                  sx={{
                    backgroundColor: "#fff0",
                  }}
                  elevation={0}
                  expanded={expanded.panel3}
                  onChange={() => handleChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="button">Subcategorías</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: "0px" }}>
                    <Box
                      sx={{
                        paddingLeft: "0.6rem",
                        overflow: "auto",
                        height:
                          subcategoriasList.length <= 4 ? "50px" : "200px",
                        overflowY: "scroll",
                        "&::-webkit-scrollbar": {
                          width: "4px !important",
                        },
                      }}
                    >
                      <FormGroup>
                        {subcategoriasList.map((item) => {
                          return (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={item.id}
                                  checked={item.subcategoriaChecked}
                                  onChange={handleCheckSubCategorias}
                                />
                              }
                              label={item.name}
                              sx={{
                                color: "#0b3196",
                                "& .MuiSvgIcon-root": { color: "#0b3196" },
                              }}
                            />
                          );
                        })}
                      </FormGroup>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )}
              {marcasList && marcasList.length>1 && (
                <Accordion
                  sx={{
                    backgroundColor: "#fff0",
                  }}
                  elevation={0}
                  expanded={expanded.panel1}
                  onChange={() => handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="button">Marcas</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: "0px" }}>
                    <Box
                      sx={{
                        paddingLeft: "0.6rem",
                        overflow: "auto",
                        height: marcasList.length <= 3 ? "50px" : "200px",
                        overflowY: "scroll",
                        "&::-webkit-scrollbar": {
                          width: "4px !important",
                        },
                      }}
                    >
                      <FormGroup>
                        {marcasList &&
                          marcasList.map((item) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={item.id}
                                  checked={item.marcaChecked}
                                  onChange={handleCheckMarcas}
                                />
                              }
                              label={item.name}
                              sx={{
                                color: "#0b3196",
                                "& .MuiSvgIcon-root": { color: "#0b3196" },
                              }}
                            />
                          ))}
                      </FormGroup>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )}
              {isLogged && (
                <Accordion
                  sx={{ backgroundColor: "#fff0" }}
                  elevation={0}
                  expanded={expanded.panel4}
                  onChange={() => handleChange("panel4")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="button">{`Precios ${
                      isDollar ? "(DLS)" : "(MXN)"
                    }`}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: "3px" }}>
                    <Slider
                      getAriaLabel={() => "Minimum distance"}
                      value={rangePrice ? rangePrice : rangePriceGlobal}
                      onChange={handleChangeRange}
                      onChangeCommitted={handleUpdateByRange}
                      valueLabelDisplay="auto"
                      getAriaValueText={valuetext}
                      disableSwap
                      min={rangePriceGlobal[0]}
                      max={rangePriceGlobal[1]}
                      step={minDistance}
                    />
                  </AccordionDetails>
                </Accordion>
              )}
              { //!valueSelectAvailability &&
                <Accordion
                sx={{
                  backgroundColor: "#fff0",
                }}
                elevation={0}
                expanded={expanded.panel5}
                onChange={() => handleChange("panel5")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="button">Disponibilidad</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "0px" }}>
                  <Box
                    sx={{
                      paddingLeft: "0.6rem",
                      overflow: "auto",
                      height: "100px",
                      overflowY: "scroll",
                      "&::-webkit-scrollbar": {
                        width: "4px !important",
                      },
                    }}
                  >
                    <FormGroup>
                      {availabilityTypeList &&
                        availabilityTypeList.map((item) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={item.id}
                                checked={item.checked}
                                onChange={handleAvailability}
                              />
                            }
                            label={item.name}
                            sx={{
                              color: "#0b3196",
                              "& .MuiSvgIcon-root": { color: "#0b3196" },
                            }}
                          />
                        ))}
                    </FormGroup>
                  </Box>
                </AccordionDetails>
              </Accordion>}
              {isLogged && // !valueSelectDiscount && 
              (
                <Accordion
                  sx={{
                    backgroundColor: "#fff0",
                  }}
                  elevation={0}
                  expanded={expanded.panel6}
                  onChange={() => handleChange("panel6")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="button">Descuentos</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: "0px" }}>
                    <Box
                      sx={{
                        paddingLeft: "0.6rem",
                        overflow: "auto",
                        height: "100px",
                        overflowY: "scroll",
                        "&::-webkit-scrollbar": {
                          width: "4px !important",
                        },
                      }}
                    >
                      <FormGroup>
                        {discountTypeList &&
                          discountTypeList.map((item) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={item.id}
                                  checked={item.checked}
                                  onChange={handleDiscounts}
                                />
                              }
                              label={item.name}
                              sx={{
                                color: "#0b3196",
                                "& .MuiSvgIcon-root": { color: "#0b3196" },
                              }}
                            />
                          ))}
                      </FormGroup>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )}
              <Accordion
                sx={{
                  backgroundColor: "#fff0",
                }}
                elevation={0}
                style={{ paddingTop: "10px", paddingLeft: "15px" }}
                // expanded={expanded.panel8}
                //onChange={() => handleChange("panel8")}
              >
                <Button
                  variant="contained"
                  onClick={() => handleClean()}
                  className={classes.btn}
                >
                  <Typography variant="button">Limpiar Filtros</Typography>
                </Button>
              </Accordion>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={10}>
            <div className={classes.mainDiv}>
              {searchParams &&
                (searchParams.type == "subc" ? (
                  <>
                    {" "}
                    <Banners
                      category={searchParams.cat}
                      subCategory={searchParams.sub}
                    />
                    <br />
                    <Carrusel
                      attributesList={attributesList}
                      handleCheckVariantes={handleCheckVariantes}
                      subCategory={searchParams.sub}
                      nameSubCategory={searchParams.subcategory}
                    />{" "}
                  </>
                ) : (
                  ""
                ))}
              <div className={classes.headerDiv}>
                <div className={classes.numProductsDiv}>
                  <Typography className={classes.totalProdTxt}>
                    {skus.length} de {totalSkus} productos
                  </Typography>
                </div>
                <div className={classes.titleSectionDiv}>
                  {title && <Title titles={title} />}
                </div>
                <div
                  className={classes.orderPageSizeDiv}
                  style={{
                    paddingTop: 10,
                    width: isMobile ? "100%" : "30%",
                    textAlign: isMobile ? "center" : "",
                  }}
                >
                  <div className={classes.filtersSection}>
                    <div
                      className={
                        classes.customSelect + " " + classes.customSelectOrder
                      }
                    >
                      <select
                        id="filterBySel"
                        className={classes.fs14}
                        value={valueSelectOrderBy}
                        onChange={(ev) => {
                          handleOrderBy(ev.target.value);
                        }}
                      >
                        {orderTypeList.map((item) => (
                          <option value={item.value}> {item.name}</option>
                        ))}
                      </select>
                      <img src={arrowBottomIcon} />
                    </div>
                    <div
                      className={
                        classes.customSelect + " " + classes.customSelectCount
                      }
                    >
                      <select
                        id="filterBySel"
                        className={classes.fs14}
                        value={pagesize}
                        onChange={handleChangePagesize}
                      >
                        <option value={window.innerWidth >= 1920 ? 15 : 12}>
                          {window.innerWidth >= 1920 ? 15 : 12}
                        </option>
                        <option value={window.innerWidth >= 1920 ? 25 : 24}>
                          {window.innerWidth >= 1920 ? 25 : 24}
                        </option>
                        <option value={window.innerWidth >= 1920 ? 35 : 36}>
                          {window.innerWidth >= 1920 ? 35 : 36}
                        </option>
                        <option value={window.innerWidth >= 1920 ? 50 : 48}>
                          {window.innerWidth >= 1920 ? 50 : 48}
                        </option>
                      </select>
                      <img src={arrowBottomIcon} />
                    </div>
                    <img
                      className={classes.cursorPointer}
                      onClick={handleChangeGrid}
                      src={toggleCardsGridIcon}
                    />
                  </div>
                </div>
              </div>
              {totalSkus != null && totalSkus == 0 ? (
                <div className={classes.divNoResults}>
                  <Typography className={classes.textNoResults}>
                    No hay resultados para esta búsqueda
                  </Typography>
                </div>
              ) : (
                skus.map((r, index) =>
                  viewGrid === false
                    ? r && (
                        <CategoryProductCard
                          product={r}
                          key={index}
                          gridView={viewGrid}
                          userIsLogged={isLogged}
                        />
                      )
                    : r && (
                        <CategoryProductSmall
                          product={r}
                          key={index}
                          gridView={viewGrid}
                          userIsLogged={isLogged}
                        />
                      )
                )
              )}
              {skus.length < totalSkus ? (
                <div className={classes.boxPaginadoBtn}>
                  <button
                    onClick={() => {
                      getProductosPartidaSiguiente();
                    }}
                  >
                    Ver más productos
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
