import React, { createContext, useEffect, useState, Fragment, useRef } from "react";
import { Helmet } from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';

import { API_URL } from "_services/api.url.back.img";
import useCheckMobileScreen from "components/Mobile/Mobile.js";
import Typography from "@material-ui/core/Typography";

import Loading from "../../utils/loading/Loading.js";

import "assets/custom/ProductInput.css";

import SectionBreadcrumb from "views/Home/Sections/SectionBreadcrumb.js";
import SectionTitle from "./CategoryProductCard/CategoryTitle.js";

import CategoryProductCard from "./CategoryProductCard/CategoryProductCard.js";
import CategoryProductSmall from "./CategoryProductCard/CategoryProductSmall.js";

import img001 from "assets/img/Image-Not-Available.png";

import { categoryServices } from "../../_services/category.services";
import toggleCardsGridIcon from "assets/icons/toggle_cards_icon.svg";
import arrowBottomIcon from "assets/icons/arrow_bottom_icon.svg";
import { useParams, useHistory } from "react-router-dom";
import { element } from "prop-types";
import { ButtonCategoriesMenu } from "components/index.js";

const useStyles = makeStyles((styles) => ({
  divNoResults: {
    width: "100%",
    textAlign: "center",
    paddingTop: "50px",
    paddingBottom: "50px",
  },
  textNoResults: {
    fontSize: "14px",
    fontFamily: "'Centrale Sans Medium'",
  },
  mainBackground: {
    backgroundColor: "#FFF",
  },
  leftDiv: {
    width: "21%",
    marginLeft: "2%",
    display: "inline-block",
    verticalAlign: "top",
    paddingRight: "2%",
    [styles.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mainDiv: {
    backgroundColor: "#fff",
    // width: "75%",
    width: "100%",
    display: "inline-block",
    padding: "24px",
    paddingTop: 0,
    verticalAlign: "top",
    [styles.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  headerDiv: {
    width: "100%",
    paddingBottom: "10px",
    borderBottom: "2px solid #0b3196",
  },
  numProductsDiv: {
    width: "30%",
    display: "inline-block",
    verticalAlign: "bottom",
    textAlign: "left",
    [styles.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  totalProdTxt: {
    color: "#000000",
    fontSize: "12px",
    fontFamily: "'Centrale Sans Light'",
    fontWeight: "300",
  },
  titleSectionDiv: {
    width: "40%",
    display: "inline-block",
    verticalAlign: "bottom",
    textAlign: "center",
    [styles.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  orderPageSizeDiv: {
    width: "30%",
    display: "inline-block",
    verticalAlign: "bottom",
    textAlign: "right",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  filtersSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  customSelectOrder: {
    minWidth: "121px",
    height: "30px",
  },
  customSelectCount: {
    width: "73px",
    height: "30px",
  },
  customSelect: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    marginRight: "10px",
    background: "#F5F5F5",
    border: "1px solid #EAEAEA",
    borderRadius: "2px",
    color: "#0B3196",
    fontSize: "14px",
    fontFamily: "'Centrale Sans Regular'",
    padding: 8,
    "& select": {
      all: "unset",
      textAlign: "left",
      zIndex: 2,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      paddingTop: "3px",
      width: "100%",
    },
    "& img": {
      position: "absolute",
      right: 8,
    },
  },
  orderByForm: {
    marginRight: "15px",
    width: 121,
    height: 30,
    textAlign: "center",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 14,
  },
  perPageForm: {
    marginRight: "15px",
    width: "20%",
    textAlign: "center",
  },
  /// Filters
  filtersMainDiv: {
    backgroundColor: "#f5f5f5",
    border: "1px solid #eaeaea",
    width: "100%",
    verticalAlign: "top",
  },
  filtersMainTitleDiv: {
    width: "90%",
    marginLeft: "5%",
    paddingTop: "5%",
    borderBottom: "2px solid #0b3196",
  },
  filtersMainTitleTxt: {
    fontSize: "24px",
    color: "#0b3196",
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "300",
  },
  attributeMainDiv: {
    width: "90%",
    marginLeft: "5%",
    marginRight: "5%",
    paddingTop: "15px",
    paddingBottom: "15px",
    marginBottom: "30px",
    borderBottom: "1px solid #eaeaea",
  },
  attributeTitle: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "24px",
    fontWeight: "600",
  },
  attributeValueDiv: {
    width: "100%",
  },
  attributeValue: {
    color: "#0b3196",
    display: "inline",
    width: "100%",
  },
  btnAplicarFiltrosDiv: {
    width: "100%",
    paddingLeft: "5%",
    paddingRight: "5%",
    paddingBottom: "30px",
  },
  btnAplicarFiltros: {
    fontFamily: "'Centrale Sans Medium'",
    width: "100%",
    backgroundColor: "#FFFFFF",
    color: "#0b3196",
    border: "1px solid #0b3196",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#0b3196",
    },
  },
  gridIcon: {
    color: "#0b3196",
    fontSize: "42px",
    "&:hover": {
      cursor: "pointer",
    },
    ["@media (max-width: 898.98px)"]: {
      // eslint-disable-line no-useless-computed-key
      display: "none",
    },
  },

  pagination: {
    width: "100%",
  },
  divVerMas: {
    width: "100%",
    textAlign: "center",
    paddingTop: "65px",
    paddingBottom: "70px",
    paddingLeft: "calc(50% - 130px)",
  },
  btnVerMas: {
    paddingLeft: "52px",
    paddingRight: "52px",
    paddingTop: "12px",
    paddingBottom: "12px",
    border: "1px solid #0C2D9C",
    color: "#0C2D9C",
    borderRadius: "3px",
    width: "261px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#0C2D9C",
      color: "#FFFFFF",
    },
  },
  txtVerMas: {
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "19px",
    textAlign: "center",
  },
  boxPaginadoBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "25px",
    paddingBottom: "10px",
    "& button": {
      all: "unset",
      margin: "0",
      borderRadius: "7px",
      cursor: "pointer",
      height: "45px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0",
      fontSize: "16px",
      width: "261px",
      border: "1px solid #003773",
      color: "#003773",
      fontFamily: "'Centrale Sans Medium'",
    },
  },
}));

export default function CategoryView(props) {
  const classes = useStyles();
  const loggedCliente = JSON.parse(localStorage.getItem("client"));
  const { name } = useParams();

  let isMobile = useCheckMobileScreen();

  const [loading, setLoading] = useState(false);
  const [userIsLogged, setUserIsLogged] = useState(false);

  const [breadcrumb, setBreadcrumb] = useState(null);
  const [page, setPage] = useState(0);
  const [pagesize, setPagesize] = useState(12);
  const [order, setOrder] = useState("fecha lanzamienta");
  const [orderType, setOrderType] = useState("asc");
  const [orderBy, setOrderBy] = useState("fecha lanzamienta.asc");
  const [paging, setPaging] = useState(1);
  const [paginado, setPaginado] = useState(0);
  const [totalPaginado, setTotalPaginado] = useState(0);
  const [viewGrid, setViewGrid] = useState(true);
  const [totalSkus, setTotalSkus] = useState(null);
  const [orderQuantity, setOrderQuantity] = useState(window.innerWidth >= 1920 ? 15:12);
  const [partidas, setPartidas] = useState(null);
  const [skusToRender, setSkusToRender] = useState([]);
  const [tipoDePaginado, setTipoDePaginado] = useState(null);
  const [contadorPartidas, setContadorPartidas] = useState(0);
  const [contadorPaginas, setContadorPaginas] = useState(0);

  const [catalog, setCatalog] = useState({
    page: 1,
    res_per_page: 20,
    totalProductos: -1,
    title: "",
    products: [],
  });
  const [categories, setCategories] = useState([]);

  const [category, setCategory] = useState(false);
  const [currentCategoryId, setCurrentCatgoryId] = useState(null);
  const [atributos, setAtributos] = useState([]);
  const [atrSelected, setAtrSelected] = useState([]);
  const [skus, setSkus] = useState([]);

  const getCategoryMatch = (tree) => {
    let findCat = tree.find((cat) => cat.cat_categoria_link === name);

    if (findCat) {
      return findCat;
    } else {
      for (let i = 0; i < tree.length; i++) {
        let cat = tree[i];
        if (cat.hijos.length > 0) {
          let findCatInside = getCategoryMatch(cat.hijos);
          if (findCatInside) {
            return findCatInside;
          }
        }
      }
    }
  };

  const getCategoryById = (tree, id) => {
    let findCat = tree.find((cat) => cat.cat_categoria_id === id);

    if (findCat) {
      return findCat;
    } else {
      for (let i = 0; i < tree.length; i++) {
        let cat = tree[i];
        if (cat.hijos.length > 0) {
          let findCatInside = getCategoryById(cat.hijos);
          if (findCatInside) {
            return findCatInside;
          }
        }
      }
    }
  };

  const getCategories = async () => {
    updateCatalog("title", "");
    setSkusToRender([]);

    const categories = await categoryServices.getCategoryTree();
    setCategories(categories.tree);

    let findCat = await getCategoryMatch(categories.tree);
    setCategory(findCat);

    if (findCat) {
      updateCatalog("title", findCat.cat_nombre_tienda);
      const catToString = findCat.cat_categoria_id.toString();
      getProductosPorCategoria(0, true, catToString);
      setCurrentCatgoryId(catToString);

      /* set breadcrumb route */
      let breadcrumbRoute = [];
      if (findCat.cat_cat_categoria_padre_id !== null) {
        let findCategoryById = getCategoryById(
          categories.tree,
          findCat.cat_cat_categoria_padre_id
        );
        if (findCategoryById) {
          breadcrumbRoute.push({
            name: findCategoryById.cat_nombre_tienda,
            link: findCategoryById.cat_categoria_link,
          });
          if (findCategoryById.cat_cat_categoria_padre_id !== null) {
            let findCategoryById = getCategoryById(
              categories.tree,
              findCategoryById.cat_cat_categoria_padre_id
            );
            if (findCategoryById) {
              breadcrumbRoute.push({
                name: findCategoryById.cat_nombre_tienda,
                link: findCategoryById.cat_categoria_link,
              });
            }
          }
        }

        breadcrumbRoute.push({
          name: findCat.cat_nombre_tienda,
          link: "",
        });
      } else {
        breadcrumbRoute.push({
          name: findCat.cat_nombre_tienda,
          link: "",
        });
      }
      breadcrumbRoute.unshift({
        name: "INICIO",
        link: "/",
      });
      setBreadcrumb(<SectionBreadcrumb route={breadcrumbRoute} />);
    } else {
      // just in this case when not cat is found is because bad category param defined and then must be redirect to 404 page
      props.history.push("/institucional/Error%20404");
    }
  };

  useEffect(() => {
    getCategories();
   
      window.scrollTo(0,0);
   
  }, []);

  useEffect(() => {
    /* if param name is null then redirect to 404 page */
    if (name === null || name === "null") {
      props.history.push("/institucional/Error%20404");
    }

    const esPrimerPagina = true;

    if (name) {
      getCategories();
    } else {
      getProductosPorCategoria(0, esPrimerPagina);
    }
  }, [orderBy, orderQuantity, name]);


  const updateCatalog = (data, value) => {
    let newCatalog = catalog;
    newCatalog[data] = value;
    setCatalog(newCatalog);
  };

  const getProductosPorCategoria = async (
    pagina,
    esPrimerPagina,
    idCategoria = null
  ) => {
    setLoading(true);
    let skusArray = [];
    let idSocioNegocio = process.env.REACT_APP_API_USER_B2B;

    if (loggedCliente != null) {
      idSocioNegocio = loggedCliente.listaSnbyid[0].sn_socios_negocio_id;
      setUserIsLogged(true);
    } else {
      setUserIsLogged(false);
      idSocioNegocio = process.env.REACT_APP_API_USER_B2B;
    }

    if (pagina === 0) {
      skusArray = [];
      setPaginado(0);
    } else {
      skusArray = skus;
    }

    try {
      const buscarProductosRes = await categoryServices.getCategoryData(
        idCategoria,
        pagina,
        orderQuantity,
        order,
        orderType,
        idSocioNegocio
      ); // limite,pagina,searchValue,idSocioNegocio

      const productos = buscarProductosRes.mainConsultaProductos.rows;
      const countProductos = buscarProductosRes.mainConsultaProductos.count;
      setTotalSkus(countProductos);
      const calcularPaginado = Math.ceil(countProductos / orderQuantity);
      setTotalPaginado(calcularPaginado);
      productos.forEach((sku) => {
        let urlImagen =
          "http://wws.com.pa/wp-content/plugins/wordpress-ecommerce/marketpress-includes/images/default-product.png";

        if (sku.imagen_productos.length) {
       
          if(sku.imagen_productos[0].imgprod_ruta_archivo.includes('https://ftp3.syscom.mx')){
            urlImagen=sku.imagen_productos[0].imgprod_ruta_archivo
          }else{
          urlImagen = `${API_URL}${sku.imagen_productos[0].imgprod_ruta_archivo.split("public")[1]}`;
          }
 
        }
        let descuento = 0;
        if (sku.precioFinal < sku.precioBasePorListaPrecio) {
          descuento = sku.precioBasePorListaPrecio - sku.precioFinal;
        }
        const skuInfo = {
          productoId: sku.productoPadreId,
          imagen: urlImagen,
          nombre: sku.prod_nombre.toLowerCase(),
          sku: sku.prod_sku,
          nombre_extranjero: sku.prod_nombre_extranjero,
          stock: sku.prod_total_stock,
          precioLista: sku.precioFinal,
          descuento: descuento,
          precioFinal: sku.precioMenosDescuento,
          precioListaDls: sku.precioFinaldls,
          precioFinalDls: sku.precioMenosDescuentodls,
          skuId: sku.prod_producto_id,
          prod_tipo_precio_base: sku.prod_tipo_precio_base,
          precioPromocionDielsaBool: sku.precioPromocionDielsaBool,
          tipoPromocionFinal: sku.tipoPromocionFinal,
          precioMenosDescuento: sku.precioMenosDescuento,
          DescuentoDielsaFijo: sku.DescuentoDielsaFijo,
          brand: sku.mar_nombre,
          bullets: sku.prod_viñetas,
          backorder: sku.aplicaBackOrder,
          prod_altura: sku.prod_altura,
          prod_ancho: sku.prod_ancho,
          prod_longitud: sku.prod_longitud,
          prod_peso: sku.prod_peso,
          prod_volumen: sku.prod_volumen,
          externalProductId: sku.externalProductId
        };
        skusArray.push(skuInfo);
      });

      setSkus(skusArray);
      if (countProductos > skusArray.length) {
        if (esPrimerPagina) {
          getPrimerPaginaDeProductos(skusArray);
        } else {
          getSiguientePaginaDeProductos(skusArray);
        }
      } else {
        setSkusToRender(skusArray);
        setTipoDePaginado(null);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSkus([null]);
      setSkusToRender([]);
    }
  };

  const getPrimerPaginaDeProductos = (skusArray) => {
    const getPartidas = Math.floor(skusArray.length / orderQuantity);
    setPartidas(getPartidas);
    setContadorPartidas(1);
    setContadorPaginas(1);
    setTipoDePaginado("partida");

    const getSkus = [];

    for (let i = 0; i < orderQuantity; i++) {
      const item = skusArray[i];
      getSkus.push(item);
    }
    setSkusToRender(getSkus);
  };

  const getSiguientePaginaDeProductos = (skusArray) => {
    const getPartidas = Math.floor(skusArray.length / orderQuantity);
    setPartidas(getPartidas);
    setTipoDePaginado("partida");

    const skusToIterate =
      parseInt(skusToRender.length) + parseInt(orderQuantity);

    const tempSkus = [];

    for (let i = 0; i < skusToIterate; i++) {
      const item = skus[i];
      tempSkus.push(item);
    }
    setSkusToRender(tempSkus);
  };

  const getProductosPartidaSiguiente = () => {
    let tempContadorPartidas = contadorPartidas;
    tempContadorPartidas++;
    setContadorPartidas(tempContadorPartidas);
    const skusArray = [];

    const skusToIterate =
      parseInt(skusToRender.length) + parseInt(orderQuantity);

    if (tempContadorPartidas <= partidas) {
      for (let i = 0; i < skusToIterate; i++) {
        const item = skus[i];
        skusArray.push(item);
      }
      setSkusToRender(skusArray);
    } else {
      if (contadorPaginas < totalPaginado) {
        buscarProductosPaginaSiguiente();
      } else {
        const tempSkus = [];
        for (let i = 0; i < skus.length; i++) {
          const item = skus[i];
          tempSkus.push(item);
        }
        setSkusToRender(tempSkus);
        setTipoDePaginado(null);
      }
    }
  };

  const buscarProductosPaginaSiguiente = () => {
    let tempContadorPaginas = contadorPaginas;
    getProductosPorCategoria(tempContadorPaginas, false, currentCategoryId);
    tempContadorPaginas++;
    setContadorPaginas(tempContadorPaginas);
  };

  const handleOrderBy = (val) => {
    setOrderBy(val);

    updateCatalog("products", []);

    let _v = val.split(".");
    setOrder(_v[0]);
    setOrderType(_v[1]);
  };

  const handleChangeGrid = () => {
    setViewGrid(!viewGrid);
  };

  return (
    <div className={classes.mainBackground}>
      <Container maxWidth={"xl"} fixed style={{paddingTop: isMobile ? 2 : 0 }} >
      <Helmet>
        <meta name="title" content={category && category.cat_meta_titulo} />
        <meta
          name="description"
          content={category && category.cat_meta_descripcion}
        />
      </Helmet>

      <Loading loading={loading} /> 
      <div style={{
          paddingTop: isMobile ? 15 : 5,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {!isMobile &&
          <ButtonCategoriesMenu />}
        {breadcrumb}
      </div>
      <div className={classes.fullDiv}>
        <div className={classes.mainDiv} >
          {/*HEADER*/}
          <div className={classes.headerDiv}>
            <div className={classes.numProductsDiv} style={{ textAlign: isMobile?  'center': '' }}  >
              <Typography className={classes.totalProdTxt}>
                {skusToRender.length} de {totalSkus} productos
              </Typography>
            </div>
            <div className={classes.titleSectionDiv} >
              <SectionTitle categoryName={catalog.title} />
            </div>
            <div className={classes.orderPageSizeDiv} style={{ width: isMobile?  '100%': '30%', textAlign: isMobile? 'center':''}} >
              <div className={classes.filtersSection}  >
                <div
                  className={
                    classes.customSelect + " " + classes.customSelectOrder
                  } 
                >
                  <select
                    id="filterBySel"
                    className={classes.fs14}
                    value={orderBy} 
                    onChange={(ev) => {
                      handleOrderBy(ev.target.value);
                    }}
                  >
                    <option value={"az-za.asc"}>A - Z</option>
                    <option value={"az-za.desc"}>Z - A</option>
                    <option value={"precio.asc"}>Menor Precio</option>
                    <option value={"precio.desc"}>Mayor Precio</option>
                    <option value={"mas vendido.asc"}>Menos Vendido</option>
                    <option value={"mas vendido.desc"}>Más Vendido</option>
                    <option value={"mejores valorados.asc"}>
                      Menos Valorados
                    </option>
                    <option value={"mejores valorados.desc"}>
                      Mejores Valorados
                    </option>
                    <option value={"fecha lanzamienta.asc"}>Más Nuevos</option>
                    <option value={"fecha lanzamienta.desc"}>
                      Más Antiguos
                    </option>
                  </select>
                  <img src={arrowBottomIcon} />
                </div>
                <div
                  className={
                    classes.customSelect + " " + classes.customSelectCount
                  }
                >
                  <select
                    id="filterBySel"
                    className={classes.fs14}
                    value={orderQuantity}
                    onChange={(ev) => {
                      setOrderQuantity(ev.target.value);
                    }}
                  >
                    <option value={window.innerWidth >= 1920 ?  15:12}>{window.innerWidth >= 1920 ?  15:12}</option>
                    <option value={window.innerWidth >= 1920 ?  25:24}>{window.innerWidth >= 1920 ?  25:24}</option>
                    <option value={window.innerWidth >= 1920 ?  35:36}>{window.innerWidth >= 1920 ?  35:36}</option>
                    <option value={window.innerWidth >= 1920 ?  50:48}>{window.innerWidth >= 1920 ?  50:48}</option>
                  </select>
                  <img src={arrowBottomIcon} />
                </div>
                <img
                  className={classes.cursorPointer}
                  onClick={handleChangeGrid}
                  src={toggleCardsGridIcon}
                />
              </div>
            </div>
          </div>

          {totalSkus != null && totalSkus == 0 ? (
            <div className={classes.divNoResults}>
              <Typography className={classes.textNoResults}>
                No hay resultados para esta búsqueda
              </Typography>
            </div>
          ) : (
            skusToRender.length > 0 &&
            skusToRender.map((r, index) =>
              viewGrid === false
                ? r && (
                    <CategoryProductCard
                      product={r}
                      key={index}
                      gridView={viewGrid}
                      userIsLogged={userIsLogged}
                    /> 
                  ) 
                : r && (
                    <CategoryProductSmall
                      product={r}
                      key={index}
                      gridView={viewGrid}
                      userIsLogged={userIsLogged}
                    />
                  )
            )
          )}

          {tipoDePaginado && (
            <div className={classes.boxPaginadoBtn}>
              <button
                onClick={() => {
                  getProductosPartidaSiguiente();
                }}
              >
                Ver más productos
              </button>
            </div>
          )}
        </div>
      </div>
      </Container>
    </div>
  );
}
