import React, { useEffect, useState } from "react";
import CategoryProductCarrusel from "views/Categories/CategoryProductCard/CategoryProductCarrusel";
/**Hooks */
import { useAuth } from "hooks/useAuth";
/**Rutes */
import { useLocation } from "react-router-dom";
import imgDummy from "assets/img/dummy/dummy.jpg";
import { filtersServices } from "services/filters/filters.services";
import { API_URL } from "_services/api.url.back.img";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

//carousel
import Carousel from "react-elastic-carousel";
import styled from "styled-components";
import "layouts/filters/components/styles.css";
import { SmallRelated } from "./SmallRelated/SmallRelated";

const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];
  
  const useStyles = makeStyles((styles) => ({
    tabBarDiv: {
        backgroundColor: "#fff",
        boxShadow: "none",
        borderBottom: "4px solid #40df00",
      },
    container: {
      width: "100%",
    },
    imgDiv: {
      width: "100%",
      display: "inline-block",
      "&:hover": {
        cursor: "pointer",
      },
      [styles.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    image: {
      width: "100%",
    },
    // carousels
    carouselTitle: {
      fontFamily: "'Centrale Sans Medium'",
      fontWeight: 600,
      color: "#0b3196",
      marginTop: 10,
    },
    carouselDivider: {
      border: "2px solid #40DF00",
      backgroundColor: "#40DF00",
      marginTop: 8,
      borderRadius: 20,
    },
    carouselContent: {
      marginTop: 6,
    },
    imgTrusty: {
      width: "100%",
    },
  }));
export const SectionAccesories = () => {
  const { isLogged, isDollar, getUserData } = useAuth();
  const classes = useStyles();

  const dataUser = getUserData();
  const [skus, setSkus] = useState([]);
  const location = useLocation();
  const [searchParams, setSearchParams] = useState({});
  const Item = styled.div`
    
    background-color: transparent;
    color: #fff;
    font-size: 4em;
  `;
  useEffect(() => {
    const query = new URLSearchParams(location.search);

    const newParams = {
      id: query.get("prod") || "",
      sku: query.get("sku") || "",
    };

    // Only update state if there's an actual change
    setSearchParams((prevParams) => {
      if (JSON.stringify(prevParams) !== JSON.stringify(newParams)) {
        return newParams;
      }
      return prevParams;
    });
  }, [location.search]);

  useEffect(() => {
    if (searchParams.id || searchParams.sku) {
      setSkus([]);
      let idProduct =
        searchParams.id == "null" || searchParams.id == "undefined" ? null : searchParams.id;
      getProductsFilter(idProduct != null ? idProduct : searchParams.sku, searchParams.sku);
    }
    window.scrollTo(0, 0);
  }, [searchParams]);

  const getProductsFilter = async (id, sku) => {
   
    let idSocioNegocio = isLogged
      ? dataUser.usuario.snu_sn_socio_de_negocio_id
      : process.env.REACT_APP_API_USER_B2B;
    const responseJson = await filtersServices.getProductsAccesories(
      id,
      idSocioNegocio,
      isDollar
    );
    let countResult = responseJson.data.length;
  if(countResult==0){
    const responseJs = await filtersServices.getProductsAccesories(
      sku,
      idSocioNegocio,
      isDollar
    );
    resultProducts(responseJs);
  }else{
    resultProducts(responseJson);
  }
  };
  //Obtiene la estructura de productos para enviar a los componentes de categoryproductcard y categoryproductsmall
  const resultProducts = (data) => {
    const skusArray = [];
    const productos = data.data;
    for (let index = 0; index < productos.length; index++) {
      const sku = productos[index];

      let urlImagen = imgDummy;
      if (sku.images.length) {
        let _skuimg = sku.images;

        // Ordena las imágenes alfabéticamente por URL
        _skuimg.sort(function (a, b) {
          return a.localeCompare(b);
        });

        // Verifica si alguna imagen contiene el dominio "https://ftp3.syscom.mx"
        if (_skuimg.some((img) => img.includes("https://ftp3.syscom.mx"))) {
          urlImagen = _skuimg; // Si alguna imagen es del dominio específico, asigna todas las imágenes
        } else {
          // Si no hay imágenes del dominio específico, construye la URL usando API_URL
          urlImagen = `${API_URL}${_skuimg[0].split("public")[1]}`; // Tomamos la primera imagen como ejemplo
        }
      }

      let descuento = 0;
      if (sku.prices.mxn.priceWithDiscount < sku.price) {
        descuento = sku.price - sku.prices.mxn.priceWithDiscount;
      }
      //accountService.getClientsInteractionsProduct({type:3, productId: sku.id})

      const skuInfo = {
        skuId: sku.id, // ID producto
        productoId: sku.parentProductId ?? null, // Id Producto padre
        sku: sku.sku, // Sku producto
        nombre: sku.name.toLowerCase(), // Nombre producto
        nombre_extranjero: sku.model, //Num Parte
        externalProductId: sku.externalProductId, // Producto Externo
        prod_altura: sku.height,
        prod_ancho: sku.width,
        prod_longitud: sku.length,
        prod_peso: sku.weight,
        prod_volumen: sku.height * sku.width * sku.length,
        stock: sku.stock,
        bullets: sku.bullets,
        prod_tipo_precio_base: sku.basePrice,
        imagen: urlImagen,
        brand: sku.brand,
        backorder: sku.isBackOrder,
        precioPromocionDielsaBool: sku.bestDiscount?.type ? true : false,
        DescuentoDielsaFijo: sku.bestDiscount?.value,
        precioBase: sku.price,
        precioLista: sku.prices.mxn.price,
        precioFinal: sku.prices.mxn.priceWithDiscount,
        precioListaDls: sku.prices.usd.price,
        precioFinalDls: sku.prices.usd.priceWithDiscount,
        precioMenosDescuento: sku.prices.mxn.discount,
        descuento: descuento,
        tipoPromocionFinal: sku.bestDiscount?.type,
      };
      skusArray.push(skuInfo);
    }
    setSkus((e) => [...e, ...skusArray]);
    // setSkus(skusArray);
  };

  return (

    <>
    {skus.length>0 &&
    <>
     {/* <h3  className={classes.tabBarDiv}> ACCESORIOS PARA EL EQUIPO </h3> */}
     <div className="related-products-header">
                <span>ACCESORIOS PARA EL EQUIPO</span>
                <div className="linea-tab"></div>
            </div>
<SmallRelated skus={skus}/>  
    </>
    
}
    </>
  
  ) 
  
  
 


};
