import React, { useState, useEffect, useContext, Fragment } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

//carousel
import Carousel from "react-elastic-carousel";
import styled from "styled-components";
import "./styles.css";
import imgDummy from "assets/img/dummy/dummy.jpg";
import { Variant } from "./Variant";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 5 },
  { width: 1200, itemsToShow: 5 },
];

const useStyles = makeStyles((styles) => ({
  container: {
    width: "100%",
  },
  imgDiv: {
    width: "100%",
    display: "inline-block",
    "&:hover": {
      cursor: "pointer",
    },
    [styles.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  image: {
    width: "100%",
  },
  // carousels
  carouselTitle: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: 600,
    color: "#0b3196",
    marginTop: 10,
  },
  carouselDivider: {
    border: "2px solid #40DF00",
    backgroundColor: "#40DF00",
    marginTop: 8,
    borderRadius: 20,
  },
  carouselContent: {
    marginTop: 6,
  },
  imgTrusty: {
    width: "25%",
  },
}));

export const Carrusel = ({ attributesList, handleCheckVariantes, subCategory,nameSubCategory }) => {
  const classes = useStyles();
  const [filteredJson, setFilteredJson] = useState([]);
  const fallbackUrl = imgDummy; // Reemplaza con la URL de la imagen predeterminada


  const Item = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 100%;
    background-color: transparent;
    color: #fff;
    font-size: 4em;
  `;

  const goTo = (link) => {
    if (link && link != "") {
      window.location.href = link;
    }
  };

  const getAttributesById = (id) => {
    const variantObject = Variant.find(variant => variant.id == id);
    
    if (!variantObject) return { subCategories: [], attributes: {} };
  
    // Extract the attributes excluding 'id'
    const subCategories = Object.keys(variantObject).filter(key => key !== 'id');
    return {
      subCategories,
      attributes: variantObject
    };
  };
  
  
  useEffect(() => {
    if (attributesList && subCategory) {
    
      const { attributes } = getAttributesById(subCategory);

      
      
      const newFilteredJson = attributesList.filter(item => {
        const filter = item.filter;
        
        if (attributes) {
          // Ensure attributes contains "Atributo 1" and "Atributo 2"
         
          const attr1 = attributes["Atributo 1"];
          const attr2 = attributes["Atributo 2"];
          
         
          return filter === attr1 || filter === attr2;
        }
        
        return false;
      });
     
    
  
      setFilteredJson(newFilteredJson);
    }
  }, [attributesList, subCategory]);
  
  const normalizeVariantName=(name)=>{
      return name.replace("/","-").replace('"',"").replace(",","-").replace(".","-")
  }

  const normalizeSubCategory = (name)=>{
   
    return (name ==="JACKS / PLUGS" ? "JACKS - PLUGS" : name)
  }


  return (
    <div className="wrapper">
      {filteredJson &&
      <div className={classes.container}>
        <Typography variant="h6" className={classes.carouselTitle}></Typography>
        <div className={classes.carouselContent}>
          <div className="App">
            <Carousel
              breakPoints={breakPoints}
              enableAutoPlay={true}
              autoPlaySpeed={5000}
            >
              {
                filteredJson
                  .filter((item) => item.list.length > 1) // Filtra los elementos que tienen más de un valor en la lista
                  .slice(0, 2) // Toma solo los dos primeros elementos que cumplan con la condición
                  .map((item, index) =>
                    item.list.map((o, index) => (
                      <div 
                        key={`carusel-item-id-${index}`}
                        className={classes.imgDiv}
                      >
                        <Item key={o.id}  onClick={(e) =>handleCheckVariantes(e, o)}>
                          <img
                            className={classes.imgTrusty}
                            key={o.id}
                            src={`${process.env.REACT_APP_API_URL_IMG}attributesv3/${normalizeSubCategory(nameSubCategory.toUpperCase())}/${item.filter}/${normalizeVariantName(o.name)}.webp`}
                            onError={(e) => e.target.src = fallbackUrl}
                            title={o.name}
                          />
                        </Item>
                        <p style={{ textAlign: "center", color:"rgb(11, 49, 150)"}}> {o.name} </p>
                      </div>
                    ))
                  )}
            </Carousel>
          </div>
        </div>
      </div>}
    </div>
  );
};

