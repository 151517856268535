import React, { useState, useEffect, useContext, Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ReactHtmlParser from 'react-html-parser'
const useStyles = makeStyles((styles) => ({
  container: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 30,
    paddingLeft: 15,
    paddingRight: 15,

    textAlign: "justify",
    fontWeight: 400,
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "24px",
  },
  productDescription: {
    fontFamily: "'Centrale Sans Regular'",
    fontSize: 18,
    textAlign: "justify",
    color: "#000000",
    marginTop: 18,
  },
}));

export default function SectionProductDescription(props) {
  const classes = useStyles();
  return (
    <span className={classes.productDescription}>
      {ReactHtmlParser(props.productDetails.skus[props.productDetails.sku_selected]?.descripcion)}
    </span>
  );
}
