import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Box, Button, IconButton, ListItemIcon, Menu, MenuItem, Container } from "@mui/material";

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ReceiptIcon from '@mui/icons-material/Receipt';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import OutputIcon from '@mui/icons-material/Output';
import SearchAutocomplete from "./SearchAutocomplete";
import CartHeader from "views/Cart/CartHeader";
import LocalCartHeader from "views/Cart/LocalCartHeader";
import { checkoutService } from "_services/checkout.service";
import { useAuth } from "hooks/useAuth";
import { Help } from "components/help";
import { accountService } from "services/account/account.service";
const HeaderNew = ({ getCarrito }) => {
    const history = useHistory();
    const { carritoItems, isDollar, setIsDollar, isLogged, logoURL, getUserData,logout} = useAuth();
    const dataUser = getUserData()
    const [searchWord, setSearchWord] = useState("");
    const [showMenu, setShowMenu] = useState(null);
    const open = Boolean(showMenu);
    const [showMenuExChange, setShowMenuExChange] = useState(null);
    const openExChange = Boolean(showMenuExChange);
    const [dataCar, setDataCar] = useState({});
    const [showCar, setShowCar] = useState(false);


 
    const handleClickMenuExChange = (event) => {
        setShowMenuExChange(event.currentTarget);
    };

    const handleCloseMenuExChange = () => {
        setShowMenuExChange(null);
    };

    const handleClickMenu = (event) => {
        setShowMenu(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setShowMenu(null);
    };

    const funSetSearchWord = (valueSearch) =>{
        setSearchWord(valueSearch);
    }

    const redirectSearch = () => {
        history.push(`/search?search=${searchWord.toUpperCase()}`);
        setSearchWord("");
    };

    const handleCerrarSesion = () => {
        setShowMenu(null);
        localStorage.removeItem('user');
        localStorage.removeItem('myCart');
        localStorage.removeItem('client');
        logout()
        window.location.reload(true);
    };

    const handleQuickPurchase = () => {
        history.push("/quickShop"); 
    }

    const handleSearchAdvanced = () => {
        history.push("/advancedSearchForm"); 
    }

    const handleIniciarSesion = () => {
        history.push("/login"); 
    }

    const handleMiCuenta = () => {
        setShowMenu(null);
        history.push("/myprofile/perfil"); 
    };

    const handleMisPedidos = () => {
        setShowMenu(null);
        history.push("/myprofile/pedidos"); 
    }

    const handleMisCotizaciones = () => {
        setShowMenu(null);
        history.push("/myprofile/cotizaciones"); 
    }

    const handleMisFavoritos = () => {
        setShowMenu(null);
        history.push("/myprofile/favoritos"); 
    }

    const handleMisProyectos = () => {
        setShowMenu(null);
        history.push("/myprofile/proyectos"); 
    }

    const handleLinkWhatsApp = () => {
        window.open('https://api.whatsapp.com/send?phone=5218126589848&text=Hola%2C%20buen%20d%C3%ADa%F0%9F%98%80', '_blank');
    }

    const handleLinkFacebook = () => {
        window.open('https://www.facebook.com/Dielsamsi', '_blank');
    }

    const handleOnChangeCurrencyExchange = (value) =>{
        setIsDollar(value == 1 ? false : true)
        window.localStorage.setItem('isDollar', value ==1 ? false : true)
        setShowMenuExChange(null);
    }

    const handleShowCar = async () => {
            setShowCar(true);
    };

    const handleCloseCar = () => {
        setShowCar(false);
    };

  return (
    //<Container fixed paddingBottom={2}>
    <Box
        sx={{
            padding: '26px 70px 10px 70px',
            // borderStyle: 'solid',
            // borderColor: '#E5EAF2',                
            // borderWidth: 0,
            // borderBottomWidth: 'thin',
        }}
    >
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                display: 'flex',
                '&:hover': {
                    cursor: 'pointer',
                },
            }}
        >
            <Button
                disableElevation
                variant="contained"
                size="small"
                sx={{
                    borderRadius: 0,
                    padding: '5px 40px',
                    backgroundColor: '#0b3196',
                }}
                startIcon={<ManageSearchIcon sx={{ color: '#40df00' }} />}
                onClick={handleSearchAdvanced}
            >
                Búsqueda Avanzada
            </Button>
            <Button
                disableElevation
                variant="contained"
                size="small"
                sx={{
                    borderRadius: 0,
                    padding: '5px 40px',
                    backgroundColor: '#0b3196',
                }}
                startIcon={<WhatsAppIcon sx={{ color: '#40df00' }} />}
                onClick={handleLinkWhatsApp}
            >
                WhatsApp
            </Button>
           
                <Help ayuda={'Ayuda'}/>
           
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-evenly' }}>
            <Box component={Link} to={'/'}>
                <img
                    src={logoURL}
                    style={{
                        maxWidth: 185,
                        maxHeight: 70,
                    }}
                />
            </Box>
            <Box sx={{ width: '25%' }}>
                <SearchAutocomplete
                    funSetSearchWord={funSetSearchWord}
                    classes=""
                    redirectSearch={redirectSearch}
                />
            </Box>
            <Box>
                <Button
                    sx={{ color: '#0b3196' }}
                    startIcon={<LocalMallIcon />}
                    onClick={handleQuickPurchase}
                >
                    Compra Rápida
                </Button>
            </Box>
            
            <Box>
                <Button
                    sx={{ color: '#0b3196' }}
                    startIcon={<CurrencyExchangeIcon />}
                    onClick={handleClickMenuExChange}
                >
                    {isDollar ? 'DLS' : 'MXN'}
                </Button>
                <Menu
                    anchorEl={showMenuExChange}
                    open={openExChange}
                    onClose={handleCloseMenuExChange}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={() => handleOnChangeCurrencyExchange('1')}>MXN</MenuItem>
                    <MenuItem onClick={() => handleOnChangeCurrencyExchange('2')}>DLS</MenuItem>
                </Menu>
            </Box>
        
            {!isLogged ?
            <Box>
                <Button
                    onClick={handleIniciarSesion}
                    sx={{ color: '#0b3196' }}
                    startIcon={<AccountCircleIcon />}
                >
                    Iniciar sesión
                </Button>
            </Box>
            :
            <>
                <Box>
                    <Button
                        sx={{ color: '#0b3196' }}
                        startIcon={<AccountCircleIcon />}
                        onClick={handleClickMenu}
                    >
                        Mi cuenta
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={showMenu}
                        open={open}
                        onClose={handleCloseMenu}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleMiCuenta}>
                            <ListItemIcon>
                                <PersonIcon fontSize="small" />
                            </ListItemIcon>
                            Mi perfil
                        </MenuItem>
                        <MenuItem onClick={handleMisPedidos}>
                            <ListItemIcon>
                                <ShoppingBasketIcon fontSize="small" />
                            </ListItemIcon>
                            Mis pedidos
                        </MenuItem>
                        <MenuItem onClick={handleMisCotizaciones}>
                            <ListItemIcon>
                                <ReceiptIcon fontSize="small" />
                            </ListItemIcon>
                            Mis cotizaciones
                        </MenuItem>
                        {dataUser.usuario.TypeUser!=2 && 
                            <>
                        <MenuItem onClick={handleMisFavoritos}>
                            <ListItemIcon>
                                <BookmarkIcon fontSize="small" />
                            </ListItemIcon>
                            Mis favoritos
                        </MenuItem>
                        <MenuItem onClick={handleMisProyectos}>
                            <ListItemIcon>
                                <BusinessCenterRoundedIcon fontSize="small" />
                            </ListItemIcon>
                            Mis Proyectos
                        </MenuItem>
                        </>
                        }
                        <MenuItem onClick={handleCerrarSesion}>
                            <ListItemIcon>
                                <OutputIcon fontSize="small" />
                            </ListItemIcon>
                            Cerrar sesión
                        </MenuItem>
                    </Menu>
                </Box>
            </>
            }
            <Box>
                <div
                    style={{
                        marginLeft: '22px',
                        marginBottom: '8px',
                    }}
                >
                    <span
                        style={{
                            position: "absolute",
                            width: "21px",
                            height: "21px",
                            borderRadius: '50%',
                            backgroundColor: '#40df00',
                            display: "flex",
                            justifyContent: "center",
                            color: '#fff',
                            fontSize: '13px',
                            fontWeight: "600",
                            zIndex: 1,
                        }}
                    >
                        {carritoItems}
                    </span>
                </div>
                    <IconButton
                        edge="end"
                        onClick={handleShowCar}
                    >
                        <ShoppingCartIcon sx={{ color: '#0b3196' }} />
                    </IconButton>
            </Box>
        </Box>
        {isLogged &&
            <CartHeader showCar={showCar} closeCartDiv={handleCloseCar} />}
        {!isLogged &&
            <LocalCartHeader showCar={showCar} closeCartDiv={handleCloseCar} getCarrito={getCarrito} />}
    </Box>
   // </Container>
  )
}

export default HeaderNew