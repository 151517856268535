import React, { createContext, useEffect, useState, Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import { API_URL } from "_services/api.url.back.img";
import useCheckMobileScreen from "components/Mobile/Mobile.js";
import Typography from "@material-ui/core/Typography";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Loading from "../../utils/loading/Loading.js";

import "assets/custom/ProductInput.css";

import SectionBreadcrumb from "views/Home/Sections/SectionBreadcrumb.js";
import SectionTitle from "views/Categories/CategoryProductCard/CategoryTitle.js";

import CategoryProductCard from "views/Categories/CategoryProductCard/CategoryProductCard.js";
import CategoryProductSmall from "views/Categories/CategoryProductCard/CategoryProductSmall.js";

import { searchServices } from "_services/search.services";

import toggleCardsGridIcon from "assets/icons/toggle_cards_icon.svg";
import arrowBottomIcon from "assets/icons/arrow_bottom_icon.svg";
import { useAuth } from "hooks/useAuth.js";
import { Accordion, AccordionDetails, AccordionSummary, FormGroup, Grid, Paper, FormControlLabel, Box, Slider } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ButtonCategoriesMenu } from "components/index.js";



let productDetailslocal = {
  name: "",
  page: 1,
  res_per_page: 20,
  totalProductos: -1,
  products_show: {
    products: [],
    title: "",
  },
};
let productDetails = createContext(productDetailslocal); /// Variable global
let user = 1; //// Change for logged user

export default function QuickSearchView(props) {
  const classes = useStyles();
  const urlParam = useParams();
  let isMobile = useCheckMobileScreen();

  const [loading, setLoading] = useState(false);
  const [breadcrumb, setBreadcrumb] = useState(null);
  const [page, setPage] = useState(0);
  const [pagesize, setPagesize] = useState(window.innerWidth >= 1920 ? 15:12);
  const [order, setOrder] = useState("fecha lanzamienta");
  const [orderType, setOrderType] = useState("asc");
  const [orderBy, setOrderBy] = useState("fecha lanzamienta.asc");
  const [atributos, setAtributos] = useState([]);
  const [viewGrid, setViewGrid] = useState(true);

  // variables para busqueda de productos y paginado
  const [skus, setSkus] = useState([]);
  const [totalPaginado, setTotalPaginado] = useState(0);
  const [userIsLogged, setUserIsLogged] = useState(false);
  const [useFilterBack, setUseFilterBack]= useState(false)

  // const [ esPrimerPagina, setEsPrimerPagina ] = useState(true);
  const [totalSkus, setTotalSkus] = useState(null);
  const { isLogged, isDollar } = useAuth();
  const [expanded, setExpanded] = useState({ 
    panel1: true,
    panel2: true,
    panel3: true,
    panel4: true,
    panel5: true,
    panel6: true,
  });
  const [marcasList, setMarcasList] = useState([]);
  const [valueSelectedBrand, setValueSelectedBrand] = useState();
  const [categoriasList, setCategoriasList] = useState([]);
  const [categorySelected, setCategorySelected] = useState([]);
  const [valueSelectedCategory, setValueSelectedCategory] = useState();
  const [subcategoriasList, setSubcategoriasList] = useState([]);
  const [valueSelectedSubCategory, setValueSelectedSubCategory] = useState();

  const [value1, setValue1] = useState([0, 100]);
  const [valueRageGlobal, setValueRageGlobal] = useState([0, 100]);
  const [newValueRange, setNewValueRange] = useState();
  const [stateDiscount, setStateDiscount] = useState(false);
  const [stateAvailability, setStateAvailability] = useState(false);
  const [stateOnRequest, setStateOnRequest] = useState(false);
  const [precioDolar, setPrecioDolar] = useState(0);
  const [precioMaxMXN, setPrecioMaxMXN] = useState(0);

  const handleChange = (panel) => {
    const value = !expanded[panel];
    setExpanded((e) => ({
      ...e,
      [panel]: value,
    }));
  };
  const handleChangeGrid = () => {
    setViewGrid(!viewGrid);
  };
  const getBreadcrumb = () => {
    // Breadcrumb
    let breadcrumbRoute = [];

    breadcrumbRoute.push({
      name: "INICIO",
      link: "/",
    });
    breadcrumbRoute.push({
      name: "BÚSQUEDA",
      link: "",
    });
    setBreadcrumb(<SectionBreadcrumb route={breadcrumbRoute} />);
  };
  function valuetext(value) {
    return `$ ${value}`;
  }

  const minDistance = 10;

  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };
  const requestResults = async (valueCategoriaSelected, valueSubcategoriaSelected, valueMarcaSelected,
    page = 0, pagesize = 12, priceRange, stateAvailability = null, stateDiscount = null, stateOnRequest) => {
    try {
      setLoading(true);
      const searchValue = urlParam.search;
      let idSocioNegocio = process.env.REACT_APP_API_USER_B2B;
      const clientData = JSON.parse(localStorage.getItem("client"));
      if (clientData) {
        idSocioNegocio = clientData.listaSnbyid[0].sn_socios_negocio_id;
        setUserIsLogged(true);
      }

      let precioMax = null;
      let precioMin = null;
      if(priceRange){
        precioMax = isDollar
          ? priceRange[1] * precioDolar
          : priceRange[1];

        precioMin = isDollar
          ? priceRange[0] * precioDolar
          : priceRange[0];
      }

      console.log('precioMax ', precioMax);
      const dataMax = precioMax
        ? Math.ceil(precioMax / 100) * 100
        : 100;

      const searchSKUs = await searchServices.searchFilters(
        page,
        pagesize,
        searchValue,
        order,
        orderType,
        idSocioNegocio,
        valueCategoriaSelected, // categorias 120
        valueSubcategoriaSelected, // subcategorias 1000049
        valueMarcaSelected, // marcas -1
        priceRange ? [precioMin, precioMax] : null, // filtroRangoPrecio [0, 10000]
        stateAvailability, // Disponibilidad 1 o null
        stateDiscount, // filtroDescuento 1 o null
        stateOnRequest,
      );

      setLoading(false);
      return searchSKUs;
    } catch (error) {
      console.log('Error: ', error);
      setLoading(false);
    }
  }
  const fillFilters = (getFiltersData) => {
    const marcasData = getFiltersData?.dataFilters?.marcasDataFiltro
        .map((item) => ({ ...item, marcaChecked: false }));
    setMarcasList(marcasData);

    const categoriasData = getFiltersData?.dataFilters?.categoriasDataFiltro
      .map((item) => ({ ...item, categoriaChecked: false }));
    setCategoriasList(categoriasData);

    const subcategoriasData = getFiltersData?.dataFilters?.subCategoriasDataFiltro
      .map((item) => ({ ...item, subcategoriaChecked: false }))
    // setSubcategoriaListGlobal(subcategoriasData);
    setSubcategoriasList(subcategoriasData);

    // console.log('Max ', getFiltersData?.dataFilters?.rowsMinMax[0].maxprecio);
    setPrecioDolar(getFiltersData?.dataFilters?.PriceDollar[0].cmm_valor);
    setPrecioMaxMXN(getFiltersData?.dataFilters?.rowsMinMax[0].maxprecio);
    const precioMax = isDollar
      ? getFiltersData?.dataFilters?.rowsMinMax[0].maxprecio / getFiltersData?.dataFilters?.PriceDollar[0].cmm_valor
      : getFiltersData?.dataFilters?.rowsMinMax[0].maxprecio;
    const dataMax = getFiltersData?.dataFilters?.rowsMinMax[0].maxprecio
      ? Math.ceil(precioMax / 100) * 100
      : 100;
    setValue1([0, dataMax]);
    setValueRageGlobal([0, dataMax]);
  }
  const fillResults = (searchSKUs) => {
    const skusArray = [];
    const productos = searchSKUs.mainConsultaProductos.rows;
    const countProductos = searchSKUs.mainConsultaProductos.count;

    const calcularPaginado = Math.ceil(countProductos / pagesize);
    setTotalSkus(countProductos);
    setTotalPaginado(calcularPaginado);
    for (let index = 0; index < productos.length; index++) {
      const sku = productos[index];
      let urlImagen =
        "http://wws.com.pa/wp-content/plugins/wordpress-ecommerce/marketpress-includes/images/default-product.png";
      if (sku.imagen_productos.length) {
        let _skuimg = sku.imagen_productos;
        _skuimg.sort(function (a, b) {
          return a.imgprod_nombre_archivo.localeCompare(
            b.imgprod_nombre_archivo
          );
        });
       
        if(_skuimg[0].imgprod_ruta_archivo.includes('https://ftp3.syscom.mx')){
          urlImagen=_skuimg[0].imgprod_ruta_archivo
        }else{
        urlImagen = `${API_URL}${ _skuimg[0].imgprod_ruta_archivo.split("public")[1]}`;
        }
      }
      let descuento = 0;
      if (sku.precioFinal < sku.precioBasePorListaPrecio) {
        descuento = sku.precioBasePorListaPrecio - sku.precioFinal;
      }
      
      const skuInfo = {
        productoId: sku.productoPadreId,
        imagen: urlImagen,
        nombre: sku.prod_nombre.toLowerCase(),
        sku: sku.prod_sku,
        nombre_extranjero: sku.prod_nombre_extranjero,
        stock: sku.prod_total_stock,
        precioLista: sku.precioFinal,
        precioListaDls: sku.precioFinaldls,
        descuento: descuento, 
        precioFinal: sku.precioMenosDescuento,
        precioFinalDls: sku.precioMenosDescuentodls,
        skuId: sku.prod_producto_id,
        prod_tipo_precio_base: sku.prod_tipo_precio_base,
        precioPromocionDielsaBool: sku.precioPromocionDielsaBool,
        tipoPromocionFinal: sku.tipoPromocionFinal,
        precioMenosDescuento: sku.precioMenosDescuento,
        DescuentoDielsaFijo: sku.DescuentoDielsaFijo,
        brand: sku.mar_nombre,
        bullets: sku.prod_viñetas,
        backorder: sku.aplicaBackOrder,
        prod_altura: sku.prod_altura,
        prod_ancho: sku.prod_ancho,
        prod_longitud: sku.prod_longitud,
        prod_peso: sku.prod_peso,
        prod_volumen: sku.prod_volumen,
        externalProductId: sku.externalProductId
      };
      skusArray.push(skuInfo);
    }
    setSkus((e) => ([...e, ...skusArray]));
  }
  const handleCheckCategorias = async (data) => {
    setPage(0);
    setSkus([]);
    const dataCategoria = categoriasList.map((item) =>
      {
        const valueChecked = (item.cat_categoria_id === Number(data.target.defaultValue))
          ? !item.categoriaChecked
          : item.categoriaChecked
          return {...item, categoriaChecked: valueChecked}
      });
    setCategoriasList(dataCategoria);

    const categoriaSelected = dataCategoria
      .filter((item) => item.categoriaChecked && item)
      .map((item) => item.cat_categoria_id);
    
    setCategorySelected(categoriaSelected);

    const valueCategoriaSelected = categoriaSelected.length > 0
      ? JSON.stringify(categoriaSelected).replace(/[^\d,-]/g, '')
      : null;
    
    setValueSelectedCategory(valueCategoriaSelected);
    const res = await requestResults(valueCategoriaSelected, valueSelectedSubCategory, valueSelectedBrand, 0, pagesize, value1, stateAvailability, stateDiscount, stateOnRequest);
    fillResults(res);
  }
  const handleCheckSubCategorias = async (data) => {
    setPage(0);
    setSkus([]);
    const dataSubcategoria = subcategoriasList.map((item) =>
      {
        const valueChecked = (item.cat_categoria_id === Number(data.target.defaultValue))
          ? !item.subcategoriaChecked
          : item.subcategoriaChecked
          return {...item, subcategoriaChecked: valueChecked}
      });
    setSubcategoriasList(dataSubcategoria);

    const subcategoriaSelected = dataSubcategoria
      .filter((item) => ( categorySelected.length
        ? item.subcategoriaChecked
          && categorySelected.includes(item.cat_cat_categoria_padre_id)
        : item.subcategoriaChecked
        || categorySelected.includes(item.cat_cat_categoria_padre_id) )
        && item)
      .map((item) => item.cat_categoria_id);

    const valueSubcategoriaSelected = subcategoriaSelected.length > 0
      ? JSON.stringify(subcategoriaSelected).replace(/[^\d,-]/g, '')
      : null;

    setValueSelectedSubCategory(valueSubcategoriaSelected);
    const res = await requestResults(valueSelectedCategory, valueSubcategoriaSelected, valueSelectedBrand, 0, pagesize, value1, stateAvailability, stateDiscount, stateOnRequest);
    fillResults(res);
  }
  const handleCheckMarcas = async (data) => {
    setPage(0);
    setSkus([]);
    const dataMarca = marcasList.map((item) =>
      {
        const valueChecked = (item.mar_marca_id === Number(data.target.defaultValue))
          ? !item.marcaChecked
          : item.marcaChecked
          return {...item, marcaChecked: valueChecked}
      });
    setMarcasList(dataMarca);

    const marcaSelected = dataMarca
      .filter((item) => item.marcaChecked && item)
      .map((item) => item.mar_marca_id);

    const valueMarcaSelected = marcaSelected.length > 0
      ? JSON.stringify(marcaSelected).replace(/[^\d,-]/g, '')
      : null;
    
    setValueSelectedBrand(valueMarcaSelected);
    const res = await requestResults(valueSelectedCategory, valueSelectedSubCategory, valueMarcaSelected, 0, pagesize, value1, stateAvailability, stateDiscount, stateOnRequest);
    fillResults(res);
  }
  const getProductosPartidaSiguiente = async () => {
    let _p = page;
    _p++;
    setPage(_p);

    const res = await requestResults(valueSelectedCategory, valueSelectedSubCategory, valueSelectedBrand, _p, pagesize, value1, stateAvailability, stateDiscount, stateOnRequest);
    fillResults(res);
  };
  const handleChangePagesize = async (ev) => {
    setSkus([]);
    setPagesize(ev.target.value);
    const res = await requestResults(valueSelectedCategory, valueSelectedSubCategory, valueSelectedBrand, page, ev.target.value, value1, stateAvailability, stateDiscount, stateOnRequest);
    fillResults(res);
  }
  const handleOrderBy = (val) => {
    setOrderBy(val);
    let _v = val.split(".");
    setOrder(_v[0]);
    setOrderType(_v[1]);
  };
  const handleUpdateByRange = async () => {
    setSkus([]);
    const precioRango = isDollar
      ? [value1[0], value1[1] * precioDolar]
      : value1;
    setNewValueRange(precioRango);
    const res = await requestResults(valueSelectedCategory, valueSelectedSubCategory, valueSelectedBrand, 0, pagesize, value1, stateAvailability, stateDiscount, stateOnRequest);
    fillResults(res);
  }
  const handleAvailability = async () => {
    setSkus([]);
    setStateAvailability(e => !e);
    const res = await requestResults(valueSelectedCategory, valueSelectedSubCategory, valueSelectedBrand, 0, pagesize, value1, !stateAvailability, stateDiscount, stateOnRequest);
    fillResults(res);
  }
  const handleOnRequest = async () => {
    setSkus([]);
    setStateOnRequest(e => !e);
    const res = await requestResults(valueSelectedCategory, valueSelectedSubCategory, valueSelectedBrand, 0, pagesize, value1, stateAvailability, stateDiscount, !stateOnRequest);
    fillResults(res);
  }
  const handleDiscount = async () => {
    setSkus([]);
    setStateDiscount(e => !e);
    const res = await requestResults(valueSelectedCategory, valueSelectedSubCategory, valueSelectedBrand, 0, pagesize, value1, stateAvailability, !stateDiscount, stateOnRequest);
    fillResults(res);
  }

  useEffect(() => {
    const searchValue = urlParam.search;
    productDetailslocal.products_show.title = searchValue;
    setPage(0);
    setSkus([]);
    setStateDiscount(false);
    setStateAvailability(false);
    setStateOnRequest(false);
    setCategorySelected([]);
    setSubcategoriasList([]);
    setMarcasList([]);
    const handleFunctions = async () => {
      const res = await requestResults();
      fillFilters(res);
      fillResults(res);
    }
    handleFunctions();
  }, [orderBy, urlParam.search, isDollar]);

  useEffect(() => {
    getBreadcrumb();
    window.scrollTo(0,0);
  },[])

  return (
    <div className={classes.mainBackground}>
      <Container maxWidth={"xl"} fixed style={{paddingTop: isMobile ? 2 : 0 }} >
        <Loading loading={loading} />
        <div style={{
            paddingTop: isMobile ? 15 : 5,
            paddingBottom: isMobile ? 15 : 5,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {!isMobile &&
          <ButtonCategoriesMenu />}
          {breadcrumb}
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <Paper
              sx={{
                backgroundColor: '#f7f7f7',
                paddingTop: '1.5rem',
                paddingLeft: '1rem',
                paddingRight: '1rem',
                paddingBottom: '1.5rem',
                marginLeft: '-2rem',
                borderRadius: '8px',
              }}
              elevation={0}
            >
              {categoriasList.length > 0 &&
              <Accordion
                sx={{
                  backgroundColor: '#fff0'
                }}
                elevation={0}
                expanded={expanded.panel2}
                onChange={() => handleChange('panel2')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="button">Categorías</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0px' }}>
                  <Box sx={{
                    paddingLeft: '0.6rem', overflow: 'auto',
                    height: categoriasList.length <= 4 ? '50px' : '200px',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '4px !important',
                    },
                  }}
                  >
                    <FormGroup>
                      {categoriasList.map((item) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={item.cat_categoria_id}
                              checked={item.categoriaChecked}
                              onChange={handleCheckCategorias}
                            />
                          }
                          label={item.cat_nombre}
                          sx={{
                            color: '#0b3196',
                            '& .MuiSvgIcon-root': { color: '#0b3196' }
                          }}
                        />
                      ))}
                    </FormGroup>
                  </Box>
                </AccordionDetails>
              </Accordion>}
              {subcategoriasList
                .filter((item) => categorySelected.length > 0
                  ? categorySelected.includes(item.cat_cat_categoria_padre_id)
                  : item).length > 0 &&
              <Accordion
                sx={{
                  backgroundColor: '#fff0'
                }}
                elevation={0}
                expanded={expanded.panel3}
                onChange={() => handleChange('panel3')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="button">Subcategorías</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0px' }}>
                  <Box sx={{
                    paddingLeft: '0.6rem', overflow: 'auto',
                    height: subcategoriasList.length <= 4 ? '50px' : '200px',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '4px !important',
                    },
                  }}
                  >
                    <FormGroup>
                      {subcategoriasList
                        .filter((item) => categorySelected.length > 0
                          ? categorySelected.includes(item.cat_cat_categoria_padre_id)
                          : item)
                        .map((item) => {
                          return (<FormControlLabel
                            control={
                              <Checkbox
                                value={item.cat_categoria_id}
                                checked={item.subcategoriaChecked}
                                onChange={handleCheckSubCategorias}
                              />
                            }
                            label={item.cat_nombre}
                            sx={{
                              color: '#0b3196',
                              '& .MuiSvgIcon-root': { color: '#0b3196' }
                            }}
                          />)
                        })}
                    </FormGroup>
                  </Box>
                </AccordionDetails>
              </Accordion>}
              <Accordion
                sx={{
                  backgroundColor: '#fff0'
                }}
                elevation={0}
                expanded={expanded.panel1}
                onChange={() => handleChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="button">Marcas</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0px' }}>
                  <Box sx={{
                    paddingLeft: '0.6rem', overflow: 'auto',
                    height: marcasList.length <= 4 ? '50px' : '200px',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '4px !important',
                    },
                  }}
                  >
                    <FormGroup>
                      {marcasList.map((item) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={item.mar_marca_id}
                              checked={item.marcaChecked}
                              onChange={handleCheckMarcas}
                            />
                          }
                          label={item.mar_nombre}
                          sx={{
                            color: '#0b3196',
                            '& .MuiSvgIcon-root': { color: '#0b3196' }
                          }}
                        />
                      ))}
                    </FormGroup>
                  </Box>
                </AccordionDetails>
              </Accordion>
              {isLogged &&
              <Accordion
                sx={{
                  backgroundColor: '#fff0'
                }}
                elevation={0}
                expanded={expanded.panel4}
                onChange={() => handleChange('panel4')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="button">{`Precios ${isDollar ? '(DLS)' : '(MXN)' }`}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '3px' }}>
                  <Slider
                    getAriaLabel={() => 'Minimum distance'}
                    value={value1}
                    onChange={handleChange1}
                    onChangeCommitted={handleUpdateByRange}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    disableSwap
                    min={0}
                    max={valueRageGlobal[1]}
                    step={50}
                  />
                </AccordionDetails>
              </Accordion>
              }
              <Accordion
                sx={{
                  backgroundColor: '#fff0'
                }}
                elevation={0}
                expanded={expanded.panel5}
                onChange={() => handleChange('panel5')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="button">Disponibilidad</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0px' }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={1}
                            checked={stateAvailability}
                            onChange={handleAvailability}
                          />
                        }
                        label="Productos entrega inmediata"
                        sx={{
                          color: '#0b3196',
                          '& .MuiSvgIcon-root': { color: '#0b3196' }
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={2}
                            checked={stateOnRequest}
                            onChange={handleOnRequest}
                          />
                        }
                        label="Productos bajo pedido"
                        sx={{
                          color: '#0b3196',
                          '& .MuiSvgIcon-root': { color: '#0b3196' }
                        }}
                      />
                    </FormGroup>

                </AccordionDetails>
              </Accordion>
              {isLogged &&
              <Accordion
                sx={{
                  backgroundColor: '#fff0'
                }}
                elevation={0}
                expanded={expanded.panel6}
                onChange={() => handleChange('panel6')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="button">Descuentos</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0px' }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={1}
                            checked={stateDiscount}
                            onChange={handleDiscount}
                          />
                        }
                        label="Descuentos"
                        sx={{
                          color: '#0b3196',
                          '& .MuiSvgIcon-root': { color: '#0b3196' }
                        }}
                      />
                    </FormGroup>
                </AccordionDetails>
              </Accordion>}
            </Paper>
          </Grid> 
          <Grid item xs={12} sm={10}>
            <div>
              <div className={classes.leftDiv + " " + classes.hide}>
                {/*LEFT PANEL*/}
                <div className={classes.filtersMainDiv}>
                  <div className={classes.filtersMainTitleDiv}>
                    <Typography className={classes.filtersMainTitleTxt}>
                      Filtros
                    </Typography>
                  </div>
                  {atributos.map((a) => (
                    <div className={classes.attributeMainDiv} key={a.name}>
                      <Typography className={classes.attributeTitle}>
                        {a.name}
                      </Typography>
                      {a.values.map((v) => (
                        <div className={classes.attributeValueDiv} key={v.pav_valor}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={v.checked}
                                // onChange={() => { setChecked(a.name, v.pav_valor) }}
                                name="checkedA"
                              />
                            }
                            label={v.pav_valor}
                            className={classes.attributeValue}
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                  <div className={classes.btnAplicarFiltrosDiv}>
                    {/* <Button variant="contained" className={classes.btnAplicarFiltros} disableElevation onClick={() => { aplicarFiltros(); }}>Aplicar Filtros</Button> */}
                  </div>
                </div>
              </div>
              <div className={classes.mainDiv}>
                {/*HEADER*/}

                <div className={classes.headerDiv}>
                  <div className={classes.numProductsDiv}>
                    <Typography className={classes.totalProdTxt}>
                      {skus.length} de {totalSkus} productos
                    </Typography> 
                  </div>
                  <div className={classes.titleSectionDiv}>
                    {/* <Typography className={classes.wordSearchDiv}>{wordSearch}</Typography> */}
                    <SectionTitle
                      category={productDetails._currentValue.products_show}
                    />
                  </div>
                  <div className={classes.orderPageSizeDiv}  style={{ paddingTop:10, width: isMobile?  '100%': '30%', textAlign: isMobile? 'center':''}}>
                    <div className={classes.filtersSection}>
                      <div
                        className={
                          classes.customSelect + " " + classes.customSelectOrder
                        }
                      >
                        <select
                          id="filterBySel"
                          className={classes.fs14}
                          value={orderBy}
                          onChange={(ev) => {
                            handleOrderBy(ev.target.value);
                          }}
                        >
                          <option value={"az-za.asc"}>A - Z</option>
                          <option value={"az-za.desc"}>Z - A</option>
                          <option value={"precio.asc"}>Menor Precio</option>
                          <option value={"precio.desc"}>Mayor Precio</option>
                          <option value={"mas vendido.asc"}>Menos Vendido</option>
                          <option value={"mas vendido.desc"}>Más Vendido</option>
                          <option value={"mejores valorados.asc"}>
                            Menos Valorados
                          </option>
                          <option value={"mejores valorados.desc"}>
                            Mejores Valorados
                          </option>
                          <option value={"fecha lanzamienta.asc"}>Más Nuevos</option>
                          <option value={"fecha lanzamienta.desc"}>
                            Más Antiguos
                          </option>
                        </select>
                        <img src={arrowBottomIcon} />
                      </div>
                      <div
                        className={
                          classes.customSelect + " " + classes.customSelectCount
                        }
                      >
                        <select
                          id="filterBySel"
                          className={classes.fs14}
                          value={pagesize}
                          onChange={handleChangePagesize}
                        >
                          <option value={window.innerWidth >= 1920 ?  15:12}>{window.innerWidth >= 1920 ?  15:12}</option>
                          <option value={window.innerWidth >= 1920 ?  25:24}>{window.innerWidth >= 1920 ?  25:24}</option>
                          <option value={window.innerWidth >= 1920 ?  35:36}>{window.innerWidth >= 1920 ?  35:36}</option>
                          <option value={window.innerWidth >= 1920 ?  50:48}>{window.innerWidth >= 1920 ?  50:48}</option>
                        </select>
                        <img src={arrowBottomIcon} />
                      </div>
                      {/* <div className="custom-select">
                        <select
                          id="page-size"
                          className={classes.fs14}
                          value={pagesize}
                          onChange={(ev) => {
                            setPagesize(ev.target.value);
                          }}
                        >
                          <option value={12}>12</option>
                          <option value={24}>24</option>
                          <option value={36}>36</option>
                          <option value={48}>48</option>
                        </select>
                      </div> */}
                      <img
                        className={classes.cursorPointer}
                        onClick={handleChangeGrid}
                        src={toggleCardsGridIcon}
                      />
                    </div>
                  </div>
                </div>
                {/*GRID*/}
                {totalSkus != null && totalSkus == 0 ? (
                  <div className={classes.divNoResults}>
                    <Typography className={classes.textNoResults}>
                      No hay resultados para esta búsqueda
                    </Typography>
                  </div>
                ) : (
                  skus.map((r, index) =>
                    viewGrid === false
                      ? r && (
                          <CategoryProductCard
                            product={r}
                            key={index}
                            gridView={viewGrid}
                            userIsLogged={userIsLogged}
                          />
                        )
                      : r && (
                          <CategoryProductSmall
                            product={r}
                            key={index}
                            gridView={viewGrid}
                            userIsLogged={userIsLogged}
                          />
                        )
                  )
                )}
                {skus.length < totalSkus ? (
                  <div className={classes.boxPaginadoBtn}>
                    <button
                      onClick={() => {
                        getProductosPartidaSiguiente();
                      }}
                    >
                      Ver más productos
                    </button>
                  </div>
                ) : (
                  <></>
                )}
                {/* {
                                  tipoDePaginado === 'pagina' &&
                                      <div className={classes.boxPaginadoBtn}>
                                          <button onClick={()=> {buscarProductosPaginaSiguiente()}}>Ver más productos pagina</button>
                                      </div>
                              } */}
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}



const useStyles = makeStyles((styles) => ({
  hide: {
    display: "none !important",
  },
  divNoResults: {
    width: "100%",
    textAlign: "center",
    paddingTop: "50px",
    paddingBottom: "50px",
  },
  textNoResults: {
    fontSize: "14px",
    fontFamily: "'Centrale Sans Medium'",
  },
  mainBackground: {
    backgroundColor: "#FFF",
  },
  leftDiv: {
    width: "21%",
    marginLeft: "2%",
    display: "inline-block",
    verticalAlign: "top",
    paddingRight: "2%",
  },
  mainDiv: {
    backgroundColor: "#fff",
    /*width: "75%",*/
    width: "100%",
    display: "inline-block",
    verticalAlign: "top",
    paddingLeft: "2%",
    paddingRight: "2%",
  },
  headerDiv: {
    width: "100%",
    paddingBottom: "10px",
    borderBottom: "2px solid #0b3196",
  },
  numProductsDiv: {
    width: "30%",
    display: "inline-block",
    verticalAlign: "bottom",
    textAlign: "left",
  },
  totalProdTxt: {
    color: "#000000",
    fontSize: "12px",
    fontFamily: "'Centrale Sans Light'",
    fontWeight: "300",
  },
  titleSectionDiv: {
    width: "40%",
    display: "inline-block",
    verticalAlign: "bottom",
    textAlign: "center",
  },
  orderPageSizeDiv: {
    width: "30%",
    display: "inline-block",
    verticalAlign: "bottom",
    textAlign: "right",
  },
  filtersSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  customSelectOrder: {
    minWidth: "121px",
    height: "30px",
  },
  customSelectCount: {
    width: "73px",
    height: "30px",
  },
  customSelect: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    marginRight: "10px",
    background: "#F5F5F5",
    border: "1px solid #EAEAEA",
    borderRadius: "2px",
    color: "#0B3196",
    fontSize: "14px",
    fontFamily: "'Centrale Sans Regular'",
    padding: 8,
    "& select": {
      all: "unset",
      textAlign: "left",
      zIndex: 2,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      paddingTop: "3px",
      width: "100%",
    },
    "& img": {
      position: "absolute",
      right: 8,
    },
  },
  orderByForm: {
    marginRight: "15px",
    width: 121,
    height: 30,
    textAlign: "center",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 14,
  },
  fs14: {
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 14,
  },
  perPageForm: {
    marginRight: "15px",
    width: "20%",
    textAlign: "center",
  },
  /// Filters
  filtersMainDiv: {
    backgroundColor: "#f5f5f5",
    border: "1px solid #eaeaea",
    width: "100%",
    verticalAlign: "top",
  },
  filtersMainTitleDiv: {
    width: "90%",
    marginLeft: "5%",
    paddingTop: "5%",
    borderBottom: "2px solid #0b3196",
  },
  filtersMainTitleTxt: {
    fontSize: "24px",
    color: "#0b3196",
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "300",
  },
  attributeMainDiv: {
    width: "90%",
    marginLeft: "5%",
    marginRight: "5%",
    paddingTop: "15px",
    paddingBottom: "15px",
    marginBottom: "30px",
    borderBottom: "1px solid #eaeaea",
  },
  attributeTitle: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "24px",
    fontWeight: "600",
  },
  attributeValueDiv: {
    width: "100%",
  },
  attributeValue: {
    color: "#0b3196",
    display: "inline",
    width: "100%",
  },
  btnAplicarFiltrosDiv: {
    width: "100%",
    paddingLeft: "5%",
    paddingRight: "5%",
    paddingBottom: "30px",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  btnAplicarFiltros: {
    fontFamily: "'Centrale Sans Medium'",
    width: "100%",
    backgroundColor: "#FFFFFF",
    color: "#0b3196",
    border: "1px solid #0b3196",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#0b3196",
    },
  },
  gridIcon: {
    color: "#0b3196",
    fontSize: "42px",
    "&:hover": {
      cursor: "pointer",
    },
    ["@media (max-width: 898.98px)"]: {
      // eslint-disable-line no-useless-computed-key
      display: "none",
    },
  },
  wordSearchDiv: {
    color: "#0b3196",
    fontFamily: "'Centrale Sans Bold'",
    fontSize: "24px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "29px",
    textAlign: "center",
  },
  pagination: {
    width: "100%",
  },
  divVerMas: {
    width: "100%",
    textAlign: "center",
    paddingTop: "65px",
    paddingBottom: "70px",
    paddingLeft: "calc(50% - 130px)",
  },
  btnVerMas: {
    paddingLeft: "52px",
    paddingRight: "52px",
    paddingTop: "12px",
    paddingBottom: "12px",
    border: "1px solid #0C2D9C",
    color: "#0C2D9C",
    borderRadius: "3px",
    width: "261px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#0C2D9C",
      color: "#FFFFFF",
    },
  },
  txtVerMas: {
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "19px",
    textAlign: "center",
  },
  boxPaginadoBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "25px",
    paddingBottom: "10px",
    "& button": {
      all: "unset",
      margin: "0",
      borderRadius: "7px",
      cursor: "pointer",
      height: "45px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0",
      fontSize: "16px",
      width: "261px",
      border: "1px solid #003773",
      color: "#003773",
      fontFamily: "'Centrale Sans Medium'",
    },
  },
}));
