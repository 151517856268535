import React, { useEffect, useState } from "react";
import { Box , Container} from "@mui/material";
import { homeServices } from "_services/home.services";
import SectionBreadcrumb from "views/Home/Sections/SectionBreadcrumb";
import useCheckMobileScreen from "components/Mobile/Mobile";
import { ListProductsGeneric } from "components";
import { WindowSharp } from "@mui/icons-material";

const ProductsGeneric = () => {
  const isMobile = useCheckMobileScreen();
  const [dataProduct, setDataProduct] = useState({});

  const breadcrumbData = [{
    name: "INICIO",
    link: "/",
  },{
    name: "PRÓXIMAMENTE",
    link: "",
  }];

  const getCollections = async () => {
    const dataCollection3 = await homeServices.getListadoProductosPromociones(100);
    setDataProduct(dataCollection3);
  }

  useEffect(() => {
   // window.alert('jols')
    getCollections();
}, []);

  return (
    <Container fixed paddingBottom={2}>

    <Box
      sx={{
        width: '100%',
        paddingTop: '0.8rem',
        paddingLeft: '5rem',
        paddingRight: '5rem',
        paddingBottom: '0.8rem',
      }}
    >
      <SectionBreadcrumb route={breadcrumbData} />
      <Box>
        <ListProductsGeneric
          dataProduct={dataProduct}
          isMobile={isMobile}
        />
      </Box>
    </Box>
    </Container>
  )
}

export default ProductsGeneric;