import React from 'react'

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import SectionTitleMain from '../SectionTitles/SectionTitleMain';
import Carousel from "react-elastic-carousel";
import styled from "styled-components";
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import Link from '@material-ui/core/Link';

const breakPoints = [
    { width: 1, itemsToShow: 2 },
    { width: 550, itemsToShow: 3, itemsToScroll: 3 },
    { width: 768, itemsToShow: 5 },
    { width: 1200, itemsToShow: 5 },
  ];


  
const useStyles = makeStyles((theme) => ({
    divItemContainer:{
        width: 200,
        borderLeft: "1px solid #efefef",
        borderRight: "1px solid #efefef",
        marginTop: "20px",
        marginBottom: "20px",
        "&:hover":{
            textDecoration: "none"
        }
      },
      titleItemCard:{
        lineHeight: "normal",
        fontWeight: 700,
        fontSize: 14,
        color: "#0b3196",
        textAlign: "center",
        marginBottom: 0
      },
      divTitleCont:{
        textAlign:"center", 
        width:"100%", 
        height: 34
      },
      titleSection:{
        marginBottom: "25px"
      },
      carousel:{
        borderTop: "2px solid #e5e5e5",
        borderBottom: "2px solid #e5e5e5"
      },
      imageSubCat:{
        maxWidth: "100px",
        height: "168px",
       
      },
}));


export default function  SectionSomosMayoristasMob (props) {
    const classes = useStyles();

    const Item = styled.div`
    display: flex grow
    //justify-content: center;
    //align-items: center;
    //height: 150px;
    background-color: transparent;
    color: #fff;
    font-size: 4em;
    padding: 15px;
  `;
   

  return (
    <Container>
        <div className={classes.titleSection}>
            <SectionTitleMain 
            title = "Somos Mayoristas en Productos para Telecomunicaciones"/>
        </div>
        
        <Carousel  breakPoints={breakPoints}  
                   enableAutoPlay={true} 
                   autoPlaySpeed={5000}
                   className={classes.carousel}
        >
           
            {props.arrayCats.map((o, index) => {
                if(o.cat_cmm_estatus_id === 1000010 && o.hijos.length > 0){
                    return(
                        <Link  href={`categorias/${o.cat_categoria_link}`} className={classes.divItemContainer}
                            key={`carousel-clients-id-${index}`}
                        >
                            <Item key={o.id}
                            >
                            <div>
                                <div style={{textAlign:"center", width:"100%", height: 34}}>
                                <Typography className={classes.titleItemCard}>{o.cat_nombre_tienda}</Typography>
                                </div>
                                
                                <div style={{width: "100%", textAlign: "center"}}>
                                {o.cat_img_link != null ? (
                                    <img className={classes.imageSubCat} src={`${process.env.REACT_APP_API_URL_IMG}${o.cat_img_link}`} />
                                ):(
                                    <BrokenImageIcon style={{color: "#EFEFEF", fontSize: 98}}/>
                                )} 
                                </div>
                            </div>
                            </Item>
                        </Link>
                        
                        
                 )
                }
                })}
            </Carousel>
    </Container>
    
  )
}
