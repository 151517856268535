import React, { useEffect, useState, useContext } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((styles) => ({
    container: {
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: 15,
        paddingRight: 15,
        display: "block",
        width: "100%",
    },
    title: {
        color: "#0b3196",
        margin: 0,
        minHeight: 20,
        fontFamily: "'Centrale Sans Medium'",
        textDecoration: "none",
        display: "inline-block",
        fontSize: "18px",
        [styles.breakpoints.down("sm")]: {
            fontSize: "12px",
        },
    },
    subtitle: {
        color: "#9b9b9b",
        margin: 0,
        minHeight: 20,
        fontFamily: "'Centrale Sans Medium'",
        textDecoration: "none",
        display: "inline-block",
        marginLeft: "15px",
        fontSize: "18px",
        [styles.breakpoints.down("sm")]: {
            fontSize: "12px",
        },
    },
}));


export default function SectionProductSKU(props) {
    const classes = useStyles();
    const [name, setName] = useState(props.productDetails.name);
    useEffect(() => {
        setName(props.productDetails.prod_sku);
    }, []);
    return (
        <div className={classes.container}>
            {/* <Typography className={classes.title}>SKU:</Typography> */}
            <Typography className={classes.subtitle}>{name}</Typography>
        </div>
    );
}
