export function dateDaysCompare(date) {
  let currentDate = new Date();
  let dateComapre = new Date(date);

  // To calculate the time difference of two dates
  let Difference_In_Time = currentDate.getTime() - dateComapre.getTime();

  // To calculate the no. of days between two dates
  let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  return Difference_In_Days;
}
