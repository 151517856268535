

import React, {
    useState,
    useEffect,
  } from "react";
  import { userService } from "_services/user.service";
  import { API_URL } from "_services/api.url.back";
  
  import { Link, useHistory, useParams } from "react-router-dom";
    
  // @material-ui/core components
  import { makeStyles, withStyles } from "@material-ui/core/styles";
  import SectionBreadcrumb from "views/Home/Sections/SectionBreadcrumb.js";
  
  // @material-ui/
  import Typography from "@material-ui/core/Typography";
  import {  Divider } from "@material-ui/core";
  import Collapse from "@material-ui/core/Collapse";
  
  import Avatar from '@material-ui/core/Avatar';
  import Table from "@material-ui/core/Table";
  import TableBody from "@material-ui/core/TableBody";
  import TableCell from "@material-ui/core/TableCell";
  import TableContainer from "@material-ui/core/TableContainer";
  import TableHead from "@material-ui/core/TableHead";
  import TableRow from "@material-ui/core/TableRow";
  

import { getPriceSapFormat } from "helpers/formatPrice";  
  import BrokenImageIcon from '@material-ui/icons/BrokenImage';

  import Grid from "@material-ui/core/Grid";

  import { useAuth } from "hooks/useAuth";
  import useCheckMobileScreen from "components/Mobile/Mobile.js";

    
  const useStyles = makeStyles((theme) => ({
    hide: {
      display: "none !important",
    },
    //alerts
    alerts: {
      color: "#FFFFFF",
      backgroundColor: "#40DF00",
      fontFamily: "'Centrale Sans Light'",
      fontSize: 22,
      "& .MuiAlert-icon": {
        color: "#FFFFFF",
      },
      "&.MuiAlert-standardError": {
        backgroundColor: "#DF2B2B",
      },
    },
    imageProd:{
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: theme.spacing(7),
        height: theme.spacing(7),
      },
      justifyContent: "center",
    },
    root: {
      backgroundColor: "#FFFFFF",
      margin: "auto",
      width: "95%",
      paddingBottom: 30,
    },
    spaceDiv: {
      marginTop: 15,
      marginBottom: 52,
    },
    linkBack: {
      fontFamily: "'Centrale Sans Medium'",
      color: "#0B3196",
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: "19px",
      textAlign: "center",
      paddingLeft: "30px",
      position: "relative",
    },
    linkBackArrow: {
      position: "absolute",
      left: "5px",
      bottom: "-2px",
    },
    title: {
      fontFamily: "'Centrale Sans Bold'",
      textTransform: "uppercase",
      fontSize: 26,
      lineHeight: "32px",
      textAlign: "justify",
      color: "#0C2D9C",
      paddingTop: "39.8px",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    titleDivider: {
      border: "1px solid #0B3196",
      width: "95%",
      margin: "auto",
    },
    gridContent: {
      paddingBottom: 30,
    },
    buttonUpdate: {
      border: "1px solid",
      color: "#0C2D9C",
      backgroundColor: "transparent",
      textTransform: "none",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: 16,
      "&:hover": {
        backgroundColor: "#3e50c5",
        color: "#ffffff",
      },
    },
    buttonUpdateSecond: {
      border: "1px solid",
      backgroundColor: "#0C2D9C",
      color: "#ffffff",
      textTransform: "none",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: 16,
      "&:hover": {
        backgroundColor: "#3e50c5",
        color: "#ffffff",
      },
    },
    inputs: {
      width: "100%",
      border: "1px solid #EAEAEA",
      borderRadius: "2px",
      backgroundColor: "#FFFFFF",
    },
    // Search
    searchDiv: {
      width: "100%",
      backgroundColor: "#F5F5F5",
      border: "1px solid #EAEAEA",
      borderRadius: "2px",
      marginBottom: "39px",
      paddingTop: "10px",
      paddingBottom: "10px",
      paddingLeft: "6px",
    },
    margin: {
      paddingLeft: "6px",
      paddingRight: "13px",
    },
    inlineDiv: {
      display: "inline-block",
      verticalAlign: "middle",
    },
    textField: {
      display: "inline-block",
      paddingLeft: "6px",
      paddingRight: "13px",
    },
    btnSearch: {
      height: "44.8px",
      width: "120px",
      borderRadius: "2px",
      backgroundColor: "#40DF00",
      paddingTop: "12px",
      paddingLeft: "35px",
      paddingBottom: "9px",
      paddingRight: "34px",
      "&:hover": {
        cursor: "pointer",
      },
      [theme.breakpoints.down("sm")]:{
        marginTop: "15px",
      }
    },
    btnSearch2: {
      height: "44.8px",
      //width: "120px",
      borderRadius: "2px",
      backgroundColor: "#40DF00",
      paddingTop: "12px",
      paddingLeft: "35px",
      paddingBottom: "9px",
      paddingRight: "34px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    btnSearchLbl: {
      color: "#FFFFFF",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: "16px",
      fontWeight: "500",
      letterSpacing: "0",
      lineHeight: "19px",
    },
    // Table
    tableHeader: {
      backgroundColor: "#0C2D9C",
      borderRadius: "2px",
      height: "51px",
    },
    tableHeaderTxt: {
      color: "#ffffff",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: "18px",
      lineHeight: "23px",
      fontWeght: "500",
    },
    filterSelect: {
      color: "#0C2D9C",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: "16px",
      fontWeight: "500",
      letterSpacing: "0",
      lineHeight: "19px",
      marginLeft: "6px",
    },
    rowDataProd:{
      color: "#000000",
      fontFamily: "'Centrale Sans Regular'",
      fontSize: "14px",
      letterSpacing: "0",
      lineHeight: "23px",
      width: '11%',
    },
  
    dataCenter:{
      textAlign:"center"
    },
    textField2: {
     
      paddingLeft: "6px",
      paddingRight: "13px",
      [theme.breakpoints.down("sm")]:{
        marginTop: "15px",
      }
    },
    rowData: {
      color: "#000000",
      fontFamily: "'Centrale Sans Regular'",
      fontSize: "18px",
      letterSpacing: "0",
      lineHeight: "23px",
    },
    iconAction: {
      width: "36px",
      marginRight: "5px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    iconActionImg:{
      width: "36px",
      marginRight: "5px",
      marginBottom: "18px !important",
      "&:hover": {
        cursor: "pointer",
      },
    },
    iconDeleteAction: {
      width: "17px",
      height: "22px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paperModal: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
    },
    modalHeader: {
      width: "540px",
      backgroundColor: "#EF0002",
      textAlign: "center",
      paddingTop: "24.8px",
      paddingBottom: "16px",
    },
    modalHeaderTxt: {
      color: "#FFFFFF",
      fontFamily: "'Centrale Sans Bold'",
      fontSize: "26px",
      fontWeight: "bold",
      letterSpacing: "0",
      lineHeight: "32px",
    },
    modalBody: {
      marginTop: "53.2px",
      marginBottom: "78px",
    },
    modalBodyTxt: {
      color: "#DF2B2B",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: "24px",
      fontWeight: "500",
      letterSpacing: "0",
      lineHeight: "29px",
      textAlign: "center",
    },
    modalBtns: {
      marginLeft: "65px",
      marginBottom: "22px",
    },
    modalBtn: {
      boxSizing: "border-box",
      height: "28px",
      width: "204px",
      border: "1px solid #0C2D9C",
      borderRadius: "2px",
      textAlign: "center",
      color: "#0C2D9C",
      marginRight: "7px",
      display: "inline-block",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#0C2D9C",
        color: "#FFFFFF",
      },
    },
    modalBtnTxt: {
      fontFamily: "'Centrale Sans Regular'",
      fontSize: "14px",
      letterSpacing: "0",
      lineHeight: "18px",
      marginTop: "4px",
    },
    /// Detalles
    titleDiv: {
      display: "block",
    },
    mainDataDiv: {
      backgroundColor: "#F2F2F2",
      paddingTop: "27.6px",
      paddingLeft: "18px",
      paddingRight: "6px",
      paddingBottom: "22px",
    },
    cotDetalleTitle: {
      color: "#0C2D9C",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: "22px",
      fontWeight: "500",
      letterSpacing: "0",
      lineHeight: "27px",
      display: "inline-block",
    },
    iconActionEdit: {
      height: "24px",
      width: "28px",
      float: "right",
      display: "inline-block",
      "&:hover": {
        cursor: "pointer",
      },
    },
    cotDetailsDataDiv: {
      paddingLeft: "2px",
      marginTop: "17px",
      width: "100%",
    },
    cotDetailsDataRow: {
      display: "block",
      marginBottom: "12px",
    },
    cotDetailsDataSmall: {
      display: "inline-block",
      width: "239px",
      verticalAlign: "top",
    },
    cotDetalleDataTitle: {
      color: "#444444",
      fontFamily: "'Centrale Sans Bold'",
      fontSize: "18px",
      fontWeight: "bold",
      letterSpacing: "0",
      lineHeight: "23px",
      display: "inline-block",
    },
    cotDetalleDataTxt: {
      color: "#000000",
      fontFamily: "'Centrale Sans Regular'",
      fontSize: "16px",
      letterSpacing: "0",
      lineHeight: "19px",
      display: "inline-block",
      marginLeft: "150px",
    },
    cotDetalleDataFecVig: {
      color: "#000000",
      fontFamily: "'Centrale Sans Regular'",
      fontSize: "16px",
      letterSpacing: "0",
      lineHeight: "19px",
      display: "inline-block",
      marginLeft: "69px",
    },
    cotDetalleDatallePrecio: {
      color: "#000000",
      fontFamily: "'Centrale Sans Regular'",
      fontSize: "16px",
      letterSpacing: "0",
      lineHeight: "19px",
      display: "inline-block",
      marginLeft: "151px",
    },
    cotDetallePersonalTitle: {
      color: "#000000",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: "16px",
      fontWeight: "500",
      letterSpacing: "0",
      lineHeight: "16px",
      display: "block",
    },
    cotDetallePersonalData: {
      color: "#000000",
      fontFamily: "'Centrale Sans Regular'",
      fontSize: "16px",
      letterSpacing: "0",
      lineHeight: "20px",
      display: "block",
    },
    divTableProducts: {
      marginTop: "44px",
      marginBottom: "36px",
      backgroundColor: "#F5F5F5",
    },
    divSubtotals: {
      width: "445px",
      border: "1px solid #EAEAEA",
      backgroundColor: "#F5F5F5",
      paddingTop: "12.8px",
      paddingLeft: "18px",
      paddingRight: "24px",
      paddingBottom: "13px",
      float: "right",
      marginBottom: "41px",
    },
    divSumatorias: {
      display: "block",
      width: "100%",
      minHeight: "275px",
    },
    divSubtotalesRow: {
      display: "block",
      marginBottom: "17px",
    },
    divLineTotal: {
      height: "1px",
      width: "100%",
      border: "1px solid #EAEAEA",
      marginTop: "-20px",
      marginBottom: "12px",
    },
    subtotalTitle: {
      color: "#000000",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: "16px",
      fontWeight: "500",
      letterSpacing: "0",
      lineHeight: "16px",
      display: "inline-block",
      verticalAlign: "top",
    },
    subtotalData: {
      color: "#000000",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: "16px",
      fontWeight: "500",
      letterSpacing: "0",
      lineHeight: "16px",
      display: "inline-block",
      textAlign: "right",
      float: "right",
      verticalAlign: "top",
    },
    totalTitle: {
      color: "#000000",
      fontFamily: "'Centrale Sans Bold'",
      fontSize: "16px",
      fontWeight: "bold",
      letterSpacing: "0",
      lineHeight: "16px",
      verticalAlign: "top",
      display: "inline-block",
    },
    totalData: {
      color: "#000000",
      fontFamily: "'Centrale Sans Bold'",
      fontSize: "16px",
      fontWeight: "bold",
      letterSpacing: "0",
      lineHeight: "16px",
      verticalAlign: "top",
      display: "inline-block",
      textAlign: "right",
      float: "right",
    },
    btnFinalizarCompra: {
      borderRadius: "3px",
      backgroundColor: "#0C2D9C",
      color: "#FFFFFF",
      float: "right",
      paddingTop: "11.8px",
      paddingBottom: "12px",
      paddingLeft: "60px",
      paddingRight: "54px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    divBtnFinalizar: {
      float: "right",
      marginBottom: "47px",
      display: "inline-block",
    },
    btnsDiv: {
      display: "block",
      width: "100%",
      marginBottom: "47px",
    },
    btnFinalizarCompraTxt: {
      fontFamily: "'Centrale Sans Medium'",
      fontSize: "16px",
      fontWeight: "500",
      letterSpacing: "0",
      lineHeight: "19px",
      textAlign: "center",
    },
    divBtnRegresar: {
      display: "inline-block",
  
    },
    btnRegresar: {
      borderRadius: "3px",
      backgroundColor: "#FFFFFF",
      color: "#0C2D9C",
      border: "1px solid #0C2D9C",
      paddingTop: "11.8px",
      paddingBottom: "12px",
      paddingLeft: "60px",
      paddingRight: "54px",
      width: "250px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#0C2D9C",
        color: "#FFFFFF",
      },
      [theme.breakpoints.down("sm")]: {
        width: "180px",
      },
    },
  
    formData: {
      backgroundColor: "#F5F5F5",
      borderRadius: 2,
      margin: "10px 0",
      border: "1px solid #EAEAEA",
      "& *": {
        margin: "3px 8px 2px 3px",
        padding: 0,
        fontFamily: "'Centrale Sans Regular'",
        border: "none",
      },
    },
    dataContainer:{
      marginTop:"15px"
    },
    formDataSubTitleDes: {
      color: "#0C2D9C",
      textTransform: "uppercase",
    },
    btnRegistro:{
      width: "100%",
      color: "#FFFFFF",
      backgroundColor:"#0C2D9C",
      textAlign: "center",
      paddingTop: "10px",
      paddingBottom: "10px",
      borderRadius: "5px",
      marginTop: "10px",
      cursor: "pointer"
  
    },
    titForm:{
      textAlign:"center",
      color: "#0C2D9C",
      width:"100%",
      fontFamily:"'Centrale Sans Medium'",
    },
    itemBtn:{
      marginTop: "25px"
    },
    formContainer:{
      backgroundColor: "#F5F5F5",
      padding: "15px"
    },
  }));
  

export default function DetailProjects(props) {
    const classes = useStyles();
   // const history = useHistory();
    const {id}= useParams();
    const {setOpenAlertF, getUserData} = useAuth();
    const dataUser = getUserData();
    const [dataBreadCrumb, setDataBreadCrumb] = useState([]);
    const [proySelected, setProySelected] = useState({});
    const [proyProds, setProyProds] = useState([]);
  
    const [bool, setBool] = useState(false);
   
  
    const [tituloSeccion, setTituloSeccion] = useState("MIS PROYECTOS");
    const [seccionListado, setSeccionListado] = useState(null);
    const [seccionDetalle, setSeccionDetalle] = useState(classes.hide);
    useEffect(() => {
        setDataBreadCrumb(() => [
          {
            name: "INICIO",
            link: "/",
          },
          {
            name: "MIS PROYECTOS",
            link: "/myprofile/proyectos",
          },
          {
            name:"DETALLE PEDIDO "+ id,
            link:""

          }
        ]);
        openDetails(id)
      }, []);
    
      let isMobile = useCheckMobileScreen();
      

    const openDetails = async (idProy) => {
     
        setTituloSeccion("DETALLE DEL PROYECTO");
        setSeccionListado(classes.hide);
        setSeccionDetalle(null);
        setBool(!bool);
        const proyectList = await userService.getDetalleProyectosBySN(dataUser.usuario.snu_cardcode, idProy);
        //const proyectList = await userService.getProyectsByCardcodeSn("C00174");
         setProySelected(proyectList.ProyectoInfo)
    
         let newProducts = []
         for(let i =0; i<proyectList.ListaProductosProyecto.length; i++ ){
          newProducts.push({
            urlImg: proyectList.ListaProductosProyecto[i]?.imgprod_ruta_archivo? (proyectList.ListaProductosProyecto[i].imgprod_ruta_archivo !="" && proyectList.ListaProductosProyecto[i].imgprod_ruta_archivo !=null) ? proyectList.ListaProductosProyecto[i].imgprod_ruta_archivo.replace('public/',''): null :null,
            nombreArticulo: proyectList.ListaProductosProyecto[i]?.nombreArticulo ?  proyectList.ListaProductosProyecto[i]?.nombreArticulo: 'NA',
            NoParte:proyectList.ListaProductosProyecto[i]?.prod_nombre_extranjero? proyectList.ListaProductosProyecto[i]?.prod_nombre_extranjero: 'NA',
            codigoArticulo:proyectList.ListaProductosProyecto[i]?.codigoArticulo? proyectList.ListaProductosProyecto[i]?.codigoArticulo : 'NA',
            cantidadAcumulada:proyectList.ListaProductosProyecto[i]?.cantidadAcumulada? proyectList.ListaProductosProyecto[i]?.cantidadAcumulada : '0',
            importeAcumulado:proyectList.ListaProductosProyecto[i]?.importeAcumulado ? proyectList.ListaProductosProyecto[i]?.importeAcumulado : '0',
            cotp_precio_base_lista:proyectList.ListaProductosProyecto[i]?.precio ? proyectList.ListaProductosProyecto[i]?.precio : '0',
            cotp_producto_cantidad: proyectList.ListaProductosProyecto[i]?.cantidadAcumulada? proyectList.ListaProductosProyecto[i]?.cantidadAcumulada :'0',
            precio: proyectList.ListaProductosProyecto[i]?.precio ? getPriceSapFormat(proyectList.ListaProductosProyecto[i]?.precio) :'$0.00'
    
          })
         }
         setProyProds(newProducts)
        
    
        
      };

      const formatDateProjects = (date)=>{
        //let newDate =''
        if(date.length==8){
          let year = date.substr(0,4)
          let month = date.substr(4,2)
          let day = date.substr(6,2)
          return day+'/'+getNameMonth(month)+'/'+year
        }else{
          return '00/'+ getNameMonth('01') + '/0000'
        }
      }
      const getNameMonth = (month) =>{
        let _m = "";
        switch (month) {
          case "01":
            _m = "Ene";
            break;
          case "02":
            _m = "Feb";
            break;
          case "03":
            _m = "Mar";
            break;
          case "04":
            _m = "Abr";
            break;
          case "05":
            _m = "May";
            break;
          case "06":
            _m = "Jun";
            break;
          case "07":
            _m = "Jul";
            break;
          case "08":
            _m = "Ago";
            break;
          case "09":
            _m = "Sep";
            break;
          case "10":
            _m = "Oct";
            break;
          case "11":
            _m = "Nov";
            break;
          case "12":
            _m = "Dic";
            break;
        }
        return _m
      }
    
    return (

        <div className={classes.root}>
        <Collapse in={alert.open}>
         
        </Collapse>
  
        <div className={classes.spaceDiv}>
          <SectionBreadcrumb route={dataBreadCrumb} />
          <Typography className={classes.title}>{tituloSeccion}</Typography>
          {isMobile ? <><Divider className={classes.titleDivider} /> <br/></> : ""}
         
      
        </div>
          <div className={seccionDetalle}>
                <div className={classes.mainDataDiv}>
                  <div className={classes.titleDiv}>
                   
                    <Typography className={classes.cotDetalleTitle}>
                      Proyecto {proySelected.idProyecto}
                    </Typography>
                  </div>
                  {proySelected.codigoCliente ? (
                    <div className={classes.cotDetailsDataDiv}>
                      <div className={classes.cotDetailsDataRow}>
                        <Typography className={classes.cotDetalleDataTitle}>
                          Nombre
                        </Typography>
                        <Typography className={classes.cotDetalleDataTxt}>
                          {
                           (proySelected.nombreProyecto!= '' ? proySelected.nombreProyecto : 'Sin nombre')
                          }
                        </Typography>
                      </div>
                      <div className={classes.cotDetailsDataRow}>
                        <Typography className={classes.cotDetalleDataTitle}>
                          Fecha de vigencia
                        </Typography>
                        <Typography className={classes.cotDetalleDataFecVig}>
                          {formatDateProjects(proySelected.fechaVencimiento)}
                        </Typography>
                      </div>
                      <div className={classes.cotDetailsDataRow}>
                        <Typography className={classes.cotDetalleDataTitle}>
                          Importe
                        </Typography>
                        <Typography className={classes.cotDetalleDatallePrecio}>
                          {proySelected.total != null && proySelected.total != undefined ?getPriceSapFormat(proySelected.total):"$0.00"}
                        </Typography>
                      </div>
                      <div className={classes.cotDetailsDataRow}>
                        <Typography className={classes.cotDetalleDataTitle}>
                          Moneda:
                        </Typography>
                        <Typography className={classes.cotDetalleDatallePrecio}>
                          {proySelected.moneda != null && proySelected.moneda}
                        </Typography>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {isMobile ? (
                  <Grid item xs={12} className={classes.gridContent}>
                  {proyProds.map((row) => {
                    return (
                      <div className={classes.formData}>
                        <Grid container spacing={0}>
                          <Grid item xs={12}>
                            <Grid container>
                            <Grid item xs={6}>
                                <Typography className={classes.formDataSubTitleDes}>
                                  Imagen:
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                              <div className={classes.imageProd}>
                                {row.urlImg!=null ? (
                                <Avatar variant="square" src={`${API_URL.split('api')[0]}${row.urlImg}`}></Avatar>
                        ): (<Avatar variant="square" src=""> <BrokenImageIcon /></Avatar>
                        )}
                                </div>
                               
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={6}>
                                <Typography className={classes.formDataSubTitleDes}>
                                  Descripción:
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography>{row.nombreArticulo}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={6}>
                                <Typography className={classes.formDataSubTitleDes}>
                                  No.Parte
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography>{row.NoParte}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={6}>
                                <Typography className={classes.formDataSubTitleDes}>
                                  Cantidad:
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography>{row.cantidadAcumulada}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={6}>
                                <Typography className={classes.formDataSubTitleDes}>
                                  Precio :
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                              {row.cotp_precio_menos_promociones ? (
                                <div>
                                  <div>
                                    <typography>
                                      Precio de Lista <span style={{textDecoration:"line-through"}}> {getPriceSapFormat(row.cotp_precio_base_lista)}</span>
                                    </typography>
                                  </div>
                                  <div>
                                    <typography>
                                      Precio Cotizado <span style={{fontWeight:"bold"}}>  {getPriceSapFormat(row.cotp_precio_menos_promociones)}</span>
                                    </typography>
                                  </div>
                                    
                                </div>
                                ): (
                                  <typography>
                                    `Precio de Lista {getPriceSapFormat(row.cotp_precio_base_lista)}`
                                  </typography>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={6}>
                                <Typography className={classes.formDataSubTitleDes}>
                                  Importe:
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography> {getPriceSapFormat(
                                row.cotp_precio_base_lista * row.cantidadAcumulada
                              )}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  })}
                  </Grid>
  
                ):(
                  <div className={classes.divTableProducts}>
                  <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow className={classes.tableHeader}>
                          <TableCell className={classes.tableHeaderTxt}></TableCell>
                          <TableCell  className={classes.tableHeaderTxt}>
                            Descripción
                          </TableCell>
                          <TableCell style={{textAlign:"center"}} className={classes.tableHeaderTxt}>
                            No. Parte
                          </TableCell>
                          
                          <TableCell style={{textAlign:"center"}} className={classes.tableHeaderTxt}>
                            Cant.
                          </TableCell>
                          <TableCell style={{textAlign:"center"}} className={classes.tableHeaderTxt}>
                            Precio 
                          </TableCell>
                          <TableCell style={{textAlign:"center"}} className={classes.tableHeaderTxt}>
                            Importe
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    
                      <TableBody>
                        {proyProds.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell style={{textAlign:"center"}} className={classes.rowDataProd}>
                              <div className={classes.imageProd}>
                         
                              {row.urlImg!=null ? (
                                <Avatar variant="square" src={`${API_URL.split('api')[0]}${row.urlImg}`}></Avatar>
                        ): (<Avatar variant="square" src=""> <BrokenImageIcon /></Avatar>
                        )}
                                </div>
                            </TableCell>
                            <TableCell  className={classes.rowDataProd}>
                              {row.nombreArticulo}
                            </TableCell>
                            <TableCell style={{textAlign:"center"}} className={classes.rowDataProd}>
                              {row.NoParte}
                            </TableCell>
                           
                            <TableCell style={{textAlign:"center"}} className={classes.rowDataProd}>
                              {row.cantidadAcumulada}
                            </TableCell>
                            <TableCell style={{textAlign:"center"}} className={classes.rowDataProd}>
                            {(row.precio)}
                            </TableCell>
                            <TableCell style={{textAlign:"center"}} className={classes.rowDataProd}>
                              {getPriceSapFormat(
                                row.cotp_precio_base_lista * row.cantidadAcumulada
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                )}
              
                {/* <div className={classes.divSumatorias}>
                  <div className={classes.divSubtotals}>
                    <div className={classes.divSubtotalesRow}>
                      <Typography className={classes.subtotalTitle}>
                        Subtotal
                      </Typography>
                      <Typography className={classes.subtotalData}>
                        {getPriceSapFormat(cotSelected && cotSelected.cot_total_base)}
                      </Typography>
                    </div>
                    <div className={classes.divSubtotalesRow}>
                      <Typography className={classes.subtotalTitle}>
                        Gastos de envío
                      </Typography>
                      <Typography className={classes.subtotalData}>
                        {getPriceSapFormat(cotSelected && cotSelected.cot_costo_envio)}
                      </Typography>
                    </div>
                    <div className={classes.divSubtotalesRow}>
                      <Typography className={classes.subtotalTitle}>
                        Descuento
                      </Typography>
                      <Typography className={classes.subtotalData}>
                        -{getPriceSapFormat(cotSelected && cotSelected.cot_descuento_total)}
                      </Typography>
                    </div>
                    <div className={classes.divSubtotalesRow}>
                      <Typography className={classes.subtotalTitle}>IVA 16%</Typography>
                      <Typography className={classes.subtotalData}>
                        {getPriceSapFormat(cotSelected && cotSelected.cot_iva_cantidad)}
                      </Typography>
                    </div>
                    <div className={classes.divLineTotal}></div>
                    <div className={classes.divSubtotalesRow}>
                      <Typography className={classes.totalTitle}>Total</Typography>
                      <Typography className={classes.totalData}>
                        {getPriceSapFormat(cotSelected && (cotSelected.cot_total_cotizacion + cotSelected.cot_costo_envio))}
                      </Typography>
                    </div>
                  </div>
                </div> */}
                <div className={classes.btnsDiv}>
                    <Link to='/myprofile/proyectos'>
                  <div className={classes.divBtnRegresar}>
                    <div className={classes.btnRegresar}>
                      <Typography
                        className={classes.btnFinalizarCompraTxt}
                        onClick={() => {
                         // closeDetails();
                        }}
                      >
                        Regresar
                      </Typography>
                    </div>
                    
                  </div></Link>
                 { /*<div className={classes.divBtnFinalizar}>
                    <div className={classes.btnFinalizarCompra}>
                      <Typography
                        className={classes.btnFinalizarCompraTxt}
                        //onClick={agregarProductosACarrito}
                        onClick={()=>{
                          window.location.href='/checkoutQuotes'
                        }}
                      >
                        Finalizar compra
                      </Typography>
                    </div>
                  </div>*/}
                </div>
              </div>
      </div>
    )

}