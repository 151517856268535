
import React, { useState, useEffect, Fragment } from "react";
import { checkoutService }  from "_services/checkout.service";
import clsx from "clsx";
import { Button } from "@mui/material";
import { DropzoneDialog } from "material-ui-dropzone";
import { API_URL } from "_services/api.url.back.img";
import { useAuth } from "hooks/useAuth";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";

export const Transferer = ({isTransfer,cartCheckoutData})=>{
    const classes = useStyles();
    const {setOpenAlertF,getUserData, setIsTransferPay} = useAuth();
    const userData = getUserData();
    const [openDropFile, setOpenDropFile]=useState(false)
    const [fileName, setFileName] = useState("");

    const openReferences = () => {
        const url = API_URL + 'CUENTASBANCARIASDIELSA.pdf';
        window.open(url, '_blank');
      }
    
    const handleCloseFile = () => {
        setOpenDropFile(false);
      };

    const handleClickReferenciaButton = () => {
        setOpenDropFile(true);
      }
    
    
      const handleSaveFile = async (files) => {

        const formData = new FormData();
        formData.append("files", files[0]);
    
        formData.append(
          "NumeroOrden", cartCheckoutData.cart.cdc_numero_orden
        );
        formData.append(
          "CreadorSocioNegocioId", //userData.usuario.idUser
          userData.usuario.snu_usuario_snu_id
        );
     
        const load_file_request = await checkoutService.uploadTransfer(
          formData,
          userData.usuario.token
        );
        //here according to the respond of the fetch to create the user, then add value to the message and the type, to be show it
       
        if(load_file_request.data.message =="El comprobante para la transferencia ha sido cargada con éxito"){
          setOpenAlertF({
            message: load_file_request.data.message,
            type: "success",
            open: true,
          });
          setIsTransferPay(true)
        }
    
        if (load_file_request.error === true) {
          setFileName("");
          setOpenAlertF({
            message: load_file_request.data.message,
            type: "error",
            open: true,
          });
          setIsTransferPay(false)
        } else if (load_file_request.error === false) {
          setFileName(load_file_request.data.filename);
          setOpenDropFile(false);
          //setIsTransferPay(false)
        }
      };
    
    return(
    <>
    {isTransfer &&
     <Grid item xs={12} className={classes.grayDiv}>
                 
                  <Paper className={clsx(classes.paperTitle)}>
                    <Typography>Referencias bancarías y comprobante</Typography>
                  </Paper>
                  <Paper className={clsx(classes.paper)}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button variant="contained" onClick={openReferences}>Descargar referencias</Button>
                      </Grid>
                      <Grid item xs={6}>
                      <Button
                  variant="contained"
                  color="primary"
                  className="referenciaButton"
                  onClick={handleClickReferenciaButton}
                >
                  Adjuntar pdf
                </Button>
                <Typography className="filename">{fileName}</Typography>
                      </Grid>
                    </Grid>
                  </Paper>
     </Grid>}

     <div>
        <DropzoneDialog
          open={openDropFile}
          onSave={(files) => handleSaveFile(files)}
          dropzoneText={"Arrastre y suelte el PDF o seleccione un archivo."}
          acceptedFiles={[".pdf"]}
          filesLimit={1}
          showPreviews={true}
          showFileNames={true}
          maxFileSize={5000000} 
          onClose={() => handleCloseFile(this)}
        />
      </div>
    </>
    
    )
}


const useStyles = makeStyles((theme) => ({
    blockOne: {
      paddingRight: 20,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: "inherit",
    },
    grayDiv: {
      marginBottom: 30,
      backgroundColor:'#F8F8F8'
    },
    radioColor: {
      '&.Mui-checked': {
        color: '#0B3196',
      },
    },
    paper: {
      backgroundColor: "#F8F8F8",
      boxShadow: "none",
      borderRadius: 0,
      padding: "15px",
      fontSize: "15px",
      "& h6": {
        fontFamily: "'Centrale Sans Medium'",
        color: "#000000",
        fontSize: "17px",
      },
      textAlign: "left",
    },
    paperTitle: {
      borderRadius: 2,
      backgroundColor: "#F3F3F3",
      padding: 10,
      "& p": {
        color: "#000000",
        fontFamily: "'Centrale Sans Medium'",
        fontSize: 18,
        fontWeight: 500,
        textAlign: "center",
      },
    },
    personalData: {
      backgroundColor: "#0B3196",
      color: "#ffffff",
      "& h6": {
        fontFamily: "'Centrale Sans Medium'",
        color: "#ffffff",
      },
      boxShadow: "none",
      borderRadius: 0,
      padding: "15px",
      fontSize: "15px",
      textAlign: "center",
    },
    usdPrice: {
      color: theme.palette.primary.error,
      fontSize: 16,
      fontWeight: 900,
    },
    selects: {
      background: "#ffffff",
      color: "#0B3196",
      fontWeight: 700,
      border: "2px solid #0B3196",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
    },
    radioTipoPago: {
      "& label.MuiFormControlLabel-root .MuiTypography-body1": {
        color: "#666060",
      },
      "& label.MuiFormControlLabel-root .MuiTypography-body1.Mui-disabled": {
        color: "#ccc6c6",
      },
      "& label.MuiFormControlLabel-root .MuiRadio-colorSecondary.Mui-checked + span.MuiTypography-body1": {
        color: "#0B3196",
        fontWeight: 700,
      },
    },
    listItemIcon: {
      color: "#666060",
    },
  }));