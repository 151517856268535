import { authHeader } from "../_helpers";
import { API_URL } from "./api.url.back";

export const orderService = {
  getOrdersByClientId,
  getOrderById,

  getOrderByIdProducts,
  getOrdersByUserId,
  getOrdersByClientCardcode,
  getOrdersStatus,
  searchOrder,
  getEstados,
  searchOrderEstado,
  getFormasDePago,
  getOrdersStatusST,
};

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    const responseData = {
      error: false,
      data: data,
    };
    if (!response.ok) {
      //responseData.error = true;
      //return responseData;
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function handleResponseAlternative(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    const responseData = {
      error: false,
      data: data,
    };
    if (!response.ok) {
      responseData.error = true;
      return responseData;
    }
    return responseData;
  });
}

function getOrdersByClientId(client_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/compras_finalizadas/list_sn_token/${client_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getOrderById(order_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/compras_finalizadas/getPedidoDetalleByID/${order_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getFormasDePago() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/sap_formas_pago/list`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

/*  */

function getOrderByIdProducts(idorder) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/compras_finalizadas/detalle_socio/${idorder}`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getOrdersByUserId(iduser) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/compras_finalizadas/list_by_user/${iduser}`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getOrdersByClientCardcode(cardcode) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      sn_cardcode: cardcode,
    }),
  };

  return fetch(
    `${API_URL}/compras_finalizadas/getHistorialPedidosByCardCode`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function getOrdersStatus() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/controles_maestros/control/ESTATUS_STATUS_ORDEN_FINALIZADA`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getOrdersStatusST() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/controles_maestros/control_public/ESTATUS_STATUS_ORDEN_FINALIZADA`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function searchOrder(search) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      prod_search_value: search,
    }),
  };

  return fetch(`${API_URL}/compras_finalizadas/GetBySkuNombre`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function getEstados(idPais) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${API_URL}/pais/getEstadoByIdPais/${idPais}`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function searchOrderEstado(estado) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      estpa_estado_pais_id: estado,
    }),
  };

  return fetch(`${API_URL}/compras_finalizadas/GetByEstado`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}
