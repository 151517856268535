import React from 'react';
import Carousel from "react-elastic-carousel";
import './SmallRelated.css';
import { getPriceSapFormat } from 'helpers/formatPrice';
import { useAuth } from "hooks/useAuth";
import {addCartBusinnesPartner} from "utils/addCartBusinnesPartner";
import {addCartLocal } from "utils/addCartLocal";

export const SmallRelated = ({ skus }) => {
    const {isLogged, getUserData, setOpenAlertF, setCarritoItems, carritoItems, getAccountIsActive} = useAuth();
    const dataUser= getUserData();
    const isActive = isLogged ? getAccountIsActive() : false
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 4 },
  ];

  const addToCart =  (idProduct, prod) =>{
   
    if(isLogged){
      //TODO trabajar este metodo
      // accountService.addCartClientes({ type: 2, userId: dataUser.usuario.snu_usuario_snu_id, productId: prod.skuId})
       //accountService.getClientsInteractionsProduct({type:2, productId: prod.skuId})
    addCartBusinnesPartner(1, prod, dataUser.usuario.snu_sn_socio_de_negocio_id,idProduct).then(({ message, type,isAmount }) => {
    
      if(!isAmount) {setCarritoItems(carritoItems +1 ); }
     setOpenAlertF({ open:true, type:type,message:message });
    })
  }else {
    setOpenAlertF({ open:false, type:'',message:'' });

    addCartLocal(1, prod).then(({ message, type,isAmount }) => {
    
      if(!isAmount) {setCarritoItems(carritoItems +1 ); }
     setOpenAlertF({ open:true, type:type,message:message });
    })
    // 
  }
  
  }

  return (
      <Carousel breakPoints={breakPoints} renderArrow={CustomArrow}>
        {skus.map(product => (
          <div key={product.skuId} className="product-card">
            <img src={product.imagen} alt={product.nombre} className="product-image" />
            <div className="product-details">
              <h3 className="product-brand">{product.brand}</h3>
              <p className="product-name">{product.nombre}</p>
              <div className="price">
                <span className="current-price">{getPriceSapFormat(product.precioFinal)}</span>
                <span className="old-price">{getPriceSapFormat(product.precioLista)}</span>
              </div>
              { isLogged && isActive ?
              <button className="add-to-cart" aria-label={`Añadir ${product.nombre} al carrito`} 
              onClick={() => {
                addToCart(product.skuId, product);
              }}
              > 
                Añadir al carrito
              </button> : ""
              }
            </div>
          </div>
        ))}
      </Carousel>
    
  );
};

const CustomArrow = ({ type, onClick, isEdge }) => {
  return (
    <button
      onClick={onClick}
      disabled={isEdge}
      className={`carousel-arrow ${type === "NEXT" ? "next" : "prev"}`}
      aria-label={type === "NEXT" ? "Siguiente" : "Anterior"}
    >
      {type === "NEXT" ? ">" : "<"}
    </button>
  );
};


