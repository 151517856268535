import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { API_URL } from "_services/api.url.back";
// @material-ui/icons
// core components
// sections for this page

import { Link } from "react-router-dom";

import SectionProductName from "./Sections/SectionProductName.js";
import SectionProductBrand from "./Sections/SectionProductBrand.js";
import SectionProductSKU from "./Sections/SectionProductSKU.js";
import SectionProductBullets from "./Sections/SectionProductBullets.js";
import SectionProductStock from "./Sections/SectionProductStock.js";
import SectionProductButtonAddCart from "./Sections/SectionProductButtonAddCart.js";
import SectionProductPrice from "./Sections/SectionProductPrice.js";

import SectionProductClientRating from "./Sections/SectionProductRating.js";

const useStyles = makeStyles((styles) => ({
  hide: {
    display: "none !important",
  },
  mainBackground: {
    backgroundColor: "#FFF",
  },
  imageDiv: {
    width: "21%",
    paddingLeft: "12px",
    paddingRight: "12px",
    display: "inline-block",
    verticalAlign: "top",
  },
  image: {
    width: "100%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  detailsDiv: {
    width: "51%",
    display: "inline-block",
    paddingLeft: "15px",
  },
  container: {
    border: "1px solid #eaeaea",
    marginLeft: 0,
    marginRight: 0,
    marginTop: 15,
    marginBottom: 15,
    padding: 5,
  },
  divButton: {
    width: "28%",
    display: "inline-block",
    verticalAlign: "top",
    borderLeft: "1px solid #eaeaea",
    paddingLeft: "15px",
    paddingRight: "15px",
    marginTop: "15px",
    marginBottom: "15px",
  },
  discountFlag: {
    width: "130px",
    backgroundColor: "#E41212",
    height: "24px",
    display: "inline-block",
    float: "left",
    verticalAlign: "top",
    fontFamily: "'Centrale Sans Regular Regular Italic'",
    color: "#FFFFFF",
    fontSize: "14px",
    textAlign: "center",
    borderRadius: "2px",
    marginTop: "12px",
  },
  banderaDescuento: {
    width: 130,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
    fontSize: 14,
    lineHeight: "14px",
    color: "#FFF",
    backgroundColor: "#E41212",
    borderRadius: "2px",
    fontFamily: "'Centrale Sans Medium'",
    marginTop: "5px",
    fontStyle: "italic",
    fontWeight: 600,
  },
  banderaDescuentoVacia: {
    height: 21,
    marginTop: -5,
    marginBottom: 10,
    marginTop: "5px",
  },
}));

function createArrImgs(id, url, alt) {
  let urlA =''
  if(url.includes('https://ftp3.syscom.mx')){
    urlA = url
  }else{
    urlA = API_URL + url;

    urlA = urlA.replace("api./public/", "");
  }
  return { id, urlA, alt };
}




export default function CategoryProductCard(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [discount, setDiscount] = useState("");
  const [hide, setHide] = useState(classes.hide);
  const [descuento, setDescuento] = useState(null);

  const setFlags = () => {
    let _pL = props.product.priceList;
    let _pC = props.product.priceClient;
    let _ds = 0;
    if (_pL !== _pC && _pL !== 0) {
      _ds = (1 - parseInt(_pC) / parseInt(_pL)) * 100;
    }
    if (_ds !== 0) {
      /*
            setHide(null);
            setDiscount("-" + _ds + "% Descuento");
            */
    }
  };
 
  useEffect(() => {
   
    setFlags();
    getDescuento();
  }, []);

 
  

 
  const getDescuento = () => {
    //const res1 = props.product.precioMenosDescuento * 100;
    //const res2 = res1 / props.product.DescuentoDielsaFijo;
    //const result = Math.round((res2 - 100) * 100) / 100;
    // const result = (()/)-100;
    //setDescuento(result);

    if (props.product.tipoPromocionFinal === "Porcentaje") {
      if (props.product.precioPromocionDielsaBool) {
        if (props.product.DescuentoDielsaFijo > 0) {
          setDescuento(
            (
              100 -
              (props.product.precioFinal * 100) / props.product.precioLista
            ).toFixed()
          );
        }
      } else {
        if (props.product.precioMenosDescuento < props.product.precioLista) {
          setDescuento(
            (
              100 -
              (props.product.precioMenosDescuento * 100) /
                props.product.precioLista
            ).toFixed()
          );
        }
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.imageDiv}>
        {/* <div className={classes.discountFlag + " " + hide}>{discount}</div> */}
        {descuento != null ? (
          <div className={classes.banderaDescuento}>
            <span>{descuento}</span>
            <span> % Descuento</span>
          </div>
        ) : (
          <div className={classes.banderaDescuentoVacia}></div>
        )}
        <Link to={`/product?prod=${props.product.productoId}&sku=${props.product.skuId}`}>
          <img
            className={classes.image}
            src={props.product.imagen}
          />
        </Link>
      </div>
      <div className={classes.detailsDiv}>
        <SectionProductBrand
          productDetails={props.product}
          gridView={props.gridView}
        />
        <SectionProductName
          productDetails={props.product}
          gridView={props.gridView}
        />
        <SectionProductSKU
          productDetails={props.product}
          gridView={props.gridView}
        />
        <SectionProductClientRating 
          className={classes.space}
          productDetails={props.product}
          gridView={props.gridView}
        />

        <SectionProductBullets
          productDetails={props.product}
          gridView={props.gridView}
        />
      </div>
      <div className={classes.divButton}>
        {JSON.parse(localStorage.getItem("client")) ? (
          <SectionProductStock
            productDetails={props.product}
            gridView={props.gridView}
          />
        ) : (
          ""
        )}

       
          <SectionProductPrice
            productDetails={props.product}
            gridView={props.gridView}
          />
        
        <SectionProductButtonAddCart
          productDetails={props.product}
          gridView={props.gridView}
        
        />
      </div>
    </div>
  );
}
