let menu_roles = [
  {
    menu: "Mi cuenta",
    key: "perfil",
    key_id: 0,
    permisos: [
      {
        titulo: "Ver todo el módulo de mi cuenta",
        key: "view",
        permiso: true,
      },
      {
        titulo: "Cambiar contraseña y datos de usuario",
        key: "edit",
        permiso: true,
      },
    ],
    submenu: [
      {
        menu: "Mis direcciones",
        key: "direcciones",
        key_id: 1,
        permisos: [
          {
            titulo: "Ver direcciones de envío",
            key: "view",
            permiso: true,
          },
          {
            titulo:
              "Actualizar direcciones de envío y de ellas seleccionar la predeterminada",
            key: "edit",
            permiso: true,
          },
          {
            titulo: "Crear nuevas direcciones de envío",
            key: "create",
            permiso: true,
          },
          {
            titulo: "Eliminar direcciones de envío",
            key: "delete",
            permiso: true,
          },
        ],
      },
    ],
  },
  {
    menu: "Mi Estado de Cuenta",
    key: "facturas",
    key_id: 3,
    permisos: [
      {
        titulo: "Acceso módulo de estado de cuenta",
        key: "view",
        permiso: true,
      },
      {
        titulo: "Acceso a estado de cuenta y consultar mi crédito",
        key: "view_credit",
        permiso: true,
      },
    ],
  },
  {
    menu: "Mis pedidos",
    key: "pedidos",
    key_id: 4,
    permisos: [
      {
        titulo: "Acceso módulo de mis pedidos",
        key: "view",
        permiso: true,
      },
      {
        titulo: "Cancelar pedidos",
        key: "edit",
        permiso: true,
      },
    ],
  },
  /*{
    menu: "Mis cotizaciones y proyectos",
    key: "cotizaciones",
    key_id: 5,
    permisos: [
      {
        titulo: "Historial de cotizaciones y proyectos",
        key: "view",
        permiso: true,
      },
      {
        titulo: "Modificación de cotizaciones de cotizaciones y proyectos",
        key: "edit",
        permiso: true,
      },
      {
        titulo: "Crear cotizaciones y proyectos",
        key: "create",
        permiso: true,
      },
      {
        titulo: "Eliminar cotizaciones y proyectos",
        key: "delete",
        permiso: true,
      },
    ],
  },
  {
    menu: "Mis favoritos",
    key: "favoritos",
    key_id: 6,
    permisos: [
      {
        titulo: "Ver mi lista de favoritos",
        key: "view",
        permiso: true,
      },
      {
        titulo: "Actualizar mi lista de favoritos",
        key: "edit",
        permiso: true,
      },
    ],
  },*/
];

export default menu_roles;
