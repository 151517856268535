import React, { useContext, createContext, } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import SectionProductClientRating from "./SectionProductClientRating.js";

const useStyles = makeStyles((styles) => ({
    container: {
        width: "100%",
        display: "inline-block",
        color: "#686868",
    },
    title: {
        fontSize: 18,
        textAlign: "justify",
        fontFamily: "'Centrale Sans Medium'",
        color: "#181818",
        lineHeight: "20px",
        [styles.breakpoints.down("sm")]: {
            fontSize: "18px",
            width: "70%",
            display: "inline-block",
            verticalAlign: "top",
        },
    },
    date: {
        fontSize: 14,
        color:"#9B9B9B",
        textAlign: "justify",
        fontFamily: "'Centrale Sans Regular'",
        fontWeight: "400",
        [styles.breakpoints.down("sm")]: {
            lineHeight: "12px",
            fontSize: 14,
        },
    },
}));


export default function SectionProductClientCommentName(props) {
    const classes = useStyles();
    const formatDate = (date) => {
        let _d = date.split("T");
        let _days = _d[0].split("-");
        return _days[2] + "/" + mesEsp(_days[1]) + "/" + _days[0];
    };
    const mesEsp = (dateNum) => {
        switch (dateNum) {
            case "01": return "Enero"; break;
            case "02": return "Febrero"; break;
            case "03": return "Marzo"; break;
            case "04": return "Abril"; break;
            case "05": return "Mayo"; break;
            case "06": return "Junio"; break;
            case "07": return "Julio"; break;
            case "08": return "Agosto"; break;
            case "09": return "Septiembre"; break;
            case "10": return "Octubre"; break;
            case "11": return "Noviembre"; break;
            case "12": return "Diciembre"; break;
        }
    };
    return (
        <div className={classes.container}>
            <Typography className={classes.title}>{props.productDetails.name}</Typography>
            <SectionProductClientRating productDetails={props.productDetails} />
            <Typography className={classes.date}>{formatDate(props.productDetails.date)}</Typography>
        </div>
    );
}
