import React from 'react';
import { useEffect, useState } from 'react';

export const Banners = ({ category, subCategory }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1333);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1333);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const imageSrc = isSmallScreen
    ? `${process.env.REACT_APP_API_URL_IMG}banners/${category}/${subCategory}_m.webp`
    : `${process.env.REACT_APP_API_URL_IMG}banners/${category}/${subCategory}.webp`;

  return (
    <div style={{ width: '100%' }}>
      <img 
        src={imageSrc} 
        style={{
          width: '100%',
          height: 'auto',
          maxHeight: '200px',
          objectFit: 'cover'
        }}
        alt={`Banner de ${category} - ${subCategory}`}
      />
    </div>
  );
};

export default Banners;