import React, { useEffect, useRef, useState, Fragment } from "react";
import clsx from "clsx";
import { checkoutService } from "../../_services/checkout.service";
import { API_URL } from "../../_services/api.url.back.img";
import iconEliminar from "../../assets/img/Dielsa/Icon_Eliminar.svg";
import CheckoutSummary from "./CheckoutSummary";
import { getPriceSapFormat } from "helpers/formatPrice";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Divider, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { DropzoneDialog } from "material-ui-dropzone";
import { useAuth } from "hooks/useAuth";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.table, //theme.palette.common.black
    color: "#003773",
    fontSize: 12,
    fontFamily: "'Centrale Sans Medium'",
    borderBottom: "1px solid #40df00",
  },
  body: {
    fontSize: 12,
    border: "none",
    color: "#4A4A4A",
    fontFamily: "'Centrale Sans Medium'",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "transparent",
    },
  },
}))(TableRow);

const columns = [
  { id: "img", label: "Imagen", minWidth: 50 },
  { id: "id", label: "ID", minWidth: 50 },
  { id: "sku", label: "SKU", minWidth: 50 },
  { id: "name", label: "Nombre", minWidth: 50, maxWidth: 300 },
  { id: "status", label: "Estado", minWidth: 50 },
  {
    id: "price",
    label: "Precio",
    minWidth: 50,
    align: "left",
    format: (value) => getPriceSapFormat(value),
  },
  {
    id: "precioFinal",
    label: "Precio tras descuento",
    minWidth: 50,
    align: "left",
    format: (value) => getPriceSapFormat(value),
  },
  {
    id: "qty",
    label: "Cantidad",
    minWidth: 50,
    align: "left",
  },
  {
    id: "total",
    label: "Total",
    minWidth: 50,
    align: "left",
    format: (value) => getPriceSapFormat(value),
  },
];

function createData(
  img,
  id,
  sku,
  name,
  status,
  precioBase,
  precioUnidad,
  precioFinal,
  precioTipoDescuento,
  qty,
  showMessage
) {
  const total = qty * precioFinal;

  return {
    img,
    id,
    sku,
    name,
    status,
    precioBase,
    precioUnidad,
    precioFinal,
    precioTipoDescuento,
    qty,
    total,
    showMessage
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 640,
  },
  table: {
    minWidth: 700,
  },
  tdTitle: {
    color: "#0B3196",
    textAlign: "justify",
  },
  paper: {
    backgroundColor: theme.palette.primary.table,
    borderRadius: 0,
    boxShadow: "none",
    margin: "20px 0",
  },
  referenciaGrid: {
    fontFamily: "'Centrale Sans Light'",
    fontSize: 16,
    fontWeight: 300,
    "& p": {
      color: "#0B3196",
    },
    "& .referenciaInput": {
      marginTop: "5px",
      marginRight: "8px",
      maxWidth: "400px",
      "& input": {
        border: "1px solid #EAEAEA",
        borderRadius: 3,
        backgroundColor: "#F5F5F5",
        height: "0.1876em",
      },
    },
    "& div.MuiFormControl-marginNormal": {
      marginTop: 5,
    },
    "& .referenciaButton": {
      fontWeight: 300,
      fontSize: "14px",
      color: "#ffffff",
      backgroundColor: "#0b3196",
      marginTop: "5px",
      marginRight: "20px",
      boxShadow: "none",
      border: "1px solid #0b3196",
      borderRadius: "5px",
      maxWidth: "193px",
      height: "42px",
    },
    "& p.filename": {
      fontWeight: 500,
      fontSize: "16px",
      textDecoration: "underline",
      color: "#0b3196",
      margin: "auto 0",
    },
  },
  stockDisponible: {
    color: "#40DF00",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
  },
  stockNoDisponible: {
    color: "#DF2B2B",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
  },
  stockValue: {
    color: "#0b3196",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 18,
  },
  hiddenElements: {
    display: "none",
  },
  productsTable: {
    "& td": {
      color: "#000000",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: "18px",
      textAlign: "justify",
      padding: 10,
    },
    border: "1px solid #EAEAEA",
    backgroundColor: "#F5F5F5",
  },
  divPrices: {
    display: "flex",
  },
  basePrice: {
    color: "#979797",
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "'Centrale Sans Light'",
  },
  unitPrice: {
    color: "#0C2D9C",
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "'Centrale Sans Light'",
  },
  promoPrice: {
    color: "#E41212",
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "'Centrale Sans Light'",
  },
  descriptionPrice: {
    color: "#000000",
    fontSize: 12,
    fontWeight: 300,
    fontFamily: "'Centrale Sans Light'",
    paddingLeft: 5,
    paddingRight: 5,
    minWidth: 110,
    textAlign: "left",
  },
  listProductName: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "'Centrale Sans Medium'",
  },
  customIconDelete: {
    width: 25,
    "&:hover": {
      cursor: "pointer",
    },
  },
  costDiv: {
    float: "right",
    width: 400,
    border: "1px solid #EAEAEA",
    borderRadius: 3,
    backgroundColor: "#F5F5F5",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      float: "none",
    },
  },
  costData: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "30px",
    "& .costResponse": {
      textAlign: "end",
    },
  },
  couponForm: {
    padding: 15,
    "& button.couponSubmitButton": {
      height: 25,
      width: 168,
      border: "1px solid #0B3196",
      borderRadius: 2,
      display: "block",
      textTransform: "none",
      background: "transparent",
      color: "#0B3196",
      position: "relative",
      "& span": {
        position: "absolute",
        bottom: 0,
        right: 0,
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
  couponInput: {
    height: 22,
    width: 168,
    borderRadius: 2,
    backgroundColor: "#FFFFFF",
    verticalAlign: "top",
    "& .MuiOutlinedInput-root": {
      height: 22,
    },
    "& input": {
      color: "#0B3196",
      fontFamily: "'Centrale Sans Regular'",
      fontSize: 12,
    },
  },
  checkCoupon: {
    verticalAlign: "bottom",
    marginLeft: 10,
  },
  //alerts
  alerts: {
    color: "#FFFFFF",
    backgroundColor: "#40DF00",
    fontFamily: "'Centrale Sans Light'",
    fontSize: 22,
    "& .MuiAlert-icon": {
      color: "#FFFFFF",
    },
    "&.MuiAlert-standardError": {
      backgroundColor: "#DF2B2B",
    },
    "&.MuiAlert-standardInfo": {
      backgroundColor: "#0091df",
    },
  },
}));

export default function CheckoutCheck1(props) {
  const classes = useStyles();
  const {setOpenAlertF, carritoItems, setCarritoItems, getUserData} = useAuth();
  const userData =getUserData();
  //declare form inputs
  let coupon = useRef(null);
  let referencia = useRef(null);

 
  const [openDropFile, setOpenDropFile] = useState(false);
  const [fileName, setFileName] = useState("");
  const [rows, setRows] = useState([]);
  const [cart, setCart] = useState({});
  const [cartItems, setCartItems] = useState([]);
  const [couponCode, setCouponCode] = useState(props.cartCheckoutData.coupon);
  const [couponApplied, setCouponApplied] = useState(
    props.cartCheckoutData.coupon !== null
  );
  const [couponLabel, setCouponLabel] = useState(
    props.cartCheckoutData.coupon !== null ? "Cupón aplicado" : "Aplicar cupón"
  );

  const getOrdenPago = async () => {
    const file = await checkoutService.getPurchaseOrder(props.cartCheckoutData.cart.cdc_numero_orden);
    setFileName(file.pds_ruta_archivo != null ?  file.pds_ruta_archivo.odc_nombre_archivo : '');
  }

  useEffect(() => {
    getOrdenPago();
  }, []);

  useEffect(() => {
    coupon.current.value = props.cartCheckoutData.coupon;
    referencia.current.value = props.cartCheckoutData.referencia;

    if (
      props.cartCheckoutData.cart.productos &&
      props.cartCheckoutData.cart.productos.length > 0
    ) {
      setCart(props.cartCheckoutData.cart);
      setCartItems(props.cartCheckoutData.cart.productos);
      prepareTable();
    }
  }, [props.cartCheckoutData.cart]);

  const prepareTable = () => {
    let rows = [];

    props.cartCheckoutData.cart.productos.map((prod) => {
      let imgSrc ="http://wws.com.pa/wp-content/plugins/wordpress-ecommerce/marketpress-includes/images/default-product.png";

      if (prod.imagenes && prod.imagenes !== null) {
        imgSrc =prod.imagenes.imgprod_ruta_archivo.includes('https://ftp3.syscom.mx') ? prod.imagenes.imgprod_ruta_archivo: `${API_URL}${prod.imagenes.imgprod_ruta_archivo.split("public")[1]}`;
      }

      let precioBase = prod.precioBaseFinal;
      let precioUnidad = 0;
      let precioFinal = prod.precioFinal;
      let precioTipoDescuento = "";

      // if type stock inactivo, only show the line with basic price that is finalPrice(could be bigger if the selected payment in card)
      if (prod.prod_tipo_precio_base === "Stock Inactivo") {
        precioBase = prod.precioFinal;
        precioTipoDescuento = "especial";
      } else {
        if (prod.precioPromocionDielsaBool) {
          precioUnidad = prod.DescuentoDielsaFijo;
        } else {
          if (prod.precioDespuesDePromocion) {
            precioUnidad = parseFloat(prod.precioDespuesDePromocion);
          }
        }

        if (prod.promocion.length > 0) {
          precioTipoDescuento += "promo ";
        }
        if (prod.cupon.length > 0) {
          precioTipoDescuento += "cupon";
        }
      }

       // Evaluación si poner el precio del producto del proyecto
       precioFinal= (prod.projectProduct && ( prod.projectProductPrice < prod.precioFinal || prod.precioFinal ===0) ? prod.projectProductPrice : prod.precioFinal)
       // precioFinal = (prod.projectProductPrice < prod.precioFinal && prod.projectProduct) ? prod.projectProductPrice : prod.precioFinal;
        let showMessage = (prod.projectProduct && ( prod.projectProductPrice < prod.precioFinal || prod.precioFinal ===0)? `No. de acuerdo ${prod.projectProducNoAcuerdo}` : null)
  
      rows.push(
        createData(
          <Avatar alt="Product" src={imgSrc} />,
          <span className={classes.hiddenElements}>
            {prod.pcdc_prod_producto_id}
          </span>,
          <span className={classes.hiddenElements}>{prod.prod_sku}</span>,
          <Fragment>
            <Typography className={classes.listProductName}>
              {`${prod.prod_nombre} [${prod.prod_nombre_extranjero}]`}
            </Typography>
            <Typography>
              {prod.prod_total_stock > 0
                ? "Envío en 1 día"
                : prod.aplicaBackOrder
                ? `Envío en ${prod.prod_dias_resurtimiento + 1} días`
                : "No disponible"}
            </Typography>
          </Fragment>,
          <span className={classes.stockValue}>
            {`En Stock (${prod.prod_total_stock})`}
          </span>,
          precioBase,
          precioUnidad,
          precioFinal,
          precioTipoDescuento,
          prod.pcdc_producto_cantidad,
          showMessage,
        )
      );
    });

    setRows(rows);
  };

  const handleChangeQty = async (row, qty, action) => {

    let totalQty = 0;
    let callUpdateMethod = false;
    props.cartCheckoutData.cart.productos.map((item) => {
      if(item.pcdc_prod_producto_id === row.id.props.children) {
        totalQty += item.totalCantidadReal;
      }
    });

    if(action === "minus") {
      if (totalQty === 1) {
        setOpenAlertF({
          message: "No puede ser cantidades inferiores a 1",
          type: "error",
          open: true,
        });
      } else {
        totalQty = totalQty - 1;
        callUpdateMethod = true;
      }
    } else {
      totalQty = totalQty + 1;
      callUpdateMethod = true;
    }

    prepareTable();

    if (callUpdateMethod) {
      setOpenAlertF({
        message: `Cantidad actualizada a ${totalQty}`,
        type: "info",
        open: true,
      });
      // document.getElementById(`qty_${row.id.props.children}`).value = totalQty;
      updateQtyRequest(row.id.props.children, totalQty);
    }
  };

  const updateQtyRequest = async (product_id, qty) => {
    const response = await checkoutService.addItemCart(
      props.cartCheckoutData.client_id,
      product_id,
      qty
    );

    if (response.error) {
      setOpenAlertF({
        message:
          "Hubo un error al intentar actualizar la cantidad del producto en el carrito.",
        type: "error",
        open: true,
      });
    } else {
      props.getCartByClientId();

      /* set to null several values to force to calculate shipping cost */
      props.handleUpdateCartCheckoutData("order_shipping_method", null);
      props.handleUpdateCartCheckoutData("order_shipping_method_info", {});
      props.handleUpdateCartCheckoutData("order_shipping_address", null);
      props.handleUpdateCartCheckoutData("order_shipping_address_info", {});
      props.handleUpdateCartCheckoutData("order_warehouse", null);
      props.handleUpdateCartCheckoutData("order_warehouse_info", {});
      props.handleUpdateCartCheckoutData("order_payment_method", null);
      props.handleUpdateCartCheckoutData("order_payment_method_info", {});
      props.handleUpdateCartCheckoutData("cart_shipping", 0);
      props.handleUpdateCartCheckoutData("fletera", 0);
    }
  };

  const deleteProduct = async (row) => {
    setCarritoItems(carritoItems-1);
    let producto = cartItems.filter(
      (prod) => prod.pcdc_prod_producto_id === row.id.props.children
    );

    const response = await checkoutService.deleteItemCart(
      props.cartCheckoutData.client_id,
      producto[0].pcdc_prod_producto_id
    );

    if (response.error) {
      setOpenAlertF({
        message: "No se pudo eliminar el producto del carrito",
        type: "error",
        open: true,
      });
    } else {
      setOpenAlertF({
        message: "Producto eliminado con éxito",
        type: "success",
        open: true,
      });

      props.getCartByClientId();

      /* set to null several values to force to calculate shipping cost */
      props.handleUpdateCartCheckoutData("order_shipping_method", null);
      props.handleUpdateCartCheckoutData("order_shipping_method_info", {});
      props.handleUpdateCartCheckoutData("order_shipping_address", null);
      props.handleUpdateCartCheckoutData("order_shipping_address_info", {});
      props.handleUpdateCartCheckoutData("order_warehouse", null);
      props.handleUpdateCartCheckoutData("order_warehouse_info", {});
      props.handleUpdateCartCheckoutData("order_payment_method", null);
      props.handleUpdateCartCheckoutData("order_payment_method_info", {});
      props.handleUpdateCartCheckoutData("cart_shipping", 0);
      props.handleUpdateCartCheckoutData("fletera", 0);

      //if is the last item and the response to remove it was succesfull, then remove shopping cart in localStorage and also globaldata of cart, redirect to catalog
      if (cartItems.length === 1) {
        props.handleUpdateCartCheckoutData("cart", {});
        setCart({});
        setCartItems([]);
        setRows([]);

        setOpenAlertF({
          message:
            "Producto eliminado con éxito. Su carrito actualmente se encuentra sin productos",
          type: "info",
          open: true,
        });
      }
    }
  };

  const guardarReferencia = (event) => {
    event.preventDefault();
    props.handleUpdateCartCheckoutData(
      referencia.current.name,
      referencia.current.value
    );
  };

  const sendReferencia = async (event) => {
    event.preventDefault();

    const response = await checkoutService.saveReference(
      props.cartCheckoutData.client_id,
      referencia.current.value
    );
  };

  const addCoupon = async (event) => {
    event.preventDefault();

    const response = await checkoutService.addCoupon(
      props.cartCheckoutData.client_id,
      event.target.coupon.value //coupon.current.value
    );

    if (response.error) {
      if (response.data.message === "Cupon ya a sido utilizado") {
        setOpenAlertF({
          message: "El cupón ya ha sido utilizado",
          type: "error",
          open: true,
        });
      } else {
        setOpenAlertF({
          message: "Hubo un error al intentar aplicar el cupón",
          type: "error",
          open: true,
        });
      }
    } else {
      if (response.data.message === "Cupon NO EXISTE") {
        setOpenAlertF({
          message: "El cupón ingresado no existe",
          type: "error",
          open: true,
        });
      } else {
        setOpenAlertF({
          message: "El cupón ingresado se aplicó con éxito",
          type: "success",
          open: true,
        });
        props.getCartByClientId();
        props.handleUpdateCartCheckoutData("coupon", coupon.current.value);
      }
    }
  };

  const deleteCoupon = async () => {
    const response = await checkoutService.deleteCoupon(
      props.cartCheckoutData.client_id
    );
    if (response.error) {
      //
    } else {
      setCouponLabel("Aplicar cupón");
      setCouponApplied(false);
      props.getCartByClientId();
      props.handleUpdateCartCheckoutData("coupon", null);
    }
  };

  const couponToggle = async () => {
    if (couponApplied === true) {
      deleteCoupon();
    } else {
      const response = await checkoutService.addCoupon(
        props.cartCheckoutData.client_id,
        couponCode
      );
      if (response.error) {
        if (response.data.message === "Cupon ya a sido utilizado") {
          setCouponLabel("El cupón ya ha sido utilizado");
          setCouponApplied(false);
        } else {
          setCouponLabel("Hubo un error al intentar aplicar el cupón");
          setCouponApplied(false);
        }
      } else {
        if (response.data.message === "Cupon NO EXISTE") {
          setCouponLabel("El cupón ingresado no existe");
          setCouponApplied(false);
        } else {
          let _txtC =
            response.data.tipoDescuento === "Porcentaje"
              ? response.data.CantidadExacta + "% descontado"
              : "$" + getPriceSapFormat(response.data.CantidadExacta) + " descontado";
          setCouponLabel("Cupón aplicado. " + _txtC);
          setCouponApplied(true);
          props.getCartByClientId();
        }
      }
    }
  };
  
  const handleClickReferenciaButton = () => {
    setOpenDropFile(true);
  }

  const handleSaveFile = async (files) => {

    const formData = new FormData();
    formData.append("files", files[0]);

    formData.append(
      "odc_numero_orden", props.cartCheckoutData.cart.cdc_numero_orden
    );
    formData.append(
      "odc_usu_usuario_creador_id", //userData.usuario.idUser
      userData.usuario.snu_usuario_snu_id
    );
 
    const load_file_request = await checkoutService.uploadPurchaseOrder(
      formData,
      userData.usuario.token
    );
    //here according to the respond of the fetch to create the user, then add value to the message and the type, to be show it
   
    if(load_file_request.data.message =="La orden de compra ha sido cargada con éxito"){
      setOpenAlertF({
        message: load_file_request.data.message,
        type: "success",
        open: true,
      });
    }

    if (load_file_request.error === true) {
      setFileName("");
      setOpenAlertF({
        message: load_file_request.data.message,
        type: "error",
        open: true,
      });
    } else if (load_file_request.error === false) {
      setFileName(load_file_request.data.filename);
      setOpenDropFile(false);
    }
  };


  const handleCloseFile = () => {
    setOpenDropFile(false);
  };

  return (
    <Grid container item xs={12}>
      

      <Grid item xs={12}>
        <Grid container item xs={12}>
          <Grid item xs={8} sm={8} className={classes.referenciaGrid}>
            <form onChange={guardarReferencia}>
              <Typography>Ingresa tu número de refencia (opcional)</Typography>
              <Grid container>
                <TextField
                  className="referenciaInput"
                  onBlur={sendReferencia}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  inputRef={referencia}
                  defaultValue={referencia}
                  id="referencia"
                  placeholder="Escribe tu número interno de compra"
                  name="referencia"
                />
                <Button
                  variant="contained"
                  color="primary"
                  className="referenciaButton"
                  onClick={handleClickReferenciaButton}
                >
                  Adjuntar pdf
                </Button>
                <Typography className="filename">{fileName}</Typography>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Paper className={clsx(classes.root, classes.paper)}>
          {props.isMobile ? (
            <Fragment>
              {rows.map((row) => {

                return (
                  <Grid container className="productGrid">
                    <Grid item xs={2}>
                      {row.img}
                    </Grid>
                    <Grid item xs={10}>
                      <div className="prodNameMob">{row.name}</div>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10}>
                      <Fragment>
                        {row.precioBase === row.precioFinal ? (
                          <div>
                            <span className={classes.basePrice}>
                              {getPriceSapFormat(row.precioBase)}
                            </span>
                            <span className={classes.descriptionPrice}>
                              {row.precioTipoDescuento === "especial"
                                ? "Precio especial"
                                : "Precio de lista"}
                            </span>
                          </div>
                        ) : (
                          <Fragment>
                            <div>
                              <span
                                className={classes.basePrice}
                                style={{
                                  textDecoration: "line-through",
                                }}
                              >
                                {getPriceSapFormat(row.precioBase)}
                              </span>
                              <span className={classes.descriptionPrice}>
                                Precio de lista
                              </span>
                            </div>

                            {row.precioFinal >= row.precioUnidad ? (
                              <div>
                                <span className={classes.unitPrice}>
                                Tu precio unitario {getPriceSapFormat(row.precioFinal)}
                                </span>
                                <span className={classes.descriptionPrice}>
                                  {`${row.showMessage ? '- ' + row.showMessage: ''}`}
                                </span>
                              </div>
                            ) : (
                              <Fragment>
                                <div>
                                  <span
                                    className={classes.unitPrice}
                                    style={{
                                      textDecoration: "line-through",
                                    }}
                                  >
                                    {getPriceSapFormat(row.precioUnidad)}
                                  </span>
                                  <span className={classes.descriptionPrice}>
                                    Tu precio unitario
                                  </span>
                                </div>
                                <div>
                                  <span className={classes.promoPrice}>
                                    {getPriceSapFormat(row.precioFinal)}
                                  </span>
                                  <span className={classes.descriptionPrice}>
                                    Precio {row.precioTipoDescuento}
                                  </span>
                                </div>
                              </Fragment>
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10}>
                      {row.status}
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={10} sx={{ paddingLeft: '1rem' }}>
                          {columns.map((column) => {
                            const value = row[column.id];

                            return (
                              <Fragment>
                                {column.id === "qty" && (
                                  <div className="def-number-input number-input">
                                    <div className="minusButton">
                                      <button
                                        onClick={() =>
                                          handleChangeQty(row, value, "minus")
                                        }
                                        className="minus"
                                      ></button>
                                    </div>
                                    <input
                                      id={`qty_${row.id.props.children}`}
                                      name={`qty_${row.id.props.children}`}
                                      className="quantity"
                                      // defaultValue={value}
                                      value={row.qty}
                                      onBlur={(event) => {
                                        handleChangeQty(
                                          row,
                                          event.target.value,
                                          "new"
                                        );
                                      }}
                                      type="number"
                                    />
                                    <div className="plusButton">
                                      <button
                                        onClick={() =>
                                          handleChangeQty(row, value, "plus")
                                        }
                                        className="plus"
                                      ></button>
                                    </div>
                                  </div>
                                )}
                              </Fragment>
                            );
                          })}
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={6}>
                          <Tooltip title="Eliminar">
                            <IconButton
                              aria-label="delete"
                              onClick={() => {
                                deleteProduct(row);
                              }}
                            >
                              <img
                                src={iconEliminar}
                                className={classes.customIconDelete}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={4} className="totalPriceProd">
                          {getPriceSapFormat(row.total)}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Fragment>
          ) : (
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                aria-label="sticky table"
                size="small"
                className={classes.productsTable}
              >
                <TableHead className={classes.hiddenElements}>
                  <TableRow>
                    {columns.map((column) => (
                      <StyledTableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          maxWidth: column.maxWidth,
                        }}
                      >
                        {column.label}
                      </StyledTableCell>
                    ))}
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => {
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.name}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];

                          return (
                            <StyledTableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                maxWidth: column.maxWidth,
                              }}
                            >
                              {column.id === "img" ||
                              column.id === "sku" ||
                              column.id === "name" ||
                              column.id === "status" ||
                              column.id === "total"
                                ? column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value
                                : ""}

                              {column.id === "price" && (
                                <Fragment>
                                  {row.precioBase === row.precioFinal ? (
                                    <div className={classes.divPrices}>
                                      <span
                                        className={classes.descriptionPrice}
                                      >
                                        {row.precioTipoDescuento === "especial"
                                          ? "Precio especial"
                                          : "Precio de lista"}
                                      </span>
                                      <span className={classes.basePrice}>
                                        {column.format(row.precioBase)}
                                      </span>{" "}
                                    </div>
                                  ) : (
                                    <Fragment>
                                      <div className={classes.divPrices}>
                                        <span
                                          className={classes.descriptionPrice}
                                        >
                                          Precio de lista
                                        </span>
                                        <span
                                          className={classes.basePrice}
                                          style={{
                                            textDecoration: "line-through",
                                          }}
                                        >
                                          {column.format(row.precioBase)}
                                        </span>
                                      </div>

                                      {row.precioFinal >= row.precioUnidad ? (
                                        <div className={classes.divPrices}>
                                          <span
                                            className={classes.descriptionPrice}
                                          >
                                            Tu precio unitario 
                                          </span>
                                          <span className={classes.unitPrice}>
                                            {column.format(row.precioFinal)} {`${row.showMessage ? ' - ' + row.showMessage: ''}`}
                                          </span>
                                        </div>
                                      ) : (
                                        <Fragment>
                                          <div className={classes.divPrices}>
                                            <span
                                              className={
                                                classes.descriptionPrice
                                              }
                                            >
                                              Tu precio unitario
                                            </span>
                                            <span
                                              className={classes.unitPrice}
                                              style={{
                                                textDecoration: "line-through",
                                              }}
                                            >
                                              {column.format(row.precioUnidad)}
                                            </span>
                                          </div>
                                          <div className={classes.divPrices}>
                                            <span
                                              className={
                                                classes.descriptionPrice
                                              }
                                            >
                                              Precio {row.precioTipoDescuento}
                                            </span>
                                            <span
                                              className={classes.promoPrice}
                                            >
                                              {column.format(row.precioFinal)} {`${row.showMessage ? ' - ' + row.showMessage: ''}`}
                                            </span>
                                          </div>
                                        </Fragment>
                                      )}
                                    </Fragment>
                                  )}
                                </Fragment>
                              )}

                              {column.id === "qty" && (
                                <div className="def-number-input number-input">
                                  <div className="minusButton">
                                    <button
                                      onClick={() => {
                                        handleChangeQty(row, value, "minus");
                                      }}
                                      className="minus"
                                    ></button>
                                  </div>
                                  <input
                                    id={`qty_${row.id.props.children}`}
                                    name={`qty_${row.id.props.children}`}
                                    className="quantity"
                                    // defaultValue={row.qty}
                                    value={row.qty}
                                    onBlur={(event) => {
                                      handleChangeQty(
                                        row,
                                        event.target.value,
                                        "new"
                                      );
                                    }}
                                    type="number"
                                  />
                                  <div className="plusButton">
                                    <button
                                      onClick={() =>
                                        handleChangeQty(row, value, "plus")
                                      }
                                      className="plus"
                                    ></button>
                                  </div>
                                </div>
                              )}
                            </StyledTableCell>
                          );
                        })}
                        <StyledTableCell>
                          <Tooltip title="Eliminar">
                            <IconButton
                              aria-label="delete"
                              onClick={() => {
                                deleteProduct(row);
                              }}
                            >
                              <img
                                src={iconEliminar}
                                className={classes.customIconDelete}
                              />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <div className={classes.costDiv}>
          <div className={classes.couponForm}>
          
            <form onSubmit={addCoupon}>
              <Typography className={classes.tdTitle} align="center">
                Ingresar código de descuento
              </Typography>
              <div>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  inputRef={coupon}
                  value={couponCode}
                  onChange={(ev) => {
                    setCouponCode(ev.target.value);
                  }}
                  className={classes.couponInput}
                  id="coupon"
                  label=""
                  name="coupon"
                />
                <FormControlLabel
                  className={classes.checkCoupon}
                  control={
                    <Checkbox checked={couponApplied} onChange={couponToggle} />
                  }
                  label={couponLabel}
                />
              </div>
            </form>
          </div>

          <CheckoutSummary cartCheckoutData={props.cartCheckoutData} stepNumber={props.stepNumber} />
        </div>
      </Grid>
      
      <div>
        <DropzoneDialog
          open={openDropFile}
          onSave={(files) => handleSaveFile(files)}
          dropzoneText={"Arrastre y suelte el PDF o seleccione un archivo."}
          acceptedFiles={[".pdf"]}
          filesLimit={1}
          showPreviews={true}
          showFileNames={true}
          maxFileSize={5000000} 
          onClose={() => handleCloseFile(this)}
        />
      </div>
    </Grid>
  );
}
