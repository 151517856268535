import React, { useState, useEffect, useContext, Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Popover from '@mui/material/Popover';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { JsxEmit } from "typescript";

const useStyles = makeStyles((styles) => ({
  title: {
    color: "#3C4858",
    margin: "1.75rem 0 0.875rem",
    marginTop: 0,
    minHeight: 32,
    fontFamily: "Roboto Slab, Times New Roman, serif",
    textDecoration: "none",
  },
  container: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: "15px",
    paddingBottom: "12px",
  },
  inStock: {
    color: "#40df00",
    fontSize: 16,
    fontWeight: 500,
    fontFamily: "'Centrale Sans Medium'",
    margin: "0 0 0"

  },
  noStock: {
    color: "#9b9b9b",
    fontSize: 18,
    fontWeight: 500,
  },
  primaryP: {
    color: "#003773",
    fontSize: 18,
    fontWeight: 500,
    marginTop: 30,
    marginBottom: 0,
  },
  ubicacion:{
    fontSize: 12,
    color:"#0C2D9C",
    cursor: "pointer",
    fontWeight: 700,
    textDecoration: "underline"
  },
  cellH:{
      background: "#B5B5B5",
      fontWeight: 700,
      padding: "10px",
      fontSize: 12
  },
  cellB:{
    padding: "10px",
    fontSize: 12
  }
}));



export default function SectionProductStock(props) {
  const classes = useStyles();
  const [stock, setStock] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    getStock();
  }, []);
  const getStock = () => {
    let b = [];
    /*if (props.productDetails.stock > 0) {
            b.push(<p className={classes.primaryP}>En stock</p>);
        } else {
            b.push(<p className={classes.primaryP}>Sin stock</p>);
        }*/
    if (props.productDetails.disponible === true) {
      b.push(
        <div>
          <p key="onStock" className={classes.inStock}>
            En Stock (
            {props.productDetails.skus[props.productDetails.sku_selected]?.stock})
          </p>
          {userLogged !== null &&  userLogged != undefined ? 
            <p className={classes.ubicacion} 
                aria-describedby={id} 
                variant="contained" 
                onClick={handleClick}
            >
                Ver ubicación
            </p> : ""
        }
        </div>
        
      );
    } else {
      b.push(
        <p key="noStock" className={classes.noStock}>
          No Disponible (
          {props.productDetails.skus[props.productDetails.sku_selected].stock})
        </p>
      );
    }
    setStock(b);
  };

  const userLogged =  JSON.parse(localStorage.getItem('user'));

  return (
    <div>
      <div className={classes.container}>{stock}</div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <TableContainer component={Paper} sx={{ p: 2 }}>
          <Table sx={{minWidth:100}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.cellH}>Disponible en:</TableCell>
                <TableCell className={classes.cellH} align="center">Stock</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.cellB}>México</TableCell>
                <TableCell align="center" className={classes.cellB}>{
                  props.productDetails.skus[props.productDetails.sku_selected].dataStockByStore[0]?.alm_almacen_id == 3 ?
                  props.productDetails.skus[props.productDetails.sku_selected].dataStockByStore[0]?.sp_cantidad :
                  props.productDetails.skus[props.productDetails.sku_selected].dataStockByStore[1]?.sp_cantidad 
                  }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.cellB}>Monterrey</TableCell>
                <TableCell align="center" className={classes.cellB}>{
                  props.productDetails.skus[props.productDetails.sku_selected].dataStockByStore[0]?.alm_almacen_id == 1 ?
                  props.productDetails.skus[props.productDetails.sku_selected].dataStockByStore[0]?.sp_cantidad :
                  props.productDetails.skus[props.productDetails.sku_selected].dataStockByStore[1]?.sp_cantidad 
                }</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Popover>
    </div>
  );
}
