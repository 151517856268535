import React, { useState, useEffect, useRef, Fragment } from "react";
import clsx from "clsx";
import { API_URL } from "../../_services/api.url.back.img";
import { getPriceSapFormat } from "helpers/formatPrice";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Divider, Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.primary.light,
    boxShadow: "none",
    borderRadius: 0,
    padding: "15px",
    fontSize: "15px",
    "& h6": {
      fontFamily: "'Centrale Sans Medium'",
      color: "#000000",
      fontSize: "17px",
    },
    textAlign: "center",
  },
  personalData: {
    backgroundColor: "#0B3196",
    color: "#ffffff",
    "& h6": {
      fontFamily: "'Centrale Sans Medium'",
      color: "#ffffff",
    },
  },
  productsList: {
    maxHeight: 420,
    overflowY: "auto",
    borderRadius: "0 0 2px 2px",
    backgroundColor: "#F8F8F8",
  },
  prodRow: {
    margin: "auto",
    width: "96%",
    marginTop: 10,
    marginBottom: 10,
  },
  productName: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 14,
    textAlign: "justify",
    paddingRight: 15,
  },
  prodQty: {
    fontFamily: "'Centrale Sans Light'",
    marginLeft: 5,
  },
  basePrice: {
    color: "#979797",
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "'Centrale Sans Light'",
  },
  unitPrice: {
    color: "#0C2D9C",
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "'Centrale Sans Light'",
  },
  promoPrice: {
    color: "#E41212",
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "'Centrale Sans Light'",
  },
  descriptionPrice: {
    color: "#000000",
    fontSize: 12,
    fontWeight: 300,
    fontFamily: "'Centrale Sans Light'",
  },
  listPrice: {
    fontFamily: "'Centrale Sans Light'",
    fontSize: 14,
  },
  finalPrice: {
    color: "#000000",
    fontSize: 14,
    fontFamily: "'Centrale Sans Light'",
  },
  totalPrice: {
    color: "#000000",
    fontFamily: "'Centrale Sans Bold'",
    fontSize: 16,
    marginTop: 10,
  },
  usdPrice: {
    color: theme.palette.primary.error,
    fontSize: 16,
    fontWeight: 900,
  },
}));

export default function CheckoutProductsList(props) {
  const classes = useStyles();
  let _txt =
    props.cartCheckoutData.cart.cdc_cmm_tipo_envio_id === 17
      ? "Disponible para recolección "
      : props.cartCheckoutData.cart.cdc_cmm_tipo_envio_id === 16
      ? "Envío "
      : "Disponible";

  return (
    <Fragment>
      <div className={classes.productsList}>
        {props.cartCheckoutData.cart
          ? props.cartCheckoutData.productsSplit.map((prod) => {
              let imgSrc =
                "http://wws.com.pa/wp-content/plugins/wordpress-ecommerce/marketpress-includes/images/default-product.png";
              let _nombre = "";
              let _precio = 0;
              let _total = 0;
              let _cantidad = 0;
              let _fecha = "";
              if (
                props.cartCheckoutData.cart.cdc_cmm_tipo_envio_id !==
                  undefined &&
                props.cartCheckoutData.cart.cdc_cmm_tipo_envio_id !== null
              ) {
                _nombre =
                  prod.detalle.prod_nombre +
                  "[" +
                  prod.detalle.prod_nombre_extranjero +
                  "]";
                /// Precios
                if (
                  prod.detalle.prod_tipo_cambio_base === "USD" &&
                  props.cartCheckoutData.cart.cdc_forma_pago_codigo == "99"
                ) {
                 // (prod.projectProduct && ( prod.projectProductPrice < prod.precioFinal || prod.precioFinal ===0)
                  _precio = (prod.detalle.projectProduct &&( prod.detalle.projectProductPriceUSD < prod.detalle.precioFinal_USD ||  prod.detalle.precioFinal_USD ==0)
                    ? getPriceSapFormat(prod.detalle.projectProductPriceUSD) + " USD - No. de acuerdo " + prod.detalle?.projectProducNoAcuerdo
                    : getPriceSapFormat(prod.detalle.precioFinal_USD) + " USD");
                  _total =
                    getPriceSapFormat(
                      (prod.detalle.projectProduct && (prod.detalle.projectProductPriceUSD < prod.detalle.precioFinal_USD || prod.detalle.precioFinal_USD ===0)
                        ? prod.detalle.projectProductPriceUSD
                        : prod.detalle.precioFinal_USD) * prod.pcf_cantidad_producto)
                     + " USD";
                } else {
                  _precio = (prod.detalle.projectProduct && ( prod.detalle.projectProductPrice < prod.detalle.precioFinal || prod.detalle.precioFinal===0)
                    ? getPriceSapFormat(prod.detalle.projectProductPrice) + " MXN - No. de acuerdo " + prod.detalle?.projectProducNoAcuerdo
                    : getPriceSapFormat(prod.detalle.precioFinal) + " MXN");
                  _total =
                    getPriceSapFormat(
                      (prod.detalle.projectProduct && (prod.detalle.projectProductPrice < prod.detalle.precioFinal || prod.detalle.precioFinal===0)
                        ? prod.detalle.projectProductPrice
                        : prod.detalle.precioFinal) * prod.pcf_cantidad_producto)
                    + " MXN";
                }
                /// Imágenes
                if (prod.detalle.imagenes && prod.detalle.imagenes !== null) {
                  imgSrc =(prod.detalle.imagenes.imgprod_ruta_archivo.includes('https://ftp3.syscom.mx')?prod.detalle.imagenes.imgprod_ruta_archivo:`${API_URL}${prod.detalle.imagenes.imgprod_ruta_archivo.split("public")[1]}`);
                }
                _cantidad = prod.pcf_cantidad_producto;
                _fecha = _txt + " " + prod.fechaEntrega;
              } else {
                _nombre =
                  prod.prod_nombre + "[" + prod.prod_nombre_extranjero + "]";
                if (
                  prod.prod_tipo_cambio_base === "USD" &&
                  props.cartCheckoutData.cart.cdc_forma_pago_codigo == "99"
                ) {
                  console.log('prod.detalle', prod);
                  _precio = (prod.projectProduct && ( prod.projectProductPriceUSD < prod.precioFinal_USD || prod.precioFinal_USD===0)
                    ? getPriceSapFormat(prod.projectProductPriceUSD) + " USD - No. de acuerdo " + prod.detalle?.projectProducNoAcuerdo
                    : getPriceSapFormat(prod.precioFinal_USD) + " USD");
                  _total =
                    getPriceSapFormat(
                      (prod.projectProduct && (prod.projectProductPriceUSD < prod.precioFinal_USD || prod.precioFinal_USD===0)
                      ?  prod.projectProductPriceUSD
                      : prod.precioFinal_USD) * prod.pcdc_producto_cantidad)
                     + " USD";
                } else {
                  console.log('prod.detalle', prod);
                  _precio = (prod.projectProduct && (prod.projectProductPrice < prod.precioFinal || prod.precioFinal ===0)
                    ? getPriceSapFormat(prod.projectProductPrice) + " MXN - No. de acuerdo " + prod.detalle?.projectProducNoAcuerdo
                    : getPriceSapFormat(prod.precioFinal) + " MXN");
                  _total =
                    getPriceSapFormat(
                      (prod.projectProduct &&( prod.projectProductPrice < prod.precioFinal|| prod.precioFinal===0)
                      ?  prod.projectProductPrice
                      : prod.precioFinal) * prod.pcdc_producto_cantidad)
                    + " MXN";
                }
                if (prod.imagenes && prod.imagenes !== null) {
                  imgSrc = prod.imagenes.imgprod_ruta_archivo.includes('https://ftp3.syscom.mx')? prod.imagenes.imgprod_ruta_archivo :`${API_URL}${prod.imagenes.imgprod_ruta_archivo.split("public")[1]}`;
                }
                _cantidad = prod.pcdc_producto_cantidad;
              }

              return (
                <Grid container className={classes.prodRow}>
                  <Grid item xs={2}>
                    <Avatar alt="Product" src={imgSrc} />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography className={classes.productName}>
                      {_nombre}
                    </Typography>
                    <span className={classes.productName}>
                      Cantidad
                      <span className={classes.prodQty}>{_cantidad}</span>
                    </span>
                    <span>{_fecha}</span>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "right" }}>
                    <Fragment>
                      <span className={classes.finalPrice}>{
                        `${_precio} ${(prod.projectProduct && (prod.projectProductPrice < prod.precioFinal|| prod.precioFinal===0) 
                          ? ' No. de acuerdo ' + prod.projectProducNoAcuerdo
                          : '')}`
                      }</span>
                    </Fragment>
                    <br />
                    <Typography className={classes.totalPrice}>
                      {_total}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider style={{ backgroundColor: "#EAEAEA" }} />
                  </Grid>
                </Grid>
              );
            })
          : ""}
      </div>
    </Fragment>
  );
}
