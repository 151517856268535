/*eslint-disable*/
import React, { useState, useEffect } from "react";
// material-ui core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

const useStyles = makeStyles((styles) => ({
  container: {
    width: "100%",
    textAlign: "left",
    // paddingBottom: 15,
    paddingLeft: "2rem",
    // paddingTop: 20,
    [styles.breakpoints.down("sm")]: {
      paddingBottom: 5,
      paddingLeft: "1rem",
      paddingTop: 5,
    },
  },
  textIcon: {
    color: "#3cdbc0",
    display: "inline-block",
    fontSize: "12px",
  },
  link: {
    color: "#9b9b9b",
    marginRight: "10px",
    fontSize: "12px",
    fontFamily: "'Centrale Sans Medium'",
    "&:hover": {
      color: "#0b3196",
    },
    [styles.breakpoints.down("sm")]: {
      fontSize: "14px",
      marginRight: "5px",
    },
  },
  txt: {
    color: "#3cdbc0",
    fontSize: "12px",
    fontFamily: "'Centrale Sans Medium'",
    [styles.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    textTransform: "uppercase",
  },
  txtLink: {
    fontSize: ".7rem",
    marginRight: "10px",
    [styles.breakpoints.down("sm")]: {
      marginRight: "5px",
    },
    textTransform: "uppercase",
  },
}));

export default function SectionBreadcrumb(props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {props?.route && props.route.map((r) => (
        <div className={classes.textIcon} key={"breadcrum" + r.name}>
          {r.link !== "" ? (
            <Link to={`${r.link}`}>
              <Typography className={classes.txtLink}>
                <span className={classes.link}>{r.name}</span>
                &rang;
              </Typography>
            </Link>
          ) : (
            <Typography className={classes.txt}>{r.name}</Typography>
          )}
        </div>
      ))}
    </div>
  );
}
