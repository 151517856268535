import React, { useState } from "react";
import "./index.css";
import "assets/scss/material-kit-react.scss?v=1.9.0";
import { ConfirmProvider } from "material-ui-confirm";
import { ThemeProvider } from "@mui/material";
import theme from "./_helpers/theme";

import { Routes } from "routes/Routes";


export const App = () => {
 
  return (
   
    <ThemeProvider theme={theme} >
      <ConfirmProvider>
       
        
         <Routes />

       
      </ConfirmProvider> 
     
    </ThemeProvider>
  
  );
};


