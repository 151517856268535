import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { productsService } from "_services/products.services.js";
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((styles) => ({
    dataSheetViewContainer: {
      width: "100%",
    },
    dataSheetViewContent: {
      width: "100%",
      height: "calc(100vh - 7px)",
    },
  }));
  
const ProductDataSheetView = () => {
    const classes = useStyles();
    const [dataSheet, setDataSheet] = useState("");
    const { name, position } = useParams();

    const getDataSheet = async () => {
        const route = await productsService.getProductDataSheet(name, position);
        setDataSheet(route);
    }

    useEffect(() => {
        getDataSheet();
    }, [dataSheet])
    

    return(
        <div className={classes.dataSheetViewContainer}>
          <Container fixed >
            <object data={dataSheet} className={classes.dataSheetViewContent}></object> 
          </Container>
        </div>
    );
}

export default ProductDataSheetView;