import React, { useEffect, useState, Fragment, createContext } from "react";
import clsx from "clsx";
import { Helmet } from "react-helmet";
import { Box, Container, Grid, Typography } from "@mui/material";

import { API_URL } from "../../_services/api.url.back.img";
import useCheckMobileScreen from "components/Mobile/Mobile.js";
import { productsService } from "../../_services/products.services";
import { homeServices } from "_services/home.services";
import Loading from "../../utils/loading/Loading.js";

// @material-ui
import { makeStyles } from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";

// sections for this page
import SectionSliderTrusty from "./Sections/SectionSliderTrusty.js";
import SectionProductName from "./Sections/SectionProductName.js";
import SectionProductRating from "./Sections/SectionProductRating.js";
import SectionProductCarousel from "./Sections/SectionProductCarousel.js";
import SectionProductBullets from "./Sections/SectionProductBullets.js";
import SectionProductStock from "./Sections/SectionProductStock.js";
import SectionProductInputAdd from "./Sections/SectionProductInputAdd.js";
import SectionProductPrice from "./Sections/SectionProductPrice.js";
import SectionProductBrand from "./Sections/SectionProductBrand.js";
import SectionProductSKU from "./Sections/SectionProductSKU.js";
import SectionProductPartNumber from "./Sections/SectionProductPartNumber.js";
import SectionSpaceTop from "views/Home/Sections/SectionSpaceTop.js";
import SectionBreadcrumb from "views/Home/Sections/SectionBreadcrumb.js";
import SectionProductTabs from "./Sections/SectionProductTabs/SectionProductTabs.js";

import { CollectionComponent, LastExistenceComponent } from "components";
import { ButtonCategoriesMenu } from "components";

import { SectionAccesories } from "./Sections/SectionAccesories";
import { SectionRelated } from "./Sections/SectionRelated";

const useStyles = makeStyles((styles) => ({
  hide: {
    display: "none !important",
  },
  mainBackground: {
    backgroundColor: "#FFF",
  },
  sectionImgs: {
    width: "37%",
    display: "inline-block",
    verticalAlign: "top",
    [styles.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  lineCenter: {
    display: "inline-block",
    verticalAlign: "top",
    width: "43%",
    [styles.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  oneLine: {
    width: "100%",
  },
  lineAqua: {
    width: "97%",
    height: 2,
    backgroundColor: "#3cdbc0",
    marginLeft: "15px",
    borderRadius: 2,
    marginTop: 6,
    marginBottom: "15px",
  },
  sectionRight: {
    width: "17%",
    marginLeft: "1%",
    display: "inline-block",
    [styles.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  sectionRightInside: {
    width: "100%",
    border: "1px solid #eaeaea",
    display: "inline-block",
    paddingBottom: 10,
    borderRadius: 3,
  },
  benefitsBanner: {
    maxWidth: "100%",
  },
  /// Tabs
  sectionTabs: {
    width: "100%",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    paddingBottom: "5rem",
    paddingTop: "2rem",
    [styles.breakpoints.down("sm")]: {
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  /// Attributes
  attribName: {
    paddingLeft: "15px",
    fontWeight: "500",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "14px",
    marginTop: 15,
    marginBottom: 5,
    color: "#00000",
  },
  attribValue: {
    marginLeft: "15px",
    fontWeight: "300",
    fontFamily: "'Centrale Sans Regular'",
    fontSize: "14px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "5px",
    paddingBottom: "5px",
    border: "1px solid #9b9b9b",
    textAlign: "center",
    display: "inline-block",
    backgroundColor: "#fff",
    marginBottom: "3px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f5f5f5",
    },
  },
  attribValueSelected: {
    color: "#fff",
    border: "1px solid #000000",
    display: "inline-block",
    fontSize: "14px",
    textAlign: "center",
    fontFamily: "'Centrale Sans Regular'",
    fontWeight: "300",
    marginLeft: "15px",
    paddingTop: "5px",
    paddingLeft: "30px",
    marginBottom: "3px",
    paddingRight: "30px",
    paddingBottom: "5px",
    backgroundColor: "#0C2D9C",
    borderRadius: "2px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#0b3196",
    },
  },
  attribValueNA: {
    marginLeft: "15px",
    paddingLeft: "30px",
    fontWeight: "300",
    fontFamily: "'Centrale Sans Regular'",
    fontSize: "14px",
    paddingRight: "30px",
    paddingTop: "5px",
    paddingBottom: "5px",
    border: "1px solid #CCC",
    textAlign: "center",
    display: "inline-block",
    backgroundColor: "#FFF",
    color: "#CCC",
    "&:hover": {
      cursor: "pointer",
    },
  },
  inline: {
    display: "inline-block",
    verticalAlign: "top",
  },
  selectVariant: {
    width: "50%",
    height: "34px",
    padding: "5px",
    marginLeft: "15px",
  },
  selectOptNormal: {
    backgroundColor: "#ffffff",
  },
  selectOptSelected: {
    backgroundColor: "#40DF00",
  },
  selectOptDisabled: {
    backgroundColor: "#a3a3a3",
  },
  /// Sliders
  sliderDiv: {
    marginTop: "50px",
    width: "100%",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    marginBottom: "30px",
    [styles.breakpoints.down("sm")]: {
      paddingLeft: "5px",
      paddingRight: "5px",
    },
  },
  prodRelatedDiv: {
    paddingLeft: "50px",
    paddingRight: "50px",
    [styles.breakpoints.down("sm")]: {
      paddingLeft: "5px",
      paddingRight: "5px",
    },
  },

  attButton: {
    margin: "0 15px 0",
    borderRadius: 2,
    boxShadow: "none",
    color: "#000000",
    backgroundColor: "transparent",
    "&:hover": {
      color: "#ffffff",
      border: 0,
      backgroundColor: "rgb(11,49,150)",
      opacity: 1,
    },
  },
  availableAttValue: {
    border: "1px solid #EAEAEA",
  },
  disableAttValue: {
    border: "1px dashed #000000",
    opacity: "0.5",
  },
  selectedAttValue: {
    backgroundColor: "rgb(11,49,150)",
    border: 0,
    color: "#ffffff",
  },
  alertNotMatchSku: {
    color: "rgb(11,49,150)",
    fontSize: 14,
    padding: 15,
  },
  lineOblique: {
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    backgroundImage:
      "linear-gradient(to bottom right,transparent 44%,transparent  49%,#857777 0,#857777 51%,transparent 0)",
    "&:hover": {
      backgroundImage: "none",
    },
  },
  loadingVariantsContainer: {
    display: "grid",
    gridTemplateColumns: "30% 70%",
  },
  loadingVariantsContent: {
    backgroundColor: "#F5F5F5",
    display: "flex",
  },
  loadingVariantsElements: {
    margin: "auto",
    textAlign: "center",
    "& p": {
      fontFamily: "Centrale Sans Regular",
      fontWeight: 400,
      fontSize: 16,
      textAlign: "justify",
      color: "#000000",
      margin: "auto",
    },
    "& img": {
      width: 44,
      height: 44,
    },
  },
}));

function createArrImgs(id, url, alt) {
  let urlA = "";
  if (url.includes("https://ftp3.syscom.mx")) {
    urlA = url;
  } else {
    urlA = API_URL + url.replace("./public", "");
  }

  return { id, urlA, alt };
}

let globalDataPdp = {
  slider_trusty: {
    images: [],
  },
};
let globalData = createContext(globalDataPdp); /// Variable global

export default function ProductView(props) {
  const classes = useStyles();
  const { ...rest } = props;
  let isMobile = useCheckMobileScreen();
  const [collectionDataFirts, setCollectionDataFirts] = useState({});
  const [breadcrumb, setBreadcrumb] = useState(null);
  const [nameP, setNameP] = useState(null);
  const [ratingP, setRatingP] = useState(null);
  const [priceP, setPriceP] = useState(null);
  const [imagesP, setImagesP] = useState(null);
  const [brandName, setBrandName] = useState(null);
  const [prodSKU, setProdSKU] = useState(null);
  const [productParent, setProductParent] = useState({});
  const [prodPartNumb, setProdPartNumb] = useState(null);
  const [inputAdd, setInputAdd] = useState(null);
  const [productBullets, setProductBulltes] = useState(null);
  const [atributos, setAtributos] = useState([]);
  const [productStock, setProductStock] = useState(null);
  const [productTabs, setProductTabs] = useState(null);
  const [discount, setDiscount] = useState("");
  const [hide, setHide] = useState(classes.hide);
  const [loading, setLoading] = useState(false);
  const [loadingVariants, setLoadingVariants] = useState(false);
  const [trusties, setTrusties] = useState(null);

  const [attrBool, setAttrBool] = useState(false);

  const [productDetails, setProductDetails] = useState({
    product_id: 0,
    name: "",
    description: "",
    priceList: 0,
    priceClient: 0,
    precioListaDls: 0,
    precioFinalDls: 0,
    priceDiscount: 0,
    brandId: 0,
    brandName: "",
    rating: 3.5,
    bullets: "",
    stock: 0,
    disponible: true,
    prod_sku: "",
    prod_imgs: [{ id: 1, url: "url", alt: "Text" }],
    prod_attr: [{ name: "Selecciona modelo...", val: "Selecciona modelo..." }],
    prod_bottom: {
      products: [],
      title: "",
    },
    clients_comments: {
      comments: [],
      title: "Comentarios de clientes",
      rating: 0,
    },
    attributes: [],
    skus: [],
    sku_selected: 0,
    attributes_selected: [],
    breadcrumb: [],
    accesories: [],
    relatedProducts: [],
    url_video: "",
  });

  const [showAlertNotMatchSku, setShowAlertNotMatchSku] = useState(null);
  const [selectedSku, setSelectedSku] = useState({});
  const [attributes, setAttributes] = useState([]);
  const [prodSkuProduct, setProdSkuProduct]= useState()

  const getProductData = async () => {
    setLoading(true);

    const _p = getParameters();
    let id = _p.prod == "null" ? null : _p.prod;
    let _var = _p.sku;
    let _idProd = _p.sku;
    /* START to find by SKU the attributes value selection and save the attributes list */
    let _atr_sel_param = [];

    if (_var !== undefined) {
      _atr_sel_param = await productsService.getSKUattrib(_var);
      if (_atr_sel_param != "Producto invalido") {
        _atr_sel_param = _atr_sel_param.message.atributos_categorias_lista;
      } else {
        _atr_sel_param = null;
      }
    }

    let attrList = [];
    let attrSelected = [];

    if (_atr_sel_param != null) {
      _atr_sel_param.map((att) => {
        attrSelected.push(att.skuav_valor);

        attrList.push({
          id: att.at_atributo_id,
          attribute: att.at_nombre,
          values: [
            {
              id: att.skuav_valor,
              value: att.skuav_valor,
              selected: true,
              available: true,
            },
          ],
        });
      });
    }

    handleUpdateProductDetails("attributes_selected", attrSelected);
    // window.alert(JSON.stringify(attrList))
    //attrList=[]
    handleUpdateProductDetails("attributes", attrList);
    setAtributos(attrList);
    setAttributes(attrList);
    /* END */

    const clientData = JSON.parse(localStorage.getItem("client"));
    let idSocioNegocio = process.env.REACT_APP_API_USER_B2B;
    if (clientData != null) {
      idSocioNegocio = clientData.listaSnbyid[0].sn_socios_negocio_id;
    }

    let product = await productsService.getProductDataOnlyChild(
      id == null ? _var : id,
      idSocioNegocio,
      _var
    );
    //  console.log(`Product ${JSON.stringify(product)}`)

    if (product.mainConsultaProductos.rows.length === 0) {
      _var = id;
      let prodIdData = await productsService.getProductFatherID(id);
      id = prodIdData.prod_producto_id;
    }
    let prodData = product.mainConsultaProductos.rows[0];

    setProductParent(prodData);
    setSelectedSku(prodData.ListaHijos[0]);

    handleUpdateProductDetails("product_id", prodData.prod_producto_id);
    handleUpdateProductDetails("prod_nombre", prodData.prod_nombre);
    handleUpdateProductDetails(
      "priceList",
      prodData.prod_producto_id_con_descuento_precio
    );

    handleUpdateProductDetails("priceClient", prodData.prod_precio);
    handleUpdateProductDetails(
      "priceDiscount",
      prodData.prod_producto_id_con_descuento_precio_final
    );
    handleUpdateProductDetails("brandId", prodData.mar_marca_id);
    handleUpdateProductDetails("brandName", prodData.mar_nombre);
    handleUpdateProductDetails("description", prodData.prod_descripcion);
    handleUpdateProductDetails("sku_selected", 0);

    /* START object of first load selected SKU */
    let _skus = [];
    if (prodData.ListaHijos.length > 0) {
      let sku_selected = prodData.ListaHijos[0];
      let _vid =
        sku_selected.prod_video_url !== null
          ? sku_selected.prod_video_url.split(
              "https://www.youtube.com/watch?v="
            )
          : null;
      _vid = _vid != null ? (_vid.length > 1 ? _vid[1] : _vid[0]) : null;
      sku_selected.imagen_productos.sort(function (a, b) {
        return a.imgprod_nombre_archivo.localeCompare(b.imgprod_nombre_archivo);
      });

      let sku_atributos = [];

      if (sku_selected.Atributos?.atributos_categorias_lista) {
        sku_selected.Atributos.atributos_categorias_lista.map((attSku) => {
          sku_atributos.push({
            id: attSku.at_atributo_id,
            attribute: attSku.at_nombre,
            value: attSku.skuav_valor,
            selected: true,
            available: true,
          });
        });
      }

      if (sku_atributos.length > 0) {
        sku_atributos.map((atri) => {
          if (atri.id == 78) {
            handleUpdateProductDetails("brandName", atri.value);
          }
        });
      }

      setProdSkuProduct(sku_selected.prod_sku)
      let _sku = {
        id: sku_selected.prod_producto_id,
        name: sku_selected.prod_nombre,
        sku: sku_selected.prod_sku,
        descripcion: sku_selected.prod_descripcion,
        attributes: sku_atributos,
        imgs: sku_selected.imagen_productos,
        precio: sku_selected.precioFinal,
        peso: sku_selected.prod_peso,
        volumen: sku_selected.prod_volumen,
        precioDescuento: sku_selected.precioMenosDescuento,
        precioListaDls: sku_selected.precioFinaldls,
        precioFinalDls: sku_selected.precioMenosDescuentodls,
        stock: sku_selected.prod_total_stock,
        datasheet: sku_selected.data_sheet,
        vines: sku_selected.prod_viñetas,
        urlVideo: _vid,
        caracteristicas: sku_selected.prod_caracteristicas_tecnicas,
        foreignName: sku_selected.prod_nombre_extranjero,
        backorder: sku_selected.aplicaBackOrder,
        diasResurtimiento: sku_selected.prod_dias_resurtimiento,
        precioSN: sku_selected.DescuentoDielsaFijo,
        descuentoSNbool: sku_selected.precioPromocionDielsaBool,
        dataStockByStore: sku_selected.dataStockByStore,
        externalProductId: sku_selected.externalProductId,
      };
      //window.alert(JSON.stringify(_sku.externalProductId))
      _skus.push(_sku);
    }

    handleUpdateProductDetails("skus", _skus);
    /* END */

    /* START reviews section */
    const _r = await productsService.getReviews(
      id == "undefined" || id == null ? _idProd : id
    );

    if (_r.constResenasProductos.length > 0) {
      let _arr = [];
      for (let i = 0; i < _r.constResenasProductos.length; i++) {
        let _rs = _r.constResenasProductos[i];
        let _a_r = {
          id: _rs.rep_resenas_productos_id,
          name: _rs.Usuario.snu_nombre + " " + _rs.Usuario.snu_primer_apellido,
          text: _rs.rep_comentario,
          rating: _rs.rep_calificacion,
          date: _rs.createdAt,
        };
        _arr.push(_a_r);
      }

      let newProductDetails = productDetails;
      newProductDetails.clients_comments.comments = _arr;
      //newProductDetails.clients_comments.rating = Math.round(product.producto.prod_calificacion_promedio * 100) / 100;
      newProductDetails.clients_comments.rating = 0;
      setProductDetails(newProductDetails);
    }
    /* END */
    // window.alert(JSON.stringify(prodData))
    var nameTitulo = "";
    var marca = "";
    if (prodData.ListaHijos.length > 0) {
      for (let i = 0; i < prodData.ListaHijos.length; i++) {
        if (prodData.ListaHijos[i].prod_producto_id == _idProd) {
          nameTitulo = prodData.ListaHijos[i].prod_nombre;
        }
      }
    }

    // window.alert(JSON.stringify(prodData.ListaHijos))

    /* START breadcrumb */
    let breadcrumbRoute = [
      {
        name: "INICIO",
        link: "/",
      },
      {
        name: prodData.cat_nombre_tienda,
        link: `/categorias/${prodData.cat_categoria_link}`,
      },
      {
        name: nameTitulo,
        link: "",
      },
    ];
    handleUpdateProductDetails("breadcrumb", breadcrumbRoute);
    /* END */

    setProductData();
    setLoading(false);

    // call second method to request not only one SKU of product parent, the selected, but instead all list to find all attributes...
    if (prodData.externalProductId != null) {
    } else {
      getParentProductData();
    }
  };

  const getParentProductData = async () => {
    setLoadingVariants(true);
    const _p = getParameters();
    let id = _p.prod;
    let _var = _p.sku;

    let idSocioNegocio = JSON.parse(localStorage.getItem("client"))
      ? JSON.parse(localStorage.getItem("client")).listaSnbyid[0]
          .sn_socios_negocio_id
      : process.env.REACT_APP_API_USER_B2B;

    let productParentAllChilds = await productsService.getProductData(
      id,
      idSocioNegocio
    );

    let prodData = productParentAllChilds.mainConsultaProductos.rows[0];

    /* find all attributes for parent product and save the selected attributes for the child product */
    if (prodData.ListaHijos.length > 0) {
      let attrList = productDetails.attributes;
      let skuList = productDetails.skus.slice();
      const listaHijosCopy = prodData.ListaHijos.slice();
      listaHijosCopy.shift();

      listaHijosCopy.map((sku, i) => {
        /* save position in array of selected SKU */
        if (_var !== undefined && sku.prod_producto_id == _var) {
          handleUpdateProductDetails("sku_selected", 0);
        }

        if (sku.Atributos.atributos_categorias_lista.length > 0) {
          let selected = false; // by default in false and only if exist in array of attributtes values selected then put in true
          let available = false;
          let sku_atributos = []; // attributes of the SKU

          /* update attribute list with all new attributes and values in every attribute */
          sku.Atributos.atributos_categorias_lista.map((skuAtt) => {
            let findAttrValueSelected = productDetails.attributes_selected.find(
              (selectedAtt) => selectedAtt === skuAtt.skuav_valor
            );

            findAttrValueSelected !== undefined
              ? (selected = true)
              : (selected = false); // mark as selected value of attribute by the load SKU

            findAttrValueSelected !== undefined
              ? (available = true)
              : (available = false); // mark as available value of attribute by the load SKU

            attrList.map((att) => {
              if (att.attribute === skuAtt.at_nombre) {
                if (att.values.length > 0) {
                  let findValueAtt = att.values.find(
                    (value) => value.value === skuAtt.skuav_valor
                  );
                  if (!findValueAtt) {
                    att.values.push({
                      id: skuAtt.skuav_valor,
                      value: skuAtt.skuav_valor,
                      selected: selected,
                      available: available,
                    });
                  }
                } else {
                  att.values.push({
                    id: skuAtt.skuav_valor,
                    value: skuAtt.skuav_valor,
                    selected: selected,
                    available: available,
                  });
                }
              }
            });

            sku_atributos.push({
              id: skuAtt.at_atributo_id,
              attribute: skuAtt.at_nombre,
              value: skuAtt.skuav_valor,
              selected: selected,
              available: available,
            });
          });

          /* create SKU object with propper data to send to every component in charge to load info of the sku selected */
          let _vid =
            sku.prod_video_url !== null
              ? sku.prod_video_url.split("https://www.youtube.com/watch?v=")
              : null;
          _vid = _vid != null ? (_vid.length > 1 ? _vid[1] : _vid[0]) : null;
          sku.imagen_productos.sort(function (a, b) {
            return a.imgprod_nombre_archivo.localeCompare(
              b.imgprod_nombre_archivo
            );
          });
          // console.log('detail product ', sku);
          let skuObj = {
            id: sku.prod_producto_id,
            name: sku.prod_nombre,
            sku: sku.prod_sku,
            descripcion: sku.prod_descripcion,
            attributes: sku_atributos, //attributes of the SKU where selected is TRUE if exist in array od selected attributes values
            imgs: sku.imagen_productos,
            precio: sku.precioFinal,
            peso: sku.prod_peso,
            volumen: sku.prod_volumen,
            precioListaDls: sku.precioFinaldls,
            precioFinalDls: sku.precioMenosDescuentodls,
            precioDescuento: sku.precioMenosDescuento,
            stock: sku.prod_total_stock,
            datasheet: sku.data_sheet,
            vines: sku.prod_viñetas,
            urlVideo: _vid,
            caracteristicas: sku.prod_caracteristicas_tecnicas,
            foreignName: sku.prod_nombre_extranjero,
            backorder: sku.aplicaBackOrder,
            diasResurtimiento: sku.prod_dias_resurtimiento,
            precioSN: sku.DescuentoDielsaFijo,
            descuentoSNbool: sku.precioPromocionDielsaBool,
            dataStockByStore: sku.dataStockByStore,
            externalProductId: sku.externalProductId,
          };

          skuList.push(skuObj);
        }
      });

      handleUpdateProductDetails("attributes", attrList);
      setAtributos(attrList);
      setAttributes(attrList);
      handleUpdateProductDetails("skus", skuList);
    }
    setLoadingVariants(false);

    setAvailableAttributes();

    setProductData();
    setLoading(false);
  };

  const getParameters = () => {
    const queryString = window.location.search.replace("?", "");
    const _p = queryString.split("&");
    let _a = [];
    for (let i = 0; i < _p.length; i++) {
      let _b = _p[i].split("=");
      _a[_b[0]] = _b[1];
    }
    return _a;
  };

  const handleUpdateProductDetails = (name, value) => {
    let newProductDetails = productDetails;
    newProductDetails[name] = value;
    setProductDetails(newProductDetails);
  };

  const transformCharacteristics = (data) => {
    let _a = [];
    let _d = data ? JSON.parse(data) : "";
    if (_d !== null) {
      for (let i = 0; i < _d.length; i++) {
        let _t = _d[i]
          .replace('"', "")
          .replace("{", "")
          .replace("}", "")
          .split(": ");
        _a.push({ name: _t[0], val: _t[1] });
      }
    }
    return _a;
  };

  const setProductData = () => {
    setBreadcrumb(<SectionBreadcrumb route={productDetails.breadcrumb} />);
    setNameP(<SectionProductName productDetails={productDetails} />);
    setRatingP(<SectionProductRating productDetails={productDetails} />);

    setPriceP(<SectionProductPrice productDetails={productDetails} />);

    setBrandName(<SectionProductBrand productDetails={productDetails} />);
    setProdPartNumb(
      <SectionProductPartNumber productDetails={productDetails} />
    );
    setInputAdd(<SectionProductInputAdd productDetails={productDetails} />);
    setProductStock(<SectionProductStock productDetails={productDetails} />);

    let sel = productDetails.sku_selected;
    /// Structurate data
    handleUpdateProductDetails("prod_sku", productDetails.skus[sel]?.sku);

    let arrImgsP = [];
    for (let i = 0; i < productDetails.skus[sel]?.imgs?.length; i++) {
      const imgsH = productDetails.skus[sel].imgs[i];
      //window.alert(JSON.stringify(productDetails.skus[sel].imgs[i]))
      arrImgsP.push(
        createArrImgs(
          imgsH.imgprod_imagen_producto_id,
          imgsH.imgprod_ruta_archivo,
          imgsH.imgprod_nombre_archivo
        )
      );
    }

    handleUpdateProductDetails("prod_imgs", arrImgsP);
    handleUpdateProductDetails(
      "url_video",
      productDetails.skus[sel]?.urlVideo !== null
        ? productDetails.skus[sel]?.urlVideo
        : ""
    );
    const productDetail = productDetails.skus[sel]?.vines
      ? JSON.parse(productDetails.skus[sel]?.vines)
      : "";
    handleUpdateProductDetails("bullets", productDetail);
    handleUpdateProductDetails("priceList", productDetails.skus[sel]?.precio);
    handleUpdateProductDetails(
      "priceClient",
      productDetails.skus[sel]?.precioSN
    );
    handleUpdateProductDetails(
      "priceDiscount",
      productDetails.skus[sel]?.precioDescuento
    );
    handleUpdateProductDetails(
      "prod_attr",
      transformCharacteristics(productDetails.skus[sel]?.caracteristicas)
    );
    handleUpdateProductDetails(
      "description",
      productDetails.skus[sel]?.descripcion
    );

    /// Set Data
    setProductTabs(<SectionProductTabs productDetails={productDetails} />);
    setProdSKU(<SectionProductSKU productDetails={productDetails} />);

    if (productDetails.bullets) {
      if (productDetails.bullets.length !== 0) {
        setProductBulltes(
          <SectionProductBullets productDetails={productDetails} />
        );
      }
    }

    setImagesP(<SectionProductCarousel productDetails={productDetails} />);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getProductData();
    //getCollections();
    getTrusties();
  }, [window.location.search]);

  const getTrusties = async () => {
    let _t = await homeServices.loadSection("MAIN_ICONS");
    let _temp_a = [];
    for (let i = 0; i < _t.archivos_main.length; i++) {
      let _a = {
        id: _t.archivos_main[i].adi_archivo_de_inicio_id,
        img:
          API_URL +
          _t.archivos_main[i].adi_ruta_archivo.replace("./public", ""),
        title: _t.archivos_main[i].adi_titulo,
        subtitle: _t.archivos_main[i].adi_descripcion,
      };
      _temp_a.push(_a);
    }
    globalData._currentValue.slider_trusty.images = _temp_a;
    setTrusties(
      <SectionSliderTrusty
        globalData={globalData._currentValue.slider_trusty}
        isMobile={isMobile}
      />
    );
  };

  function isProductAvailable(
    targetAttribute,
    value,
    selectedAttributesValues,
    allAttributes,
    allProducts
  ) {
    // we need to know the position for the target attribute. We do that by searching the index in which the "attribute" value is equal to the targetAttribute.
    const targetAttributeIndex = allAttributes.findIndex(
      (attr) => attr.attribute === targetAttribute
    );

    // we make a copy of the array to avoid modifying the parameter.
    // arrays are passed by reference, which means that if we modify the values of the array we will be changing the
    // array for the outside as well. This is important!
    const newSelectedAttributes = [...selectedAttributesValues];

    // we replace the target value in the position of the attribute we want to test.
    // After this we have a configuration of the attributes with the value on the position of the targetAttribute
    newSelectedAttributes[targetAttributeIndex] = value;

    // now we check if there is an item that contains our attribute configuration.
    return allProducts.some((product) => {
      // we transform the array of attributes to an array of attributes values.
      // we are assuming the order of the attributes in the product matches the order of our selectedAttributes.
      // In practice this trust cause issues in the long run.
      const productAttributesValues = product.attributes.map(
        (attr) => attr.value
      );

      // we transform every array into a string with the items separated by commas. This should help with the array comparison.
      return (
        productAttributesValues.join(",") === newSelectedAttributes.join(",")
      );
    });
  }

  const setAvailableAttributes = () => {
    let newAttributes = productDetails.attributes;
    let newSkus = productDetails.skus;

    newAttributes.forEach((att, i) => {
      att.values.forEach((attV) => {
        let result = isProductAvailable(
          att.attribute,
          attV.value,
          productDetails.attributes_selected,
          productDetails.attributes,
          productDetails.skus
        );
        if (result) {
          attV.available = true; // available attribute in attribute list

          newSkus.forEach((sku) => {
            // available attribute in sku list
            if (sku.attributes[i].value === attV.value) {
              sku.attributes[i].available = true;
            }
          });
        } else {
          attV.available = false;
          newSkus.forEach((sku) => {
            // available attribute in sku list
            if (sku.attributes[i].value === attV.value) {
              sku.attributes[i].available = false;
            }
          });
        }
      });
    });

    handleUpdateProductDetails("attributes", newAttributes);
    handleUpdateProductDetails("skus", newSkus);
  };

  const setAttrSelected = (attr, val) => {
    /* update selected attribute value */

    let newAttributesSelected = productDetails.attributes_selected;
    let newAttributes = productDetails.attributes;
    console.log("Products detail: ", newAttributes);
    newAttributes.map((att, i) => {
      if (att.attribute === attr) {
        att.values.map((v) => {
          if (v.value === val) {
            v.selected = true;
            v.available = true;
          } else {
            v.selected = false;
            v.available = false;
          }
        });
        newAttributesSelected[i] = val;
      }
    });
    handleUpdateProductDetails("attributes_selected", newAttributesSelected);
    let newSkus = productDetails.skus;
    newSkus.map((sku) => {
      sku.attributes.map((skuAt) => {
        if (skuAt.attribute === attr) {
          if (skuAt.value === val) {
            skuAt.selected = true;
            skuAt.available = true;
          } else {
            skuAt.selected = false;
            skuAt.available = false;
          }
        }
      });
    });
    setAvailableAttributes();
    setAttrBool(!attrBool);
    loadSkuMatch();
  };

  const loadSkuMatch = () => {
    /* empty all data from previous SKU */
    setProdSKU(null);
    setProductBulltes(null);
    setImagesP(null);
    setProdPartNumb(null);
    setPriceP(null);
    setInputAdd(null);
    setProductStock(null);
    setNameP(null);
    setProductTabs(null);
    setShowAlertNotMatchSku(null);

    let newSelectedAttributes = [...productDetails.attributes_selected];

    let findSkuPosition = "-1";
    let findMatchSku = productDetails.skus.filter((sku, i) => {
      const productAttributesValues = sku.attributes.map((attr) => attr.value);
      if (
        productAttributesValues.join(",") === newSelectedAttributes.join(",")
      ) {
        findSkuPosition = i;
        return true;
      }
    });

    if (findMatchSku && findSkuPosition !== "-1") {
      handleUpdateProductDetails("sku_selected", findSkuPosition);
      handleUpdateProductDetails("prod_sku", findMatchSku.sku);

      setTimeout(() => {
        setProductData();
      }, 150);
    } else {
      let findValueAvailableFirst = productDetails.attributes.findLast(
        (att) => {
          return att.values.find((attV) => attV.available === true);
        }
      );
      if (findValueAvailableFirst) {
        setShowAlertNotMatchSku(
          `No existen variantes de productos para la actual selección de valores de atributos. Puede cambiar la selección del atributo ${findValueAvailableFirst.attribute} por alguna otra opción disponible`
        );
      } else {
        setShowAlertNotMatchSku(
          `No existen variantes de productos para la actual selección de valores de atributos.`
        );
      }
    }
  };

  const getValueAttr = (attr) => {
    for (let _i = 0; _i < productDetails.attributes.length; _i++) {
      if (productDetails.attributes[_i].attribute === attr) {
        for (
          let _j = 0;
          _j < productDetails.attributes[_i].values.length;
          _j++
        ) {
          if (productDetails.attributes[_i].values[_j].selected === true) {
            return productDetails.attributes[_i].values[_j].id;
          }
        }
      }
    }
    return "";
  };

  const validateAttributeLength = (arrayAtributos) => {
    let dropdown = false;
    if (arrayAtributos.length >= 4) {
      for (let i = 0; i < arrayAtributos.length && !dropdown; i++) {
        if (arrayAtributos[i].value.length > 9) {
          dropdown = true;
        }
      }
    }
    return dropdown;
  };

  const getCollections = async () => {
    const dataCollection1 = await homeServices.getListadoColecciones4();

    setCollectionDataFirts(dataCollection1 ? dataCollection1 : null);
  };
  return (
    <div className={classes.mainBackground}>
      <Container fixed style={{ paddingTop: isMobile ? 10 : 0 }}>
        <Helmet>
          <meta name="title" content={productParent?.prod_meta_titulo} />
          <meta
            name="description"
            content={productParent?.prod_meta_descripcion}
          />
        </Helmet>
        <Loading loading={loading} />

        <div
          style={{
            paddingTop: isMobile ? 15 : 5,
            paddingBottom: isMobile ? 15 : 5,
            display: "flex",
            alignItems: "center",
          }}
        >
          {!isMobile && <ButtonCategoriesMenu />}
          {breadcrumb}
        </div>
        <SectionSpaceTop />
        <div className={classes.sectionImgs}>
          <div className={classes.discountFlag + " " + hide}>{discount}</div>
          {imagesP}
        </div>
        <div className={classes.lineCenter}>
          <div className={classes.oneLine}>
            {brandName}
            {ratingP}
          </div>
          <div className={classes.oneLine}>
            {nameP}
            {prodPartNumb}
            <div className={classes.lineAqua}></div>
          </div>
          <div className={classes.oneLine}>{productBullets}</div>
          <br />
          <Grid container>
            <Grid
              item
              xs={12}
              className={
                classes.oneLine +
                (loadingVariants && " " + classes.loadingVariantsContainer)
              }
            >
              {atributos.map((attr, i) => {
                return (
                  <div key={attr.attribute}>
                    <Typography className={classes.attribName}>
                      {attr.attribute}
                    </Typography>
                    {attr.values.length < 5 &&
                    !validateAttributeLength(attr.values) ? (
                      attr.values.map((val) => (
                        <Fragment key={val.id}>
                          {val.value != "" && (
                            <Button
                              className={
                                val.available
                                  ? val.selected
                                    ? clsx(
                                        classes.attButton,
                                        classes.availableAttValue,
                                        classes.selectedAttValue
                                      )
                                    : clsx(
                                        classes.attButton,
                                        classes.availableAttValue
                                      )
                                  : val.selected
                                  ? clsx(
                                      classes.attButton,
                                      classes.disableAttValue,
                                      classes.selectedAttValue
                                    )
                                  : clsx(
                                      classes.attButton,
                                      classes.disableAttValue
                                    )
                              }
                              size="small"
                              variant="contained"
                              onClick={() =>
                                setAttrSelected(attr.attribute, val.id)
                              }
                            >
                              {val.value}
                              {!val.available && (
                                <div className={classes.lineOblique}></div>
                              )}
                            </Button>
                          )}
                        </Fragment>
                      ))
                    ) : (
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: 120 }}
                        style={{ marginLeft: 15 }}
                      >
                        <Select
                          id={attr.attribute}
                          value={getValueAttr(attr.attribute)}
                          onChange={(ev) => {
                            setAttrSelected(attr.attribute, ev.target.value);
                          }}
                          label={attr.attribute}
                          style={{ width: "150px" }}
                        >
                          {attr.values.map((val) =>
                            val.available ? (
                              <MenuItem
                                value={val.id}
                                name={val.id}
                                id={val.id}
                              >
                                {val.value}
                              </MenuItem>
                            ) : (
                              <MenuItem
                                /* disabled */
                                style={{ color: "#9b9b9b" }}
                                value={val.id}
                                name={val.id}
                                id={val.id}
                              >
                                {val.value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    )}
                  </div>
                );
              })}
            </Grid>
            {loadingVariants && (
              <div className={classes.loadingVariantsContent}>
                <div className={classes.loadingVariantsElements}>
                  <p>Cargando más variantes</p>
                  <img src="/loading_dielsa.gif" alt="loading-icon" />
                </div>
              </div>
            )}
          </Grid>

          <div className={classes.oneLine}>
            <p className={classes.alertNotMatchSku}>
              {showAlertNotMatchSku && showAlertNotMatchSku}
            </p>
          </div>
        </div>
        <div className={classes.sectionRight}>
          <div className={classes.sectionRightInside}>
            {productStock}
            {priceP}
          </div>
          <div className={classes.sectionRightInside}>{inputAdd}</div>
          <div className={classes.sectionRightInside} style={{ marginTop: 26 }}>
            {trusties}
          </div>
        </div>

        <div className={classes.sectionTabs}>{productTabs}</div>

        {/* {collectionDataFirts?.productosColeccion &&
                    <Grid item xs={12}>
                        <Container maxWidth="xl" sx={{ paddingTop: '1.5rem' }}>
                            <CollectionComponent
                                collectionData={collectionDataFirts}
                                isMobile={isMobile}
                            />
                        </Container>
                    </Grid>} */}

<SectionAccesories />
{ prodSkuProduct && <SectionRelated  prodSku={prodSkuProduct} />}
      </Container>

     
      {/* {isMobile ? (
        <Fragment>
        
        
        </Fragment>
      ) : (
        <Fragment>
        
       
        </Fragment>
      )} */}
    </div>
  );
}
