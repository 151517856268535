// @material-ui/
import TextField from "@mui/material/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useAuth } from "hooks/useAuth";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { StyleRegister } from "./StyleRegister";
import { accountService } from "services/account/account.service";
export const Register = () => {
  const history = useHistory();
  const classes = StyleRegister();
  const { setOpenAlert } = useAuth();
  const [fullName, setFullName] = useState("");
  const [rfc, setRfc] = useState("");
  const [isValidRfc, setIsValidRfc] = useState(true);
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [phone, setPhone] = useState("");
  const [isValidPhone, setIsValidPhone] = useState(true);
  const handleChangeRfc = (inputValue) => {
    setRfc(inputValue);
    const rfcRegexIndividual = /^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}$/;
    const rfcRegexLegalEntity = /^[A-Z]{3}[0-9]{6}[A-Z0-9]{3}$/;
    if (
      rfcRegexIndividual.test(inputValue) ||
      rfcRegexLegalEntity.test(inputValue)
    ) {
      //Rfc válido
      setIsValidRfc(true);
    } else {
      // Rfc no válido
      setIsValidRfc(false);
    }
  };
  const handleChangeEmail = (inputValue) => {
    setEmail(inputValue);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(inputValue)) {
      //Correo válido
      setIsValidEmail(true);
    } else {
      //Correo no válido
      setIsValidEmail(false);
    }
  };
  const handleChangePhone = (inputValue) => {
    setPhone(inputValue);
    const phoneRegex = /^\d{10}$/;
    if (phoneRegex.test(inputValue)) {
      //Si es un numero
      setIsValidPhone(true);
    } else {
      setIsValidPhone(false);
    }
  };

  const addUserForm = async (e) => {
    e.preventDefault();
    if (
      fullName.length > 0 &&
      address.length > 0 &&
      isValidEmail &&
      isValidPhone &&
      isValidRfc
    ) {

      let result =  await accountService.addRegisterBussiness({email,fullName,address,rfc,phone})
      let type=2
      const resulta = await accountService.addNewUserInteractions({type})
      if(result.status=='fail'){
        setOpenAlert({
          open: true,
          message: "Intenta más tarde.",
          type: "error",
        });
      }else{
        
        
        setOpenAlert({
          open: true,
          message:
            "Tu solicitud está siendo revisada. Una vez aprobada, recibirás un correo electrónico con tus credenciales para acceder al portal.",
          type: "success",
        });
        if(resulta){
        history.push("/")
       }
      }
    } else {
      setOpenAlert({
        open: true,
        message: "Revisar los datos del formulario",
        type: "warning",
      });
    }
  };

  return (
    <form onSubmit={addUserForm}>
      <div className={classes.rootGrid}>
        <Grid container spacing={2}>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              className={classes.inputs}
              size="small"
              id="fullName"
              name="fullName"
              type="text"
              label="Nombre Completo"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              className={classes.inputs}
              size="small"
              id="Rfc"
              name="Rfc"
              type="text"
              label="RFC"
              value={rfc}
              error={!isValidRfc}
              helperText={!isValidRfc ? "Ingresa un RFC válido." : ""}
              onChange={(e) => handleChangeRfc(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              className={classes.inputs}
              size="small"
              id="email"
              name="email"
              type="email"
              label="Correo electrónico"
              value={email}
              error={!isValidEmail}
              helperText={
                !isValidEmail
                  ? "Por favor, ingresa un correo electrónico válido."
                  : ""
              }
              onChange={(e) => handleChangeEmail(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              className={classes.inputs}
              size="small"
              id="address"
              name="address"
              type="text"
              label="Dirección"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              className={classes.inputs}
              size="small"
              id="phone"
              name="phone"
              type="text"
              label="Teléfono"
              value={phone}
              error={!isValidPhone}
              helperText={
                !isValidPhone
                  ? "Por favor, ingresa un número de teléfono válido."
                  : ""
              }
              onChange={(e) => handleChangePhone(e.target.value)}
            />
          </Grid>
        </Grid>
        <div className={classes.buttonDiv}>
          <Button variant="contained" className={classes.buttons} type="submit">
            Enviar
          </Button>
        </div>
      </div>
    </form>
  );
};
