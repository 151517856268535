import React, { useEffect, useState, useContext } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((styles) => ({
  container: {
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 15,
    paddingRight: 15,
    display: "block",
    width: "100%",
  },
  title: {
    color: "#0B3196",
    margin: 0,
    minHeight: 20,
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: 500,
    textDecoration: "none",
    display: "inline-block",
    fontSize: "12px",
    [styles.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  subtitle: {
    color: "#9B9B9B",
    margin: 0,
    minHeight: 20,
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: 500,
    textDecoration: "none",
    display: "inline-block",
    marginLeft: 5,
    fontSize: "12px",
    [styles.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
}));

export default function SectionProductPartNumber(props) {
  const classes = useStyles();
  const [sku, setSku] = useState(props.productDetails.name);
  const [name, setName] = useState(props.productDetails.name);
  useEffect(() => {
    setSku(
      props.productDetails.skus[props.productDetails.sku_selected]?.sku
    );
    setName(
      props.productDetails.skus[props.productDetails.sku_selected]?.foreignName
    );
  }, []);
  return (
    <div className={classes.container}>
      <div>
        <Typography className={classes.title}>SKU:</Typography>
        <Typography className={classes.subtitle}>{name}</Typography>
      </div>
    </div>
  );
}
