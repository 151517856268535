import React, {
  useState,
  useEffect,
  useRef,
} from "react";
import { userService } from "_services/user.service";
import { generalService } from "_services/general.service";
import { accountService } from "services/account/account.service";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import Container from "@material-ui/core/Container";

import "assets/custom/Login.css";
// @material-ui/
import TextField from "@mui/material/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

// core components
import useCheckMobileScreen from "components/Mobile/Mobile.js";
import SectionSpaceTop from "views/Home/Sections/SectionSpaceTop.js";

// sections for this page
import { useAuth } from "hooks/useAuth";
import { Style } from "./Style";
import { Title } from "ui/title";
export const RegisterBusiness = () => {
  const classes = Style();
  const history = useHistory();
  const { setOpenAlert } = useAuth();

  let isMobile = useCheckMobileScreen();

  const [pais, setPais] = useState([]);
  const [paisSelected, setPaisSelected] = useState(0);
  const [estado, setEstado] = useState([]);
  const [estadoSelected, setEstadoSelected] = useState(0);

  const [cities, setCities] = useState([]);
  const [city, setCity] = useState({
    id_city: 0,
    city: "",
  });

  const [cfdi, setCfdi] = useState([]);
  const [cfdiSelected, setCfdiSelected] = useState(0);
  const [formapago, setFormapago] = useState([]);
  const [formapagoSelected, setFormapagoSelected] = useState(0);
  const [mediopago, setMediopago] = useState([]);
  const [mediopagoSelected, setMediopagoSelected] = useState(0);

  //declare form inputs
  let chcity = useRef(null);

  let razonsocial = useRef(null);
  let nombrecomercial = useRef(null);
  let sitioweb = useRef(null);
  let email = useRef(null);
  let rfc = useRef(null);
  let codigopostal = useRef(null);
  let calle = useRef(null);
  let numero = useRef(null);
  let colonia = useRef(null);
  //let municipio = useRef(null);
  let banco = useRef(null);
  let numerocuenta = useRef(null);

  const getPais = async () => {
    const pais_response = await generalService.getCountries();
    setPais(pais_response.paises);
  };

  const getEstado = async (paisId) => {
    const estado_response = await generalService.getStates(paisId);
    setEstado(estado_response.estados);
  };

  const getCities = async (estadoId) => {
    const cities_response = await generalService.getCities(estadoId);
    setCities(cities_response.data.constCiudadesEstados.rows);
  };

  const getCfdi = async () => {
    const cfdi_response = await generalService.getCfdi();
    setCfdi(cfdi_response.constCfdi);
  };

  const getFormapago = async () => {
    const formapago_response = await generalService.getPaymentFormas();
    setFormapago(formapago_response.constSapFormasPago);
  };

  const getMedioPago = async () => {
    const mediopago_response = await generalService.getPaymentMetodos();
    setMediopago(mediopago_response.constSapMetodosPago);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPais();
    getCfdi();
    getFormapago();
    getMedioPago();
  }, []);



  useEffect(() => {
    window.scrollTo(0, 0);
    userService.logout();
  }, []);

  const handleChange = (event) => {
    if (event.target.name === "pais") {
      setEstadoSelected(0);
      setCity({ id_city: 0, city: "" });
      setCities([]);
      setPaisSelected(event.target.value);
      getEstado(event.target.value);
    }
    if (event.target.name === "estado") {
      setCity({ id_city: 0, city: "" });
      setCities([]);
      setEstadoSelected(event.target.value);
      getCities(event.target.value);
    }
    if (event.target.name === "formapago") {
      setFormapagoSelected(event.target.value);
    }
    if (event.target.name === "mediopago") {
      setMediopagoSelected(event.target.value);
    }
    if (event.target.name === "cfdi") {
      setCfdiSelected(event.target.value);
    }
  };

  const handleChangeCity = (event) => {
    let city_name = "";
    for (let index = 0; index < event.target.length; index++) {
      const city = event.target[index];
      if (city.value === event.target.value) {
        city_name = city.id;
        break;
      }
    }
    setCity({
      id_city: event.target.value,
      city: city_name,
    });
  };

  const createClient = async (ev) => {
    ev.preventDefault();

    const register_response = await userService.registerClient(
      ev.target.razonsocial.value,
      ev.target.nombrecomercial.value,
      ev.target.sitioweb.value,
      ev.target.rfc.value,
      ev.target.email.value,
      ev.target.codigopostal.value,
      ev.target.calle.value,
      ev.target.numero.value,
      ev.target.colonia.value,
      city.city, //ev.target.municipio.value,
      paisSelected,
      estadoSelected,
      formapagoSelected,
      ev.target.banco.value,
      ev.target.numerocuenta.value,
      mediopagoSelected,
      cfdiSelected
    );

    if (register_response.error) {
      setOpenAlert({
        message: "Hubo un error al intentar realizar el registro.",
        type: "error",
        open: true,
      });
    } else {
      let type=2
      const resulta = await accountService.addNewUserInteractions({type})
      setOpenAlert({
        message:
          "Gracias por contactarnos, en breve nuestro personal te contactará para dar seguimiento a tu solicitud.",
        type: "info",
        open: true,
      });
      //vaciar formulario
      razonsocial.current.value = "";
      nombrecomercial.current.value = "";
      sitioweb.current.value = "";
      rfc.current.value = "";
      email.current.value = "";
      codigopostal.current.value = "";
      calle.current.value = "";
      numero.current.value = "";
      colonia.current.value = "";
      banco.current.value = "";
      numerocuenta.current.value = "";
      setPaisSelected(0);
      setEstadoSelected(0);
      setCity({ id_city: 0, city: "" });
      setCities([]);
      setFormapagoSelected(0);
      setMediopagoSelected(0);
      setCfdiSelected(0);
    }
  };


  return (
    <div className={classes.mainBackground}>
      <Container fixed style={{ paddingTop: isMobile ? 20 : 0 }}>
        <SectionSpaceTop />

        {isMobile ? (
          <div className={classes.spaceDiv}>
            <Link className={classes.linkBack} onClick={() => history.goBack()}>
              <ChevronLeftIcon className={classes.linkBackArrow} /> Regresar
            </Link>
            <Typography className={classes.titleMobile}>
              Nueva cuenta
            </Typography>
            <Divider className={classes.titleDivider} />
          </div>
        ) : (
          ""
        )}

        <form className={classes.accesoCliente} onSubmit={createClient}>
          {isMobile ? "" : <Title title="Nueva cuenta" />}
          <div className={classes.clienteNuevoTextDiv}>
            <Typography className={classes.nuevoClienteText}>
              {isMobile
                ? ""
                : `Por favor, llena este formulario para acceder a todo el catálogo de
              Dielsa`}
            </Typography>
          </div>

          <>
            <div className={classes.rootGrid}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.titleFormSection}>
                    Datos del solicitante
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    inputRef={razonsocial}
                    id="razonsocial"
                    name="razonsocial"
                    type="text"
                    label="Nombre o Razón Social"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    inputRef={nombrecomercial}
                    id="nombrecomercial"
                    name="nombrecomercial"
                    type="text"
                    label="Nombre comercial"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    inputRef={sitioweb}
                    id="sitioweb"
                    name="sitioweb"
                    type="text"
                    label="Sitio web"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    inputRef={rfc}
                    id="rfc"
                    name="rfc"
                    type="text"
                    label="RFC (RUC)"
                    inputProps={{
                      minLength: 12,
                      maxLength: 13,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    inputRef={email}
                    id="email"
                    name="email"
                    type="email"
                    label="Correo electrónico"
                  />
                </Grid>
              </Grid>
            </div>

            <div className={classes.rootGrid}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.titleFormSection}>
                    Domicilio
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                      <TextField
                        required
                        variant="outlined"
                        className={classes.inputs}
                        size="small"
                        inputRef={codigopostal}
                        id="codigopostal"
                        name="codigopostal"
                        type="text"
                        label="Código postal"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    inputRef={calle}
                    id="calle"
                    name="calle"
                    type="text"
                    label="Calle"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        required
                        variant="outlined"
                        className={classes.inputs}
                        size="small"
                        inputRef={numero}
                        id="numero"
                        name="numero"
                        type="text"
                        label="Número"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    inputRef={colonia}
                    id="colonia"
                    name="colonia"
                    type="text"
                    label="Colonia"
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  className={classes.inputs}
                  size="small"
                  inputRef={municipio}
                  id="municipio"
                  name="municipio"
                  type="text"
                  label="Municipio o Delegación"
                />
              </Grid> */}
                <Grid item xs={12} sm={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <FormControl
                        required
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel htmlFor="outlined-pais-native-simple">
                          País
                        </InputLabel>
                        <Select
                          className={classes.selects}
                          native
                          value={paisSelected}
                          label="País"
                          onChange={handleChange}
                          inputProps={{
                            name: "pais",
                            id: "outlined-pais-native-simple",
                          }}
                        >
                          <option value=""></option>
                          {pais.map((p) => {
                            return (
                              <option
                                key={`pais-id-${p.pais_pais_id}`}
                                value={p.pais_pais_id}
                              >
                                {p.pais_nombre}
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl
                        required
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel htmlFor="outlined-estado-native-simple">
                          Estado
                        </InputLabel>
                        <Select
                          className={classes.selects}
                          native
                          value={estadoSelected}
                          label="Estado"
                          onChange={handleChange}
                          inputProps={{
                            name: "estado",
                            id: "outlined-estado-native-simple",
                          }}
                        >
                          <option value=""></option>
                          {estado.map((e, index) => {
                            return (
                              <option
                                key={`estado-id-${index}`}
                                value={e.estpa_estado_pais_id}
                              >
                                {e.estpa_estado_nombre}
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl
                        required
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel htmlFor="outlined-estado-native-simple">
                          Ciudad
                        </InputLabel>
                        <Select
                          className={classes.selects}
                          native
                          value={city.id}
                          label="Ciudad"
                          onChange={handleChangeCity}
                          inputProps={{
                            name: "ciudad",
                            id: "outlined-ciudad-native-simple",
                          }}
                        >
                          <option value=""></option>
                          {cities.map((c, index) => {
                            return (
                              <option
                                key={`cities-id-${index}`}
                                value={c.city_ciudades_estados_id}
                                name={c.city_ciudad}
                                id={c.city_ciudad}
                              >
                                {c.city_ciudad}
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>

            <div className={classes.rootGrid}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.titleFormSection}>
                    Información de pago
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    //required
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-formapago-native-simple">
                      Forma de pago
                    </InputLabel>
                    <Select
                      className={classes.selects}
                      native
                      value={formapagoSelected}
                      label="Forma de pago"
                      onChange={handleChange}
                      inputProps={{
                        name: "formapago",
                        id: "outlined-formapago-native-simple",
                      }}
                    >
                      <option value=""></option>
                      {formapago.map((fp, index) => {
                        return (
                          <option
                            key={`formapago-id-${index}`}
                            value={`Id:${fp.sfp_sap_formas_pago_id} - ${fp.sfp_clave} ${fp.sfp_descripcion}`}
                          >{`${fp.sfp_clave} ${fp.sfp_descripcion}`}</option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    inputRef={banco}
                    id="banco"
                    name="banco"
                    type="text"
                    label="Banco"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    inputRef={numerocuenta}
                    id="numerocuenta"
                    name="numerocuenta"
                    type="text"
                    label="Número de cuenta bancaria"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    //required
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-mediopago-native-simple">
                      Método de pago
                    </InputLabel>
                    <Select
                      className={classes.selects}
                      native
                      value={mediopagoSelected}
                      label="Método de pago"
                      onChange={handleChange}
                      inputProps={{
                        name: "mediopago",
                        id: "outlined-mediopago-native-simple",
                      }}
                    >
                      <option value=""></option>
                      {mediopago.map((mp, index) => {
                        return (
                          <option
                            key={`mediopago-id-${index}`}
                            value={`Id:${mp.smp_metodos_pago_id} - ${mp.smp_codigo_metodo} ${mp.smp_definicion}`}
                          >{`${mp.smp_codigo_metodo} ${mp.smp_definicion}`}</option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    required
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-cfdi-native-simple">
                      Uso del CFDI
                    </InputLabel>
                    <Select
                      className={classes.selects}
                      native
                      value={cfdiSelected}
                      label="Uso del CFDI"
                      onChange={handleChange}
                      inputProps={{
                        name: "cfdi",
                        id: "outlined-cfdi-native-simple",
                      }}
                    >
                      <option value=""></option>
                      {cfdi.map((cfdi, index) => {
                        return (
                          <option
                            key={`cfdi-id-${index}`}
                            value={`Id:${cfdi.cfdi_cfdi_id} - ${cfdi.cfdi_codigo} ${cfdi.cfdi_texto}`}
                          >{`${cfdi.cfdi_codigo} ${cfdi.cfdi_texto}`}</option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <div className={classes.buttonDiv}>
              <Button
                variant="contained"
                className={classes.buttons}
                type="submit"
              >
                Registrarme
              </Button>
            </div>
          </>
        </form>
      </Container>
    </div>
  );
};
