import { authHeader } from "../_helpers/auth-header.js";
import { API_URL } from "./api.url.back";

export const categoryServices = {
  getCategoryData,
  getCategoryTree,
  getCategoryAttributes,
  getAttributesValues,
  getFiltersData,
};

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    const responseData = {
      error: false,
      data: data,
    };
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function getCategoryData(
  idCategoria,
  page,
  pagesize,
  orderBy,
  orderByType,
  idSocioNegocio
) {
  const body = {
    limite: pagesize,
    pagina: page,
    cat_categoria_id: idCategoria,
    // condicion: idCategoria,
    orderBy: orderBy,
    ASCDESC: orderByType,
    idSocioNegocio: idSocioNegocio,
  };
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };

  // return fetch(`${API_URL}/productos/getProductosByFiltroPadres_Front_ST`, requestOptions)
  return fetch(
    `${API_URL}/category/get-products-category`,
   // `${API_URL}/productos/frontGetProductoByCategoriaV3OnlyChilds`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getFiltersData(
  idCate,
  page,
  pagesize,
  orderBy,
  orderByType,
  atributos
) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      limite: pagesize,
      pagina: page,
      cat_categoria_id: idCate,
      orderBy: orderBy,
      ASCDESC: orderByType,
      AtributosArray: atributos,
    }),
  };

  return fetch(
    `${API_URL}/atributos/getProductosByFiltroAtributoPadresST`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getCategoryTree() {
  const requestOptions = {
    method: "GET",
  };

  return fetch(`${API_URL}/categoria/list_public`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getCategoryAttributes(idCate) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      cat_categoria_id: idCate,
    }),
  };

  return fetch(`${API_URL}/atributos/AllCategoriasAtributosST`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getAttributesValues(id) {
  const requestOptions = {
    method: "GET",
  };

  return fetch(
    `${API_URL}/atributos/getValuesFromAtributteST/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}
