
import { makeStyles } from "@material-ui/core/styles";

export const Style = makeStyles((styles) => ({
  mainBackground: {
    backgroundColor: "#FFF",
  },
  accesoCliente: {
    display: "block",
    width: "60%",
    margin: "auto",
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: "10vh",
    paddingBottom: "20vh",
    /* backgroundColor: "#efefef", */
    [styles.breakpoints.down("sm")]: {
      width: "100%",
      paddingBottom: "5vh",
      paddingTop: "2vh",
    },
  },
  accesoCliente2: {
    display: "block",
    width: "60%",
    margin: "auto",
    paddingLeft: 50,
    paddingRight: 50,
 
    /* backgroundColor: "#efefef", */
    [styles.breakpoints.down("sm")]: {
      width: "100%",
  
    },
  },
  inputsDiv: {
    width: "100%",
    paddingLeft: "15%",
    paddingRight: "15%",
    marginBottom: 15,
    [styles.breakpoints.down("sm")]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  buttonDiv: {
    width: "100%",
    paddingTop: "10%",
    paddingLeft: "25%",
    paddingRight: "25%",
    marginBottom: 15,
    [styles.breakpoints.down("sm")]: {
      paddingTop: 30,
      paddingLeft: 15,
      paddingRight: 15,
      width: "90%",
      margin: "auto",
    },
  },
  //alerts
  alerts: {
    color: "#FFFFFF",
    backgroundColor: "#40DF00",
    fontFamily: "'Centrale Sans Light'",
    fontSize: 22,
    "& .MuiAlert-icon": {
      color: "#FFFFFF",
    },
    "&.MuiAlert-standardError": {
      backgroundColor: "#DF2B2B",
    },
  },
  inputs: {
    width: "100%",
    border: "1px solid #EAEAEA",
    borderRadius: "2px",
    backgroundColor: "#F5F5F5",
  },
  spaceDiv: {
    marginTop: 15,
    marginBottom: 15,
  },
  buttons: {
    width: "100%",
    color: "#ffffff",
    backgroundColor: "#0C2D9C",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  clienteNuevoTextDiv: {
    width: "100%",
    marginBottom: 15,
  },
  nuevoClienteText: {
    color: "#000000",
    fontFamily: "'Centrale Sans Regular'",
    textAlign: "center",
    marginTop: 10,
    marginBottom: 10,
    fontSize: 18,
  },
  linkBack: {
    fontFamily: "'Centrale Sans Medium'",
    color: "#0B3196",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "19px",
    textAlign: "center",
    paddingLeft: "30px",
    position: "relative",
  },
  linkBackArrow: {
    position: "absolute",
    left: "5px",
    bottom: "-2px",
  },
  titleMobile: {
    fontFamily: "'Centrale Sans Bold'",
    fontSize: 24,
    lineHeight: "29px",
    textAlign: "center",
    color: "#0b3196",
    paddingTop: 20,
  },
  titleDivider: {
    border: "1px solid #0B3196",
    width: "95%",
    margin: "auto",
  },
  root: {
    "& .MuiTextField-root": {
      margin: styles.spacing(1),
      width: "25ch",
    },
  },
  rootGrid: {
    flexGrow: 1,
    margin: "50px 0",
    [styles.breakpoints.down("sm")]: {
      margin: "20px 0",
    },
  },
  titleFormSection: {
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
    color: "#0C2D9C",
    [styles.breakpoints.down("sm")]: {
      fontFamily: "'Centrale Sans Regular'",
      fontSize: 14,
      fontWeight: 300,
    },
  },
  formControl: {
    minWidth: "100%",
    "& label": {
      transform: "translate(14px, 14px) scale(1)",
    },
  },
  selectEmpty: {
    marginTop: styles.spacing(2),
  },
  selects: {
    backgroundColor: "#F5F5F5",
    border: "1px solid #EAEAEA",
    borderRadius: 4,
    height: 40,
    fontFamily: "'Centrale Sans Regular'",
  },
}));