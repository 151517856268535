import React, { useState, useEffect, useContext, Fragment } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((styles) => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [styles.breakpoints.down("sm")]: {
      display: "inline",
    },
  },
  imgDiv: {
    width: "49%",
    display: "inline-block",
    paddingBottom: "25px",
    "&:hover": {
      cursor: "pointer",
    },
    [styles.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: "5px",
      paddingRight: "0px",
    },
  },
  image: {
    width: "100%",
    borderRadius: "10px",
  },
  wrapper: {
    marginTop: 35,
  },
  mobileMode: {
    display: "none",
    ["@media (max-width: 959px)"]: {
      display: "block",
    },
  },
  desktopMode: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    ["@media (max-width: 959px)"]: {
      display: "none",
    },
  }
}));

export default function SectionIndustryFocused(props) {
  const classes = useStyles();
  const goTo = (link) => {
    if (link && link != "") {
      window.location.href = link;
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.mobileMode}>
          <Splide
            id="splide"
            aria-labelledby="autoplay-example-heading"
            hasTrack={ false }
            options={{
              rewind: true,
              perPage: 1,
              perMove: 1,
              padding: 0,
              arrows: false,
              gap: 0,
              fixedWidth: "100%",
              type         : 'loop',
              gap          : '1rem',
              autoplay     : true,
              pauseOnHover : false,
              resetProgress: false
            }}
          >
            {props.globalData.banners.map((slide) => (
              <SplideSlide key={slide.id} className={classes.sliders}>
                <div
                  className={classes.imgDiv}
                  onClick={() => {
                    goTo(slide.link);
                  }}
                >
                  <img
                    alt={slide.id}
                    src={slide.img}
                    className={classes.image}
                  />
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>
        <div className={classes.desktopMode}>
          <Fragment>
            {props.globalData.banners.map((slide) => (
              <div
                key={slide.id}
                className={classes.imgDiv}
                onClick={() => {
                  goTo(slide.link);
                }}
              >
                <img alt={slide.id} src={slide.img} className={classes.image} />
              </div>
            ))}
          </Fragment>
        </div>
      </div>
    </div>
  );
}
