import * as React from 'react';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';

export const AlertDefault = (props) =>{
    const handleClose = () => {
        props.setOpenAlertF({
            message: "",
            type: "",
            open: false, 
          })
      };
return (    
<Box sx={{ width: '100%' }}>
 <Collapse in={props.openAlertF.open}>
  {props.openAlertF.open && 
   <Snackbar open={props.openAlertF.open} anchorOrigin={{vertical: 'top', horizontal: 'right'   }} autoHideDuration={3000} onClose={handleClose}>
    <Alert
     severity={props.openAlertF.type}
     sx={{ mb: 2 }}
    >
    {props.openAlertF.message}
  </Alert></Snackbar>
  }
</Collapse>

</Box>);
}