import { createTheme } from "@mui/material";
import "../index.css";

import { esES } from "@material-ui/core/locale";

/* custom configuration if is not save in local storage the user configuration */
//Azul #0b3196
//Verde #40df00
//Aqua #3cdbc0
let mode = "light";
let main_p = "#0b3196"; // old #003773
let main_s = "#40df00"; // old #40df00

const theme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1081,
        lg: 1200,
        xl: 1920,
      },
    },
    typography: {
      fontFamily: ["Centrale Sans Light", "sans-serif"].join(","),
    },
    palette: {
      primary: {
        light: "#dadada",
        main: main_p,
        dark: "#006db3",
        table: "#F8F8F8",
        error: "#DF2B2B",
      },
      secondary: {
        light: "#F8F8F8",
        main: main_s,
        dark: "#F8F8F8",
      },
    },
    //alerts
    alerts: {
      color: "#FFFFFF",
      backgroundColor: "#40DF00",
      fontFamily: "'Centrale Sans Light'",
      fontSize: 22,
      "& .MuiAlert-icon": {
        color: "#FFFFFF",
      },
      "&.MuiAlert-standardError": {
        backgroundColor: "#DF2B2B",
      },
      "&..MuiAlert-standardInfo": {
        backgroundColor: "#0091df",
      },
    },
  },
  esES
);

export default theme;
