import * as React from 'react';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';

export default function Alerts(props) {
 
    const handleClose = () => {
        props.setOpenAlert(false)
      };
   

  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={props.openAlert}>
        {props.openAlert && 
        <Snackbar open={props.openAlert} anchorOrigin={{vertical: 'top', horizontal: 'right'   }} autoHideDuration={3000} onClose={handleClose}>
        <Alert
           severity={props.typeAlert}
          sx={{ mb: 2 }}
        >
          {props.message}
        </Alert></Snackbar>
        }
    </Collapse>
      
    </Box>
 
  );
}
