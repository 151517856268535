export const Variant = [
    {
      "name": "UPS",
      "Atributo 1": "KVA",
      "Atributo 2": "MARCA",
      "id": 1000091
    },
    {
      "name": "Alta tensión",
      "Atributo 1": "CALIBRE",
      "Atributo 2": "COLOR",
      "id": 1000092
    },
    {
      "name": "Baja tensión",
      "Atributo 1": "CALIBRE",
      "Atributo 2": "COLOR",
      "id": 1000093
    },
    {
      "name": "Media tensión",
      "Atributo 1": "CALIBRE",
      "Atributo 2": "COLOR",
      "id": 1000094
    },
    {
      "name": "Contactos y apagadores",
      "Atributo 1": "PUERTOS",
      "Atributo 2": "COLOR",
      "id": 1000095
    },
    {
      "name": "Herramientas",
      "Atributo 1": null,
      "Atributo 2": null,
      "id": 1000096
    },
    {
      "name": "Racks",
      "Atributo 1": "MEDIDA",
      "Atributo 2": null,
      "id": 1000146
    },
    {
      "name": "Gabinetes",
      "Atributo 1": "MATERIAL",
      "Atributo 2": null, //"PROTECCION",
      "id": 1000147
    },
    {
      "name": "Organizadores de cables",
      "Atributo 1": "MEDIDA",
      "Atributo 2": null, // "TIPO",
      "id": 1000148
    },
    {
      "name": "Accesorios",
      "Atributo 1": null,
      "Atributo 2": null,
      "id": 1000150
    },
    {
      "name": "Charolas",
      "Atributo 1": null,
      "Atributo 2": null,
      "id": 1000199
    },
    {
      "name": "PDU",
      "Atributo 1": "TIPO",
      "Atributo 2": "CONTACTOS",
      "id": 1000200
    },
    {
      "name": "Cofinamiento",
      "Atributo 1": null,
      "Atributo 2": null,
      "id": 1000221
    },
    {
      "name": "Charola",
      "Atributo 1": "PERALTE",
      "Atributo 2": "ANCHO",
      "id": 1000113
    },
    {
      "name": "Tubería conduit",
      "Atributo 1": "TIPO",
      "Atributo 2": "MEDIDA",
      "id": 1000114
    },
    {
      "name": "Soportería y Fijación",
      "Atributo 1": "TIPO",
      "Atributo 2": "MEDIDA",
      "id": 1000121
    },
    {
      "name": "PVC",
      "Atributo 1": "TIPO",
      "Atributo 2": null,
      "id": 1000201
    },
    {
      "name": "Canaleta",
      "Atributo 1": null,
      "Atributo 2": null,
      "id": 1000209
    },
    {
      "name": "CCTV",
      "Atributo 1": null,
      "Atributo 2": null,
      "id": 1000204
    },
    {
      "name": "Control de acceso",
      "Atributo 1": "CONDUCTORES Y CALIBRE",
      "Atributo 2": "COLOR",
      "id": 1000205
    },
    {
      "name": "Detección de incendios",
      "Atributo 1": "CONDUCTORES Y CALIBRE",
      "Atributo 2": null,
      "id": 1000206
    },
    {
      "name": "Cable UTP",
      "Atributo 1": "COLOR",
      "Atributo 2": "CATEGORÍA",
      "id": 1000000
    },
    {
      "name": "Patch Panel",
      "Atributo 1": "PUERTOS",
      "Atributo 2": "POSICIÓN",
      "id": 1000007
    },
    {
      "name": "Jacks y Plugs",
      "Atributo 1": "CATEGORÍA",
      "Atributo 2": "COLOR",
      "id": 1000008
    },
    {
      "name": "Patch cord",
      "Atributo 1": "CATEGORÍA",
      "Atributo 2": "LONGITUD",
      "id": 1000009
    },
    {
      "name": "Placa de pared",
      "Atributo 1": "PUERTOS",
      "Atributo 2": "MATERIAL",
      "id": 1000010
    },
    {
      "name": "Caja de sobreponer",
      "Atributo 1": null,
      "Atributo 2": null,
      "id": 1000011
    },
    {
      "name": "Herramienta",
      "Atributo 1": "CONECTOR",
      "Atributo 2": null,
      "id": 1000013
    },
    {
      "name": "Cable de fibra óptica",
      "Atributo 1": "TIPO DE FIBRA",
      "Atributo 2": "HILOS",
      "id": 1000049
    },
    {
      "name": "Conectores",
      "Atributo 1": "CONECTOR",
      "Atributo 2": "HILOS",
      "id": 1000051
    },
    {
      "name": "Jumpers y pigtails",
      "Atributo 1": "TIPO DE FIBRA",
      "Atributo 2": "CONECTORES",
      "id": 1000052
    },
    {
      "name": "Distribuidor",
      "Atributo 1": "TIPO DE FIBRA",
      "Atributo 2": null,
      "id": 1000053
    },
    {
      "name": "Canalización",
      "Atributo 1": "MARCA",
      "Atributo 2": null,
      "id": 1000211
    },
    {
      "name": "Cableado estructurado",
      "Atributo 1": "CATEGORÍA",
      "Atributo 2": null,
      "id": 1000212
    },
    {
      "name": "Energía",
      "Atributo 1": "MARCA",
      "Atributo 2": null,
      "id": 1000213
    },
    {
      "name": "Fibra Óptica",
      "Atributo 1": "TIPO DE FIBRA",
      "Atributo 2": "HILOS",
      "id": 1000214
    },
    {
      "name": "Racks y gabinetes",
      "Atributo 1": "TIPO",
      "Atributo 2": null,
      "id": 1000215
    },
    {
      "name": "Seguridad física",
      "Atributo 1": "MARCA",
      "Atributo 2": null,
      "id": 1000216
    }
  ];
  