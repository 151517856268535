import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { API_URL } from "_services/api.url.back.img";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((styles) => ({
    barraDiagonal: {
        position: 'absolute',
        top: '4%',
        right: '-20%',
        width: '100%',
        height: '15%',
        background: '#ff0000',
        zIndex: 1,
        clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
        transform: 'rotate(45deg)',
        // [styles.breakpoints.down("sm")]: {
        //     top: '4%',
        // },
        ["@media (max-width: 1115px)"]: {
            top: '11%',
        },
        ["@media (max-width: 1080px)"]: {
            top: '8%',
        },
        ["@media (max-width: 880px)"]: {
            top: '12%',
        },
        ["@media (max-width: 800px)"]: {
            top: '14%',
        },
        ["@media (max-width: 700px)"]: {
            top: '20%',
        },
        ["@media (max-width: 620px)"]: {
            top: '18%',
            right: '-26%',
            height: '24%',
            width: '118%',
        },
        ["@media (max-width: 550px)"]: {
            top: '13%',
            right: '-26%',
            height: '20%',
            width: '118%',
        },
    },
    textoEnBarra: {
        position: 'absolute',
        top: '14%',
        right: '0%',
        zIndex: 2,
        color: '#fff',
        fontSize:'13px !important',
        transform: 'rotate(45deg)',
        ["@media (max-width: 900px)"]: {
            top: '25%',
            right: '-3%',
        },
        ["@media (max-width: 780px)"]: {
            top: '20%',
            right: '-4%',
        },
        ["@media (max-width: 720px)"]: {
            top: '25%',
            right: '-8%',
        },
        ["@media (max-width: 600px)"]: {
            top: '30%',
            right: '2%',
        },
        ["@media (max-width: 550px)"]: {
            top: '23%',
            right: '0%',
        },
        ["@media (max-width: 480px)"]: {
            top: '24%',
            right: '-5%',
        },
    },
    imagen: {
        maxWidth: '100%',
        display: 'block',
        height: 'auto',
        // ["@media (max-width: 1366px)"]: {
        //     minHeight: '260px',
        // },
        // ["@media (max-width: 1080px)"]: {
        //     minHeight: '260px',
        // },
    },
}));

const LastProduct = ({ item }) => {
    const history = useHistory();
    const classes = useStyles();

    const goTo = (link) => {
        if (link) {
          history.push(`/product?prod=${link.productoPadreId}&sku=${link.prod_producto_id}`);
        }
    };

    return (
        <Box
            sx={{
                padding: '4px 2px',
                "&:hover": {
                    cursor: "pointer",
                },
            }}
            onClick={() => {
                goTo(item);
            }}
        >
            <Box
                sx={{
                    border: '1px solid #c5c5c5',
                    borderRadius: '10px',
                }}
            >
                <div
                    style={{
                        position: 'relative',
                        display: 'inline-block',
                        overflow: 'hidden',
                    }}
                >
                    <div className={classes.barraDiagonal}></div>
                    <Typography
                        className={classes.textoEnBarra}
                        //variant="h6"
                        component="div"
                    >
                        Últimas piezas
                    </Typography>
                    <Box
                        sx={{
                            padding: '10px',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            className={classes.imagen}
                            src={
                                item.imgprod_ruta_archivo
                                && API_URL + item.imgprod_ruta_archivo.replace("./public", "")
                            }
                        />
                    </Box>
                </div>
                <Box
                    sx={{
                        padding: '5px 20px',
                    }}
                >
                    <Typography
                        variant="body1"
                        sx={{
                            fontWeight: 500,
                            color: "#0b3196",
                            fontFamily: "Centrale Sans Medium",
                        }}
                        noWrap
                    >
                        {item.prod_nombre}
                    </Typography>
                    <Typography
                        variant="button"
                        sx={{
                            fontWeight: 500,
                            color: "#40df00",
                            fontFamily: "Centrale Sans Medium",
                        }}
                    >
                        {`SKU ${item.prod_nombre_extranjero}`}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default LastProduct