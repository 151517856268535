export function dateFormat(date) {
  //return price.toLocaleString("en-US", { minimumFractionDigits: 2 });

  if (typeof date === "string") {
    return `${date.slice(0, 4)}/${date.slice(4, 6)}/${date.slice(6, 8)}`;
  }

  let dateData = new Date(date.split("T")[0]);
  /* return `${dateData.getDate() + 1}/${
    dateData.getMonth() + 1
  }/${dateData.getFullYear()} `; */
  return `${dateData.getFullYear()} /${dateData.getMonth() + 1}/${
    dateData.getDate() + 1
  }`;
}
