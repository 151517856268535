import React, { useEffect, useState, useContext, Fragment } from "react";
import {
  BrowserRouter as Router,
  Link,
  useHistory,
  useLocation,
} from "react-router-dom";
import {
  IconButton,
  ListItemButton,
  Box,
  Button,
  Collapse,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link as LinkMUI,
} from "@mui/material";
import { ListItemSecondaryAction } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DescriptionIcon from "@material-ui/icons/Description";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import ReceiptIcon from "@material-ui/icons/Receipt";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import useCheckMobileScreen from "components/Mobile/Mobile.js";
import { userService } from "_services/user.service";
import { AuthContext } from "auth/context/AuthContext";
import { useAuth } from "hooks/useAuth";

const LayoutProfile = ({ children, setCarritoItems }) => {
  const { logout } = useContext(AuthContext);
  const { getUserData } = useAuth();
  const dataUser = getUserData();
  const isMobile = useCheckMobileScreen();
  const location = useLocation();
  const history = useHistory();
  const [user, setUser] = useState({});
  const [openMyAccount, setOpenMyAccount] = useState(false);
  const [saldo, setSaldo] = useState(null);
  const [hiddenMenu, setHiddenMenu] = useState(false);
  const [hiddenContent, setHiddenContent] = useState(false);

  const getSaldoAndCredito = async () => {
    const clientData = JSON.parse(localStorage.getItem("client"));
    const clientId = clientData.listaSnbyid[0].sn_socios_negocio_id;
    const clientDataById = await userService.getClientById(clientId);
    const saldoActual = clientDataById.data.listaSnbyid[0].sn_credito;
    // const creditoDisponible =
    //   clientDataById.data.listaSnbyid[0].sn_credito_disponible;
    setSaldo(saldoActual);
    // setCredito(creditoDisponible);
    // setIsloading(true);
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) {
      // Redireccionar a login
      history.push("/login");
    } else {
      setUser(JSON.parse(localStorage.getItem("user")).usuario);
      //window.alert(JSON.stringify(dataUser.usuario.TypeUser))
    }

    if (localStorage.getItem("client")) {
      getSaldoAndCredito();
    }
  }, []);

  const handleExit = () => {
    setCarritoItems(0);
    logout();
    localStorage.removeItem("client");
    history.push("/login");
  };

  const handlerHideNavMobile = () => {
    if (isMobile) {
      setHiddenMenu(true);
      setHiddenContent(false);
    }
  };

  const handlerShowNavMobile = () => {
    if (isMobile) {
      setHiddenMenu(false);
      setHiddenContent(true);
    }
  };

  useEffect(() => {
    if (!isMobile) {
      setHiddenMenu(true);
      setHiddenContent(true);
    } else {
      setHiddenMenu(false);
      setHiddenContent(true);
    }
  }, [isMobile]);

  return (
    <div>
      <Container maxWidth="lg" sx={{ pb: 8, pt: isMobile ? "6px" : "15px" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {hiddenContent && isMobile && (
              <Grid item>
                <LinkMUI
                  underline="hover"
                  sx={{ display: "flex" }}
                  onClick={handlerHideNavMobile}
                >
                  <ChevronLeftIcon />
                  {"Regresar"}
                </LinkMUI>
              </Grid>
            )}
            {hiddenMenu && (
              <Grid
                item
                xs={isMobile ? "12" : "3"}
                style={{ paddingTop: "15px" }}
              >
                <List sx={{ paddingTop: "0px" }}>
                  {user.snu_menu_roles &&
                    user.snu_menu_roles.map((modulo, i) => {
                      let result_permiso_view = modulo.permisos.find(
                        (item) => item.key === "view"
                      );
                     if(dataUser.usuario.TypeUser==2 && modulo.key_id==7 && modulo.key_id){
                     }else if(dataUser.usuario.TypeUser==2 && modulo.key_id==6){
                     }else{
                      return (
                        result_permiso_view.permiso &&
                        (modulo.key_id != 3 || saldo != 0 || dataUser.usuario.TypeUser!=2) && (
                          <>
                            <ListItem
                              key={i}
                              component={Link}
                              to={`/myprofile/${modulo.key}`}
                              style={{
                                backgroundColor:
                                  location.pathname ===
                                    `/myprofile/${modulo.key}` ||
                                  location.pathname.includes(
                                    `/myprofile/${modulo.key}`
                                  )
                                    ? "#40DF00"
                                    : "#F5F5F5",
                                padding: 0,
                                borderRadius: "10px",
                                marginTop: "2px",
                                marginBottom: "2px",
                              }}
                              onClick={handlerShowNavMobile}
                            >
                              <ListItemButton
                                sx={{
                                  borderRadius: "10px",
                                  color:
                                    location.pathname ===
                                      `/myprofile/${modulo.key}` ||
                                    location.pathname.includes(
                                      `/myprofile/${modulo.key}`
                                    )
                                      ? "#fff !important"
                                      : "#0b3196 !important",
                                }}
                              >
                                <ListItemIcon
                                  sx={{
                                    color:
                                      location.pathname ===
                                        `/myprofile/${modulo.key}` ||
                                      location.pathname.includes(
                                        `/myprofile/${modulo.key}`
                                      )
                                        ? "#fff !important"
                                        : "#0b3196 !important",
                                  }}
                                >
                                  {modulo.key_id === 0 && <AccountCircleIcon />}
                                  {modulo.key_id === 3 && <DescriptionIcon />}
                                  {modulo.key_id === 4 && (
                                    <ShoppingBasketIcon />
                                  )}
                                  {modulo.key_id === 5 && <ReceiptIcon />}
                                  {modulo.key_id === 6 && <BookmarkIcon />}
                                  {modulo.key_id === 7 && (
                                    <BusinessCenterIcon />
                                  )}
                                </ListItemIcon>
                                <ListItemText
                                  style={{
                                    color:
                                      location.pathname ===
                                        `/myprofile/${modulo.key}` ||
                                      location.pathname.includes(
                                        `/myprofile/${modulo.key}`
                                      )
                                        ? "#fff"
                                        : "#0b3196",
                                  }}
                                  primary={modulo.menu}
                                />
                                {modulo.submenu && (
                                  <ListItemSecondaryAction className="expandListOptions">
                                    <IconButton
                                      edge="end"
                                      aria-label="more"
                                      onClick={() => {
                                        setOpenMyAccount(!openMyAccount);
                                      }}
                                      sx={{
                                        color:
                                          location.pathname ===
                                          `/myprofile/${modulo.key}`
                                            ? "#fff !important"
                                            : "#0b3196 !important",
                                      }}
                                    >
                                      {openMyAccount ? (
                                        <ExpandLess />
                                      ) : (
                                        <ExpandMore />
                                      )}
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                )}
                              </ListItemButton>
                            </ListItem>
                            <Collapse
                              in={isMobile ? true : openMyAccount}
                              timeout="auto"
                              unmountOnExit
                            >
                              {modulo.submenu && (
                                <List>
                                  {modulo.submenu.map((submenu, e) => (
                                    <>
                                      {(dataUser.usuario.TypeUser ==2 && submenu.key =='usuarios')? 
                                        "":
                                        <ListItem
                                          key={e}
                                          component={Link}
                                          to={`/myprofile/perfil/${submenu.key}`}
                                          style={{
                                            backgroundColor:
                                              location.pathname ===
                                              `/myprofile/perfil/${submenu.key}`
                                                ? "#86f759"
                                                : "#F5F5F5",
                                            color:
                                              location.pathname ===
                                              `/myprofile/perfil/${submenu.key}`
                                                ? "#fff !important"
                                                : "#0b3196 !important",
                                            padding: 0,
                                            borderRadius: "10px",
                                            marginTop: "2px",
                                            marginBottom: "2px",
                                          }}
                                          onClick={handlerShowNavMobile}
                                        >
                                          <ListItemButton>
                                            <ListItemIcon></ListItemIcon>
                                            <ListItemText
                                              style={{
                                                paddingLeft: 10,
                                                color:
                                                  location.pathname ===
                                                  `/myprofile/perfil/${submenu.key}`
                                                    ? "#fff"
                                                    : "#0b3196",
                                              }}
                                              primary={submenu.menu}
                                            />
                                          </ListItemButton>
                                        </ListItem>
                                      
                                            }

                                    </>
                                  ))}
                                </List>
                              )}
                            </Collapse>
                          </>
                        )
                      );}
                    })}
                </List>
                <div style={{ marginTop: 25 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    // className={classes.buttonRegister}
                    onClick={() => handleExit()}
                    startIcon={<ExitToAppIcon />}
                  >
                    Salir de mi cuenta
                  </Button>
                </div>
              </Grid>
            )}
            {hiddenContent && (
              <Grid item xs={isMobile ? "12" : "9"}>
                {children}
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default LayoutProfile;
