import React, { useState, useEffect, useContext, Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((styles) => ({
    container: {
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: 0,
        marginTop: "5px",
    },
    brandName: {
        color: "#0B3196",
        fontFamily: "'Centrale Sans Medium'",
        fontSize: "12px",
        fontWeight: "500",
        letterSpacing: "0",
        lineHeight: "14px",
    }
}));


export default function SectionProductBrand(props) {
    const classes = useStyles();
    return (
        <div >
            <div className={classes.container}>
                <Typography className={classes.brandName}>{props.productDetails.brand}</Typography>
            </div>
        </div>
    );
}
