
import React,{useState} from 'react';
import HubspotForm from 'react-hubspot-form'
import InfoIcon from '@mui/icons-material/Info';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import "./Help.css"

export const Help =(props) =>{
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    return (
        <div>
          { props.ayuda !=null?
             <Button
             disableElevation
             variant="contained"
             size="small"
             sx={{
                 borderRadius: 0,
                 padding: '5px 40px',
                 backgroundColor: '#0b3196',
             }}
             startIcon={<InfoIcon sx={{ color: '#40df00' }} />}
             onClick={handleClickOpen}
         >
             Ayuda
         </Button >:
          <div
            style={{
              display: "block",
              width: "100%",
              paddingRight: 15,
              paddingTop: '8px',
            }}
          >
            <p 
              className={" labelmouse"}
              style={{
                width: "100%",
                textAlign: "left",
                color: "#ffffff",
                fontWeight: "400",
                fontFamily: "'Centrale Sans Light'",
                fontSize: 14,
                whiteSpace: "break-Spaces",
                lineHeight: "15px",
                fontWeight: "300",
                fontSize: "14px",
                lineHeight: "17px",
              }}
              onClick={handleClickOpen}
            >
            Reportar un problema
            </p>
          </div>
          }
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle xs id="alert-dialog-title">
              {"Reportar problemas técnicos del portal"}
            </DialogTitle>
            <DialogContent>
              <br/>
              <br/>
            <HubspotForm
                  region="na1"
                  portalId='5030999'
                  formId='01947675-99b6-4547-9334-c7b0659003fb'
                  onSubmit={() => console.log('Submit!')}
                  onReady={(form) => console.log('Form ready!')}
                  loading={<div>Loading...</div>} />
            </DialogContent>
            
          </Dialog>
      </div>
    
          
          
    

    );
}