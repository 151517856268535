import React from 'react'

import Collapse from '@material-ui/core/Collapse';
import Snackbar from '@material-ui/core/Snackbar';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';

/**Estilos */
import { makeStyles } from "@material-ui/core/styles";


export const AlertForm = (props)=>{
    const classes = useStyles();
    const handleClose = () => {
        props.setOpenAlert({
            message: "",
            type: "",
            open: false, 
          })
      };
   
    return (
    
        <Box sx={{ width: '100%'}}>
        <Collapse in={props.openAlert.open}>
          {props.openAlert.open && 
          <Snackbar open={props.openAlert.open} anchorOrigin={{vertical: 'top', horizontal: 'right'   }} 
          sx={{ position: "absolute" }}
          autoHideDuration={3000} onClose={handleClose}>
          <Alert
             className={classes.alerts}
             severity={props.openAlert.type}
            sx={{ mb: 2 }}
          >
            {props.openAlert.message}
          </Alert></Snackbar>
          }
      </Collapse>
        
      
      </Box>
    
    )
}


const useStyles = makeStyles(() => ({ 
alerts: {
    color: "#FFFFFF",
    backgroundColor: "#40DF00",
    fontFamily: "'Centrale Sans Light'",
    fontSize: 22,
    "& .MuiAlert-icon": {
      color: "#FFFFFF",
    },
    "&.MuiAlert-standardError": {
      backgroundColor: "#DF2B2B",
    },
  }

}))