import React, { useState, useEffect, useRef, Fragment } from "react";
import clsx from "clsx";
import { userService } from "../../_services/user.service";
import { generalService } from "../../_services/general.service";
import { checkoutService } from "../../_services/checkout.service";
import CheckoutProductsList from "./CheckoutProductsList";
import CheckoutSummary from "./CheckoutSummary";
import { getPriceSapFormat } from "helpers/formatPrice";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Typography } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import HouseIcon from "@material-ui/icons/House";
import { useAuth } from "hooks/useAuth";
const useStyles = makeStyles((theme) => ({
  blockOne: {
    paddingRight: 20,
  },
  content: {
    margin: "15px",
  },
  contentButton: {
    margin: "0 15px 15px 15px",
  },
  selectStyle: {
    marginTop: "20px",
  },
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  grayDiv: {
    marginBottom: 30,
  },
  radioColor: {
    "&.Mui-checked": {
      color: "#0B3196",
    },
  },
  paper: {
    backgroundColor: "#F8F8F8",
    boxShadow: "none",
    borderRadius: 0,
    padding: "15px",
    fontSize: "15px",
    "& h6": {
      fontFamily: "'Centrale Sans Medium'",
      color: "#000000",
      fontSize: "17px",
    },
    textAlign: "center",
  },
  paperTitle: {
    borderRadius: 2,
    backgroundColor: "#F3F3F3",
    padding: 10,
    "& p": {
      color: "#000000",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: 18,
      fontWeight: 500,
      textAlign: "center",
    },
  },
  personalData: {
    backgroundColor: "#0B3196",
    color: "#ffffff",
    "& h6": {
      fontFamily: "'Centrale Sans Medium'",
      color: "#ffffff",
    },
    boxShadow: "none",
    borderRadius: 0,
    padding: "15px",
    fontSize: "15px",
    textAlign: "center",
  },
  radioTipoEntrega: {
    flexDirection: "row",
    flexWrap: "nowrap",
    "& label.MuiFormControlLabel-root .MuiTypography-body1": {
      color: "#666060",
    },
    "& label.MuiFormControlLabel-root .MuiRadio-colorSecondary.Mui-checked + span.MuiTypography-body1": {
      color: "#0B3196",
      fontWeight: 700,
    },
  },
  listItemIcon: {
    color: "#666060",
  },
  selectedAddress: {
    "& p": {
      fontSize: 18,
      fontFamily: "'Centrale Sans Regular'",
      color: "#000000",
      textAlign: "left",
    },
  },
  //alerts
  alerts: {
    color: "#FFFFFF",
    backgroundColor: "#40DF00",
    fontFamily: "'Centrale Sans Light'",
    fontSize: 22,
    "& .MuiAlert-icon": {
      color: "#FFFFFF",
    },
    "&.MuiAlert-standardError": {
      backgroundColor: "#DF2B2B",
    },
  },
  selects: {
    background: "#ffffff",
    color: "#0B3196",
    fontWeight: 700,
    border: "2px solid #0B3196",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },
}));

export default function CheckoutShipping2(props) {
  const classes = useStyles();
  const { setOpenAlertF, setIsShippingHome} = useAuth();
  const [optionsCountries, setOptionsCountries] = useState([])
  const [optionsStates, setOptionsStates] = useState([])
  const [optionsCities, setOptionsCities] =useState([])
  //declare form inputs
  let alias = useRef(null);
  let street = useRef(null);
  let street1 = useRef(null);
  let street2 = useRef(null);
  let ext = useRef(null);
  let int = useRef(null);
  let colony = useRef(null);

  let chcity = useRef(null);
  let chstate = useRef(null);
  let chcountry = useRef(null);

  let zipcode = useRef(null);
  let contact = useRef(null);
  let phoneNumber = useRef(null);

  const [clientAddresses, setClientAddresses] = useState([]);
  const [warehouses, setWarehouses] = useState([]);

  const [selectedShippingMethod, setSelectedShippingMethod] = useState(
    props.cartCheckoutData.order_shipping_method
  );
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(
    props.cartCheckoutData.order_shipping_address
  );
  const [
    selectedShippingAddressInfo,
    setSelectedShippingAddressInfo,
  ] = useState(props.cartCheckoutData.order_shipping_address_info);
  const [selectedWarehouse, setSelectedWarehouse] = useState(
    props.cartCheckoutData.order_warehouse
  );
  const [selectedWarehouseInfo, setSelectedWarehouseInfo] = useState(
    props.cartCheckoutData.order_warehouse_info
  );
  const [selectedShippingType, setSelectedShippingType] = useState(
    props.cartCheckoutData.fletera
  );

  const [openModal, setOpenModal] = useState(false);
  const [actionAddress, setActionAddress] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [fleteras, setFleteras] = useState([]);
  const [completar, setCompletar] = useState(false);
  const [changeSelect, setChangeSelect]= useState(false);
  const [country, setCountry] = useState({
    id_country: 0,
  });
  const [state, setState] = useState({
    id_state: 0,
    state: "",
  });
  const [city, setCity] = useState({
    id_city: 0,
    city: "",
  });

  const getWarehouses = async () => {
    const warehouses_response = await checkoutService.getWarehousesPickup();
    setWarehouses(warehouses_response.listaAlmacenes);
  };

  const getClientAddresses = async () => {
    const client_addresses_response = await userService.getAddressByClientCardCode(
      props.cartCheckoutData.client_cardcode
    );
    setClientAddresses(client_addresses_response.data.listaSnubycardcode);
  };

  const getFleteras = async () => {
    const response = await checkoutService.getFleteras();
    setFleteras(
      response.listaFleteras.filter(
        (value) => value.flet_nombre === "Paquetexpress"
      )
    );
  };

  useEffect(() => {
    getWarehouses();
    getClientAddresses();
    getFleteras();
    getCountries();
    /* handleChangeDeliveryType({
      target: {
        value: 0,
      },
    }); */
  }, []);

  // select shipping method: envio o recoleccion
  const handleChangeDeliveryType = (event) => {
    setSelectedShippingMethod(event.target.value);

    props.handleUpdateCartCheckoutData(
      "order_shipping_method",
      event.target.value
    );

    // si es recoleccion el costo de envio tiene que ser en ceros al igual que el dato de fletera
    if (event.target.value == 17) {
      if (selectedWarehouse !== 0 && selectedWarehouse !== null) {
        updateCartShippingCost(17, null, selectedWarehouse, null);
      }
    } else {
      if (selectedShippingAddress !== 0 && selectedShippingAddress !== null) {
        updateCartShippingCost(
          16,
          selectedShippingAddress,
          null,
          fleteras[0].flet_fletera_id
        );
      }
    }
  };

  const handleChangeSelectedWarehouse = (event) => {
    setSelectedWarehouse(event.target.value);

    // updateCartShippingCost(17, null, event.target.value, null);

    if (event.target.value !== 0) {
      updateCartShippingCost(17, null, event.target.value, null);

      setSelectedWarehouseInfo(
        warehouses.filter((wh) => wh.alm_almacen_id === event.target.value)[0]
      );
      props.handleUpdateCartCheckoutData(
        "order_warehouse_info",
        warehouses.filter((wh) => wh.alm_almacen_id === event.target.value)[0]
      );
    } else {
      setSelectedWarehouseInfo({});
      props.handleUpdateCartCheckoutData("order_warehouse_info", {});
    }
  };

  const validateAddress = (clientTmp) => {
    if (
      !clientTmp.snd_alias ||
      !clientTmp.snd_direccion ||
      !clientTmp.snd_direccion_num_ext ||
      !clientTmp.snd_contacto ||
      !clientTmp.snd_telefono ||
      !clientTmp.snd_colonia ||
      !clientTmp.snd_codigo_postal ||
      !clientTmp.snd_pais_id ||
      !clientTmp.snd_estado_id ||
      !clientTmp.snd_estado_id ||
      !clientTmp.estado.estpa_estado_nombre
    ) {
      //setOpenModal(true);
      //setActionAddress("editar");
      setIsShippingHome(true)
      setOpenAlertF({open:true, message:'La dirección seleccionada esta incompleta, por lo cual no podemos calcular el costo de envio.', type:'warning'})
    }else{
      setIsShippingHome(false)
    }
  };

  const handleChangeAddress = (event) => {
    setSelectedShippingAddress(event.target.value);
    if (event.target.value !== 0) {
      const clientAddressConst = clientAddresses.filter(
        (ad) => ad.snd_direcciones_id === event.target.value
      )[0];
      setSelectedShippingAddressInfo(clientAddressConst);

      validateAddress(clientAddressConst);

      props.handleUpdateCartCheckoutData(
        "order_shipping_address_info",
        clientAddresses.filter(
          (ad) => ad.snd_direcciones_id === event.target.value
        )[0]
      );
    } else {
     
      setSelectedShippingAddressInfo({});
      props.handleUpdateCartCheckoutData("order_shipping_address_info", {});
    }

  
    updateCartShippingCost(
      16,
      event.target.value,
      null,
      fleteras[0].flet_fletera_id
    );
    
  };

  const updateCartShippingCost = async (
    shipping_method,
    address_id,
    warehouse_id,
    fletera_id
  ) => {
    const response = await checkoutService.updateCartShipping(
      props.cartCheckoutData.client_id,
      shipping_method,
      address_id,
      warehouse_id,
      fletera_id
    );

    if (address_id === null) {
      address_id = 0;
    }
    if (warehouse_id === null) {
      warehouse_id = 0;
    }
    if (fletera_id === null) {
      fletera_id = 0;
    }

    props.handleUpdateCartCheckoutData("order_shipping_address", address_id);
    props.handleUpdateCartCheckoutData("order_warehouse", warehouse_id);
    props.handleUpdateCartCheckoutData("fletera", fletera_id);

    if (response.error) {
      if (response.data.e !== "") {
       
        setOpenAlertF({
          message: `No fue posible obtener el costo de envío para la dirección seleccionada. ${response.data.message}`,
          type: "error",
          open: true,
        });
        setIsShippingHome(true)
      } else {
        setOpenAlertF({
          message:
            "No fue posible obtener el costo de envío para la dirección seleccionada. Compruebe que su dirección tenga todos sus datos correctos antes de continuar.",
          type: "error",
          open: true,
        });
        setIsShippingHome(true)
      }
      if (shipping_method == 16) {
        // if error and shipping method is "ENVIO A DOMICILIO" then fleteras in zero or null for in the validation function handleNext in CheckoutSteps.js stop to advance
        props.handleUpdateCartCheckoutData("fletera", 0);
        setIsShippingHome(true)
      
      }
    } else {
      
      setIsShippingHome(false)
      setOpenAlertF({ message: "", type: "", open: false });
      props.getCartByClientId();
    }
  };

  const handleClickOpenModal = async (action) => {
    setActionAddress(action);
    setOpenModal(true);
    setChangeSelect(true)
    // si la accion es editar debo prellenar los campos del formulario de editar direccion del dialog
    if (action == "editar") {
      
      alias.current = selectedShippingAddressInfo.snd_alias;
      street.current = selectedShippingAddressInfo.snd_direccion;
      street1.current = selectedShippingAddressInfo.snd_calle1;
      street2.current = selectedShippingAddressInfo.snd_calle2;
      ext.current = selectedShippingAddressInfo.snd_direccion_num_ext;
      int.current = selectedShippingAddressInfo.snd_direccion_num_int;
      colony.current = selectedShippingAddressInfo.snd_colonia;
      zipcode.current = selectedShippingAddressInfo.snd_codigo_postal;
      chcountry.current = selectedShippingAddressInfo.snd_pais_id;
      chstate.current = selectedShippingAddressInfo.snd_estado_id;
      contact.current = selectedShippingAddressInfo.snd_contacto;
      phoneNumber.current = selectedShippingAddressInfo.snd_telefono;
     
      await getStates(52)
      setState({
        id_state: selectedShippingAddressInfo.snd_estado_id,
        state: selectedShippingAddressInfo.estado.estpa_estado_nombre,
      }); 
    
     
      setCountry({
        id_country: 52,
      });
     // window.alert(selectedShippingAddressInfo.snd_estado_id)
     
      await findCity(
        selectedShippingAddressInfo.snd_estado_id,
        selectedShippingAddressInfo.snd_ciudad
      );
      

    }else{
     setState({
        id_state: 0,
        state: "",
      });
      setCity ({
        id_city: 0,
        city: "",
      });
     setOptionsStates([])
     setOptionsCities([])

    }
  };


 

  const findCity = async (id_state, city_name) => {
    const cities_response = await generalService.getCities(id_state);

    // window.alert(JSON.stringify(cities_response))
    let city_id = 0;
    for (
      let index = 0;
      index < cities_response.data.constCiudadesEstados.rows.length;
      index++
    ) {
      const city = cities_response.data.constCiudadesEstados.rows[index];
      if (city.city_ciudad === city_name) {
        city_id = city.city_ciudades_estados_id;
        break;
      }
    }
    setCity({
      id_city: city_id,
      city: city_name,
    });
     //await getCities(id_state)

    //window.alert(city_id)
    //window.alert(city_name)
    chcity.current = city_id

    await getCities(id_state)
    //.toString();
    
  };




  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const getCountries = async () => {
    const countries_response = await generalService.getCountries();
    setCountries(countries_response.paises);
    setOptionsCountries(countries_response.paises)
  };
  const getStates = async (CountryId)=>{
    const states_response = await generalService.getStates(CountryId);
    setStates(states_response.estados);
    setOptionsStates(states_response.estados)
  }

  const getCities = async (StateId)=>{
    const cities_response = await generalService.getCities(StateId);
    setCities(cities_response.data.constCiudadesEstados.rows);
    setOptionsCities(cities_response.data.constCiudadesEstados.rows)
  }




  //getCountries();

  const handleChangeCountry = async (event) => {
    setCountry({
      id_country: event.target.value,
    });
    const states_response = await generalService.getStates(event.target.value);
    setStates(states_response.estados);
    setOptionsStates(states_response.estados)
    setCities([])
    setOptionsCities([])
  };

  const handleChangeState = async (event) => {
    let state_name = "";
    for (let index = 0; index < event.target.length; index++) {
      const state = event.target[index];
      if (state.value === event.target.value) {
        state_name = state.id;
        break;
      }
    }
    setState({
      id_state: event.target.value,
      state: state_name,
    });

    const cities_response = await generalService.getCities(event.target.value);
    setCities(cities_response.data.constCiudadesEstados.rows);
    setOptionsCities(cities_response.data.constCiudadesEstados.rows)
  };



  const handleChangeCity = (event) => {
    let city_name = "";
    for (let index = 0; index < event.target.length; index++) {
      const city = event.target[index];
      if (city.value === event.target.value) {
        city_name = city.id;
        break;
      }
    }
    setCity({
      id_city: event.target.value,
      city: city_name,
    });
  };

  const handleSubmitAddAddress = async (event) => {
    event.preventDefault();

    if (actionAddress === "crear") {
      const create_address_client_response = await userService.createAddressClient(
        event.target.alias.value,
        event.target.zipcode.value,
        event.target.street.value,
        event.target.street1.value,
        event.target.street2.value,
        event.target.ext.value,
        event.target.int.value,
        event.target.colony.value,
        country.id_country,
        state.id_state,
        city.city,
        event.target.contact.value,
        event.target.phoneNumber.value,
        props.cartCheckoutData.client_cardcode
      );

      if (create_address_client_response.error) {
        setOpenAlertF({
          message: "Hubo un error al intentar crear la dirección al cliente.",
          type: "error",
          open: true,
        });
      } else {
        setOpenAlertF({
          message: "Dirección creada con éxito.",
          type: "success",
          open: true,
        });
        handleCloseModal();
        getClientAddresses();
      }
    } else if (actionAddress === "editar") {
      const edit_address_client_response = await userService.updateAddressClient(
        selectedShippingAddressInfo,
        alias.current.value,
        zipcode.current.value,
        street.current.value,
        street1.current.value,
        street2.current.value,
        ext.current.value,
        int.current.value,
        colony.current.value,

        chcountry.current.value,
        chstate.current.value,
        //chcity.current.value,
        city.city,

        event.target.contact.value,
        event.target.phoneNumber.value,
        props.cartCheckoutData.client_cardcode,
        props.user.usuario.usu_usuario_id
      );

      if (edit_address_client_response.error) {
        setOpenAlertF({
          message:
            "Hubo un error al intentar actualizar la dirección del cliente.",
          type: "error",
          open: true,
        });
      } else {
        setOpenAlertF({
          message: "Dirección actualizada con éxito.",
          type: "success",
          open: true,
        });
        handleCloseModal();
        getClientAddresses();
        setSelectedShippingAddress(0);
        setSelectedShippingAddressInfo({});
      }
    }
  };

 
 
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} md={6} className={classes.blockOne}>
          <Grid container>
            {props.isMobile && (
              <Grid item xs={12}>
                <Paper className={clsx(classes.personalData)}>
                  <Typography variant="h6">Datos personales</Typography>
                  <Typography>
                    {props.cartCheckoutData.client.sn_razon_social}
                  </Typography>
                  <Typography>
                    {props.cartCheckoutData.client.sn_email_facturacion}
                  </Typography>
                  <Typography>
                    {props.cartCheckoutData.client.sn_telefono_empresa}
                  </Typography>
                </Paper>
              </Grid>
            )}
            <Grid item xs={12} className={classes.grayDiv}>
              <Paper className={clsx(classes.paperTitle)}>
                <Typography>Elige un tipo de entrega </Typography>
              </Paper>
              <Paper className={clsx(classes.paper)}>
                <FormControl component="fieldset">
                  <RadioGroup
                    className={classes.radioTipoEntrega}
                    aria-label="shippingmethods"
                    name="shippingmethods"
                    defaultValue={props.cartCheckoutData.order_shipping_method}
                    onChange={handleChangeDeliveryType}
                  >
                    <Grid container>
                      <Grid item sm={6} xs={12}>
                        <ListItem
                          key={"sm" + "16"}
                          role={undefined}
                          dense
                          button
                          className="radioList"
                          onClick={() => {
                            document
                              .getElementById("shipping16")
                              .classList.add("activeRadio");

                            document
                              .getElementById("pickup17")
                              .classList.remove("activeRadio");
                          }}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <LocalShippingIcon id={`shipping16`} />
                          </ListItemIcon>
                          <FormControlLabel
                            value="16"
                            control={<Radio className={classes.radioColor} />}
                            label="Envío a domicilio"
                          />
                        </ListItem>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <ListItem
                          key={"sm" + "17"}
                          role={undefined}
                          dense
                          button
                          className="radioList"
                          onClick={() => {
                            document
                              .getElementById("shipping16")
                              .classList.remove("activeRadio");
                            document
                              .getElementById("pickup17")
                              .classList.add("activeRadio");
                          }}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <HouseIcon id={`pickup17`} />
                          </ListItemIcon>
                          <FormControlLabel
                            value="17"
                            control={<Radio className={classes.radioColor} />}
                            label="Recolección"
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Paper>
            </Grid>
            <Grid item xs={12} className={classes.grayDiv}>
              {selectedShippingMethod == 16 && (
                <Fragment>
                  <Paper className={clsx(classes.paperTitle)}>
                    <Typography>Dirección de entrega</Typography>
                  </Paper>
                  <Paper className={clsx(classes.paper)}>
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        {selectedShippingAddressInfo.snd_direcciones_id && (
                          <div className={classes.selectedAddress}>
                            <Typography>
                              {` ${
                                selectedShippingAddressInfo.snd_alias !== null
                                  ? selectedShippingAddressInfo.snd_alias
                                  : ""
                              } 
                              ${selectedShippingAddressInfo.snd_direccion} 
                              ${
                                selectedShippingAddressInfo.snd_direccion_num_ext !==
                                  null &&
                                selectedShippingAddressInfo.snd_direccion_num_ext !==
                                  ""
                                  ? ` Ext.${selectedShippingAddressInfo.snd_direccion_num_ext}`
                                  : ""
                              }
                              ${
                                selectedShippingAddressInfo.snd_direccion_num_int !==
                                  null &&
                                selectedShippingAddressInfo.snd_direccion_num_int !==
                                  ""
                                  ? ` Int.${selectedShippingAddressInfo.snd_direccion_num_int}`
                                  : ""
                              }
                            `}
                            </Typography>
                            <Typography>
                              {`${selectedShippingAddressInfo.snd_colonia}, ${selectedShippingAddressInfo.snd_ciudad}`}
                            </Typography>
                            <Typography>
                              {` ${
                                selectedShippingAddressInfo.snd_estado_id
                                  ? selectedShippingAddressInfo.estado
                                      .estpa_estado_nombre
                                  : ""
                              } ${
                                selectedShippingAddressInfo.pai.pais_nombre
                              } `}
                            </Typography>
                            <Typography>
                              {`CP ${selectedShippingAddressInfo.snd_codigo_postal}`}
                            </Typography>

                            <Tooltip title="Editar">
                              <IconButton
                                aria-label="edit"
                                onClick={() => handleClickOpenModal("editar")}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          className={classes.formControl}
                          variant="outlined"
                        >
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedShippingAddress}
                            onChange={handleChangeAddress}
                            className={classes.selects}
                          >
                            <MenuItem value={0}>Elegir dirección</MenuItem>
                            {clientAddresses.map((value) => {
                              return (
                                <MenuItem value={value.snd_direcciones_id}>
                                  {`${value.snd_direccion} #${value.snd_direccion_num_ext}, ${value.snd_ciudad}`}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid style={{ margin: "auto" }}>
                        <Fragment>
                          <Button
                            variant="contained"
                            color="primary"
                            className="create-collection"
                            onClick={() => handleClickOpenModal("crear")}
                          >
                            Nueva Dirección <AddIcon />
                          </Button>
                        </Fragment>
                      </Grid>
                    </Grid>
                  </Paper>
                </Fragment>
              )}

              {selectedShippingMethod == 17 && (
                <Fragment>
                  <Paper className={clsx(classes.paperTitle)}>
                    <Typography>Dirección de recolección</Typography>
                  </Paper>
                  <Paper className={clsx(classes.paper)}>
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        {selectedWarehouseInfo.alm_almacen_id ? (
                          <div className={classes.selectedAddress}>
                            <Typography>
                              {` ${selectedWarehouseInfo.alm_nombre} - ${selectedWarehouseInfo.alm_direccion} CP.${selectedWarehouseInfo.alm_codigo_postal} `}
                            </Typography>
                          </div>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          className={classes.formControl}
                          variant="outlined"
                        >
                          <Select
                            className={classes.selects}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedWarehouse}
                            onChange={handleChangeSelectedWarehouse}
                          >
                            <MenuItem value={0}>Elegir tienda</MenuItem>
                            {warehouses.map((value) => {
                              return (
                                <MenuItem value={value.alm_almacen_id}>
                                  {`${value.alm_nombre}`}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Paper>
                </Fragment>
              )}
            </Grid>
            <Grid item xs={12} className={classes.grayDiv}>
              {selectedShippingMethod == 16 &&
              selectedShippingAddress !== 0 &&
              selectedShippingAddress !== null ? (
                <Fragment>
                  <Paper className={clsx(classes.paperTitle)}>
                    <Typography>Tipo de envío</Typography>
                  </Paper>
                  <Paper
                    className={clsx(classes.paper)}
                    style={{ textAlign: "left" }}
                  >
                    <FormControl component="fieldset">
                      {fleteras.map((item) => {
                        return (
                          <ListItem
                            key={"sm" + item.flet_fletera_id}
                            role={undefined}
                            dense
                          >
                            <Typography>
                              {item.flet_nombre === "Paquetexpress"
                                ? "Paquetería"
                                : item.flet_nombre}{" "}
                              <br />{" "}
                              <span style={{ fontWeight: 700 }}>
                                {" "}
                                {getPriceSapFormat(
                                  props.cartCheckoutData.cart_shipping
                                )}
                              </span>
                            </Typography>
                          </ListItem>
                        );
                      })}
                    </FormControl>
                 
                  </Paper>
                </Fragment>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={classes.blockTwo}>
          <Fragment>
            <Grid container>
              {/* {!props.isMobile && (
              )} */}
              <Fragment>
                <Grid item xs={12}>
                  <Paper
                    className={clsx(classes.personalData)}
                    style={{ marginBottom: 30 }}
                  >
                    <Typography variant="h6">Datos personales</Typography>
                    <Typography>
                      {props.cartCheckoutData.client.sn_razon_social}
                    </Typography>
                    <Typography>
                      {props.cartCheckoutData.client.sn_email_facturacion}
                    </Typography>
                    <Typography>
                      {props.cartCheckoutData.client.sn_telefono_empresa}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <Paper className={clsx(classes.paper, classes.personalData)}>
                    <Typography variant="h6">Resumen de tu carrito</Typography>
                  </Paper>
                  <CheckoutProductsList
                    cartCheckoutData={props.cartCheckoutData}
                  />
                </Grid>
              </Fragment>
              <CheckoutSummary
                cartCheckoutData={props.cartCheckoutData}
                stepNumber={props.stepNumber}
              />
            </Grid>
          </Fragment>
        </Grid>
      </Grid>

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={(event) => handleSubmitAddAddress(event)}>
          <DialogTitle id="form-dialog-title">
            {actionAddress === "crear"
              ? "Agregar Nueva Dirección"
              : "Editar Dirección"}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              inputRef={alias}
              defaultValue={actionAddress === "editar" ? alias.current : ""}
              margin="dense"
              id="alias"
              name="alias"
              label="Alias/Nombre"
              fullWidth
              required
            />
            <TextField
              inputRef={street}
              defaultValue={actionAddress === "editar" ? street.current : ""}
              margin="dense"
              id="street"
              name="street"
              label="Calle Principal"
              fullWidth
              required
            />
            <TextField
              inputRef={street1}
              defaultValue={actionAddress === "editar" ? street1.current : ""}
              margin="dense"
              id="street1"
              name="street1"
              label="Entrecalle 1"
              fullWidth
            />
            <TextField
              inputRef={street2}
              defaultValue={actionAddress === "editar" ? street2.current : ""}
              margin="dense"
              id="street2"
              name="street2"
              label="Entrecalle 2"
              fullWidth
            />
            <TextField
              inputRef={ext}
              defaultValue={actionAddress === "editar" ? ext.current : ""}
              type="text"
              margin="dense"
              id="ext"
              name="ext"
              label="Ext."
              required
            />
            <TextField
              inputRef={int}
              defaultValue={actionAddress === "editar" ? int.current : ""}
              type="text"
              margin="dense"
              id="int"
              name="int"
              label="Int."
            />
            <Grid item xs={12} md={4}>
              <TextField
                inputRef={contact}
                defaultValue={actionAddress === "editar" ? contact.current : ""}
                margin="dense"
                id="contact"
                name="contact"
                label="Contacto que recibe"
                required
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                inputRef={phoneNumber}
                defaultValue={
                  actionAddress === "editar" ? phoneNumber.current : ""
                }
                inputProps={{ pattern: "[0-9]{10,10}" }}
                margin="dense"
                id="phoneNumber"
                name="phoneNumber"
                label="Teléfono"
                required
              />
            </Grid>

            <Grid container>
              <Grid item xs={12} md={4}>
                <TextField
                  inputRef={colony}
                  defaultValue={
                    actionAddress === "editar" ? colony.current : ""
                  }
                  margin="dense"
                  id="colony"
                  name="colony"
                  label="Colonia"
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  inputRef={zipcode}
                  defaultValue={
                    actionAddress === "editar" ? zipcode.current : ""
                  }
                  inputProps={{ pattern: "[0-9]{5,5}"}}
                  margin="dense"
                  id="zipcode"
                  name="zipcode"
                  label="Codigo Postal"
                  required
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <FormControl className={classes.selectStyle}>
                  <InputLabel shrink htmlFor="country-native-label-placeholder">
                    País
                  </InputLabel>
                  <NativeSelect
                    required
                    inputRef={chcountry}
                    defaultValue={
                      actionAddress === "editar" ? chcountry.current : chcountry
                    }
                    value={country.id}
                    onChange={(event) => handleChangeCountry(event)}
                  >
                    <option value="">Eligir un país</option>
                    { optionsCountries.map((country) => (
                      <option
                        value={country.pais_pais_id}
                        name={country.pais_nombre}
                        id={country.pais_pais_id}
                      >
                        {country.pais_nombre}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
              <FormControl className={classes.selectStyle}>
                      <InputLabel
                        shrink
                        htmlFor="state-native-label-placeholder"
                      >
                        Estado
                      </InputLabel>
                      {(!changeSelect || actionAddress ==="crear") ?
                      <NativeSelect
                        required
                        inputRef={chstate}
                        defaultValue={
                          actionAddress === "editar" ? chstate.current : chstate
                        }
                        value={state.id}
                        onChange={(event) => handleChangeState(event)}
                      >
                        <option value="">Eligir un estado</option>
                        {  optionsStates.map((state) => (
                          <option
                            value={state.estpa_estado_pais_id}
                            name={state.estpa_estado_nombre}
                            id={state.estpa_estado_nombre}
                          >
                            {state.estpa_estado_nombre}
                          </option>
                        ))}
                      </NativeSelect> : <br/>
                      }
                      {(actionAddress === "editar" && changeSelect)&& (
                        <span style={{ fontSize: 15 }}>{state.state}</span>
                      )}
                    </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                    <FormControl className={classes.selectStyle}>
                      <InputLabel
                        shrink
                        htmlFor="city-native-label-placeholder"
                      >
                        Ciudad
                      </InputLabel>
                      {  (!changeSelect || actionAddress ==="crear") ?
                      <NativeSelect
                        required
                        inputRef={chcity}
                        defaultValue={
                          actionAddress === "editar" ? chcity.current: chcity
                        }
                        value={city.id}
                        onChange={(event) => handleChangeCity(event)}
                      >
                        <option value="">Eligir una ciudad</option>
                        { optionsCities.map((city) => (
                          <option
                            value={city.city_ciudades_estados_id}
                            name={city.city_ciudad}
                            id={city.city_ciudad}
                          >
                            {city.city_ciudad}
                          </option>
                        ))}
                      </NativeSelect> : <br/>
                      }
                      {(actionAddress === "editar" && changeSelect)&& (
                        
                        <span style={{ fontSize: 15 }}>{city.city}</span>
                      )}
                    </FormControl>
              </Grid>

              <Grid item xs={12} md={2}>
              {(actionAddress === "editar" && changeSelect)&& (
              <Tooltip title="Editar estado y ciudad">
                              <IconButton
                                aria-label="edit"
                                onClick={() => setChangeSelect(false)}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>)}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="secondary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              {actionAddress === "crear"
                ? "Crear Dirección"
                : "Editar Dirección"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
}
