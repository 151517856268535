import React, { useState, useEffect, Fragment } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { Grid, IconButton, InputAdornment, createStyles, TextField, Autocomplete } from "@mui/material";


// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
import { searchServices } from "_services/search.services";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { Popper, makeStyles } from "@material-ui/core";



const SearchAutocomplete = ({funSetSearchWord, classes}) => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [valor, setValor] = useState(false);
  const {search, setSearch} = useAuth();
  const [searchs, setSearchs] = useState('');

  useEffect(() =>{ 
    if(searchs.length >= 3) {
      ObtenerData(searchs);
    } else {
      setData([]);
    }
  }, [searchs]);

  const ObtenerData = (search) => {
    searchServices.searchAutocomplete(search).then(({ data }) => {
      setData(data)
    });
  }


  const onChangeSearch = (word) => {
      if(word.length >= 3) {
        setSearchs(word);
      } else {
        setData([]);
      }
  }

  const onSelectHadleValue = (e) => {
    e.preventDefault();
    funSetSearchWord(e.target.value);
  }

  const handleChangeRedirect = (value) => {
    setSearchs('');
    let searchWordValue = value.toUpperCase();
    setData([]);
    history.push(`/filters?search=${encodeURI(searchWordValue)}&type=sh`);
  };
  
  const handleRedirect=(value) => {
    setSearchs('');
    let searchWordValue = value.toUpperCase();
    setData([]);
    history.push(`/filters?search=${encodeURI(searchWordValue)}&type=sh`);
  }

  const handlePressEnter = (e) => {
    if(e.key === 'Enter') {
      setData([]);
      handleChangeRedirect(e.target.value);
    }
  }

  const CustomPopper = function (props) {
    return <Popper {...props} className={classes.root} placement="bottom" />;
  };

  const handleClickButton = () => {
    const searchWordValue = searchs.toUpperCase();
    setSearchs('');
    setData([]);
    history.push(`/filters?search=${encodeURI(searchWordValue)}&type=sh`);
  }

  return (
    <Autocomplete
      freeSolo
      value={searchs}
      disableClearable
      options={data.map((option) => option.prod_nombre)}
      onChange={(event, value) => handleChangeRedirect(value)}
      onKeyUp={(e) => handlePressEnter(e)}
      PopperComponent={CustomPopper}
      sx={{
        '& .MuiOutlinedInput-root': {
            borderRadius: '35px',
            height: '45px',
            paddingLeft: 4,
            '& fieldset': {
                borderColor: '#e9e9e9 !important',
                borderWidth: '4px !important',
            },
        },
        '&:hover': {
            borderColor: '#e9e9e9', // Color del borde al hacer hover
        },
        '& .Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#e9e9e9 !important',
                borderWidth: '4px !important',
            },
        },
        '& .MuiInputLabel-root': { // label
            color: '#0b3196',
        },
        '&:hover .MuiInputLabel-root': { // label al hacer hover
            color: '#0b3196',
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Realiza una búsqueda..."
          variant="outlined"
          size="small"
          fullWidt
          value={searchs}
          
          onChange={(ev) => onChangeSearch(ev.target.value)}
          onSelect={(e) => onSelectHadleValue(e)}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            
            endAdornment: (
              <InputAdornment position="end" sx={{ marginRight: 1 }} >
                <IconButton size="small" onClick={handleClickButton}>
                  <SearchIcon sx={{ color: '#40df00' }} style={{cursor:"pointer"}}  />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      )}
    />
  );
}

export default SearchAutocomplete;