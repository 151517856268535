
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/
import TextField from '@mui/material/TextField';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useAuth } from "hooks/useAuth";
import React, { useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import { signInService } from "auth/services/signInService";
import { userService } from "_services/user.service";
import { checkoutService } from "_services/checkout.service";
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { getTotalCartProducts } from "services/getTotalCartProducts";
import { accountService } from "services/account/account.service";
export const Register =()=>{
  const history = useHistory();

    const classes = useStyles();
    const {setOpenAlert,setIsLogged,setCarritoItems}= useAuth();
    const [name, setName]= useState('')
    const [lastName, setLastName]= useState('')
    const [motherLastName, setMotherLastName]=useState('')
    const [email, setEmail]= useState('')
    const [password, setPassword]= useState('')
    const [valideMail, setValideMail]= useState(false)


    const isValidEmail=(email) => {
      return /\S+@\S+\.\S+/.test(email);
    }

    useEffect(()=>{
  
      if(!isValidEmail(email) && email.length>1 ){
          setValideMail(true)
          //window.alert(mail)
      }else{
          setValideMail(false)
      }

  },[email])
  const getProducts = async()=>{
    let countProducts = await getTotalCartProducts()
    setCarritoItems(countProducts)
}


    const addUserForm = async (e) =>{
      e.preventDefault();

      if(!valideMail&& email.length>2 && name.length>0 && lastName.length>0 && motherLastName.length>0 && password.length>3){        
        let newUser= await userService.registerUserB2C(name,lastName,motherLastName,email,password)      
        if(newUser.error){
          setOpenAlert({open:true, type:'success',message: 'El correo ya existe.' })
        }else{
          let type=5
      const resulta = await accountService.addNewUserInteractions({type})
        setOpenAlert({open:true, type:'success',message:'¡Tu usuario se ha registrado exitosamente!' })
        history.push('/login')
        // signInService(email, password).then(({ isSuccess, token, data, message }) => {
        //   if(isSuccess){           
        //      setIsLogged(true);
        //      userService.getClientById(data.usuario.snu_sn_socio_de_negocio_id);
        //      checkoutService.createOrGetCartByClientId(data.usuario.snu_sn_socio_de_negocio_id);
        //      getProducts();
        //     history.push("/"); 
        // }else{
        //   setOpenAlert({open:true, type:'success',message })
        // } 
        // })
      }


      }else if (valideMail){
        setOpenAlert({open:true, type:'error',message:'El correo no es válido.' })
      }else if(password.length<=8){
        setOpenAlert({open:true, type:'error',message:'La contraseña debe ser mayor a 8 caracteres, no incluir caracteres especiales.' })
      }
      else{
       // window.alert('')
        setOpenAlert({open:true, type:'error',message:'Los campos de Nombre | Apellidos son obligatorios.' })
      }
    }


    return (
        
        <div className={classes.rootGrid}>
          <form onSubmit={addUserForm}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.titleFormSection}>
                Datos del solicitante
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                fullWidth
                variant="outlined"
                className={classes.inputs}
                size="small"
                id="name"
                name="name"
                type="text"
                label="Nombre"
                value={name}
                onChange={(e)=> setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                fullWidth
                variant="outlined"
                className={classes.inputs}
                size="small"
                id="lastName"
                name="lastName"
                type="text"
                label="Apellido Paterno"
                value={lastName}
                onChange={(e)=> setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                fullWidth
                variant="outlined"
                className={classes.inputs}
                size="small" 
                id="motherLast"
                name="motherLast"
                type="text"
                label="Apellido Materno"
                value={motherLastName}
                onChange={(e)=> setMotherLastName(e.target.value)}
              />
            </Grid>
           
            <Grid item xs={12} sm={12}>
              <TextField
                required
                fullWidth
                variant="outlined"
                className={classes.inputs}
                size="small"
                id="email"
                name="email"
                type="email"
                label="Correo electrónico"
                value={email}
                onChange={(e)=> setEmail(e.target.value)}
              />
               { valideMail && <label style={{color:'gray'}} > *Campo Obligatorio</label>}
         {valideMail && <label style={{color:'gray'}} > *El correo tiene un formato incorrecto, ejemplo: ejemplo@example.com</label> }
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                required
                fullWidth
                variant="outlined"
                className={classes.inputs}
                size="small"
                value={password}
                onChange={(e)=> setPassword(e.target.value)}
                id="pass"
                name="pass"
                type="password"
                label="Contraseña"
              />
            </Grid>
          </Grid>
          <div className={classes.buttonDiv}>
          <Button variant="contained" className={classes.buttons} type="submit">
            Registrarme
          </Button>
          </div>
          </form>
       
          <br/>
          <Divider>
          <Chip label="O" size="medium" />
        </Divider>
              
  
              <Typography className={classes.nuevoClienteText}>
                ¿Deseas registrarte como empresa? Completa el formulario para disfrutar de beneficios exclusivos
              </Typography>   

                <div className={classes.buttonDiv2}>
          <Button variant="contained" className={classes.buttons} onClick={()=>{history.push("/register")}} type="button">
            Registrarme como empresa
          </Button>
          </div>
            
        </div>
        
    )
}

const useStyles = makeStyles((styles) => ({
    mainBackground: {
      backgroundColor: "#FFF",
    },
    accesoCliente: {
      display: "block",
      width: "60%",
      margin: "auto",
      paddingLeft: 50,
      paddingRight: 50,
      paddingTop: "10vh",
      paddingBottom: "20vh",
      /* backgroundColor: "#efefef", */
      [styles.breakpoints.down("sm")]: {
        width: "100%",
        paddingBottom: "5vh",
        paddingTop: "2vh",
      },
    },
    inputsDiv: {
      width: "100%",
      paddingLeft: "15%",
      paddingRight: "15%",
      marginBottom: 15,
      [styles.breakpoints.down("sm")]: {
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
    buttonDiv: {
      width: "100%",
      paddingTop: "10%",
      paddingLeft: "25%",
      paddingRight: "25%",
      marginBottom: 15,
      [styles.breakpoints.down("sm")]: {
        paddingTop: 30,
        paddingLeft: 15,
        paddingRight: 15,
        width: "90%",
        margin: "auto",
      },
    }, buttonDiv2: {
      width: "100%",
      paddingTop: "5%",
      paddingLeft: "25%",
      paddingRight: "25%",
      marginBottom: 15,
      [styles.breakpoints.down("sm")]: {
        paddingTop: 30,
        paddingLeft: 15,
        paddingRight: 15,
        width: "90%",
        margin: "auto",
      },
    },
    //alerts
    alerts: {
      color: "#FFFFFF",
      backgroundColor: "#40DF00",
      fontFamily: "'Centrale Sans Light'",
      fontSize: 22,
      "& .MuiAlert-icon": {
        color: "#FFFFFF",
      },
      "&.MuiAlert-standardError": {
        backgroundColor: "#DF2B2B",
      },
    },
    inputs: {
      width: "100%",
      border: "1px solid #EAEAEA",
      borderRadius: "2px",
      backgroundColor: "#F5F5F5",
    },
    spaceDiv: {
      marginTop: 15,
      marginBottom: 15,
    },
    buttons: {
      width: "100%",
      color: "#ffffff",
      backgroundColor: "#0C2D9C",
      textTransform: "none",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: 16,
      "&:hover": {
        backgroundColor: "#3e50c5",
        color: "#ffffff",
      },
    },
    clienteNuevoTextDiv: {
      width: "100%",
      marginBottom: 15,
    },
    nuevoClienteText: {
      color: "#000000",
      fontFamily: "'Centrale Sans Regular'",
      textAlign: "center",
      marginTop: 10,
      marginBottom: 10,
      fontSize: 18,
    },
    linkBack: {
      fontFamily: "'Centrale Sans Medium'",
      color: "#0B3196",
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: "19px",
      textAlign: "center",
      paddingLeft: "30px",
      position: "relative",
    },
    linkBackArrow: {
      position: "absolute",
      left: "5px",
      bottom: "-2px",
    },
    titleMobile: {
      fontFamily: "'Centrale Sans Bold'",
      fontSize: 24,
      lineHeight: "29px",
      textAlign: "center",
      color: "#0b3196",
      paddingTop: 20,
    },
    titleDivider: {
      border: "1px solid #0B3196",
      width: "95%",
      margin: "auto",
    },
    root: {
      "& .MuiTextField-root": {
        margin: styles.spacing(1),
        width: "25ch",
      },
    },
    rootGrid: {
      flexGrow: 1,
      margin: "50px 0",
      [styles.breakpoints.down("sm")]: {
        margin: "20px 0",
      },
    },
    titleFormSection: {
      fontFamily: "'Centrale Sans Medium'",
      fontSize: 18,
      fontWeight: 500,
      textAlign: "center",
      color: "#0C2D9C",
      [styles.breakpoints.down("sm")]: {
        fontFamily: "'Centrale Sans Regular'",
        fontSize: 14,
        fontWeight: 300,
      },
    },
    formControl: {
      minWidth: "100%",
      "& label": {
        transform: "translate(14px, 14px) scale(1)",
      },
    },
    selectEmpty: {
      marginTop: styles.spacing(2),
    },
    selects: {
      backgroundColor: "#F5F5F5",
      border: "1px solid #EAEAEA",
      borderRadius: 4,
      height: 40,
      fontFamily: "'Centrale Sans Regular'",
    },
  }));