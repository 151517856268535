import React, { useState, useEffect, useContext, Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components

import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarHalfIcon from '@material-ui/icons/StarHalf';


const useStyles = makeStyles((styles) => ({
    space: {
        width: "100%",
        display: "inline-block",
        textAlign: "left",
        paddingLeft: "15.5%",
        paddingRight: "13%",
        [styles.breakpoints.down("sm")]: {
            paddingLeft: "3%",
            paddingRight: "3%",
        },
    },
    container: {
        backgroundColor: "#0C2D9C",
    },
    iconColor: {
        color: "#ffe720",
        fontSize: 24,
        [styles.breakpoints.down("sm")]: {
            fontSize: "22px",
        },
    },
    rating: {
        display: "inline-block",
        width: "100%",
        textAlign: "center",
    },
    comments: {
        display: "inline-block",
        textAlign: "center",
        width: "100%",
    },
    rate: {
        width: "100%",
        textAlign: "center",
    },
    globalRateTxt: {
        color: "#fff",
        fontSize: "48px",
        display: "inline-block",
        fontFamily: "'Centrale Sans Medium'",
        lineHeight: "50px",
        marginBottom: "0px",
        marginTop: "26px",
        [styles.breakpoints.down("sm")]: {
            fontSize: "36px",
            marginTop: "15px",
        },
    },
    maxRateTxt: {
        color: "#fff",
        fontSize: "28px",
        marginLeft: "5px",
        display: "inline-block",
        fontFamily: "'Centrale Sans Medium'",
        fontWeight: "300",
        lineHeight: "31px",
        marginBottom: "0px",
        [styles.breakpoints.down("sm")]: {
            fontSize: "20px",
        },
    },
    commentsTxt: {
        color: "#fff",
        fontFamily: "'Centrale Sans Medium'",
        fontSize: "16px",
        fontWeight: "300",
        [styles.breakpoints.down("sm")]: {
            fontSize: "14px",
        },
    },
    divSpace: {
        width: "100%",
        paddingLeft: "15px",
        paddingRight: "15px",
        marginTop: "5px",
        marginBottom: "10px",
        [styles.breakpoints.down("sm")]: {
            marginBottom: "0px",
        },
    },
    lineSpace: {
        backgroundColor: "#00FF00",
        height: "2px",
        [styles.breakpoints.down("sm")]: {
            height: "1px",
        },
    },
}));

export default function SectionProductGlobalRating(props) {
    const classes = useStyles();
    const [rateStars, setRateStars] = useState(null);
    useEffect(() => {
        setStars();
    }, []);
    const setStars = () => {
        let stars = [];
        for (let i = 1; i <= Math.floor(props.productDetails.clients_comments.rating); i++) {
            stars.push(<StarIcon key={"full" + i} className={classes.iconColor} />);
        }
        if (props.productDetails.clients_comments.rating > Math.floor(props.productDetails.clients_comments.rating)) {
            stars.push(<StarHalfIcon key={"half"} className={classes.iconColor} />);
        }
        for (let i = Math.ceil(props.productDetails.clients_comments.rating); i < 5; i++) {
            stars.push(<StarBorderIcon key={"empty" + i} className={classes.iconColor} />);
        }
        setRateStars(stars);
    }
    return (
        <div className={classes.space}>
            <div className={classes.container}>
                <div className={classes.rate}>
                    <p className={classes.globalRateTxt}>{props.productDetails.clients_comments.rating}</p><p className={classes.maxRateTxt}>/5</p>
                </div>
                <div className={classes.rating}>
                    {rateStars}
                </div>
                <div className={classes.divSpace}>
                    <div className={classes.lineSpace}>
                    </div>
                </div>
                <div className={classes.comments}>
                    <p className={classes.commentsTxt}>{props.productDetails.clients_comments.comments.length} Comentario{props.productDetails.clients_comments.comments.length === 1 ? "" : "s"}</p>
                </div>
            </div>
        </div>
    );
}
