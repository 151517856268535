import React, { useState, useEffect, useContext, Fragment } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

//carousel
import Carousel from "react-elastic-carousel";
import styled from "styled-components";
import "./styles.css";

const breakPoints = [
  { width: 1, itemsToShow: 1 }, 
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 5 },
  { width: 1200, itemsToShow: 5 },
];

const useStyles = makeStyles((styles) => ({
  container: {
    width: "100%",
  },
  imgDiv: {
    width: "70%",
    display: "inline-block",
    "&:hover": {
      cursor: "pointer",
    },
    [styles.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  image: {
    width: "100%",
  },
  // carousels
  carouselTitle: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: 600,
    color: "#0b3196",
    marginTop: 10,
  },
  carouselDivider: {
    border: "2px solid #40DF00",
    backgroundColor: "#40DF00",
    marginTop: 8,
    borderRadius: 20,
  },
  carouselContent: {
    marginTop: 6,
  },
  imgTrusty: {
    width: "80%",
  },
}));

export default function SectionHomeMidCarousel(props) {
  const classes = useStyles();

  const Item = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 100%;
    background-color: transparent;
    color: #fff;
    font-size: 4em;
  `;

  const goTo = (link) => {
    if (link && link != "") {
      window.location.href = link;
    }
  };

  return (
    <div className="wrapper">
      <div className={classes.container}>
        <Typography variant="h6" className={classes.carouselTitle}>
          {props.globalData.title}
        </Typography>
        <div className={classes.carouselContent}>
          <div className="App">
            <Carousel breakPoints={breakPoints} enableAutoPlay={true} autoPlaySpeed={5000}>
              {props.globalData.options.map((o, index) => (
                <div
                  key={`carusel-item-id-${index}`}
                  className={classes.imgDiv}
                  onClick={() => {
                    goTo(o.link);
                  }}
                  
                >
                  <Item key={o.id}>
                    <img className={classes.imgTrusty} key={o.id} src={o.img} />
                  </Item>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}
