import React, { useContext, createContext, useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((styles) => ({
  title: {
    color: "#444444",
    fontFamily: "'Centrale Sans Light'",
    fontSize: "18px",
    fontWeight: "300",
    letterSpacing: "0",
    lineHeight: "23px",
    textAlign: "center",
    lineHeight: "27px",
  },
  container: {
    width: "100%",
  },
  fontBold: {
    fontFamily: "'Centrale Sans Bold'",
    fontSize: 24,
    marginTop: 10,
  },
}));

export default function SectionTitle(props) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.container}>
        <Typography className={classes.title}>
          Resultados para <br />
          <span className={classes.fontBold}>
            {props?.categoryName != null && props?.categoryName}
            {
              props?.category?.title != null
                && props.category?.title + ' '
            }
            {props.category?.nombre_extranjero != null
              && props.category?.nombre_extranjero + ' '}
            {props.category?.description != null
              && props.category?.description?.toUpperCase() + ' '}
            {props.category?.brand != null && props.category?.brand + ' '}
            {props.category?.category != null && props.category?.category}
          </span>
        </Typography>
      </div>
    </div>
  );
}
