import React, {
  useContext,
  createContext,
  useState,
  useRef,
  useEffect,
  Fragment,
} from "react";
import { userService } from "../../../_services/user.service";
import { useHistory, useLocation } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import { getPriceSapFormat } from "helpers/formatPrice";
import { dateFormat } from "_helpers/date-format";
import { dateDaysCompare } from "_helpers/date-days-compare";

// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
// @material-ui/
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
// core components
import useCheckMobileScreen from "components/Mobile/Mobile.js";
import SectionBreadcrumb from "views/Home/Sections/SectionBreadcrumb.js";
import { useAuth } from "hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    margin: "auto",
    width: "95%",
    paddingBottom: 30,
  },
  spaceDiv: {
    marginTop: 15,
    marginBottom: 15,
  },
  linkBack: {
    fontFamily: "'Centrale Sans Medium'",
    color: "#0B3196",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "19px",
    textAlign: "center",
    paddingLeft: "30px",
    position: "relative",
  },
  linkBackArrow: {
    position: "absolute",
    left: "5px",
    bottom: "-2px",
  },
  title: {
    fontFamily: "'Centrale Sans Bold'",
    textTransform: "uppercase",
    fontSize: 24,
    lineHeight: "29px",
    textAlign: "justify",
    color: "#0b3196",
    paddingTop: 20,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  titleDivider: {
    border: "1px solid #0B3196",
    width: "95%",
    margin: "auto",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: "30px 0",
  },
  //formData
  formData: {
    backgroundColor: "#F5F5F5",
    borderRadius: 2,
    margin: "10px 0",
    border: "1px solid #EAEAEA",
    maxHeight: 400,
    overflowY: "auto",
    "& *": {
      margin: "0",
      padding: "5px 0 5px 10px",
      fontFamily: "'Centrale Sans Regular'",
      border: "none",
      fontSize: 16,
      [theme.breakpoints.down("sm")]: {
        padding: "2px 0 2px 4px",
      },
    },
  },
  formDataTitle: {
    color: "#0C2D9C",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 22,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "27px",
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  formDataSubTitleDes: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 18,
    textAlign: "justify",
  },
  gridContent: {
    paddingBottom: 30,
  },
  buttonDelete: {
    border: "none",
    color: "#8e8a8a",
    backgroundColor: "transparent",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    margin: "auto",
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  buttonBack: {
    border: "1px solid",
    color: "#0C2D9C",
    backgroundColor: "transparent",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    margin: 0,
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  buttonUpdate: {
    border: "1px solid",
    color: "#0C2D9C",
    backgroundColor: "transparent",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    margin: "auto",
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  buttonSemaforo: {
    border: "none",
    backgroundColor: "#0C2D9C",
    color: "#ffffff",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    margin: 10,
    marginLeft: 0,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
    "&:active, &:focus": {
      border: "2px solid #3e50c5",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 5,
    },
  },
  inputs: {
    width: "100%",
    border: "1px solid #EAEAEA",
    borderRadius: "2px",
    backgroundColor: "#FFFFFF",
  },
  //alerts
  alerts: {
    color: "#FFFFFF",
    backgroundColor: "#40DF00",
    fontFamily: "'Centrale Sans Light'",
    fontSize: 22,
    "& .MuiAlert-icon": {
      color: "#FFFFFF",
    },
    "&.MuiAlert-standardError": {
      backgroundColor: "#DF2B2B",
    },
  },
  //defaultAddress
  semaforoGrid: {
    margin: "20px 0 0",
  },
  semaforoDiv: {
    border: "none",
    backgroundColor: "rgba(60,219,192,0.14)",
    padding: 20,
    margin: "10px 0",
    borderRadius: 3,
  },
  estadoDiv: {
    border: "none",
    backgroundColor: "transparent",
    padding: 0,
    borderRadius: 3,
  },
  filterGrid: {
    border: "1px solid #EAEAEA",
    backgroundColor: "#F5F5F5",
    borderRadius: 2,
    height: 55,
  },
  defaultAlias: {
    color: "#0C2D9C",
    fontFamily: "'Centrale Sans Bold'",
    fontSize: 18,
    fontWeight: "bold",
    letterSpacing: 0,
    lineHeight: "23px",
  },
  defaultAddress: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    fontWeight: "500",
    letterSpacing: 0,
    lineHeight: "19px",
  },
  buttonBackDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "10px 0",
  },
  actionButtonsDes: {
    display: "inline-flex",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-around",
    },
  },
  actionButtonsCleanFilter: {
    display: "flex",
    justifyContent: "flex-end",
  },
  actionButtonsMov: {},
  //selecte
  formControl: {
    minWidth: "25%",
    [theme.breakpoints.down("sm")]: {
      minWidth: "50%",
    },
  },
  selects: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #e8e8e8",
    borderRadius: 4,
    height: 40,
    fontFamily: "'Centrale Sans Regular'",
    position: "relative",
    top: 6,
    left: 6,
  },
  dowloadLinks: {
    borderRadius: 2,
    backgroundColor: "#40DF00",
    color: "#ffffff",
    padding: "5px 10px",
    marginRight: 5,
    "&:hover": {
      backgroundColor: "#40DF00",
      color: "#ffffff",
    },
  },
  billStatus: {
    color: "#40DF00",
  },
  //table destopk
  tableHead: {
    backgroundColor: "#0B3196",
    color: "#FFFFFF",
  },
  tableRow: {},
}));

export default function ProfileBills(props) {
  const classes = useStyles();
  const confirm = useConfirm();

  let history = useHistory();
  const location = useLocation();

  let isMobile = useCheckMobileScreen();
  const {setOpenAlertF}= useAuth();
 

  const [cleanFilter, setCleanFilter] = useState(false);
  const [billsOverdue, setBillsOverdue] = useState(0);
  const [billsToBeat, setBillsToBeat] = useState(0);
  const [billsPaid, setBillsPaid] = useState(0);

  const [bills, setBills] = useState([]);
  const [billsBackup, setBillsBackup] = useState([]);
  const [filterDate, setFilterDate] = useState("");
  const [saldo, setSaldo] = useState(null);
  const [credito, setCredito] = useState(null);
  const [dataBreadCrumb, setDataBreadCrumb] = useState([]);
  const [permisosUser, setPermisosUser] = useState({});
  const [user, setUser] = useState({});
  const [client, setClient] = useState({});

  useEffect(() => {
    if (!localStorage.getItem("user")) {
        //redirect to login
        history.push("/login");
    } else {
        setUser(JSON.parse(localStorage.getItem("user")).usuario);
    }

    if (localStorage.getItem("client")) {
        // getSaldoAndCredito();
        setClient(JSON.parse(localStorage.getItem("client")).listaSnbyid[0]);
    }
    
    setDataBreadCrumb(() => [
      {
        name: "INICIO",
        link: "/",
      },
      {
        name: "MIS FACTURAS",
        link: "",
      }
    ]);

    getPermisos();
  }, []);

  const getPermisos = async () => {
    const selectedIndex = location.pathname;
    if (JSON.parse(localStorage.getItem("user"))) {
      if (JSON.parse(localStorage.getItem("user")).usuario.snu_menu_roles) {
        let result_permiso;
        result_permiso = await JSON.parse(
          localStorage.getItem("user")
        ).usuario.snu_menu_roles.find((item) => {
          return `/myprofile/${item.key}` === selectedIndex
        });

        if (result_permiso === undefined) {
          JSON.parse(localStorage.getItem("user")).usuario.snu_menu_roles.map(
            (modulo) => {
              if (modulo.submenu) {
                result_permiso = modulo.submenu.find(
                  (item) => `/myprofile/${item.key}` === selectedIndex
                );
              }
            }
          );
        }
        setPermisosUser(result_permiso);
      }
    }
  };

  const getBills = async () => {
    const client_bills_response = await userService.getBills(
      user.snu_cardcode
    );

    setBills(client_bills_response.data.constFacturas);
    setBillsBackup(client_bills_response.data.constFacturas);

    let billPaidCount = 0;
    let billOverdueCount = 0;
    let billToBeatCount = 0;

    client_bills_response.data.constFacturas && client_bills_response.data.constFacturas.map((bill) => {
      if (bill.fac_estatus === "Pagada") {
        billPaidCount++;
      }
      if (dateDaysCompare(dateFormat(bill.fac_fecha_venc)) > 0) {
        billOverdueCount++;
      }
      if (
        dateDaysCompare(dateFormat(bill.fac_fecha_venc)) < 0 &&
        dateDaysCompare(dateFormat(bill.fac_fecha_venc)) > -2
      ) {
        billToBeatCount++;
      }
    });

    setBillsOverdue(billOverdueCount);
    setBillsToBeat(billToBeatCount);
    setBillsPaid(billPaidCount);
  };

  useEffect(() => {
    getBills();
    getSaldoAndCredito();
  }, []);

  const getSaldoAndCredito = async () => {
    const clientData = JSON.parse(localStorage.getItem("client"));
    const clientId = clientData.listaSnbyid[0].sn_socios_negocio_id;
    const clientDataById = await userService.getClientById(clientId);
    const saldoActual = clientDataById.data.listaSnbyid[0].sn_credito;
    const creditoDisponible =
      clientDataById.data.listaSnbyid[0].sn_credito_disponible;
    setSaldo(saldoActual);
    setCredito(creditoDisponible);
  };

  const handleSemaforoFilter = (action) => {
    setCleanFilter(true);
    if (action === "red") {
      //filter bills table, show only VENCIDAS
      setBills(
        billsBackup.filter(
          (bill) => dateDaysCompare(dateFormat(bill.fac_fecha_venc)) > 0
        )
      );
    } else if (action === "yellow") {
      //show bill with only two days to VENCER
      setBills(
        billsBackup.filter(
          (bill) =>
            dateDaysCompare(dateFormat(bill.fac_fecha_venc)) < 0 &&
            dateDaysCompare(dateFormat(bill.fac_fecha_venc)) > -5 // 5 days to VENCER
        )
      );
    } else if (action === "green") {
      //show bills already pay by status
      setBills(billsBackup.filter((bill) => bill.fac_estatus === "Pagada"));
    } else if (action === "clean") {
      setBills(billsBackup);
      setCleanFilter(false);
    }
  };

  const handleChangeFilterDate = (event) => {
    setFilterDate(event.target.value);
    if (event.target.value === "30") {
      setBills(
        billsBackup.filter(
          (bill) =>
            dateDaysCompare(dateFormat(bill.fac_fecha_conta)) > 0 &&
            dateDaysCompare(dateFormat(bill.fac_fecha_conta)) < 30 // filtro de fecha de emision
        )
      );
    } else {
      setBills(billsBackup);
    }
  };

  return (
    <div className={classes.root}>
    

      <div className={classes.spaceDiv}>
        <SectionBreadcrumb route={dataBreadCrumb} />
        <Typography className={classes.title}>Mi estado de cuenta</Typography>
        {isMobile ? <Divider className={classes.titleDivider} /> : ""}
      </div>
      {saldo != 0 ?
      <Grid container spacing={0}>
        {permisosUser
          ? permisosUser?.permisos?.map((p) => {
              if (p.key === "view_credit" && p.permiso === true) {
                return (
                  <Grid item xs={12} className={classes.semaforoGrid} key={p.key}>
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <div className={classes.estadoDiv}>
                          <div
                            style={{
                              height: 89,
                              borderRadius: 3,
                              backgroundColor: "#0C2D9C",
                              color: "#FFFFFF",
                              fontFamily: "'Centrale Sans Medium'",
                              fontSize: 16,
                              padding: 10,
                            }}
                          >
                            <Typography>Limite de crédito</Typography>
                            <Typography style={{ fontSize: 32 }}>
                              {saldo != null ? getPriceSapFormat(saldo) : ""}
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className={classes.estadoDiv}>
                          <div
                            style={{
                              height: 89,
                              borderRadius: 3,
                              backgroundColor: "#FFFFFF",
                              border: "1px solid #0C2D9C",
                              color: "#0C2D9C",
                              fontFamily: "'Centrale Sans Medium'",
                              fontSize: 16,
                              padding: 10,
                            }}
                          >
                            <Typography>Crédito disponible</Typography>
                            <Typography style={{ fontSize: 32 }}>
                              {credito != null ? getPriceSapFormat(credito) : ""}
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              }
            })
          : ""}
      </Grid>
      :  <Typography>  </Typography>
      }
    </div>
  );
}
