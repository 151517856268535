import { authHeader } from ".";
import { API_URL } from "../_services/api.url.back";

const conektaService = {
  getApiKey,
};

function getApiKey() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/conekta_internal_apis/conekta_public_key`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    const responseData = {
      error: false,
      data: data,
    };
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

export default conektaService;
