import React, {
  useContext,
  createContext,
  useState,
  useRef,
  useEffect,
  Fragment,
} from "react";
import { orderService } from "../../../_services/order.service";
import { useHistory } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import { getPriceSapFormat } from "helpers/formatPrice";
import OrderDetail from "./OrderDetail";
import MenuItem from "@material-ui/core/MenuItem";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SectionBreadcrumb from "views/Home/Sections/SectionBreadcrumb.js";

// @material-ui/
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Button } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import VisibilityIcon from "@material-ui/icons/Visibility";
// import Tooltip as TooltipMui from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

// core components
import useCheckMobileScreen from "components/Mobile/Mobile.js";
// get order status
import shortWord from "utils/shortWord";

const jerarquiaDeStatus = [
  {
    valor: 1,
    codigo: "1000107",
    statusFront: "Pendiente de Confirmar",
    statusBack: "PENDIENTE",
  },
  {
    valor: 2,
    codigo: "1000109",
    statusFront: "En Proceso",
    statusBack: "ABIERTA",
  },
  {
    valor: 3,
    codigo: "1000112",
    statusFront: "En Proceso",
    statusBack: "APROBADA",
  },
  {
    valor: 4,
    codigo: "¿?",
    statusFront: "En Proceso",
    statusBack: "PROCESO",
  },
  {
    valor: 5,
    codigo: "1000110",
    statusFront: "Entregado",
    statusBack: "CERRADA",
  },
];
// jerarquia valores generales: 1000185, 1000184, 1000187, 1000186,
const valoresDeStatus = [
  {
    valor: 0,
    codigo: "1000107",
    statusFront: "Pendiente de Confirmar",
    statusBack: "PENDIENTE",
  },
  {
    valor: 0,
    codigo: "1000108",
    statusFront: "Cancelado",
    statusBack: "CANCELADA",
  },
  {
    valor: 0,
    codigo: "1000109",
    statusFront: "En Proceso",
    statusBack: "ABIERTA",
  },
  {
    valor: 0,
    codigo: "1000112",
    statusFront: "En Proceso",
    statusBack: "APROBADA",
  },
  {
    valor: 0,
    codigo: "¿?",
    statusFront: "En Proceso",
    statusBack: "PROCESO",
  },
  {
    valor: 0,
    codigo: "1000110",
    statusFront: "Entregado",
    statusBack: "CERRADA",
  },
  {
    valor: 0,
    codigo: "1000111",
    statusFront: "En Proceso",
    statusBack: '"ELECCION PENDIENTE"',
  },
  {
    valor: 0,
    codigo: "1000113",
    statusFront: "Cancelado",
    statusBack: "RECHAZADA",
  },
  {
    valor: 0,
    codigo: "1000114",
    statusFront: "En Proceso",
    statusBack: '"REPLICA PENDIENTE"',
  },
  {
    valor: 0,
    codigo: "1000115",
    statusFront: "En Proceso",
    statusBack: '"REPLICA ERROR"',
  },
  {
    valor: 0,
    codigo: "1000116",
    statusFront: "En Proceso",
    statusBack: '"AUTORIZACION PENDIENTE"',
  },
  {
    valor: 4,
    codigo: "1000186",
    statusFront: "Entregado",
    statusBack: "ENTREGADA",
  },
  {
    valor: 3,
    codigo: "1000187",
    statusFront: "En tránsito",
    statusBack: "TRANSITO",
  },
  {
    valor: 2,
    codigo: "1000184",
    statusFront: "En proceso",
    statusBack: "PROCESO",
  },
  {
    valor: 1,
    codigo: "1000185",
    statusFront: "Pendiente de Confirmar",
    statusBack: "PENDIENTE",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    margin: "auto",
    width: "95%",
    paddingBottom: 30,
  },
  spaceDiv: {
    marginTop: 15,
    marginBottom: 15,
  },
  linkBack: {
    fontFamily: "'Centrale Sans Medium'",
    color: "#0B3196",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "19px",
    textAlign: "center",
    paddingLeft: "30px",
    position: "relative",
  },
  linkBackArrow: {
    position: "absolute",
    left: "5px",
    bottom: "-2px",
  },
  title: {
    fontFamily: "'Centrale Sans Bold'",
    textTransform: "uppercase",
    fontSize: 24,
    lineHeight: "29px",
    textAlign: "justify",
    color: "#0b3196",
    paddingTop: 20,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  titleDivider: {
    border: "1px solid #0B3196",
    width: "95%",
    margin: "auto",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: "30px 0",
  },
  //formData
  formData: {
    backgroundColor: "#F5F5F5",
    borderRadius: 2,
    margin: "10px 0",
    border: "1px solid #EAEAEA",
    maxHeight: 500,
    overflowY: "auto",
  },
  formDataTitle: {
    color: "#0C2D9C",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 22,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "27px",
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  formDataSubTitleDes: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 18,
    textAlign: "justify",
  },
  gridContent: {
    paddingBottom: 30,
  },
  buttonDelete: {
    border: "none",
    color: "#8e8a8a",
    backgroundColor: "transparent",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    margin: "auto",
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  buttonBack: {
    border: "1px solid",
    color: "#0C2D9C",
    backgroundColor: "transparent",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    margin: 0,
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  buttonUpdate: {
    border: "1px solid",
    color: "#0C2D9C",
    backgroundColor: "transparent",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    margin: "auto",
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  buttonSemaforo: {
    border: "none",
    backgroundColor: "#0C2D9C",
    color: "#ffffff",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    margin: 10,
    marginLeft: 0,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 5,
    },
  },
  inputs: {
    width: "100%",
    border: "1px solid #EAEAEA",
    borderRadius: "2px",
    backgroundColor: "#FFFFFF",
  },
  //alerts
  alerts: {
    color: "#FFFFFF",
    backgroundColor: "#40DF00",
    fontFamily: "'Centrale Sans Light'",
    fontSize: 22,
    "& .MuiAlert-icon": {
      color: "#FFFFFF",
    },
    "&.MuiAlert-standardError": {
      backgroundColor: "#DF2B2B",
    },
  },
  //defaultAddress
  semaforoGrid: {
    margin: "35px 0 0",
  },
  semaforoDiv: {
    border: "none",
    backgroundColor: "rgba(60,219,192,0.14)",
    padding: 20,
    margin: "10px 0",
    borderRadius: 3,
  },
  filterGrid: {
    border: "1px solid #EAEAEA",
    backgroundColor: "#F5F5F5",
    borderRadius: 2,
    height: 55,
    paddingTop: 8
  },
  orderByForm: {
    marginRight: "15px",
    width: 140,
    height: 30,
    textAlign: "center",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 14,
  },
  defaultAlias: {
    color: "#0C2D9C",
    fontFamily: "'Centrale Sans Bold'",
    fontSize: 18,
    fontWeight: "bold",
    letterSpacing: 0,
    lineHeight: "23px",
  },
  defaultAddress: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    fontWeight: "500",
    letterSpacing: 0,
    lineHeight: "19px",
  },
  buttonBackDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "10px 0",
  },
  actionButtonsDes: {
    display: "inline-flex",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-around",
    },
  },
  actionButtonsCleanFilter: {
    display: "flex",
    justifyContent: "flex-end",
  },
  actionButtonsMov: {},
  //selecte
  formControl: {
    minWidth: "25%",
    [theme.breakpoints.down("sm")]: {
      minWidth: "50%",
    },
  },
  selects: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #e8e8e8",
    borderRadius: 4,
    height: 40,
    fontFamily: "'Centrale Sans Regular'",
    position: "relative",
    top: 6,
    left: 6,
  },
  dowloadLinks: {
    borderRadius: 2,
    backgroundColor: "#40DF00",
    color: "#ffffff",
    padding: "5px 10px",
    marginRight: 5,
    "&:hover": {
      backgroundColor: "#40DF00",
      color: "#ffffff",
    },
  },
  billStatus: {
    color: "#40DF00",
  },
  //table destopk
  tableHead: {
    backgroundColor: "#0B3196",
    color: "#FFFFFF",
    padding: "10px 5px 0 10px",
    fontFamily: "'Centrale Sans Regular'",
    border: "none",
    fontSize: 16,
  },
  tableRow: {
    margin: "5px 0",
    padding: "5px 10px 0px 10px",
    fontFamily: "'Centrale Sans Regular'",
    border: "none",
    borderBottom: "1px solid #EAEAEA",
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      padding: "2px 0 2px 4px",
    },
  },
  tdText: {
    fontWeight: 500,
    fontSize: "18px",
    color: "#000",
    fontFamily: "Centrale Sans Regular",
  },
}));

export default function ProfileOrders(props) {
  const classes = useStyles();
  const confirm = useConfirm();

  let history = useHistory();

  let isMobile = useCheckMobileScreen();


  const [filterStatus, setFilterStatus] = useState(" ");
  const [orders, setOrders] = useState([]);
  const [ordersBackup, setOrdersBackup] = useState([]);
  const [dataBreadCrumb, setDataBreadCrumb] = useState([]);
  const [user, setUser] = useState({});
  const [client, setClient] = useState({});
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(0);
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);

  useEffect(() => {
    setDataBreadCrumb(() => [
      {
        name: "INICIO",
        link: "/",
      },
      {
        name: "MIS PEDIDOS",
        link: "",
      }
    ]);
  }, []);

  const getOrders = async () => {
    console.log('Entro a la funcion getOrders');
    setLoadingSkeleton(false);
    const client_orders_response = await orderService.getOrdersByClientId(
      client.sn_socios_negocio_id
    );
    console.log('client_orders_response ', client_orders_response);
    let ordersList = client_orders_response.historico;
    const orders_status = await orderService.getOrdersStatusST();
    for (let i = 0; i < ordersList.length; i++) {
      let _o = ordersList[i];
      if (
        _o.cf_estatus_orden == _o.cf_estatus_orden_usd ||
        _o.cf_estatus_orden_usd == null
      ) {
        const getStatusFront = jerarquiaDeStatus.find(
          (item) => item.codigo == _o.cf_estatus_orden
        );
        if (getStatusFront) {
          ordersList[i].statusCMM = getStatusFront?.statusFront;
        } else {
          const getStatusFront = valoresDeStatus.find(
            (item) => item.codigo == _o.cf_estatus_orden
          );
          ordersList[i].statusCMM = getStatusFront?.statusFront;
        }
      } else if (
        _o.cf_estatus_orden == null &&
        _o.cf_estatus_orden_usd != null
      ) {
        const getStatusFront = valoresDeStatus.find(
          (item) => item.codigo == _o.cf_estatus_orden_usd
        );
        if (getStatusFront) {
          ordersList[i].statusCMM = getStatusFront?.statusFront;
        } else {
          ordersList[i].statusCMM = "Revisando status...";
        }
      } else if (
        _o.cf_estatus_orden != null &&
        _o.cf_estatus_orden_usd != null
      ) {
        const jerarquiaStatus = jerarquiaDeStatus.find(
          (item) => item.codigo == _o.cf_estatus_orden
        );
        const jerarquiaStatusUsd = jerarquiaDeStatus.find(
          (item) => item.codigo == _o.cf_estatus_orden_usd
        );
        if (jerarquiaStatus && jerarquiaStatusUsd) {
          if (jerarquiaStatus.valor >= jerarquiaStatusUsd.valor) {
            ordersList[i].statusCMM = jerarquiaStatus.statusFront;
          } else {
            ordersList[i].statusCMM = jerarquiaStatusUsd.statusFront;
          }
        } else if (jerarquiaStatus && !jerarquiaStatusUsd) {
          ordersList[i].statusCMM = jerarquiaStatus.statusFront;
        } else if (!jerarquiaStatus && jerarquiaStatusUsd) {
          ordersList[i].statusCMM = jerarquiaStatusUsd.statusFront;
        } else if (!jerarquiaStatus && !jerarquiaStatusUsd) {
          const getStatusFrontValor = valoresDeStatus.find(
            (item) => item.codigo == _o.cf_estatus_orden
          );
          const getStatusFrontValorUsd = valoresDeStatus.find(
            (item) => item.codigo == _o.cf_estatus_orden_usd
          );
          if (getStatusFrontValor.valor >= getStatusFrontValorUsd.valor) {
            ordersList[i].statusCMM = getStatusFrontValor.statusFront;
          } else {
            ordersList[i].statusCMM = getStatusFrontValorUsd.statusFront;
          }
        }
      }
      const client_order_response = await orderService.getOrderById(
        ordersList[i].cf_compra_finalizada_id
      );
      let orderIsInTransit = false;
      for (const sku of client_order_response.constCompraFinalizada
        .ProductoCompraFinalizada) {
        if (sku.pcf_linea_estatus_sap === "En tránsito") {
          orderIsInTransit = true;
          break;
        } else {
          orderIsInTransit = false;
          // break;
        }
      }

      if (orderIsInTransit) {
        ordersList[i].statusCMM = "En tránsito";
      }

      let orderHasParcialidad = false;
      for (const sku of client_order_response.constCompraFinalizada
        .ProductoCompraFinalizada) {
        if (
          sku.pcf_linea_estatus_sap.includes("Parcialidad") &&
          sku.pcf_linea_estatus_sap != "En proceso" &&
          sku.pcf_linea_estatus_sap != "Entregado"
        ) {
          orderHasParcialidad = true;
        } else {
          orderHasParcialidad = false;
          break;
        }
      }
      if (orderHasParcialidad) {
        ordersList[i].statusCMM = "En tránsito";
      }

      let orderIsInProcess = false;
      for (const sku of client_order_response.constCompraFinalizada
        .ProductoCompraFinalizada) {
        if (sku.pcf_linea_estatus_sap === "En proceso") {
          orderIsInProcess = true;
        }
      }
      if (orderIsInProcess) {
        ordersList[i].statusCMM = "En proceso";
      }

      let orderHasEntregadoParcialidad = false;
      for (const sku of client_order_response.constCompraFinalizada
        .ProductoCompraFinalizada) {
        if (
          sku.pcf_linea_estatus_sap.includes("Entregado") &&
          sku.pcf_linea_estatus_sap.includes("Parcialidad")
        ) {
          orderHasEntregadoParcialidad = true;
        }
      }
      if (orderHasEntregadoParcialidad) {
        ordersList[i].statusCMM = "En proceso";
      }

      let todosTransitoParcialidad = false;
      for (const sku of client_order_response.constCompraFinalizada
        .ProductoCompraFinalizada) {
        if (
          sku.pcf_linea_estatus_sap.includes("En tránsito") &&
          sku.pcf_linea_estatus_sap.includes("Parcialidad")
        ) {
          todosTransitoParcialidad = true;
        } else {
          todosTransitoParcialidad = false;
          break;
        }
      }
      if (todosTransitoParcialidad) {
        ordersList[i].statusCMM = "En proceso";
      }
    }

    setOrders(
      ordersList.sort(
        (a, b) => b.cf_compra_finalizada_id - a.cf_compra_finalizada_id
      )
    );
    setOrdersBackup(
      ordersList.sort(
        (a, b) => b.cf_compra_finalizada_id - a.cf_compra_finalizada_id
      )
    );
    setLoadingSkeleton(true);
  };

  useEffect(() => {
    if (props.orderId !== 0 && props.orderId !== undefined) {
      setShowOrderDetail(true);
      setSelectedOrderId(props.orderId);
    }
    if (!localStorage.getItem("user")) {
        //redirect to login
        history.push("/login");
    } else {
        setUser(JSON.parse(localStorage.getItem("user")).usuario);
    }

    if (localStorage.getItem("client")) {
        // getSaldoAndCredito();
        setClient(JSON.parse(localStorage.getItem("client")).listaSnbyid[0]);
    }
  }, []);

  useEffect(() => {
    // console.log('user ---> ', user, client);
    getOrders();
  }, [client]);

  const orderDetail = (orderId) => {
    //window.location.search = `?orderid=${orderId}`;

    setShowOrderDetail(true);
    setSelectedOrderId(orderId);
  };

  const handleChangeFilterStatus = (event) => {
    setFilterStatus(event.target.value);
    if (event.target.value === " ") {
      setOrders(ordersBackup);
    } else {
      setOrders(
        ordersBackup.filter(
          (or) => or.cf_estatus_orden === parseInt(event.target.value)
        )
      );
    }
  };

  return (
    <div className={classes.root}>
     
      

      {showOrderDetail ? (
        <Fragment>
          <OrderDetail
            selectedOrderId={selectedOrderId}
            dataBreadCrumb={dataBreadCrumb}
            setDataBreadCrumb={setDataBreadCrumb}
          />
          <Grid item xs={12} md={6}>
            <div className={classes.buttonBackDiv}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.buttonBack}
                onClick={() => {
                  setShowOrderDetail(false);
                  getOrders();
                }}
              >
                Atrás
              </Button>
            </div>
          </Grid>
        </Fragment>
      ) : (
        <Fragment>
          <div className={classes.spaceDiv}>
            <SectionBreadcrumb route={dataBreadCrumb} />
            <Typography className={classes.title}>Mis pedidos</Typography>
            {isMobile ? <Divider className={classes.titleDivider} /> : ""}
          </div>

          <Grid container spacing={0}>
            <Grid item xs={12} className={classes.filterGrid}>
            
              <FormControl variant="outlined" className={classes.orderByForm}>
                  <InputLabel id="orderByLab">Estatus</InputLabel>
                  <Select
                    id="orderBySel"
                    value={filterStatus}
                    onChange={handleChangeFilterStatus}
                    label="Estatus"
                    
                  >
                    <MenuItem value={" "}>Todos</MenuItem>
                    <MenuItem value={1000184}>En Proceso</MenuItem>
                    <MenuItem value={1000185}>Pendiente de Confirmar</MenuItem>
                    <MenuItem value={1000186}>Entregado</MenuItem>
                    <MenuItem value={1000187}>En tránsito</MenuItem>
                    <MenuItem value={1000108}>Cancelado</MenuItem>
                  </Select>
                </FormControl>



            </Grid> 

            {isMobile ? (
              <Grid item xs={12} className={classes.gridContent}>
                {loadingSkeleton ? orders.map((order, index) => {
                  return (
                    <div className={classes.formData} key={index}>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography
                                className={classes.formDataSubTitleDes}
                              >
                                Fecha
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography>{`${order.cf_compra_fecha}`}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography
                                className={classes.formDataSubTitleDes}
                              >
                                No. Pedido
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography>{`${order.cf_compra_numero_orden} `}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography
                                className={classes.formDataSubTitleDes}
                              >
                                Status
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography>{`${
                                order.cf_estatus_orden === 1000107
                                  ? "Pendiente"
                                  : ""
                              }${
                                order.cf_estatus_orden === 1000110
                                  ? "Cerrada"
                                  : ""
                              }${
                                order.cf_estatus_orden === 1000112
                                  ? "Aprobada"
                                  : ""
                              } `}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography
                                className={classes.formDataSubTitleDes}
                              >
                                Tipo Envío
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography>{`${order.tipo_envio_id.cmm_valor} `}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography
                                className={classes.formDataSubTitleDes}
                              >
                                Tipo de Pago
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              {order.tipo_compra_id != null ? (
                                <Typography>{`${order.tipo_compra_id.cmm_valor} `}</Typography>
                              ) : (
                                <Typography>-</Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography
                                className={classes.formDataSubTitleDes}
                              >
                                Total
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography>{`${getPriceSapFormat(
                                order.cf_total_compra
                              )} `}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Tooltip title="Detalle">
                            <IconButton
                              onClick={() =>
                                orderDetail(order.cf_compra_finalizada_id)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </div>
                  );
                }) 
               : (
                <Box sx={{ display: 'flex', justifyContent: 'center' }} pt={6} pb={6}>
                  <CircularProgress />
                </Box>
              )
              }
              </Grid>
            ) : (
              <Grid item xs={12} className={classes.gridContent}>
                <div className={classes.formData}>
                  <Grid container spacing={0} className={classes.tableHead}>
                    <Grid item xs={12} sm={2}>
                      Fecha
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={2}
                      style={{ display: "inline-table" }}
                    >
                      No. Pedido
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      Status
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      Tipo Envío
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      Total
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      Acciones
                    </Grid>
                  </Grid>
                  {loadingSkeleton ? orders.map((order, index) => {
                    return (
                      <Grid
                        container
                        spacing={0}
                        className={classes.tableRow}
                        key={index}
                      >
                        <Grid item xs={12} sm={2}>
                          <Typography
                            className={classes.tdText}
                          >{`${order.cf_compra_fecha} `}</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          style={{ display: "inline-table" }}
                        >
                          <Tooltip title={order.cf_compra_numero_orden}>
                            <Typography
                              className={classes.tdText}
                              style={{ marginRight: 5 }}
                            >
                              {shortWord(order.cf_compra_numero_orden, 9)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Typography className={classes.tdText}>
                            {order.statusCMM}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Typography
                            className={classes.tdText}
                          >{`${order.tipo_envio_id.cmm_valor} `}</Typography>
                        </Grid>
                        {/* <Grid item xs={12} sm={2}>
                          {
                            order.tipo_compra_id != null ?
                            <Typography className={classes.tdText}>{`${order.tipo_compra_id.cmm_valor} `}</Typography>
                            :
                            <Typography className={classes.tdText}>-</Typography>

                          }
                        </Grid> */}
                        <Grid item xs={12} sm={1}>
                          <Typography
                            className={classes.tdText}
                          >{`${getPriceSapFormat(
                            order.cf_total_compra
                          )} `}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={1} style={{ marginLeft: 15 }}>
                          <Tooltip title="Detalle">
                            <IconButton
                              onClick={() =>
                                orderDetail(order.cf_compra_finalizada_id)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    );
                  }) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }} pt={6} pb={6}>
                      <CircularProgress />
                    </Box>
                  )}
                </div>
              </Grid>
            )}
          </Grid>
        </Fragment>
      )}
    </div>
  );
}
