
import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ArrowRightAltRoundedIcon from "@material-ui/icons/ArrowRightAltRounded";
import { Link, Redirect, useHistory } from "react-router-dom";
import { homeServices } from "_services/home.services";
import { API_URL } from "../../_services/api.url.back.img";
import { checkoutService } from "_services/checkout.service";
import CartHeader from "views/Cart/CartHeader.js";
import LocalCartHeader from "views/Cart/LocalCartHeader.js";
import IcoLogo from 'assets/icons/LOGO_DIELSA.svg'
import { IconButton } from "@mui/material";

export const MenuMovil =(props) => {
    
    const history = useHistory();
    const [logo, setLogo] = useState("");
    const [cartView, setCartView] = useState(null);
    const [menuMobile, setMenuMobile] = useState(props.classes.hide);
    const [cateView, setCateView] = useState(props.classes.hide);
    const [cateView2, setCateView2] = useState(props.classes.hide);
    const [categorias, setCategorias] = useState([]);
    const [subcategorias, setSubcategorias] = useState([]);
    const [cateSel, setCateSel] = useState(0);
    const [searchWord, setSearchWord] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [dataBanner, setDataBanner] = useState("");
  
    const getDataBanner = async () => {
      let _d = await homeServices.getFranjaInformativa();
      setDataBanner(_d.controles_maestros[0].cmm_valor);
    };
  

  
 
    const closeCartDiv = () => {
      setCartView(null);
    };
    
  
  
  
  
  
    const handleCateView = () => {
      if (cateView === null) {
        setCateView(props.classes.hide);
      } else {
        getCateList();
        setCateView(null);
      }
    };
    const handleHideSubCat = (cat) =>{
     
      if (cat == cateSel) {
        //setCateView(classes.hide);
        setCateSel(0)
        //setCategorias([])
        //getCateList();
      // window.alert('hola')
      //window.alert('cierra modal a categoria:', cat)
      } else {
        setCateSel(cat);
      // window.alert('abre modal a categoria:', cat)
       // window.alert('hola 2')
      }
    }
  
    const getCateList = async () => {
      let _c = await homeServices.getListadoCategorias();
      setCategorias(_c.tree);
    };
  
    const showMenu = () => {
      setMenuMobile(null);
    };
    const closeMobDiv = () => {
        //toggleDrawer(anchor, false);
        setState({ top: false,
            left: false,
            bottom: false,
            right: false });
      setMenuMobile(props.classes.hide);
      setCateSel(0)
      getCateList();
      setCateView(props.classes.hide);
    };
  
    useEffect(() => {}, [menuMobile]);
  
  
  
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
 
  
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });
    
      const toggleDrawer = (anchor, open) => (event) => {

        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        closeMobDiv()
    setState({ ...state, [anchor]: open });
      };

      const list = (anchor) => (
        <Box
          sx={{ width: 310 }}
          role="presentation"
          //onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
          className={props.classes.menuMob }
        >
         <div >
          <div
            className={props.classes.closeDiv}
            onClick={() => {
              closeMobDiv();
            }}
          >
            <Typography className={props.classes.closeMenu}>Cerrar</Typography>
            <Typography className={props.classes.closeX}>X</Typography>
          </div>
          <div className={props.classes.imgLogoMenuDiv}>
            <img className={props.classes.imgLogoMenu} src={IcoLogo} />
          </div>
          <div className={props.classes.mainMenuDiv}>
            <div className={props.classes.innerMenuDiv} >
              <Typography className={props.classes.innerMenuTxt} >
                Productos
              </Typography>
              <ExpandMoreRoundedIcon className={cateView===null ? props.classes.innerMenuIcon2: props.classes.innerMenuIcon } onClick={handleCateView} />
              <div className={props.classes.subcategoriasDiv + " " + cateView}>
                {categorias.map((c) =>
                  c.hijos.length > 0 &&
                    c.cat_cmm_estatus_id == 1000010&& <div 
                      className={props.classes.cateHeaderMainDivSel}
                    >
                       <Link to={`/categorias/${c.cat_categoria_link}`} onClick={() => {closeMobDiv()}} > <Typography className={props.classes.cateHeaderMain}>
                        {c.cat_nombre_tienda}
                      </Typography></Link>
                      <Typography onClick={()=> {
                        //setSubcategorias(c.hijos);
                        handleHideSubCat(c.cat_categoria_id);
                        //setCateSel(c.cat_categoria_id);
                    }
                      }
                        className={ cateSel=== c.cat_categoria_id ? props.classes.cateHeaderMainArrow2: props.classes.cateHeaderMainArrow}>
                        &rang;
                      </Typography>

                      {  c.hijos.map((cc) =>
                      cc.cat_cmm_estatus_id == 1000010&& <div 
                      className={ cateSel=== c.cat_categoria_id ? props.classes.cateHeaderMainDivSel3 : props.classes.cateHeaderMainDivSel2}
                      >
                            <Link to={`/categorias/${cc.cat_categoria_link}`} onClick={() => {closeMobDiv();}}> <Typography className={props.classes.cateHeaderMain}>
                              {'-'+cc.cat_nombre_tienda}
                            </Typography> </Link>
                          
                          </div>
                          )
                        
                        }

                    </div>
                )}
             
              </div>
            </div>
            <div className={props.classes.innerMenuDiv}>
             <Link to='/institucional/marcas' onClick={() => {closeMobDiv();}}> <Typography className={props.classes.innerMenuTxt}>Marcas</Typography>
              <ArrowRightAltRoundedIcon className={props.classes.innerMenuIcon} /></Link>
            </div>
            {/* <div className={props.classes.innerMenuDiv}>
              <Typography className={props.classes.innerMenuTxt}>
                Promociones
              </Typography>
              <ArrowRightAltRoundedIcon className={props.classes.innerMenuIcon} />
            </div> */}
            <div className={props.classes.innerMenuDiv}>
            <a href="https://www.dielsablog.com/" target="_blank"  onClick={() => {closeMobDiv();}}> 
            <Typography className={props.classes.innerMenuTxt}>Blog</Typography>
              <ArrowRightAltRoundedIcon className={props.classes.innerMenuIcon} /> </a>
            </div>
            {/* <div className={props.classes.innerMenuDiv}>
              <Typography className={props.classes.innerMenuTxt}>Webinars</Typography>
            </div> */}
            <div className={props.classes.innerMenuDiv}>
            <Link to='/advancedSearchForm' onClick={() => {closeMobDiv();}}> <Typography className={props.classes.innerMenuTxt}>
                Búsqueda avanzada
              </Typography> 
              <ArrowRightAltRoundedIcon className={props.classes.innerMenuIcon} />
            </Link>
            </div>
            <div className={props.classes.innerMenuDiv}>
              <Link to='/quickShop' onClick={() => {closeMobDiv();}}> <Typography className={props.classes.innerMenuTxt}>
                  Compra rápida
                </Typography> 
                <ArrowRightAltRoundedIcon className={props.classes.innerMenuIcon} />
              </Link>
            </div>
          </div>
        </div>
        </Box>
      );


    return (
       
       
        <React.Fragment key={'left'}>
          <IconButton
            className={props.classes.menuDiv}  
            onClick={toggleDrawer('left', true)}
            sx={{ padding: '3px' }}
          >
            <MenuIcon className={props.classes.normalIcon} />
          </IconButton>
          <Drawer
            anchor={'left'}
            open={state['left']}
            onClose={toggleDrawer('left', false)}
            className={props.classes.root}
          >
            {list('left')}
          </Drawer>
        </React.Fragment>
       
      
    )


}