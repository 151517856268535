import React,{ useEffect, useState } from 'react'
import { AuthContext } from './AuthContext'
import PropTypes from 'prop-types'

export const AuthProvider = ({ children }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [typeAuth, setTypeAuth] = useState('signin') // signin, signup, forgetPassword, successSignup
  const tmpIsLogged = window.localStorage.getItem('user') !== null
  const [isLogged, setIsLogged] = useState(tmpIsLogged)
  const tmpIsDollar =  window.localStorage.getItem('isDollar') !==null

 // const rawListFavorites = window.localStorage.getItem('__listFavorites') || '[]'
 // const tmpListFavorites = JSON.parse(rawListFavorites)
  //const [listFavorites, setListFavorites] = useState(tmpListFavorites)
  const [isDollar,setIsDollar]= useState( tmpIsDollar? tmpIsDollar: false)
  const [search, setSearch]= useState('')
  const [onCloseCallback, setOnCloseCallback] = useState(null)
  const [dataQuote, setDataQuote]= useState({
    idCotizacion:0,
    idSocioNegocio:0,
    productos:[]
  })
  const [logoURL, setLogoURL] = useState('');
  const [showAlert, setShowAlert] = useState(false)
  const [openAlert, setOpenAlert] = useState({
    open:false, type:'',message:'' 
  })
  const [openAlertF, setOpenAlertF] = useState({
    open:false, type:'',message:'' 
  })
  const getUserData = () => {
    const rawData = window.localStorage.getItem('user') || '{}'
    const userData = JSON.parse(rawData)

    return userData
  }

  const getToken = () => {
    const rawData = window.localStorage.getItem('token') || '{}'
   // const userData = JSON.parse(rawData)

    return rawData
  }

  const setStorage = (key, data) => {
    window.localStorage.setItem(`_${key}`, JSON.stringify(data))
  }

  const getStorage = (key) => {
    const rawData = window.localStorage.getItem(`_${key}`) || '{}'

    const storageData = JSON.parse(rawData)
    return storageData
  }

  const logout = () => {
   // setListFavorites([])

    window.localStorage.removeItem('user')
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('myCart');
    window.localStorage.clear()

    setIsLogged(false)
  }

  const getUsername = () => {
    // eslint-disable-next-line camelcase
    const { first_name } = getUserData()

    // eslint-disable-next-line camelcase
    const userName = (first_name.trim().length > 0) ? first_name : 'Door'

    return userName
  }
   const [carritoItems,setCarritoItems] = useState(null)
  /*useEffect(() => {
    window.localStorage.setItem('__listFavorites', JSON.stringify(listFavorites))
  }, [listFavorites])*/

  const [isTransferPay, setIsTransferPay]= useState(false)
  const [isFormCompany, setIsFormCompany]= useState(false)
  const [isShippingHome,setIsShippingHome] = useState(false)
  const [isCreditDielsa, setIsCreditDielsa] = useState(false)
  let dataUser = getUserData()
  const getAccountIsActive = ()=>{
    let isActive = dataUser.usuario.snu_cardcode.includes('sc-') ? false : true 
    return isActive
  }
  const valuesProvider = {
    typeAuth,
    setTypeAuth,
    getAccountIsActive,
    isTransferPay, setIsTransferPay,
    showDialog,
    setShowDialog,
    isCreditDielsa, setIsCreditDielsa,
    isLogged,
    setIsLogged,
    isFormCompany, setIsFormCompany,
    isShippingHome,setIsShippingHome,
    openAlert,
    setOpenAlert,
    onCloseCallback,
    setOnCloseCallback,
    carritoItems, 
    setCarritoItems,
    getUserData,
    getToken,
    getUsername,
    openAlertF, setOpenAlertF,
    logout,
    isDollar, setIsDollar,
    setStorage,
    getStorage,
    dataQuote, setDataQuote,
    search,setSearch,
    logoURL, setLogoURL
  }

  return (
    <AuthContext.Provider value={valuesProvider}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.any
}

AuthProvider.defaultProps = {
  children: null
}
