import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  Fragment,
} from "react";
import { Link, Redirect } from "react-router-dom";
 
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { homeServices } from "../../_services/home.services";
import { API_URL } from "../../_services/api.url.back.img";

// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import "../../assets/custom/Login.css";
// @material-ui/
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

// core components
import useCheckMobileScreen from "components/Mobile/Mobile.js";
//se cambia logo por mayor resolucion
// import dielsaLogoObscuro from "assets/img/Dielsa/dielsa_fondos_obscuros.png";
//import dielsaLogoObscuro from "assets/img/Dielsa/logohd.png";
import dielsaLogoObscuro from "assets/logos/Logo_Dielsa_White_C.png";

const useStyles = makeStyles((theme) => ({
  mainBackground: {
    backgroundColor: "#FFF",
  },
  header: {
    backgroundColor: "#0B3196",
    height: 110,
    marginBottom: 50,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#FFFFFF",
      borderBottom: "1px solid #0B3196",
    },
  },
  logoDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
  },
  headerImgLogo: {
    maxHeight: 90,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      maxHeight: 70,
      maxWidth: 150,
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export default function CheckoutHeader(props) {
  const classes = useStyles();
  const { ...rest } = props;
  let history = useHistory();

  let isMobile = useCheckMobileScreen();

  const [logo, setLogo] = useState("");

  const getLogo = async () => {
    if (isMobile) {
      let _l = await homeServices.loadSection("LOGO_HOME");
      setLogo(
        API_URL + _l.archivos_main[0].adi_ruta_archivo.replace("./public", "")
      );
    } else {
      setLogo(dielsaLogoObscuro);
    }
  };

  useEffect(() => {
    getLogo();
  });

  return (
    <div className={classes.mainBackground}>
      <Grid container>
        <Grid item xs={12} className={classes.header}>
          <div className={classes.logoDiv}>
            <a href={`/`}>
              <img
                src={logo}
                className={classes.headerImgLogo}
              />
            </a>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
