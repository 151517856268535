import React, { createContext, useEffect, useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import useCheckMobileScreen from "components/Mobile/Mobile.js";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import SearchIcon from "@mui/icons-material/Search";
// @material-ui/icons
// core components

import { DropzoneDialog } from "material-ui-dropzone";

import "assets/custom/ProductInput.css";

import SectionSpaceTop from "views/Home/Sections/SectionSpaceTop.js";
import { searchServices } from "_services/search.services";
import { API_URL } from "_services/api.url.back.img";
import Loading from "../../utils/loading/Loading.js";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import { getPriceSapFormat } from "helpers/formatPrice.js";
import { checkoutService } from "_services/checkout.service";
import SectionBreadcrumb from "views/Home/Sections/SectionBreadcrumb.js";

import toast, { Toaster } from "react-hot-toast";
import Tooltip from "@material-ui/core/Tooltip";
import { useAuth } from "hooks/useAuth";
import { addCartBusinnesPartner } from "utils/addCartBusinnesPartner";
import { addCartLocal } from "utils/addCartLocal";
import { ButtonCategoriesMenu } from "components/index.js";

const useStyles = makeStyles((styles) => ({
  hide: {
    display: "none !important",
  },
  container: {
    width: "100%",
    backgroundColor: "#ffffff",
  },
  titleDiv: {
    width: "100%",
    textAlign: "center",
    marginTop: "12px",
    marginBottom: "25px",
  },
  title: {
    color: "#000",
    fontFamily: "'Centrale Sans Bold'",
    fontSize: "26px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "32px",
    textAlign: "center",
  },
  formDiv: {
    width: "calc(100% - 60px)",
    backgroundColor: "#F5F5F5",
    border: "1px solid #EAEAEA",
    marginLeft: "30px",
    marginRight: "30px",
    marginTop: "56px",
    marginBottom: "40px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "9px",
    display: "flex",
  },
  inputSearch: {
    width: "454px",
    display: "inline-block",
    verticalAlign: "top",
  },
  btnSearch: {
    "&:disabled": {
      backgroundColor: "#e3e3e3 !important",
    },
  },
  btnUpload: {
    backgroundColor: "#000",
    color: "#ffffff",
    marginLeft: "30px",
    display: "inline-block",
    verticalAlign: "top",
    height: 36.5,
  },
  btnDownload: {
    backgroundColor: "#F5F5F5",
    color: "#000",
    marginLeft: "30px",
    display: "inline-block",
    verticalAlign: "top",
    height: 36.5,
  },
  containerProducts: {
    marginBottom: "20px",
  },
  productGrid: {
    display: "flex",
    borderBottom: "1px solid #EAEAEA;",
  },
  gridHeader: {
    display: "flex",
    backgroundColor: "#0b3196",
    borderRadius: 2,
    height: 39,
  },
  textHeader: {
    color: "#ffffff",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "18px",
    fontWeight: "bold",
  },
  productItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textItem: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "18px",
    fontWeight: "bold",
  },
  productImg: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  cantidadInput: {
    height: "35px",
    width: "45px",
    border: "1px solid #F5F5F5",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "center",
  },
  cantidadBtnRestar: {
    all: "unset",
    height: "35px",
    width: "45px",
    transform: "scaleX(-1)",
    borderRadius: "0 3px 3px 0",
    backgroundColor: "#F5F5F5",
    cursor: "pointer",
    color: "#0b3196",
    fontFamily: "'Gotham Light', sans-serif",
    fontSize: "20px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#0b3196",
      color: "#ffffff",
    },
  },
  button: {
    ":disabled": {
      backgroundColor: "grey",
    },
  },
  cantidadBtnSumar: {
    all: "unset",
    height: "35px",
    width: "45px",
    transform: "scaleX(-1)",
    borderRadius: "3px 0px 0px 3px",
    backgroundColor: "#F5F5F5",
    cursor: "pointer",
    color: "#0b3196",
    fontFamily: "'Gotham Light', sans-serif",
    fontSize: "20px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#0b3196",
      color: "#ffffff",
    },
  },
  btnAdd: {
    height: 40,
    backgroundColor: "#ffffff",
    color: "#0C2D9C",
    border: "1px solid #0C2D9C",
    borderRadius: 3,
    fontSize: 14,
    width: "-webkit-fill-available",
    "&:hover": {
      backgroundColor: "#0C2D9C",
      color: "#fff",
    },
    textTransform: "none",
    boxShadow: "none",
  },
  addToCartBtn: {
    all: "unset",
    height: "35px",
    width: "155px",
    border: "2px solid #0b3196",
    borderRadius: "3px",
    cursor: "pointer",
    color: "#0b3196",
    fontFamily: "'Gotham Light', sans-serif",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
  },
  boxPaginadoBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "25px",
    paddingBottom: "10px",
    "& button": {
      all: "unset",
      margin: "0",
      borderRadius: "7px",
      cursor: "pointer",
      height: "45px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0",
      fontSize: "16px",
      width: "261px",
      border: "1px solid #003773",
      color: "#003773",
      fontFamily: "'Centrale Sans Medium'",
      "&:hover": {
        backgroundColor: "#003773",
        color: "#ffffff",
      },
    },
  },
  cardStyle: {
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 2,
    marginRight: 2,
    borderRadius: 8,
    minWidth: 465,
    maxWidth: 465,
    [styles.breakpoints.down("xs")]: {
      minWidth: 325,
      maxWidth: 325,
    },
  },
}));

export default function QuickShop(props) {
  const {
    isLogged,
    getUserData,
    setOpenAlertF,
    setCarritoItems,
    carritoItems,
    isDollar,
    getAccountIsActive,
  } = useAuth();
  const dataUser = getUserData();
  const isActive = isLogged ? getAccountIsActive() : false;
  const classes = useStyles();
  const history = useHistory();

  const { ...rest } = props;
  let isMobile = useCheckMobileScreen();
  const [dataBanner, setDataBanner] = useState("");
  const [openXLSX, setOpenXLSX] = useState(false);
  const [filesXLSX, setFilesXLSX] = useState([]);

  const [open, setOpen] = useState(false);

  // development
  // variables para busqueda de productos y paginado
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState("fecha lanzamienta");
  const [orderType, setOrderType] = useState("asc");
  const [orderBy, setOrderBy] = useState("fecha lanzamienta.asc");
  const [atributos, setAtributos] = useState([]);

  const [skus, setSkus] = useState([]);
  const [totalPaginado, setTotalPaginado] = useState(0);
  const [palabraBuscar, setPalabraBuscar] = useState(null);
  const [userIsLogged, setUserIsLogged] = useState(false);

  const [totalSkus, setTotalSkus] = useState(0);
  const [orderQuantity, setOrderQuantity] = useState(12);
  const [partidas, setPartidas] = useState(null);
  const [skusToRender, setSkusToRender] = useState([]);

  const [esPartida, setEsPartida] = useState(true);
  const [tipoDePaginado, setTipoDePaginado] = useState(null);
  const [contadorPartidas, setContadorPartidas] = useState(0);
  const [contadorPaginas, setContadorPaginas] = useState(0);
  const [paginado, setPaginado] = useState(0);
  const [stringBuscar, setStringBuscar] = useState("");
  const [productosNoEncontrados, setProductosNoEncontrados] = useState();

  const [qtyLcoalCart, setQtyLocalCart] = useState(1);
  const [dataBreadCrumb, setDataBreadCrumb] = useState([]);
  const [qshopDivisa, setQshopDivisa] = useState("mxn");

  useEffect(() => {
    window.scrollTo(0, 0);

    setDataBreadCrumb(() => [
      {
        name: "INICIO",
        link: "/",
      },
      {
        name: "COMPRA RÁPIDA",
        link: "",
      },
    ]);
  }, []);

  useEffect(() => {
    setQshopDivisa(props.divisa);
  }, [props.divisa]);

  const buscarProductos = async (searchValue, pagina, esPrimerPagina) => {
    setLoading(true);
    let skusArray = [];
    let idSocioNegocio = process.env.REACT_APP_API_USER_B2B;
    const clientData = JSON.parse(localStorage.getItem("client"));
    if (clientData != null) {
      idSocioNegocio = clientData.listaSnbyid[0].sn_socios_negocio_id;
      setUserIsLogged(true);
    } else {
      setUserIsLogged(false);
    }

    if (pagina === 0) {
      skusArray = [];
      setPaginado(0);
      //setProductosNoEncontrados(true);
    } else {
      skusArray = skus;
      setProductosNoEncontrados(false);
    }
    try {
      const buscarProductosRes = await searchServices.searchFilters(
        pagina,
        20,
        searchValue,
        order,
        orderType,
        idSocioNegocio
      );

      const productos = buscarProductosRes.mainConsultaProductos.rows;
      const countProductos = buscarProductosRes.mainConsultaProductos.count;
      const calcularPaginado = Math.ceil(countProductos / orderQuantity);
      setTotalPaginado(calcularPaginado);
      productos.forEach((sku) => {
        let urlImagen =
          "http://wws.com.pa/wp-content/plugins/wordpress-ecommerce/marketpress-includes/images/default-product.png";
        if (sku.imagen_productos.length) {
          let _skuimg = sku.imagen_productos;
          _skuimg.sort(function (a, b) {
            return a.imgprod_nombre_archivo.localeCompare(
              b.imgprod_nombre_archivo
            );
          });

          if (
            _skuimg[0].imgprod_ruta_archivo.includes("https://ftp3.syscom.mx")
          ) {
            urlImagen = _skuimg[0].imgprod_ruta_archivo;
          } else {
            urlImagen = `${API_URL}${
              _skuimg[0].imgprod_ruta_archivo.split("public")[1]
            }`;
          }
        }
        let descuento = 0;
        if (sku.precioFinal < sku.precioBasePorListaPrecio) {
          descuento = sku.precioBasePorListaPrecio - sku.precioFinal;
        }
        const skuInfo = {
          productoId: sku.prod_producto_id,
          imagen: urlImagen,
          nombre: sku.prod_nombre.toLowerCase(),
          sku: sku.prod_sku,
          nombre_extranjero: sku.prod_nombre_extranjero,
          stock: sku.prod_total_stock,
          precioLista: sku.prod_precio,
          descuento: descuento,
          precioFinal: sku.precioMenosDescuento,
          precioListaDls: sku.precioFinaldls,
          precioFinalDls: sku.precioMenosDescuentodls,
          skuId: sku.prod_producto_id,
          prod_tipo_precio_base: sku.prod_tipo_precio_base,
          precioPromocionDielsaBool: sku.precioPromocionDielsaBool,
          tipoPromocionFinal: sku.tipoPromocionFinal,
          precioMenosDescuento: sku.precioMenosDescuento,
          DescuentoDielsaFijo: sku.DescuentoDielsaFijo,
          brand: sku.mar_nombre,
          bullets: sku.prod_viñetas,
          backorder: sku.aplicaBackOrder,
          prod_altura: sku.prod_altura,
          prod_ancho: sku.prod_ancho,
          prod_longitud: sku.prod_longitud,
          prod_peso: sku.prod_peso,
          prod_volumen: sku.prod_volumen,
          precio_dolar: sku.priceProductInDollar,
        };
        skusArray.push(skuInfo);
      });
      setSkus(skusArray);
      setTotalSkus(countProductos);
      if (countProductos > orderQuantity) {
        if (esPrimerPagina) {
          getPrimerPaginaDeProductos(skusArray);
        } else {
          getSiguientePaginaDeProductos(skusArray);
        }
      } else {
        setSkusToRender(skusArray);
        setTipoDePaginado(null);
      }

      setLoading(false);
      if (skusArray.length == 0) {
        setProductosNoEncontrados(true);
      }
    } catch (error) {
      setLoading(true);
      // setAlerta({'active': true, 'type': 'warning', 'message': 'Error al buscar productos'})
      setProductosNoEncontrados(true);
      setSkus([]);
    }
  };

  const getPrimerPaginaDeProductos = (skusArray) => {
    const getPartidas = Math.floor(skusArray.length / orderQuantity);
    setPartidas(getPartidas);
    setContadorPartidas(1);
    setContadorPaginas(1);
    setTipoDePaginado("partida");
    const getSkus = [];
    for (let i = 0; i < orderQuantity; i++) {
      const item = skusArray[i];
      getSkus.push(item);
    }

    setSkusToRender(getSkus);
  };

  const getSiguientePaginaDeProductos = (skusArray) => {
    const getPartidas = Math.floor(skusArray.length / orderQuantity);
    setPartidas(getPartidas);
    setTipoDePaginado("partida");
    const skusToIterate = skusToRender.length + orderQuantity;
    const tempSkus = [];
    for (let i = 0; i < skusToIterate; i++) {
      const item = skus[i];
      tempSkus.push(item);
    }
    setSkusToRender(tempSkus);
  };

  const getProductosPartidaSiguiente = () => {
    let tempContadorPartidas = contadorPartidas;
    tempContadorPartidas++;
    setContadorPartidas(tempContadorPartidas);

    const skusArray = [];
    const skusToIterate = skusToRender.length + orderQuantity;
    if (tempContadorPartidas <= partidas) {
      for (let i = 0; i < skusToIterate; i++) {
        const item = skus[i];
        skusArray.push(item);
      }
      setSkusToRender(skusArray);
    } else {
      if (contadorPaginas < totalPaginado) {
        buscarProductosPaginaSiguiente();
      } else {
        const tempSkus = [];
        for (let i = 0; i < skus.length; i++) {
          const item = skus[i];
          tempSkus.push(item);
        }
        setSkusToRender(tempSkus);
        setTipoDePaginado(null);
      }
    }
  };

  const buscarProductosPaginaSiguiente = () => {
    let tempContadorPaginas = contadorPaginas;
    buscarProductos(palabraBuscar, tempContadorPaginas, false);
    tempContadorPaginas++;
    setContadorPaginas(tempContadorPaginas);
  };

  const handleOpenXLSX = () => {
    setOpenXLSX(true);
    setFilesXLSX([]);
  };

  const handleCloseXLSX = () => {
    setOpenXLSX(false);
    setFilesXLSX([]);
  };

  const handleSaveXLSX = async (files) => {
    setLoading(true);
    const formData = new FormData();

    formData.append("excel", files[0]);
    formData.append(
      "cdc_sn_socio_de_negocio_id",
      JSON.parse(localStorage.getItem("client")).listaSnbyid[0]
        .sn_socios_negocio_id
    );
    const load_attributes_request = await searchServices.loadCargaMasiva(
      formData,
      JSON.parse(localStorage.getItem("client")).listaSnbyid[0]
        .sn_socios_negocio_id,
      JSON.parse(localStorage.getItem("user")).usuario.token
    );

    setOpenAlertF({
      open: true,
      message: `Se agregaron ${load_attributes_request.cantidadAgregados} productos a tu carrito`,
      type: "success",
    });

    if (load_attributes_request.cantidadNoAgregados > 0) {
      let txt_qty =
        load_attributes_request.cantidadNoAgregados > 1 ? "skus" : "sku";

      const listSkus = load_attributes_request.NoagregadosJson.map(
        (item) => " " + item.SKU + " - " + item.Motivo + "\n"
      );
      toast.error(
        `Error al agregar ${load_attributes_request.cantidadNoAgregados} ${txt_qty} al carrrito:\n ${listSkus}`
      );
    }
    setLoading(false);
    setOpenAlertF({
      open: true,
      message: load_attributes_request.message,
      type: "success",
    });
    setOpen(true);

    //Saving files to state for further use and closing Modal.
    setFilesXLSX(files);
    setOpenXLSX(false);
  };

  const busquedaDeProductos = (event) => {
    event.preventDefault();
    const buscarValue = event.target.buscarInput.value;
    setPalabraBuscar(buscarValue);
    buscarProductos(buscarValue, 0, true);
  };

  const handleCantidad = (productId, action) => {
    let qtyValue = document.getElementById(`qty_${productId}`).value;
    if (action === "sumar") {
      qtyValue++;
      document.getElementById(`qty_${productId}`).value = qtyValue;
      if (!userIsLogged) {
        setQtyLocalCart(qtyValue);
      }
    } else if (action === "restar") {
      if (qtyValue > 1) {
        qtyValue--;
        document.getElementById(`qty_${productId}`).value = qtyValue;
        if (!userIsLogged) {
          setQtyLocalCart(qtyValue);
        }
      }
    }
  };

  const handleCantidadFromKeyboard = (productId) => {
    let qtyValue = document.getElementById(`qty_${productId}`).value;
    document.getElementById(`qty_${productId}`).value = qtyValue;
    if (!userIsLogged) {
      setQtyLocalCart(qtyValue);
    }
  };

  const addToCart = (idProduct, prod) => {
    if (isLogged) {
      const cantidad = document.getElementById(`qty_${idProduct}`).value;

      addCartBusinnesPartner(
        cantidad,
        prod,
        dataUser.usuario.snu_sn_socio_de_negocio_id,
        idProduct
      ).then(({ message, type, isAmount }) => {
        if (!isAmount) {
          setCarritoItems(carritoItems + 1);
        }
        setOpenAlertF({ open: true, type: type, message: message });
      });
    } else {
      const skuData = {
        nombre: prod.nombre,
        nombre_extranjero: prod.nombre_extranjero,
        stock: prod.stock,
        imagen: prod.imagen,
        sku: prod.sku,
        skuId: prod.id,
      };
      setOpenAlertF({ open: false, type: "", message: "" });
      addCartLocal(qtyLcoalCart, skuData).then(
        ({ message, type, isAmount }) => {
          if (!isAmount) {
            setCarritoItems(carritoItems + 1);
          }
          setOpenAlertF({ open: true, type: type, message: message });
        }
      );
      //
    }
  };


  const handleLength = (e) => {
    const validarEspacio = e.target.value;
    const auxArray = Array.from(validarEspacio);
    if (auxArray[0] === " ") {
    } else {
      setStringBuscar(e.target.value);
    }
  };

  const validPlantillaSn = () => {
    const usrLogged = JSON.parse(localStorage.getItem("client"));
    if (usrLogged != null) {
      return (
        <Box display="flex">
          <a
            href={`${process.env.REACT_APP_API_URL_IMG}Template_Carga_Quick_Shop.xlsx`}
            download
          >
            <Tooltip
              title="Descargar plantilla de compra rápida"
              aria-label="Descargar"
              placement="top"
            >
              <Button variant="outlined">Plantilla de compra rápida</Button>
            </Tooltip>
          </a>

          <Tooltip
            title="Subir plantilla de compra rápida para agregar productos al carrito"
            aria-label="Cargar"
            placement="top"
          >
            <Button
              className={classes.btnUpload}
              onClick={() => {
                handleOpenXLSX();
              }}
              variant="outlined"
            >
              Subir plantilla
            </Button>
          </Tooltip>
        </Box>
      );
    } else {
      return "";
    }
  };

  const renderSkusList = skus.map((item, index) => {
    return (
      <Grid container className={classes.productGrid} key={index}>
        <Grid item xs={1} className={classes.productItem}>
          <img src={item.imagen} className={classes.productImg} />
        </Grid>
        <Grid item xs={3} className={classes.productItem}>
          <span className={classes.textItem}>{item.nombre}</span>
        </Grid>
        <Grid item xs={2} className={classes.productItem}>
          <span className={classes.textItem}>{item.nombre_extranjero}</span>
        </Grid>
        <Grid
          item
          xs={1}
          className={classes.productItem + " " + classes.flexCenter}
        >
          <span className={classes.textItem}>{item.stock}</span>
        </Grid>

        <Grid
          item
          xs={1}
          className={classes.productItem + " " + classes.flexCenter}
        >
          {!isDollar ? (
            <span className={classes.textItem}>
              {getPriceSapFormat(item.precioFinal)}
            </span>
          ) : (
            <span className={classes.textItem}>
              {getPriceSapFormat(item.precioFinalDls)}
            </span>
          )}
        </Grid>

        <Grid
          item
          xs={2}
          className={classes.productItem + " " + classes.flexCenter}
        >
          <button
            className={classes.cantidadBtnRestar}
            onClick={() => {
              handleCantidad(item.skuId, "restar");
            }}
          >
            -
          </button>
          <input
            className={classes.cantidadInput}
            defaultValue="1"
            id={`qty_${item.skuId}`}
            type="text"
            name="cantidad"
            onChange={() => {
              handleCantidadFromKeyboard(item.skuId);
            }}
          />
          <button
            className={classes.cantidadBtnSumar}
            onClick={() => {
              handleCantidad(item.skuId, "sumar");
            }}
          >
            +
          </button>
        </Grid>
        <Grid
          item
          xs={2}
          className={classes.productItem + " " + classes.flexCenter}
        >
          {isLogged ? (
            isActive?
            <Button
              className={classes.btnAdd}
              variant="contained"
              onClick={() => {
                addToCart(item.skuId, item);
              }}
            >
              {item.prod_peso == 0 ||
              item.prod_volumen == 0 ||
              item.precioFinal == 0 ||
              (item.stock == 0 && item.backorder === false)
                ? "Añadir a cotización"
                : "Añadir al carrito"}
            </Button> :""
          ) : (
            <Button
              className={classes.btnAdd}
              variant="contained"
              onClick={() => {
                addToCart(item.skuId, item);
              }}
            >
              {"Añadir a cotización"}
            </Button>
          )}
        </Grid>
      </Grid>
    );
  });

  const renderSkusListMobile = skus.map((item, index) => {
    return (
      <Grid container key={index} style={{ justifyContent: "center" }}>
        <Card className={classes.cardStyle}>
          <CardContent>
            <Box sx={{ display: "flex" }}>
              <img
                src={item.imagen}
                height={110}
                style={{ minWidth: "110px" }}
              />
              <Box>
                <Typography>{item.nombre}</Typography>
                <Typography>{`SKU: ${item.nombre_extranjero}`}</Typography>
                <Typography>
                  <span
                    className={classes.textItem}
                    style={{ color: "#0b3196" }}
                  >
                    {`En stock (${item.stock})`}
                  </span>
                </Typography>

                <Grid
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginLeft: "-35px",
                  }}
                >
                  <Grid
                    item
                    xs={2}
                    className={classes.productItem + " " + classes.flexCenter}
                  >
                    <button
                      className={classes.cantidadBtnRestar}
                      onClick={() => {
                        handleCantidad(item.skuId, "restar");
                      }}
                      style={{ paddingLeft: "8px", paddingRight: "8px" }}
                    >
                      -
                    </button>
                    <input
                      className={classes.cantidadInput}
                      defaultValue="1"
                      id={`qty_${item.skuId}`}
                      type="text"
                      name="cantidad"
                      onChange={() => {
                        handleCantidadFromKeyboard(item.skuId);
                      }}
                    />
                    <button
                      className={classes.cantidadBtnSumar}
                      onClick={() => {
                        handleCantidad(item.skuId, "sumar");
                      }}
                      style={{ paddingLeft: "8px", paddingRight: "8px" }}
                    >
                      +
                    </button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>
          <Divider style={{ marginLeft: 4, marginRight: 4 }} />
          <CardActions>
            <Grid container>
              <Grid
                item
                xs={6}
                className={classes.productItem + " " + classes.flexCenter}
                style={{ display: "flex" }}
              >
                <span
                  style={{ marginLeft: "2rem" }}
                  className={classes.textItem}
                >
                  {getPriceSapFormat(
                    isDollar ? item.precioFinalDls : item.precioFinal
                  )}
                </span>
                <Divider
                  style={{ marginLeft: "2rem" }}
                  orientation="vertical"
                  flexItem
                />
              </Grid>

              <Grid item xs={6}>
                <Button
                  className={classes.btnAdd}
                  variant="contained"
                  onClick={() => {
                    addToCart(item.skuId, item);
                  }}
                >
                  {isLogged
                    ? item.prod_peso == 0 ||
                      item.prod_volumen == 0 ||
                      item.precioFinal == 0 ||
                      (item.stock == 0 && item.backorder === false)
                      ? "Añadir a cotización"
                      : "Añadir al carrito"
                    : "Añadir a cotización"}
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    );
  });

  return (
    <div className={classes.mainBackground}>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          className: "",
          style: {
            fontWeight: "bold",
            color: "#000",
          },
        }}
      />
      <Loading loading={loading} />
      <SectionSpaceTop />

      <div
        className={classes.container}
        style={{
          paddingTop: 0,
          marginBottom: "1rem",
        }}
      >
        <Container fixed paddingBottom={2}>
          <div className={classes.titleDiv}>
            <div
              style={{
                paddingTop: isMobile ? 15 : 5,
                paddingBottom: isMobile ? 15 : 5,
                display: "flex",
                alignItems: "center",
              }}
            >
              {!isMobile && <ButtonCategoriesMenu />}
              <SectionBreadcrumb route={dataBreadCrumb} />
            </div>
            <Typography className={classes.title}>Compra rápida</Typography>
          </div>
          <Grid
            container
            spacing={2}
            style={{
              backgroundColor: isMobile ? "#fff" : "#f4f4f4",
              borderRadius: 8,
            }}
          >
            <Grid Grid item md={6} xs={12}>
              <form
                onSubmit={(event) => {
                  busquedaDeProductos(event);
                }}
                style={{ display: "flex", margin: 1 }}
              >
                <TextField
                  name="buscarInput"
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={(e) => handleLength(e)}
                />
                <Button
                  disabled={!stringBuscar.length > 0}
                  type="submit"
                  className={classes.btnSearch}
                  variant="contained"
                  style={{
                    marginLeft: 6,
                    backgroundColor: isMobile ? "#0b3196" : "#40df00",
                    color: "#fff",
                  }}
                >
                  {isMobile ? <SearchIcon /> : "Buscar"}
                </Button>
              </form>
            </Grid>
            <Grid Grid item md={6} xs={12}>
              {!isMobile && validPlantillaSn()}
              <DropzoneDialog
                open={openXLSX}
                onSave={(files) => handleSaveXLSX(files)}
                dropzoneText={"Arrastre y suelte un archivo XLSX"}
                acceptedFiles={[".xlsx"]}
                filesLimit={1}
                showPreviews={true}
                showFileNames={true}
                maxFileSize={103857600}
                onClose={() => handleCloseXLSX(this)}
              />
            </Grid>
          </Grid>
          {isMobile && <Divider style={{ paddingBottom: "6px" }} />}
        </Container>
      </div>
      {skus.length > 0 ? (
        <Container fixed className={classes.containerProducts}>
          {!isMobile && (
            <Grid container className={classes.gridHeader}>
              <Grid
                item
                xs={1}
                className={classes.productItem + " " + classes.flexCenter}
              >
                <span className={classes.textHeader}>Imagen</span>
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.productItem + " " + classes.flexCenter}
              >
                <span className={classes.textHeader}>Nombre</span>
              </Grid>
              <Grid
                item
                xs={2}
                className={classes.productItem + " " + classes.flexCenter}
              >
                <span className={classes.textHeader}>SKU</span>
              </Grid>
              <Grid
                item
                xs={1}
                className={classes.productItem + " " + classes.flexCenter}
              >
                <span className={classes.textHeader}>Cant.</span>
              </Grid>

              <Grid
                item
                xs={1}
                className={classes.productItem + " " + classes.flexCenter}
              >
                {!isDollar ? (
                  <span className={classes.textHeader}>Precio MXN</span>
                ) : (
                  <span className={classes.textHeader}>Precio DLS</span>
                )}
              </Grid>

              <Grid
                item
                xs={2}
                className={classes.productItem + " " + classes.flexCenter}
              >
                <span className={classes.textHeader}>Cantidad</span>
              </Grid>
              <Grid
                item
                xs={2}
                className={classes.productItem + " " + classes.flexCenter}
              >
                <span className={classes.textHeader}>Acción</span>
              </Grid>
            </Grid>
          )}
          {!isMobile && renderSkusList}
          {isMobile && renderSkusListMobile}
        </Container>
      ) : (
        <Container
          fixed
          className={classes.containerProducts}
          style={{ textAlign: "center", marginBottom: 50, marginTop: 50 }}
        >
          <h3>
            {productosNoEncontrados && skus.length == 0
              ? "Disculpa, no encontramos resultados con tus criterios de búsqueda"
              : ""}{" "}
          </h3>
        </Container>
      )}

      {tipoDePaginado === "partida" && (
        <div className={classes.boxPaginadoBtn}>
          <button
            onClick={() => {
              getProductosPartidaSiguiente();
            }}
          >
            Ver más productos
          </button>
        </div>
      )}
    </div>
  );
}
