import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Avatar, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TextField, IconButton, Autocomplete, Button, Box, FormControl, RadioGroup, FormControlLabel, ListItem, Radio, ListItemIcon, InputLabel, Select, MenuItem } from "@mui/material";
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import HouseIcon from '@mui/icons-material/House';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import useCheckMobileScreen from "components/Mobile/Mobile";
import SectionBreadcrumb from "views/Home/Sections/SectionBreadcrumb.js";
import { API_URL } from "_services/api.url.back";
import { userService } from "_services/user.service";
import { checkoutService } from "_services/checkout.service";
import { valideProductsBuy } from "utils/valideProductsBuy";
import Loading from "utils/loading/Loading";
import { useAuth } from "hooks/useAuth";
import { addItemCartByIdProduct } from "utils/addCartBusinnesPartner";
import { ModalSearchAndAddProduct, ModalWarning } from "components";
import { getPriceSapFormat } from "helpers/formatPrice";
const useStyles = makeStyles((theme) => ({
  hide: {
    display: "none !important",
  },
  //alerts
  alerts: {
    color: "#FFFFFF",
    backgroundColor: "#40DF00",
    fontFamily: "'Centrale Sans Light'",
    fontSize: 22,
    "& .MuiAlert-icon": {
      color: "#FFFFFF",
    },
    "&.MuiAlert-standardError": {
      backgroundColor: "#DF2B2B",
    },
  },
  imageProd:{
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    justifyContent: "center",
  },
  root: {
    backgroundColor: "#FFFFFF",
    margin: "auto",
    width: "95%",
    paddingBottom: 30,
  },
  spaceDiv: {
    marginTop: 15,
    marginBottom: 52,
  },
  linkBack: {
    fontFamily: "'Centrale Sans Medium'",
    color: "#0B3196",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "19px",
    textAlign: "center",
    paddingLeft: "30px",
    position: "relative",
  },
  linkBackArrow: {
    position: "absolute",
    left: "5px",
    bottom: "-2px",
  },
  title: {
    fontFamily: "'Centrale Sans Bold'",
    textTransform: "uppercase",
    fontSize: 26,
    lineHeight: "32px",
    textAlign: "justify",
    color: "#0C2D9C",
    paddingTop: "39.8px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  titleDivider: {
    border: "1px solid #0B3196",
    width: "95%",
    margin: "auto",
  },
  gridContent: {
    paddingBottom: 30,
  },
  buttonUpdate: {
    border: "1px solid",
    color: "#0C2D9C",
    backgroundColor: "transparent",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  buttonUpdateSecond: {
    border: "1px solid",
    backgroundColor: "#0C2D9C",
    color: "#ffffff",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  inputs: {
    width: "100%",
    border: "1px solid #EAEAEA",
    borderRadius: "2px",
    backgroundColor: "#FFFFFF",
  },
  // Search
  searchDiv: {
    width: "100%",
    backgroundColor: "#F5F5F5",
    border: "1px solid #EAEAEA",
    borderRadius: "2px",
    marginBottom: "39px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "6px",
  },
  margin: {
    paddingLeft: "6px",
    paddingRight: "13px",
  },
  inlineDiv: {
    display: "inline-block",
    verticalAlign: "middle",
    [theme.breakpoints.down("sm")]:{
      marginTop: "15px",
    }
  },
  textField: {
    display: "inline-block",
    paddingLeft: "6px",
    paddingRight: "13px",
    [theme.breakpoints.down("sm")]:{
      marginTop: "15px",
    }
  },
  textField2: {
   
    paddingLeft: "6px",
    paddingRight: "13px",
    [theme.breakpoints.down("sm")]:{
      marginTop: "15px",
    }
  },
  btnSearch: {
    height: "44.8px",
    width: "120px",
    borderRadius: "2px",
    backgroundColor: "#40DF00",
    paddingTop: "12px",
    paddingLeft: "35px",
    paddingBottom: "9px",
    paddingRight: "34px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  btnSearchLbl: {
    color: "#FFFFFF",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "19px",
  },
  // Table
  tableHeader: {
    backgroundColor: "#0C2D9C",
    borderRadius: "2px",
    height: "51px",
  },
  tableHeaderTxt: {
    color: "#fff !important",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "18px",
    lineHeight: "23px",
    fontWeght: "500",
  },
  filterSelect: {
    color: "#0C2D9C",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "19px",
    marginLeft: "6px",
  },
  rowDataProd:{
    color: "#000000",
    fontFamily: "'Centrale Sans Regular'",
    fontSize: "14px",
    letterSpacing: "0",
    lineHeight: "23px",
    width: '11%',
  },

  dataCenter:{
    textAlign:"center"
  },
  rowData: {
    color: "#000000",
    fontFamily: "'Centrale Sans Regular'",
    fontSize: "18px",
    letterSpacing: "0",
    lineHeight: "23px",
  },
  iconAction: {
    width: "36px",
    marginRight: "5px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  iconActionImg:{
    width: "36px",
    marginRight: "5px",
    marginBottom: "18px !important",
    "&:hover": {
      cursor: "pointer",
    },
  },
  iconDeleteAction: {
    width: "17px",
    height: "22px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
  },
  modalHeader: {
    width: "540px",
    backgroundColor: "#EF0002",
    textAlign: "center",
    paddingTop: "24.8px",
    paddingBottom: "16px",
  },
  modalHeaderTxt: {
    color: "#FFFFFF",
    fontFamily: "'Centrale Sans Bold'",
    fontSize: "26px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "32px",
  },
  modalBody: {
    marginTop: "53.2px",
    marginBottom: "78px",
  },
  modalBodyTxt: {
    color: "#DF2B2B",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "24px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "29px",
    textAlign: "center",
  },
  modalBtns: {
    marginLeft: "65px",
    marginBottom: "22px",
  },
  modalBtn: {
    boxSizing: "border-box",
    height: "28px",
    width: "204px",
    border: "1px solid #0C2D9C",
    borderRadius: "2px",
    textAlign: "center",
    color: "#0C2D9C",
    marginRight: "7px",
    display: "inline-block",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#0C2D9C",
      color: "#FFFFFF",
    },
  },
  modalBtnTxt: {
    fontFamily: "'Centrale Sans Regular'",
    fontSize: "14px",
    letterSpacing: "0",
    lineHeight: "18px",
    marginTop: "4px",
  },
  /// Detalles
  titleDiv: {
    display: "block",
  },
  mainDataDiv: {
    backgroundColor: "#F2F2F2",
    paddingTop: "27.6px",
    paddingLeft: "18px",
    paddingRight: "6px",
    paddingBottom: "22px",
  },
  cotDetalleTitle: {
    color: "#0C2D9C",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "22px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "27px",
    display: "inline-block",
  },
  iconActionEdit: {
    height: "24px",
    width: "28px",
    float: "right",
    display: "inline-block",
    "&:hover": {
      cursor: "pointer",
    },
  },
  cotDetailsDataDiv: {
    paddingLeft: "2px",
    marginTop: "17px",
    width: "100%",
  },
  cotDetailsDataRow: {
    display: "block",
    marginBottom: "12px",
  },
  cotDetailsDataSmall: {
    display: "inline-block",
    width: "50%",
    verticalAlign: "top",
  },
  cotDetalleDataTitle: {
    color: "#444444",
    fontFamily: "'Centrale Sans Bold'",
    fontSize: "18px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "23px",
    display: "inline-block",
  },
  cotDetalleDataTxt: {
    color: "#000000",
    fontFamily: "'Centrale Sans Regular'",
    fontSize: "16px",
    letterSpacing: "0",
    lineHeight: "19px",
    display: "inline-block",
    marginLeft: "110px",
  },
  cotDetallePersonalTitle: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "16px",
    display: "block",
  },
  cotDetallePersonalData: {
    color: "#000000",
    fontFamily: "'Centrale Sans Regular'",
    fontSize: "16px",
    letterSpacing: "0",
    lineHeight: "20px",
    display: "block",
  },
  divTableProducts: {
    marginTop: "20px",
    marginBottom: "36px",
    backgroundColor: "#F5F5F5",
  },
  divSubtotals: {
    width: "445px",
    border: "1px solid #EAEAEA",
    backgroundColor: "#F5F5F5",
    paddingTop: "12.8px",
    paddingLeft: "18px",
    paddingRight: "24px",
    paddingBottom: "13px",
    float: "right",
    marginBottom: "41px",
    [theme.breakpoints.down("sm")]:{
      width: "325px"
    } 
  },
  divSumatorias: {
    display: "block",
    width: "100%",
    minHeight: "275px",
  },
  divSubtotalesRow: {
    display: "block",
    marginBottom: "17px",
  },
  divLineTotal: {
    height: "1px",
    width: "100%",
    border: "1px solid #EAEAEA",
    marginTop: "-20px",
    marginBottom: "12px",
  },
  subtotalTitle: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "16px",
    display: "inline-block",
    verticalAlign: "top",
  },
  subtotalData: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "16px",
    display: "inline-block",
    textAlign: "right",
    float: "right",
    verticalAlign: "top",
  },
  totalTitle: {
    color: "#000000",
    fontFamily: "'Centrale Sans Bold'",
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "16px",
    verticalAlign: "top",
    display: "inline-block",
  },
  totalData: {
    color: "#000000",
    fontFamily: "'Centrale Sans Bold'",
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "16px",
    verticalAlign: "top",
    display: "inline-block",
    textAlign: "right",
    float: "right",
  },
  btnFinalizarCompra: {
    borderRadius: "3px",
    backgroundColor: "#0C2D9C",
    color: "#FFFFFF",
    float: "right",
    paddingTop: "11.8px",
    paddingBottom: "12px",
    paddingLeft: "60px",
    paddingRight: "54px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  btnFinalizarCompraMob: {
    borderRadius: "3px",
    backgroundColor: "#0C2D9C",
    color: "#FFFFFF",
    //float: "right",
    paddingTop: "11.8px",
    paddingBottom: "12px",
    paddingLeft: "60px",
    paddingRight: "54px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  divBtnFinalizar: {
    float: "right",
    marginBottom: "47px",
    display: "inline-block",
  },
  btnsDiv: {
    display: "block",
    width: "100%",
    marginBottom: "47px",
  },
  btnFinalizarCompraTxt: {
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "19px",
    textAlign: "center",
  },
  divBtnRegresar: {
    display: "inline-block",

  },
  btnRegresar: {
    borderRadius: "3px",
    backgroundColor: "#FFFFFF",
    color: "#0C2D9C",
    border: "1px solid #0C2D9C",
    paddingTop: "11.8px",
    paddingBottom: "12px",
    paddingLeft: "60px",
    paddingRight: "54px",
    width: "250px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#0C2D9C",
      color: "#FFFFFF",
    },
    [theme.breakpoints.down("sm")]: {
      width: "180px",
    },
  },

  btnRegresarMob: {
    borderRadius: "3px",
    backgroundColor: "#FFFFFF",
    color: "#0C2D9C",
    border: "1px solid #0C2D9C",
    paddingTop: "11.8px",
    paddingBottom: "12px",
    paddingLeft: "60px",
    paddingRight: "54px",
    marginTop:"15px",
    //width: "250px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#0C2D9C",
      color: "#FFFFFF",
    },
  },

  formData: {
    backgroundColor: "#F5F5F5",
    borderRadius: 2,
    margin: "10px 0",
    border: "1px solid #EAEAEA",
    "& *": {
      margin: "3px 8px 2px 3px",
      padding: 0,
      fontFamily: "'Centrale Sans Regular'",
      border: "none",
    },
  },

  formDataSubTitleDes: {
    color: "#0C2D9C",
    textTransform: "uppercase",
  },
  inputs: {
    width: "100%",
    border: "1px solid #EAEAEA",
    borderRadius: "2px",
    backgroundColor: "#FFFFFF",
  },
  dataContainer:{
    marginTop:"15px"
  },
  btnRegistro:{
    marginTop: "15px",
    color: "#FFFFFF",
    with: "100%",
    backgroundColor: "#0C2D9C",
    "& :hover":{
      backgroundColor: "#0C2D9C",
      color: "#FFFFFF",
    },
    "& .MuiButton-root":{
      backgroundColor: "#0C2D9C !important",
      color: "#FFFFFF !important",
      "& :hover":{
        backgroundColor: "#0C2D9C !important",
        color: "#FFFFFF !important",
      }
    } 
  }
}));

const EditarCotizacion = () => {
    const { id } = useParams();
    const classes = useStyles();
    const history = useHistory();
    const {getUserData, isLogged, setOpenAlertF , setDataQuote} = useAuth();
    const dataUser = getUserData();
    const isMobile = useCheckMobileScreen();
    const [dataBreadCrumb, setDataBreadCrumb] = useState([]);
    const [tituloSeccion] = useState("COTIZACIÓN");
    const [cotDetalle, setCotDetalle] = useState(0);
    const [cotSelected, setCotSelected] = useState([]);
    const [prodCod, setProdCod] = useState([]);
    const [user, setUser] = useState({});
    const [userAddresses, setUserAddresses] = useState([]);
    const [pickupList, setPickupList] = useState([]);
    const [buy, setBuy]= useState(false)
    const [saveQuote, setSaveQuote]= useState(true);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalWarning, setShowModalWarning] = useState(false);
    const [dataToDelete, setDataToDelete] = useState({});
    const [deliveryType, setDeliveryType] = useState(0);
    const [selectDirection, setSelectDirection] = useState({ id: '', name: '' });
    const [selectDirectionStore, setSelectDirectionStore] = useState({ id: '', name: '' });

    const getValues = async () => {
      setLoading(true)
      const body = {
        socio_negocio_id: user?.snu_sn_socio_de_negocio_id
      }
       
      let _cot = await userService.getCotizacionDetalle(id, body);
      setLoading(false)

      console.log(_cot.constCotizacionesProductos)
      valideProductsBuy(_cot.constCotizacionesProductos).then(({ productsBuy }) => {
        console.log(productsBuy)
        let isActive= false
        let notBuy =0;
         for(let i=0; i<productsBuy.length; i++){
             if(productsBuy[i].purchase){
             // isActive= true
             }else{
              notBuy = notBuy +1;
             }
             console.log(productsBuy[i].purchase)
         }
        setBuy(notBuy>0? false: true)
      });

      setDeliveryType(_cot.constCotizaciones.cot_cmm_tipo_envio_id);
      setCotSelected(_cot.constCotizaciones);
      setProdCod(_cot.constCotizacionesProductos);

      const client_address_response = await userService.getAddressByClientCardCode(
        user.snu_cardcode
      );

      const addressClient = client_address_response.data.listaSnubycardcode.map((item) => ({
        label: `${item.snd_alias ? item.snd_alias + ' - ' : ''}${item.snd_direccion} ${item.snd_direccion_num_ext} ${item.snd_ciudad}`,
        ...item
      }));

      const selectedAdress = addressClient.filter((item) =>
        item.snd_direcciones_id === _cot.constCotizaciones.cot_direccion_envio_id);
      
      setUserAddresses(addressClient);
      setSelectDirection({
        id: selectedAdress[0] ? selectedAdress[0]?.snd_direcciones_id : '',
        name: selectedAdress[0] ? selectedAdress[0]?.label : '',
      });

      const pickup_response = await  checkoutService.getWarehousesPickup();

      const selectedStore = pickup_response.listaAlmacenes.filter((item) =>
        item.alm_almacen_id === _cot.constCotizaciones.cot_alm_almacen_recoleccion
      );

      setPickupList(pickup_response.listaAlmacenes);
      setSelectDirectionStore({
        id: selectedStore[0] ? selectedStore[0]?.alm_almacen_id : '',
        name: selectedStore[0] ? selectedStore[0]?.alm_nombre : '',
      });
      
    }

    const handleAmount = async (e, product) => {
      const newProdCod = prodCod.map((item) => {
        if(item.prod_sku === product.prod_sku && e.target.value > 0) {
          return {...item, cotp_producto_cantidad: e.target.value};
        }
        return item;
      });
      // setProdCod(newProdCod);

      const respondUpdated = await userService.updateProductQuantityOfQuoteService(
        user?.snu_sn_socio_de_negocio_id,
        id,
        product.cotp_cotizaciones_productos_id,
        Number(e.target.value > 0 ? e.target.value : 1,
        product.prod_sku));

      if(!respondUpdated.error) {
        getValues();

        setOpenAlertF({
          message: "Cantidad de producto actualizada",
          type: "success",
          open: true, 
        });
      }
    }

    const handleShowListProduct = () => {
      setShowModal(true);
    }

    const handleClose = () => {
      setShowModal(false);
    }

    const addProductToQuotes = async (data) => {
        const socio_negocio_id = JSON.parse(localStorage.getItem("user")).usuario
          .snu_sn_socio_de_negocio_id
       
         
        const respondProductToQuotes = await userService.addProductToQuotesService(
          socio_negocio_id,
          id,
          data.sku,
          1,
          data.externalProductId
        );
        if(!respondProductToQuotes.error) {
          getValues();
          setOpenAlertF({
            message: "El producto se agregó a tu cotización",
            type: "success",
            open: true, 
          });
        }
    }

    const handleShowModalWarning = (product) => {
      setDataToDelete(product);
      setShowModalWarning(true);
    }

    const handleCloseModalWarning = () => {
      setShowModalWarning(false);
    }

    const handleDeleteProduct = async () => {
      const respondProductToQuotes = await userService.deleteProductOfQuotesService(
        user?.snu_sn_socio_de_negocio_id,
        id,
        dataToDelete.cotp_cotizaciones_productos_id
      );
      if(!respondProductToQuotes.error) {
        getValues();
        handleCloseModalWarning();
        setOpenAlertF({
          message: "Producto eliminado",
          type: "success",
          open: true, 
        });
      }
    }

    const saveData = () => {
      setSaveQuote(true)
    }

    const closeDetails = () => {
      history.push("/myprofile/cotizaciones");
    };

    const formatDateFull = (date) => {
      let _d = date.split("T");
      let _a = _d[0].split("-");
      let _m = "";
      switch (_a[1]) {
        case "01":
          _m = "Enero";
          break;
        case "02":
          _m = "Febrero";
          break;
        case "03":
          _m = "Marzo";
          break;
        case "04":
          _m = "Abril";
          break;
        case "05":
          _m = "Mayo";
          break;
        case "06":
          _m = "Junio";
          break;
        case "07":
          _m = "Julio";
          break;
        case "08":
          _m = "Agosto";
          break;
        case "09":
          _m = "Septiembre";
          break;
        case "10":
          _m = "Octubre";
          break;
        case "11":
          _m = "Noviembre";
          break;
        case "12":
          _m = "Diciembre";
          break;
      }
      return _a[2] + "/" + _m + "/" + _a[0];
    };

    const addProductsCart = async ()=> {
      setDataQuote({
        idCotizacion:id,
        idSocioNegocio:isLogged ? dataUser.usuario.snu_sn_socio_de_negocio_id :0,
        productos:prodCod
      });
      await prodCod.map(async (item) => {
        await addItemCartByIdProduct( (isLogged ? dataUser.usuario.snu_sn_socio_de_negocio_id : 0),
          item.cotp_prod_producto_id, item.cotp_producto_cantidad)
            .then(() => {
                history.push('/checkout')
            })
      });
    }

    const handleChangeDeliveryType = (e) => {
      setDeliveryType(e.target.value);
    }
    
    const handleChangeSelectDirection = (event) => {
      const selectedDirection = pickupList.filter((item) =>
        item.alm_almacen_id === event.target.value
      );
      setSelectDirectionStore({
        id: event.target.value,
        name: selectedDirection[0].alm_nombre,
      });
      setSelectDirection({ id: '', name: '' });
    };

    const handleChangeSelectStore = (event) => {
      const selectStore = userAddresses.filter((item) => 
        item.snd_direcciones_id === event.target.value);

      setSelectDirection({
        id: event.target.value,
        name: selectStore[0]?.label,
      });
      setSelectDirectionStore({ id: '', name: '' });
    };

    const saveNewDelibery = async (idDeliveryType, idSelectDirection) => {
      const respondUpdated = await userService.updateDeliberyOfQuoteService(
        user?.snu_sn_socio_de_negocio_id,
        id,
        idDeliveryType,
        idSelectDirection);

      if(!respondUpdated.error) {
        getValues();

        setOpenAlertF({
          message: "Dirección de entrega actualizada",
          type: "success",
          open: true, 
        });
      }
    }
    
    useEffect(() => {
      if(selectDirection.id !== cotSelected.cot_direccion_envio_id && selectDirection.id) {
        saveNewDelibery(deliveryType, selectDirection.id);
      }
      if(selectDirectionStore.id !== cotSelected.cot_alm_almacen_recoleccion && selectDirectionStore.id) {
        saveNewDelibery(deliveryType, selectDirectionStore.id);
      }
    }, [selectDirection, selectDirectionStore]);

    useEffect(() => {
      if(Object.keys(user).length > 0) {
        getValues();
      }
    }, [user]);

    useEffect(() => {
      if (!localStorage.getItem("user")) {
          //redirect to login
          history.push("/login");
      } else {
          setUser(JSON.parse(localStorage.getItem("user")).usuario);
      }
      setCotDetalle(id);
      setDataBreadCrumb(() => [
        {
          name: "INICIO",
          link: "/",
        },
        {
          name: "MIS COTIZACIONES",
          link: "/myprofile/cotizaciones",
        },
        {
          name: "Editar cotización",
          link: "",
        }
      ]);
    },[])

  return (
    <div>
      <ModalSearchAndAddProduct
        showModal={showModal}
        handleClose={handleClose}
        title="Agregar producto"
        handleFunction={addProductToQuotes}
      />
      <ModalWarning
        showModal={showModalWarning}
        handleClose={handleCloseModalWarning}
        textWarning="¿Desea eliminar este producto de su cotización?"
        textAction="Eliminar"
        handleFunction={handleDeleteProduct}
      />
      <div>
        <SectionBreadcrumb route={dataBreadCrumb} />
        <Loading loading={loading} />
        <Typography variant="h6" className={classes.title}>{tituloSeccion}</Typography>
      </div>
      <div className={classes.mainDataDiv}>
        <div className={classes.titleDiv}>
          <Typography className={classes.cotDetalleTitle}>
            Cotización {cotDetalle}
          </Typography>
        </div>
        {cotSelected.cot_sn_socios_negocio_id && (
          <div className={classes.cotDetailsDataDiv}>
            <div className={classes.cotDetailsDataRow}>
              <Typography className={classes.cotDetalleDataTitle}>
                Fecha de elab.
              </Typography>
              <Typography className={classes.cotDetalleDataTxt}>
                {formatDateFull(cotSelected.createdAt)}
              </Typography>
            </div>
            <div className={classes.cotDetailsDataRow}>
              <Typography className={classes.cotDetalleDataTitle}>
                Fecha de venc.
              </Typography>
              <Typography className={classes.cotDetalleDataTxt}>
                {formatDateFull(cotSelected.cot_fecha_vencimiento)}
              </Typography>
            </div>
            <Grid container spacing={2} sx={{ paddingLeft: 2, paddingRight: 2 }}>
              <Grid xs={12} sm={6} sx={{ paddingBottom: 2 }}>
                <Typography className={classes.cotDetallePersonalTitle}>
                  Datos personales
                </Typography>
                <Typography className={classes.cotDetallePersonalData}>
                  {cotSelected.InformacionCliente.sn_cardcode}
                </Typography>
                <Typography className={classes.cotDetallePersonalData}>
                  {cotSelected.InformacionCliente.sn_nombre_empresa}
                </Typography>
              </Grid>
              <Grid xs={12} sm={6}>
                  <Grid xs={12}>
                    <Typography>
                      Elige un tipo de entrega
                    </Typography>
                  </Grid>
                  <Grid>
                    <FormControl component="fieldset">
                      <RadioGroup
                        value={deliveryType}
                        onChange={handleChangeDeliveryType}
                        xs={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}
                      >
                        <ListItem>
                          <ListItemIcon>
                            <LocalShippingIcon />
                          </ListItemIcon>
                          <FormControlLabel
                            value="16"
                            control={<Radio />}
                            label="Envío a domicilio"
                            sx={{ color: '#525252' }}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <HouseIcon />
                          </ListItemIcon>
                          <FormControlLabel
                            value="17"
                            control={<Radio />}
                            label="Recoger en tienda"
                            sx={{ color: '#525252' }}
                          />
                        </ListItem>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {deliveryType == 16
                  ?
                    <Grid xs={12} sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      paddingTop: 3,
                    }}
                    >
                      <Grid>
                        <Typography>Dirección de entrega</Typography>
                      </Grid>
                      <Grid>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                          <InputLabel>Elegir dirección</InputLabel>
                          <Select
                            variant="outlined"
                            label="Elegir dirección"
                            value={selectDirection.id}
                            onChange={handleChangeSelectStore}
                            sx={{ maxWidth: '400px' }}
                          >
                            <MenuItem value="">
                              <em>Elegir dirección</em>
                            </MenuItem>
                            {userAddresses.length && userAddresses.map((item) => 
                              <MenuItem value={item.snd_direcciones_id}>{item.label}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  :
                    <Grid xs={12} sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      paddingTop: 3,
                    }}
                    >
                      <Grid>
                        <Typography>Dirección de recolección</Typography>
                      </Grid>
                      <Grid>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                          <InputLabel>Elegir tienda</InputLabel>
                          <Select
                            variant="outlined"
                            label="Elegir tienda"
                            value={selectDirectionStore.id}
                            onChange={handleChangeSelectDirection}
                            sx={{ maxWidth: '400px' }}
                          >
                            <MenuItem value="">
                              <em>Elegir tienda</em>
                            </MenuItem>
                            {pickupList.length && pickupList.map((item) =>
                              <MenuItem value={item.alm_almacen_id}>{item.alm_nombre}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>}
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      <div style={{ paddingTop: 8 }}>
        <Button
          sx={{ backgroundColor: '#0C2D9C' }}
          variant="contained"
          onClick={handleShowListProduct}
          startIcon={<AddIcon />}
        >
          Agregar producto
        </Button>
      </div>
      {isMobile ? (
        <Grid item xs={12} className={classes.gridContent}>
          {prodCod.map((row) => {
              return (
                <div className={classes.formData}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography className={classes.formDataSubTitleDes}>
                            Imagen:
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <div className={classes.imageProd}>
                            {row.imagenes[0]?.imgprod_ruta_archivo ?  (
                              <Avatar variant="square" src={row.imagenes[0]?.imgprod_ruta_archivo.includes('https://ftp3.syscom.mx')? row.imagenes[0]?.imgprod_ruta_archivo: `${API_URL.split('api')[0]}${row.imagenes[0]?.imgprod_ruta_archivo.split('public')[1]}`}></Avatar>
                            ): (
                              <Avatar variant="square" src=""> <BrokenImageIcon /></Avatar>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography className={classes.formDataSubTitleDes}>
                            Descripción:
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography>{row.prod_nombre}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography className={classes.formDataSubTitleDes}>
                            No. de parte:
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography> {row.prod_nombre_extranjero}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography className={classes.formDataSubTitleDes}>
                            Cantidad:
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography>{row.cotp_producto_cantidad}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography className={classes.formDataSubTitleDes}>
                            Precio :
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          {row.cotp_precio_menos_promociones ? (
                            <div>
                              <div>
                                <typography>
                                  Precio de Lista <span style={{textDecoration:"line-through"}}>{getPriceSapFormat(row.cotp_precio_base_lista)}</span>
                                </typography>
                              </div>
                              <div>
                                <typography>
                                  Precio Cotizado <span style={{fontWeight:"bold"}}>{getPriceSapFormat(row.cotp_precio_menos_promociones)}</span>
                                </typography>
                              </div>  
                            </div>
                          ): (
                            <typography>
                              `Precio de Lista ${getPriceSapFormat(row.cotp_precio_base_lista)}`
                            </typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography className={classes.formDataSubTitleDes}>
                            Importe:
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography>
                            {getPriceSapFormat(
                              row.cotp_precio_base_lista * row.cotp_producto_cantidad
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              );
          })}
        </Grid>
      ):(
          <div className={classes.divTableProducts}>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell className={classes.tableHeaderTxt}></TableCell>
                  <TableCell  className={classes.tableHeaderTxt}>
                    Descripción
                  </TableCell>
                  <TableCell style={{textAlign:"center"}} className={classes.tableHeaderTxt}>
                    No. Parte
                  </TableCell>
                  
                  <TableCell style={{textAlign:"center"}} className={classes.tableHeaderTxt}>
                    Cant.
                  </TableCell>
                  <TableCell style={{textAlign:"center"}} className={classes.tableHeaderTxt}>
                    Precio 
                  </TableCell>
                  <TableCell style={{textAlign:"center"}} className={classes.tableHeaderTxt}>
                    Importe
                  </TableCell>
                  <TableCell style={{textAlign:"center"}} className={classes.tableHeaderTxt}>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prodCod.map((row) => (
                  <TableRow key={row.numCot}>
                    <TableCell style={{textAlign:"center"}} className={classes.rowDataProd}>
                      <div className={classes.imageProd}>
                        {row.imagenes[0]?.imgprod_ruta_archivo ?  (
                          <Avatar variant="square" src={row.imagenes[0]?.imgprod_ruta_archivo.includes('https://ftp3.syscom.mx')? row.imagenes[0]?.imgprod_ruta_archivo: `${API_URL.split('api')[0]}${row.imagenes[0]?.imgprod_ruta_archivo.split('public')[1]}`}></Avatar>
                        ): (<Avatar variant="square" src=""> <BrokenImageIcon /></Avatar>
                        )}
                      </div>
                    </TableCell>
                    <TableCell  className={classes.rowDataProd}>
                      {row.prod_nombre}
                    </TableCell>
                    <TableCell style={{textAlign:"center"}} className={classes.rowDataProd}>
                      {row.prod_nombre_extranjero}
                    </TableCell>
                   
                    <TableCell style={{textAlign:"center"}} className={classes.rowDataProd}>
                      <TextField
                      label="Cantidad"
                      variant="outlined"
                      size="small"
                      type="number"
                      value={row.cotp_producto_cantidad}
                      onChange={(e) => handleAmount(e, row)}
                    />
                    </TableCell>
                    <TableCell style={{textAlign:"center"}} className={classes.rowDataProd}>
                      
                      {row.cotp_precio_menos_promociones ? (
                        <div>
                          <div>
                            <typography>
                              Precio de Lista <span style={{textDecoration:"line-through"}}>{getPriceSapFormat(row.cotp_precio_base_lista)}</span>
                            </typography>
                          </div>
                          <div>
                            <typography>
                            Precio Cotizado <span style={{fontWeight:"bold"}}>{getPriceSapFormat(row.cotp_precio_menos_promociones)}</span>
                            </typography>
                          </div>
                          
                        </div>
                        ): (
                          <typography>
                            `Precio de Lista ${getPriceSapFormat(row.cotp_precio_base_lista)}`
                          </typography>
                        )}
                    </TableCell>
                    <TableCell style={{textAlign:"center"}} className={classes.rowDataProd}>
                      {getPriceSapFormat(
                      (row.cotp_precio_menos_promociones ? row.cotp_precio_menos_promociones : row.cotp_precio_base_lista) * row.cotp_producto_cantidad
                      )}
                    </TableCell>
                    <TableCell style={{textAlign:"center"}} className={classes.rowDataProd}>
                      <IconButton aria-label="delete" onClick={() => handleShowModalWarning(row)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      <div className={classes.divSumatorias}>
        <div className={classes.divSubtotals}>
          <div className={classes.divSubtotalesRow}>
            <Typography className={classes.subtotalTitle}>
              Subtotal
            </Typography>
            <Typography className={classes.subtotalData}>
              {getPriceSapFormat(cotSelected && cotSelected.cot_total_base)}
            </Typography>
          </div>
          <div className={classes.divSubtotalesRow}>
            <Typography className={classes.subtotalTitle}>
              Gastos de envío
            </Typography>
            <Typography className={classes.subtotalData}>
              {getPriceSapFormat(cotSelected && cotSelected.cot_costo_envio)}
            </Typography>
          </div>
          <div className={classes.divSubtotalesRow}>
            <Typography className={classes.subtotalTitle}>
              Descuento
            </Typography>
            <Typography className={classes.subtotalData}>
              -{getPriceSapFormat(cotSelected && cotSelected.cot_descuento_total)}
            </Typography>
          </div>
          <div className={classes.divSubtotalesRow}>
            <Typography className={classes.subtotalTitle}>IVA 16%</Typography>
            <Typography className={classes.subtotalData}>
              {getPriceSapFormat(cotSelected && cotSelected.cot_iva_cantidad)}
            </Typography>
          </div>
          <div className={classes.divLineTotal}></div>
          <div className={classes.divSubtotalesRow}>
            <Typography className={classes.totalTitle}>Total</Typography>
            <Typography className={classes.totalData}>
              {getPriceSapFormat(cotSelected && (cotSelected.cot_total_cotizacion + cotSelected.cot_costo_envio))}
            </Typography>
          </div>
        </div>
      </div>
      {isMobile ? (
            <Grid>
              {/* {!saveQuote&& <Grid Container>
                  <Grid item xs={12} className={classes.btnFinalizarCompraMob}>
                        <Typography
                          className={classes.btnFinalizarCompraTxt}
                          onClick={saveData}
                        >
                          Guardar Cambios
                        </Typography>
                  </Grid>
              </Grid>} */}
              {saveQuote && (buy ?
                <Grid Container>
                  <Grid item xs={12} className={classes.btnFinalizarCompraMob}>
                        <Typography
                          className={classes.btnFinalizarCompraTxt}
                          onClick={()=>addProductsCart()}
                        >
                          Finalizar compra
                        </Typography>
                  </Grid>
              </Grid>
              :
              <Grid Container>
                  <Grid item xs={12} className={classes.btnFinalizarCompraMob}>
                        <Typography
                          className={classes.btnFinalizarCompraTxt}
                          //onClick={saveData}
                        >
                          Contactar al vendedor
                        </Typography>
                  </Grid>
              </Grid>
              
              )}
              <Grid Container>
                  <Grid item sx={12} className={classes.btnRegresarMob} >
                        <Typography
                          className={classes.btnFinalizarCompraTxt}
                          onClick={() => {
                            closeDetails();
                          }}
                        >
                          Regresar
                        </Typography>
                  </Grid>
              </Grid>
            </Grid>
      ):(
        <div className={classes.btnsDiv}>
          <div className={classes.divBtnRegresar} >
            <div className={classes.btnRegresar} >
              <Typography
                className={classes.btnFinalizarCompraTxt}
                onClick={() => {
                  closeDetails();
                }}
              >
                Regresar
              </Typography>
            </div>
          </div>
          <div className={classes.divBtnFinalizar}>
            {!saveQuote &&<div className={classes.btnFinalizarCompra}>
              <Typography
                className={classes.btnFinalizarCompraTxt}
                onClick={saveData}
              >
                Guardar Cambios
              </Typography>
            </div>}

           {saveQuote && (buy ? <div className={classes.btnFinalizarCompra}>
              <Typography
                className={classes.btnFinalizarCompraTxt}
                onClick={()=>addProductsCart()}
              >
                Finalizar Compra
              </Typography>
            </div>
           : <div className={classes.btnFinalizarCompra}>
              <Typography
                className={classes.btnFinalizarCompraTxt}
               // onClick={saveData}
              >
               Contactar al vendedor
              </Typography>
            </div>)}
          </div>
        </div>
      )}
    </div>
  )
}

export default EditarCotizacion;