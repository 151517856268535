import React, { Fragment, useState } from 'react';
import { Box, Button, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { Link, useHistory } from "react-router-dom";
import { MenuMovil } from './MenuMovil';
import useStyles from './stylesClasses';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ReceiptIcon from '@mui/icons-material/Receipt';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import OutputIcon from '@mui/icons-material/Output';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import SearchAutocomplete from './SearchAutocomplete';
import CartHeader from 'views/Cart/CartHeader';
import LocalCartHeader from 'views/Cart/LocalCartHeader';
import { useAuth } from "hooks/useAuth";
import { checkoutService } from '_services/checkout.service';

const HeaderMobileNew = ({ getCarrito }) => {
    const history = useHistory();
    const classes = useStyles();
    const { logoURL, isLogged, isDollar, setIsDollar, carritoItems, getUserData,logout } = useAuth();
    const dataUser = getUserData()
    const [searchWord, setSearchWord] = useState("");
    const [showMenu, setShowMenu] = useState(null);
    const open = Boolean(showMenu);
    const [showMenuExChange, setShowMenuExChange] = useState(null);
    const openExChange = Boolean(showMenuExChange);
    const [dataCar, setDataCar] = useState({});
    const [showCar, setShowCar] = useState(false);

    const handleClickMenuExChange = (event) => {
        setShowMenuExChange(event.currentTarget);
    };

    const handleCloseMenuExChange = () => {
        setShowMenuExChange(null);
    };

    const funSetSearchWord = (valueSearch) =>{
        setSearchWord(valueSearch);
    }
    const redirectSearch = () => {
        history.push(`/search?search=${searchWord.toUpperCase()}`);
        setSearchWord("");
    };

    const handleLinkWhatsApp = () => {
        window.open('https://api.whatsapp.com/send?phone=5218126589848&text=Hola%2C%20buen%20d%C3%ADa%F0%9F%98%80', '_blank');
    }

    const handleClickMenu = (event) => {
        setShowMenu(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setShowMenu(null);
    };

    const handleCerrarSesion = () => {
        setShowMenu(null);
        localStorage.removeItem('user');
        localStorage.removeItem('myCart');
        localStorage.removeItem('client');
        logout()
        window.location.reload(true);
    };

    const handleMiCuenta = () => {
        setShowMenu(null);
        history.push("/myprofile/perfil"); 
    };

    const handleMisPedidos = () => {
        setShowMenu(null);
        history.push("/myprofile/pedidos"); 
    }

    const handleMisCotizaciones = () => {
        setShowMenu(null);
        history.push("/myprofile/cotizaciones"); 
    }

    const handleMisFavoritos = () => {
        setShowMenu(null);
        history.push("/myprofile/favoritos"); 
    }

    const handleMisProyectos = () => {
        setShowMenu(null);
        history.push("/myprofile/proyectos"); 
    }

    const handleIniciarSesion = () => {
        history.push("/login"); 
    }

    const handleOnChangeCurrencyExchange = (value) =>{
        setIsDollar(value == 1 ? false : true)
        window.localStorage.setItem('isDollar', value ==1 ? false : true)
        setShowMenuExChange(null);
    }

    const handleShowCar = async () => {
        setShowCar(true);
    };

    const handleCloseCar = () => {
        setShowCar(false);
    };

    return (
        <Fragment>
            <Box
                sx={{
                    width: "100%",
                    height: "100px",
                    position: "fixed",
                    zIndex: 3,
                    backgroundColor: '#ffffff91',
                    backdropFilter: 'blur(15px)',
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            paddingTop: '8px',
                            paddingLeft: '6px',
                            paddingRight: '6px',
                            position: 'absolute',
                            top: 0,
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                width: '32%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Box>
                                <MenuMovil classes={classes} />
                            </Box>
                            <Box component={Link} to={'/'}>
                                <img
                                    src={logoURL}
                                    style={{
                                        height: "24px",
                                        width: "70px",
                                        marginTop: '-6px',
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                width: '45%' ,
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                           
                            <Box>
                                <Button
                                    sx={{ color: '#0b3196' }}
                                    startIcon={<CurrencyExchangeIcon />}
                                    onClick={handleClickMenuExChange}
                                >
                                    {isDollar ? 'DLS' : 'MXN'}
                                </Button>
                                <Menu
                                    anchorEl={showMenuExChange}
                                    open={openExChange}
                                    onClose={handleCloseMenuExChange}
                                    MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => handleOnChangeCurrencyExchange('1')}>MXN</MenuItem>
                                    <MenuItem onClick={() => handleOnChangeCurrencyExchange('2')}>DLS</MenuItem>
                                </Menu>
                            </Box>
                            <Box>
                                <IconButton
                                    sx={{
                                        backgroundColor: '#00e500',
                                        color: '#0b3196',
                                        padding: '3px',
                                        '&:hover': {
                                            backgroundColor: '#00e500',
                                        },
                                        '&:active': {
                                            backgroundColor: '#00e500'
                                        },
                                        '&:focus': {
                                            backgroundColor: '#00e500',
                                        }
                                    }}
                                    onClick={handleLinkWhatsApp}
                                >
                                    <WhatsAppIcon />
                                </IconButton>
                            </Box>
                            {isLogged ?
                            <Box>
                                <IconButton
                                    sx={{
                                        backgroundColor: '#00e500',
                                        color: '#0b3196',
                                        padding: '3px',
                                        '&:hover': {
                                            backgroundColor: '#00e500',
                                        },
                                        '&:active': {
                                            backgroundColor: '#00e500'
                                        },
                                        '&:focus': {
                                            backgroundColor: '#00e500',
                                        }
                                    }}
                                    onClick={handleClickMenu}
                                >
                                    <AccountCircleOutlinedIcon />
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={showMenu}
                                    open={open}
                                    onClose={handleCloseMenu}
                                    MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={handleMiCuenta}>
                                        <ListItemIcon>
                                            <PersonIcon fontSize="small" />
                                        </ListItemIcon>
                                        Mi perfil
                                    </MenuItem>
                                    <MenuItem onClick={handleMisPedidos}>
                                        <ListItemIcon>
                                            <ShoppingBasketIcon fontSize="small" />
                                        </ListItemIcon>
                                        Mis pedidos
                                    </MenuItem>
                                    <MenuItem onClick={handleMisCotizaciones}>
                                        <ListItemIcon>
                                            <ReceiptIcon fontSize="small" />
                                        </ListItemIcon>
                                        Mis cotizaciones
                                    </MenuItem>
                                    {dataUser.usuario.TypeUser!=2 &&
                                    <> 
                                    <MenuItem onClick={handleMisFavoritos}>
                                        <ListItemIcon>
                                            <BookmarkIcon fontSize="small" />
                                        </ListItemIcon>
                                        Mis favoritos
                                    </MenuItem>
                                    <MenuItem onClick={handleMisProyectos}>
                                        <ListItemIcon>
                                            <BusinessCenterRoundedIcon fontSize="small" />
                                        </ListItemIcon>
                                        Mis Proyectos
                                    </MenuItem>
                                    </>
                                        }
                                    <MenuItem onClick={handleCerrarSesion}>
                                        <ListItemIcon>
                                            <OutputIcon fontSize="small" />
                                        </ListItemIcon>
                                        Cerrar sesión
                                    </MenuItem>
                                </Menu>
                            </Box>
                            : <Box>
                                <IconButton
                                    onClick={handleIniciarSesion}
                                    sx={{
                                        backgroundColor: '#00e500',
                                        color: '#0b3196',
                                        padding: '3px',
                                        '&:hover': {
                                            backgroundColor: '#00e500',
                                        },
                                        '&:active': {
                                            backgroundColor: '#00e500'
                                        },
                                        '&:focus': {
                                            backgroundColor: '#00e500',
                                        }
                                    }}
                                >
                                    <PersonOutlinedIcon />
                                </IconButton>
                            </Box>}
                            <Box
                                sx={{
                                    paddingLeft: '4px'
                                }}
                            >
                                <div
                                    style={{
                                        marginLeft: '14px',
                                        marginBottom: '9px',
                                    }}
                                >
                                    <span
                                        style={{
                                            position: "absolute",
                                            width: "18px",
                                            height: "18px",
                                            borderRadius: '50%',
                                            backgroundColor: '#40df00',
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: 'center',
                                            color: '#fff',
                                            fontSize: '11px',
                                            fontWeight: "600",
                                            zIndex: 1,
                                        }}
                                    >
                                        {carritoItems}
                                    </span>
                                </div>
                                <IconButton
                                    sx={{
                                        padding: '5px',
                                        color: '#0b3196',
                                    }}
                                    onClick={handleShowCar}
                                >
                                    <ShoppingCartOutlinedIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: "100%",
                            position: 'absolute',
                            top: '52px',
                            padding: '0px 10px'
                        }}
                    >
                        <SearchAutocomplete
                            funSetSearchWord={funSetSearchWord}
                            classes=""
                            redirectSearch={redirectSearch}
                        />
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    width: "100%",
                    height: "100px",
                }}
            />
            {isLogged &&
                <CartHeader showCar={showCar} closeCartDiv={handleCloseCar}/>}
            {!isLogged &&
                <LocalCartHeader showCar={showCar} closeCartDiv={handleCloseCar} getCarrito={getCarrito} />}
        </Fragment>
    )
}

export default HeaderMobileNew;