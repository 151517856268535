import React, { useState, useLayoutEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import './Loading.css'
 export const LoadingProgress= ()=> {
  const [show, setShow] = useState(true)
  const styl= 'container-homes containers hides'
  const styl2= 'container-homes containers'


  return (
      <div className={ show? styl2 : styl  }>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress size={'100px'} />
        
        </Box>
        <br/>
        <p style={{marginLeft:'-40px'}}>Estamos creando tu cotización.</p>
      </div>
  );
} 
