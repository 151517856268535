import conektaService from "./config";

let config = {
  conekta: {
    staging: {
      publicKey: "", // default api key to be replace by the api return by backend //key_H1crhx6sLNr6HkjiUyzeLqg
    },
  },
};

const conektaHelper = {
  getApiConekta: async () => {
    let response = await conektaService.getApiKey();
    if (response.constControlMaestroMultiple) {
      config.conekta.staging.publicKey =
        response.constControlMaestroMultiple.cmm_valor;
    }
  },
  initConekta: async () => {
    await conektaHelper.getApiConekta();
    window.Conekta.setPublicKey(config.conekta.staging.publicKey);
  },
  getCardBrand: (cardNumber) => {
    return window.Conekta.card.getBrand(cardNumber);
  },
  validateCardNumber: (cardNumber) => {
    return window.Conekta.card.validateNumber(cardNumber);
  },
  validateCvc: (cvc) => {
    return window.Conekta.card.validateCVC(cvc);
  },
  validateExpirationDate: (expiryMonth, expiryYear) => {
    return window.Conekta.card.validateExpirationDate(
      expiryMonth,
      `20${expiryYear}`
    );
  },
  tokenize: (
    cardNumber,
    cardHolder,
    expiryMonth,
    expiryYear,
    cvc,
    successCallback,
    errorCallback
  ) => {
    const tokenParams = {
      card: {
        number: cardNumber,
        name: cardHolder,
        exp_year: expiryYear,
        exp_month: expiryMonth,
        cvc,
      },
    };
    window.Conekta.Token.create(tokenParams, successCallback, errorCallback);
  },
};

export default conektaHelper;
