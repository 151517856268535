export function getPriceSapFormat(price) {
    const factor = Math.pow(10, 2);

    let number = price * factor;

    let rounded = Math.round(number);

    if((number % 1 === 0.5 || number % 1 === -0.5) && rounded % 2 !== 0) {
        rounded -= 1;
    }

    const formattedPrice = (rounded / factor).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return `$${formattedPrice}`;
}
  
  

  
  