import { authHeader } from "../_helpers";
import { API_URL } from "./api.url.back";

export const wishlistService = {
  getWishlistByClientId,
  deleteItemWishList,
};

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    const responseData = {
      error: false,
      data: data,
    };
    if (!response.ok) {
      //responseData.error = true;
      //return responseData;
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function handleResponseAlternative(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    const responseData = {
      error: false,
      data: data,
    };
    if (!response.ok) {
      responseData.error = true;
      return responseData;
    }
    return responseData;
  });
}

function getWishlistByClientId(clientid, pricelistid) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      wl_nombre: "Lista Basica",
      wl_sn_socios_negocio_id: clientid,
      sn_lista_precio: pricelistid,
    }),
  };

  return fetch(
    `${API_URL}/wish_list/get_wish_list_by_user_SN_Token`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function deleteItemWishList(clientid, productid) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    body: JSON.stringify({
      wl_nombre: "Lista Basica",
      wl_sn_socios_negocio_id: clientid,
      wlp_prod_producto_id: productid,
    }),
  };

  return fetch(
    `${API_URL}/wish_list/delete_producto_from_wish_SN_Token`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}
