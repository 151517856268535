import { authHeader } from "../_helpers";
import { API_URL } from "./api.url.back";

export const generalService = {
  getFAQs,
  getCities,
  getStates,
  getCountries,
  getCfdi,
  getShippingMethods,
  getPaymentMethods,
  getPaymentFormas,
  getPaymentMetodos,
  getInstitutionalPagesList,
  getInstitutionalPage,
};

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    const responseData = {
      error: false,
      data: data,
    };
    if (!response.ok) {
      //responseData.error = true;
      //return responseData;
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function handleResponseAlternative(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    const responseData = {
      error: false,
      data: data,
    };
    if (!response.ok) {
      responseData.error = true;
      return responseData;
    }
    return responseData;
  });
}

function getCities(idstate) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      estpa_estado_pais_id: idstate,
      limite: 9999,
      pagina: 0,
    }),
  };

  return fetch(`${API_URL}/ciudades_estados/list_by_estado`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function getStates(idcountry) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/pais/getEstadoByIdPais/${idcountry}`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getFAQs() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/faqs/list`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getCountries() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/pais/getListPais`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getCfdi() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/cfdi/listCFDI`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getShippingMethods() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/controles_maestros/control_public/TIPO_ENVIO`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      const todayDate = new Date();
      jsonData.updateDate = {
        fullDate: todayDate,
        day: todayDate.getDate(),
        month: todayDate.getMonth(),
        year: todayDate.getFullYear(),
      };

      localStorage.setItem("shippingMethods", JSON.stringify(jsonData));
      return jsonData;
    });
}

function getPaymentMethods() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/controles_maestros/control_public/TIPO_COMPRA`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      const todayDate = new Date();
      jsonData.updateDate = {
        fullDate: todayDate,
        day: todayDate.getDate(),
        month: todayDate.getMonth(),
        year: todayDate.getFullYear(),
      };
      localStorage.setItem("paymentMethods", JSON.stringify(jsonData));
      return jsonData;
    });
}

function getPaymentFormas() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/sap_formas_pago/list`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getPaymentMetodos() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/sap_metodos_pago/list`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getInstitutionalPagesList() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };


  // revisar 
  return fetch(
    `${API_URL}/paginas_institucionales/list_public/`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getInstitutionalPage(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/paginas_institucionales/pagina/public/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}
