import { checkoutService } from "_services/checkout.service";

export const addItemCartByIdProduct = async (businessPartner, idProduct, amount) => {
  const data = await checkoutService.createOrGetCartByClientId(businessPartner).then( async () => {
    return await checkoutService.addItemCart(
      businessPartner,
      idProduct,
      amount
    ); 
  })
  return data
} 

export const  addCartBusinnesPartner = (amount, itemProduct, businessPartner, idProduct) => {
  return new Promise(resolve => {
     let isQuotation = false;  //Cotizacion 
     let isCart= false;  //Carrito compras
     let isCartAmount= false; // Cuando el producto se excede del stock solicitado, una cantidad mayor.
     checkoutService.getPriceByProductProjectCart(businessPartner,itemProduct.sku).then(({data}) => {
      
     console.log(data)
     //itemProduct.prod_precio = 
     if(itemProduct.prod_precio===0 &&data != null ){
      console.log('1')
      itemProduct.prod_precio= data != null ?  data.precio : itemProduct.prod_precio
     }else if (itemProduct.precioFinal===0 &&data != null){
      itemProduct.precioFinal= (data != null ?  data.precio : itemProduct.precioFinal)
      console.log('2:', data.precio)
     }else if(itemProduct.precioDescuento ===0 && data != null){
      console.log('3')
      itemProduct.precioDescuento= data != null ?  data.precio : itemProduct.precioDescuento
     }  
     

    

    console.log('itemProduct ===> ', itemProduct);

     if( (itemProduct.prod_precio === 0 || itemProduct.prod_peso ===0 || itemProduct.prod_volumen ===0 || itemProduct.prod_total_stock ===0 ) &&(itemProduct.backorder ===false))
      {  //Resultado categorias, busquedas
       isQuotation= true;
      }else if( (itemProduct.precioDescuento===0 || itemProduct.peso===0 || itemProduct.volumen===0 || itemProduct.stock===0  ) && (itemProduct.backorder === false) ){
        //detalle del producto
      isQuotation = true;
      }else if (itemProduct.stock > 0 && itemProduct.precioFinal > 0 &&  itemProduct.prod_peso >0 && itemProduct.prod_volumen >0 &&  amount <=itemProduct.stock){
        //Resultado de categorias, busqueda,
        isCart= true;
      }else if(itemProduct.stock === 0 && itemProduct.precioFinal > 0 &&  itemProduct.prod_peso > 0 && itemProduct.prod_volumen > 0 && itemProduct.backorder === true){
        //Resultados de categorias, busqueda, cuando el producto tiene stock 0, pero activo el backorder, y todas las demas condiciones las cumple.
        isCart=true;
      }else if(itemProduct.stock>0 && itemProduct.precioDescuento>0 && itemProduct.peso>0 && itemProduct.volumen>0 && amount <=itemProduct.stock){
        //Detalle del producto
        isCart=true;
      }else if(itemProduct.stock===0 && itemProduct.precioDescuento>0 && itemProduct.peso>0 && itemProduct.volumen>0 &&itemProduct.backorder === true){
        //Detalle del producto, cuando el producto tienen stock 0, pero activo el backorder, y todas las demas condiciones cumple
        isCart=true;
      }else if(itemProduct.backorder === false && 
        itemProduct.precioFinal > 0 &&  itemProduct.prod_peso >0 && itemProduct.prod_volumen >0 && amount > itemProduct.stock
      ){
        //Resultado de categorias, busqueda, cuando el producto tiene una cantidad que supera el stock disponible
        isCartAmount= true;
      }else if ((itemProduct.backorder===false && 
        itemProduct.precioDescuento>0 && itemProduct.peso>0 && itemProduct.volumen>0 && amount > itemProduct.stock)){
        //Detalle del producto, cuando el producto tinee una cantidad que supera el stock.
        isCartAmount= true;
      }else if(itemProduct.backorder === true &&
      itemProduct.precioFinal > 0 &&  itemProduct.prod_peso >0 && itemProduct.prod_volumen >0 && amount > itemProduct.stock
    ){
      //Resultado de categorias, busqueda, cuando el producto tiene una cantidad que supera el stock disponible
      isCart= true;
    }else if ((itemProduct.backorder===true && 
      itemProduct.precioDescuento>0 && itemProduct.peso>0 && itemProduct.volumen>0 && amount > itemProduct.stock)){
      //Detalle del producto, cuando el producto tinee una cantidad que supera el stock.
      isCart= true;
    }
      else {
        isCart= true; isCartAmount= true; isQuotation= true;
      }
      if (isCart && isCartAmount && isQuotation){
        resolve({type:'error', message:'hubo un detalle: al agregar el producto al carrito de compras.', isAmount:true})

      }else{
      checkoutService.createOrGetCartByClientId(businessPartner).then(({data}) => {
        const prodsList =  data.cdc_carrito_de_compra_id.productos;
        if(prodsList.length === 0){    
             checkoutService.addItemCart( 
              businessPartner,
              idProduct,
              amount
            ); 
            if (isCart && !isCartAmount && !isQuotation){
            resolve({type:'success', message:'Producto agregado.', isAmount:false})
          }else if(!isCart && !isCartAmount && isQuotation){
            resolve({type:'success',message:'Producto agregado, pero solo esta disponible para solicitar cotización.', isAmount:false })
          }else if (!isCart && isCartAmount && !isQuotation){
            resolve({type:'success',message:'Producto agregado, pero la cantidad de producto en carrito supera la cantidad en existencia. Su orden será ajustada acorde a existencias.', isAmount:false })
          }
         }else{
            const encontrado = prodsList.find((prd)=> prd.prod_sku === itemProduct.sku);
            console.log(encontrado)
            if(encontrado === undefined){
               checkoutService.addItemCart(
                businessPartner,
                idProduct,
                amount
              );  
              if (isCart && !isCartAmount && !isQuotation){
                resolve({type:'success', message:'Producto agregado.', isAmount:false})
              }else if(!isCart && !isCartAmount && isQuotation){
                resolve({type:'success',message:'Producto agregado, pero solo esta disponible para solicitar cotización.', isAmount:false })
              }else if (!isCart && isCartAmount && !isQuotation){
                resolve({type:'success',message:'Producto agregado, pero la cantidad de producto en carrito supera la cantidad en existencia. Su orden será ajustada acorde a existencias.', isAmount:false })
              }
            }else{
              const newQty = (encontrado.pcdc_producto_cantidad + parseInt(amount));
              console.log('Total productos:'+ newQty)
               checkoutService.addItemCart(
                businessPartner,
                idProduct,
                newQty
              );
              if (isCart && !isCartAmount && !isQuotation){
                resolve({type:'success', message:'Producto agregado.', isAmount:true})
              }else if(!isCart && !isCartAmount && isQuotation){
                resolve({type:'success',message:'Producto agregado, pero solo esta disponible para solicitar cotización.', isAmount:true })
              }else if (!isCart && isCartAmount && !isQuotation){
                resolve({type:'success',message:'Producto agregado, pero la cantidad de producto en carrito supera la cantidad en existencia. Su orden será ajustada acorde a existencias.', isAmount:true })
              }
            }
            
         }
        
        
        })

      }
      })
    })
}



