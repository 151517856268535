import React, { useState, useEffect, useContext, Fragment } from "react";
import { Options } from "@splidejs/splide";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import ReactImageMagnify from "react-image-magnify";
import ImageMagnify from "./SectionProductCarouselImage";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((styles) => ({
  container: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 15,
    paddingRight: 15,
    display: "inline-block",
    verticalAlign: "top",
    [styles.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& .splide__arrow": {
      border: "3px solid #40df00",
      background: "transparent",
    },
  },
  large: {
    width: "100%",
    height: "auto",
  },
  text: {
    display: "block",
    textAlign: "center",
    lineHeight: 1,
  },
  title: {
    display: "block",
    textAlign: "center",
    fontSize: 26,
    marginTop: 30,
  },
  sliders: {
    textAlign: "center",
    width: "60%",
    maxWidth: "100% !important",
    maxHeight: "100% !important",
    "& div": {
      width: "100% !important",
      height: "100% !important",
      maxWidth: "100% !important",
      maxHeight: "100% !important",
    },
    // '& img':{
    //     // minWidth: "100% !important",
    //     // maxWidth: "100% !important",
    //     // maxHeight: "100% !important",
    //     // width: "auto",
    //     // width: "auto",
    //     // height: "100% !important",
    //     margin: 0,
    //     // position: "relative",
    //     // top: "10%",
    //     // left: "0%",
    // }
  },
  imgCarousel: {
    maxHeight: "100% !important",
  },
  imgMain: {
    width: "100%",
    marginBottom: 25,
  },
  splideDiv: {
    paddingBottom: "30px",
  },
  videoEmb: {
    width: "100%",
    height: "100%",
  },
  sliderThumb: {
    cursor: "pointer",
  },
  imgTmb: {
    cursor: "pointer",
  },
  divThumb: {
    //paddingLeft: "50px",
    //paddingRight: "50px",
  },
}));

const mainOptions = {
  type: "loop",
  perPage: 1,
  perMove: 1,
  gap: "1rem",
  pagination: false,
  height: "400px",
  arrows: false,
};

const thumbsOptions = {
  type: "slide",
  rewind: true,
  gap: "1rem",
  pagination: false,
  fixedWidth: 48.08,
  fixedHeight: 45.36,
  cover: true,
  focus: "left",
  isNavigation: true,
  padding: { left: 50, right: 50 },
};

export default function SectionProductCarousel(props) {
  const classes = useStyles();

  const [images, setImages] = useState([]);

  const mainRef = React.useRef();

  /**
   * The thumbnail Splide component.
   */
  const thumbsRef = React.useRef();

  useEffect(() => {
    setImages(props.productDetails.prod_imgs);

    if (mainRef.current && thumbsRef.current && thumbsRef.current.splide) {
      mainRef.current.sync(thumbsRef.current.splide);
    }
  }, []);
  return (
    <div className={classes.container}>
      <Splide options={mainOptions} ref={mainRef}>
        {props.productDetails.prod_imgs.map((img) => (
          <SplideSlide key={img.id} className={classes.sliders}>
            {/* <ReactImageMagnify {...{
                            // smallImage: {
                            //     alt: img.alt,
                            //     isFluidWidth: true,
                            //     src: img.urlA,
                            // },
                            // largeImage: {
                            //     src: img.urlA,
                            //     width: 1500,
                            //     height: 1500
                            // },
                            // enlargedImagePosition: "over"
                            smallImage: {
                                alt: "Wristwatch by Ted Baker London",
                                isFluidWidth: true,
                                src: img.urlA,
                                sizes:
                                "(min-width: 800px) 33.5vw, (min-width: 415px) 50vw, 100vw"
                            },
                            largeImage: {
                                alt: "",
                                src: img.urlA,
                                width: 1200,
                                height: 1800
                            },
                            // isHintEnabled: true,
                            enlargedImagePosition: "over"
                        }} /> */}
            <ImageMagnify img={img} />
          </SplideSlide>
        ))}
        {props.productDetails.url_video !== "" ? (
          <SplideSlide key="vid" className={classes.sliders}>
            <iframe
              src={
                "https://www.youtube.com/embed/" +
                props.productDetails.url_video
              }
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
              className={classes.videoEmb}
            />
          </SplideSlide>
        ) : (
          ""
        )}
      </Splide>
      <div className={classes.divThumb}>
        <Splide options={thumbsOptions} ref={thumbsRef}>
          {props.productDetails.prod_imgs.map((img) => (
            <SplideSlide key={img.id} className={classes.sliderThumb}>
              <img src={img.urlA} className={classes.imgTmb} />
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
}
