import { API_URL } from "./api.url.back";

export const homeServices = {
    loadSection,
    getFranjaInformativa,
    getInstitutionalPages,
    getCollection,
    getListadoCategorias,
    getListadoColecciones,
    getListadoColecciones2,
    getListadoColecciones3,
    getListadoColecciones4,
    getListadoProductosPromociones
};

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        const responseData = {
            error: false,
            data: data,
        };
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //location.reload(true);
            }

            //responseData.error = true;
            //return responseData;
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function handleResponseAlternative(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        const responseData = {
            error: false,
            data: data,
        };
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //location.reload(true);
            }

            responseData.error = true;
            return responseData;
        }
        return responseData;
    });
}

function loadSection(idSection) {
    const requestOptions = {
        method: "GET",
    };

    return fetch(`${API_URL}/files/get_file/${idSection}`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
            return jsonData;
        });
}

function getFranjaInformativa() {
    const requestOptions = {
        method: "GET",
    };

    return fetch(`${API_URL}/controles_maestros/control_public/FRANJA_INFORMATIVA`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
            return jsonData;
        });
}

function getInstitutionalPages() {
    const requestOptions = {
        method: "GET",
    };

    return fetch(`${API_URL}/paginas_institucionales/list_public`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
            return jsonData;
        });
}

function getCollection(id) {
    const requestOptions = {
        method: "GET",
    };

    return fetch(`${API_URL}/colecciones/list_public/${id}`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
            return jsonData;
        });
}

function getListadoCategorias() {
    const requestOptions = {
        method: "GET",
    };

    return fetch(`${API_URL}/categoria/list_public`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
            return jsonData;
        });
}

function getListadoProductosPromociones(id) {
    const requestOptions = {
        method: "GET",
    };

    return fetch(`${API_URL}/api-integrations/get-pagination-products/${id}`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
            return jsonData;
        });
}


function getListadoColecciones() {
    const requestOptions = {
        method: "GET",
    };

    return fetch(`${API_URL}/collection/collections/1`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
            return jsonData;
        });
}

function getListadoColecciones2() {
    const requestOptions = {
        method: "GET",
    };

    return fetch(`${API_URL}/collection/collections/2`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
            return jsonData;
        });
}

function getListadoColecciones3() {
    const requestOptions = {
        method: "GET",
    };

    return fetch(`${API_URL}/collection/collections/3`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
            return jsonData;
        });
}

function getListadoColecciones4() {
    const requestOptions = {
        method: "GET",
    };

    return fetch(`${API_URL}/collection/collections/4`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
            return jsonData;
        });
}