import React, {useEffect, useState} from 'react';
import {categoryServices} from '../../../../_services/category.services';
import PropTypes, { array } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Typography } from '@mui/material';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import useCheckMobileScreen from "components/Mobile/Mobile.js";
import SectionTitleMain from '../SectionTitles/SectionTitleMain';
import Carousel from "react-elastic-carousel";
import styled from "styled-components";
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import { Block } from '@material-ui/icons';

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550,  itemsToScroll: 2 },
  { width: 768, itemsToShow: 5 },
  { width: 1200, itemsToShow: 5 },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#fff",
  },
  tabHeader: {
    backgroundColor: "#fff",
    color: "#0b3196",
  },
  indicatorBkg: {
    background: "#0b3196",
   // background: "transparent",
   // borderTop: "15px solid #40df00",
   // borderLeft: "10px solid transparent",
   // borderRight: "10px solid transparent",
    width: "15% !important",
    height: 10,
    top: 47,
   // marginLeft: 70,
    position: "absolute",
    clipPath: "polygon(50% 0%, 60% 80%, 100% 80%, 100% 100%, 0 100%, 0 80%, 40% 80%)",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
    },
    //bottom: "-10px",
    zIndex: "02",
  },
  tabBarDiv: {
    backgroundColor: "#fff",
    boxShadow: "none",
    borderBottom: "2px solid #0b3196",
    marginTop:"25px"
  },
  tabTitle: {
    fontSize: "18px",
    fontFamily: "'Centrale Sans Medium'",
    color: "#40df00",
    paddingLeft: 0,
    paddingRight: 50,
    fontWeight: 400,
    maxWidth: "none",
    width:"19% !important",
    lineHeight: "normal",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      padding: "10px 0px",
    },
  },

  descrDiv: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 30,
    paddingLeft: 15,
    paddingRight: 15,

    textAlign: "justify",
    fontWeight: 400,
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "24px",
  },
  carouselDivider: {
    border: "2px solid #40DF00",
    backgroundColor: "#40DF00",
    marginTop: 8,
    borderRadius: 20,
  },
  carouselContent: {
    marginTop: 6,
  },
  divItemContainer:{
    width: 200,
    border: "1px solid #efefef",
    borderRadius: '10px',
  },
  titleItemCard:{
    lineHeight: "normal",
    fontWeight: '700 !important',
    fontSize: 20,
    color: "#0b3196",
    textAlign: "left",
    marginBottom: 0
  },
  linkProds:{
    color: "#ffffff",
    fontSize: 16,
    backgroundColor: "#0C2D9C",
    padding: "10px 25px",
    borderRadius: 5,
    cursor: "pointer",
    "&:hover":{
      color: "#ffffff",
    }
  },
  contProds:{
    textAlign: "center",
    display: "none"
  },
  imageSubCat:{
    maxWidth: "168px",
    height: "168px",
  },

  qty:{
    fontSize: "12px",
    color: "#727272",
    fontWeight: 400 
  },
  titlesCont:{
    height:"66px"
  },
  fielDesc:{
    color: "#000000",
    textAlign: "left"
  },
  ocultar:{
    display: "none",
  },
  visualizar:{
    display: "block",
  }
  
}));


export default  function SectionSomosMayoristas (props) {
  
  const classes = useStyles();
  let isMobile = useCheckMobileScreen();
  const [value, setValue] = useState(3);
  const [subCats, setSubCats] = useState([]);
  const [arrayCounts, setArrayCounts] = useState([]);
  

  const Item = styled.div`
    display: flex grow
    //justify-content: center;
    //align-items: center;
    //height: 150px;
    background-color: transparent;
    color: #fff;
    font-size: 4em;
    padding: 15px;
  `;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectedCat = async (e,nomCat,index) =>{
    setArrayCounts([]);
    e.preventDefault();
    const arraySubCats = props.arrayCats[index].hijos;
    setSubCats(arraySubCats);
    let arrayAux = [];
    for(let i = 0; i< arraySubCats.length; i++){
      if(arraySubCats[i].cat_cmm_estatus_id == 1000010){
        let idCategoria = `${arraySubCats[i].cat_categoria_id}`;
        let page = 0;
        let pagesize = 12;
        let orderBy = "fecha lanzamienta";
        let orderByType = "asc";
        let idSocioNegocio = "0";
        const response = await categoryServices.getCategoryData(
          idCategoria,
          page,
          pagesize,
          orderBy,
          orderByType,
          idSocioNegocio
        );
        const cantidadPrductos = response.mainConsultaProductos.count;
        arrayAux.push({
          idSubcat: arraySubCats[i].cat_categoria_id,
          cantidadP: cantidadPrductos,
        })
      }
    }
    setArrayCounts(arrayAux);
  };

  const handleVerBoton = (e,id) => {
    e.preventDefault();
    let btn = document.getElementById(`btn_${id}`);
    let desc = document.getElementById(`desc_${id}`);

    btn.classList.remove(classes.ocultar);
    btn.classList.add(classes.visualizar);
    desc.classList.add(classes.ocultar);
    desc.classList.remove(classes.visualizar);
  }

  const handleOcultarDesc = (e, id) =>{
    e.preventDefault();
    let btn = document.getElementById(`btn_${id}`);
    let desc = document.getElementById(`desc_${id}`);

    desc.classList.remove(classes.ocultar);
    desc.classList.add(classes.visualizar);
    btn.classList.add(classes.ocultarBoton);
    btn.classList.remove(classes.visualizar);
  }
  
  return (
      <>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            color: "#0b3196",
            marginTop: 1,
            fontFamily: "Centrale Sans Medium",
          }}
        >
          Categorías Populares
        </Typography>
        <AppBar position="static" className={classes.tabBarDiv}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="scrollable auto tabs example"
            className={classes.tabHeader}
            classes={{ indicator: classes.indicatorBkg }}
            //orientation={isMobile ? "vertical" : "horizontal"}
            variant="scrollable"
            scrollButtons="auto"
          >
            {props.arrayCats.map((item,index) =>{
              return (
                item.cat_cmm_estatus_id === 1000010 && item.hijos.length > 0 ?(
                  <Tab
                    key={index}
                    label={item.cat_nombre_tienda}
                    className={classes.tabTitle}
                    {...a11yProps(index)}
                    onClick={e => handleSelectedCat(e,item.cat_nombre,index)}
                  />
                ):""
              )
              
            })}
          </Tabs>
        </AppBar>
                {props.arrayCats.map((itemCat, index) => (
                  <TabPanel
                      key={index}
                      value={value}
                      index={index}
                      style={{ marginTop: 15, textAlign: "justify" }}
                    >
                        <Carousel  breakPoints={breakPoints}  enableAutoPlay={true} autoPlaySpeed={5000}>
                          {subCats.length == 0 ? (
                            
                            props.subCatsDefault.map((o, index) => {
                             
                              if(o.cat_cmm_estatus_id == 1000010){
                                return(
                                  <div className={classes.divItemContainer}
                                key={`carousel-clients-id-${index}`}
                              >
                                <Item key={o.index}
                                  onMouseEnter={(e) => handleVerBoton(e, o.cat_categoria_id)}
                                  onMouseLeave={(e)=> handleOcultarDesc(e, o.cat_categoria_id)}
                                >
                                  <div>
                                    <div>
                                      <Typography className={classes.titleItemCard}>{o.cat_nombre_tienda}</Typography>
                                      {props.arrayCont.map((itemC, iter) => (<Typography key={iter} className={classes.qty}>{itemC.idSubcat == o.cat_categoria_id ? `${itemC.cantidadP} productos`:""}</Typography>))}
                                    </div>
                                    
                                      <div style={{width: "100%", textAlign: "center"}}>
                                      {o.cat_img_link != null ? (
                                        <img className={classes.imageSubCat} src={`${process.env.REACT_APP_API_URL_IMG}/${o.cat_img_link}`} />
                                      ):(
                                        <BrokenImageIcon style={{color: "#EFEFEF", fontSize: 98}}/>
                                      )} 
                                    </div>
                                      <Box
                                        sx={{
                                          height: '22px',
                                        }}
                                      >
                                        <Typography id={`btn_${o.cat_categoria_id}`} className={classes.contProds}>
                                          <a href={`/categorias/${o.cat_categoria_link}`} className={classes.linkProds}>Ver productos</a>
                                        </Typography>
                                        <Typography id={`desc_${o.cat_categoria_id}`} className={classes.fielDesc}></Typography>
                                      </Box>
                                  </div>
                                </Item>
                              </div>
                                )
                              }
                              
                              })
                          ):(
                            subCats.map((o, index) => {
                              if(o.cat_cmm_estatus_id == 1000010){
                                return(
                                  <div className={classes.divItemContainer}  key={`carousel-clients-id-${index}`}>
                                  <Item key={o.index}
                                    onMouseEnter={(e) => handleVerBoton(e, o.cat_categoria_id)}
                                    onMouseLeave={(e)=> handleOcultarDesc(e, o.cat_categoria_id)}
                                  >
                                    <div>
                                      <div className={classes.titlesCont}>
                                        <Typography className={classes.titleItemCard}>{o.cat_nombre_tienda} </Typography>
                                        {arrayCounts.map((itemC, iter) =>( <Typography key={iter} className={classes.qty}>{itemC.idSubcat == o.cat_categoria_id ? `${itemC.cantidadP} productos`:""}</Typography>))}
                                      </div>
                                      
                                      <div style={{width: "100%", textAlign: "center"}}>
                                      {o.cat_img_link != null ? (
                                        <img className={classes.imageSubCat} src={`${process.env.REACT_APP_API_URL_IMG}/${o.cat_img_link}`} />
                                      ):(
                                        <BrokenImageIcon style={{color: "#EFEFEF", fontSize: 135}}/>
                                      )} 
                                      </div>
                                        <p id={`btn_${o.cat_categoria_id}`} className={classes.contProds}>
                                          <a href={`/categorias/${o.cat_categoria_link}`} className={classes.linkProds}>Ver productos</a>
                                        </p>
                                        <p id={`desc_${o.cat_categoria_id}`} className={classes.fielDesc}>{o.cat_descripcion}</p>
                                      
                                    </div>
                                  </Item>
                                </div>
                                  )
                              }
                              

                              
                            })
                          )}
                        </Carousel>
                    </TabPanel>
    
                ))}
               
      </>
    
  )
}
