export const transformJson =(data) => {
    return data.map(item => {
        let [id, name] = item.split('|');
        return { id, name };
    });
}

export const transformedValues =(values)=> values.map((value, index) => ({
    id: index + 1,  
    name: value,
    checked: false
})) 