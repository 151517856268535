import React, {
  useContext,
  createContext,
  useState,
  useRef,
  useEffect,
  Fragment,
} from "react";
import { userService } from "../../../_services/user.service";
import { generalService } from "../../../_services/general.service";
import { Redirect } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";

// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SectionBreadcrumb from "views/Home/Sections/SectionBreadcrumb.js";

// @material-ui/
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useAuth } from "hooks/useAuth";
// core components
import useCheckMobileScreen from "components/Mobile/Mobile.js";

const CustomCheckbox = withStyles({
  root: {
    color: "#40DF00",
    "&$checked": {
      color: "#40DF00",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& ~ label": {
      color: "#000000",
      fontSize: 12,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    margin: "auto",
    width: "95%",
    paddingBottom: 30,
  },
  spaceDiv: {
    marginTop: 15,
    marginBottom: 15,
  },
  linkBack: {
    fontFamily: "'Centrale Sans Medium'",
    color: "#0B3196",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "19px",
    textAlign: "center",
    paddingLeft: "30px",
    position: "relative",
  },
  linkBackArrow: {
    position: "absolute",
    left: "5px",
    bottom: "-2px",
  },
  title: {
    fontFamily: "'Centrale Sans Bold'",
    textTransform: "uppercase",
    fontSize: 24,
    lineHeight: "29px",
    textAlign: "justify",
    color: "#0b3196",
    paddingTop: 20,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  titleDivider: {
    border: "1px solid #0B3196",
    width: "95%",
    margin: "auto",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: "30px 0",
  },
  //formData
  formData: {
    backgroundColor: "#F5F5F5",
    borderRadius: 2,
    margin: "10px 0",
    border: "1px solid #EAEAEA",
    "& *": {
      margin: "3px 8px 2px 3px",
      padding: 0,
      fontFamily: "'Centrale Sans Regular'",
      border: "none",
    },
  },
  formDataTitle: {
    color: "#0B3196",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "23px",
    textAlign: "center",
  },
  formDataTitleDes: {
    color: "#0C2D9C",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 22,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "27px",
    textAlign: "justify",
  },
  formDataTitleMov: {
    color: "#0C2D9C",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "27px",
    textAlign: "center",
  },
  formDataSubTitleDes: {
    color: "#0C2D9C",
    textTransform: "uppercase",
  },
  gridContent: {
    paddingBottom: 30,
  },
  buttonDelete: {
    border: "none",
    color: "#8e8a8a",
    backgroundColor: "transparent",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    margin: "auto",
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  buttonBack: {
    border: "1px solid",
    color: "#0C2D9C",
    backgroundColor: "transparent",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    margin: 0,
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  buttonUpdate: {
    border: "1px solid",
    color: "#0C2D9C",
    backgroundColor: "transparent",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    margin: "auto",
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  buttonUpdateSecond: {
    border: "1px solid",
    backgroundColor: "#0C2D9C",
    color: "#ffffff",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  inputs: {
    width: "100%",
    border: "1px solid #EAEAEA",
    borderRadius: "2px",
    backgroundColor: "#FFFFFF",
  },
  //alerts
  alerts: {
    color: "#FFFFFF",
    backgroundColor: "#40DF00",
    fontFamily: "'Centrale Sans Light'",
    fontSize: 22,
    "& .MuiAlert-icon": {
      color: "#FFFFFF",
    },
    "&.MuiAlert-standardError": {
      backgroundColor: "#DF2B2B",
    },
  },
  //defaultAddress
  defaultAddressGrid: {
    margin: "35px 0",
  },
  defaultAddressDiv: {
    border: "1px solid #000000",
    padding: 20,
    margin: "10px 0",
    borderRadius: 3,
  },
  defaultAlias: {
    color: "#0C2D9C",
    fontFamily: "'Centrale Sans Bold'",
    fontSize: 18,
    fontWeight: "bold",
    letterSpacing: 0,
    lineHeight: "23px",
  },
  defaultAddress: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    fontWeight: "500",
    letterSpacing: 0,
    lineHeight: "19px",
  },
  buttonBackDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "10px 0",
  },
  actionButtonsDes: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  actionButtonsMov: {},
  //selecte
  formControl: {
    minWidth: "100%",
    "& label": {
      transform: "translate(14px, 14px) scale(1)",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selects: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #e8e8e8",
    borderRadius: 4,
    height: 37,
    fontFamily: "'Centrale Sans Regular'",
    margin: 0,
    padding: 0,
  },
}));

export default function ProfileAddress(props) {
  const classes = useStyles();
  const confirm = useConfirm();

  let history = useHistory();
  const location = useLocation();
  let isMobile = useCheckMobileScreen();
  const { openAlert, setOpenAlert } = useAuth();
  const [dataBreadCrumb, setDataBreadCrumb] = useState([]);
  const [user, setUser] = useState({});
  const [permisosUser, setPermisosUser] = useState({});
  const [openFormDataAddress, setOpenFormDataAddress] = useState(false);
  const [actionForm, setActionForm] = useState("create");
  const [selectedAddress, setSelectedAddress] = useState({});

  const [addressess, setAddressess] = useState([]);
  const [addressDefault, setAddressDefault] = useState([]);

  const [alias, setAlias] = useState("");
  const [codigopostal, setCodigopostal] = useState("");
  const [calle, setCalle] = useState("");
  const [calle1, setCalle1] = useState("");
  const [calle2, setCalle2] = useState("");
  const [numinterior, setNuminterior] = useState("");
  const [numexterior, setNumexterior] = useState("");
  const [colonia, setColonia] = useState("");
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [telefono, setTelefono]= useState("")
  const [nombre, setNombre]= useState("")

  const [pais, setPais] = useState([]);
  const [paisSelected, setPaisSelected] = useState(0);
  const [estado, setEstado] = useState([]);
  const [estadoSelected, setEstadoSelected] = useState(0);
  const [ciudad, setCiudad] = useState([]);
  const [ciudadSelected, setCiudadSelected] = useState({
    id_city: 0,
    city: "",
  });

  useEffect(() => {
    console.log('permisosUser ', permisosUser)
  }, [permisosUser])

  useEffect(() => {
    if (!localStorage.getItem("user")) {
        //redirect to login
        history.push("/login");
    } else {
        setUser(JSON.parse(localStorage.getItem("user")).usuario);
    }

    setDataBreadCrumb(() => [
      {
        name: "INICIO",
        link: "/",
      },
      {
        name: "MI CUENTA",
        link: "/myprofile/perfil",
      },
      {
        name: "MIS DIRECCIONES",
        link: "",
      }
    ]);

    getPermisos();
    getAddressess();
    getPais();
  }, []);

  const getPermisos = async () => {
    const selectedIndex = location.pathname;
    if (JSON.parse(localStorage.getItem("user"))) {
      if (JSON.parse(localStorage.getItem("user")).usuario.snu_menu_roles) {

        let result_permiso;
        result_permiso = await JSON.parse(
          localStorage.getItem("user")
        ).usuario.snu_menu_roles.find((item) => `/myprofile/${item.key}` === selectedIndex);

        if (result_permiso === undefined) {
          JSON.parse(localStorage.getItem("user")).usuario.snu_menu_roles.map(
            (modulo) => {
              if (modulo.submenu) {
                result_permiso = modulo.submenu.find(
                  (item) => `/myprofile/perfil/${item.key}` === selectedIndex
                );
              }
            }
          );
        }
        setPermisosUser(result_permiso);
      }
    }
  };

  const getAddressess = async () => {
    const client_address_response = await userService.getAddressByClientCardCode(
      JSON.parse(localStorage.getItem("user")).usuario.snu_cardcode
    );
    console.log('data de direcciones ', client_address_response.data.listaSnubycardcode);
    setAddressess(client_address_response.data.listaSnubycardcode);
    setAddressDefault(
      client_address_response?.data?.listaSnubycardcode?.filter(
        (add) => add.snd_direccion_envio_default === true
      )
    );
  };

  const getPais = async () => {
    const pais_response = await generalService.getCountries();
    setPais(pais_response.paises);
  };

  const getEstado = async (paisId) => {
    const estado_response = await generalService.getStates(paisId);
    setEstado(estado_response.estados);
  };

  const getCiudad = async (estado_id) => {
    const cities_response = await generalService.getCities(estado_id);
    setCiudad(cities_response.data.constCiudadesEstados.rows);
  };

  const handleChange = (event) => {
    if (event.target.name === "pais") {
      setEstadoSelected(0);
      setPaisSelected(event.target.value);
      getEstado(event.target.value);
    }
    if (event.target.name === "estado") {
      setEstadoSelected(event.target.value);
      getCiudad(event.target.value);
    }
    if (event.target.name === "ciudad") {
      let city_name = "";
      for (let index = 0; index < ciudad.length; index++) {
        if (
          ciudad[index].city_ciudades_estados_id ===
          parseInt(event.target.value)
        ) {
          city_name = ciudad[index].city_ciudad;
          break;
        }
      }
      setCiudadSelected({
        id_city: event.target.value,
        city: city_name,
      });
    }
    if (event.target.name === "alias") {
      const onlyLettersValue = event.target.value.replace(/[^A-Za-z\s]/g, '');
      setAlias(onlyLettersValue);
    }
    if (event.target.name === "codigopostal") {
      setCodigopostal(event.target.value);
    }
    if (event.target.name === "calle") {
      setCalle(event.target.value);
    }
    if (event.target.name === "calle1") {
      setCalle1(event.target.value);
    }
    if (event.target.name === "calle2") {
      setCalle2(event.target.value);
    }
    if (event.target.name === "numexterior") {
      setNumexterior(event.target.value);
    }
    if (event.target.name === "numinterior") {
      setNuminterior(event.target.value);
    }
    if (event.target.name === "colonia") {
      setColonia(event.target.value);
    }
    if (event.target.name === "telefono"){
      setTelefono(event.target.value);
    const phoneRegex = /^\d{10}$/;
    if (phoneRegex.test(event.target.value)) {
      //Si es un numero
      setIsValidPhone(true);
    } else {
      setIsValidPhone(false);
    }
    }
    if(event.target.name ==="nombre"){
      setNombre(event.target.value)
    }

  };

  const handleFormAction = (action, address) => {
    setOpenFormDataAddress(true);
    setActionForm(action);

    if (action === "edit") {
      setSelectedAddress(address);

      //"here fill all the input ref textfield of the form"
      setAlias(address.snd_alias);
      setCodigopostal(address.snd_codigo_postal);
      setCalle(address.snd_direccion);
      setCalle1(address.snd_calle1);
      setCalle2(address.snd_calle2);
      setNumexterior(address.snd_direccion_num_ext);
      setNuminterior(address.snd_direccion_num_int);
      setColonia(address.snd_colonia);
      setPaisSelected(address.snd_pais_id);
      getEstado(address.snd_pais_id);
      setEstadoSelected(address.snd_estado_id);
      getCiudad(address.snd_estado_id);
      setTelefono(address.snd_telefono)

      findCity(address.snd_estado_id, address.snd_ciudad);

      if(isMobile){
        setTimeout(()=>{
          const addClasses = document.getElementById('inputLabelEstado');
          addClasses.classList.add('MuiInputLabel-shrink');
        },500)
        
      }

    } else {
      setSelectedAddress({});
      setAlias("");
      setCodigopostal("");
      setCalle("");
      setCalle1("");
      setCalle2("");
      setNumexterior("");
      setNuminterior("");
      setColonia("");
      setPaisSelected(0);
      setTelefono("")
      setEstadoSelected(0);
      setCiudadSelected({
        id_city: 0,
        city: "",
      });
    }
  };

  const findCity = async (id_state, city_name) => {
    const cities_response = await generalService.getCities(id_state);

    let city_id = 0;
    for (
      let index = 0;
      index < cities_response.data.constCiudadesEstados.rows.length;
      index++
    ) {
      const city = cities_response.data.constCiudadesEstados.rows[index];
      if (city.city_ciudad === city_name) {
        city_id = city.city_ciudades_estados_id;
        break;
      }
    }
    setCiudadSelected({
      id_city: city_id,
      city: city_name,
    });
  };

  const handleClickDefaultAddress = (add) => {
    confirm({
      title: `Cambio de dirección`,
      description: `¿Está seguro de cambiar 
      la dirección predeterminada?`,
      confirmationButtonProps: { autoFocus: true },
      cancellationButtonProps: { autoFocus: true },
      confirmationText: `Cambiar mi dirección`,
      cancellationText: `Dejar mi dirección actual`,
    }).then(() => handleChangeDefaultAddress(add));
  };

  const handleChangeDefaultAddress = async (add) => {
    if (!addressDefault[0]) {
      const add_default_address_response = await userService.updateClientDefaultAddress(
        add.snd_direcciones_id,
        "true"
      );

      if (add_default_address_response.error) {
        setOpenAlert({
          message: "Hubo un error al definir la dirección por defecto.",
          type: "error",
          open: true,
        });
      } else {
        setOpenAlert({
          message: "La dirección fue definida por defecto exitosamente.",
          type: "success",
          open: true,
        });
        getAddressess();
      }
    } else {
      const remove_default_address_response = await userService.updateClientDefaultAddress(
        addressDefault[0].snd_direcciones_id,
        "false"
      );

      if (!remove_default_address_response.error) {
        const add_default_address_response = await userService.updateClientDefaultAddress(
          add.snd_direcciones_id,
          "true"
        );

        if (add_default_address_response.error) {
          setOpenAlert({
            message: "Hubo un error al definir la dirección por defecto.",
            type: "error",
            open: true,
          });
        } else {
          setOpenAlert({
            message: "La dirección fue definida por defecto exitosamente.",
            type: "success",
            open: true,
          });
          getAddressess();
        }
      }
    }
  };

  const deleteAddressConfirm = (add) => {
    confirm({
      title: `Eliminar dirección`,
      description: `¿Está seguro de eliminar 
      la dirección?`,
      confirmationButtonProps: { autoFocus: true },
      cancellationButtonProps: { autoFocus: true },
      confirmationText: `Aceptar`,
      cancellationText: `Cancelar`,
    }).then(() => deleteAddress(add));
  };

  const deleteAddress = async (address) => {
    const delete_address_response = await userService.deleteClientAddress(
      address.snd_direcciones_id
    );

    if (delete_address_response.error) {
      setOpenAlert({
        message: "Hubo un error intentado eliminar la dirección.",
        type: "error",
        open: true,
      });
    } else {
      setOpenAlert({
        message: "La dirección fue eliminada exitosamente.",
        type: "success",
        open: true,
      });
      getAddressess();
    }
  };

  const createAddress = async (event) => {
    event.preventDefault();

    const create_address_client_response = await userService.createAddressClient(
      alias,
      codigopostal,
      calle,
      calle1,
      calle2,
      numexterior,
      numinterior,
      colonia,
      paisSelected,
      estadoSelected,
      ciudadSelected.city,
      user.snu_usuario,
      telefono,
     // user.snu_telefono,
      user.snu_cardcode
    );

    if (create_address_client_response.error) {
      setOpenAlert({
        message: "Hubo un error creando la dirección.",
        type: "error",
        open: true,
      });
    } else {
      setOpenAlert({
        message: "La dirección fue creada correctamente.",
        type: "success",
        open: true,
      });
      //if success the open the vie form section
      setOpenFormDataAddress(false);
      getAddressess();
    }
  };

  const updateAddress = async (event) => {
    event.preventDefault();
    const update_address_client_response = await userService.updateAddressClient(
      selectedAddress,
      alias,
      codigopostal,
      calle,
      calle1,
      calle2,
      numexterior,
      numinterior,
      colonia,
      paisSelected,
      estadoSelected,
      ciudadSelected.city,
      user.snu_usuario,
      telefono,
      user.snu_cardcode,
      user.snu_usuario_snu_id
    );

    if (update_address_client_response.error) {
      setOpenAlert({
        message: "Hubo un error actualizando su dirección.",
        type: "error",
        open: true,
      });
    } else {
      setOpenAlert({
        message: "Su dirección fue actualizada correctamente.",
        type: "success",
        open: true,
      });
      //if success the open the vie form section
      setOpenFormDataAddress(false);
      getAddressess();
    }
  };

  return (
    <div className={classes.root}>
     

      <div className={classes.spaceDiv}>
        <SectionBreadcrumb route={dataBreadCrumb} />
        <Typography className={classes.title}>
          {actionForm === "edit"
            ? "Modifica la dirección"
            : "Direcciones de envío"}
        </Typography>
        {isMobile ? <Divider className={classes.titleDivider} /> : ""}
      </div>

      {openFormDataAddress ? (
        <Grid container spacing={0}>
          <Grid item xs={12} className={classes.gridContent}>
            {isMobile ? (
              ""
            ) : (
              <Typography className={classes.formDataTitleDes}>
                {actionForm === "create"
                  ? "Ingresa la nueva dirección"
                  : "Actualiza la dirección"}
              </Typography>
            )}
          </Grid>

          <div className={classes.formData}>
            <form
              onSubmit={actionForm === "create" ? createAddress : updateAddress}
            >
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  {isMobile ? (
                    <Grid container>
                      <Typography className={classes.formDataTitleMov}>
                        Registro de datos
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          className={classes.inputs}
                          size="small"
                          onChange={handleChange}
                          value={alias}
                          id="alias"
                          name="alias"
                          type="text"
                          placeholder="Alias/Nombre"
                        />
                      </Grid>

                      <Typography className={classes.formDataTitleMov}>
                        Domicilio
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          className={classes.inputs}
                          size="small"
                          onChange={handleChange}
                          value={codigopostal}
                          id="codigopostal"
                          name="codigopostal"
                          type="text"
                          placeholder="Código postal"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          className={classes.inputs}
                          size="small"
                          onChange={handleChange}
                          value={calle}
                          id="calle"
                          name="calle"
                          type="text"
                          placeholder="Calle"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          className={classes.inputs}
                          size="small"
                          onChange={handleChange}
                          value={calle1}
                          id="calle1"
                          name="calle1"
                          type="text"
                          placeholder="Entrecalle 1"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          className={classes.inputs}
                          size="small"
                          onChange={handleChange}
                          value={calle2}
                          id="calle2"
                          name="calle2"
                          type="text"
                          placeholder="Entrecalle 2"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          className={classes.inputs}
                          size="small"
                          onChange={handleChange}
                          value={numexterior}
                          id="numexterior"
                          name="numexterior"
                          type="text"
                          placeholder="Número Ext."
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          className={classes.inputs}
                          size="small"
                          onChange={handleChange}
                          value={numinterior}
                          id="numinterior"
                          name="numinterior"
                          type="text"
                          placeholder="Número Int."
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          className={classes.inputs}
                          size="small"
                          onChange={handleChange}
                          value={telefono}
                          id="telefono"
                          name="telefono"
                          type="text"
                          error={!isValidPhone}
                          helperText={
                            !isValidPhone
                              ? "Por favor, ingresa un número de teléfono válido."
                              : ""
                          }
                          placeholder="Celular"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          className={classes.inputs}
                          size="small"
                          onChange={handleChange}
                          value={colonia}
                          id="colonia"
                          name="colonia"
                          type="text"
                          placeholder="Colonia"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          required
                          fullWidth
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel htmlFor="outlined-pais-native-simple">
                            País
                          </InputLabel>
                          <Select
                            className={classes.selects}
                            native
                            value={paisSelected}
                            label="País"
                            onChange={handleChange}
                            inputProps={{
                              name: "pais",
                              id: "outlined-pais-native-simple",
                            }}
                          >
                            <option value=""></option>
                            {pais?.map((p) => {
                              return (
                                <option value={p.pais_pais_id}>
                                  {p.pais_nombre}
                                </option>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>

                      {paisSelected === 52 || paisSelected === "52" ? (
                        <Grid item xs={12}>
                          <FormControl
                            required
                            fullWidth
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <InputLabel id="inputLabelEstado" htmlFor="outlined-estado-native-simple">
                              Estado
                            </InputLabel>
                            <Select
                              className={classes.selects}
                              native
                              value={estadoSelected}
                              label="Estado"
                              onChange={handleChange}
                              inputProps={{
                                name: "estado",
                                id: "outlined-estado-native-simple",
                              }}
                            >
                              <option value=""></option>
                              {estado?.map((e) => {
                                return (
                                  <option value={e.estpa_estado_pais_id}>
                                    {e.estpa_estado_nombre}
                                  </option>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>
                  ) : (
                    <Grid container>
                      {" "}
                      {/* >>>>>>>>>>>>>>>>>>>>>>>>> Formulario de Añadir Direccion <<<<<<<<<<<<<<<<<<<<<<<<< */}
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={3}>
                            <Typography className={classes.formDataSubTitleDes}>
                              Alias/Nombre
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              required
                              fullWidth
                              variant="outlined"
                              className={classes.inputs}
                              size="small"
                              onChange={handleChange}
                              value={alias}
                              id="alias"
                              name="alias"
                              type="text"
                              label=""
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={3}>
                            <Typography className={classes.formDataSubTitleDes}>
                              Código postal
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              required
                              fullWidth
                              variant="outlined"
                              className={classes.inputs}
                              size="small"
                              onChange={handleChange}
                              value={codigopostal}
                              id="codigopostal"
                              name="codigopostal"
                              type="text"
                              label=""
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={3}>
                            <Typography className={classes.formDataSubTitleDes}>
                              Calle
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              required
                              fullWidth
                              variant="outlined"
                              className={classes.inputs}
                              size="small"
                              onChange={handleChange}
                              value={calle}
                              id="calle"
                              name="calle"
                              type="text"
                              label=""
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={3}>
                            <Typography className={classes.formDataSubTitleDes}>
                              Entrecalles
                            </Typography>
                          </Grid>
                          <Grid item xs={4} style={{ display: "flex" }}>
                            <TextField
                              variant="outlined"
                              className={classes.inputs}
                              size="small"
                              onChange={handleChange}
                              value={calle1}
                              id="calle1"
                              name="calle1"
                              type="text"
                              label=""
                            />
                            <TextField
                              variant="outlined"
                              className={classes.inputs}
                              size="small"
                              onChange={handleChange}
                              value={calle2}
                              id="calle2"
                              name="calle2"
                              type="text"
                              label=""
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={3}>
                            <Typography className={classes.formDataSubTitleDes}>
                              Número
                            </Typography>
                          </Grid>
                          <Grid item xs={4} style={{ display: "flex" }}>
                            <TextField
                              required
                              variant="outlined"
                              className={classes.inputs}
                              size="small"
                              onChange={handleChange}
                              value={numexterior}
                              id="numexterior"
                              name="numexterior"
                              type="text"
                              label=""
                            />
                            <TextField
                              variant="outlined"
                              className={classes.inputs}
                              size="small"
                              onChange={handleChange}
                              value={numinterior}
                              id="numinterior"
                              name="numinterior"
                              type="text"
                              label=""
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={3}>
                            <Typography className={classes.formDataSubTitleDes}>
                              Colonia
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              required
                              fullWidth
                              variant="outlined"
                              className={classes.inputs}
                              size="small"
                              onChange={handleChange}
                              value={colonia}
                              id="colonia"
                              name="colonia"
                              type="text"
                              label=""
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={3}>
                            <Typography className={classes.formDataSubTitleDes}>
                              Celular
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              required
                              fullWidth
                              variant="outlined"
                              className={classes.inputs}
                              size="small"
                              onChange={handleChange}
                              value={telefono}
                              id="telefono"
                              name="telefono"
                              type="text"
                              label=""
                              error={!isValidPhone}
                              helperText={
                                !isValidPhone
                                  ? "Por favor, ingresa un número de teléfono válido."
                                  : ""
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={3}>
                            <Typography className={classes.formDataSubTitleDes}>
                              Pais
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl
                              required
                              fullWidth
                              variant="outlined"
                              className={classes.formControl}
                            >
                              <InputLabel htmlFor="outlined-pais-native-simple"></InputLabel>
                              <Select
                                className={classes.selects}
                                native
                                value={paisSelected}
                                label="País"
                                onChange={handleChange}
                                inputProps={{
                                  name: "pais",
                                  id: "outlined-pais-native-simple",
                                }}
                              >
                                <option value=""></option>
                                {pais?.map((p) => {
                                  return (
                                    <option value={p.pais_pais_id}>
                                      {p.pais_nombre}
                                    </option>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      {paisSelected === 52 || paisSelected === "52" ? (
                        <Fragment>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={3}>
                                <Typography
                                  className={classes.formDataSubTitleDes}
                                >
                                  Estado
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl
                                  required
                                  fullWidth
                                  variant="outlined"
                                  className={classes.formControl}
                                >
                                  <InputLabel htmlFor="outlined-estado-native-simple"></InputLabel>
                                  <Select
                                    className={classes.selects}
                                    native
                                    value={estadoSelected}
                                    label="Estado"
                                    onChange={handleChange}
                                    inputProps={{
                                      name: "estado",
                                      id: "outlined-estado-native-simple",
                                    }}
                                  >
                                    <option value=""></option>
                                    {estado?.map((e) => {
                                      return (
                                        <option value={e.estpa_estado_pais_id}>
                                          {e.estpa_estado_nombre}
                                        </option>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={3}>
                                <Typography
                                  className={classes.formDataSubTitleDes}
                                >
                                  Ciudad
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl
                                  required
                                  fullWidth
                                  variant="outlined"
                                  className={classes.formControl}
                                >
                                  <InputLabel htmlFor="outlined-ciudad-native-simple"></InputLabel>
                                  <Select
                                    className={classes.selects}
                                    native
                                    value={ciudadSelected.id}
                                    label="Ciudad"
                                    onChange={handleChange}
                                    inputProps={{
                                      name: "ciudad",
                                      id: "outlined-ciudad-native-simple",
                                    }}
                                  >
                                    <option value=""></option>
                                    {ciudad?.map((c) => {
                                      return (
                                        <option
                                          value={c.city_ciudades_estados_id}
                                        >
                                          {c.city_ciudad}
                                        </option>
                                      );
                                    })}
                                  </Select>
                                  <span style={{ fontSize: 10 }}>
                                    {ciudadSelected.city}
                                  </span>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Fragment>
                      ) : (
                        ""
                      )}
                    </Grid>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.buttonUpdateSecond}
                    type="submit"
                  >
                    Guardar datos
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>

          <Grid item xs={12} md={6}>
            <div className={classes.buttonBackDiv}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.buttonBack}
                onClick={() => {
                  setOpenFormDataAddress(false);
                }}
              >
                Atrás
              </Button>
            </div>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={0}>
          <Grid item xs={12} className={classes.defaultAddressGrid}>
            <Grid container>
              <Grid xs={12}>
                <Typography className={classes.formDataTitleDes}>
                  Dirección predeterminada
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.defaultAddressDiv}>
                  {addressDefault[0] ? (
                    <Fragment>
                      <Typography className={classes.defaultAlias}>
                        {addressDefault[0].snd_alias}
                      </Typography>
                      <Typography className={classes.defaultAddress}>{`${
                        addressDefault[0].snd_direccion
                      } No.Ext ${
                        addressDefault[0].snd_direccion_num_ext
                      } No.Int ${addressDefault[0].snd_direccion_num_int} e/ ${
                        addressDefault[0].snd_calle1
                      } ${addressDefault[0].snd_calle2}, ${
                        addressDefault[0].snd_colonia
                      },  ${
                        addressDefault[0].snd_estado_id
                          ? addressDefault[0].estado.estpa_estado_nombre
                          : ""
                      } ${addressDefault[0].pai.pais_nombre} C.P ${
                        addressDefault[0].snd_codigo_postal
                      }`}</Typography>
                    </Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                {permisosUser
                  && permisosUser?.permisos?.map((p) => {
                      if (p.key === "create" && p.permiso === true) {
                        return (
                          <div className={classes.actionButtonsDes}>
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.buttonUpdateSecond}
                              onClick={() => handleFormAction("create")}
                            >
                              Añadir nueva dirección
                            </Button>
                          </div>
                        );
                      }
                    })}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.gridContent}>
            {isMobile ? (
              ""
            ) : (
              <Typography className={classes.formDataTitleDes}>
                Direcciones guardadas
              </Typography>
            )}

            {addressess?.map((add) => {
              return (
                <div className={classes.formData}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Grid container>
                        {isMobile ? (
                          ""
                        ) : (
                          <Grid item xs={2}>
                            <Typography className={classes.formDataSubTitleDes}>
                              ALIAS Dirección
                            </Typography>
                          </Grid>
                        )}

                        <Grid item xs={4}>
                          <Typography>{`${
                            add.snd_alias ? add.snd_alias : " - "
                          } `}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container>
                        {isMobile ? (
                          ""
                        ) : (
                          <Grid item xs={2}>
                            <Typography className={classes.formDataSubTitleDes}>
                              Dirección
                            </Typography>
                          </Grid>
                        )}

                        <Grid item xs={10} md={4}>
                          <Typography>{`${add.snd_direccion} No.Ext ${
                            add.snd_direccion_num_ext
                          } No.Int ${add.snd_direccion_num_int} e/ ${
                            add.snd_calle1
                          } ${add.snd_calle2}, ${add.snd_colonia},  ${
                            add.snd_estado_id
                              ? add.estado.estpa_estado_nombre
                              : ""
                          }  ${add.pai.pais_nombre} C.P ${
                            add.snd_codigo_postal
                          }`}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      {permisosUser
                        ? permisosUser?.permisos?.map((p) => {
                            if (p.key === "edit" && p.permiso === true) {
                              return (
                                <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <CustomCheckbox
                                        checked={
                                          add.snd_direccion_envio_default
                                        }
                                        onChange={() =>
                                          handleClickDefaultAddress(add)
                                        }
                                        name="defaultAddress"
                                      />
                                    }
                                    label="Marcar como dirección predeterminada"
                                  />
                                </FormGroup>
                              );
                            }
                          })
                        : ""}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {isMobile ? (
                        <div className={classes.actionButtonsMov}>
                          {permisosUser
                            ? permisosUser.permisos?.map((p) => {
                                if (p.key === "edit" && p.permiso === true) {
                                  return (
                                    <Tooltip title="Editar">
                                      <IconButton
                                        aria-label="update"
                                        onClick={() =>
                                          handleFormAction("edit", add)
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  );
                                } else if (
                                  p.key === "delete" &&
                                  p.permiso === true
                                ) {
                                  return (
                                    <Tooltip title="Eliminar">
                                      <IconButton
                                        aria-label="update"
                                        onClick={() =>
                                          deleteAddressConfirm(add)
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  );
                                }
                              })
                            : ""}
                        </div>
                      ) : (
                        <div className={classes.actionButtonsDes}>
                          {permisosUser
                            ? permisosUser?.permisos?.map((p) => {
                                if (p.key === "edit" && p.permiso === true) {
                                  return (
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      className={classes.buttonUpdate}
                                      onClick={() =>
                                        handleFormAction("edit", add)
                                      }
                                    >
                                      Editar
                                    </Button>
                                  );
                                } else if (
                                  p.key === "delete" &&
                                  p.permiso === true
                                ) {
                                  return (
                                    <Button
                                      //variant="outlined"
                                      color="primary"
                                      className={classes.buttonDelete}
                                      onClick={() => deleteAddressConfirm(add)}
                                    >
                                      Eliminar
                                    </Button>
                                  );
                                }
                              })
                            : ""}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </div>
              );
            })}
          </Grid>
        </Grid>
      )}
    </div>
  );
}
