import React, { useState, useEffect, useContext, Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import arrow from "assets/img/Dielsa/Arrow_blue.png";

const useStyles = makeStyles((styles) => ({
  hide: {
    display: "none !important",
  },
  container: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 10,
    paddingRight: 5,
  },
  bullContainer: {
    " & *": {
      display: "-webkit-inline-box",
    },
  },
  bull: {
    color: "#40DF00",
    fontSize: "60px",
    maxWidth: "3%",
    lineHeight: 0,
    verticalAlign: "top",
  },
  vineta: {
    color: "#5F5F5F",
    fontFamily: "'Centrale Sans Regular'",
    fontSize: "12px",
    maxWidth: "97%",
    fontWeight: 400,
    lineHeight: "1.2em",
  },
  moreTxt: {
    color: "#0B3196",
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "500",
    fontSize: "12px",
    marginTop: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  imgArrow: {
    width: "25px",
    marginLeft: "10px",
    verticalAlign: "middle",
  },
  arrowLess: {
    transform: "scaleX(-1)",
  },
}));

export default function SectionProductBullets(props) {
  const classes = useStyles();
  const [bullets, setBullets] = useState(null);
  const [readMore, setReadMore] = useState(null);
  const [readLess, setReadLess] = useState(classes.hide);
  useEffect(() => {
    getBullets(3);
  }, []);

  const getBullets = (_max) => {
    let a = [];
    let _num =
      props.productDetails.bullets !== null
        ? _max === 3
          ? 3
          : props.productDetails.bullets.length
        : 0;

     //console.log(props.productDetails.skus[0].externalProductId)
    if (_max === 3) {
      setReadLess(classes.hide);
      setReadMore(null);
    } else {
      setReadLess(null);
      setReadMore(classes.hide);
    }
    if (_num !== null && _num > 0) {
      for (let i = 0; i < _num; i++) {
        let _b = props.productDetails.bullets[i];
        if (_b !== undefined) {
          let _pos = "";
          _pos = ("Viñeta_" + (i + 1)).toString();
          let _d = _b[_pos];
          a.push(
            <div key={"bull" + i} className={classes.bullContainer}>
              <Typography className={classes.bull}>{/* &bull; */}.</Typography>
              <Typography className={classes.vineta}>{_d}</Typography>
            </div>
          );
        }
      }
    }
    setBullets(a);
  };
  return (
    <div>
      <div className={classes.container}>
        {bullets}
        {props.productDetails.length > 3 ? (
                  <div>
                    <Typography
                  onClick={() => {
                    getBullets(5);
                  }}
                  className={classes.moreTxt + " " + readMore}
                >
                  Leer Más
                  <img src={arrow} className={classes.imgArrow} />
                </Typography>
                <Typography
                  onClick={() => {
                    getBullets(3);
                  }}
                  className={classes.moreTxt + " " + readLess}
                >
                  Leer Menos
                  <img
                    src={arrow}
                    className={classes.imgArrow + " " + classes.arrowLess}
                  />
                </Typography>
                  </div>
                
        ):""}
        
      </div>
    </div>
  );
}
