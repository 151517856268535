import { authHeader } from "../_helpers";
import { API_URL } from "./api.url.back";

export const checkoutService = {
  createOrGetCartByClientId,
  getCartByClientIdCheckout,
  getPriceByProductProject,
  getPriceByProductProjectCart,
  deleteItemCart,
  addItemCart,
  saveReference,
  getWarehousesList,
  getWarehousesPickup,
  getPaymentFormas,
  addCoupon,
  deleteCoupon,
  getFleteras,
  updateCartShipping,
  updateCartPayment,
  updateCartCfdi,
  prevalidateCart,
  conectaTokenSend,
  completeOrderCheckout,
  getCartSplitProductsByClientIdCheckout,
  getCartCurrencySubtotals,

  updateCartShippingCostAndFletera,
  getFleterasCostos,
  getCostoPaqueteexpress,
  getPaymentMetodos,
  getCartByClientId,
  getCartByCartId,
  updateCart,
  updateCartTotal,
  completeOrder,
  completeOrderInSAP,
  cartCotizacion,
  getExpirationTime,
  uploadPurchaseOrder,
  getPurchaseOrder,
  uploadTransfer
};

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    const responseData = {
      error: false,
      data: data,
    };
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function handleResponseAlternative(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    const responseData = {
      error: false,
      data: data,
    };
    if (!response.ok) {
      responseData.error = true;
      return responseData;
    }
    return responseData;
  });
}

function createOrGetCartByClientId(id_client) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_sn_socio_de_negocio_id: id_client,
    }),
  };

  return fetch(`${API_URL}/v2_carrito_compras/get_cart/`, requestOptions)
    .then(handleResponseAlternative)
    .then((cart) => {
      localStorage.setItem("cart", JSON.stringify(cart.data));
      return cart;
    });
}

function getCartByClientIdCheckout(id_client) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_sn_socio_de_negocio_id: id_client,
    }),
  };

  return fetch(`${API_URL}/v2_carrito_compras/get_V2_checkout/`, requestOptions)
    .then(handleResponseAlternative)
    .then((cart) => {
      localStorage.setItem("cart", JSON.stringify(cart.data));
      return cart;
    });
}

async function getPriceByProductProject(id_client, prod_sku) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      socio_de_negocio_id: id_client,
      prod_sku
    }),
  };

  return await fetch(`${API_URL}/proyectos/obtenerprecioproductoproyecto/`, requestOptions)
    .then(response => response.json())
    .then((response) => {
      return response.data;
    });
}
function getPriceByProductProjectCart(id_client, prod_sku) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      socio_de_negocio_id: id_client,
      prod_sku
    }),
  };

  return  fetch(`${API_URL}/proyectos/obtenerprecioproductoproyecto/`, requestOptions)
    .then(response =>response.json())
    .then((response)=> {
      return response;
    });
}
function getCartSplitProductsByClientIdCheckout(id_client) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_sn_socio_de_negocio_id: id_client,
    }),
  };

  return fetch(
    `${API_URL}/v2_carrito_compras/getPreOrdenDividida/`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((cart) => {
      return cart;
    });
}

function getCartCurrencySubtotals(id_client) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_sn_socio_de_negocio_id: id_client,
    }),
  };

  return fetch(
    `${API_URL}/v2_carrito_compras/get_V2_checkout_resumen_MXN_USD/`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((cart) => {
      return cart;
    });
}

function deleteItemCart(client_id, product_id) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_sn_socio_de_negocio_id: client_id,
      prod_producto_id: product_id,
    }),
  };

  return fetch(
    `${API_URL}/v2_carrito_compras/delete_product_to_cart/`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function addItemCart(client_id, product_id, qty) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_sn_socio_de_negocio_id: client_id,
      prod_producto_id: product_id,
      cantidad: qty,
    }),
  };

  return fetch(
    `${API_URL}/v2_carrito_compras/add_product_to_cart/`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function saveReference(client_id, reference) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_sn_socio_de_negocio_id: client_id,
      cdc_referencia: reference,
    }),
  };

  return fetch(`${API_URL}/v2_carrito_compras/asign_reference/`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function getWarehousesList() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/almacenes/list_public`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getWarehousesPickup() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/almacenes/get_almacenes_recoleccion`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getPaymentFormas() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/sap_formas_pago/list`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function addCoupon(client_id, coupon_code) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_sn_socio_de_negocio_id: client_id,
      promcup_cupon_codigo: coupon_code,
    }),
  };

  return fetch(`${API_URL}/v2_carrito_compras/asign_coupon/`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function deleteCoupon(client_id) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_sn_socio_de_negocio_id: client_id,
    }),
  };

  return fetch(
    `${API_URL}/v2_carrito_compras/delete_coupon_cart/`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function getFleteras() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/fleteras/list_socio`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function updateCartShipping(
  client_id,
  shipping_type,
  address_id,
  warehouse_id,
  fletera_id
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_sn_socio_de_negocio_id: client_id,
      cdc_cmm_tipo_envio_id: shipping_type,
      cdc_direccion_envio_id: address_id,
      cdc_alm_almacen_recoleccion: warehouse_id,
      cdc_fletera_id: fletera_id,
    }),
  };

  return fetch(
    `${API_URL}/v2_carrito_compras/set_cart_shipping_detail/`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function updateCartPayment(client_id, payment_type) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_sn_socio_de_negocio_id: client_id,
      sfp_sap_formas_pago_id: payment_type,
    }),
  };

  return fetch(
    `${API_URL}/v2_carrito_compras/set_cart_tipo_compra/`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function updateCartCfdi(client_id, order_cfdi) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_sn_socio_de_negocio_id: client_id,
      cdc_cfdi: order_cfdi,
    }),
  };

  return fetch(`${API_URL}/v2_carrito_compras/asign_cfdi/`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function prevalidateCart(client_id) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_sn_socio_de_negocio_id: client_id,
    }),
  };

  return fetch(
    `${API_URL}/v2_carrito_compras/prevalidar_carrito/`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function conectaTokenSend(token_id, client_id, user_client_id) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      token_id: token_id,
      cdc_sn_socio_de_negocio_id: client_id,
      snu_usuario_snu_id: user_client_id,
    }),
  };

  return fetch(
    `${API_URL}/conekta_internal_apis/conektaCrearOrden`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((jsonData) => {
//validar pagos

      return jsonData;

    });
}

function completeOrderCheckout(client_id, user_client_id) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_sn_socio_de_negocio_id: client_id,
      cf_snu_usuario_snu_id: user_client_id,
      cf_vendido_por_usu_usuario_id: null,
      cf_generado_en: "Store"
    }),
  };

  return fetch(
    `${API_URL}/compras_finalizadas/v2_compra_finalizada/`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

/*  */

function getFleterasCostos(cartid, addresid) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_carrito_de_compra_id: cartid,
      snd_direcciones_id: addresid,
    }),
  };

  return fetch(
    `${API_URL}/fleteras/Cotizar_Carrito_Fleter_Front`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function getCostoPaqueteexpress(cartid, clientid, addresid) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_carrito_de_compra_id: cartid,
      snu_usuario_snu_id: clientid,
      snd_direcciones_id: addresid,
    }),
  };

  return fetch(`${API_URL}/paquete_express/cotizar_carrito`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function updateCartShippingCostAndFletera(
  cart_id,
  cart_fletera_id,
  cart_costo_envio
) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_carrito_de_compra_id: cart_id,
      cdc_fletera_id: cart_fletera_id.toString(),
      cdc_costo_envio: cart_costo_envio.toString(),
    }),
  };

  return fetch(`${API_URL}/carrito_de_compras/update`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function getPaymentMetodos() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/sap_metodos_pago/list`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getCartByClientId(clientId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/carrito_de_compras/orden/${clientId}`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getCartByCartId(cartId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/carrito_de_compras/carrito/${cartId}`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((jsonData) => {
      if (!jsonData.error) {
        localStorage.setItem("cart", JSON.stringify(jsonData.data));
      }
      return jsonData;
    });
}

function updateCart(
  cart_id,
  cart_total,
  cart_tipo_envio,
  cart_direccion_id,
  cart_almacen_id,
  cart_tipo_pago,
  cart_fletera_id,
  cart_costo_envio
) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_carrito_de_compra_id: cart_id,
      cdc_total_carrito: cart_total,
      cdc_cmm_tipo_envio_id: cart_tipo_envio,
      cdc_direccion_envio_id: cart_direccion_id,
      cdc_alm_almacen_recoleccion: cart_almacen_id,
      cdc_cmm_tipo_compra_id: cart_tipo_pago,
      cdc_fletera_id: cart_fletera_id,
      cdc_costo_envio: cart_costo_envio,
    }),
  };

  return fetch(`${API_URL}/carrito_de_compras/update`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function updateCartTotal(cart_id, cart_total) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_carrito_de_compra_id: cart_id,
      cdc_total_carrito: cart_total,
    }),
  };

  return fetch(`${API_URL}/carrito_de_compras/update`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function completeOrder(
  order_number,
  date,
  payment_type_id,
  shipping_type_id,
  client_id,
  shipping_address_id,
  warehouse_id,
  type_tax,
  order_status,
  fletera,
  order_sap_forma_payment,
  order_sap_metodo_payment,
  referencia
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cf_compra_numero_orden: order_number,
      cf_compra_fecha: date,
      cf_cmm_tipo_compra_id: payment_type_id,
      cf_cmm_tipo_envio_id: shipping_type_id,
      cf_cmm_tipo_impuesto: type_tax,
      cf_vendido_a_socio_negocio_id: client_id,
      cf_estatus_orden: order_status,
      cf_fletera_id: fletera,
      cf_sap_metodos_pago_codigo: order_sap_metodo_payment,
      cf_sap_forma_pago_codigo: order_sap_forma_payment,
      cf_referencia: referencia,
      cf_direccion_envio_id: shipping_address_id,
      cf_alm_almacen_recoleccion: warehouse_id,
    }),
  };

  return fetch(`${API_URL}/compras_finalizadas/add_socio`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function completeOrderInSAP(order_complete_id) {
  const requestOptions = {
    method: "POST",
    //headers: authHeader(),
    body: JSON.stringify({
      cf_compra_numero_orden: order_complete_id,
    }),
  };

  return fetch(
    `${API_URL}/integraciones_info_transfer/IntegracionCrearOrdenSap/`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function cartCotizacion(
  cartid,
  proyname,
  tipocotizacion,
  clientid,
  fechavencimiento
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_carrito_de_compra_id: cartid,
      cot_proyecto_nombre: proyname,
      cot_cmm_tipo_id: tipocotizacion,
      cot_cmm_estatus_id: 1000078,
      cot_sn_socios_negocio_id: clientid,
      cot_fecha_vencimiento: fechavencimiento,
    }),
  };

  return fetch(
    `${API_URL}/carrito_de_compras/crearCotizacion_sn`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function getExpirationTime() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/controles_maestros/control_public/TIEMPO_CADUCIDAD_COTIZACION_PROYECTO`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}


function uploadPurchaseOrder(formData, token) {
  const requestOptions = {
    method: "POST",
    headers: { token: token },
    body: formData
  };
  
  return fetch(`${API_URL}/files/purchase_order_sn`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}


function uploadTransfer(formData, token) {
  const requestOptions = {
    method: "POST",
    headers: { token: token },
    body: formData
  };
  
  return fetch(`${API_URL}/files/upload-transfers-order`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}


function getPurchaseOrder(numOrder) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/files/purchase_order_sn/${numOrder}`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}