import React, {
  useContext,
  createContext,
  useState,
  useRef,
  useEffect,
  Fragment,
} from "react";
import { userService } from "../../../_services/user.service";
import { generalService } from "../../../_services/general.service";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import iconEliminar from "../../../assets/img/Dielsa/Icon_Eliminar.svg";
import menu_roles from "../../../_helpers/menu_roles";
import menu_roles_admin from "../../../_helpers/menu_roles_admin";

// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SectionBreadcrumb from "views/Home/Sections/SectionBreadcrumb.js";

// @material-ui/
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useAuth } from "hooks/useAuth";
// core components
import useCheckMobileScreen from "components/Mobile/Mobile.js";
import { Tune } from "@material-ui/icons";

const CustomCheckbox = withStyles({
  root: {
    color: "#40DF00",
    "&$checked": {
      color: "#40DF00",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& ~ label": {
      color: "#000000",
      fontSize: 12,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    margin: "auto",
    width: "95%",
    paddingBottom: 30,
  },
  spaceDiv: {
    marginTop: 15,
    marginBottom: 15,
  },
  linkBack: {
    fontFamily: "'Centrale Sans Medium'",
    color: "#0B3196",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "19px",
    textAlign: "center",
    paddingLeft: "30px",
    position: "relative",
  },
  linkBackArrow: {
    position: "absolute",
    left: "5px",
    bottom: "-2px",
  },
  title: {
    fontFamily: "'Centrale Sans Bold'",
    textTransform: "uppercase",
    fontSize: 24,
    lineHeight: "29px",
    textAlign: "justify",
    color: "#0b3196",
    paddingTop: 20,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  titleDivider: {
    border: "1px solid #0B3196",
    width: "95%",
    margin: "auto",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: "30px 0",
  },
  //formData
  formData: {
    backgroundColor: "#F5F5F5",
    borderRadius: 2,
    margin: "10px 0",
    border: "1px solid #EAEAEA",
    maxHeight: 500,
    overflowY: "auto",
  },
  formDataTitle: {
    color: "#0C2D9C",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 22,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "27px",
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  formDataSubTitleDes: {
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 18,
    textAlign: "justify",
    color: "#0C2D9C",
    textTransform: "none",
    paddingLeft: 10,
    paddingTop: 5,
  },
  formDataTitleMov: {
    color: "#0C2D9C",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "27px",
    textAlign: "center",
  },
  formDataTitleDes: {
    color: "#0C2D9C",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 22,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "27px",
    textAlign: "justify",
    paddingTop: 20,
  },
  gridContent: {
    paddingBottom: 30,
  },
  buttonDelete: {
    border: "none",
    color: "#8e8a8a",
    backgroundColor: "transparent",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    margin: "auto",
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  buttonBack: {
    border: "1px solid",
    color: "#0C2D9C",
    backgroundColor: "transparent",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    margin: 0,
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  buttonUpdate: {
    border: "1px solid",
    color: "#0C2D9C",
    backgroundColor: "transparent",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    margin: "auto",
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  buttonUpdateSecond: {
    border: "1px solid",
    backgroundColor: "#0C2D9C",
    color: "#ffffff",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  inputs: {
    width: "100%",
    border: "1px solid #EAEAEA",
    borderRadius: "2px",
    backgroundColor: "#FFFFFF",
  },
  //alerts
  alerts: {
    color: "#FFFFFF",
    backgroundColor: "#40DF00",
    fontFamily: "'Centrale Sans Light'",
    fontSize: 22,
    "& .MuiAlert-icon": {
      color: "#FFFFFF",
    },
    "&.MuiAlert-standardError": {
      backgroundColor: "#DF2B2B",
    },
  },
  //defaultAddress
  defaultAddressGrid: {
    margin: "35px 0",
  },
  defaultAddressDiv: {
    border: "1px solid #000000",
    padding: 20,
    margin: "10px 0",
    borderRadius: 3,
  },
  defaultAlias: {
    color: "#0C2D9C",
    fontFamily: "'Centrale Sans Bold'",
    fontSize: 18,
    fontWeight: "bold",
    letterSpacing: 0,
    lineHeight: "23px",
  },
  defaultAddress: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    fontWeight: "500",
    letterSpacing: 0,
    lineHeight: "19px",
  },
  buttonBackDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "10px 0",
  },
  actionButtonsDes: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  actionButtonsMov: {},
  //selecte
  formControl: {
    minWidth: "100%",
    "& label": {
      transform: "translate(14px, 14px) scale(1)",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selects: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #e8e8e8",
    borderRadius: 4,
    height: 37,
    fontFamily: "'Centrale Sans Regular'",
    margin: 0,
    padding: 0,
  },
  //table destopk
  tableHead: {
    backgroundColor: "#0B3196",
    color: "#FFFFFF",
    padding: "10px 5px 10px 10px",
    fontFamily: "'Centrale Sans Regular'",
    border: "none",
    fontSize: 16,
  },
  tableRow: {
    margin: "5px 0",
    padding: "5px 10px 0px 10px",
    fontFamily: "'Centrale Sans Regular'",
    border: "none",
    borderBottom: "1px solid #EAEAEA",
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      padding: "2px 0 2px 4px",
    },
  },
  customIconDelete: {
    width: 25,
    "&:hover": {
      cursor: "pointer",
    },
  },
  elementForm: {
    display: "inline-flex",
    padding: "5px 10px 10px 5px",
    [theme.breakpoints.down("sm")]: {
      padding: "2px 2px 2px 2px",
    },
  },

  notFound:{
    textAlign: "center",
    width: "100%",
    fontSize: "24px",
    marginTop: "30px"
  }
}));

export default function ProfileAccesos(props) {
  const classes = useStyles();
  const confirm = useConfirm();
  const { ...rest } = props;
  let history = useHistory();

  let isMobile = useCheckMobileScreen();

 const {setOpenAlert} = useAuth();

  const [openFormDataProfile, setOpenFormDataProfile] = useState(false);
  const [actionForm, setActionForm] = useState("");

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [dataBreadCrumb, setDataBreadCrumb] = useState([]);
  const [user, setUser] = useState({});
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [nombre, setNombre] = useState("");
  const [apellido1, setApellido1] = useState("");
  const [apellido2, setApellido2] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [area, setArea] = useState("");
  const [puesto, setPuesto] = useState("");
  const [permisos, setPermisos] = useState([]); //JSON.parse(JSON.stringify(menu_roles))
  const [vistaLista, setVistaLista] = useState(false);

  const handleChangeValue = (event) => {
    const textId = event.target.id;
    const formattedValue = event.target.value.replace(/[^0-9]/g, '').slice(0, 10);
    //setValueAnswer((e) => ({...e, [textId]: formattedValue }));
  };

  const handleChangeValueName = (event) => {
    const textId = event.target.id;
    const onlyLettersValue = event.target.value.replace(/[^A-Za-z\s]/g, '');
    setNombreUsuario(onlyLettersValue)
   // setValueAnswerName((e) => ({...e, [textId]: onlyLettersValue }));
  };
  useEffect(() => {
    if (!localStorage.getItem("user")) {
        //redirect to login
        history.push("/login");
    } else {
        setUser(JSON.parse(localStorage.getItem("user")).usuario);
    }

    setDataBreadCrumb(() => [
      {
        name: "INICIO",
        link: "/",
      },
      {
        name: "MI CUENTA",
        link: "/myprofile/perfil",
      },
      {
        name: "PERFILES DE ACCESO",
        link: "",
      }
    ]);

    getClientUsers();
    getPermisosJson();
  }, []);

  const getClientUsers = async () => {
    const users_client_response = await userService.getClientUsers(
      user.snu_sn_socio_de_negocio_id
    );

    let usersList = users_client_response.data?.usuarios?.filter(
      (usu) => usu.controles_maestros_multiple.cmm_control_id === 1000048
    )

    if(usersList?.length > 0){
      setVistaLista(true)
    }

    setUsers(
      users_client_response.data?.usuarios?.filter(
        (usu) => usu.controles_maestros_multiple.cmm_control_id === 1000048
      )
    );
  };


  const getPermisosJson = async () => {
    const json_permisos_usuario_response = await userService.getJsonPermisosUsuario();
    setPermisos(json_permisos_usuario_response.user_b2b);
  };

  const handleChange = (event) => {
    if (event.target.name === "nombreUsuario") {
     // const onlyLettersValue = event.target.value.replace(/[^A-Za-z\s]/g, '');
      setNombreUsuario(event.target.value);
    }
    if (event.target.name === "nombre") {
      const onlyLettersValue = event.target.value.replace(/[^A-Za-z\s]/g, '');
      setNombre(onlyLettersValue);
    }
    if (event.target.name === "apellido1") {
      const onlyLettersValue = event.target.value.replace(/[^A-Za-z\s]/g, '');
      setApellido1(onlyLettersValue);
    }
    if (event.target.name === "apellido2") {
      const onlyLettersValue = event.target.value.replace(/[^A-Za-z\s]/g, '');
      setApellido2(onlyLettersValue);
    }
    if (event.target.name === "correo") {
     
      setCorreo(event.target.value);
    }
    if (event.target.name === "telefono") {
      const formattedValue = event.target.value.replace(/[^0-9]/g, '').slice(0, 10);
      setTelefono(formattedValue);
    }
    if (event.target.name === "area") {
     
      setArea(event.target.value);
    }
    if (event.target.name === "puesto") {
     // const onlyLettersValue = event.target.value.replace(/[^A-Za-z\s]/g, '');
      setPuesto(event.target.value);
    }
  };

  const handleFormAction = (action, user) => {
    setOpenFormDataProfile(true);
    setActionForm(action);
    setSelectedUser(user);

    if (action === "edit") {
      //"here fill all the input ref textfield of the form"
      setNombreUsuario(user.snu_nombre);
      setNombre(user.snu_nombre);
      setApellido1(user.snu_primer_apellido);
      setApellido2(user.snu_segundo_apellido);
      setCorreo(user.snu_correo_electronico);
      setTelefono(user.snu_telefono);
      setArea(user.snu_area);
      setPuesto(user.snu_puesto);
      // set permisos por el del usuario a editar
      setPermisos(user.snu_menu_roles);
    } else {
      setNombreUsuario("");
      setNombre("");
      setApellido1("");
      setApellido2("");
      setCorreo("");
      setTelefono("");
      setArea("");
      setPuesto("");
    }
  };

  const deleteUserProfileConfirm = (user_profile) => {
    confirm({
      title: `Eliminar perfil de usuario`,
      description: `¿Está seguro de eliminar 
      el perfil de usuario?`,
      confirmationButtonProps: { autoFocus: true },
      cancellationButtonProps: { autoFocus: true },
      confirmationText: `Aceptar`,
      cancellationText: `Cancelar`,
    }).then(() => deleteUserProfile(user_profile));
  };

  const deleteUserProfile = async (user_profile) => {
    const delete_user_response = await userService.updateUserProfileStatus(
      user_profile.snu_usuario_snu_id,
      user_profile.snu_cardcode,
      user_profile.snu_sn_socio_de_negocio_id,
      props.user.snu_usuario_snu_id,
      1000050
    );
    if (delete_user_response.error) {
      setOpenAlert({
        message: "Hubo un error eliminando el perfil de usuario",
        type: "error",
        open: true,
      });
    } else {
      setOpenAlert({
        message: "Fue eliminando el perfil de usuario",
        type: "success",
        open: true,
      });
      setOpenFormDataProfile(false);
      getClientUsers();
    }
  };

  const createUserProfile = async (event) => {
    event.preventDefault();
    if(!/\S+@\S+\.\S+/.test(correo)) {
      window.scrollTo(0,0);
      setOpenAlert({open:true, type:'error', message:'El formato del correo electrónico debe ser ejemplo@mail.com'})
    
    }else{
    const create_user_response = await userService.createUserProfile(
      nombre,
      apellido1,
      apellido2,
      correo,
      telefono,
      nombreUsuario,
      user.snu_cardcode,
      user.snu_sn_socio_de_negocio_id,
      user.snu_usuario_snu_id,
      area,
      puesto,
      permisos
    );
    console.log('create_user_response --->', create_user_response);
    if (create_user_response.error) {
      setOpenAlert({
        message: create_user_response.data.message,
        type: "error",
        open: true,
      });
    } else {
      setOpenAlert({
        message: "El perfil de usuario fue creado exitosamente",
        type: "success",
        open: true,
      });
      setOpenFormDataProfile(false);
      getClientUsers();
      //getPermisosJson();
    }}
  };

  const updateUserProfile = async (event) => {
    event.preventDefault();
    if(!/\S+@\S+\.\S+/.test(correo)) {
      window.scrollTo(0,0);
      setOpenAlert({open:true, type:'error', message:'El formato del correo electrónico debe ser ejemplo@mail.com'})
    
    }else{
    const update_user_response = await userService.updateUserProfile(
      selectedUser.snu_usuario_snu_id,
      nombre,
      apellido1,
      apellido2,
      correo,
      telefono,
      nombreUsuario,
      user.snu_cardcode,
      user.snu_sn_socio_de_negocio_id,
      user.snu_usuario_snu_id,
      area,
      puesto,
      permisos
    );
    if (update_user_response.error) {
      setOpenAlert({
        message: `Hubo un error al actualizar el perfil de usuario, ${update_user_response.data.message}` ,
        type: "error",
        open: true,
      });
    } else {
      setOpenAlert({
        message: "El perfil de usuario fue modificado exitosamente",
        type: "success",
        open: true,
      });
      setOpenFormDataProfile(false);
      getClientUsers();
    }}
  };

  const handleChangePermiso = (modulo, submenu, permiso) => {
    if (submenu === "") {
      let result_rol = permisos.find((item) => item.key_id === modulo.key_id);
      let result_permiso = result_rol.permisos.find(
        (item) => item.key === permiso.key
      );
      result_permiso.permiso = !result_permiso.permiso;
      let newMenu = [...permisos];
      setPermisos(newMenu);
    } else {
      let result_rol = permisos.find((item) => item.key_id === modulo.key_id);
      let result_rol_sub = result_rol.submenu.find(
        (item) => item.key_id === submenu.key_id
      );
      let result_permiso = result_rol_sub.permisos.find(
        (item) => item.key === permiso.key
      );
      result_permiso.permiso = !result_permiso.permiso;
      let newMenu = [...permisos];
      setPermisos(newMenu);
    }

    /*   permisos.forEach((mod) => {
      if (mod.key_id === modulo.key_id) {
        mod.permisos.forEach((p) => {
          if (p.key === permiso.key) {
            p.permiso = !p.permiso;
            let newMenu = [...permisos];
            setPermisos(newMenu);
          }
        });
      } else {
        if (modulo.submenu) {
          modulo.submenu.forEach((subm) => {
            if (subm.key_id === submenu.key_id) {
              subm.permisos.forEach((p) => {
                if (p.key === permiso.key) {
                  p.permiso = !p.permiso;
                  let newMenu = [...permisos];
                  setPermisos(newMenu);
                }
              });
            }
          });
        }
      }
    }); */
  };

  return (
    <div className={classes.root}>
    

      <div className={classes.spaceDiv}>
        <SectionBreadcrumb route={dataBreadCrumb} />
        <Typography className={classes.title}>
          {actionForm === "" ? "Perfiles de acceso" : ""}
          {actionForm === "create" ? "Nuevo perfil de acceso" : ""}
          {actionForm === "edit" ? "Actualizar perfil de acceso" : ""}
        </Typography>
        {isMobile ? <Divider className={classes.titleDivider} /> : ""}
      </div>

      {openFormDataProfile ? (
        <Fragment>
          <Grid container>
            <Grid xs={12}>
              <form
                onSubmit={
                  actionForm === "create"
                    ? createUserProfile
                    : updateUserProfile
                }
              >
                <Grid container>
                  {!isMobile ? (
                    <Grid item xs={12}>
                      <Typography className={classes.formDataTitleDes}>
                        Datos Personales
                      </Typography>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid item xs={12}>
                    <div className={classes.formData}>
                      <Grid container>
                        {isMobile ? (
                          <Grid xs={12}>
                            <Typography className={classes.formDataTitle}>
                              Datos Personales
                            </Typography>
                          </Grid>
                        ) : (
                          ""
                        )}
                        <Grid xs={12} md={6} className={classes.elementForm}>
                          <Grid container>
                            {!isMobile ? (
                              <Grid xs={12} sm={4}>
                                <span className={classes.formDataSubTitleDes}>
                                  Usuario
                                </span>
                              </Grid>
                            ) : (
                              ""
                            )}
                            <Grid xs={12} sm={8}>
                              <TextField
                                required
                                fullWidth
                                variant="outlined"
                                className={classes.inputs}
                                size="small"
                                 
                                onChange={handleChange}
                                value={nombreUsuario}
                                id="nombreUsuario"
                                name="nombreUsuario"
                                type="text"
                                placeholder={isMobile ? "Usuario" : ""}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          xs={12}
                          md={6}
                          className={classes.elementForm}
                        ></Grid>
                        <Grid xs={12} md={6} className={classes.elementForm}>
                          <Grid container>
                            {!isMobile ? (
                              <Grid xs={12} sm={4}>
                                <span className={classes.formDataSubTitleDes}>
                                  Nombre
                                </span>
                              </Grid>
                            ) : (
                              ""
                            )}
                            <Grid xs={12} sm={8}>
                              <TextField
                                required
                                fullWidth
                                variant="outlined"
                                className={classes.inputs}
                                size="small"
                                onChange={handleChange}
                                value={nombre}
                                id="nombre"
                                name="nombre"
                                type="text"
                                placeholder={isMobile ? "Nombre" : ""}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid xs={12} className={classes.elementForm}>
                          <Grid container>
                            {!isMobile ? (
                              <Grid xs={12} sm={2}>
                                <span className={classes.formDataSubTitleDes}>
                                  Apellidos
                                </span>
                              </Grid>
                            ) : (
                              ""
                            )}
                            <Grid xs={12} sm={5}>
                              <TextField
                                required
                                fullWidth
                                variant="outlined"
                                className={classes.inputs}
                                size="small"
                                onChange={handleChange}
                                value={apellido1}
                                id="apellido1"
                                name="apellido1"
                                type="text"
                                placeholder={isMobile ? "Primer Apellido" : ""}
                              />
                            </Grid>
                            <Grid xs={12} sm={5}>
                              <TextField
                                required
                                fullWidth
                                variant="outlined"
                                className={classes.inputs}
                                size="small"
                                onChange={handleChange}
                                value={apellido2}
                                id="apellido2"
                                name="apellido2"
                                type="text"
                                placeholder={isMobile ? "Segundo Apellido" : ""}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid xs={12} md={6} className={classes.elementForm}>
                          <Grid container>
                            {!isMobile ? (
                              <Grid xs={12} sm={4}>
                                <span className={classes.formDataSubTitleDes}>
                                  Correo
                                </span>
                              </Grid>
                            ) : (
                              ""
                            )}
                            <Grid xs={12} sm={8}>
                              <TextField
                                required
                                fullWidth
                                variant="outlined"
                                className={classes.inputs}
                                size="small"
                                onChange={handleChange}
                                value={correo}
                                id="correo"
                                name="correo"
                                type="email" 
                                placeholder={isMobile ? "Correo" : ""}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid xs={12} md={6} className={classes.elementForm}>
                          <Grid container>
                            {!isMobile ? (
                              <Grid xs={12} sm={4}>
                                <span className={classes.formDataSubTitleDes}>
                                  Teléfono
                                </span>
                              </Grid>
                            ) : (
                              ""
                            )}
                            <Grid xs={12} sm={8}>
                              <TextField
                                required
                                fullWidth
                                variant="outlined"
                                className={classes.inputs}
                                size="small"
                                onChange={handleChange}
                                value={telefono}
                                id="telefono"
                                name="telefono"
                                type="text"
                                placeholder={isMobile ? "Teléfono" : ""}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid xs={12} md={6} className={classes.elementForm}>
                          <Grid container>
                            {!isMobile ? (
                              <Grid xs={12} sm={4}>
                                <span className={classes.formDataSubTitleDes}>
                                  Área
                                </span>
                              </Grid>
                            ) : (
                              ""
                            )}
                            <Grid xs={12} sm={8}>
                              <TextField
                                required
                                fullWidth
                                variant="outlined"
                                className={classes.inputs}
                                size="small"
                                onChange={handleChange}
                                value={area}
                                id="area"
                                name="area"
                                type="text"
                                placeholder={isMobile ? "Área" : ""}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid xs={12} md={6} className={classes.elementForm}>
                          <Grid container>
                            {!isMobile ? (
                              <Grid xs={12} sm={4}>
                                <span className={classes.formDataSubTitleDes}>
                                  Puesto
                                </span>
                              </Grid>
                            ) : (
                              ""
                            )}
                            <Grid xs={12} sm={8}>
                              <TextField
                                required
                                fullWidth
                                variant="outlined"
                                className={classes.inputs}
                                size="small"
                                onChange={handleChange}
                                value={puesto}
                                id="puesto"
                                name="puesto"
                                type="text"
                                placeholder={isMobile ? "Puesto" : ""}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>

                  <Grid container>
                    {!isMobile ? (
                      <Grid item xs={12}>
                        <Typography className={classes.formDataTitleDes}>
                          Control de accesos
                        </Typography>
                      </Grid>
                    ) : (
                      ""
                    )}
                    {permisos.map((modulo, index) => {
                      return (
                        <Grid item xs={12} key={index}>
                          <div className={classes.formData}>
                            <Grid container>
                              <Grid xs={12}>
                                <Typography
                                  className={classes.formDataSubTitleDes}
                                >
                                  {modulo.menu}
                                </Typography>
                              </Grid>
                              <Grid xs={12}>
                                {modulo.permisos.map((permiso) => {
                                  return (
                                    <Fragment>
                                      <FormGroup row style={{ margin: 10 }}>
                                        <FormControlLabel
                                          control={
                                            <CustomCheckbox
                                              checked={permiso.permiso}
                                              onChange={() =>
                                                handleChangePermiso(
                                                  modulo,
                                                  "",
                                                  permiso
                                                )
                                              }
                                              name={`${permiso.key}`}
                                            />
                                          }
                                          label={`${permiso.titulo}`}
                                        />
                                      </FormGroup>
                                    </Fragment>
                                  );
                                })}
                                {modulo.submenu
                                  ? modulo.submenu.map((submenu) => {
                                      return (
                                        <Fragment>
                                          {submenu.permisos.map(
                                            (permisosub) => {
                                              return (
                                                <Fragment>
                                                  <FormGroup
                                                    row
                                                    style={{ margin: 10 }}
                                                  >
                                                    <FormControlLabel
                                                      control={
                                                        <CustomCheckbox
                                                          checked={
                                                            permisosub.permiso
                                                          }
                                                          onChange={() =>
                                                            handleChangePermiso(
                                                              modulo,
                                                              submenu,
                                                              permisosub
                                                            )
                                                          }
                                                          name={`${permisosub.key}`}
                                                        />
                                                      }
                                                      label={`${permisosub.titulo}`}
                                                    />
                                                  </FormGroup>
                                                </Fragment>
                                              );
                                            }
                                          )}
                                        </Fragment>
                                      );
                                    })
                                  : ""}
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.buttonUpdateSecond}
                      type="submit"
                    >
                      Guardar datos
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.buttonBackDiv}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.buttonBack}
                  onClick={() => {
                    setOpenFormDataProfile(false);
                    setActionForm("");
                    setPermisos(JSON.parse(JSON.stringify(menu_roles)));
                  }}
                >
                  Atrás
                </Button>
              </div>
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <Grid container spacing={0}>
          <Grid item xs={12} className={classes.defaultAddressGrid}>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div className={classes.actionButtonsDes}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.buttonUpdateSecond}
                    onClick={() => handleFormAction("create")}
                  >
                    Crear nuevo perfil
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
          {isMobile ? (
            vistaLista ? (
              <Grid item xs={12} className={classes.gridContent}>
              {users.map((user) => {
                return (
                  <div className={classes.formData}>
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography className={classes.formDataSubTitleDes}>
                              Fecha de alta
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography>{`${
                              user.createdAt.split("T0")[0]
                            }`}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography className={classes.formDataSubTitleDes}>
                              Nombre
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography>{`${user.snu_nombre} ${user.snu_primer_apellido} ${user.snu_segundo_apellido}`}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography className={classes.formDataSubTitleDes}>
                              Área
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography>{`${user.snu_area}`}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography className={classes.formDataSubTitleDes}>
                              Puesto
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography>{`${user.snu_puesto}`}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Tooltip title="Actualizar">
                          <IconButton
                            style={{
                              position: "relative",
                              bottom: 10,
                            }}
                            aria-label="edit"
                            onClick={() => handleFormAction("edit", user)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Eliminar">
                          <IconButton
                            style={{
                              position: "relative",
                              bottom: 10,
                            }}
                            aria-label="delete"
                            onClick={() => {
                              deleteUserProfileConfirm(user);
                            }}
                          >
                            {/* <img
                            src={iconEliminar}
                            className={classes.customIconDelete}
                          /> */}
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
            </Grid>
            ):(
              <Typography className={classes.notFound}>
                No se encontraron prefiles de acceso
              </Typography>
            )
           
          ) : (
            vistaLista ? (
              <Grid item xs={12} className={classes.gridContent}>
              <div className={classes.formData}>
                <Grid container spacing={0} className={classes.tableHead}>
                  <Grid item xs={12} sm={2}>
                    Fecha de alta
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    Nombre
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    Área
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    Puesto
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    Acciones
                  </Grid>
                </Grid>
                {users.map((user) => {
                  return (
                    <Grid container spacing={0} className={classes.tableRow}>
                      <Grid item xs={12} sm={2}>
                        <Typography>{`${
                          user.createdAt.split("T0")[0]
                        }`}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography>{`${user.snu_nombre} ${user.snu_primer_apellido} ${user.snu_segundo_apellido}`}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Typography>{`${user.snu_area}`}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Typography>{`${user.snu_puesto}`}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Tooltip title="Actualizar">
                          <IconButton
                            style={{
                              position: "relative",
                              bottom: 10,
                            }}
                            aria-label="edit"
                            onClick={() => handleFormAction("edit", user)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Eliminar">
                          <IconButton
                            style={{
                              position: "relative",
                              bottom: 10,
                            }}
                            aria-label="delete"
                            onClick={() => {
                              deleteUserProfileConfirm(user);
                            }}
                          >
                            {/* <img
                            src={iconEliminar}
                            className={classes.customIconDelete}
                          /> */}
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  );
                })}
              </div>
            </Grid>
            ):(
              <Typography className={classes.notFound}>
                No se encontraron prefiles de acceso
              </Typography>
            )
           
          )}
        </Grid>
      )}
    </div>
  );
}
