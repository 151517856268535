import React, { useState, useEffect, useContext, Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";

import SectionProductGlobalRating from "./SectionProductGlobalRating.js";
import SectionProductClientCommentName from "./SectionProductClientCommentName.js";
import SectionProductClientCommentText from "./SectionProductClientCommentText.js";

import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import { productsService } from "_services/products.services.js";

const useStyles = makeStyles((styles) => ({
  container: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 30,
  },
  hide: {
    display: "none !important",
  },
  title: {
    color: "#003773",
    width: "100%",
    textAlign: "center",
    fontWeight: 700,
  },
  pDescription: {
    textAlign: "justify",
    fontWeight: 400,
  },
  rating: {
    width: "30%",
    display: "inline-block",
    verticalAlign: "top",
    [styles.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  comments: {
    width: "69%",
    display: "inline-block",
    verticalAlign: "top",
    [styles.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "15px",
    },
  },
  addCommentDiv: {
    width: "69%",
    display: "inline-block",
    verticalAlign: "top",
    [styles.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  commentDiv: {
    borderBottom: "1px solid #40df00",
    width: "100%",
    marginBottom: "15px",
  },
  userDiv: {
    width: "25%",
    display: "inline-block",
    verticalAlign: "top",
    paddingLeft: "50px",
    paddingRight: "15px",
    paddingTop: "6px",
    paddingBottom: "6px",
    marginTop: "4px",
    marginBottom: "4px",
    borderRight: "1px solid #EDEDED",
    [styles.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: "3%",
    },
  },
  commentsDiv: {
    width: "75%",
    display: "inline-block",
    verticalAlign: "top",
    paddingLeft: "45px",
    [styles.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: "3%",
      paddingRight: "3%",
    },
  },
  btnDiv: {
    paddingLeft: "15.5%",
    paddingRight: "13%",
    marginTop: "15px",
    [styles.breakpoints.down("sm")]: {
      paddingLeft: "3%",
      paddingRight: "3%",
    },
  },
  btn: {
    color: "#0b3196",
    fontFamily: "'Centrale Sans Medium'",
    width: "100%",
    border: "1px solid #0b3196",
    textTransform: "none",
    paddingTop: 8,
    "& span": {
      alignItems: "center",
      justifyContent: "center",
    },
  },
  btnEnviar: {
    color: "#fff",
    backgroundColor: "#0b3196",
    fontFamily: "'Centrale Sans Medium'",
    border: "1px solid #0b3196",
    marginTop: "15px",
    paddingTop: 9,
    paddingLeft: "50px",
    paddingRight: "50px",
    "&:hover": {
      backgroundColor: "#09287d",
    },
    textTransform: "none",
    verticalAlign: "middle",
    textAlign: "center",
  },
  btnRegresar: {
    color: "#0b3196",
    fontFamily: "'Centrale Sans Medium'",
    marginTop: "15px",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: 8,
    border: "1px solid #0b3196",
    marginLeft: "20px",
    textTransform: "none",
    verticalAlign: "middle",
    textAlign: "center",
  },
  comentTextarea: {
    marginTop: "10px",
    backgroundColor: "#F5F5F5",
  },
  iconColor: {
    color: "#ffe720",
    fontSize: 24,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export default function SectionProductClientsComments(props) {
  const classes = useStyles();
  const [listadoComentarios, setListadoComentarios] = useState(null);
  const [agregarComentario, setAgregarComentario] = useState(classes.hide);
  const [starsVisual, setStarsVisual] = useState(0);
  const [rateStars, setRateStars] = useState(null);
  const [comentarios, setComentarios] = useState("");
  const [alertStatus, setAlertStatus] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const cometarioForm = (state) => {
    if (state === 1) {
      setListadoComentarios(classes.hide);
      setAgregarComentario(null);
    }
    if (state === 2) {
      setListadoComentarios(null);
      setAgregarComentario(classes.hide);
    }
  };

  useEffect(() => {
    setStars();
  }, []);

  useEffect(() => {
    setStars();
  }, [starsVisual]);

  const setAlertStatusTimeout = () => {
    setAlertStatus(true);
    setTimeout(function () {
      setAlertStatus(false);
    }, 10000);
  };

  const setCurrentStars = (val) => {
    setStarsVisual(val);
  };

  const setStars = () => {
    let stars = [];
    let _s = starsVisual;
    for (let i = 1; i <= _s; i++) {
      stars.push(
        <StarIcon
          className={classes.iconColor}
          onMouseOver={(a) => {
            setCurrentStars(i);
          }}
        />
      );
    }
    for (let i = _s; i < 5; i++) {
      stars.push(
        <StarBorderIcon
          className={classes.iconColor}
          onMouseOver={(a) => {
            setCurrentStars(i + 1);
          }}
        />
      );
    }
    setRateStars(stars);
  };

  const enviarComentarios = async () => {
    let _c;
    try {
      _c = await productsService.createComment(
        props.productDetails.product_id,
        JSON.parse(localStorage.getItem("user")).usuario.snu_usuario_snu_id,
        starsVisual,
        comentarios,
        JSON.parse(localStorage.getItem("user")).usuario.snu_usuario_snu_id
      );
      if (_c.status == 200) {
        cometarioForm(2);
        //setAlertStatusTimeout();
        setAlertSeverity("info");
        setAlertMessage(
          "Tu comentario será publicado en las próximas 48 horas."
        );
      } else {
        cometarioForm(2);
        //setAlertStatusTimeout();
        setAlertSeverity("info");
        setAlertMessage(
          "Tu comentario será publicado en las próximas 48 horas."
        );
      }
    } catch (e) {
      //setAlertStatusTimeout();
      setAlertSeverity("error");
      setAlertMessage(
        "Ya se ha escrito previamente un comentario para este producto."
      );
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.rating}>
        <SectionProductGlobalRating productDetails={props.productDetails} />
        {JSON.parse(localStorage.getItem("user")) !== null ? (
          <div className={classes.btnDiv}>
            <Button
              variant="outlined"
              className={classes.btn + " " + listadoComentarios}
              onClick={() => {
                cometarioForm(1);
              }}
            >
              Quiero escribir un comentario
            </Button>
          </div>
        ) : (
          ""
        )}
        <div
          className={classes.btnDiv + " " + (alertStatus ? "" : classes.hide)}
        >
          <Alert severity={alertSeverity}>{alertMessage}</Alert>
        </div>
      </div>
      <div className={classes.comments + " " + listadoComentarios}>
        {props.productDetails.clients_comments.comments.length > 0 ? (
          props.productDetails.clients_comments.comments.map((comment) => (
            <div className={classes.commentDiv}>
              <div className={classes.userDiv}>
                <SectionProductClientCommentName productDetails={comment} />
              </div>
              <div className={classes.commentsDiv}>
                <SectionProductClientCommentText productDetails={comment} />
              </div>
            </div>
          ))
        ) : (
          <Typography>No hay comentarios para este producto</Typography>
        )}
      </div>
      <div className={classes.addCommentDiv + " " + agregarComentario}>
        {rateStars}
        <TextField
          id="outlined-multiline-static"
          label="Escribe tus comentarios"
          multiline
          rows={10}
          defaultValue={comentarios}
          value={comentarios}
          onChange={(e) => {
            setComentarios(e.target.value);
          }}
          variant="outlined"
          fullWidth
          className={classes.comentTextarea}
        />
        <Button
          className={classes.btnEnviar + " " + agregarComentario}
          onClick={() => {
            enviarComentarios();
          }}
        >
          Enviar comentarios
        </Button>
        <Button
          variant="outlined"
          className={classes.btnRegresar + " " + agregarComentario}
          onClick={() => {
            cometarioForm(2);
          }}
        >
          Regresar a comentarios
        </Button>
      </div>
    </div>
  );
}
