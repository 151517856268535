import React, { useState, useEffect, Fragment } from "react";
import { homeServices } from "../../_services/home.services";
import { generalService } from "../../_services/general.service";
// import { cmsServices } from "../../_services/cms.services";
import SectionSpaceTop from "views/Home/Sections/SectionSpaceTop.js";
import useCheckMobileScreen from "components/Mobile/Mobile.js";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import "./Faq.css";

const Faq = () => {
  let isMobile = useCheckMobileScreen();
  const [dataBanner, setDataBanner] = useState("");
  const [faqData, setfaqData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getDataBanner();
    getFAQ();
  }, []);

  const getFAQ = async () => {
    let _res = await generalService.getFAQs();
    setfaqData(_res.constFaqs);
  };

  const getDataBanner = async () => {
    let _d = await homeServices.getFranjaInformativa();
    setDataBanner(_d.controles_maestros[0].cmm_valor);
  };

  const showAnswer = (event) => {
    event.preventDefault();
    // event.target.classList.toggle("active");
    event.currentTarget.classList.toggle("active");
  };

  const faqList = faqData.map((item, index) => (
    <Grid className="faqItem" item xs={12} md={12} key={index}>
      <div className="faqQuestion" onClick={(event) => showAnswer(event)}>
        <span>{item.faqs_pregunta}</span>
        <i className="fas fa-chevron-down"></i>
        <i className="fas fa-chevron-up"></i>
      </div>
      <div className="faqAnswer">{item.faqs_respuesta}</div>
    </Grid>
  ));

  return (
    <div className="mainBackground" style={{ backgroundColor: "white" }}>
      <Container fixed style={{ paddingTop: isMobile ? 2 : 0 }}>
        <SectionSpaceTop />

        <div className="faqContainer">
          <Container maxWidth="lg">
            <h1 className="title">Preguntas frecuentes</h1>
            <Grid container spacing={3}>
              {faqList}
            </Grid>
          </Container>
        </div>
      </Container>
    </div>
  );
};
export default Faq;
