import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  Fragment,
} from "react";
import { userService } from "../../_services/user.service";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';

import "../../assets/custom/Login.css";
// @material-ui/
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import EmailIcon from "@material-ui/icons/Email";

/**Alertas */
import {AlertForm}  from "ui/alertForm";

// core components
import useCheckMobileScreen from "components/Mobile/Mobile.js";

const useStyles = makeStyles((styles) => ({
  mainBackground: {
    backgroundColor: "#FFF",
  },
  containerGrid: {
    width: "95%",
    minHeight: 600,
    margin: "auto",
    display: "flex",
    alignItems: "flex-start",
    paddingTop: 0,
    [styles.breakpoints.down("sm")]: {
      marginBottom: "10vh",
    
    },
  },
  buttonDiv: {
    width: "100%",
    paddingLeft: "25%",
    paddingRight: "25%",
    marginBottom: 15,
    [styles.breakpoints.down("sm")]: {
      paddingLeft: 15,
      paddingRight: 15,
      width: "90%",
      margin: "auto",
    },
  },
  inputsDiv: {
    margin: "0 10px 10px 0",
    /* [styles.breakpoints.down("sm")]: {
      paddingLeft: 15,
      paddingRight: 15,
    }, */
  },
  inputs: {
    width: "100%",
    borderRadius: "2px",
    backgroundColor: "#FFFFFF",
    "& label": {
      color: "#0B3196",
    },
    "& input": {
      height: "0.88em",
      padding: 10,
    },
    "& div": {
      borderRadius: "2px",
    },
    "& input::placeholder": {
      verticalAlign: "middle",
      fontFamily: "'Centrale Sans Light'",
      fontSize: 16,
    },
  },
  inputPlaceholder: {
    "&::placeholder": {
      color: "#0B3196",
      opacity: 1,
    },
  },
  inputsTextarea: {
    fontFamily: "'Centrale Sans Light'",
    fontSize: 16,
    fontWeight: 100,
    width: "100%",
    border: "1px solid #CAC4C4",
    borderRadius: "2px",
    backgroundColor: "#FFFFFF",
    display: "flex",
    margin: "auto",
    padding: 10,
    "&::placeholder": {
      color: "#0B3196",
    },
  },
  //alerts
  alerts: {
    color: "#FFFFFF",
    backgroundColor: "#40DF00",
    fontFamily: "'Centrale Sans Light'",
    fontSize: 22,
    "& .MuiAlert-icon": {
      color: "#FFFFFF",
    },
    "&.MuiAlert-standardError": {
      backgroundColor: "#DF2B2B",
    },
  },
  buttons: {
    marginTop: 40,
    width: "100%",
    height: 35,
    backgroundColor: "#ffffff",
    color: "#0C2D9C",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    "&:hover": {
      color: "#3e50c5",
    },
    "& span": {
      marginLeft: 10,
    },
  },
  title: {
    fontSize: 28,
    textAlign: "center",
    width: "100%",
    color: "#0b3196",
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "500",
    lineHeight: 1.2,
    [styles.breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
  titleContact: {
    fontSize: 28,
    textAlign: "center",
    width: "100%",
    color: "#FFFFFF",
    fontFamily: "'Centrale Sans Medium'",
    marginBottom: 20
  },
  subTitleText: {
    color: "#000000",
    fontFamily: "'Centrale Sans Regular'",
    textAlign: "center",
    fontSize: 18,
    [styles.breakpoints.down("sm")]: {
      //textAlign: "justify",
      textAlign: "center",
    },
  },
  contactForm: {
    backgroundColor: "#0B3196",
    borderRadius: 3,
  },
}));

export default function ContactView(props) {
  const classes = useStyles();
  const { ...rest } = props;
  let history = useHistory();

  let isMobile = useCheckMobileScreen();

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [openAlert, setOpenAlert] = useState({
    message: "",
    type: "",
    open: false,
  });
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])
  const handleChange = (event) => {
    if (event.target.name === "name") 
    {
      const onlyLettersValue = event.target.value.replace(/[^A-Za-z\s]/g, '');
      setName(onlyLettersValue);
    } else if (event.target.name === "mobile") {
      const formattedValue = event.target.value.replace(/[^0-9]/g, '').slice(0, 10);
      setMobile(formattedValue);
    } else if (event.target.name === "email") {
      setEmail(event.target.value);
    } else if (event.target.name === "company") {
      setCompany(event.target.value);
    } else if (event.target.name === "message") {
      setMessage(event.target.value);
    }
  };

  const contactUs = async (ev) => {
    ev.preventDefault();
    if(!/\S+@\S+\.\S+/.test(email)) {
      window.scrollTo(0,0);
      setOpenAlert({open:true, type:'error', message:'El formato del correo electrónico debe ser ejemplo@mail.com'})
    
    }else{
    const contact_response = await userService.contactSend(
      name,
      mobile,
      email,
      company,
      message
    );

    if (contact_response.error) {
      setOpenAlert({
        message: "Hubo un error al intentar enviar el mensaje de contacto.",
        type: "error",
        open: true,
      });
    } else {
      
      setOpenAlert({
        message: "Se recibieron los datos de contacto exitosamente.",
        type: "success",
        open: true,
      });

      setName('')
      setMobile('')
      setEmail('')
      setCompany('')
      setMessage('')

    }
  }
  };

  return (
    <div className={classes.mainBackground}>
      <Container fixed style={{paddingTop: isMobile ? 2 : 50 }} >
    
      <AlertForm setOpenAlert={setOpenAlert} openAlert={openAlert}/>
      <Grid container spacing={6} className={classes.containerGrid}>
      
        <Grid item container xs={12} md={6}>
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: 20 }}>
              <Typography className={classes.title}>
                ¿Necesitas ayuda?¿Buscas más información?
              </Typography>
              <Typography className={classes.title}>
                ¿Quieres conocer más acerca de Dielsa?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.subTitleText}>
                Por favor llena el siguiente formulario,
              </Typography>
              <Typography className={classes.subTitleText}>
                un vendedor te contactará lo más pronto posible.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={6} className={classes.contactForm}>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.titleContact}>
                Contáctanos
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={contactUs}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <div className={classes.inputsDiv}>
                      <TextField
                        required
                        variant="outlined"
                        className={classes.inputs}
                        size="small"
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Nombre"
                        InputProps={{
                          classes: { input: classes.inputPlaceholder },
                        }}
                        value={name}
                        onChange={handleChange}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className={classes.inputsDiv}>
                      <TextField
                        required
                        variant="outlined"
                        className={classes.inputs}
                        size="small"
                        id="mobile"
                        name="mobile"
                        type="text"
                        placeholder="Celular"
                        InputProps={{
                          classes: { input: classes.inputPlaceholder },
                        }}
                        value={mobile}
                        onChange={handleChange}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className={classes.inputsDiv}>
                      <TextField
                        required
                        variant="outlined"
                        className={classes.inputs}
                        size="small"
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Correo"
                        InputProps={{
                          classes: { input: classes.inputPlaceholder },
                        }}
                        value={email}
                        onChange={handleChange}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className={classes.inputsDiv}>
                      <TextField
                        variant="outlined"
                        className={classes.inputs}
                        size="small"
                        id="company"
                        name="company"
                        type="text"
                        placeholder="Empresa(opcional)"
                        InputProps={{
                          classes: { input: classes.inputPlaceholder },
                        }}
                        value={company}
                        onChange={handleChange}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.inputsDiv}>
                      <TextareaAutosize
                        required
                        aria-label="message"
                        rowsMin={5}
                        placeholder="Mensaje"
                        className={classes.inputsTextarea}
                        size="small"
                        id="message"
                        name="message"
                        value={message}
                        onChange={handleChange}
                      />
                    </div>
                  </Grid>
                </Grid>

                <div className={classes.buttonDiv}>
                  <Button
                    variant="contained"
                    className={classes.buttons}
                    type="submit"
                  >
                    <EmailIcon />
                    <span>Enviar mis datos</span>
                  </Button>
                </div>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </Container>
    </div>
  );
}
