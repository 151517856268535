import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useRef,
  Fragment,
  Component,
} from "react";
import clsx from "clsx";
import { userService } from "../../_services/user.service";
import { generalService } from "../../_services/general.service";

import { Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';

import "../../assets/custom/Login.css";
// @material-ui/
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { DropzoneDialog } from "material-ui-dropzone";

// core components
import useCheckMobileScreen from "components/Mobile/Mobile.js";


import SectionSpaceTop from "views/Home/Sections/SectionSpaceTop.js";

import { useAuth } from "hooks/useAuth";
// sections for this page

const useStyles = makeStyles((styles) => ({
  mainBackground: {
    backgroundColor: "#FFF",
  },
  title: {
    fontSize: 28,
    textAlign: "center",
    width: "100%",
    color: "#0b3196",
    fontFamily: "'Centrale Sans Bold'",
    fontWeight: "600",
  },
  container: {
    width: "100%",
  },
  accesoCliente: {
    display: "block",
    width: "60%",
    margin: "auto",
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: "10vh",
    paddingBottom: "20vh",
    /* backgroundColor: "#efefef", */
    [styles.breakpoints.down("sm")]: {
      width: "100%",
      paddingBottom: "5vh",
      paddingTop: "2vh",
    },
  },
  inputsDiv: {
    width: "100%",
    paddingLeft: "15%",
    paddingRight: "15%",
    marginBottom: 15,
    [styles.breakpoints.down("sm")]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  buttonDiv: {
    width: "100%",
    paddingLeft: "25%",
    paddingRight: "25%",
    marginBottom: 15,
    [styles.breakpoints.down("sm")]: {
      paddingTop: 30,
      paddingLeft: 15,
      paddingRight: 15,
      width: "90%",
      margin: "auto",
    },
  },
  inputs: {
    width: "100%",
    border: "1px solid #EAEAEA",
    borderRadius: "2px",
    backgroundColor: "#F5F5F5",
  },
  inputsWithoutWidth: {
    border: "1px solid #EAEAEA",
    borderRadius: "2px",
    backgroundColor: "#F5F5F5",
    marginTop: 10,
  },
  spaceDiv: {
    marginTop: 15,
    marginBottom: 15,
  },
  buttons: {
    width: "100%",
    color: "#ffffff",
    backgroundColor: "#0C2D9C",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  buttonsWithoutWidth: {
    marginTop: 40,
    color: "#ffffff",
    backgroundColor: "#0C2D9C",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  clienteNuevoTextDiv: {
    width: "100%",
    marginBottom: 15,
  },
  nuevoClienteText: {
    color: "#000000",
    fontFamily: "'Centrale Sans Regular'",
    textAlign: "center",
    marginTop: 10,
    marginBottom: 10,
    fontSize: 18,
  },
  linkBack: {
    fontFamily: "'Centrale Sans Medium'",
    color: "#0B3196",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "19px",
    textAlign: "center",
    paddingLeft: "30px",
    position: "relative",
  },
  linkBackArrow: {
    position: "absolute",
    left: "5px",
    bottom: "-2px",
  },
  titleMobile: {
    fontFamily: "'Centrale Sans Bold'",
    fontSize: 24,
    lineHeight: "29px",
    textAlign: "center",
    color: "#0b3196",
    paddingTop: 20,
  },
  titleDivider: {
    border: "1px solid #0B3196",
    width: "95%",
    margin: "auto",
  },
  root: {
    "& .MuiTextField-root": {
      margin: styles.spacing(1),
      width: "25ch",
    },
  },
  rootGrid: {
    flexGrow: 1,
    margin: "50px 0",
    [styles.breakpoints.down("sm")]: {
      margin: "20px 0",
    },
  },
  titleFormSection: {
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
    color: "#0C2D9C",
    [styles.breakpoints.down("sm")]: {
      fontFamily: "'Centrale Sans Regular'",
      fontSize: 14,
      fontWeight: 300,
    },
  },
  subTitleFormSection: {
    fontFamily: "'Centrale Sans Regular'",
    fontSize: 14,
    fontWeight: 300,
    textAlign: "center",
    color: "#0C2D9C",
  },
  formControl: {
    minWidth: "100%",
    "& label": {
      transform: "translate(14px, 14px) scale(1)",
    },
  },
  selectEmpty: {
    marginTop: styles.spacing(2),
  },
  selects: {
    backgroundColor: "#F5F5F5",
    border: "1px solid #EAEAEA",
    borderRadius: 4,
    height: 40,
    fontFamily: "'Centrale Sans Regular'",
  },
  gridFlex: {
    display: "flex",
    marginTop: 20,
  },
  inputsDate: {
    "& input": {
      padding: 11,
    },
  },
  //alerts
  alerts: {
    color: "#FFFFFF",
    backgroundColor: "#40DF00",
    fontFamily: "'Centrale Sans Light'",
    fontSize: 22,
    "& .MuiAlert-icon": {
      color: "#FFFFFF",
    },
    "&.MuiAlert-standardError": {
      backgroundColor: "#DF2B2B",
    },
  },
}));

export default function CreditRequest(props) {

  const classes = useStyles();
  const { ...rest } = props;

  let isMobile = useCheckMobileScreen();

  const {setOpenAlert} = useAuth();

  const [user, setUser] = useState({});
  const [quienInforma, setQuienInforma] = useState("representante");
  const [tipoEmpresa, setTipoEmpresa] = useState("trasnacional");
  const [tieneVehiculosReparto, setTieneVehiculosReparto] = useState("no");
  const [tieneSucursales, setTieneSucursales] = useState("no");
  const [perteneceGrupo, setPerteneceGrupo] = useState("no");
  const [tipoRamaVentas, setTipoRamaVentas] = useState("publico");
  const [tipoMonedaFactura, setTipoMonedaFactura] = useState("peso");
  const [tipoDomicilioApodLegal, setTipoDomicilioApodLegal] = useState(
    "propio"
  );
  const [tipoLocal, setTipoLocal] = useState("propio");
  const [pais, setPais] = useState([]);
  const [paisSelected, setPaisSelected] = useState(0);
  const [estado, setEstado] = useState([]);
  const [estadoSelected, setEstadoSelected] = useState(0);
  const [inputFields, setInputFields] = useState([
    {
      ssdc_codigo_postal: "",
      ssdc_calle: "",
      ssdc_numero: "",
      ssdc_colonia: "",
      ssdc_municipio: "",
      ssdc_estado: "",
      ssdc_telefono: "",
      ssdc_correo_electronico: "",
    },
  ]);
  const [empleadosqty, setEmpleadosqty] = useState(0);
  const [inputFieldsVehiculos, setInputFieldsVehiculos] = useState([
    {
      vsdc_marca: "",
      vsdc_tipo: "",
      vsdc_model: "",
    },
  ]);

  const [filesLoad, setFilesLoad] = useState(false);
  const [creditRequestResponse, setCreditRequestResponse] = useState({});
  const [files, setFiles] = useState();
  const [openDrop, setOpenDrop] = useState(false);

  const [valueAnswer, setValueAnswer] = useState({
    numero: '',
    telefonopago: '',
    telefonocontabilidad: '',
    telefonodireccion: '',
    telefonofacturacion: '',
    telefonoapodlegal: '',
    telefonoref1: '',
    telefonoref2: '',
    telefonoref3: '',
    telefonobancoref1: '',
    telefonobancoref2: '',
  });

  const [valueAnswerName, setValueAnswerName] = useState({
    nombreref1: '',
    nombreref2: '',
    nombreref3: '',
    contactopago: '',
    contactocontabilidad: '',
    contactodireccion: '',
    contactofacturacion: '',
    nombrerecoge1: '',
    nombrerecoge2: '',
    nombrerecoge3: '',
  });

  const handleChangeValue = (event) => {
    const textId = event.target.id;
    const formattedValue = event.target.value.replace(/[^0-9]/g, '').slice(0, 10);
    setValueAnswer((e) => ({...e, [textId]: formattedValue }));
  };

  const handleChangeValueName = (event) => {
    const textId = event.target.id;
    const onlyLettersValue = event.target.value.replace(/[^A-Za-z\s]/g, '');
    setValueAnswerName((e) => ({...e, [textId]: onlyLettersValue }));
  };

  const getPais = async () => {
    const pais_response = await generalService.getCountries();
    setPais(pais_response.paises);
  };

  const getEstado = async (paisId) => {
    const estado_response = await generalService.getStates(paisId);
    setEstado(estado_response.estados);
  };

  useEffect(() => {
    window.scrollTo(0,0);
    if (!localStorage.getItem("user")) {
      //redirect to login
      props.history.push("/login");
    } else {
      getPais();
      setUser(JSON.parse(localStorage.getItem("user")).usuario);
    }
  }, []);

  const handleChange = (event) => {
    if (event.target.name === "pais") {
      setEstadoSelected(0);
      setPaisSelected(event.target.value);
      getEstado(event.target.value);
    }
    if (event.target.name === "estado") {
      setEstadoSelected(event.target.value);
    }
  };

  const handleChangeEmpleadosQty = (event) => {
    setEmpleadosqty(event.target.value);
  };

  const handleChangeRadioQuienInforma = (event) => {
    setQuienInforma(event.target.value);
  };

  const handleChangeRadioTipoEmpresa = (event) => {
    setTipoEmpresa(event.target.value);
  };

  const handleChangeRadioTieneSucursales = (event) => {
    setTieneSucursales(event.target.value);
  };

  const handleChangeRadioTieneVehiculosReparto = (event) => {
    setTieneVehiculosReparto(event.target.value);
  };

  const handleChangeRadioPerteneceGrupo = (event) => {
    setPerteneceGrupo(event.target.value);
  };

  const handleChangeRadioTipoDomicilioApodLegal = (event) => {
    setTipoDomicilioApodLegal(event.target.value);
  };

  const handleChangeRadioTipoLocal = (event) => {
    setTipoLocal(event.target.value);
  };

  const handleChangeRadioTipoRamaVentas = (event) => {
    setTipoRamaVentas(event.target.value);
  };

  const handleChangeRadioTipoMonedaFactura = (event) => {
    setTipoMonedaFactura(event.target.value);
  };

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({
      ssdc_codigo_postal: "",
      ssdc_calle: "",
      ssdc_numero: "",
      ssdc_colonia: "",
      ssdc_municipio: "",
      ssdc_estado: "",
      ssdc_telefono: "",
      ssdc_correo_electronico: "",
    });
    setInputFields(values);
  };

  const handleRemoveFields = (index) => {
    if (inputFields.length > 1) {
      const values = [...inputFields];
      values.splice(index, 1);
      setInputFields(values);
    }
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    //values[index].indexsuc = index;

    if (event.target.name === "ssdc_codigo_postal") {
      values[index].ssdc_codigo_postal = event.target.value;
    } else if (event.target.name === "ssdc_calle") {
      values[index].ssdc_calle = event.target.value;
    } else if (event.target.name === "ssdc_numero") {
      values[index].ssdc_numero = event.target.value;
    } else if (event.target.name === "ssdc_colonia") {
      values[index].ssdc_colonia = event.target.value;
    } else if (event.target.name === "ssdc_municipio") {
      values[index].ssdc_municipio = event.target.value;
    } else if (event.target.name === "ssdc_estado") {
      values[index].ssdc_estado = event.target.value;
    } else if (event.target.name === "ssdc_telefono") {
      values[index].ssdc_telefono = event.target.value;
    } else if (event.target.name === "ssdc_correo_electronico") {
      values[index].ssdc_correo_electronico = event.target.value;
    }
    setInputFields(values);
  };

  const handleAddFieldsVehiculo = () => {
    const values = [...inputFieldsVehiculos];
    values.push({
      vsdc_marca: "",
      vsdc_tipo: "",
      vsdc_model: "",
    });
    setInputFieldsVehiculos(values);
  };

  const handleRemoveFieldsVehiculo = (index) => {
    if (inputFieldsVehiculos.length > 1) {
      const values = [...inputFieldsVehiculos];
      values.splice(index, 1);
      setInputFieldsVehiculos(values);
    }
  };

  const handleInputChangeVehiculo = (index, event) => {
    const values = [...inputFieldsVehiculos];
    //values[index].indexsuc = index;

    if (event.target.name === "vsdc_marca") {
      values[index].vsdc_marca = event.target.value;
    } else if (event.target.name === "vsdc_tipo") {
      values[index].vsdc_tipo = event.target.value;
    } else if (event.target.name === "vsdc_model") {
      values[index].vsdc_model = event.target.value;
    }
    setInputFieldsVehiculos(values);
  };

  const requestCredit = async (event) => {
    event.preventDefault();

    const register_response = await userService.requestCreditClient(
      event.target.razonsocial.value,
      event.target.rfc.value,
      event.target.sitioweb.value,
      event.target.giroempresa.value,
      event.target.nombreinforma.value,
      quienInforma,
      event.target.tipoquieninforma ? event.target.tipoquieninforma.value : "",
      event.target.antiguedadpuesto.value,
      event.target.codigopostal.value,
      event.target.calle.value,
      event.target.numero.value,
      event.target.colonia.value,
      event.target.municipio.value,
      estadoSelected,
      paisSelected,
      event.target.telefonopago.value,
      event.target.telefonocontabilidad.value,
      event.target.telefonodireccion.value,
      event.target.telefonofacturacion.value,
      event.target.contactopago.value,
      event.target.contactocontabilidad.value,
      event.target.contactodireccion.value,
      event.target.contactofacturacion.value,
      event.target.emailpago.value,
      event.target.emailcontabilidad.value,
      event.target.emaildireccion.value,
      event.target.emailfacturacion.value,
      tipoEmpresa,
      tieneSucursales === "si" ? true : false,
      inputFields,
      perteneceGrupo === "si" ? true : false,
      event.target.cualgrupo ? event.target.cualgrupo.value : "",
      event.target.antiguedadempresa.value,
      event.target.fechaoperacioninicio.value,
      event.target.capitalfijo.value,
      event.target.capitalvariable.value,
      event.target.frentedim.value,
      event.target.fondodim.value,
      event.target.superficiedim.value,
      event.target.codigopostalapodlegal.value,
      event.target.calleapodlegal.value,
      event.target.numeroapodlegal.value,
      event.target.coloniaapodlegal.value,
      event.target.municipioapodlegal.value,
      event.target.estadoapodlegal.value,
      event.target.telefonoapodlegal.value,
      event.target.emailapodlegal.value,
      tipoDomicilioApodLegal,
      event.target.rentaapodlegal ? event.target.rentaapodlegal.value : "",
      tipoRamaVentas,
      event.target.empresaramaventas
        ? event.target.empresaramaventas.value
        : "",
      event.target.territorioinflu.value,
      event.target.volumencompra.value,
      event.target.promedioventas.value,
      event.target.ventasanuales.value,
      event.target.utilidadesanoprev.value,
      event.target.utilidadesproyectadas.value,
      tipoMonedaFactura,
      empleadosqty === "+10"
        ? event.target.empleadosqtyexacta.value
        : empleadosqty,
      /* event.target.empleadosqtyexacta
        ? event.target.empleadosqtyexacta.value
        : "", */
      event.target.pagosfijosmensuales.value,
      tipoLocal,
      event.target.montorentamensual
        ? event.target.montorentamensual.value
        : "",
      event.target.limitecredito.value,
      event.target.plazocredito.value,
      event.target.nombreref1.value,
      event.target.nombreref2.value,
      event.target.nombreref3.value,
      event.target.montoref1.value,
      event.target.montoref2.value,
      event.target.montoref3.value,
      event.target.antiguedadref1.value,
      event.target.antiguedadref2.value,
      event.target.antiguedadref3.value,
      event.target.plazoref1.value,
      event.target.plazoref2.value,
      event.target.plazoref3.value,
      event.target.telefonoref1.value,
      event.target.telefonoref2.value,
      event.target.telefonoref3.value,
      event.target.bancoref1.value,
      event.target.bancoref2.value,
      event.target.numerocuentaref1.value,
      event.target.numerocuentaref2.value,
      event.target.sucursalbancoref1.value,
      event.target.sucursalbancoref2.value,
      event.target.ejecutivoref1.value,
      event.target.ejecutivoref2.value,
      event.target.telefonobancoref1.value,
      event.target.telefonobancoref2.value,
      event.target.nombrerecoge1 ? event.target.nombrerecoge1.value : "",
      event.target.nombrerecoge2 ? event.target.nombrerecoge2.value : "",
      event.target.nombrerecoge3 ? event.target.nombrerecoge3.value : "",
      tieneVehiculosReparto === "si" ? true : false,
      event.target.qtyvehiculosreparto
        ? event.target.qtyvehiculosreparto.value
        : "",
      inputFieldsVehiculos,
      user.snu_sn_socio_de_negocio_id
    );

    if (register_response.error) {
      setOpenAlert({
        message: "Hubo un error al intentar solicitar el crédito.",
        type: "error",
        open: true,
      });
    } else {
      setOpenAlert({
        message:
          "La solicitud de crédito fue enviada con exito. Por favor anexe los documentos solicitados para completar el proceso.",
        type: "info",
        open: true,
      });
      event.target.reset()
      setInputFieldsVehiculos('')
      setTieneVehiculosReparto('no')
      
      setFilesLoad(true);
      setCreditRequestResponse(register_response.data.solicitud_de_credito);
    }
  };

  const handleSaveFiles = async (files) => {
    const formData = new FormData();

    formData.append("files", files[0]);
    formData.append("sn_socios_negocio_id", user.snu_sn_socio_de_negocio_id);
    formData.append(
      "adi_sdc_solicitud_de_credito_id",
      creditRequestResponse.sdc_solicitud_de_credito_id
    );

    const load_anexos_request = await userService.loadAnexosZip(
      formData,
      user.token
    );

    //here according to the respond of the fetch to create the user, then add value to the message and the type, to be show it
    if (load_anexos_request.error) {
      setOpenAlert({
        message: "No se pudo cargar el ZIP",
        type: "error",
        open: true,
      });
    } else {
      setOpenAlert({
        message: "Cargados exitosamente los Anexos ZIP.",
        type: "success",
        open: true,
      });

      //Saving files to state for further use and closing Modal.
      setFiles(files);
      setOpenDrop(false);
    }
  };

  return (
    <div className={classes.mainBackground}>
      <Container fixed style={{paddingTop: isMobile ? 150 : 0 }}>
      <SectionSpaceTop />

      {isMobile ? (
        <div className={classes.spaceDiv}>
          <Link to="/" className={classes.linkBack}>
            <ChevronLeftIcon className={classes.linkBackArrow} /> Regresar
          </Link>
          <Typography className={classes.titleMobile}>
            Solicitud de crédito
          </Typography>
          <Divider className={classes.titleDivider} />
        </div>
      ) : (
        ""
      )}

      <form
        className={classes.accesoCliente}
        onSubmit={(event) => requestCredit(event)}
      >
        {isMobile ? (
          ""
        ) : (
          <div>
            <div className={classes.container}>
              <Typography className={classes.title}>
                Solicitud de crédito
              </Typography>
            </div>
          </div>
        )}
        <div className={classes.clienteNuevoTextDiv}>
          <Typography>
            ANEXAR LA DOCUMENTACIÓN SOLICITADA:
            <ol>
              <li>
                Acta Constitutiva de la Empresa con el registro de inscripción
                al Registro Público de la Propiedad y del Comercio.
              </li>
              <li>
                Cédula de Identificación Fiscal y Opinión de Cumplimiento.
              </li>
              <li>
                Estados de Cuenta Bancaria (3 últimos) de las 3 principales
                cuentas.
              </li>
              <li>
                Comprobante de domicilio fiscal (servicios) y de cada una de las
                sucursales.
              </li>
              <li>Identificación oficial vigente del representante Legal.</li>
              <li>
                Comprobante de domicilio (servicios) particular del Accionista
                Mayoritario.
              </li>
              <li>
                En caso de que el Apoderado Legal sea de nacionalidad
                extranjera, deberá acreditar su legal estancia en el país.
              </li>
              <li>
                En caso de ser empresa de nueva creación (hasta 2 años de
                operaciones), deberá anexar copia certificada de bien inmueble
                libre de gravamen que ampare el monto del crédito solicitado.
              </li>
              <li>
                En caso de ser persona física deberá anexar copia certificada de
                bien inmueble libre de gravamen que ampare el monto del crédito
                solicitado.
              </li>
              <li>
                Estados Financieros de los últimos 2 años firmados por el
                contador de la empresa (anexar cédula profesional del Contador).
              </li>
              <li>Declaración anual del ejercicio inmediato anterior.</li>
              <li>
                Reporte del Buró de Crédito con no mayor a 3 meses de
                antigüedad.
              </li>
              <li>Procedimiento para Pago de Facturas.</li>
            </ol>
          </Typography>
          <Typography>
            DOCUMENTOS A FIRMAR UNA VEZ APROBADO EL CRÉDITO:
            <ul>
              <li>Pagaré</li>
              <li>Contrato</li>
              <li>Domiciliación</li>
            </ul>
          </Typography>
        </div>

        <div className={classes.rootGrid}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.titleFormSection}>
                Datos del solicitante
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={razonsocial}
                id="razonsocial"
                name="razonsocial"
                type="text"
                label="Nombre o Razón Social"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={rfc}
                id="rfc"
                name="rfc"
                type="text"
                label="RFC (RUC)"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={sitioweb}
                id="sitioweb"
                name="sitioweb"
                type="text"
                label="Sitio web"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={giroempresa}
                id="giroempresa"
                name="giroempresa"
                type="text"
                label="Giro de la empresa"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={nombreinforma}
                    id="nombreinforma"
                    name="nombreinforma"
                    type="text"
                    label="Nombre de quien proporciona la información"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend"></FormLabel>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="gender1"
                      value={quienInforma}
                      onChange={handleChangeRadioQuienInforma}
                    >
                      <FormControlLabel
                        value="representante"
                        control={<Radio color="default" />}
                        label="Representante"
                      />
                      <FormControlLabel
                        value="propietario"
                        control={<Radio color="default" />}
                        label="Propietario"
                      />
                      <FormControlLabel
                        value="otro"
                        control={<Radio color="default" />}
                        label="Otro"
                      />
                      {quienInforma === "otro" ? (
                        <TextField
                          required
                          variant="outlined"
                          className={classes.inputsWithoutWidth}
                          size="small"
                          //inputRef={tipoquieninforma}
                          id="tipoquieninforma"
                          name="tipoquieninforma"
                          type="text"
                          label="Especifique"
                        />
                      ) : (
                        ""
                      )}
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={antiguedadpuesto}
                    id="antiguedadpuesto"
                    name="antiguedadpuesto"
                    type="text"
                    label="Antigüedad en el puesto"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div className={classes.rootGrid}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.titleFormSection}>
                Domicilio
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={codigopostal}
                    id="codigopostal"
                    name="codigopostal"
                    type="text"
                    label="Código postal"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={calle}
                id="calle"
                name="calle"
                type="text"
                label="Calle"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    value={valueAnswer.numero}
                    onChange={(e) => handleChangeValue(e)}
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={numero}
                    id="numero"
                    name="numero"
                    type="text"
                    label="Número"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={colonia}
                id="colonia"
                name="colonia"
                type="text"
                label="Colonia"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={municipio}
                id="municipio"
                name="municipio"
                type="text"
                label="Municipio o Delegación"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl
                    required
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-pais-native-simple">
                      País
                    </InputLabel>
                    <Select
                      className={classes.selects}
                      native
                      value={paisSelected}
                      label="País"
                      onChange={handleChange}
                      inputProps={{
                        name: "pais",
                        id: "outlined-pais-native-simple",
                      }}
                    >
                      <option value=""></option>
                      {pais.map((p) => {
                        return (
                          <option value={p.pais_pais_id}>
                            {p.pais_nombre}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    required
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-estado-native-simple">
                      Estado
                    </InputLabel>
                    <Select
                      className={classes.selects}
                      native
                      value={estadoSelected}
                      label="Estado"
                      onChange={handleChange}
                      inputProps={{
                        name: "estado",
                        id: "outlined-estado-native-simple",
                      }}
                    >
                      <option value=""></option>
                      {estado.map((e) => {
                        return (
                          <option value={e.estpa_estado_pais_id}>
                            {e.estpa_estado_nombre}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div className={classes.rootGrid}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.titleFormSection}>
                Información de contacto
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.subTitleFormSection}>
                    Pagos
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={valueAnswer.telefonopago}
                    onChange={(e) => handleChangeValue(e)}
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={telefonopago}
                    id="telefonopago"
                    name="telefonopago"
                    type="text"
                    label="Teléfono (s)"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.inputs}
                    value={valueAnswerName.contactopago}
                    size="small"
                    //inputRef={contactopago}
                    id="contactopago"
                    name="contactopago"
                    type="text"
                    label="Contacto"
                    onChange={(e) => handleChangeValueName(e)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={emailpago}
                    id="emailpago"
                    name="emailpago"
                    type="email"
                    label="Email"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.subTitleFormSection}>
                    Contabilidad
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={valueAnswer.telefonocontabilidad}
                    onChange={(e) => handleChangeValue(e)}
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={telefonocontabilidad}
                    id="telefonocontabilidad"
                    name="telefonocontabilidad"
                    type="text"
                    label="Teléfono (s)"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={contactocontabilidad}
                    id="contactocontabilidad"
                    name="contactocontabilidad"
                    type="text"
                    label="Contacto"
                    value={valueAnswerName.contactocontabilidad}
                    onChange={(e) => handleChangeValueName(e)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={emailcontabilidad}
                    id="emailcontabilidad"
                    name="emailcontabilidad"
                    type="email"
                    label="Email"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.subTitleFormSection}>
                    Dirección General
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={valueAnswer.telefonodireccion}
                    onChange={(e) => handleChangeValue(e)}
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={telefonodireccion}
                    id="telefonodireccion"
                    name="telefonodireccion"
                    type="text"
                    label="Teléfono (s)"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={contactodireccion}
                    id="contactodireccion"
                    name="contactodireccion"
                    type="text"
                    label="Contacto"
                    value={valueAnswerName.contactodireccion}
                    onChange={(e) => handleChangeValueName(e)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={emaildireccion}
                    id="emaildireccion"
                    name="emaildireccion"
                    type="email"
                    label="Email"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.subTitleFormSection}>
                    Facturación
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={valueAnswer.telefonofacturacion}
                    onChange={(e) => handleChangeValue(e)}
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={telefonofacturacion}
                    id="telefonofacturacion"
                    name="telefonofacturacion"
                    type="text"
                    label="Teléfono (s)"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={contactofacturacion}
                    id="contactofacturacion"
                    name="contactofacturacion"
                    type="text"
                    label="Contacto"
                    value={valueAnswerName.contactofacturacion}
                    onChange={(e) => handleChangeValueName(e)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={emailfacturacion}
                    id="emailfacturacion"
                    name="emailfacturacion"
                    type="email"
                    label="Email"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div className={classes.rootGrid}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.titleFormSection}>
                Información de empresa
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend"></FormLabel>
                <RadioGroup
                  row
                  aria-label="gender"
                  name="gender1"
                  value={tipoEmpresa}
                  onChange={handleChangeRadioTipoEmpresa}
                >
                  <FormControlLabel
                    value="trasnacional"
                    control={<Radio color="default" />}
                    label="Trasnacional"
                  />
                  <FormControlLabel
                    value="nacional"
                    control={<Radio color="default" />}
                    label="Nacional bien establecida"
                  />
                  <FormControlLabel
                    value="pequena"
                    control={<Radio color="default" />}
                    label="Pequeña"
                  />
                  <FormControlLabel
                    value="familiar"
                    control={<Radio color="default" />}
                    label="Familiar"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  ¿Cuenta con sucursales?
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="gender"
                  name="gender1"
                  value={tieneSucursales}
                  onChange={handleChangeRadioTieneSucursales}
                >
                  <FormControlLabel
                    value="si"
                    control={<Radio color="default" />}
                    label="Si"
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio color="default" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {tieneSucursales === "si" ? (
              <Fragment>
                <Button
                  className={classes.buttonsWithoutWidth}
                  onClick={() => handleAddFields()}
                  type="button"
                  variant="contained"
                  color="primary"
                >
                  Agregar Sucursal <AddIcon />
                </Button>

                {inputFields.map((inputField, index) => (
                  <Fragment key={`${inputField}~${index}`}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={10}
                        justify="flex-start"
                        alignItems="flex-end"
                        className={classes.gridFlex}
                      >
                        <Typography className={classes.subTitleFormSection}>
                          Sucursal No. {index + 1}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        justify="flex-end"
                        className={classes.gridFlex}
                      >
                        <Button
                          onClick={() => handleRemoveFields(index)}
                          type="button"
                          variant="contained"
                          color="primary"
                          className={classes.buttonsWithoutWidth}
                        >
                          <DeleteIcon />
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          className={classes.inputs}
                          size="small"
                          onChange={(event) => handleInputChange(index, event)}
                          value={inputField.ssdc_codigo_postal}
                          id="ssdc_codigo_postal"
                          name="ssdc_codigo_postal"
                          type="text"
                          label="Código postal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          className={classes.inputs}
                          size="small"
                          onChange={(event) => handleInputChange(index, event)}
                          value={inputField.ssdc_calle}
                          id="ssdc_calle"
                          name="ssdc_calle"
                          type="text"
                          label="Calle"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          className={classes.inputs}
                          size="small"
                          onChange={(event) => handleInputChange(index, event)}
                          value={inputField.ssdc_numero}
                          id="ssdc_numero"
                          name="ssdc_numero"
                          type="text"
                          label="Numero"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          className={classes.inputs}
                          size="small"
                          onChange={(event) => handleInputChange(index, event)}
                          value={inputField.ssdc_colonia}
                          id="ssdc_colonia"
                          name="ssdc_colonia"
                          type="text"
                          label="Colonia"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          className={classes.inputs}
                          size="small"
                          onChange={(event) => handleInputChange(index, event)}
                          value={inputField.ssdc_municipio}
                          id="ssdc_municipio"
                          name="ssdc_municipio"
                          type="text"
                          label="Municipio"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          className={classes.inputs}
                          size="small"
                          onChange={(event) => handleInputChange(index, event)}
                          value={inputField.ssdc_estado}
                          id="ssdc_estado"
                          name="ssdc_estado"
                          type="text"
                          label="Estado"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          className={classes.inputs}
                          size="small"
                          onChange={(event) => handleInputChange(index, event)}
                          value={inputField.ssdc_telefono}
                          id="ssdc_telefono"
                          name="ssdc_telefono"
                          type="text"
                          label="Teléfono"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          className={classes.inputs}
                          size="small"
                          onChange={(event) => handleInputChange(index, event)}
                          value={inputField.ssdc_correo_electronico}
                          id="ssdc_correo_electronico"
                          name="ssdc_correo_electronico"
                          type="email"
                          label="Email"
                        />
                      </Grid>
                    </Grid>
                  </Fragment>
                ))}
              </Fragment>
            ) : (
              ""
            )}

            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Pertenece a algún Grupo
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="gender"
                  name="gender1"
                  value={perteneceGrupo}
                  onChange={handleChangeRadioPerteneceGrupo}
                >
                  <FormControlLabel
                    value="si"
                    control={<Radio color="default" />}
                    label="Si"
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio color="default" />}
                    label="No"
                  />
                  {perteneceGrupo === "si" ? (
                    <TextField
                      required
                      variant="outlined"
                      className={classes.inputsWithoutWidth}
                      size="small"
                      //inputRef={cualgrupo}
                      id="cualgrupo"
                      name="cualgrupo"
                      type="text"
                      label="¿Cuál?"
                    />
                  ) : (
                    ""
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={antiguedadempresa}
                id="antiguedadempresa"
                name="antiguedadempresa"
                type="text"
                label="Antigüedad de la empresa"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                id="fechaoperacioninicio"
                name="fechaoperacioninicio"
                //inputRef={fechaoperacioninicio}
                label="Fecha de inicio de operaciones"
                type="date"
                defaultValue={new Date()}
                className={clsx(classes.inputs, classes.inputsDate)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.subTitleFormSection}>
                    Capital Social Actual
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    variant="outlined"
                    id="capitalfijo"
                    name="capitalfijo"
                    //inputRef={capitalfijo}
                    label="Fijo"
                    type="text"
                    className={clsx(classes.inputs)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    variant="outlined"
                    id="capitalvariable"
                    name="capitalvariable"
                    //inputRef={capitalvariable}
                    label="Variable"
                    type="text"
                    className={clsx(classes.inputs)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div className={classes.rootGrid}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.titleFormSection}>
                Dimensiones del negocio
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={frentedim}
                id="frentedim"
                name="frentedim"
                type="text"
                label="Frente"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={fondodim}
                id="fondodim"
                name="fondodim"
                type="text"
                label="Fondo"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={superficiedim}
                id="superficiedim"
                name="superficiedim"
                type="text"
                label="Superficie"
              />
            </Grid>
          </Grid>
        </div>

        <div className={classes.rootGrid}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.titleFormSection}>
                Datos del apoderado legal
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.subTitleFormSection}>
                Domicilio particular
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={codigopostalapodlegal}
                    id="codigopostalapodlegal"
                    name="codigopostalapodlegal"
                    type="text"
                    label="Código postal"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={calleapodlegal}
                id="calleapodlegal"
                name="calleapodlegal"
                type="text"
                label="Calle"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={numeroapodlegal}
                    id="numeroapodlegal"
                    name="numeroapodlegal"
                    type="text"
                    label="Número"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={coloniaapodlegal}
                id="coloniaapodlegal"
                name="coloniaapodlegal"
                type="text"
                label="Colonia"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={municipioapodlegal}
                id="municipioapodlegal"
                name="municipioapodlegal"
                type="text"
                label="Municipio"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={estadoapodlegal}
                id="estadoapodlegal"
                name="estadoapodlegal"
                type="text"
                label="Estado"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={valueAnswer.telefonoapodlegal}
                onChange={(e) => handleChangeValue(e)}
                required
                fullWidth
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={telefonoapodlegal}
                id="telefonoapodlegal"
                name="telefonoapodlegal"
                type="text"
                label="Teléfono"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={emailapodlegal}
                id="emailapodlegal"
                name="emailapodlegal"
                type="email"
                label="Email"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend"></FormLabel>
                <RadioGroup
                  row
                  aria-label="gender"
                  name="gender1"
                  value={tipoDomicilioApodLegal}
                  onChange={handleChangeRadioTipoDomicilioApodLegal}
                >
                  <FormControlLabel
                    value="propio"
                    control={<Radio color="default" />}
                    label="Propio"
                  />
                  <FormControlLabel
                    value="rentado"
                    control={<Radio color="default" />}
                    label="Rentado"
                  />
                  {tipoDomicilioApodLegal === "rentado" ? (
                    <TextField
                      required
                      variant="outlined"
                      className={classes.inputsWithoutWidth}
                      size="small"
                      //inputRef={rentaapodlegal}
                      id="rentaapodlegal"
                      name="rentaapodlegal"
                      type="text"
                      label="Renta"
                    />
                  ) : (
                    ""
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </div>

        <div className={classes.rootGrid}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.titleFormSection}>
                Esta información es confidencial y para uso exclusivo de
                DISTRIBUIDORA DE ENLACES ELECTRÓNICOS S.A. DE C.V.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  ¿A qué ramos están dirigidos principalmente sus esfuerzos de
                  ventas?
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="gender"
                  name="gender1"
                  value={tipoRamaVentas}
                  onChange={handleChangeRadioTipoRamaVentas}
                >
                  <FormControlLabel
                    value="publico"
                    control={<Radio color="default" />}
                    label="Público en Gral."
                  />
                  <FormControlLabel
                    value="gobierno"
                    control={<Radio color="default" />}
                    label="Gobierno"
                  />
                  <FormControlLabel
                    value="diversos"
                    control={<Radio color="default" />}
                    label="Diversos"
                  />
                  <FormControlLabel
                    value="mixto"
                    control={<Radio color="default" />}
                    label="Mixto"
                  />
                  <FormControlLabel
                    value="solouno"
                    control={<Radio color="default" />}
                    label="Solo una empresa"
                  />
                  {tipoRamaVentas === "solouno" ? (
                    <TextField
                      required
                      variant="outlined"
                      className={classes.inputsWithoutWidth}
                      size="small"
                      //inputRef={empresaramaventas}
                      id="rentaapodlegal"
                      name="rentaapodlegal"
                      type="text"
                      label="Especifique"
                    />
                  ) : (
                    ""
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.subTitleFormSection}>
                ¿Qué territorio abarca su zona de influencia?
              </Typography>
              <TextField
                required
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={territorioinflu}
                id="territorioinflu"
                name="territorioinflu"
                type="text"
                label=""
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.subTitleFormSection}>
                ¿Cuál es el volumen mensual de compra para DIELSA?
              </Typography>
              <TextField
                required
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={volumencompra}
                id="volumencompra"
                name="volumencompra"
                type="text"
                label=""
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.subTitleFormSection}>
                ¿Cuál es su promedio mensual de ventas?
              </Typography>
              <TextField
                required
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={promedioventas}
                id="promedioventas"
                name="promedioventas"
                type="text"
                label=""
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.subTitleFormSection}>
                ¿Cuál es el monto de sus ventas anuales?
              </Typography>
              <TextField
                required
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={ventasanuales}
                id="ventasanuales"
                name="ventasanuales"
                type="text"
                label=""
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.subTitleFormSection}>
                Monto de sus utilidades del año anterior
              </Typography>
              <TextField
                required
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={utilidadesanoprev}
                id="utilidadesanoprev"
                name="utilidadesanoprev"
                type="text"
                label=""
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.subTitleFormSection}>
                Monto proyectado de las utilidades del presente año
              </Typography>
              <TextField
                required
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={utilidadesproyectadas}
                id="utilidadesproyectadas"
                name="utilidadesproyectadas"
                type="text"
                label=""
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Moneda en que factura </FormLabel>
                <RadioGroup
                  row
                  aria-label="gender"
                  name="gender1"
                  value={tipoMonedaFactura}
                  onChange={handleChangeRadioTipoMonedaFactura}
                >
                  <FormControlLabel
                    value="peso"
                    control={<Radio color="default" />}
                    label="Pesos"
                  />
                  <FormControlLabel
                    value="dolar"
                    control={<Radio color="default" />}
                    label="Dólares"
                  />
                  <FormControlLabel
                    value="otro"
                    control={<Radio color="default" />}
                    label="Otro"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.subTitleFormSection}>
                ¿Cuántos empleados laboran en la Empresa o Negocio?
              </Typography>
              <FormControl
                required
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-empleadosqty-native-simple">
                  No.
                </InputLabel>
                <Select
                  className={classes.selects}
                  native
                  value={empleadosqty}
                  label="País"
                  onChange={handleChangeEmpleadosQty}
                  inputProps={{
                    name: "empleadosqty",
                    id: "outlined-empleadosqty-native-simple",
                  }}
                >
                  <option value=""></option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="+10">+10</option>
                </Select>
              </FormControl>
            </Grid>
            {empleadosqty === "+10" ? (
              <Grid
                item
                xs={12}
                sm={6}
                className={classes.gridFlex}
                alignItems="flex-end"
              >
                <TextField
                  required
                  variant="outlined"
                  className={classes.inputs}
                  size="small"
                  //inputRef={empleadosqtyexacta}
                  id="empleadosqtyexacta"
                  name="empleadosqtyexacta"
                  type="text"
                  label="Especifique"
                />
              </Grid>
            ) : (
              ""
            )}
            <Grid item xs={12}>
              <Typography className={classes.subTitleFormSection}>
                Monto total de sus pagos fijos mensuales en el presente año
                (Renta Local, Vehículos, Créditos, Nómina, Etc.)
              </Typography>
              <TextField
                required
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={pagosfijosmensuales}
                id="pagosfijosmensuales"
                name="pagosfijosmensuales"
                type="text"
                label=""
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  El local del negocio es:
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="gender"
                  name="gender1"
                  value={tipoLocal}
                  onChange={handleChangeRadioTipoLocal}
                >
                  <FormControlLabel
                    value="propio"
                    control={<Radio color="default" />}
                    label="Propio"
                  />
                  <FormControlLabel
                    value="rentado"
                    control={<Radio color="default" />}
                    label="Rentado"
                  />
                  {tipoLocal === "rentado" ? (
                    <TextField
                      required
                      variant="outlined"
                      className={classes.inputsWithoutWidth}
                      size="small"
                      //inputRef={montorentamensual}
                      id="montorentamensual"
                      name="montorentamensual"
                      type="text"
                      label="Monto renta mensual"
                    />
                  ) : (
                    ""
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={limitecredito}
                id="limitecredito"
                name="limitecredito"
                type="text"
                label="Límite de crédito deseado"
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={plazocredito}
                id="plazocredito"
                name="plazocredito"
                type="text"
                label="Plazo"
              />
            </Grid>
          </Grid>
        </div>

        <div className={classes.rootGrid}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.titleFormSection}>
                Favor de anotar tres referencias comerciales (Vigencia de 6
                meses a la fecha)
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.subTitleFormSection}>
                    Referencia No. 1
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    value={valueAnswerName.nombreref1}
                    onChange={(e) => handleChangeValueName(e)}
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={nombreref1}
                    id="nombreref1"
                    name="nombreref1"
                    type="text"
                    label="Nombre"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={montoref1}
                    id="montoref1"
                    name="montoref1"
                    type="text"
                    label="Monto"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={antiguedadref1}
                    id="antiguedadref1"
                    name="antiguedadref1"
                    type="text"
                    label="Antigüedad"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={plazoref1}
                    id="plazoref1"
                    name="plazoref1"
                    type="text"
                    label="Plazo"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    value={valueAnswer.telefonoref1}
                    onChange={(e) => handleChangeValue(e)}
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={telefonoref1}
                    id="telefonoref1"
                    name="telefonoref1"
                    type="text"
                    label="Teléfonos"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.subTitleFormSection}>
                    Referencia No. 2
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    value={valueAnswerName.nombreref2}
                    onChange={(e) => handleChangeValueName(e)}
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={nombreref2}
                    id="nombreref2"
                    name="nombreref2"
                    type="text"
                    label="Nombre"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={montoref2}
                    id="montoref2"
                    name="montoref2"
                    type="text"
                    label="Monto"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={antiguedadref2}
                    id="antiguedadref2"
                    name="antiguedadref2"
                    type="text"
                    label="Antigüedad"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={plazoref2}
                    id="plazoref2"
                    name="plazoref2"
                    type="text"
                    label="Plazo"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    value={valueAnswer.telefonoref2}
                    onChange={(e) => handleChangeValue(e)}
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={telefonoref2}
                    id="telefonoref2"
                    name="telefonoref2"
                    type="text"
                    label="Teléfonos"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.subTitleFormSection}>
                    Referencia No. 3
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    value={valueAnswerName.nombreref3}
                    onChange={(e) => handleChangeValueName(e)}
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={nombreref3}
                    id="nombreref3"
                    name="nombreref3"
                    type="text"
                    label="Nombre"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={montoref3}
                    id="montoref3"
                    name="montoref3"
                    type="text"
                    label="Monto"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={antiguedadref3}
                    id="antiguedadref3"
                    name="antiguedadref3"
                    type="text"
                    label="Antigüedad"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={plazoref3}
                    id="plazoref3"
                    name="plazoref3"
                    type="text"
                    label="Plazo"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    value={valueAnswer.telefonoref3}
                    onChange={(e) => handleChangeValue(e)}
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={telefonoref3}
                    id="telefonoref3"
                    name="telefonoref3"
                    type="text"
                    label="Teléfonos"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div className={classes.rootGrid}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.titleFormSection}>
                Referencias bancarias (Cheques y tarjetas de crédito, copia de
                los 3 últimos estados de cuenta.)
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.subTitleFormSection}>
                    Referencia No. 1
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={bancoref1}
                    id="bancoref1"
                    name="bancoref1"
                    type="text"
                    label="Banco"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={numerocuentaref1}
                    id="numerocuentaref1"
                    name="numerocuentaref1"
                    type="text"
                    label="No. de cuenta"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={sucursalbancoref1}
                    id="sucursalbancoref1"
                    name="sucursalbancoref1"
                    type="text"
                    label="Sucursal"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={ejecutivoref1}
                    id="ejecutivoref1"
                    name="ejecutivoref1"
                    type="text"
                    label="Ejecutivo de Cuenta"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    value={valueAnswer.telefonobancoref1}
                    onChange={(e) => handleChangeValue(e)}
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={telefonobancoref1}
                    id="telefonobancoref1"
                    name="telefonobancoref1"
                    type="text"
                    label="Teléfonos"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.subTitleFormSection}>
                    Referencia No. 2
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={bancoref2}
                    id="bancoref2"
                    name="bancoref2"
                    type="text"
                    label="Banco"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={numerocuentaref2}
                    id="numerocuentaref2"
                    name="numerocuentaref2"
                    type="text"
                    label="No. de cuenta"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={sucursalbancoref2}
                    id="sucursalbancoref2"
                    name="sucursalbancoref2"
                    type="text"
                    label="Sucursal"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={ejecutivoref2}
                    id="ejecutivoref2"
                    name="ejecutivoref2"
                    type="text"
                    label="Ejecutivo de Cuenta"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    value={valueAnswer.telefonobancoref2}
                    onChange={(e) => handleChangeValue(e)}
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    //inputRef={telefonobancoref2}
                    id="telefonobancoref2"
                    name="telefonobancoref2"
                    type="text"
                    label="Teléfonos"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div className={classes.rootGrid}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.titleFormSection}>
                Favor de anotar el nombre de las personas autorizadas para
                recibir o recoger mercancía de DISTRIBUIDORA DE ENLACES
                ELECTRÓNICOS S.A. DE C.V., en caso de no registrar a nadie el
                enviado deberá traer una Carta de Autorización de la empresa.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={nombrerecoge1}
                id="nombrerecoge1"
                name="nombrerecoge1"
                type="text"
                label="Nombre"
                value={valueAnswerName.nombrerecoge1}
                onChange={(e) => handleChangeValueName(e)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={nombrerecoge2}
                id="nombrerecoge2"
                name="nombrerecoge2"
                type="text"
                label="Nombre"
                value={valueAnswerName.nombrerecoge2}
                onChange={(e) => handleChangeValueName(e)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                className={classes.inputs}
                size="small"
                //inputRef={nombrerecoge3}
                id="nombrerecoge3"
                name="nombrerecoge3"
                type="text"
                label="Nombre"
                value={valueAnswerName.nombrerecoge3}
                onChange={(e) => handleChangeValueName(e)}
              />
            </Grid>
          </Grid>
        </div>

        <div className={classes.rootGrid}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.titleFormSection}></Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  ¿Cuenta con vehículos de reparto y/o para personal?
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="gender"
                  name="gender1"
                  value={tieneVehiculosReparto}
                  onChange={handleChangeRadioTieneVehiculosReparto}
                >
                  <FormControlLabel
                    value="si"
                    control={<Radio color="default" />}
                    label="Si"
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio color="default" />}
                    label="No"
                  />
                  {tieneVehiculosReparto === "si" ? (
                    <TextField
                      required
                      variant="outlined"
                      className={classes.inputsWithoutWidth}
                      size="small"
                      //inputRef={qtyvehiculosreparto}
                      id="qtyvehiculosreparto"
                      name="qtyvehiculosreparto"
                      type="number"
                      label="Especifique"
                    />
                  ) : (
                    ""
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>

            {tieneVehiculosReparto === "si" ? (
              <Fragment>
                <Button
                  className={classes.buttonsWithoutWidth}
                  onClick={() => handleAddFieldsVehiculo()}
                  type="button"
                  variant="contained"
                  color="primary"
                >
                  Agregar Vehículo <AddIcon />
                </Button>

                {inputFieldsVehiculos.map((inputField, index) => (
                  <Fragment key={`${inputField}~${index}`}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={10}
                        justify="flex-start"
                        alignItems="flex-end"
                        className={classes.gridFlex}
                      >
                        <Typography className={classes.subTitleFormSection}>
                          Vehículo No. {index + 1}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        justify="flex-end"
                        className={classes.gridFlex}
                      >
                        <Button
                          onClick={() => handleRemoveFieldsVehiculo(index)}
                          type="button"
                          variant="contained"
                          color="primary"
                          className={classes.buttonsWithoutWidth}
                        >
                          <DeleteIcon />
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          className={classes.inputs}
                          size="small"
                          onChange={(event) =>
                            handleInputChangeVehiculo(index, event)
                          }
                          value={inputField.vsdc_marca}
                          id="vsdc_marca"
                          name="vsdc_marca"
                          type="text"
                          label="Marca"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          className={classes.inputs}
                          size="small"
                          onChange={(event) =>
                            handleInputChangeVehiculo(index, event)
                          }
                          value={inputField.vsdc_tipo}
                          id="vsdc_tipo"
                          name="vsdc_tipo"
                          type="text"
                          label="Tipo"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          className={classes.inputs}
                          size="small"
                          onChange={(event) =>
                            handleInputChangeVehiculo(index, event)
                          }
                          value={inputField.vsdc_model}
                          id="vsdc_model"
                          name="vsdc_model"
                          type="text"
                          label="Modelo"
                        />
                      </Grid>
                    </Grid>
                  </Fragment>
                ))}
              </Fragment>
            ) : (
              ""
            )}
          </Grid>
        </div>

        <div className={classes.buttonDiv}>
          <Button variant="contained" className={classes.buttons} type="submit">
            Solicitar
          </Button>
        </div>

        <br />
        <Collapse in={alert.open}>
          <Alert
            onClose={() => {
              setOpenAlert({
                message: "",
                type: "",
                open: false,
              });
            }}
            severity={alert.type}
          >
            {alert.message}
          </Alert>
        </Collapse>
        <br />

        {filesLoad ? (
          <div className={classes.buttonDiv}>
            <Button
              onClick={() => setOpenDrop(true)}
              className={classes.buttons}
            >
              Anexos
            </Button>
            <DropzoneDialog
              open={openDrop}
              onSave={(files) => handleSaveFiles(files)}
              dropzoneText={"Arrastre y suelte un archivo ZIP con los Anexos"}
              acceptedFiles={[".zip"]}
              filesLimit={1}
              showPreviews={true}
              showFileNames={true}
              maxFileSize={5000000}
              onClose={() => setOpenDrop(false)}
            />
          </div>
        ) : (
          ""
        )}
      </form>
      </Container>
      {isMobile ? (
        <Fragment>
         
        </Fragment>
      ) : (
        <Fragment>
        
        </Fragment>
      )}

    
    </div>
  );
}
