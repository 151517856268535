import { checkoutService } from "_services/checkout.service";

export const getTotalCartProducts = async () => {
  let countProducts = 0;
    const user = JSON.parse(localStorage.getItem("user"));
    if (user !== null) {
        const cart = await checkoutService.createOrGetCartByClientId(user.usuario.snu_sn_socio_de_negocio_id);
        countProducts = cart.data.cdc_carrito_de_compra_id.productos.length;
    } else {
        const cartLocal = JSON.parse(localStorage.getItem("myCart"));
        countProducts = cartLocal ? cartLocal.length : 0;
       
    }
    return countProducts;
}
