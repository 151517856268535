import { authHeader } from "../_helpers";
import { API_URL } from "./api.url.back";
import { userService } from "./user.service";

export const quotesService = {
  getAllClients,
  getAllClientsAssigned,
  getClientById,
  getProductsListChilds,
  getStockList,
  getCart,
  createCart,
  updateCart,
  deleteItemCart,
  getProjects,
  createProject,
  getProjectDetails,
  getProductsFilter,
  addProductQuote,
  getProductDetails,
  sendQuote,
  updatQuote,
  quoteToCart,
  getExpirationTime,
  getProductPrice,
  getMaxDiscount,
  getCmmPorcentajeProyecto,
  getAllQuotes,
  getQuoteById,
  cancelQuote,
  createQuote,
  createUserProspect,
  createQuoteSN
};

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    const responseData = {
      error: false,
      data: data,
    };
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        window.location.reload(true);
      }

      //responseData.error = true;
      //return responseData;
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function handleResponseAlternative(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    const responseData = {
      error: false,
      data: data,
    };
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        window.location.reload(true);
      }

      responseData.error = true;
      return responseData;
    }
    return responseData;
  });
}

function createQuote(products, prospectId, comentarios, copyEmail) {
  const data = { 
    up_usuarios_prospectos_id: prospectId,
    cdc_sn_socio_de_negocio_id: null,
    cot_prospecto: true,
    snd_direcciones_id: null,
    upd_direcciones_id: 3,
    recoleccion_almacen_id: null,
    tipo_envio: 16,
    cot_referencia: comentarios,
    cot_usu_usuario_vendedor_id: 1,
    cotp_usu_descuento_cotizacion: 0,
    cot_cot_tratamiento: "mr",
    cot_mantener_copia: copyEmail,
    cot_productos: products,
  };

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${API_URL}/cotizaciones/v3_crear_cotizacion`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function createQuoteSN(shippingType, socioDeNegocioId, comentarios, snDrireccionId, snAlmacenId,copyEmail) {
  const data = {
    up_usuarios_prospectos_id: null,
    cdc_sn_socio_de_negocio_id: socioDeNegocioId,
    cot_prospecto: false,
    snd_direcciones_id: snDrireccionId,
    upd_direcciones_id: null,
    recoleccion_almacen_id: snAlmacenId,
    tipo_envio: shippingType,
    cot_referencia: comentarios,
    cot_usu_usuario_vendedor_id: 1,
    cotp_usu_descuento_cotizacion: 0,
    cot_cot_tratamiento: "mr",
    cot_mantener_copia: copyEmail,
    cot_productos: null,
    cotp_porcentaje_descuento_vendedor: 0
  };

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${API_URL}/cotizaciones/v3_crear_cotizacion`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function createUserProspect(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${API_URL}/usuarios_prospectos/add`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function getAllClients() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/socio_negocio/list/`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getAllClientsAssigned(iduser) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/user/socios_de_negocio/${iduser}`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getClientById(cli_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/socio_negocio/list/${cli_id}`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getProductsListChilds() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/productos/listaToProducto/`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getStockList() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/productos_stock/getStock/`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getCart(cartId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/carrito_de_compras/carrito/${cartId}`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

/*Crear carrito*/
function createCart(
  socios_negocio_id,
  producto_id,
  usuario_vendedor_id,
  cantidad
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_sn_socio_de_negocio_id: socios_negocio_id,
      pcdc_prod_producto_id: producto_id,
      cdc_usu_usuario_vendedor_id: usuario_vendedor_id,
      pcdc_producto_cantidad: cantidad,
    }),
  };

  return fetch(`${API_URL}/carrito_de_compras/create`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

/*Actualizar carrito*/
function updateCart(id_cart, producto_id, cantidad) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      pcdc_carrito_de_compra_id: id_cart,
      pcdc_prod_producto_id: producto_id,
      pcdc_producto_cantidad: cantidad,
    }),
  };

  return fetch(`${API_URL}/carrito_de_compras/add_product`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

/*Eliminar artículo de carrito*/
function deleteItemCart(id_cart, producto_id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    body: JSON.stringify({
      pcdc_carrito_de_compra_id: id_cart,
      pcdc_prod_producto_id: producto_id,
    }),
  };

  return fetch(`${API_URL}/carrito_de_compras/delete_product`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

/*Obtener proyectos y cotizaciones*/
function getProjects(clientId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/cotizaciones_proyectos/cotizaciones_proyectos_by_socio_by_admin/${clientId}`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

/*Crear proyecto / cotización*/
function createProject(
  cart_id,
  project_name,
  tipo,
  status,
  vendedor_id,
  fecha_vencimiento
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cdc_carrito_de_compra_id: cart_id,
      cot_proyecto_nombre: project_name,
      cot_cmm_tipo_id: tipo,
      cot_cmm_estatus_id: status,
      cot_usu_usuario_vendedor_id: vendedor_id,
      cot_fecha_vencimiento: fecha_vencimiento,
    }),
  };

  return fetch(`${API_URL}/carrito_de_compras/crearCotizacion`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

/*Obtener detalles de proyectos y cotizaciones*/
function getProjectDetails(orderId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/cotizaciones_proyectos/cotizacion_by_id/${orderId}`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

/*Obtener productos por filtros*/
function getProductsFilter(tipo, search, idAlmacenVirtual) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/productos_stock/getStockFiltro/${tipo}/${search}/${idAlmacenVirtual}`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

/*Agregar producto a cotización*/
function addProductQuote(
  cot_numero_orden,
  cot_usu_usuario_creador_id,
  cot_cmm_estatus_id,
  cot_sn_socios_negocio_id,
  cot_usu_usuario_vendedor_id,
  cot_cotizacion_id
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cot_numero_orden: cot_numero_orden,
      cot_usu_usuario_creador_id: cot_usu_usuario_creador_id,
      cot_cmm_estatus_id: cot_cmm_estatus_id,
      cot_sn_socios_negocio_id: cot_sn_socios_negocio_id,
      cot_usu_usuario_vendedor_id: cot_usu_usuario_vendedor_id,
      cot_cotizacion_id: cot_cotizacion_id,
    }),
  };

  return fetch(`${API_URL}/cotizaciones_proyectos/create`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

/*Actualizar cotización*/
function updatQuote(body) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(`${API_URL}/cotizaciones_proyectos/update`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function getProductDetails(prod_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/productos/onlyid/${prod_id}`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function sendQuote(cot_cotizacion_id, snu_usuario_snu_id) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      snu_usuario_snu_id: snu_usuario_snu_id,
      cot_cotizacion_id: cot_cotizacion_id,
    }),
  };

  return fetch(
    `${API_URL}/cotizaciones_proyectos/send_cotizacion`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function quoteToCart(cot_cotizacion_id, carcop_usu_usuario_vendedor_id) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cot_cotizacion_id: cot_cotizacion_id,
      carcop_usu_usuario_vendedor_id: carcop_usu_usuario_vendedor_id,
    }),
  };

  return fetch(
    `${API_URL}/carrito_de_compras/converter_cotizacion`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function getExpirationTime() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/controles_maestros/control/TIEMPO_CADUCIDAD_COTIZACION_PROYECTO`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getProductPrice(arrP, sn_id) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      productos_id: arrP,
    }),
  };

  return fetch(
    `${API_URL}/productos/mejor_descuento/socio/${sn_id}`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function getMaxDiscount() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/controles_maestros/control/DESCUENTO_MAXIMO_COTIZACION_PROYECTO`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

/// CMM
function getCmmPorcentajeProyecto() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/controles_maestros/control/PROYECTOS_PORCENTAJE`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

/* new quotes api by henry */

function getAllQuotes() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/cotizaciones/list/`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getQuoteById(id_quote) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/cotizaciones/cotizacion_detalle/${id_quote}`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function cancelQuote(id_quote, update_by) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    body: JSON.stringify({
      cot_cotizacion_id: id_quote,
      cot_usu_usuario_modificador_id: update_by,
    }),
  };

  return fetch(`${API_URL}/cotizaciones/cancelar_Cotizacion/`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}
