import React, { useState, useEffect, useContext, Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components

import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarHalfIcon from "@material-ui/icons/StarHalf";

const useStyles = makeStyles((styles) => ({
  iconColor: {
    color: "#ffe720",
    fontSize: 24,
  },
  space: {
    textAlign: "left",
  },
  fullDiv: {
    textAlign: "left",
    width: "96%",
    borderBottom: "2px solid #eaeaea",
  },
}));

export default function SectionProductRating(props) {
  const classes = useStyles();
  const [rateStars, setRateStars] = useState(null);
  useEffect(() => {
    setStars();
  }, []);
  const setStars = () => {
    let stars = [];
    for (let i = 1; i <= Math.floor(props.productDetails.rating); i++) {
      stars.push(
        <StarIcon
          key={"star" + i + "" + props.productDetails.id}
          className={classes.iconColor}
        />
      );
    }
    if (props.productDetails.rating > Math.floor(props.productDetails.rating)) {
      stars.push(
        <StarHalfIcon
          key={"halfStar" + props.productDetails.id}
          className={classes.iconColor}
        />
      );
    }
    for (let i = Math.ceil(props.productDetails.rating); i < 5; i++) {
      stars.push(
        <StarBorderIcon
          key={"star" + i + "" + props.productDetails.id}
          className={classes.iconColor}
        />
      );
    }
    // dev
    // for (let i = 0; i < 3; i++) {
    //   stars.push(<StarBorderIcon key={"star"} className={classes.iconColor} />);
    // }
    setRateStars(stars);
  };
  return (
    <div className={props.gridView === false ? classes.space : classes.fullDiv}>
      {rateStars}
    </div>
  );
}
