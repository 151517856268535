import * as React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Grid } from "@material-ui/core";
import { Box } from "@mui/material";
import { useAuth } from "hooks/useAuth";
import { accountService } from "services/account/account.service";
import Loading from "utils/loading/Loading";
import { useState } from "react";
export const Active = () => {
    const {getAccountIsActive, isLogged, getUserData, setOpenAlert}=useAuth()
    const isActive = isLogged ? getAccountIsActive() : false
    const [loading, setLoading]= useState(false)
    const dataUser = getUserData()

    const ActiveUser = (e)=>{
      
      ActiveUser2()
    }
    const ActiveUser2 = async()=>{
      setLoading(true)
      if(isLogged){
          
          let result = await accountService.ActivateB2CUser(dataUser.usuario.token)
        
          while(result.status == 'fail'){
            result = await accountService.ActivateB2CUser(dataUser.usuario.token)
          }
          setLoading(false)
          dataUser.usuario.snu_sn_socio_de_negocio_id = result.user.snu_sn_socio_de_negocio_id
          dataUser.usuario.snu_cardcode = result.user.snu_cardcode          
          window.localStorage.setItem('user', JSON.stringify(dataUser))
          
          
         // window.location.reload();
          setOpenAlert({open:true, message:'¡Felicidades! Tu cuenta se ha activado. Ahora puedes disfrutar de la experiencia Dielsa.', type:'success'})
      }
    }
   
  return (
    isLogged && (
        <>
        <Loading loading={loading}/>
        { isActive ? "":
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Stack spacing={2} sx={{ width: '50%' }}>
      <Grid container spacing={2}>
      <Alert severity="warning" sx={{ width: '100%' }}>
        <Grid item xs={12}>
            <AlertTitle>
              {"Activa tu cuenta para disfrutar la experiencia completa en Dielsa. ¡No te lo pierdas!  "} 
          <Button variant="outlined" color="primary" onClick={(e)=> ActiveUser(e)}>
           Activar 
          </Button>
            </AlertTitle>
          
        </Grid>
        </Alert>
      </Grid>
    </Stack>
  </Box>}
        </>
    ))
};
