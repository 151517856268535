import React, { useState, useEffect, useContext, Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((styles) => ({
  title: {
    color: "#3C4858",
    margin: "1.75rem 0 0.875rem",
    marginTop: 0,
    minHeight: 32,
    fontFamily: "Roboto Slab, Times New Roman, serif",
    textDecoration: "none",
  },
  container: {
    width: "100%",
    paddingLeft: 0,
  },
  bulletP: {
    fontSize: "12px",
    margin: 0,
    lineHeight: "12px",
  },
}));

export default function SectionProductBullets(props) {
  const classes = useStyles();
  const [bullets, setBullets] = useState(null);
  useEffect(() => {
    getBullets();
  }, []);
  const getBullets = () => {
    if (
      props.productDetails.bullets !== undefined &&
      props.productDetails.bullets !== null
    ) {
      let _b = JSON.parse(props.productDetails.bullets);
      const b = props.productDetails.bullets.split("-");
      let a = [];
      for (let i = 0; i < _b.length; i++) {
        let _index = "Viñeta_" + (i + 1);
        a.push(<p className={classes.bulletP}>* {_b[i][_index]}</p>);
      }
      setBullets(a);
    }
  };
  return (
    <div>
      <div className={classes.container}>{bullets}</div>
    </div>
  );
}
