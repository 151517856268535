export const  valideProductsBuy = (constCotizacionesProductos) => {
    return new Promise(resolve => {
       

        let hiddenButtonCar = false;
        let productsBuy = [];
        if(constCotizacionesProductos) {
            constCotizacionesProductos.map((prod) => {
            if(prod.cotp_precio_menos_promociones > 0
              && prod.prod_peso > 0 
              && prod.prod_volumen > 0
              && (prod.cotp_back_order || prod.prod_total_stock > 0) 
            ) {
           //   hiddenButtonCar = true;
              productsBuy.push({purchase: true})
            }else{
              productsBuy.push({purchase: false})
            }
    
           
           // window.alert(prod)
          })
           
        }
        //return {showBuys: hiddenButtonCar, duplicados};
          resolve({productsBuy})
  
        
      })
  }
  