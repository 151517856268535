import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';

const ModalWarning = ({ showModal, handleClose, textWarning, textAction, handleFunction }) => {
  return (
    <Dialog
        open={showModal}
        onClose={handleClose}
    >
        <DialogTitle>
            <WarningIcon sx={{ color: '#eea90b' }} />
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {textWarning}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleFunction} autoFocus>
            {textAction}
          </Button>
        </DialogActions>
    </Dialog>
  )
}

export default ModalWarning