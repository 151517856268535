export const API_URL = process.env.REACT_APP_API_URL;

export  const  signInService = (username, password ) => {
  let cartid = "";
  if (
    localStorage.getItem("shoppingcart") &&
    localStorage.getItem("shoppingcart") !== undefined
  ) {
    cartid = JSON.parse(localStorage.getItem("shoppingcart")).carrito_de_compra
      .cdc_carrito_de_compra_id;
  }

  let requestOptions={
    method: 'POST',
    headers: { "Content-Type": "application/json" },

    body: JSON.stringify({ snu_correo_electronico: username,
      snu_contrasenia: password,
      cart_id: cartid})
  }
  return new Promise(resolve => {
   fetch(`${API_URL}/socio_negocio_usuarios/login`,requestOptions )
      .then((response)=> response.json())
      .then((response) => {
        if(response.usuario){
         window.localStorage.setItem('user', JSON.stringify(response))
        let token=response.usuario.token;

         window.localStorage.setItem('token', token)

          resolve({
            isSuccess: true,
            message: 'Inicio correctamente sesión.',
            token,
            data:response})
          
          }else{
            resolve({
              isSuccess: false,
              message: 'Hubo un detalle al iniciar sesión.',
              token:null,
              data:null})
          }
           
      }
      
      )
      .catch((err) => {
        const message = 'No se pudo establecer una conexión'
        resolve({
          isSuccess: false,
          message,
          token: null,
          data: null
        })
      })
   
  })
}
