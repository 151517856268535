import React, { createContext, useEffect, useState, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import Container from '@material-ui/core/Container';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import useCheckMobileScreen from "components/Mobile/Mobile.js";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
// @material-ui/icons
// core components


import "assets/custom/ProductInput.css";

import SectionSpaceTop from "views/Home/Sections/SectionSpaceTop.js";
import SectionBreadcrumb from "views/Home/Sections/SectionBreadcrumb.js";
import { ButtonCategoriesMenu } from "components";

const useStyles = makeStyles((styles) => ({
  container: {
    width: "100%",
    backgroundColor: "#ffffff",
  },
  titleDiv: {
    width: "100%",
    textAlign: "center",
    marginTop: "12px",
  },
  title: {
    color: "#0B3196",
    fontFamily: "'Centrale Sans Bold'",
    fontSize: "26px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "32px",
    textAlign: "center",
  },
  subtitleDiv: {
    width: "100%",
    textAlign: "center",
    marginTop: "57px",
    marginBottom: "50px",
  },
  subTitle: {
    color: "#0B3196",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "20px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "22px",
    textAlign: "center",
  },
  formDiv: {
    width: "100%",
    paddingLeft: "20%",
    paddingRight: "20%",
    [styles.breakpoints.down("sm")]: {
      paddingLeft: "12px",
      paddingRight: "13px",
    },
  },
  formRow: {
    width: "100%",
    marginBottom: "17px",
    textAlign: "center",
  },
  formTitleDiv: {
    width: "22%",
    display: "inline-block",
    verticalAlign: "middle",
    [styles.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  formTitle: {
    color: "#444444",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "19px",
    textAlign: "left",
  },
  formInputDiv: {
    width: "78%",
    display: "inline-block",
    verticalAlign: "middle",
    [styles.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  formInput: {
    backgroundColor: "#F5F5F5",
  },
  formRowBtn: {
    width: "100%",
    marginTop: "61px",
    marginBottom: "94px",
    textAlign: "center",
  },
  btnSearch: {
    borderRadius: "3px",
    backgroundColor: "#ffffff",
    border: "2px solid #0b3196",
    color: "#0b3196",
    textAlign: "center",
    paddingLeft: "70px",
    paddingRight: "70px",
    paddingTop: "8px",
    paddingBottom: "8px",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "19px",
    "&:hover": {
      backgroundColor: "#0b3196",
      color: "#ffffff",
    },
  },
}));

export default function AdvancedSearchForm(props) {
  const classes = useStyles();
  const history = useHistory();

  const { ...rest } = props;
  let isMobile = useCheckMobileScreen();
  const [dataBanner, setDataBanner] = useState("");
  const [nombre, setNombre] = useState("");
  const [nombreExtranjero, setNombreExtranjero] = useState("");
  const [sku, setSku] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [marca, setMarca] = useState("");
  const [categoria, setCategoria] = useState("");
  const [dataBreadCrumb, setDataBreadCrumb] = useState([]);

  useEffect(() => {
   
      window.scrollTo(0,0);
   
    setDataBreadCrumb(() => [
      {
        name: "INICIO",
        link: "/",
      },
      {
        name: "BÚSQUEDA AVANZADA",
        link: "",
      }
    ]);
  }, []);

  const busquedaAvanzada = () => {
    let _true = false;
    _true = nombre !== "" ? true : _true;
    _true = nombreExtranjero !== "" ? true : _true;
    _true = sku !== "" ? true : _true;
    _true = descripcion !== "" ? true : _true;
    _true = marca !== "" ? true : _true;
    _true = categoria !== "" ? true : _true;
    if (_true) {
      history.push(
        `/filters?name=${nombre.toUpperCase() ?  nombre.toUpperCase() : nombreExtranjero.toUpperCase()}&sku=${sku.toUpperCase()}&description=${btoa(descripcion)}&brand=${marca.toUpperCase()}&category=${categoria.toUpperCase()}&type=ads`
      );
    }
  };

  return (
    <div className={classes.mainBackground}>
       <Container fixed style={{paddingTop: isMobile ? 2 : 0 }} >
      <SectionSpaceTop />
    

      <div className={classes.container}>
        <div className={classes.titleDiv}>
          <div style={{
              paddingTop: isMobile ? 15 : 5,
              paddingBottom: isMobile ? 15 : 5,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {!isMobile &&
            <ButtonCategoriesMenu />}
            <SectionBreadcrumb route={dataBreadCrumb} />
          </div>
          <Typography className={classes.title}>Búsqueda avanzada</Typography>
        </div>
        <div className={classes.subtitleDiv}>
          <Typography className={classes.subTitle}>
            Llena los campos para realizar una búsqueda avanzada
          </Typography>
        </div>
        <div className={classes.formDiv}>
          <div className={classes.formRow}>
            <div className={classes.formTitleDiv}>
              <Typography className={classes.formTitle}>
                Nombre del producto
              </Typography>
            </div>
            <div className={classes.formInputDiv}>
              <TextField
                label=""
                variant="outlined"
                size="small"
                className={classes.formInput}
                fullWidth
                value={nombre}
                onChange={(ev) => {
                  setNombre(ev.target.value);
                }}
              />
            </div>
          </div>
          <div className={classes.formRow}>
            <div className={classes.formTitleDiv}>
              <Typography className={classes.formTitle}>
                SKU
              </Typography>
            </div>
            <div className={classes.formInputDiv}>
              <TextField
                label=""
                variant="outlined"
                size="small"
                className={classes.formInput}
                fullWidth
                value={nombreExtranjero}
                onChange={(ev) => {
                  setNombreExtranjero(ev.target.value);
                }}
              />
            </div>
          </div>
          {/* <div className={classes.formRow}>
            <div className={classes.formTitleDiv}>
              <Typography className={classes.formTitle}>SKU</Typography>
            </div>
            <div className={classes.formInputDiv}>
              <TextField
                label=""
                variant="outlined"
                size="small"
                className={classes.formInput}
                fullWidth
                value={sku}
                onChange={(ev) => {
                  setSku(ev.target.value);
                }}
              />
            </div>
          </div> */}
          <div className={classes.formRow}>
            <div className={classes.formTitleDiv}>
              <Typography className={classes.formTitle}>Descripción</Typography>
            </div>
            <div className={classes.formInputDiv}>
              <TextField
                label=""
                variant="outlined"
                size="small"
                className={classes.formInput}
                fullWidth
                value={descripcion}
                onChange={(ev) => {
                  setDescripcion(ev.target.value);
                }}
              />
            </div>
          </div>
          <div className={classes.formRow}>
            <div className={classes.formTitleDiv}>
              <Typography className={classes.formTitle}>Marca</Typography>
            </div>
            <div className={classes.formInputDiv}>
              <TextField
                label=""
                variant="outlined"
                size="small"
                className={classes.formInput}
                fullWidth
                value={marca}
                onChange={(ev) => {
                  setMarca(ev.target.value);
                }}
              />
            </div>
          </div>
          <div className={classes.formRow}>
            <div className={classes.formTitleDiv}>
              <Typography className={classes.formTitle}>Categoría</Typography>
            </div>
            <div className={classes.formInputDiv}>
              <TextField
                label=""
                variant="outlined"
                size="small"
                className={classes.formInput}
                fullWidth
                value={categoria}
                onChange={(ev) => {
                  setCategoria(ev.target.value);
                }}
              />
            </div>
          </div>
          <div className={classes.formRowBtn}>
            <Button
              className={classes.btnSearch}
              onClick={() => {
                busquedaAvanzada();
              }}
            >
              Buscar
            </Button>
          </div>
        </div>
      </div>
</Container>
    </div>
  );
}
