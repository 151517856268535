import React, { useState, useEffect, Fragment } from "react";
import { homeServices } from "../../_services/home.services";
import SectionSpaceTop from "views/Home/Sections/SectionSpaceTop.js";
import HeaderBanner from "views/Header/HeaderBanner.js";
import useCheckMobileScreen from "components/Mobile/Mobile.js";
import Grid from "@material-ui/core/Grid";
import "./Quotes.css";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';

//import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { quotesService } from "_services/quotes.service";
import { generalService } from "_services/general.service";
import { orderService } from "_services/order.service";
import RemoveItemIcon from "assets/icons/icon_remove_cart_item.svg";
import clsx from "clsx";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ListItem from "@material-ui/core/ListItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import HouseIcon from "@material-ui/icons/House";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import MenuItem from '@material-ui/core/MenuItem';
import Select from "@material-ui/core/Select";
import { userService } from "_services/user.service";
import { checkoutService } from "_services/checkout.service";
import { useAuth } from "hooks/useAuth";
import { LoadingProgress } from "utils/loadingProgress";
const style = {
  position: "absolute",
  width: "50%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: 20,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 0,
  textAlign: "center",
  color: "#000",
  fontWeight: 500,
};

const useStyles = makeStyles((styles) => ({
  grayDiv: {
    marginBottom: 0,
    backgroundColor: "#EAEAEA"
  },

  paperTitle: {
    borderRadius: 2,
    backgroundColor: "#EAEAEA",
    padding: 10,
    "& p": {
      color: "#000000",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: 12,
      fontWeight: 500,
      textAlign: "center",
    },
  },

  smargin: {
    marginTop: 7,
    fontSize: 12,
    color: "#606973",
    fontFamily: "Centrale Sans Medium",
  },

  paper: {
    boxShadow: "none",
    borderRadius: 0,
    padding: "15px",
    fontSize: "15px",
    "& h6": {
      fontFamily: "'Centrale Sans Medium'",
      color: "#000000",
      fontSize: "17px",
    },
    textAlign: "center",
  },

  radioTipoEntrega: {
    flexDirection: "row",
    flexWrap: "nowrap",
    "& label.MuiFormControlLabel-root .MuiTypography-body1": {
      color: "#666060",
    },
    "& label.MuiFormControlLabel-root .MuiRadio-colorSecondary.Mui-checked + span.MuiTypography-body1": {
      color: "#0B3196",
      fontWeight: 700,
    },
  },

  hide: {
    display: "none !important",
  },
  container: {
    width: "100%",
    backgroundColor: "#ffffff",
  },
  titleDiv: {
    width: "100%",
    textAlign: "center",
    marginTop: "37px",
  },
  title: {
    color: "#000",
    fontFamily: "'Centrale Sans Bold'",
    fontSize: "26px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "32px",
    textAlign: "center",
  },
  formDiv: {
    width: "calc(100% - 60px)",
    backgroundColor: "#F5F5F5",
    border: "1px solid #EAEAEA",
    marginLeft: "30px",
    marginRight: "30px",
    marginTop: "56px",
    marginBottom: "40px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "9px",
    display: "flex",
  },
  inputSearch: {
    width: "454px",
    display: "inline-block",
    verticalAlign: "top",
  },
  btnSearch: {
    backgroundColor: "#0b3196",
    color: "#ffffff",
    marginLeft: "27px",
    display: "inline-block",
    verticalAlign: "top",
  },
  btnUpload: {
    backgroundColor: "#000",
    color: "#ffffff",
    marginLeft: "54px",
    display: "inline-block",
    verticalAlign: "top",
    height: 36.5,
  },
  btnDownload: {
    backgroundColor: "#F5F5F5",
    color: "#000",
    marginLeft: "47px",
    display: "inline-block",
    verticalAlign: "top",
    height: 36.5,
  },
  containerProducts: {
    marginBottom: "20px",
  },
  productGrid: {
    display: "flex",
    borderBottom: "2px solid #D1D1D1",
    paddingTop: 10,
    paddingBottom: 10,
  },
  gridHeader: {
    display: "flex",
    backgroundColor: "#0b3196",
    borderRadius: 2,
    height: 39,
  },
  textHeader: {
    color: "#ffffff",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "18px",
    fontWeight: "bold",
  },
  productItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },
  textItem: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "13px",
  },
  productImg: {
    maxWidth: "79px",
    maxHeight: "79px",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  cantidadInput: {
    height: "35px",
    width: "54px",
    border: "1px solid #c4c4c4",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "18px",
    textAlign: "center",
  },
  cantidadBtnRestar: {
    all: "unset",
    height: "33px",
    width: "41px",
    transform: "scaleX(-1)",
    borderRadius: "0 3px 3px 0",
    backgroundColor: "#FFF",
    cursor: "pointer",
    color: "#0b3196",
    fontFamily: "'Gotham Light', sans-serif",
    fontSize: 18,
    fontWeight: "bold",
    border: "1px solid #c4c4c4",
    "&:hover": {
      backgroundColor: "#0b3196",
      color: "#ffffff",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cantidadBtnSumar: {
    all: "unset",
    height: "34px",
    width: "41px",
    transform: "scaleX(-1)",
    borderRadius: "3px 0px 0px 3px",
    // backgroundColor: "#FFF",
    cursor: "pointer",
    // color: "#0b3196",
    fontFamily: "'Gotham Light', sans-serif",
    fontSize: 18,
    fontWeight: "bold",
    border: "1px solid #c4c4c4",
    backgroundColor: "#0b3196",
    color: "#ffffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addToCartBtn: {
    all: "unset",
    height: "35px",
    width: "155px",
    border: "2px solid #0b3196",
    borderRadius: "3px",
    cursor: "pointer",
    color: "#0b3196",
    fontFamily: "'Gotham Light', sans-serif",
    fontSize: "18px",
    fontWeight: "bold",
  },
  iconTrash: {
    color: "#949494",
  },
  btn: {
    all: "unset",
    backgroundColor: "#0B3196",
    borderRadius: "3px",
    height: "35px",
    color: "#FFFFFF",
    fontFamily: "'Centrale Sans Medium'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    textAlign: "center",
    width: "65%",
    cursor: "pointer",
    marginTop: 54,
  },
  boxPaginadoBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "25px",
    paddingBottom: "10px",
    "& button": {
      all: "unset",
      margin: "0",
      borderRadius: "7px",
      cursor: "pointer",
      height: "45px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0",
      fontSize: "16px",
      width: "261px",
      border: "1px solid #003773",
      color: "#003773",
      fontFamily: "'Centrale Sans Medium'",
      "&:hover": {
        backgroundColor: "#003773",
        color: "#ffffff",
      },
    },
  },
  selects: {
    background: "#ffffff",
    color: "#0B3196",
    fontWeight: 700,
    border: "2px solid #0B3196",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },
}));

const Request = (props) => {
  const classes = useStyles();
  const history = useHistory();
  let isMobile = useCheckMobileScreen();
  const [dataBanner, setDataBanner] = useState("");
  const [products, setProducts] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [paises, setPaises] = useState(null);
  const [estados, setEstados] = useState(null);
  const [ciudades, setCiudades] = useState(null);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const {setOpenAlert, setCarritoItems,carritoItems, isLogged}= useAuth();
  //Quote user Logged
  const [validaEnviar, setValidarEnviar] = useState(false); 
  const [validarRecoleccion, setValidarRecoleccion] = useState(false);
  const [userAddresses, setUserAddresses] = useState([]); 
  const userLogged = JSON.parse(localStorage.getItem("user"));
  const clientLogged = JSON.parse(localStorage.getItem("client"));
  const [quoteAddress, setQuoteAddress] = useState('');
  const [dirSelected, setDirSelected] = useState({});
  const [pickupList, setPickupList] = useState([]);
  const [pickupWarehouse, setPickupWarehouse] = useState('');
  const [pickupSelected, setPickupSelected] = useState({});
  const [validePais, setValidePais] = useState({isPais: true, isEstado: true, ciudad:true})
  const [isForm, setIsForm]= useState(false)
  const [isLoading, setIsLoading]= useState(false)
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    value,
    watch,
    formState: { errors },
  } = useForm();

  const getDataBanner = async () => {
    let _d = await homeServices.getFranjaInformativa();
    setDataBanner(_d.controles_maestros[0].cmm_valor);
  };

  useEffect(() => {
    getDataBanner();
   
      window.scrollTo(0,0);
   
    // const skusData = [
    //   {
    //     nombre: "Porshe",
    //     nombre_extranjero: "porshe",
    //     stock: 3,
    //     imagen:
    //       "https://files.porsche.com/filestore/image/multimedia/none/982-718spyder-modelimage-sideshot/model/d9552a39-8616-11e9-80c4-005056bbdc38/porsche-model.png",
    //     sku: "1",
    //     skuId: "2",
    //     cantidad: 3,
    //   },
    //   {
    //     nombre: "Porshe",
    //     nombre_extranjero: "porshe",
    //     stock: 3,
    //     imagen:
    //       "https://files.porsche.com/filestore/image/multimedia/none/982-718spyder-modelimage-sideshot/model/d9552a39-8616-11e9-80c4-005056bbdc38/porsche-model.png",
    //     sku: "2",
    //     skuId: "2",
    //     cantidad: 5,
    //   },
    //   {
    //     nombre: "Porshe",
    //     nombre_extranjero: "porshe",
    //     stock: 3,
    //     imagen:
    //       "https://files.porsche.com/filestore/image/multimedia/none/982-718spyder-modelimage-sideshot/model/d9552a39-8616-11e9-80c4-005056bbdc38/porsche-model.png",
    //     sku: "3",
    //     skuId: "2",
    //     cantidad: 2,
    //   },
    //   {
    //     nombre: "Porshe",
    //     nombre_extranjero: "porshe",
    //     stock: 3,
    //     imagen:
    //       "https://files.porsche.com/filestore/image/multimedia/none/982-718spyder-modelimage-sideshot/model/d9552a39-8616-11e9-80c4-005056bbdc38/porsche-model.png",
    //     sku: "4",
    //     skuId: "2",
    //     cantidad: 1,
    //   },
    // ];

    // localStorage.setItem("myCart", JSON.stringify(skusData));
    getCart();
    getPaises();
   
  }, []);

  const getUserCartObj = async () =>{
    const response = await checkoutService.getCartByClientIdCheckout(userLogged.usuario.snu_sn_socio_de_negocio_id);
   
    setProducts(response.data.cdc_carrito_de_compra_id.productos);
    if(response.data.cdc_carrito_de_compra_id.productos.length === 0){
      
      setOpenAlert({
        message: "Agrega productos para continuar.",
        type: "error",
        open: true,
      });
    }
  }

  const deleteItem = async (sku) => {
    const response = await checkoutService.deleteItemCart(userLogged.usuario.snu_sn_socio_de_negocio_id, sku);
    setCarritoItems(carritoItems-1);
    getUserCartObj();
  }

  const changeQty = async (productId, qty) => {
    const response = await checkoutService.addItemCart(userLogged.usuario.snu_sn_socio_de_negocio_id, productId, qty);
    getUserCartObj();
  }

  const getCart = () => {
      if(userLogged){
        getUserCartObj();

      }else{
        const localCart = JSON.parse(localStorage.getItem("myCart"));
      
        const cartValues = localCart ? localCart : [];
        setProducts(cartValues);
        if (cartValues.length === 0) {
         
          setOpenAlert({
            message: "Agrega productos para continuar.",
            type: "error",
            open: true,
          });
        }
      }
  };

  const removeFromCart = async (sku) => {
      if(userLogged){
        deleteItem(sku);
      }else{
        const localCart = JSON.parse(localStorage.getItem("myCart"));
        let currentCart = localCart.filter((item) => item.sku != sku);
        setMyCartNewValues(currentCart);
        setCarritoItems(carritoItems-1);
      }
  };

  const handleChangeQuantity = async (sku, cantidad) => {
      if (cantidad > 0) {
        if(userLogged){
          changeQty(sku, cantidad);
        }else{
          const currentCart = JSON.parse(localStorage.getItem("myCart"));
          const getItem = currentCart.find((item) => item.sku === sku);
          const itemIndex = currentCart.indexOf(getItem);
          currentCart[itemIndex].cantidad = cantidad;
          setMyCartNewValues(currentCart);
        }
        
      }else {
       
        setOpenAlert({
          message: "Cantidad debe ser mayor a 0.",
          type: "error",
          open: true,
        });
      }
  };

  const increaseQuantity = async (sku) => {
    if(userLogged){
      const currentCart = JSON.parse(localStorage.getItem("cart"));
      const getItem = currentCart.cdc_carrito_de_compra_id.productos.find((item) => item.pcdc_prod_producto_id === sku);
      let currentQuantity = parseInt(getItem.pcdc_producto_cantidad);
      currentQuantity++;
      const update = currentQuantity;
      changeQty(sku, update);
    }else{
      const currentCart = JSON.parse(localStorage.getItem("myCart"));
      const getItem = currentCart.find((item) => item.sku === sku);
      const itemIndex = currentCart.indexOf(getItem);
      let currentQuantity = parseInt(currentCart[itemIndex].cantidad);
      currentQuantity++;
      currentCart[itemIndex].cantidad = currentQuantity;
      setMyCartNewValues(currentCart);
    }
      
  };

  const decreaseQuantity = async (sku) => {
    if(userLogged){
      const currentCart = JSON.parse(localStorage.getItem("cart"));
      const getItem = currentCart.cdc_carrito_de_compra_id.productos.find((item) => item.pcdc_prod_producto_id === sku);
      let currentQuantity = parseInt(getItem.pcdc_producto_cantidad);
      if(currentQuantity > 1){
        currentQuantity--;
        const update = currentQuantity;
        changeQty(sku, update);
      }
    }else{
      const currentCart = JSON.parse(localStorage.getItem("myCart"));
      const getItem = currentCart.find((item) => item.sku === sku);
      const itemIndex = currentCart.indexOf(getItem);
      let currentQuantity = parseInt(currentCart[itemIndex].cantidad);
      if (currentQuantity > 1) {
        currentQuantity--;
        currentCart[itemIndex].cantidad = currentQuantity;
        setMyCartNewValues(currentCart);
      }
    }
      
  };

  const setMyCartNewValues = (cart) => {
      setProducts(null);
      localStorage.setItem("myCart", JSON.stringify(cart));
      getCart();
      props.getCarrito();
  };

  const renderSkusList = products.map((item, index) => {
    return (
      <Grid container className={classes.productGrid} key={index}>
        <Grid item xs={4} sm={2} className={classes.productItem}
          direction="column"
          style={{alignItems:"center", justifyContent:"center"}}
        >
          {userLogged ? (
              <img 
              src={(item.imagenes && (item.imagenes.imgprod_ruta_archivo.includes('https://ftp3.syscom.mx')? item.imagenes.imgprod_ruta_archivo : `${process.env.REACT_APP_API_URL_IMG}${item.imagenes?.imgprod_ruta_archivo.split("public")[1]}` ) ) }
              //src={ `${process.env.REACT_APP_API_URL_IMG}${item.imagenes?.imgprod_ruta_archivo.split("public")[1]}`} 
              
              className={classes.productImg} />
          ):(
            <img src={item.imagen} className={classes.productImg} />
          )}
          
        </Grid>
        <Grid item xs={8} sm={6} className={classes.productItem}>
          <span className={classes.textItem}>{userLogged != null ? item.prod_nombre : item.nombre}</span>
        </Grid>
        <Grid item xs={12} sm={3} 
          className={classes.productItem + " " + classes.flexCenter}
        >
          <button
            type="button"
            className={classes.cantidadBtnRestar}
            onClick={() => {
              userLogged !== null ? decreaseQuantity(item.pcdc_prod_producto_id):decreaseQuantity(item.sku)
              
            }}
          >
            -
          </button>
          <input
            className={classes.cantidadInput}
            value={userLogged !== null ?  item.pcdc_producto_cantidad : item.cantidad}
            id={`qty_${userLogged !== null ?  item.pcdc_prod_producto_id : item.skuId}`}
            type="text"
            name="cantidad"
            onChange={(event) => {
              userLogged !== null ?  handleChangeQuantity(item.pcdc_prod_producto_id, event.target.value):handleChangeQuantity(item.sku, event.target.value)
            }}
          />
          <button
            type="button"
            className={classes.cantidadBtnSumar}
            onClick={() => {
              userLogged !== null ? increaseQuantity(item.pcdc_prod_producto_id):increaseQuantity(item.sku)
            }}
          >
            +
          </button>
        </Grid>
        <Grid
          xs={12} sm={1} 
          className="d-flex align-items-center justify-content-center"
        >
          <img
            onClick={() => {
              userLogged !== null ? removeFromCart(item.pcdc_prod_producto_id) : removeFromCart(item.sku);
            }}
            src={RemoveItemIcon}
            className="h-32 ms-4 cursor-pointer"
          />
        </Grid>
      </Grid>
    );
  });

  const getPaises = async () => {
    try {
      const response = await generalService.getCountries();
      setPaises(response.paises);
    } catch (error) {}
  };

  const getEstados = async (idPais) => {
    try {
      const response = await orderService.getEstados(idPais);
      setEstados(response.estados);
    } catch (error) {
      setEstados(null);
      setCiudades(null);
    }
  };

  const getCiudades = async (idEstado) => {
    try {
      const response = await generalService.getCities(idEstado);
      setCiudades(response.data.constCiudadesEstados.rows);
    } catch (error) {
      setCiudades(null);
    }
  };

  const createProspect = async (data) => {
    const body = {
      up_razon_social: data.razonSocial !='' ? data.razonSocial: null,
      up_nombre_comercial: data.nombreComercial,
      up_rfc: data.rfc !='' ? data.rfc : null,
      up_email_facturacion: data.email,
      up_direccion_facturacion: data.callePrincipal +' '+ data.colonia,
      up_codigo_postal: data.cp,
      up_direccion:data.callePrincipal  +' '+ data.colonia,
      up_direccion_num_ext: data.numExt,
      up_direccion_num_int: data.numInt,
      up_colonia: data.colonia,
      up_ciudad: data.ciudad,
      up_pais_id: data.pais,
      up_estado_id: data.estado,
      up_cfdi: null, // lista de cfdis
      up_usu_usuario_creador_id: 1,
      upd_calle1: data.entreCalle1,
      upd_calle2: data.entreCalle2,
      upd_alias: null,
      upd_contacto: null,
      upd_telefono: data.telefono,
      up_datos_b2b: {
        up_sitio_web: null,
        up_numero_cuenta_banco: null,
        up_nombre_banco: null,
        up_forma_pago: null,
        up_medio_pago: null,
      },
      up_cmm_estatus_id: 1000188, // tipos de estatus ?
    };
     try{
      const response = await quotesService.createUserProspect(body);
      return response;
     }catch(error){
      return null;
     }
  };

  const getEstadoOnchane = (data) =>{
    getEstados(data.pais)
  }

  const onSubmit = async (data) => {
   setIsLoading(true)
   
    if(userLogged){
      try{
        if(!validaEnviar && !validarRecoleccion){
          setIsLoading(false)
          setOpenAlert({
            message: "Seleccionar método de envío.",
            type: "error",
            open: true,
          });
        }else{
            if(validaEnviar && (dirSelected.snd_direcciones_id != null)){
              const snAlmacenId = null;
              const shipType = 16;
              if(products.length > 0){
                await quotesService.createQuoteSN(
                  shipType,
                  userLogged.usuario.snu_sn_socio_de_negocio_id, 
                  data.comentarios, 
                  dirSelected.snd_direcciones_id, 
                  snAlmacenId,
                  data.incluirEnCopia);
                  setIsLoading(false)
              handleOpen();
              setCarritoItems(0);
              setTimeout(() => {
                cancelRequest();
              }, 4000);
            }else{
              setIsLoading(false)
              setOpenAlert({
                message: "No hay productos para cotizar.",
                type: "error",
                open: true,
              });
            }
            }else{
              if(validarRecoleccion && (pickupSelected.alm_almacen_id != null)){
              const snDrireccionId = null;
              const shipType = 17;
                if(products.length > 0){
                 await quotesService.createQuoteSN(
                  shipType,
                  userLogged.usuario.snu_sn_socio_de_negocio_id, 
                  data.comentarios, 
                  snDrireccionId, 
                  pickupSelected.alm_almacen_id,
                  data.incluirEnCopia);
                  setIsLoading(false)
                handleOpen();
                setTimeout(() => {
                  cancelRequest();
                }, 4000);
              }else{
                setIsLoading(false)
                setOpenAlert({
                  message: "No hay productos para cotizar.",
                  type: "error",
                  open: true,
                });
              }
              }else{
                setIsLoading(false)
                setOpenAlert({
                  message: "Seleccione una dirección o tienda.",
                  type: "error",
                  open: true,
                });
              }
            }
        }
      }catch(error){
        setIsLoading(false)
        setOpenAlert({
          message: "Error al solicitar cotización.",
          type: "error",
          open: true,
        });
      }
    }else{
      if(products.length >0 ){ 
        if( validePais.isPais== true && validePais.isEstado == true  && validePais.ciudad ==true && isForm){
      const prospect = await createProspect(data);
      if (prospect && prospect?.data?.up_usuarios_prospectos_id) { 
        const prospectId = prospect.data.up_usuarios_prospectos_id;
        const productsData = products.map((product) => {
          return { prod_producto_id: product.skuId, cantidad: product.cantidad };
        });
      
        try {
          await quotesService.createQuote(
            productsData,
            prospectId,
            data.comentarios,
            data.incluirEnCopia
          );
        
          setIsLoading(false)
          handleOpen();
          setTimeout(() => {
            cancelRequest();
          }, 4000);
        } catch (error) {
          setIsLoading(false)
          setOpenAlert({
            message: "Error al solicitar cotización.",
            type: "error",
            open: true,
          });
        }
      } else {
        setIsLoading(false)
       
        setOpenAlert({
          message:  prospect.data.message=="Prospecto ya existe" ? "El correo ya fue registrado, como prospecto." :"Error al crear usuario prospecto.",
          type: "error",
          open: true,
        });
      } 
    }else{
      setIsLoading(false)
        setOpenAlert({
          message: "Selecciona país| estado |ciudad, para continuar.",
          type: "error",
          open: true,
        });
      }}
      else{
        setIsLoading(false)
        setOpenAlert({
          message: "Para continuar, agrega productos.",
          type: "error",
          open: true,
        });
      }


    }
   
  };

  const cancelRequest = () => {
    if(userLogged){

      history.push("/");
      localStorage.removeItem("cart");
     props.getCarrito();
    }else{
      localStorage.removeItem("myCart");
      props.getCarrito();
      history.push("/");
    }
    
  };

  const closeCheckout = () => {
    handleClose();
    cancelRequest();
  };

  
//Quotes User Logged  
  const enviar = () =>{
    setValidarEnviar(true);
    setValidarRecoleccion(false);
    getClientAddresses();
  }
    
  const recolectar = () =>{
    setValidarEnviar(false);
    setValidarRecoleccion(true);
    getPickup();
  }

  const getClientAddresses = async () => {
    const client_addresses_response = await userService.getAddressByClientCardCode(userLogged.usuario.snu_cardcode);
    setUserAddresses(client_addresses_response.data.listaSnubycardcode);
    
  };

  const handlechangeAddress = (event) => {
      setQuoteAddress(event.target.value);
      console.log(event.target.value);
      const direc = userAddresses.find(dir => dir.snd_direcciones_id == event.target.value);
      setDirSelected(direc);
  }

  const getPickup = async () => {
    const pickup_response = await  checkoutService.getWarehousesPickup();
    setPickupList(pickup_response.listaAlmacenes)
  }

  const handleChangePickup = (event) =>{
    setPickupWarehouse(event.target.value);
    const pic = pickupList.find(pick => pick.alm_almacen_id == event.target.value);
    setPickupSelected(pic);
  }
  
  const onChangeForm = (element, e) => {
    //alert(JSON.stringify(value));
 
  let index = e.target.selectedIndex;
  console.log(e.target.options[index].text);
  switch(element){
    case "pais":
      setEstados(null);
      setCiudades(null);
      setValue("pais", e.target.value !="" ? e.target.value:'' )
      setValidePais({isPais: true, isEstado: false, ciudad:false})
      getEstados(e.target.value)
      break;
    case "estado":
     
      setCiudades(null);
      setValue("estado", e.target.value)
      setValidePais({isPais: true, isEstado: true, ciudad:false})
      getCiudades(e.target.value)
      break;
    case "ciudad":
      setValue("ciudad", e.target.value)
      setValidePais({isPais: true, isEstado: true, ciudad:true})
      setIsForm(true)
      break;
  }
   const data = getValues();
   console.log(data)
  };
 

   
  const valideInfo =()=>{
   
    if (validePais.isPais== true && validePais.isEstado == true  && validePais.ciudad ==true && !isForm){
      setValidePais({isPais: false, isEstado: false, ciudad:false})
    }else if(!validePais.isPais && !validePais.isEstado   && !validePais.ciudad )
    {
      setValidePais({isPais: true, isEstado: true, ciudad:true})
    }
    
    //const data = getValues();
    // setValidePais({isPais: validePais.isPais? false: false, isEstado: validePais.isEstado? false :false, Ciudad: validePais.ciudad? false: false})  
  
  }
 

  return (
    <div className="mainBackground"> 
     

      <HeaderBanner dataBanner={dataBanner} />
      <Container fixed style={{paddingTop: isMobile ? 100 : 0 }}>
      {isLoading && <LoadingProgress />}
      <SectionSpaceTop />
      <div className="quoteContainer">
        <Container >
          <form onSubmit={handleSubmit(onSubmit)}>
            <h1 className="title">SOLICITAR COTIZACIÓN</h1>
           
              <Grid container>
                <Grid item xs={12} sm={isMobile? 12: 6} style={{ paddingRight: 3 }}>
                  {userLogged !== null ? (
                    <div className="p-3 mb-4">
                      <div style={{ marginBottom: 12 }}>
                        <span className="subtitle">DATOS DE FACTURACIÓN</span>
                      </div>
                      <div className="card p-3">
                        <div className="form-card">
                          <Grid container className="mb-5">
                            <Grid
                              item
                              xs={4}
                              className="d-flex align-items-center h-32"
                            >
                              <span className="subtitle">CARDCODE</span>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography className={classes.smargin}>
                                {clientLogged.listaSnbyid[0].sn_cardcode}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container className="mb-5">
                            <Grid
                              item
                              xs={4}
                              className="d-flex align-items-center h-32"
                            >
                              <span className="subtitle">RAZÓN SOCIAL</span>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography className={classes.smargin}>
                                {clientLogged.listaSnbyid[0].sn_razon_social}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container className="mb-5">
                            <Grid
                              item
                              xs={4}
                              className="d-flex align-items-center h-32"
                            >
                              <span className="subtitle">RFC</span>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography className={classes.smargin}>
                                {clientLogged.listaSnbyid[0].sn_rfc}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container className="mb-5">
                            <Grid
                              item
                              xs={4}
                              className="d-flex align-items-center h-32"
                            >
                              <span className="subtitle">DOMICILIO</span>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography className={classes.smargin}>
                                {
                                  clientLogged.listaSnbyid[0]
                                    .sn_direccion_facturacion
                                }
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                      <div style={{ marginBottom: 12, marginTop: 24 }}>
                        <span className="subtitle">DATOS DE PERSONALES</span>
                      </div>
                      <div className="card p-3">
                        <div className="form-card">
                          <Grid container className="mb-5">
                            <Grid
                              item
                              xs={4}
                              className="d-flex align-items-center h-32"
                            >
                              <span className="subtitle">NOMBRE</span>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography
                                className={classes.smargin}
                              >{`${userLogged.usuario.snu_nombre} ${userLogged.usuario.snu_primer_apellido} ${userLogged.usuario.snu_segundo_apellido}`}</Typography>
                            </Grid>
                          </Grid>
                          <Grid container className="mb-5">
                            <Grid
                              item
                              xs={4}
                              className="d-flex align-items-center h-32"
                            >
                              <span className="subtitle">CORREO</span>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography className={classes.smargin}>
                                {userLogged.usuario.snu_correo_electronico}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container className="mb-5">
                            <Grid
                              item
                              xs={4}
                              className="h-32"
                              style={{ lineHeight: 1 }}
                            >
                              <span className="subtitle">
                                INCLUIR EN COPIA (SEPARAR CON ;)
                              </span>
                            </Grid>
                            <Grid item xs={8}>
                              <input
                                {...register("incluirEnCopia", {
                                  required: false,
                                })}
                                type="text"
                                className={"w-100 input px-2 "}
                                placeholder="nombre@ejemplo.com;nombre@ejemplo.com"
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                      <div className="card p-3" style={{ marginTop: 24 }}>
                        <Grid xs={12} container className={classes.grayDiv}>
                          <Grid className={clsx(classes.paperTitle)}>
                            <Typography>Elige un tipo de entrega</Typography>
                          </Grid>
                        </Grid>
                        <Grid>
                          <FormControl component="fieldset">
                            <RadioGroup className={isMobile?'': classes.radioTipoEntrega} >
                              <Grid sx={12} sm={6}>
                              <ListItem
                                key={"sm" + "16"}
                                role={undefined}
                                dense
                                button
                                className="radiolist"
                                onClick={()=>{
                                  enviar();
                                }}
                              >
                                <FormControlLabel
                                  value="16"
                                  control={<Radio />}
                                  label="Envío a domicilio"
                                />
                                <ListItemIcon className={classes.listItemIcon}>
                                  <LocalShippingIcon id={`shipping16`} />
                                </ListItemIcon>
                              </ListItem>
                              </Grid>
                              <Grid sx={12} sm={6}>
                              <ListItem
                                key={"sm" + "17"}
                                role={undefined}
                                dense
                                button
                                className="radioList"
                                onClick={()=>{
                                  recolectar();
                                }}
                              >
                                <FormControlLabel
                                  value="17"
                                  control={<Radio />}
                                  label="Recoger en tienda (sin envío"
                                />
                                <ListItemIcon className={classes.listItemIcon}>
                                  <HouseIcon id={`pickup17`} />
                                </ListItemIcon>
                              </ListItem>
                              </Grid>
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </div>
                      {validaEnviar ? (
                         <div className="card p-3" style={{ marginTop: 24 }}>
                         <Grid xs={12} className={classes.grayDiv}>
                           <Grid className={clsx(classes.paperTitle)}>
                             <Typography>Dirección de entrega</Typography>
                             
                           </Grid>
                         </Grid>
                         <Grid>
                           <Grid style={{textAlign: "center", marginTop: 24, marginBottom:24}}>
                             <Typography>{`
                                ${dirSelected.snd_alias !== undefined ? dirSelected.snd_alias : ""}`}<br/>{`
                                ${dirSelected.snd_calle1 !== undefined ?  dirSelected.snd_calle1 : ""} 
                                ${(dirSelected.snd_direccion_num_ext !== undefined ) ? `#${dirSelected.snd_direccion_num_ext},` : ""} 
                                ${dirSelected.snd_colonia !== undefined ? `${dirSelected.snd_colonia},`:""} `}<br/>
                                {`${dirSelected.snd_ciudad !== undefined ? `${dirSelected.snd_ciudad},`:""} 
                                ${(dirSelected&&dirSelected.estado?.estpa_estado_nombre && dirSelected.estado?.estpa_estado_nombre && dirSelected.estado?.estpa_estado_nombre !== undefined) ? dirSelected.estado?.estpa_estado_nombre : "" }
                                ${dirSelected.snd_codigo_postal !== undefined ? `C.P.: ${dirSelected.snd_codigo_postal}`:""}`}
                            
                                
                                
                            </Typography>
                            
                           </Grid>
                           <Grid 
                              item 
                              xs={12}
                              style={{textAlign: "center",  marginBottom:24}}
                           >
                             <FormControl 
                               className={classes.formControl}
                               variant="outlined"
                               style={{ width: isMobile ?'204px' : ''}}
                             >
                               <Select
                                 labelId="select-quote-address-label"
                                 id="select-quote-adsress"
                                 className={classes.selects}
                                 value={quoteAddress}
                                 onChange={handlechangeAddress}
                                 displayEmpty
                               >
                                <MenuItem selected="selected" value="" disabled><em>Elegir dirección</em></MenuItem>
                                  {userAddresses.map((value) => {
                                    return (
                                      <MenuItem value={value.snd_direcciones_id}>
                                        {`${value.snd_direccion} #${value.snd_direccion_num_ext}, ${value.snd_ciudad}`}
                                      </MenuItem>
                                    );
                                  })}
                               </Select>
                             </FormControl>
                           </Grid>
                           
                         </Grid>
                       </div>
                      ):""}
                      {validarRecoleccion ? (
                        <div className="card p-3" style={{ marginTop: 24 }}>
                        <Grid xs={12} className={classes.grayDiv}>
                          <Grid className={clsx(classes.paperTitle)}>
                            <Typography>Dirección de recolección</Typography>
                          </Grid>
                        </Grid>
                        <Grid>
                          <Grid style={{textAlign: "center", marginTop: 24, marginBottom:24}}>
                            <Typography>
                              {`${pickupSelected.alm_nombre ? pickupSelected.alm_nombre : ""}`}<br/>
                              {`${pickupSelected.alm_direccion ? pickupSelected.alm_direccion : ""}`}</Typography>
                          </Grid>
                          <Grid 
                            item 
                            xs={12}
                            style={{textAlign: "center",  marginBottom:24}}
                          >
                            <FormControl 
                              className={classes.formControl}
                              variant="outlined"
                              style={{textAlign: "center",  marginBottom:24}}
                            >
                              <Select 
                                 labelId="select-quote-pickup"
                                 id="select-quote-pickup"
                                 className={classes.selects}
                                 displayEmpty
                                 value={pickupWarehouse}
                                 onChange={handleChangePickup}
                              >

                                <MenuItem selected="selected" value="" disabled><em>Elegir tienda</em></MenuItem> 
                                  {pickupList.map((value) => {
                                      return (
                                        <MenuItem value={value.alm_almacen_id}>
                                          {`${value.alm_nombre}`}
                                        </MenuItem>
                                      );
                                    })}

                              </Select>
                            </FormControl>
                          </Grid>
                          
                        </Grid>
                      </div>

                      ):""}
                     
                    </div>
                  ) : (
                    <div className="p-3 card mb-4">
                      <div className="mb-4">
                        <span className="subtitle">DATOS DE LA COTIZACIÓN</span>
                      </div>
                      <div className="form-card">
                        {/* input stars */}
                        <Grid container className="mb-5">
                          <Grid
                            item
                            xs={4}
                            className="d-flex align-items-center h-32"
                          >
                            <span className="subtitle">Email*</span>
                          </Grid>
                          <Grid item xs={8}>
                            <input
                              {...register("email", {
                                required: true,
                                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              })}
                              type="text"
                              className={
                                "w-100 input px-2 " +
                                (errors.email ? "error" : "")
                              }
                              placeholder="nombre@ejemplo.com"
                            />
                            {errors.email?.type === "pattern" && (
                              <div className="error-message">
                                El email no es válido
                              </div>
                            )}
                          </Grid>
                        </Grid>
                        {/* input ends */}
                        {/* input stars */}
                        <Grid container className="mb-5">
                          <Grid item xs={4} className="h-32">
                            <span className="subtitle">Razón Social</span>
                          </Grid>
                          <Grid item xs={8}>
                            <input
                              {...register("razonSocial", { required: false })}
                              type="text"
                              className={"w-100 input px-2 "}
                            />
                          </Grid>
                        </Grid>
                        {/* input ends */}
                        {/* input stars */}
                        <Grid container className="mb-5">
                          <Grid item xs={4} className="h-32">
                            <span className="subtitle">RFC</span>
                          </Grid>
                          <Grid item xs={8}>
                            <input
                              {...register("rfc", {
                                required: false,
                                // minLength: 12,
                                // maxLength: 13,
                                // pattern: /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
                              })}
                              type="text"
                              className={"w-100 input px-2 "}
                            />
                            {/* {errors.email?.type === "minLength" && (
                                <div className="error-message">
                                  El RFC debe contener entre 12 y 13 caracteres
                                  (tiene menos).
                                </div>
                              )}
                              {errors.email?.type === "maxLength" && (
                                <div className="error-message">
                                  El RFC debe contener entre 12 y 13 caracteres
                                  (tiene más).
                                </div>
                              )} */}
                            {/* {errors.email?.type === "pattern" && (
                                <div className="error-message">
                                  El RFC no es válido
                                </div>
                              )} */}
                          </Grid>
                        </Grid>
                        {/* input ends */}
                        {/* input stars */}
                        <Grid container className="mb-5">
                          <Grid item xs={4} className="h-32">
                            <span className="subtitle">Nombre Comercial*</span>
                          </Grid>
                          <Grid item xs={8}>
                            <input
                              {...register("nombreComercial", {
                                required: true,
                              })}
                              type="text"
                              className={
                                "w-100 input px-2 " +
                                (errors.nombreComercial ? "error" : "")
                              }
                            />
                          </Grid>
                        </Grid>
                        {/* input ends */}
                        {/* input stars */}
                        <Grid container className="mb-5">
                          <Grid item xs={4} className="h-32">
                            <span className="subtitle">En atención a*</span>
                          </Grid>
                          <Grid item xs={8}>
                            <input
                              {...register("nombre", { required: true })}
                              type="text"
                              className={
                                "w-100 input px-2 " +
                                (errors.nombre ? "error" : "")
                              }
                            />
                          </Grid>
                        </Grid>
                        {/* input ends */}
                        {/* input stars */}
                        <Grid container className="mb-5">
                          <Grid item xs={4} className="h-32">
                            <span className="subtitle">Teléfono*</span>
                          </Grid>
                          <Grid item xs={8}>
                            <input
                              {...register("celular", {
                                required: true,
                                minLength: 10,
                                maxLength: 10,
                                pattern: /^[0-9]*$/,
                              })}
                              type="text"
                              className={
                                "w-100 input px-2 " +
                                (errors.celular ? "error" : "")
                              }
                            />
                            {errors.celular?.type === "minLength" && (
                              <div className="error-message">
                                El teléfono debe ser de 10 dígitos (tiene
                                menos).
                              </div>
                            )}
                            {errors.celular?.type === "maxLength" && (
                              <div className="error-message">
                                El teléfono debe ser de 10 dígitos (tiene más).
                              </div>
                            )}
                            {errors.celular?.type === "pattern" && (
                              <div className="error-message">
                                El teléfono debe contener solo dígitos
                              </div>
                            )}
                          </Grid>
                        </Grid>
                        {/* input ends */}
                        {/* input stars */}
                        <Grid container className="mb-5">
                          <Grid item xs={4} className="h-32">
                            <span className="subtitle">
                              Incluir en copia (separar con ;)
                            </span>
                          </Grid>
                          <Grid item xs={8}>
                            <input
                              {...register("incluirEnCopia", {
                                required: false,
                              })}
                              type="text"
                              className={"w-100 input px-2 "}
                              placeholder="nombre@ejemplo.com;nombre@ejemplo.com"
                            />
                          </Grid>
                        </Grid>
                        {/* input ends */}
                      </div>
                      <div className="p-3 card">
                        <div className="mb-4">
                          <span className="subtitle">DIRECCIÓN DE ENVIO</span>
                        </div>
                        <div className="form-card">
                          {/* input stars */}
                          <Grid container className="mb-5">
                            <Grid item xs={4} className="h-32">
                              <span className="subtitle">Calle Principal*</span>
                            </Grid>
                            <Grid item xs={8}>
                              <input
                                {...register("callePrincipal", {
                                  required: true,
                                })}
                                type="text"
                                className={
                                  "input px-2 " + (errors.callePrincipal ? "error" : "")
                                }
                                placeholder=""
                              />
                            </Grid>
                          </Grid>
                          {/* input ends */}
                          {/* input stars */}
                          <Grid container className="mb-5">
                            <Grid item xs={4} className="h-32">
                              <span className="subtitle">Entrecalle1</span>
                            </Grid>
                            <Grid item xs={8}>
                              <input
                                {...register("entreCalle1", {
                                  required: false,
                                })}
                                type="text"
                                className={"w-100 input px-2 "}
                              />
                            </Grid>
                          </Grid>
                          {/* input ends */}
                          {/* input stars */}
                          <Grid container className="mb-5">
                            <Grid item xs={4} className="h-32">
                              <span className="subtitle">Entrecalle2</span>
                            </Grid>
                            <Grid item xs={8}>
                              <input
                                {...register("entreCalle2", {
                                  required: false,
                                })}
                                type="text"
                                className={"w-100 input px-2 "}
                              />
                            </Grid>
                          </Grid>
                          {/* input ends */}
                          {/* Double input stars */}
                          <Grid container className="mb-5">
                            <Grid item xs={4} className="h-32">
                              <span className="subtitle">Núm. Ext.*</span>
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              className="d-flex align-items-center"
                            >
                              <div>
                                <input
                                  {...register("numExt", {
                                    required: true,
                                    pattern: /^[0-9]*$/,
                                  })}
                                  type="text"
                                  className={
                                    "w-100 input px-2 " +
                                    (errors.numExt ? "error" : "")
                                  }
                                />
                              </div>
                              <span className="subtitle ms-4 me-2">
                                Núm. Int.
                              </span>
                              <input
                                {...register("numint", {
                                  required: false,
                                  pattern: /^[0-9]*$/,
                                })}
                                type="text"
                                className={"input px-2"}
                              />
                            </Grid>
                          </Grid>
                          {/* Double input ends */}
                          {/* input stars */}
                          <Grid container className="mb-5">
                            <Grid item xs={4} className="h-32">
                              <span className="subtitle">Colonia*</span>
                            </Grid>
                            <Grid item xs={8}>
                              <input
                                type="text"
                                {...register("colonia", {
                                  required: true
                                })}
                                className={
                                  "w-100 input px-2 " +
                                  (errors.colonia ? "error" : "")
                                }
                              />
                            </Grid>
                          </Grid>
                          {/* input ends */}
                          {/* Double input stars */}
                          <Grid container className="mb-5">
                            <Grid item xs={4} className="h-32">
                              <span className="subtitle">Teléfono*</span>
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              className="d-flex align-items-center"
                            >
                              <input
                                {...register("telefono", {
                                  required: true,
                                  minLength: 10,
                                  maxLength: 10,
                                  pattern: /^[0-9]*$/,
                                })}
                                type="text"
                                className={
                                  "w-100 input px-2 " +
                                  (errors.telefono ? "error" : "")
                                }
                              />
                              <span className="subtitle ms-4 me-2">
                                Código postal*
                              </span>
                              <input
                                {...register("cp", {
                                  required: true,
                                  minLength: 5,
                                  maxLength: 5,
                                  pattern: /^[0-9]*$/,
                                })}
                                type="text"
                                className={
                                  "w-100 input px-2 " +
                                  (errors.cp ? "error" : "")
                                }
                              />
                            </Grid>
                          </Grid>
                          {/* Double input ends */}
                          {/* Double input stars */}
                          <Grid container className="mb-5">
                            <Grid item xs={4} className="h-32">
                              <span className="subtitle">País*</span>
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              className="d-flex align-items-center"
                            >
                              <select
                                onChange={(e) =>{onChangeForm("pais", e);}}

                                className={
                                  "w-100 input px-2 " +
                                  (!validePais.isPais ? "error" : "")
                                }
                              >
                                <option value=""  disabled selected>
                                  Selecciona el pais
                                </option>
                                {paises &&
                                  paises.map((item, index) => (
                                    <option
                                      
                                      value={item.pais_pais_id}
                                      key={`option-pai-id-${index}`}
                                    >
                                      {item.pais_nombre}
                                    </option>
                                  ))}
                              </select>
                              {/* <input
                                  {...register("pais", { required: true })}
                                  type="text"
                                  className={
                                    "w-100 input px-2 " + (errors.pais ? "error" : "")
                                  }
                                /> */}

                              <span className="subtitle ms-4 me-2">
                                Estado*
                              </span>
                              <select
                               
                               onChange={(e) =>{onChangeForm("estado", e);}}
                                className={
                                  "w-100 input px-2 " +
                                  (!validePais.isEstado ? "error" : "")
                                }
                              >
                                <option value="" disabled selected>
                                  Selecciona el estado
                                </option>
                                {estados &&
                                  estados.map((item, index) => (
                                    <option
                                      value={item.estpa_estado_pais_id}
                                      key={`option-estado-id-${index}`}
                                    >
                                      {item.estpa_estado_nombre}
                                    </option>
                                  ))}
                              </select>
                              {/* <input
                                  {...register("estado", { required: true })}
                                  type="text"
                                  className={
                                    "w-100 input px-2 " +
                                    (errors.estado ? "error" : "")
                                  }
                                /> */}
                            </Grid>
                          </Grid>
                          {/* Double input ends */}
                          {/* input stars */}
                          <Grid container className="mb-5">
                            <Grid item xs={4} className="h-32">
                              <span className="subtitle">Ciudad*</span>
                            </Grid>
                            <Grid item xs={8}>
                              <select
                                
                                onChange={(e) =>{onChangeForm("ciudad", e);}}
                                className={
                                  "w-100 input px-2 " +
                                  (!validePais.ciudad ? "error" : "")
                                }
                              >
                                <option value="" disabled selected>
                                  Selecciona la ciudad
                                </option>
                                {ciudades &&
                                  ciudades.map((item, index) => (
                                    <option
                                      value={item.city_ciudades_estados_id}
                                      key={`option-ciudad-id-${index}`}
                                    >
                                      {item.city_ciudad}
                                    </option>
                                  ))}
                              </select>
                            </Grid>
                          </Grid>
                          {/* input ends */}
                        </div>
                      </div>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} sm={isMobile? 12:6} style={{ paddingLeft: 3 }}>
                  <div className="p-10 card mb-5">
                    {products && products.length > 0 && renderSkusList}
                  </div>
                  <div className="">
                    <div className="mb-2">
                      <span className="title">Comentarios</span>
                    </div>
                    <p className="text">
                      Favor de incluir cualquier nota que nos ayude a poder
                      ofrecerle el mejor precio, como detalles de su proyecto,
                      presupuesto, fecha estimada de compra, forma de pago, etc.
                    </p>
                    <div className="my-5">
                      <textarea
                        {...register("comentarios", { required: false })}
                        className="card p-4 w-100"
                        placeholder="Escribe tus comentarios"
                        rows="10"
                      ></textarea>
                    </div>
                    {/* buttons starts */}
                    <Grid container>
                      <Grid
                        item
                        xs={12} sm={5}
                        className="d-flex justify-content-center p-3"
                      >
                        <button
                          type="button"
                          className="btnOutline"
                          onClick={cancelRequest}
                        >
                          Cancelar
                        </button>
                      </Grid>
                      <Grid
                        item
                        xs={12} sm={5}
                        className="d-flex justify-content-center p-3"
                      >
                        <button  onClick={ ()=> !isLogged? valideInfo() :''} className="btn">
                          Solicitar Cotización
                        </button>
                      </Grid>
                      <Grid
                        item
                        xs={12} sm={2}
                        className="d-flex justify-content-center align-items-center p-3"
                      >
                        <a
                          className="return"
                          onClick={() => {
                            history.goBack();
                          }}
                        >
                          {" "}
                          <i
                            className="fas fa-chevron-left"
                            style={{ marginRight: 3 }}
                          ></i>
                          Regresar
                        </a>
                      </Grid>
                    </Grid>
                    {/* buttons ends */}
                  </div>
                </Grid>
              </Grid>
           
          </form>
        </Container>
       
       
       
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="modal-card">
            <div>
              <span className="message">
                La cotización ha sido enviada con éxito. Nuestro equipo de
                ventas se pondrá en contacto contigo a la brevedad.
              </span>
            </div>
            <div>
              <button
                onClick={closeCheckout}
                type="button"
                className={classes.btn}
              >
                Aceptar
              </button>
            </div>
          </Box>
        </Modal>
      </div>
      </Container>
      {isMobile ? (
        <Fragment>
        
        
        </Fragment>
      ) : (
        <Fragment>
        
       
        </Fragment>
      )}
    </div>
  );
};
export default Request;
