import React, { useContext, createContext, } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((styles) => ({
    title: {
        fontSize: 28,
        textAlign: "center",
        width: "100%",
        color: "#0b3196",
        fontFamily: "'Centrale Sans Bold'",
        fontWeight: "600",
    },
    container: {
        width: "100%",
    }
}));


export const  Title =(props) =>{
    const classes = useStyles();
    return (
        <div >
            <div className={classes.container}>
                <Typography className={classes.title}>{props.title}</Typography>
            </div>
        </div>
    );
}
