import React, { useState, useEffect, useContext, Fragment } from "react";
import { API_URL } from "_services/api.url.back.img";
// @material-ui/core components
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import DescriptionIcon from "@material-ui/icons/Description";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { productsService } from "_services/products.services.js";

const useStyles = makeStyles((styles) => ({
  container: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 18,
    paddingLeft: 15,
    paddingRight: 15,
    [styles.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  pDescription: {
    textAlign: "justify",
    fontWeight: 400,
  },
  table: {
    border: "none",
  },
  btnDownloadSheet: {
    backgroundColor: "#7edea5",
    padding: "5px 20px",
  },
  tableSplited: {
    width: "50%",
    display: "inline-block",
    verticalAlign: "top",
    [styles.breakpoints.down("sm")]: {
      width: "100%",
      display: "inline-block",
    },
  },
  title: {
    fontFamily: "'Centrale Sans Bold'",
  },
  mobTable: {
    [styles.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  datasheetDiv: {
    width: "100px",
    textAlign: "center",
    marginTop: "15px",
    marginLeft: "30px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f5f5f5",
    },
  },
  datasheetBtn: {
    backgroundColor: "#0b3196",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#0b3196",
    },
  },
  datasheetBtnTitle: {
    lineHeight: "1.2",
    marginTop: 7,
    fontSize: 14,
    fontFamily: "'Centrale Sans Medium'",
    color: "#444444",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      //backgroundColor: theme.palette.action.hover,
      backgroundColor: "#f5f5f5",
    },
    "& th,td": {
      borderBottom: "none",
      padding: 9,
      paddingLeft: 60
    },
  },
}))(TableRow);

export default function SectionProductDataSheet(props) {
  const classes = useStyles();

  const [rowsLeft, setRowsLeft] = useState([]);
  const [rowsRight, setRowsRight] = useState([]);
  const [datasheet, setDatasheet] = useState(null);
  const [validIcon, setValidIcon] = useState(false);

  const splitRows = () => {
    let _a = [];
    let _b = [];
    for (let i = 0; i < props.productDetails.prod_attr.length; i++) {
      if (i % 2 === 0) {
        _a.push(props.productDetails.prod_attr[i]);
      } else {
        _b.push(props.productDetails.prod_attr[i]);
      }
    }
    setRowsLeft(_a);
    setRowsRight(_b);
  };

  (async () => {
    const pdsList = await productsService.getProductDataSheetLength(props.productDetails.skus[props.productDetails.sku_selected].id)
    const aux = 1;
    if(aux <= pdsList.countListProductDataSheet){
      setValidIcon(true);
    }else{
      setValidIcon(false);
    }
  })();

  const downloadFile = async () => {
    const pdsList = await productsService.getProductDataSheetLength(props.productDetails.skus[props.productDetails.sku_selected].id)


    for (let i = 1; i <= pdsList.countListProductDataSheet; i++) {
      let _datasheet = "/ficha/" + props.productDetails.prod_sku + '/' + i;
      window.open(_datasheet, "_blank");
    }
  };

  useEffect(() => {
    splitRows();
    setDatasheet(
      <div
        className={classes.datasheetDiv}
        onClick={() => {
          downloadFile();
        }}
      >
        <IconButton className={classes.datasheetBtn}>
          <DescriptionIcon />
        </IconButton>
        <Typography
          className={classes.datasheetBtnTitle}
        >
          Descargar Archivo
        </Typography>
      </div>
    );
  }, []);

  useEffect(() => {
    splitRows();
    setDatasheet(
      <div
        className={classes.datasheetDiv}
        onClick={() => {
          downloadFile();
        }}
      >
        <IconButton className={classes.datasheetBtn}>
          <DescriptionIcon />
        </IconButton>
        <Typography className={classes.datasheetBtnTitle}>Descargar Archivo</Typography>
      </div>
    );
  }, [props.productDetails.prod_sku]);

  return (
    <div className={classes.container}>
      <TableContainer className={classes.tableSplited}>
        <Table className={classes.table} aria-label="a dense table">
          <TableBody>
            {rowsLeft.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  className={classes.title + " " + classes.mobTable}
                >
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="left" className={classes.mobTable}>
                  {row.val}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer className={classes.tableSplited}>
        <Table className={classes.table} aria-label="a dense table">
          <TableBody>
            {rowsRight.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  className={classes.title + " " + classes.mobTable}
                >
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="left" className={classes.mobTable}>
                  {row.val}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {validIcon  ?  datasheet:""}
    </div>
  );
}
