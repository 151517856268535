import React, { useEffect, useState, useContext } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((styles) => ({
  title: {
    color: "#000000",
    /* margin: "1.75rem 0 0.875rem", */
    lineHeight: 1,
    marginTop: 0,
    minHeight: "28.8",
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: 500,
    textDecoration: "none",
    fontSize: "24px",
    textAlign: "justified",
    textTransform: "capitalize",
    [styles.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  container: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 15,
    paddingRight: 15,
  },
}));

export default function SectionProductName(props) {
  const classes = useStyles();
  const [name, setName] = useState(props.productDetails?.name);
  useEffect(() => {
    setName(
      props.productDetails.skus[
        props.productDetails.sku_selected
      ]?.name.toLowerCase()
    );
  }, []);
  return (
    <div>
      <div className={classes.container}>
        <Typography className={classes.title}>{name}</Typography>
      </div>
    </div>
  );
}
