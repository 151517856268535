import img001 from "assets/img/Dielsa/cable_big.png";
import img002 from "assets/img/Dielsa/blog.png";
import img003 from "assets/img/Dielsa/cable_circle.png";
import img004 from "assets/img/Dielsa/cable_round.png";
import imgNA from "assets/img/Image-Not-Available.png";

import man001 from "assets/img/manufacturers/man_01.png";
import man002 from "assets/img/manufacturers/man_02.png";
import man003 from "assets/img/manufacturers/man_03.png";
import man004 from "assets/img/manufacturers/man_04.png";
import man005 from "assets/img/manufacturers/man_05.png";

import client001 from "assets/img/clients/client_01.png";
import client002 from "assets/img/clients/client_02.png";
import client003 from "assets/img/clients/client_03.png";
import client004 from "assets/img/clients/client_04.png";
import client005 from "assets/img/clients/client_05.png";

const globalDataHome = {
    logoImg: "",
    dataBanner: "",
    mainBanners: {
      banners: [],
      side1: [],
      side2: [],
    },
    cate_dest: [
      { id: 1, image: img003, text: "Cableado estructurado", link: "" },
      { id: 2, image: img004, text: "Fibra Optica", link: "" },
      { id: 3, image: img004, text: "Seguridad Fisica", link: "" },
      { id: 4, image: img004, text: "Seguridad de Datos", link: "" },
      { id: 5, image: img004, text: "Energia", link: "" },
      { id: 6, image: img004, text: "Seguridad de Datos", link: "" },
    ],
    clients_trust: {
      images: [
        { id: 1, image: client001 },
        { id: 2, image: client002 },
        { id: 3, image: client003 },
        { id: 4, image: client004 },
        { id: 5, image: client005 },
      ],
      title: "CLIENTES QUE CONFÍAN EN DIELSA",
    },
    slider_trusty: {
      images: [],
    },
    mid_banners: {
      banners: [],
    },
    mid_brands: {
      title: "Nuestras Marcas",
      options: [],
    },
    mid_clients: {
      title: "Clientes que confían en Dielsa",
      options: [],
    },
    productos_efocados: {
      title: "Productos Enfocados por Industria",
      section: [],
    },
    marcas_productos: {
      images: [
        { id: 1, image: img003 },
        { id: 2, image: img004 },
        { id: 3, image: img003 },
        { id: 4, image: img004 },
        { id: 5, image: img003 },
        { id: 6, image: img004 },
      ],
      title: "Marcas de Productos",
    },
    prod_dest: {
      products: [],
      title: "Productos Destacados",
    },
    prod_bottom: {
      products: [
        {
          id: 1,
          brand: "Tech Ziom",
          sku: "1234567-89",
          name:
            "Cable (4 Postes) 7 Pies de Fibra Optica17.41in Calibre 45UR Negro",
          stock: 100,
          priceList: 1599,
          priceClient: 1359,
          image: img001,
          link: "",
        },
        {
          id: 2,
          brand: "Tech Ziom",
          sku: "1234567-89",
          name:
            "Cable (4 Postes) 7 Pies de Fibra Optica17.41in Calibre 45UR Negro",
          stock: 100,
          priceList: 1599,
          priceClient: 1359,
          image: img001,
          link: "",
        },
        {
          id: 3,
          brand: "Tech Ziom",
          sku: "1234567-89",
          name:
            "Cable (4 Postes) 7 Pies de Fibra Optica17.41in Calibre 45UR Negro",
          stock: 100,
          priceList: 1599,
          priceClient: 1359,
          image: img001,
          link: "",
        },
        {
          id: 4,
          brand: "Tech Ziom",
          sku: "1234567-89",
          name:
            "Cable (4 Postes) 7 Pies de Fibra Optica17.41in Calibre 45UR Negro",
          stock: 100,
          priceList: 1599,
          priceClient: 1359,
          image: img001,
          link: "",
        },
        {
          id: 5,
          brand: "Tech Ziom",
          sku: "1234567-89",
          name:
            "Cable (4 Postes) 7 Pies de Fibra Optica17.41in Calibre 45UR Negro",
          stock: 100,
          priceList: 1599,
          priceClient: 1359,
          image: img001,
          link: "",
        },
        {
          id: 6,
          brand: "Tech Ziom",
          sku: "1234567-89",
          name:
            "Cable (4 Postes) 7 Pies de Fibra Optica17.41in Calibre 45UR Negro",
          stock: 100,
          priceList: 1599,
          priceClient: 1359,
          image: img001,
          link: "",
        },
      ],
      title: "Cables",
    },
    blog_post: {
      posts: [
        {
          id: 1,
          name: "Leviton: Soluciones de energia para instalaciones",
          image: img002,
          text:
            "Estas son las soluciones electricas de grado hospitalario, antimicrobianas y temporales para instalaciones de atencion medica temporales que debes conocer.",
          link: "",
        },
        {
          id: 2,
          name: "Leviton: Soluciones de energia para instalaciones",
          image: img002,
          text:
            "Estas son las soluciones electricas de grado hospitalario, antimicrobianas y temporales para instalaciones de atencion medica temporales que debes conocer.",
          link: "",
        },
        {
          id: 3,
          name: "Leviton: Soluciones de energia para instalaciones",
          image: img002,
          text:
            "Estas son las soluciones electricas de grado hospitalario, antimicrobianas y temporales para instalaciones de atencion medica temporales que debes conocer.",
          link: "",
        },
        {
          id: 4,
          name: "Leviton: Soluciones de energia para instalaciones",
          image: img002,
          text:
            "Estas son las soluciones electricas de grado hospitalario, antimicrobianas y temporales para instalaciones de atencion medica temporales que debes conocer.",
          link: "",
        },
        {
          id: 5,
          name: "Leviton: Soluciones de energia para instalaciones",
          image: img002,
          text:
            "Estas son las soluciones electricas de grado hospitalario, antimicrobianas y temporales para instalaciones de atencion medica temporales que debes conocer.",
          link: "",
        },
        {
          id: 6,
          name: "Leviton: Soluciones de energia para instalaciones",
          image: img002,
          text:
            "Estas son las soluciones electricas de grado hospitalario, antimicrobianas y temporales para instalaciones de atencion medica temporales que debes conocer.",
          link: "",
        },
      ],
      title: "Blog",
    },
    clients_comments: {
      comments: [
        {
          id: 1,
          name: "Ivan Herrera",
          text:
            "En mi empresa lorem ipsum sit amet, et consectetur ssit ipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          rating: 4,
        },
        {
          id: 2,
          name: "Ivan Herrera",
          text:
            "En mi empresa lorem ipsum sit amet, et consectetur ssit ipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          rating: 3,
        },
        {
          id: 3,
          name: "Ivan Herrera",
          text:
            "En mi empresa lorem ipsum sit amet, et consectetur ssit ipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          rating: 5,
        },
        {
          id: 4,
          name: "Ivan Herrera",
          text:
            "En mi empresa lorem ipsum sit amet, et consectetur ssit ipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          rating: 4,
        },
        {
          id: 5,
          name: "Ivan Herrera",
          text:
            "En mi empresa lorem ipsum sit amet, et consectetur ssit ipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          rating: 4,
        },
        {
          id: 6,
          name: "Ivan Herrera",
          text:
            "En mi empresa lorem ipsum sit amet, et consectetur ssit ipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          rating: 4,
        },
      ],
      title: "¡Nuestros clientes dicen..!",
    },
    manufacturers: {
      images: [
        { id: 1, image: man001 },
        { id: 2, image: man002 },
        { id: 3, image: man003 },
        { id: 4, image: man004 },
        { id: 5, image: man005 },
      ],
      title: "NUESTROS FABRICANTES",
    },
    categories_tabs: [],
    text_middle_title: "Somos Mayoristas en productos de Telecomunicaciones",
};

export default globalDataHome;