import React, { useState, useLayoutEffect } from 'react'
import './Loading.css'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';


const Loading = ({loading}) => {
   // const [show, setShow] = useState(true)
    const styl= 'container-homess containerss hidess'
    const styl2= 'container-homess containerss'
    return(
        <div className={ loading? styl2 : styl  }>
        <p style={{marginLeft:'-10px'}}>Cargando...</p>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress size={'100px'} />
        
        </Box>
        <br/>
        <p style={{marginLeft:'0px'}}>Espere por favor</p>
      </div>
    );
}; 

export default Loading;