import React, { useState, useEffect } from "react";
import "assets/custom/Scrollbar.css";
import "assets/custom/ProductInput.css";
/// Global
import { API_URL } from "../../_services/api.url.back.img";
import { checkoutService } from "_services/checkout.service";

import { Link, Redirect } from "react-router-dom";
import { Alert, AlertTitle } from '@material-ui/lab';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { TextField } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Fade from '@mui/material/Fade';
import iconDelete from "assets/img/Dielsa/Icon_Eliminar.svg";
import IconButton from "@material-ui/core/IconButton";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from "hooks/useAuth";
import { getPriceSapFormat } from "helpers/formatPrice";
import { accountService } from "services/account/account.service";
const useStyles = makeStyles((styles) => ({
  hide: {
    display: "none !important",
  },
  /// Cart
  cartDisplayFullDiv1: {
    width: "100%",
    height: "100vh",
    display: "block",
    position: "fixed",
    zIndex: "999",
    backdropFilter: 'blur(7px)',
    backgroundColor: '#0000000f',
    float: "right",
    right: 0,
    top: 0,
    boxShadow: "-5px 0px 10px 1px #aaaaaa",
  },
  cartDisplayFullDiv: {
    width: "415px",
    height: "100vh",
    display: "block",
    position: "fixed",
    zIndex: "1002",
    backgroundColor: "#ffffff",
    float: "right",
    right: 0,
    top: 0,
    boxShadow: "-5px 0px 10px 1px #aaaaaa",
    overflowY: "scroll",
    [styles.breakpoints.down("sm")]: {
      width: "85%",
    },
  },
  cartHeaderDiv: {
    width: "100%",
    backgroundColor: "#0b3196",
  },
  cartHeaderBodyDiv: {
    width: "100%",
    height: "53vh",
    backgroundColor: "#ffffff",
    overflowY: "scroll",
    [styles.breakpoints.only("xs")]: {
      height: "53vh",
    },
    [styles.breakpoints.only("sm")]: {
      height: "62vh",
    },
    [styles.breakpoints.only("sm")]: {
      height: "53vh",
    },
  },
  cartFooterBodyDiv: {
    width: "100%",
    height: "18vh",
    backgroundColor: "#ffffff",
    boxShadow: "0 -5px 10px -10px #333",
    marginTop: "5px",
  },
  /// Header
  cartClose: {
    width: "100%",
    textAlign: "right",
    color: "#ffffff",
    paddingRight: "10px",
    paddingTop: "0px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  cartCloseTxt: {
    color: "#ffffff",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "18px",
    display: "inline-block",
    verticalAlign: "-webkit-baseline-middle",
    marginRight: "15px",
    fontWeight: "200",
  },
  cartCloseX: {
    color: "#ffffff",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "24px",
    display: "inline-block",
    verticalAlign: "top",
    fontWeight: "200",
  },
  cartTitle: {
    width: "100%",
    textAlign: "center",
    color: "#ffffff",
    fontSize: "24px",
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "400",
    paddingBottom: "0.5rem"
  },
  /// Body
  productDiv: {
    borderBottom: "2px solid #eaeaea",
  },
  imgDiv: {
    width: "30%",
    display: "inline-block",
    verticalAlign: "top",
    paddingTop: "14px",
    paddingLeft: "8px",
    paddingRight: "5px",
  },
  detailsDiv: {
    width: "70%",
    display: "inline-block",
    verticalAlign: "top",
    paddingTop: "15px",
    paddingRight: "7px",
  },
  productName: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "500",
    fontSize: "15px",
    height: "64px",
    overflow: "hidden",
    textAlign: "justify",
    color: "#000000",
    cursor: "pointer",
  },
  preciosDiv: {
    marginTop: "10px",
    marginBottom: "5px",
  },
  precioLista: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "300",
    fontSize: "18px",
    textAlign: "justify",
    color: "#000000",
  },
  precioUnidad: {
    color: "#0b3196",
    marginTop: 0,
    fontSize: "20px",
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "500",
    display: "inline-block",
  },
  precioListaCross: {
    color: "#979797",
    fontFamily: "'Centrale Sans Thin'",
    textDecoration: "line-through",
    marginBottom: 0,
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: 1,
    display: "inline-block",
  },
  precioUnidadCross: {
    color: "#0b3196",
    textDecoration: "line-through",
    marginTop: 0,
    fontSize: "16px",
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "500",
    display: "inline-block",
  },
  precioPromo: {
    color: "#E41212",
    marginTop: 0,
    fontSize: "20px",
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "500",
    display: "inline-block",
  },
  descriptionPrice: {
    color: "#000000",
    marginTop: 0,
    fontSize: 14,
    fontFamily: "'Centrale Sans Light'",
    fontWeight: "400",
    display: "inline-block",
    paddingLeft: 10,
    width: 125,
  },
  stockDiv: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  productoStock: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "400",
    fontSize: "18px",
    color: "#0b3196",
  },
  productoDisponible: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "400",
    fontSize: "18px",
    color: "#40df00",
  },
  productoNoDisponible: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "400",
    fontSize: "18px",
    color: "#9b9b9b",
  },
  productoSubtotalDiv: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  productoSubtotal: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "600",
    fontSize: "20px",
    color: "#000000",
    display: "inline-block",
  },
  deleteDiv: {
    width: "25%",
    display: "inline-block",
  },
  btnDel: {
    marginLeft: "-15px",
  },
  inputDiv: {
    width: "75%",
    display: "inline-block",
    textAlign: "right",
  },
  btnInput: {
    backgroundColor: "#ffffff",
    color: "#0b3196",
    border: "2px solid #eaeaea",
    fontWeight: "800",
    backgroundColor: "#ffffff",
    fontSize: "16px",
    padding: "0px",
    maxHeight: "40px",
    width: "35px",
    minWidth: "auto",
    display: "inline-block",
    verticalAlign: "top",
    lineHeight: "36px",
    "&:hover": {
      backgroundColor: "#0b3196",
      color: "#ffffff",
    },
  },
  customIconDelete: {
    width: "25px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  inpCant: {
    width: "50px",
    // display: "inline-block",
    // verticalAlign: "top",
    textAlign: "center",
    paddingTop: '8px',
    paddingBottom: '8px',
    '&.MUI-Input': {
      paddingTop: '8px',
      paddingBottom: '8px',
    }
  },
  /// Footer
  footerCartTextDiv: {
    width: "50%",
    display: "inline-block",
    textAlign: "left",
    paddingLeft: "15px",
  },
  footerCartAmountDiv: {
    width: "50%",
    display: "inline-block",
    textAlign: "right",
    paddingRight: "15px",
  },
  subTotalCart: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "500",
    fontSize: "16px",
    color: "#000000",
  },
  subTotalAmountCart: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "500",
    fontSize: "16px",
    color: "#000000",
  },
  totalCart: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "800",
    fontSize: "16px",
    color: "#000000",
  },
  totalAmountCart: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "800",
    fontSize: "16px",
    color: "#000000",
  },
  btnDiv: {
    width: "100%",
    paddingLeft: "15px",
    paddingRight: "15px",
    marginTop: "15px",
  },
  btn: {
    width: "100%",
    backgroundColor: "#ffffff",
    color: "#0b3196",
    border: "2px solid #0b3196",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#0b3196",
      color: "#ffffff",
    },
  },
  /// Empty cart
  emptyCartDiv: {
    width: "100%",
    paddingTop: "50px",
    textAlign: "center",
  },
  emptyCartTxt: {
    fontSize: "24px",
    color: "#000000",
  },
  /// Img cart
  imgCart: {
    width: "100%",
    cursor: "pointer",
  },
  title: {
    color: "#0B3196",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "12px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "14px",
    display: "inline-block",
  },
  text: {
    color: "#9B9B9B",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "12px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "14px",
    display: "inline-block",
    marginLeft: "5px",
  },
}));

export default function CartHeader(props) { 
  const classes = useStyles();
  const {carritoItems, setCarritoItems,isLogged,getUserData } = useAuth();
  const dataUser = getUserData()
  const [cart, setCart] = useState({});
  const [cartBool, setCartBool] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [loadingCart, setLoadingCart] = useState(false);
  const [backOrderButtonDisableFirst, setBackOrderButtonDisableFirst] = useState({});


  useEffect(() => {
   // window.alert(props.showCar)
  
   getTotales();
  
  }, [props.showCar]);
 

  const deleteCartItem = async (idProd) => {
    let _user = JSON.parse(localStorage.getItem("user"));
    let _r = await checkoutService.deleteItemCart(
      _user.usuario.snu_sn_socio_de_negocio_id,
      idProd
    );
    setCarritoItems(carritoItems-1);
    getTotales();  
  };

  const deleteCartItemClean = async (idProd) => {
    let _user = JSON.parse(localStorage.getItem("user"));
    let _r = await checkoutService.deleteItemCart(
      _user.usuario.snu_sn_socio_de_negocio_id,
      idProd
    );
   
    getTotales();  
  };

  const cleanCart = () =>{
    if(cart.productos.length > 0){
      for(let i=0; i<cart.productos.length; i++){
        deleteCartItemClean(cart.productos[i].pcdc_prod_producto_id);
      }
      if(isLogged){
        accountService.addAbandonedCarts({
          type: 2,
          userId: dataUser.usuario.snu_usuario_snu_id,
        });
      }
      setCarritoItems(0)
    }
  }

  const decreaseProductCart = async (idProd, currentQty) => {
    let callUpdateMethod = false;

    let totalQty = -1;
    cart.productos.map((item) => {
      if(item.pcdc_prod_producto_id === idProd) {
        totalQty += item.pcdc_producto_cantidad;
      }
    });

    if (totalQty > 0) {
      callUpdateMethod = true;
    }

    if (callUpdateMethod) {
      // document.getElementById(`inpCant${idProd}`).value = totalQty;
      updateQtyRequest(idProd, totalQty);
    }
  };

  const increaseProductCart = async (idProd, currentQty) => {

    let totalQty = 1;
    cart.productos.map((item) => {
      if(item.pcdc_prod_producto_id === idProd) {
        totalQty += item.pcdc_producto_cantidad;
      }
    });

    //increment
    // document.getElementById(`inpCant${idProd}`).value = totalQty;
    updateQtyRequest(idProd, totalQty);
  };

  const updateQtyRequest = async (product_id, qty) => {
    const response = await checkoutService.addItemCart(
      JSON.parse(localStorage.getItem("user")).usuario
        .snu_sn_socio_de_negocio_id,
      product_id,
      qty
    );
    getTotales();
  };



  const getTotales = async () => {
    setLoadingCart(true);
    let _user = JSON.parse(localStorage.getItem("user"));
    let _cart = await checkoutService.getCartByClientIdCheckout(_user.usuario.snu_sn_socio_de_negocio_id);
    _cart = _cart.data.cdc_carrito_de_compra_id;
    const nuevoConteo = {};
    const productCount = [];
    const productosArray = [];
    for (let index = 0; index < _cart.productos.length; index++) {
      const elemento = _cart.productos[index];
      if (nuevoConteo[elemento.pcdc_prod_producto_id]) {
        nuevoConteo[elemento.pcdc_prod_producto_id]++;
        productCount.push({pcdc_prod_producto_id: elemento.pcdc_prod_producto_id,
          count: nuevoConteo[elemento.pcdc_prod_producto_id]++});
      } else {
        nuevoConteo[elemento.pcdc_prod_producto_id] = 1;
        productCount.push({pcdc_prod_producto_id: elemento.pcdc_prod_producto_id,
          count: 1});
      }
      const data = await checkoutService.getPriceByProductProject(
        _user.usuario.snu_sn_socio_de_negocio_id,
        elemento.prod_sku
      );
     console.log(data)
     elemento.prod_precio=  (data!= null &&
      (data.precio <elemento.precioFinal || elemento.precioFinal ===0) ?  data.precio : elemento.prod_precio)
      productosArray.push({
        ...elemento,
        proyectoVerificarPrecio: (data!= null &&
        (data.precio <elemento.precioFinal || elemento.precioFinal ===0) 
            ? true : false), 
        proyectoProdprecio:data!= null 
          ? Number(data.precio)
          : 0,
        proyectoProdprecioUSD: data != null
          ? Number(data.precioUSD)
          : 0,
        proyectoMoneda: data != null
          ?data.moneda
          : "",
        proyectoAcuerdoId: data != null
          ? data.idProyecto
          : ""
      });
    
    }

    _cart = {..._cart, productos: productosArray };

    setBackOrderButtonDisableFirst(productCount);
    setCart(_cart);
    if (_cart !== null) {
      //setCart(_cart);
      let _st = 0;
      let _t = 0;
      for (let i = 0; i < _cart.productos.length; i++) {
        let _p = _cart.productos[i];
        let _precio = parseFloat(_p.prod_precio);
        let _prec_d = parseFloat(_p.precioFinal);
        let _sub = _prec_d * _p.pcdc_producto_cantidad;
        _st += parseFloat(_p.pcdc_precio) * _p.pcdc_producto_cantidad;
        _t += _sub;
      }
      setSubtotal(_st);
      setTotal(_t);
    } else {
      setTotal(0);
    }
    setLoadingCart(false);
  
  };


  const validPriceStock = () =>{
    let hiddenButtonCar = false;
    let duplicados = {};
    if(cart?.productos) {
      cart.productos.map((prod) => {
        if(prod.prod_precio > 0
          && prod.producto.prod_peso > 0 
          && prod.producto.prod_volumen > 0
          && (prod.aplicaBackOrder || prod.prod_total_stock > 0) 
        ) {
          hiddenButtonCar = true;
        }

        if(duplicados[prod.pcdc_prod_producto_id]) {
          duplicados[prod.pcdc_prod_producto_id]++;
        } else {
          duplicados[prod.pcdc_prod_producto_id] = 1;
        }
      })
    }
    return {showBuys: hiddenButtonCar, duplicados};
  }

  return (
    <>
      <Fade in={props.showCar} timeout={{ enter: 100, exit: 100 }}>
        <div>
          <Fade in={props.showCar} timeout={{ enter: 1800, exit: 1800 }}>
            <div
              className={classes.cartDisplayFullDiv1}
              onClick={() => {
                props.closeCartDiv();
              }}
            />
          </Fade>
          <div className={classes.cartDisplayFullDiv}>
            <div className={classes.cartHeaderDiv}>
              <div
                className={classes.cartClose}
                onClick={() => {
                  props.closeCartDiv();
                }}
              >
                <IconButton aria-label="close" onClick={() => props.closeCartDiv()}>
                  <CloseIcon sx={{ color: '#fff' }} />
                </IconButton>
                <Typography className={classes.cartTitle}>Carrito</Typography>
              </div>
            </div>
            <div className={classes.cartHeaderBodyDiv}>
              { !loadingCart ?
              cart?.productos?.length ? (
                cart.productos.map((m, index) => {
                  return (
                  <div className={classes.productDiv} key={index}>
                    {
                      ((m.prod_total_stock === 0 && !m.aplicaBackOrder) || m.prod_precio === 0 || m.canti  && m.pcdc_producto_cantidad > m.prod_total_stock 
                      || m.producto?.prod_peso ==0 || m.producto?.prod_volumen ==0
                      ) &&
                      <div style={{ paddingTop: '9px', paddingRight: '9px', paddingLeft: '9px' }}>
                        <Alert severity="info" style={{ borderRadius: '10px' }}>
                          Este producto solo esta disponible <strong> para cotizar.</strong>
                        </Alert>
                      </div>
                    }
                    {
                    ((!m.aplicaBackOrder
                      && m.pcdc_producto_cantidad > m.prod_total_stock
                      && m.prod_total_stock !== 0)
                      && m.prod_precio > 0 
                      && m.producto?.prod_peso > 0
                      && m.producto?.prod_volumen > 0
                      ) &&
                      <div style={{ paddingTop: '9px', paddingRight: '9px', paddingLeft: '9px' }}>
                        <Alert severity="info" style={{ borderRadius: '10px' }}>
                          Este producto supera el stock actual, <strong>al realizar la compra se ajustará a la cantidad del stock disponible.</strong>
                        </Alert>
                      </div>
                    }
                    {
                    (m.aplicaBackOrder
                      // || m.canti
                      && validPriceStock().duplicados[m.pcdc_prod_producto_id] > 1
                      && m.prod_precio > 0 
                      && m.producto?.prod_peso > 0
                      && m.producto?.prod_volumen > 0)
                      &&
                      <div style={{ paddingTop: '9px', paddingRight: '9px', paddingLeft: '9px' }}>
                        <Alert severity="info" style={{ borderRadius: '10px' }}>
                          Este producto supera el stock disponible, <strong>se mostrarán separada la cantidad disponible y la diferencia como un segundo producto.</strong>
                        </Alert>                      
                      </div>
                    }
                    <div className={classes.imgDiv}>
                      {m.imagenes ? (
                        <Link
                          to={`/product?prod=${m.productoPadreId}&sku=${m.pcdc_prod_producto_id}`}
                        >
                          <img
                            className={classes.imgCart}
                            src={m.imagenes !== null ?(  m.imagenes.imgprod_ruta_archivo.includes('https://ftp3.syscom.mx')? '' : API_URL) + "" +
                                  m.imagenes.imgprod_ruta_archivo.replace(
                                    "./public",
                                    ""
                                  )
                                : ""
                            }
                          />
                        </Link>
                      ) : (
                        <Link
                          to={`/product?prod=${m.productoPadreId}&sku=${m.pcdc_prod_producto_id}`}
                        >
                          <img
                            className={classes.imgCart}
                            src="http://wws.com.pa/wp-content/plugins/wordpress-ecommerce/marketpress-includes/images/default-product.png"
                          />
                        </Link>
                      )}
                    </div>
                    <div className={classes.detailsDiv}>
                      <Link
                        to={`/product?prod=${m.productoPadreId}&sku=${m.pcdc_prod_producto_id}`}
                      >
                        <Typography className={classes.productName}>
                          {m.prod_nombre}
                        </Typography>
                      </Link>
                      <Typography className={classes.title}>
                        SKU:
                      </Typography>
                      <Typography className={classes.text}>
                        {m.prod_nombre_extranjero}
                      </Typography>
                      {JSON.parse(localStorage.getItem("client")) !== undefined &&
                      JSON.parse(localStorage.getItem("client")) !== null ? (
                        m.prod_precio !== m.precioFinal ? (
                          m.precioPromocionDielsaBool === true && !m.proyectoVerificarPrecio? (
                            <div className={classes.preciosDiv}>
                              <div>
                                <Typography className={classes.descriptionPrice}>
                                  Precio de lista
                                </Typography>
                                <Typography className={classes.precioListaCross}>
                                  {getPriceSapFormat(m.prod_precio)}
                                </Typography>
                              </div>

                              <div>
                                <Typography className={classes.descriptionPrice}>
                                  Precio unitario
                                </Typography>
                                <Typography className={classes.precioUnidadCross}>
                                  {getPriceSapFormat(m.DescuentoDielsaFijo)}
                                </Typography>
                              </div>

                              <div>
                                <Typography className={classes.descriptionPrice}>
                                  Precio promo
                                </Typography>
                                <Typography className={classes.precioPromo}>
                                  {getPriceSapFormat(m.precioFinal)}
                                </Typography>
                              </div>
                            </div>
                          ) : (
                            <div className={classes.preciosDiv}>
                            {!m.proyectoVerificarPrecio &&<div>
                                <Typography className={classes.descriptionPrice}>
                                  Precio de lista
                                </Typography>
                                <Typography className={classes.precioListaCross}>
                                  {getPriceSapFormat(m.prod_precio)}
                                </Typography>
                              </div>}

                              <div>
                                <Typography className={classes.descriptionPrice}>
                                  Precio unitario
                                </Typography>
                                <Typography className={classes.precioUnidad}>
                                  {`${getPriceSapFormat(
                                    (m.proyectoVerificarPrecio 
                                      && m.proyectoProdprecio < m.precioFinal ||m.precioFinal===0)
                                      ? m.proyectoProdprecio
                                      : m.precioFinal
                                    )}`}
                                </Typography>
                                <Typography
                                  className={classes.productoSubtotal}
                                  style={{
                                    fontSize: 14,
                                    paddingLeft: 10,
                                    color: '#ff5f5f',
                                    fontWeight: "300"
                                  }}
                                >
                                  
                                  {    (m.proyectoVerificarPrecio 
                                &&( m.proyectoProdprecio < m.precioFinal || m.precioFinal===0)
                                      ?`No. de acuerdo: ${m.proyectoAcuerdoId}` : '')}



                                </Typography>
                              </div>
                            </div>
                          )
                        ) : m.prod_tipo_precio_base !== "Stock Inactivo" ? (
                          <div className={classes.preciosDiv}>
                            <div >
                              <Typography className={classes.descriptionPrice}>
                                Precio de lista
                              </Typography>
                              <Typography className={classes.precioLista}>
                                {getPriceSapFormat(m.prod_precio)}
                              </Typography>
                              <Typography
                                  className={classes.productoSubtotal}
                                  style={{
                                    fontSize: 14,
                                    paddingLeft: 10,
                                    color: '#ff5f5f',
                                    fontWeight: "300"
                                  }}
                                >
                                  
                                {   (m.proyectoVerificarPrecio 
                                &&( m.proyectoProdprecio < m.precioFinal || m.precioFinal===0)
                                      ?`No. de acuerdo: ${m.proyectoAcuerdoId}` : '')}


                                </Typography>
                            </div>
                          </div>
                        ) : (
                          <div className={classes.preciosDiv}>
                            <div >
                              <Typography className={classes.descriptionPrice}>
                                Precio especial
                              </Typography>
                              <Typography className={classes.precioLista}>
                                {getPriceSapFormat(m.prod_precio)}
                              </Typography>
                              <Typography
                                  className={classes.descriptionPrice}
                                  style={{
                                    fontSize: 14,
                                    paddingLeft: 10,
                                    color: '#ff5f5f',
                                    fontWeight: "300"
                                  }}
                                >
                                  
                                  {    (m.proyectoVerificarPrecio 
                                &&( m.proyectoProdprecio < m.precioFinal || m.precioFinal===0)
                                      ?`No. de acuerdo: ${m.proyectoAcuerdoId}` : '')}



                                </Typography>
                            </div>
                          </div>
                        )
                      ) : (
                        ""
                      )}
                      
                      <div className={classes.stockDiv}>
                        <Typography className={classes.productoStock}>
                          En stock ({m.prod_total_stock})
                        </Typography>
                        {/*<Typography
                          className={
                            m.prod_total_stock > 0
                              ? classes.productoDisponible
                              : classes.productoNoDisponible
                          }
                        >
                          {m.prod_total_stock > 0 ? "Disponible" : "No Disponible"}
                        </Typography>*/}
                      </div>
                      {JSON.parse(localStorage.getItem("client")) !== undefined &&
                      JSON.parse(localStorage.getItem("client")) !== null &&
                      JSON.parse(localStorage.getItem("client")).listaSnbyid[0] !==
                        undefined ? (
                        JSON.parse(localStorage.getItem("client")).listaSnbyid[0]
                          .sn_lista_precios !== null ? (
                          <div className={classes.productoSubtotalDiv}>
                            <Typography className={classes.descriptionPrice}>
                              Total l&iacute;nea
                            </Typography>
                            <Typography className={classes.productoSubtotal}>
                              {`${getPriceSapFormat(
                                (m.proyectoVerificarPrecio 
                                && m.proyectoProdprecio < m.precioFinal ||m.precioFinal===0)
                                  ? m.proyectoProdprecio * m.pcdc_producto_cantidad
                                  : m.pcdc_producto_cantidad * m.precioFinal
                              )}`}
                            </Typography>
                          </div>
                        ) : (
                          <div className={classes.productoSubtotalDiv}>
                            <Typography className={classes.productoSubtotal}>
                              {getPriceSapFormat(
                                m.pcdc_producto_cantidad * m.precioFinal
                              )}
                            </Typography>
                          </div>
                        )
                      ) : (
                        ""
                      )}
                      <div>
                        <div className={classes.deleteDiv}>
                          <IconButton
                            aria-label="delete"
                            className={classes.btnDel}
                            onClick={() => {
                              deleteCartItem(m.pcdc_prod_producto_id);
                            }}
                          >
                            <img
                              src={iconDelete}
                              className={classes.customIconDelete}
                            />
                          </IconButton>
                        </div>
                        <div className={classes.inputDiv}>
                          <Button
                            variant="contained"
                            className={classes.btnInput}
                            disableElevation
                            onClick={() => {
                              decreaseProductCart(
                                m.pcdc_prod_producto_id,
                                m.pcdc_producto_cantidad
                              );
                            }}
                            // disabled={m.aplicaBackOrder && backOrderButtonDisableFirst[index].count > 1}
                          >
                            -
                          </Button>
                          <TextField
                            id={"inpCant" + m.pcdc_prod_producto_id}
                            value={m.pcdc_producto_cantidad}
                            // onBlur={(ev) => {
                            //   changeAmountCart(
                            //     m.pcdc_prod_producto_id,
                            //     ev.target.value
                            //   );
                            // }}
                            size="small"
                            className={classes.inpCant + " inp-center"}
                            variant="outlined"
                            // sx={{
                            //   paddingTop: '8px',
                            //   paddingBottom: '8px',
                            // }}
                          />
                          <Button
                            variant="contained"
                            className={classes.btnInput}
                            disableElevation
                            onClick={() => {
                              increaseProductCart(
                                m.pcdc_prod_producto_id,
                                m.pcdc_producto_cantidad
                              );
                            }}
                            // disabled={m.aplicaBackOrder && backOrderButtonDisableFirst[index].count > 1}
                          >
                            +
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )})
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div
                    className={classes.detailsDiv}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      paddingTop: 60,
                      paddingBottom: 60,
                    }}
                  >
                    <Typography className={classes.emptyCartTxt}>
                      El carrito de compras se encuentra vacío
                    </Typography>
                    <div style={{ paddingTop: '60px' }}>
                      <ShoppingCartIcon sx={{ fontSize: 120 }} />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className={classes.productDiv}>
                    <div className={classes.imgDiv}>
                      <Skeleton variant="rounded" animation="wave" width='100%' height={130} />
                    </div>
                    <div className={classes.detailsDiv}>
                      <Typography className={classes.productName}>
                        <Stack spacing={1}>
                          <Skeleton variant="rounded" animation="wave" width='90%' />
                          <Skeleton variant="rounded" animation="wave" width='80%' />
                        </Stack>
                      </Typography>
                      <div style={{display: 'flex'}}>
                        <Skeleton variant="rounded" animation="wave" width='15%' />
                        &nbsp;
                        <Skeleton variant="rounded" animation="wave" width='15%' />
                      </div>
                      <div className={classes.preciosDiv}>
                        <div style={{display: 'flex'}}>
                          <Skeleton variant="rounded" animation="wave" width='25%' />
                          &nbsp;
                          <Skeleton variant="rounded" animation="wave" width='15%' />
                        </div>
                      </div>

                      <div className={classes.stockDiv}>
                        <div style={{display: 'flex'}}>
                          <Skeleton variant="rounded" animation="wave" width='20%' height={25} />
                          &nbsp;
                          <Skeleton variant="rounded" animation="wave" width='10%' height={25} />
                        </div>
                      </div>

                      <div className={classes.productoSubtotalDiv}>
                        <div style={{display: 'flex', alignItems: 'flex-end', paddingTop: '16px', paddingBottom: '16px'}}>
                          <Skeleton variant="rounded" animation="wave" width='25%' />
                          &nbsp;
                          <Skeleton variant="rounded" animation="wave" width='35%' height={25} />
                        </div>
                      </div>
                      <div>
                        <div className={classes.deleteDiv} style={{ paddingRight: 10 }}>
                          <Skeleton variant="circular" width={35} height={35} />
                        </div>
                        <div className={classes.inputDiv} style={{ paddingLeft: 30 }}>
                          <Skeleton variant="rounded" animation="wave" width='100%' height={35} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.productDiv}>
                    <div className={classes.imgDiv}>
                      <Skeleton variant="rounded" animation="wave" width='100%' height={130} />
                    </div>
                    <div className={classes.detailsDiv}>
                      <Typography className={classes.productName}>
                        <Stack spacing={1}>
                          <Skeleton variant="rounded" animation="wave" width='90%' />
                          <Skeleton variant="rounded" animation="wave" width='80%' />
                        </Stack>
                      </Typography>
                      <div style={{display: 'flex'}}>
                        <Skeleton variant="rounded" animation="wave" width='15%' />
                        &nbsp;
                        <Skeleton variant="rounded" animation="wave" width='15%' />
                      </div>
                      <div className={classes.preciosDiv}>
                        <div style={{display: 'flex'}}>
                          <Skeleton variant="rounded" animation="wave" width='25%' />
                          &nbsp;
                          <Skeleton variant="rounded" animation="wave" width='15%' />
                        </div>
                      </div>

                      <div className={classes.stockDiv}>
                        <div style={{display: 'flex'}}>
                          <Skeleton variant="rounded" animation="wave" width='20%' height={25} />
                          &nbsp;
                          <Skeleton variant="rounded" animation="wave" width='10%' height={25} />
                        </div>
                      </div>

                      <div className={classes.productoSubtotalDiv}>
                        <div style={{display: 'flex', alignItems: 'flex-end', paddingTop: '16px', paddingBottom: '16px'}}>
                          <Skeleton variant="rounded" animation="wave" width='25%' />
                          &nbsp;
                          <Skeleton variant="rounded" animation="wave" width='35%' height={25} />
                        </div>
                      </div>
                      <div>
                        <div className={classes.deleteDiv} style={{ paddingRight: 10 }}>
                          <Skeleton variant="circular" width={35} height={35} />
                        </div>
                        <div className={classes.inputDiv} style={{ paddingLeft: 30 }}>
                          <Skeleton variant="rounded" animation="wave" width='100%' height={35} />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                
              )
            }
            </div>
            {cart !== null && cart !== undefined ? (
              <div className={classes.cartFooterBodyDiv}>
                <div className={classes.footerCartTextDiv}>
                  <Typography className={classes.subTotalCart}>Subtotal</Typography>
                </div>
                <div className={classes.footerCartAmountDiv}>
                  <Typography className={classes.subTotalAmountCart}>
                    {cart !== null && cart !== undefined
                      ? JSON.parse(localStorage.getItem("client")) !== undefined &&
                        JSON.parse(localStorage.getItem("client")) !== null
                        ? getPriceSapFormat(cart.precioTotal)
                        : "N/D"
                      : "$0.00"}
                  </Typography>
                </div>
                <div className={classes.footerCartTextDiv}>
                  <Typography className={classes.subTotalCart}>
                    Descuentos
                  </Typography>
                </div>
                <div className={classes.footerCartAmountDiv}>
                  <Typography className={classes.subTotalAmountCart}>
                    {cart !== null && cart !== undefined
                      ? JSON.parse(localStorage.getItem("client")) !== undefined &&
                        JSON.parse(localStorage.getItem("client")) !== null
                        ? getPriceSapFormat(cart.totalDescuentos)
                        : "N/D"
                      : "$0.00"}
                  </Typography>
                </div>
                <div className={classes.footerCartTextDiv}>
                  <Typography className={classes.subTotalCart}>
                    IVA {cart.tipoImpuesto}
                  </Typography>
                </div>
                <div className={classes.footerCartAmountDiv}>
                  <Typography className={classes.subTotalAmountCart}>
                    {cart !== null && cart !== undefined
                      ? JSON.parse(localStorage.getItem("client")) !== undefined &&
                        JSON.parse(localStorage.getItem("client")) !== null
                        ? getPriceSapFormat(cart.TotalImpuesto)
                        : "N/D"
                      : "$0.00"}
                  </Typography>
                </div>
                <div className={classes.footerCartTextDiv}>
                  <Typography className={classes.totalCart}>Total</Typography>
                </div>
                <div className={classes.footerCartAmountDiv}>
                  <Typography className={classes.totalAmountCart}>
                    {cart !== null && cart !== undefined
                      ? JSON.parse(localStorage.getItem("client")) !== undefined &&
                        JSON.parse(localStorage.getItem("client")) !== null
                        ? getPriceSapFormat(cart.precioFinalTotalMasImpuestos)
                        : "N/D"
                      : "$0.00"}
                  </Typography>
                </div>
                {validPriceStock().showBuys && (
                  <div className={classes.btnDiv}>
                  <Link to={`/checkout`}>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      disableElevation
                      onClick={() => props.closeCartDiv()}
                    >
                      Ir a mi carrito
                    </Button>
                  </Link>
                </div>
                )}
                <div className={classes.btnDiv}>
                  <Link to={`/quotes/request`}>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      disableElevation
                      onClick={() => props.closeCartDiv()}
                    >
                      Solicitar Cotización
                    </Button>
                  </Link>
                </div>
                <div className={classes.btnDiv}>
                  <Typography style={{marginTop:5, marginBottom: 15, textAlign:"center"}}>
                    <Button 
                          onClick={cleanCart}
                          variant="contained"
                          className={classes.btn}
                    >
                        Limpiar Carrito
                      </Button>
                  </Typography>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Fade>
    </>
  );
}
