import React, { useState, useEffect, Fragment } from "react";
import clsx from "clsx";
import { API_URL } from "_services/api.url.back.img";
import { connect } from "react-redux";
import dielsaFavicon from "../../assets/img/Dielsa/dielsa_favicon.png";
import { checkoutService } from "../../_services/checkout.service";
import { userService } from "_services/user.service";
import { generalService } from "../../_services/general.service";
import CheckoutConekta from "./CheckoutConekta";
import CheckoutProductsList from "./CheckoutProductsList";
import CheckoutSummary from "./CheckoutSummary";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Typography } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { Button, TextField } from "@mui/material";
import { Transferer } from "./Transferer";
import { useAuth } from "hooks/useAuth";
const useStyles = makeStyles((theme) => ({
  blockOne: {
    paddingRight: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: "inherit",
  },
  grayDiv: {
    marginBottom: 30,
  },
  radioColor: {
    '&.Mui-checked': {
      color: '#0B3196',
    },
  },
  paper: {
    backgroundColor: "#F8F8F8",
    boxShadow: "none",
    borderRadius: 0,
    padding: "15px",
    fontSize: "15px",
    "& h6": {
      fontFamily: "'Centrale Sans Medium'",
      color: "#000000",
      fontSize: "17px",
    },
    textAlign: "left",
  },
  paperTitle: {
    borderRadius: 2,
    backgroundColor: "#F3F3F3",
    padding: 10,
    "& p": {
      color: "#000000",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: 18,
      fontWeight: 500,
      textAlign: "center",
    },
  },
  personalData: {
    backgroundColor: "#0B3196",
    color: "#ffffff",
    "& h6": {
      fontFamily: "'Centrale Sans Medium'",
      color: "#ffffff",
    },
    boxShadow: "none",
    borderRadius: 0,
    padding: "15px",
    fontSize: "15px",
    textAlign: "center",
  },
  usdPrice: {
    color: theme.palette.primary.error,
    fontSize: 16,
    fontWeight: 900,
  },
  selects: {
    background: "#ffffff",
    color: "#0B3196",
    fontWeight: 700,
    border: "2px solid #0B3196",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },
  radioTipoPago: {
    "& label.MuiFormControlLabel-root .MuiTypography-body1": {
      color: "#666060",
    },
    "& label.MuiFormControlLabel-root .MuiTypography-body1.Mui-disabled": {
      color: "#ccc6c6",
    },
    "& label.MuiFormControlLabel-root .MuiRadio-colorSecondary.Mui-checked + span.MuiTypography-body1": {
      color: "#0B3196",
      fontWeight: 700,
    },
  },
  listItemIcon: {
    color: "#666060",
  },
}));

export default function CheckoutPayment3(props) {
  const classes = useStyles();
  const {isCreditDielsa}= useAuth()
  const [isTransfer, setIsTransfer] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [cfdis, setCfdis] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    props.cartCheckoutData.order_payment_method
  );
  const [selectedPaymentMethodInfo, setSelectedPaymentMethodInfo] = useState(
    props.cartCheckoutData.order_payment_method_info
  );
  const [selectedCFDI, setSelectedCFDI] = useState(0);

  const getPaymentMethods = async () => {
    const response = await checkoutService.getPaymentFormas();
    response.constSapFormasPago.map((pay) => {
      pay.sfp_clave == "03" && (pay.sfp_nombre = "Transferencia bancaria");
      pay.sfp_clave == "04" && (pay.sfp_nombre = "Tarjeta de débito/crédito");
      pay.sfp_clave == "99" && (pay.sfp_nombre = "Mi crédito con Dielsa");
    });
    setPaymentMethods(
      response.constSapFormasPago.filter((pay) => {
        return (
          pay.sfp_clave == "03" ||
          pay.sfp_clave == "04" ||
          pay.sfp_clave == "99"
        );
      })
    );
  };

  const getClientCreditData = async () => {
    const response = await userService.getClientById(
      props.cartCheckoutData.client_id
    );
    props.handleUpdateCartCheckoutData("client", response.data.listaSnbyid[0]);
  };

  const getCFDIlist = async () => {
    const response = await generalService.getCfdi();
    setCfdis(response.constCfdi);

    // asign propper value to select CFDI
    if (
      props.cartCheckoutData.cart.cdc_cfdi === "" ||
      props.cartCheckoutData.cart.cdc_cfdi === null
    ) {
      setSelectedCFDI(0);
    } else {
      setSelectedCFDI(props.cartCheckoutData.cart.cdc_cfdi);
    }
  };

  useEffect(() => {
    getPaymentMethods();
    getClientCreditData();
    getCFDIlist();
  }, []);

  const handleChange = (event) => {
  //  window.alert('Metodo Pago:' + event.target.value)
    //Transferencia
    if(event.target.value ==27){
      //window.alert('Metodo Pago:' + event.target.value)
      setIsTransfer(true)
    }else{
      setIsTransfer(false)
    }
    setSelectedPaymentMethod(parseInt(event.target.value));
    setSelectedPaymentMethodInfo(
      paymentMethods.filter(
        (pm) => pm.sfp_sap_formas_pago_id == event.target.value
      )[0]
    );

    props.handleUpdateCartCheckoutData(
      "order_payment_method",
      parseInt(event.target.value)
    );
    props.handleUpdateCartCheckoutData(
      "order_payment_method_info",
      paymentMethods.filter(
        (pm) => pm.sfp_sap_formas_pago_id == event.target.value
      )[0]
    );
    props.handleUpdateCartCheckoutData("token_card", null); // reset to null token card if change selection
    props.handleUpdateCartCheckoutData("cardnumber", null);
    props.handleUpdateCartCheckoutData("cardname", null);
    props.handleUpdateCartCheckoutData("cardexpmonth", null);
    props.handleUpdateCartCheckoutData("cardexpyear", null);
    props.handleUpdateCartCheckoutData("cardcvc", null);

    updateCartPayment(parseInt(event.target.value));
  };

  const updateCartPayment = async (payment_type) => {
    const response = await checkoutService.updateCartPayment(
      props.cartCheckoutData.client_id,
      payment_type
    );
    props.getCartByClientId();
  };

  const handleChangeCfdi = (event) => {
    setSelectedCFDI(event.target.value);
    updateCartCfdi(event.target.value);
  }; 

  const updateCartCfdi = async (order_cfdi) => {
    const response = await checkoutService.updateCartCfdi(
      props.cartCheckoutData.client_id,
      order_cfdi
    );
    props.getCartByClientId();
  };

  const openReferences = () => {
    const url = API_URL + 'CUENTASBANCARIASDIELSA.pdf';
    window.open(url, '_blank');
  }

  useEffect(() => {
  
    //window.alert(JSON.stringify( props.cartCheckoutData.cart))
  }, [selectedPaymentMethodInfo])
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} md={6} className={classes.blockOne}>
          <Grid container item xs={12}>
            <Grid item xs={12} className={classes.content}>
              <Grid container>
                
                <Grid item xs={12} className={classes.grayDiv}>
                  <Paper className={clsx(classes.paperTitle)}>
                    <Typography>Forma de pago</Typography>
                  </Paper>
                  <Paper className={clsx(classes.paper)}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        className={classes.radioTipoPago}
                        aria-label="paymentMethods"
                        name="paymentMethods"
                        value={selectedPaymentMethod}
                        onChange={handleChange}
                      >
                        {paymentMethods.map((item, index) => {
                          if (item.sfp_clave == "99") {
                            return (
                              <Fragment key={index}>
                                <ListItem
                                  key={"sm" + item.sfp_sap_formas_pago_id}
                                  role={undefined}
                                  dense
                                  button
                                  onClick={() => {
                                    document
                                      .getElementById("dielsacredit")
                                      .classList.add("activeRadio");
                                    document
                                      .getElementById("banktransfer")
                                      .classList.remove("activeRadio");
                                    document
                                      .getElementById("creditcard")
                                      .classList.remove("activeRadio");
                                  }}
                                >
                                  {(props.cartCheckoutData.client.sn_credito >
                                  0 && !isCreditDielsa )? (
                                    <FormControlLabel
                                      value={item.sfp_sap_formas_pago_id}
                                      control={<Radio className={classes.radioColor} />}
                                      label={
                                        item.sfp_nombre
                                          ? item.sfp_nombre
                                          : item.sfp_descripcion
                                      }
                                    />
                                  ) : (
                                    <Fragment>
                                      <FormControlLabel
                                        disabled
                                        value={item.sfp_sap_formas_pago_id}
                                        control={<Radio className={classes.radioColor} />}
                                        label={
                                          item.sfp_nombre
                                            ? item.sfp_nombre
                                            : item.sfp_descripcion
                                        }
                                      />
                                      <Tooltip
                                        title={
                                          "No cuenta con límite de crédito"
                                        }
                                      >
                                        <HelpOutlineIcon />
                                      </Tooltip>
                                    </Fragment>
                                  )}
                                  <ListItemIcon
                                    className={classes.listItemIcon}
                                  >
                                    {/* <LocalShippingIcon id={`dielsacredit`} /> */}
                                    <img
                                      src={dielsaFavicon}
                                      id={`dielsacredit`}
                                    />
                                  </ListItemIcon>
                                </ListItem>
                                {selectedPaymentMethodInfo.sfp_clave ==
                                  "99" && (
                                  <span
                                    style={{
                                      color: "#DF2B2B",
                                      fontSize: 12,
                                      paddingLeft: 15,
                                    }}
                                  >
                                    <span className={classes.usdPrice}>*</span>{" "}
                                    Los productos son vendidos en USD y se
                                    muestran a la tasa de conversión del día de
                                    hoy. Su valor puede cambiar al momento de
                                    liquidar la factura.
                                  </span>
                                )}
                              </Fragment>
                            );
                          } else {
                            return (
                              <ListItem
                                key={"sm" + item.sfp_sap_formas_pago_id}
                                role={undefined}
                                dense
                                button 
                                onClick={() => {
                                  if (item.sfp_clave === "03") {
                                    document
                                      .getElementById("banktransfer")
                                      .classList.add("activeRadio");
                                    document
                                      .getElementById("dielsacredit")
                                      .classList.remove("activeRadio");
                                    document
                                      .getElementById("creditcard")
                                      .classList.remove("activeRadio");
                                  } else if (item.sfp_clave === "04") {
                                    document
                                      .getElementById("creditcard")
                                      .classList.add("activeRadio");
                                    document
                                      .getElementById("dielsacredit")
                                      .classList.remove("activeRadio");
                                    document
                                      .getElementById("banktransfer")
                                      .classList.remove("activeRadio");
                                  }
                                }}
                              >
                                <FormControlLabel
                                  value={item.sfp_sap_formas_pago_id}
                                  control={<Radio className={classes.radioColor} />}
                                  label={
                                    item.sfp_nombre
                                      ? item.sfp_nombre
                                      : item.sfp_descripcion
                                  }
                                />
                                <ListItemIcon className={classes.listItemIcon}>
                                  {item.sfp_clave === "03" && (
                                    <ReceiptIcon id={`banktransfer`} />
                                  )}
                                  {item.sfp_clave === "04" && (
                                    <CreditCardIcon id={`creditcard`} />
                                  )}
                                </ListItemIcon>
                              </ListItem>
                            );
                          }
                        })}
                      </RadioGroup>
                    </FormControl>
                  </Paper>
                </Grid>

                      
               <Transferer cartCheckoutData={props.cartCheckoutData} isTransfer={isTransfer}/>

                <Grid item xs={12} className={classes.grayDiv}>
                  <Paper className={clsx(classes.paperTitle)}>
                    <Typography>Selecciona CFDI</Typography>
                  </Paper>
                  <Paper className={clsx(classes.paper)}>
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <FormControl
                          className={classes.formControl}
                          variant="outlined"
                        >
                          <Select
                            className={classes.selects}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedCFDI}
                            onChange={handleChangeCfdi}
                          >
                            <MenuItem value={0}>Elegir CFDI</MenuItem>
                            {cfdis.map((value) => {
                              return (
                                <MenuItem
                                  value={value.cfdi_codigo}
                                  key={value.cfdi_codigo}
                                >
                                  {`${value.cfdi_codigo} - ${value.cfdi_texto}`}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  {selectedPaymentMethodInfo.sfp_clave == "04" && (
                    <CheckoutConekta
                      cartCheckoutData={props.cartCheckoutData}
                      handleUpdateCartCheckoutData={
                        props.handleUpdateCartCheckoutData
                      }
                      getCartByClientId={props.getCartByClientId}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={classes.blockTwo}>
          <Fragment>
            <Grid container>
              {!props.isMobile && (
                <Fragment>
                  <Grid item xs={12}>
                    <Paper
                      className={clsx(classes.personalData)}
                      style={{ marginBottom: 30 }}
                    >
                      <Typography variant="h6">Datos personales</Typography>
                      <Typography>
                        {props.cartCheckoutData.client.sn_razon_social}
                      </Typography>
                      <Typography>
                        {props.cartCheckoutData.client.sn_email_facturacion}
                      </Typography>
                      <Typography>
                        {props.cartCheckoutData.client.sn_telefono_empresa}
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12}>
                    <Paper
                      className={clsx(classes.paper, classes.personalData)}
                    >
                      <Typography variant="h6">
                        Resumen de tu carrito
                      </Typography>
                    </Paper>
                    <CheckoutProductsList
                      cartCheckoutData={props.cartCheckoutData}
                    />
                  </Grid>
                </Fragment>
              )}
              <CheckoutSummary cartCheckoutData={props.cartCheckoutData} stepNumber={props.stepNumber} />
            </Grid>
          </Fragment>
        </Grid>
      </Grid>
    </Fragment>
  );
}
