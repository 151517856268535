import React, { useState, useEffect, useContext, Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useAuth } from "hooks/useAuth";
import { getPriceSapFormat } from "helpers/formatPrice";
const useStyles = makeStyles((styles) => ({
  container: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 15,
    paddingRight: 15,
  },
  oneLine: {
    display: "block",
  },
  inStock: {
    color: "#40df00",
    fontSize: 16,
    fontWeight: 500,
  },
  noStock: {
    color: "#0b3196",
    fontSize: 18,
    fontWeight: 500,
  },
  primaryP: {
    color: "#0b3196",
    fontSize: 18,
    fontWeight: 500,
    marginTop: 30,
    marginBottom: 0,
  },
  infoText: {
    width: "50%",
    fontSize: "12px",
    whiteSpace: "nowrap",
  },
  priceList: {
    color: "#9b9b9b",
    marginBottom: 0,
    fontSize: "24px",
    fontFamily: "'Centrale Sans Medium'",
    lineHeight: 1,
    display: "inline-block",
    width: "50%",
  },
  priceListCross: {
    color: "#767676",
    textDecoration: "line-through",
    marginBottom: 0,
    fontSize: "16px",
    fontFamily: "'Centrale Sans Medium'",
    lineHeight: 1,
    display: "inline-block",
    width: "50%",
  },
  priceClient: {
    color: "#0C2D9C",
    marginTop: 0,
    fontSize: "24px",
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "600",
    display: "inline-block",
    width: "50%",
  },
  priceClientCross: {
    color: "#0b3196",
    textDecoration: "line-through",
    marginTop: 0,
    fontSize: "24px",
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "600",
    display: "inline-block",
    width: "50%",
  },
  priceDiscount: {
    color: "#E41212",
    marginTop: 0,
    fontSize: "24px",
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "600",
    display: "inline-block",
    width: "50%",
  },
  shippingTime: {
    fontWeight: "600",
    marginBottom: "15px",
  },
}));

export default function SectionProductStock(props) {
  const classes = useStyles();
  const {isDollar} = useAuth();
  const [prices, setPrices] = useState(null);

  useEffect(()=>{
   getPrices();
  },[isDollar])

  /**
   * Money format
   */


  const getPrices = () => {
    let b = [];
    if (
      props.productDetails.skus[props.productDetails.sku_selected]?.stock > 0
    ) {
      b.push(
        <label className={classes.shippingTime}>
          Env&iacute;o en 1 d&iacute;a
        </label>
      );
    } else {
      if (
        props.productDetails.skus[props.productDetails.sku_selected]
          ?.backorder === true
      ) {
        if (
          props.productDetails.skus[props.productDetails.sku_selected]
            .diasResurtimiento === 0
        ) {
          b.push(
            <label className={classes.shippingTime}>
              Env&iacute;o en{" "}
              {props.productDetails.skus[props.productDetails.sku_selected]
                .diasResurtimiento + 1}{" "}
              d&iacute;a
            </label>
          );
        } else {
          b.push(
            <label className={classes.shippingTime}>
              Env&iacute;o en{" "}
              {props.productDetails.skus[props.productDetails.sku_selected]
                .diasResurtimiento + 1}{" "}
              d&iacute;as
            </label>
          );
        }
      } else {
        b.push(
          <label className={classes.shippingTime}>
            No disponible para env&iacute;o
          </label>
        );
      }
    }
    if (
      props.productDetails.skus[props.productDetails.sku_selected]?.precio !==
      props.productDetails.skus[props.productDetails.sku_selected]
        ?.precioDescuento
    ) {
      b.push(
        <div className={classes.oneLine}>
          <Typography className={classes.priceListCross} style={{visibility: (props.productDetails.skus[props.productDetails.sku_selected]
             .precioListaDls ===0?'hidden': '')}}>
            {getPriceSapFormat(
             isDollar? props.productDetails.skus[props.productDetails.sku_selected]
             .precioListaDls : props.productDetails.skus[props.productDetails.sku_selected]
                .precio
            )}
          </Typography>
          <label className={classes.infoText}>Precio de Lista ({isDollar ? 'USD': 'MXN' })</label>
        </div>
      );
    } else {
      b.push(
        <div className={classes.oneLine} style={{visibility: (props.productDetails.skus[props.productDetails.sku_selected]
          .precioListaDls ===0?'hidden': '')}}>
          <Typography style={{marginTop:10}} className={classes.priceList} >
            {getPriceSapFormat(isDollar ?props.productDetails.skus[props.productDetails.sku_selected]
                ?.precioListaDls:
              props.productDetails.skus[props.productDetails.sku_selected]
                ?.precio
            )}
          </Typography>
          <Typography className={classes.infoText} style={{color: "#aaaaaa"}}>
            Precio de Lista ({isDollar ? 'USD': 'MXN' })
          </Typography>
        </div>
      );
    }
    if (
      props.productDetails.skus[props.productDetails.sku_selected]?.precio !==
      props.productDetails.skus[props.productDetails.sku_selected]
        ?.precioDescuento
    ) {
      if (
        props.productDetails.skus[props.productDetails.sku_selected]
          .descuentoSNbool === true &&
        props.productDetails.skus[props.productDetails.sku_selected].precioSN !=
          0
      ) {
        b.push(
          <div style={{visibility: (props.productDetails.skus[props.productDetails.sku_selected]
            .precioFinalDls ===0?'hidden': '')}}>
            <Typography className={classes.priceClientCross}>
              {getPriceSapFormat(isDollar ?  props.productDetails.skus[props.productDetails.sku_selected]
                  .precioFinalDls:
                props.productDetails.skus[props.productDetails.sku_selected]
                  .precioSN
              )}
            </Typography> 
            <label className={classes.infoText}>Tu precio unitario ({isDollar ? 'USD': 'MXN' })</label>
          </div>
        );
        b.push(
          <div style={{visibility: (props.productDetails.skus[props.productDetails.sku_selected]
            .precioFinalDls ===0?'hidden': '')}}>
            <Typography className={classes.priceDiscount}>
              {getPriceSapFormat(isDollar ? props.productDetails.skus[props.productDetails.sku_selected]
                  .precioFinalDls:
                props.productDetails.skus[props.productDetails.sku_selected]
                  .precioDescuento
              )}
            </Typography>
            <label className={classes.infoText}>Precio Promo ({isDollar ? 'USD': 'MXN' })</label>
          </div>
        );
      } else {
        b.push(
          <div  style={{visibility: (props.productDetails.skus[props.productDetails.sku_selected]
            .precioFinalDls ===0?'hidden': '')}}>
            <Typography className={classes.priceClient}>
              {getPriceSapFormat(isDollar ? props.productDetails.skus[props.productDetails.sku_selected]
                  .precioFinalDls :
                props.productDetails.skus[props.productDetails.sku_selected]
                  .precioDescuento
              )}
            </Typography>
            <label className={classes.infoText}>Tu precio unitario ({isDollar ? 'USD': 'MXN' })</label>
          </div>
        );
      }
    }
    setPrices(b);
  };

  useEffect(() => {
    getPrices();
  }, []);

  return (
    <div>
      <div className={classes.container}>{prices}</div>
    </div>
  );
}
