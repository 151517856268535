import React, { useState, useEffect, useContext, Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import imgFav from "assets/img/Dielsa/Icon_Favoritos.svg";
import { productsService } from "../../../../_services/products.services";

const useStyles = makeStyles((styles) => ({
  title: {
    color: "#0b3196",
    margin: "1.75rem 0 0.875rem",
    marginTop: 0,
    minHeight: 32,
    fontFamily: "Roboto Slab, Times New Roman, serif",
    textDecoration: "none",
  },
  container: {
    width: "100%",
    paddingLeft: 0,
  },
  inStock: {
    opacity: "0.95",
    color: "#40DF00",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "14px",
    textAlign: "justify",
  },
  noStock: {
    color: "#9b9b9b",
    opacity: "0.95",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "14px",
    textAlign: "justify",
  },
  primaryP: {
    color: "#0B3196",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "19px",
    textAlign: "justify",
    margin: 0,
  },
  imgDiv: {
    width: "100%",
    textAlign: "right",
  },
  imgF: {
    width: "40px",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export default function SectionProductStock(props) {
  const classes = useStyles();

  const addToFav = async () => {
    let _r = await productsService.addProductoToWishlist(
      props.product.idSKU,
      "Lista Basica",
      JSON.parse(localStorage.getItem("user")).usuario
        .snu_sn_socio_de_negocio_id
    );
  };
  return (
    <div key={"stk" + props.productDetails.id}>
      {props.gridView === false ? (
        <div className={classes.imgDiv}>
          {/* <img src={imgFav} className={classes.imgF} onClick={() => { addToFav(); }} /> */}
        </div>
      ) : (
        ""
      )}
      <div className={classes.container}>
        <p key={"stock" + props.productDetails.id} className={classes.primaryP}>
          En stock ({props.productDetails.stock})
        </p>
        {props.productDetails.stock > 0
          || props.productDetails.backorder === true
          ? (
            <p key={"disp" + props.productDetails.id} className={classes.inStock}>
              Disponible
            </p>
          )
          : (
            <p key={"disp" + props.productDetails.id} className={classes.noStock}>
              No Disponible
            </p>
          )
        }
      </div>
    </div>
  );
}
