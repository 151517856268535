/*eslint-disable*/
import React, { useState, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

import { Link, Redirect } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

// @material-ui/icons

import fb from "assets/img/Dielsa/Icon_Facebook.svg";
import twt from "assets/img/Dielsa/Icon_Twitter.svg";
import yt from "assets/img/Dielsa/Icon_Youtube.svg";
import li from "assets/img/Dielsa/Icon_LinkedIn.svg";
import ig from "assets/img/Dielsa/Icon_Instagram.svg";

import conekta from "assets/img/Dielsa/Conekta-logo-transparente-png-1024x274.png";
import paypal from "assets/img/Dielsa/PayPal-Logo.wine.png";
import visa from "assets/img/Dielsa/visa_PNG30.png";
import mastercard from "assets/img/Dielsa/mastercard-logo-7.png";
import lock from "assets/img/Dielsa/Icon_Pedidos.svg";

import envios from "assets/img/Dielsa/Icon_EnvioDielsa.svg";
import pe from "assets/img/Dielsa/paqueteexpress.png";

const useStyles = makeStyles((styles) => ({
  container: {
    backgroundColor: "#09287d",
  },
  iconsDiv: {
    width: "100%",
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 10,
    [styles.breakpoints.down("sm")]: {
      width: "95%",
      marginLeft: "2.5%",
    },
    "& p": {
      color: "#FFFFFF",
      fontFamily: "'Centrale Sans Medium'",
      fontStyle: "italic",
      fontSize: 14,
    },
  },
  iconsSocial: {
    width: "100%",
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 10,
    [styles.breakpoints.down("sm")]: {
      width: "95%",
      marginLeft: "2.5%",
    },
  },
  icons: {
    color: "#FFF",
    fontSize: 28,
  },
  iconSpacing: {
    marginLeft: 15,
    marginRight: 15,
  },
  divDerechos: {
    width: "100%",
    textAlign: "center",
    paddingBottom: 15,
  },
  textIcon: {
    color: "#FFF",
    display: "block",
  },
  aBread: {
    color: "#9a9a9a",
  },
  iconImg: {
    width: "38px",
    filter: "brightness(0) invert(1)",
  },
  iconLogo: {
    height: "16px",
    filter: "brightness(0) invert(1)",
    marginLeft: "13px",
    marginRight: "13px",
  },
  iconShip: {
    width: "70px",
    filter: "brightness(0) invert(1)",
    marginLeft: "25px",
    marginRight: "25px",
    marginTop: "-20px",
    marginBottom: "-20px",
  },
  iconPE: {
    width: "150px",
    filter: "brightness(0) invert(1)",
    marginLeft: "0px",
    marginRight: "40px",
    marginTop: "-35px",
    marginBottom: "-35px",
  },
}));

export default function CheckoutFooter(props) {
  const classes = useStyles();
  let d = new Date();
  let n = d.getFullYear();

  return (
    <footer>
      <div className={classes.container}>
        <div className={classes.iconsDiv}>
          <Typography>
            Pago 100% seguro <img src={lock} className={classes.iconLogo} />
          </Typography>
          {/* <img src={pe} className={classes.iconPE} />
          <img src={envios} className={classes.iconShip} /> */}
        </div>

        <div
          className={classes.iconsDiv}
          style={{ borderBottom: "1px solid #6d8caf" }}
        >
          <img src={visa} className={classes.iconLogo} />
          <img src={mastercard} className={classes.iconLogo} />
          <img src={paypal} className={classes.iconLogo} />
          <img src={conekta} className={classes.iconLogo} />
        </div>

        <div className={classes.divDerechos}>
          <Typography variant="caption" className={classes.textIcon}>
            &#169; {n} Dielsa. Todos Los Derechos Reservados
          </Typography>
        </div>
      </div>
    </footer>
  );
}
