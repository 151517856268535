import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import ProductGeneric from "components/ProductGeneric/ProductGeneric";

const ListProductsGeneric = ({ dataProduct, isMobile }) => {
    const history = useHistory();

    const goToLastProduct = () => {
        history.push(`/promociones_productos`);
    };

    return (
        <Box>
            <Box
                sx={{
                    "&:hover": {
                        cursor: "pointer",
                    },
                }}
                onClick={goToLastProduct}
            >
                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: 600,
                        color: "#0b3196",
                        paddingTop: 1,
                        paddingBottom: 2,
                        fontFamily: "Centrale Sans Medium",
                    }}
                >
                   Próximamente
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {dataProduct.productsAll &&
                dataProduct.productsAll.map((item) => 
                    <Grid key={item.id} item  xs={6} sm={4} md={3} lg={2.4} >
                        <ProductGeneric item={item} isMobile={isMobile}/>
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}

export default ListProductsGeneric