import React, { useContext, createContext, } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((styles) => ({
    title: {
        fontSize: 14,
        color: "#000",
        fontWeight: 500,
        fontFamily: "'Centrale Sans Medium'",
        [styles.breakpoints.down("sm")]: {
            fontSize: "14px",
        },
    },
    container: {
        width: "100%",
        marginTop: 5,
        marginBottom: 20,
    }
}));


export default function SectionProductClientCommentText(props) {
    const classes = useStyles();
    return (
        <div >
            <div className={classes.container}>
                <Typography className={classes.title}>{props.productDetails.text}</Typography>
            </div>
        </div>
    );
}
