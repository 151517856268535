import React, { useState, useEffect } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { searchServices } from "_services/search.services"; 
import { API_URL } from "_services/api.url.back.img";
import CategoryProductSmall from "views/Categories/CategoryProductCard/CategoryProductSmall";


const ModalSearchAndAddProduct = ({ showModal, handleClose, title, handleFunction }) => {
    const [page, setPage] = useState(0);
    const [pagesize] = useState(window.innerWidth >= 1920 ? 15:12);
    const [searchValue, setSearchValue] = useState("");
    const [userIsLogged, setUserIsLogged] = useState(false);
    const [totalSkus, setTotalSkus] = useState(null);
    const [dataProduct, setDataProduct] = useState([]);
    const [loading, setLoading] = useState(false);

    const buscarProductos = async (searchValue, pagina, _pageSize) => {
        setLoading(true);
        let skusArray = [];
        let idSocioNegocio = process.env.REACT_APP_API_USER_B2B;
        const order = 'fecha lanzamienta';
        const orderType = "asc";
        const clientData = JSON.parse(localStorage.getItem("client"));
        if (clientData != null) {
            idSocioNegocio = clientData.listaSnbyid[0].sn_socios_negocio_id;
            setUserIsLogged(true);
        } else {
            setUserIsLogged(false);
        }

        skusArray = pagina > 0 ? dataProduct : []; 

        const searchSKUs = await searchServices.searchSKUs(
            pagina,
            _pageSize,
            searchValue,
            order,
            orderType,
            idSocioNegocio
        );
        const productos = searchSKUs?.mainConsultaProductos?.rows;
        const countProductos = searchSKUs.mainConsultaProductos.count;
        setTotalSkus(countProductos);

        for (let index = 0; index < productos.length; index++) {
            const sku = productos[index];
            let urlImagen =
              "http://wws.com.pa/wp-content/plugins/wordpress-ecommerce/marketpress-includes/images/default-product.png";
            if (sku.imagen_productos.length) {
              let _skuimg = sku.imagen_productos;
              _skuimg.sort(function (a, b) {
                return a.imgprod_nombre_archivo.localeCompare(
                  b.imgprod_nombre_archivo
                );
              });
              urlImagen = _skuimg[0].imgprod_ruta_archivo.includes('https://ftp3.syscom.mx')? _skuimg[0].imgprod_ruta_archivo : `${API_URL}${_skuimg[0].imgprod_ruta_archivo.split("public")[1]}`;
            }
            let descuento = 0;
            if (sku.precioFinal < sku.precioBasePorListaPrecio) {
              descuento = sku.precioBasePorListaPrecio - sku.precioFinal;
            }
            const skuInfo = {
              productoId: sku.productoPadreId,
              imagen: urlImagen,
              nombre: sku.prod_nombre.toLowerCase(),
              sku: sku.prod_sku,
              nombre_extranjero: sku.prod_nombre_extranjero,
              stock: sku.prod_total_stock,
              precioLista: sku.precioFinal,
              precioListaDls: sku.precioFinaldls,
              descuento: descuento, 
              precioFinal: sku.precioMenosDescuento,
              precioFinalDls: sku.precioMenosDescuentodls,
              skuId: sku.prod_producto_id,
              prod_tipo_precio_base: sku.prod_tipo_precio_base,
              precioPromocionDielsaBool: sku.precioPromocionDielsaBool,
              tipoPromocionFinal: sku.tipoPromocionFinal,
              precioMenosDescuento: sku.precioMenosDescuento,
              DescuentoDielsaFijo: sku.DescuentoDielsaFijo,
              brand: sku.mar_nombre,
              bullets: sku.prod_viñetas,
              backorder: sku.aplicaBackOrder,
              prod_altura: sku.prod_altura,
              prod_ancho: sku.prod_ancho,
              prod_longitud: sku.prod_longitud,
              prod_peso: sku.prod_peso,
              prod_volumen: sku.prod_volumen,
              externalProductId: sku.externalProductId
            };
            skusArray.push(skuInfo);
        }
        setLoading(false);
        setDataProduct(skusArray);
    }

    const getProductosPartidaSiguiente = () => {
        let _p = page;
        _p++;
        setPage(_p);
        console.log(_p);
        buscarProductos(searchValue, _p, pagesize);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setPage(0);
        buscarProductos(searchValue, 0, pagesize);
    }

    useEffect(() => {
        if (searchValue !== null) {
            setPage(0);
            buscarProductos(searchValue, 0, pagesize);
        }
    }, [pagesize]);

    useEffect(() => {
        // buscarProductos(searchValue, 0, pagesize);
    }, []);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={showModal}
            onClose={handleClose}
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <Box sx={{ position: 'absolute', right: 0, top: 0, pr: 1, pt: 1 }}>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent>
                <Box
                    noValidate
                    component="form"
                    sx={{
                        width: '80%',
                        display: 'flex',
                        m: 'auto',
                    }}
                    onSubmit={handleSubmit}
                >
                    <TextField
                        label="Buscar producto"
                        variant="outlined"
                        fullWidth
                        size="small"
                        autoComplete="off"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        sx={{ marginLeft: 1, backgroundColor: '#0C2D9C' }}
                        loading={loading}
                    >
                        <SearchIcon />
                    </LoadingButton>
                </Box>
                {dataProduct.length > 0 ? <Box
                    sx={{
                        marginTop: 2,
                        paddingRight: 2,
                        paddingLeft: 2,
                        overflowY: 'scroll',
                        maxHeight: '450px',
                        overflowX: 'hidden',
                        '&::-webkit-scrollbar': {
                            width: '8px !important',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888 !important',
                            borderRadius: '6px !important',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#eee !important',
                        },
                    }}
                >
                    {dataProduct.length > 0 && dataProduct.map((r, index) => {
                        return (<CategoryProductSmall
                            product={r}
                            key={index}
                            gridView={true}
                            userIsLogged={userIsLogged}
                            changeFuction={true}
                            handleFunction={handleFunction}
                        />)
                    })}
                    {dataProduct.length < totalSkus && (
                        <div style={{ paddingTop: 6, display: 'flex', justifyContent: 'center' }}>
                            <LoadingButton
                                variant="contained"
                                onClick={getProductosPartidaSiguiente}
                                loading={loading}
                                sx={{ backgroundColor: '#0C2D9C' }}
                            >
                                Ver más productos
                            </LoadingButton>
                        </div>
                    )}
                </Box>
                : <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        paddingTop: 6
                    }}>
                    <Typography variant="body1" gutterBottom sx={{ color: '#595959' }}>
                        Lista de producto vacía, ¡empieza a buscar tus productos!
                    </Typography>
                    <ManageSearchIcon sx={{ color: '#595959', fontSize: 60 }} />
                </Box>}
            </DialogContent>
        </Dialog>
    )
}

export default ModalSearchAndAddProduct;