import React, { useState, useEffect, useContext, Fragment } from "react";
import { useHistory } from "react-router-dom";

import { checkoutService } from "_services/checkout.service";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import "assets/custom/ProductInput.css";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";

import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import toast from "react-hot-toast";
import Alerts from "ui/Alerts/Alerts";
import { useAuth } from "hooks/useAuth";
import {addCartBusinnesPartner} from "utils/addCartBusinnesPartner";
import {addCartLocal } from "utils/addCartLocal";
import { accountService } from "services/account/account.service";
const useStyles = makeStyles((styles) => ({
  container: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 30,
  },
  containerGrid: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  btnCartAdd: {
    width: "100%",
    cursor: "pointer",
    height: 35,
    backgroundColor: "#ffffff",
    color: "#0C2D9C",
    border: "1px solid #0C2D9C",
    fontSize: 16,
    fontFamily: "'Centrale Sans Medium'",
    borderRadius: 3,
    "&:hover": {
      backgroundColor: "#0C2D9C",
      color: "#ffffff",
    },
  },
  addDiv: {
    width: "55%",
    display: "inline-block",
    verticalAlign: "top",
  },
  addDivGrid: {
    width: "100%",
    display: "block",
    verticalAlign: "top",
  },
  inputDiv: {
    width: "45%",
    display: "flex",
    float: "left",
  },
  inputDivDesktop: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    verticalAlign: "top",
    margin: "5px 0",
  },
  btnInput: {
    cursor: "pointer",
    backgroundColor: "#ffffff",
    color: "#0C2D9C",
    border: "1px solid #eaeaea",
    fontWeight: "800",
    padding: "0px",
    maxHeight: "40px",
    width: "32px",
    height: "35px",
    fontSize: "18px",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#0C2D9C",
      color: "#ffffff",
    },
  },
  btnInputLeft: {
    borderTopLeftRadius: "3px",
    borderBottomLeftRadius: "3px",
  },
  btnInputRight: {
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
  },
  inpCant: {
    width: "30px",
    height: "35px",
    textAlign: "center",
    border: "1px solid #eaeaea",
    fontSize: "14px",
    borderRadius: 0,
    color: "#444444",
  },
}));

export default function SectionProductButtonAddCart(props) {
  const classes = useStyles();
  const history = useHistory();
  const {isLogged, getUserData, setOpenAlertF, setCarritoItems, carritoItems, getAccountIsActive} = useAuth();
  const dataUser= getUserData();
  const isActive = isLogged ? getAccountIsActive() : false
  const [openAlert,setOpenAlert]= useState(false)
  const [typeAlert, setTypeAlert] = useState('')
  const [message, setMessage]= useState('')
  const [cantidad, setCantidad] = useState(1);
  const [open, setOpen] = useState(false);
 // const [isLogged, setIslogged] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
    type: "",
  });

  const decreaseProductCart = () => {
    let _c = cantidad - 1;
    if (_c > 0) {
      setCantidad(_c);
    }
  };



  const increaseProductCart = () => {
    let _c = cantidad + 1;
    if (_c > 0) {
      setCantidad(_c);
    }
  };

  const changeAmountCart = (_c) => {
    if (_c > 0) {
      setCantidad(_c);
    }
  };
  const addToCart =  (idProduct, prod) =>{
   
    if(isLogged){
      //TODO trabajar este metodo
       accountService.addCartClientes({ type: 2, userId: dataUser.usuario.snu_usuario_snu_id, productId: prod.skuId})
       //accountService.getClientsInteractionsProduct({type:2, productId: prod.skuId})
    addCartBusinnesPartner(cantidad, prod, dataUser.usuario.snu_sn_socio_de_negocio_id,idProduct).then(({ message, type,isAmount }) => {
    
      if(!isAmount) {setCarritoItems(carritoItems +1 ); }
     setOpenAlertF({ open:true, type:type,message:message });
    })
  }else {
    setOpenAlertF({ open:false, type:'',message:'' });

    addCartLocal(cantidad, prod).then(({ message, type,isAmount }) => {
    
      if(!isAmount) {setCarritoItems(carritoItems +1 ); }
     setOpenAlertF({ open:true, type:type,message:message });
    })
    // 
  }
  
  }





  return (
    <div>
      <Alerts setOpenAlert={setOpenAlert} typeAlert={typeAlert} openAlert={openAlert} message={message} setMessage={setMessage}/>
      <div
        className={
          props.gridView === false ? classes.container : classes.containerGrid
        }
      >
        
        {props.gridView === false ? (
          <div className={classes.inputDiv}>
            <button
              variant="contained"
              className={classes.btnInput + " " + classes.btnInputLeft}
              disableElevation
              onClick={() => {
                decreaseProductCart();
              }}
            >
              -
            </button>
            <input
              id={"inpCant" + props.productDetails.id}
              value={cantidad}
              onChange={(ev) => {
                changeAmountCart(ev.target.value);
              }}
              size="small"
              className={classes.inpCant + " inp-center"}
              variant="outlined"
            />
            <button
              variant="contained"
              className={classes.btnInput + " " + classes.btnInputRight}
              disableElevation
              onClick={() => {
                increaseProductCart();
              }}
            >
              +
            </button>
          </div>
        ) : (
          <div className={classes.inputDivDesktop}>
            <button
              variant="contained"
              className={classes.btnInput + " " + classes.btnInputLeft}
              disableElevation
              onClick={() => {
                decreaseProductCart();
              }}
            >
              -
            </button>
            <input
              id={"inpCant" + props.productDetails.id}
              value={cantidad}
              onChange={(ev) => {
                changeAmountCart(ev.target.value);
              }}
              size="small"
              className={classes.inpCant + " inp-center"}
              variant="outlined"
            />
            <button
              variant="contained"
              className={classes.btnInput + " " + classes.btnInputRight}
              disableElevation
              onClick={() => {
                increaseProductCart();
              }}
            >
              +
            </button>
          </div>
        )}
        <div
          className={
            props.gridView === false ? classes.addDiv : classes.addDivGrid
          }
        >
          {(isLogged && (
            isActive ? 
          <button
            className={classes.btnCartAdd}
            variant="contained"
            disableElevation
            onClick={() => {
              addToCart(props.productDetails.skuId, props.productDetails);
            }}
           > 
            {
            ( 
                props.productDetails.prod_peso == 0 ||
                props.productDetails.prod_volumen == 0 ||
                props.productDetails.precioFinal ==0 ||
                (props.productDetails.stock== 0  &&
                props.productDetails.backorder=== false) ? "Añadir a cotización":  "Añadir al carrito" )
           
             }
          </button> :""
        ))}
        {(!isLogged && (
          
          <button
            className={classes.btnCartAdd}
            variant="contained"
            disableElevation
            onClick={() => {
              addToCart(props.productDetails.skuId, props.productDetails);
            }}
           > 
            {
            "Añadir a cotización"
           
             }
          </button> 
        ))}
        </div>
      </div>
    </div>
  );
}
