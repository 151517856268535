import React, { useState, useEffect, Fragment } from "react";
import clsx from "clsx";
import { orderService } from "../../../_services/order.service";
import { useHistory } from "react-router-dom";
import { API_URL } from "../../../_services/api.url.back.img";
import { getPriceSapFormat } from "helpers/formatPrice";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { useAuth } from "hooks/useAuth";
// core components
import useCheckMobileScreen from "components/Mobile/Mobile.js";
import SectionBreadcrumb from "views/Home/Sections/SectionBreadcrumb.js";

const jerarquiaDeStatus = [
  {
    valor: 1,
    codigo: "1000107",
    statusFront: "Pendiente de Confirmar",
    statusBack: "PENDIENTE",
  },
  {
    valor: 2,
    codigo: "1000109",
    statusFront: "En Proceso",
    statusBack: "ABIERTA",
  },
  {
    valor: 3,
    codigo: "1000112",
    statusFront: "En Proceso",
    statusBack: "APROBADA",
  },
  {
    valor: 4,
    codigo: "¿?",
    statusFront: "En Proceso",
    statusBack: "PROCESO",
  },
  {
    valor: 5,
    codigo: "1000110",
    statusFront: "Entregado",
    statusBack: "CERRADA",
  },
];

// jerarquia valores generales: 1000185, 1000184, 1000187, 1000186,
const valoresDeStatus = [
  {
    valor: 0,
    codigo: "1000107",
    statusFront: "Pendiente de Confirmar",
    statusBack: "PENDIENTE",
  },
  {
    valor: 0,
    codigo: "1000108",
    statusFront: "Cancelado",
    statusBack: "CANCELADA",
  },
  {
    valor: 0,
    codigo: "1000109",
    statusFront: "En Proceso",
    statusBack: "ABIERTA",
  },
  {
    valor: 0,
    codigo: "1000112",
    statusFront: "En Proceso",
    statusBack: "APROBADA",
  },
  {
    valor: 0,
    codigo: "¿?",
    statusFront: "En Proceso",
    statusBack: "PROCESO",
  },
  {
    valor: 0,
    codigo: "1000110",
    statusFront: "Entregado",
    statusBack: "CERRADA",
  },
  {
    valor: 0,
    codigo: "1000111",
    statusFront: "En Proceso",
    statusBack: '"ELECCION PENDIENTE"',
  },
  {
    valor: 0,
    codigo: "1000113",
    statusFront: "Cancelado",
    statusBack: "RECHAZADA",
  },
  {
    valor: 0,
    codigo: "1000114",
    statusFront: "En Proceso",
    statusBack: '"REPLICA PENDIENTE"',
  },
  {
    valor: 0,
    codigo: "1000115",
    statusFront: "En Proceso",
    statusBack: '"REPLICA ERROR"',
  },
  {
    valor: 0,
    codigo: "1000116",
    statusFront: "En Proceso",
    statusBack: '"AUTORIZACION PENDIENTE"',
  },
  {
    valor: 4,
    codigo: "1000186",
    statusFront: "Entregado",
    statusBack: "ENTREGADA",
  },
  {
    valor: 3,
    codigo: "1000187",
    statusFront: "En tránsito",
    statusBack: "TRANSITO",
  },
  {
    valor: 2,
    codigo: "1000184",
    statusFront: "En proceso",
    statusBack: "PROCESO",
  },
  {
    valor: 1,
    codigo: "1000185",
    statusFront: "Pendiente de Confirmar",
    statusBack: "PENDIENTE",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    margin: "auto",
    width: "95%",
    paddingBottom: 30,
  },
  spaceDiv: {
    marginTop: 15,
    marginBottom: 15,
  },
  linkBack: {
    fontFamily: "'Centrale Sans Medium'",
    color: "#0B3196",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "19px",
    textAlign: "center",
    paddingLeft: "30px",
    position: "relative",
  },
  linkBackArrow: {
    position: "absolute",
    left: "5px",
    bottom: "-2px",
  },
  title: {
    fontFamily: "'Centrale Sans Bold'",
    textTransform: "uppercase",
    fontSize: 24,
    lineHeight: "29px",
    textAlign: "justify",
    color: "#0b3196",
    paddingTop: 20,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  title2: {
    fontFamily: "'Centrale Sans Bold'",
    textTransform: "uppercase",
    fontSize: 20,
    lineHeight: "29px",
    textAlign: "justify",
    color: "#9b9b9b",
    paddingTop: 20,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  titleDivider: {
    border: "1px solid #0B3196",
    width: "95%",
    margin: "auto",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: "30px 0",
  },
  //formData
  formData: {
    backgroundColor: "#F5F5F5",
    borderRadius: 2,
    margin: "10px 0",
    border: "1px solid #EAEAEA",
    maxHeight: 500,
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#FFFFFF",
      border: "none",
    },
  },
  formDataTitle: {
    color: "#0C2D9C",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 22,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "27px",
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  formDataTitleDes: {
    color: "#0C2D9C",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 22,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "27px",
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  formDataSubTitleDes: {
    color: "#9b9b9b",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 18,
    textAlign: "justify",
    padding: "0 10px",
  },
  formDataSubTitleResp: {
    color: "#0B3196",
    fontFamily: "'Centrale Sans Light'",
    fontSize: 16,
    textAlign: "justify",
    padding: 5,
  },
  formDataSubTitleStatus: {
    color: "#0C2D9C",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 22,
    textAlign: "justify",
    padding: 10,
    "& span": {
      color: "#40DF00",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  gridContent: {
    paddingBottom: 30,
  },
  buttonDelete: {
    border: "none",
    color: "#8e8a8a",
    backgroundColor: "transparent",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    margin: "auto",
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  buttonBack: {
    border: "1px solid",
    color: "#0C2D9C",
    backgroundColor: "transparent",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    margin: 0,
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  buttonUpdate: {
    border: "1px solid",
    color: "#0C2D9C",
    backgroundColor: "transparent",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    margin: "auto",
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  buttonSemaforo: {
    border: "none",
    backgroundColor: "#0C2D9C",
    color: "#ffffff",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    margin: 10,
    marginLeft: 0,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 5,
    },
  },
  inputs: {
    width: "100%",
    border: "1px solid #EAEAEA",
    borderRadius: "2px",
    backgroundColor: "#FFFFFF",
  },
  //alerts
  alerts: {
    color: "#FFFFFF",
    backgroundColor: "#40DF00",
    fontFamily: "'Centrale Sans Light'",
    fontSize: 22,
    "& .MuiAlert-icon": {
      color: "#FFFFFF",
    },
    "&.MuiAlert-standardError": {
      backgroundColor: "#DF2B2B",
    },
  },
  //defaultAddress
  semaforoGrid: {
    margin: "35px 0 0",
  },
  semaforoDiv: {
    border: "none",
    backgroundColor: "rgba(60,219,192,0.14)",
    padding: 20,
    margin: "10px 0",
    borderRadius: 3,
  },
  filterGrid: {
    border: "1px solid #EAEAEA",
    backgroundColor: "#F5F5F5",
    borderRadius: 2,
    height: 55,
  },
  defaultAlias: {
    color: "#0C2D9C",
    fontFamily: "'Centrale Sans Bold'",
    fontSize: 18,
    fontWeight: "bold",
    letterSpacing: 0,
    lineHeight: "23px",
  },
  defaultAddress: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    fontWeight: "500",
    letterSpacing: 0,
    lineHeight: "19px",
  },
  buttonBackDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "10px 0",
  },
  actionButtonsDes: {
    display: "inline-flex",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-around",
    },
  },
  actionButtonsCleanFilter: {
    display: "flex",
    justifyContent: "flex-end",
  },
  actionButtonsMov: {},
  //selecte
  formControl: {
    minWidth: "25%",
    [theme.breakpoints.down("sm")]: {
      minWidth: "50%",
    },
  },
  selects: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #e8e8e8",
    borderRadius: 4,
    height: 40,
    fontFamily: "'Centrale Sans Regular'",
    position: "relative",
    top: 6,
    left: 6,
  },
  dowloadLinks: {
    borderRadius: 2,
    backgroundColor: "#40DF00",
    color: "#ffffff",
    padding: "5px 10px",
    marginRight: 5,
    "&:hover": {
      backgroundColor: "#40DF00",
      color: "#ffffff",
    },
  },
  billStatus: {
    color: "#40DF00",
  },
  //table destopk
  tableHead: {
    backgroundColor: "#0B3196",
    color: "#FFFFFF",
    padding: "10px 5px 0 10px",
    fontFamily: "'Centrale Sans Regular'",
    border: "none",
    fontSize: 16,
  },
  tableRow: {
    margin: "5px 0",
    padding: "10px 5px 0 10px",
    fontFamily: "'Centrale Sans Regular'",
    border: "none",
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      padding: "2px 0 2px 4px",
    },
  },
  productName: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 14,
    textAlign: "justify",
    paddingRight: 15,
  },
  prodQty: {
    fontFamily: "'Centrale Sans Light'",
    marginLeft: 5,
  },
  basePrice: {
    textDecoration: "line-through",
    fontFamily: "'Centrale Sans Light'",
    fontSize: 12,
  },
  totalPrice: {
    whiteSpace: "nowrap",
    fontFamily: "'Centrale Sans Bold'",
    fontSize: 16,
    marginTop: 10,
  },
  costDiv: {
    float: "right",
    width: 400,
    border: "1px solid #EAEAEA",
    borderRadius: 3,
    backgroundColor: "#F5F5F5",
    padding: 15,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      float: "none",
    },
  },
  costData: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "30px",
    "& .costResponse": {
      textAlign: "end",
    },
    margin: "10px 0",
    padding: 15,
  },
  dataOrderDiv: {
    margin: 10,
    maxWidth: "22%",
  },
  prodRow: {
    padding: 10,
  },
  content: {
    margin: "15px 0",
  },
  paper: {
    backgroundColor: theme.palette.primary.light,
    boxShadow: "none",
    borderRadius: 0,
    padding: "15px",
    fontSize: "15px",
    "& h6": {
      fontFamily: "'Centrale Sans Medium'",
      color: "#000000",
      fontSize: "17px",
    },
  },
  paperTitle: {
    borderRadius: 2,
    backgroundColor: "#0B3196",
    padding: 10,
    "& p": {
      color: "#ffffff",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: 18,
      fontWeight: 500,
      textAlign: "center",
    },
  },
  subTitle: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    fontWeight: 500,
    marginTop: 15,
  },
  divData: {
    paddingRight: 10,
    textAlign: "left",
  },
  profileLink: {
    color: "#0B3196",
    "&:hover, &:focus": {
      color: "#0B3196",
    },
  },
  //style summary data
  costData: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "30px",
    "& .costResponse": {
      textAlign: "end",
    },
    margin: "10px 0",
    padding: 15,
    backgroundColor: "#F5F5F5",
    width: "100%",
  },
  //style product list table
  paper: {
    backgroundColor: theme.palette.primary.light,
    boxShadow: "none",
    borderRadius: 0,
    padding: "15px",
    fontSize: "15px",
    "& h6": {
      fontFamily: "'Centrale Sans Medium'",
      color: "#000000",
      fontSize: "17px",
    },
    textAlign: "center",
  },
  personalData: {
    backgroundColor: "#0B3196",
    color: "#ffffff",
    "& h6": {
      fontFamily: "'Centrale Sans Medium'",
      color: "#ffffff",
    },
  },
  productsList: {
    maxHeight: 420,
    overflowY: "auto",
    borderRadius: "0 0 2px 2px",
    backgroundColor: "#F8F8F8",
  },
  prodRow: {
    margin: "auto",
    width: "96%",
    marginTop: 10,
    marginBottom: 10,
  },
  productName: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 14,
    textAlign: "justify",
    paddingRight: 15,
    marginBottom: 10,
  },
  prodQty: {
    fontFamily: "'Centrale Sans Light'",
    fontSize: 16,
    textAlign: "center",
  },
  basePrice: {
    textDecoration: "line-through",
    fontFamily: "'Centrale Sans Light'",
    fontSize: 14,
    textAlign: "center",
  },
  listPrice: {
    fontFamily: "'Centrale Sans Light'",
    fontSize: 16,
    textAlign: "center",
  },
  listPriceTxt: {
    fontFamily: "'Centrale Sans Light'",
    fontSize: 14,
    textAlign: "center",
  },
  totalPrice: {
    fontFamily: "'Centrale Sans Bold'",
    fontSize: 16,
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  divisaTitle: {
    fontWeight: 600,
  },
}));

export default function OrderDetail(props) {
  const classes = useStyles();
  let history = useHistory();

  let isMobile = useCheckMobileScreen();

  const {setOpenAlertF}= useAuth();
  const [order, setOrder] = useState({});
  const [orderData, setOrderData] = useState({});
  //const [orderDetail, setOrderDetail] = useState({});
  const [subtotal, setSubtotal] = useState(0);
  const [taxRate, setTaxRate] = useState(0.16);
  const [formasDePago, setFormasDePago] = useState([]);
  const [metodoDePago, setMetodoDePago] = useState(null);
  const [statusOrder, setStatusOrder] = useState("");
  const [statusOrderCMM, setStatusOrderCMM] = useState("");
  const [entregadoSAP, setEntregadoSAP] = useState(false);

  useEffect(() => {
    props.setDataBreadCrumb(() => [
      {
        name: "INICIO",
        link: "/",
      },
      {
        name: "MIS PEDIDOS",
        link: "",
      }
    ]);
  }, []);

  const getOrderById = async () => {
    const client_order_response = await orderService.getOrderById(
      props.selectedOrderId
    );
    const orders_status = await orderService.getOrdersStatusST();
    setEntregadoSAP(
      client_order_response.constCompraFinalizada.cf_sap_entregado
    );
    let _o = client_order_response.constCompraFinalizada;
    if (
      _o.cf_estatus_orden == _o.cf_estatus_orden_usd ||
      _o.cf_estatus_orden_usd == null
    ) {
      const getStatusFront = jerarquiaDeStatus.find(
        (item) => item.codigo == _o.cf_estatus_orden
      );
      if (getStatusFront) {
        _o.statusCMM = getStatusFront?.statusFront;
      } else {
        const getStatusFront = valoresDeStatus.find(
          (item) => item.codigo == _o.cf_estatus_orden
        );
        _o.statusCMM = getStatusFront?.statusFront;
      }
    } else if (_o.cf_estatus_orden == null && _o.cf_estatus_orden_usd != null) {
      const getStatusFront = valoresDeStatus.find(
        (item) => item.codigo == _o.cf_estatus_orden_usd
      );
      if (getStatusFront) {
        _o.statusCMM = getStatusFront?.statusFront;
      } else {
        _o.statusCMM = "Revisando status...";
      }
    } else if (_o.cf_estatus_orden != null && _o.cf_estatus_orden_usd != null) {
      const jerarquiaStatus = jerarquiaDeStatus.find(
        (item) => item.codigo == _o.cf_estatus_orden
      );
      const jerarquiaStatusUsd = jerarquiaDeStatus.find(
        (item) => item.codigo == _o.cf_estatus_orden_usd
      );
      if (jerarquiaStatus && jerarquiaStatusUsd) {
        if (jerarquiaDeStatus.valor >= jerarquiaStatusUsd) {
          _o.statusCMM = jerarquiaStatus.statusFront;
        } else {
          _o.statusCMM = jerarquiaStatusUsd.statusFront;
        }
      } else if (jerarquiaStatus && !jerarquiaStatusUsd) {
        _o.statusCMM = jerarquiaStatus.statusFront;
      } else if (!jerarquiaStatus && jerarquiaStatusUsd) {
        _o.statusCMM = jerarquiaStatusUsd.statusFront;
      } else if (!jerarquiaStatus && !jerarquiaStatusUsd) {
        const getStatusFrontValor = valoresDeStatus.find(
          (item) => item.codigo == _o.cf_estatus_orden
        );
        const getStatusFrontValorUsd = valoresDeStatus.find(
          (item) => item.codigo == _o.cf_estatus_orden_usd
        );
        if (getStatusFrontValor.valor >= getStatusFrontValorUsd.valor) {
          _o.statusCMM = getStatusFrontValor.statusFront;
        } else {
          _o.statusCMM = getStatusFrontValorUsd.statusFront;
        }
      }
    }
    setStatusOrder(_o.statusCMM);
    let orderIsInTransit = false;
    for (const sku of client_order_response.constCompraFinalizada
      .ProductoCompraFinalizada) {
      if (sku.pcf_linea_estatus_sap === "En tránsito") {
        orderIsInTransit = true;
      }
    }
    if (orderIsInTransit) {
      setStatusOrder("En tránsito");
    }

    let orderHasParcialidad = false;
    for (const sku of client_order_response.constCompraFinalizada
      .ProductoCompraFinalizada) {
      if (
        sku.pcf_linea_estatus_sap.includes("Parcialidad") &&
        sku.pcf_linea_estatus_sap != "En proceso" &&
        sku.pcf_linea_estatus_sap != "Entregado"
      ) {
        orderHasParcialidad = true;
      } else {
        orderHasParcialidad = false;
        break;
      }
    }
    if (orderHasParcialidad) {
      setStatusOrder("En tránsito");
    }

    let orderIsInProcess = false;
    for (const sku of client_order_response.constCompraFinalizada
      .ProductoCompraFinalizada) {
      if (sku.pcf_linea_estatus_sap === "En proceso") {
        orderIsInProcess = true;
      }
    }
    if (orderIsInProcess) {
      setStatusOrder("En proceso");
    }
    let orderHasEntregadoParcialidad = false;
    for (const sku of client_order_response.constCompraFinalizada
      .ProductoCompraFinalizada) {
      if (
        sku.pcf_linea_estatus_sap.includes("Entregado") &&
        sku.pcf_linea_estatus_sap.includes("Parcialidad")
      ) {
        orderHasEntregadoParcialidad = true;
      }
    }
    if (orderHasEntregadoParcialidad) {
      setStatusOrder("En proceso");
    }

    let todosTransitoParcialidad = false;
    for (const sku of client_order_response.constCompraFinalizada
      .ProductoCompraFinalizada) {
      if (
        sku.pcf_linea_estatus_sap.includes("En tránsito") &&
        sku.pcf_linea_estatus_sap.includes("Parcialidad")
      ) {
        todosTransitoParcialidad = true;
      } else {
        todosTransitoParcialidad = false;
        break;
      }
    }
    if (todosTransitoParcialidad) {
      setStatusOrder("En proceso");
    }
    setOrderData(client_order_response.constCompraFinalizada);
    setOrder(client_order_response.constCompraFinalizada);

    setSubtotal(client_order_response.constCompraFinalizada.cf_orden_subtotal);

    setMetodoDePago(
      client_order_response.constCompraFinalizada.cf_sap_forma_pago_codigo
    );

    const formasDePagoResponse = await orderService.getFormasDePago();
    setFormasDePago(formasDePagoResponse.constSapFormasPago);

    for (
      let index = 0;
      index < formasDePagoResponse.constSapFormasPago.length;
      index++
    ) {
      const element = formasDePagoResponse.constSapFormasPago[index];
      if (
        element.sfp_clave ==
        client_order_response.constCompraFinalizada.cf_sap_forma_pago_codigo
      ) {
        setMetodoDePago(element.sfp_clave);
        break;
      }
    }
  };

  const getVisualStatusOrder = (statusSAP, orderDetails) => {
    switch (statusSAP.toUpperCase()) {
      case "TRANSITO":
        return "En transito";
      case "PENDIENTE":
        return "Pendiente de confirmar";
      case "CANCELADA":
        return "Cancelada";
      case "ABIERTA":
        return "En Proceso";
      case "CERRADA":
        return "Entregado";
      case "ELECCION PENDIENTE":
        return "Pendiente de confirmar";
      case "APROBADA":
        return "En Proceso";
      case "RECHAZADA":
        return "Rechazada";
      case "REPLICA PENDIENTE":
        return "Pendiente de confirmar";
      case "REPLICA ERROR":
        return "Pendiente de confirmar";
      case "AUTORIZACION PENDIENTE":
        return "Pendiente de confirmar";
    }
    return "Pendiente de procesar";
  };

  const getVisualStatusLine = (lineStatus, entregados, cantidadTotal) => {
    lineStatus = lineStatus === null ? null : lineStatus.toUpperCase();
    switch (lineStatus) {
      case null:
        return "Pendiente de confirmar";
      case "ABIERTO":
        if (statusOrderCMM === "PENDIENTE") {
          return "Pendiente de confirmar";
        }
        if (entregados === null || entregados === 0) {
          return "En Proceso";
        }
        if (entregados < cantidadTotal) {
          return "En Tránsito";
        }
        break;
      case "CERRADO":
      case "CERRADA":
        if (entregadoSAP === true) {
          if (entregados < cantidadTotal) {
            return (
              "Entregado (Parcialidad " + entregados + "/" + cantidadTotal + ")"
            );
          }
          return "Entregado";
        }
        return "En Tránsito";
      case "PICKING PENDIENTE":
        return "En Tránsito";
      case "FACTURADA":
        return "Facturada";
      case "ENTREGADA":
        return "Entregado";
      case "LIBERADA":
        return "En Tránsito";
      case "PICKEADA":
        return "Entregado";
      case "PARCIALMENTE PICKEADA":
        return (
          "Entregado (Parcialidad " + entregados + "/" + cantidadTotal + ")"
        );
      case "NA":
        return "Pendiente de confirmar";
    }
    return "ok";
  };

  const convertDate = (dateTime) => {
    let date = dateTime.split("T");
    let _d = date[0].split("-");
    let _m = "";
    switch (_d[1]) {
      case "01":
      case "1":
        _m = "Enero";
        break;
      case "02":
      case "2":
        _m = "Febrero";
        break;
      case "03":
      case "3":
        _m = "Marzo";
        break;
      case "04":
      case "4":
        _m = "Abril";
        break;
      case "05":
      case "5":
        _m = "Mayo";
        break;
      case "06":
      case "6":
        _m = "Junio";
        break;
      case "07":
      case "7":
        _m = "Julio";
        break;
      case "08":
      case "8":
        _m = "Agosto";
        break;
      case "09":
      case "9":
        _m = "Septiembre";
        break;
      case "10":
        _m = "Octubre";
        break;
      case "11":
        _m = "Noviembre";
        break;
      case "12":
        _m = "Diciembre";
        break;
    }
    return _d[2] + "/" + _m + "/" + _d[0];
  };

  useEffect(() => {
    if (!localStorage.getItem("user") && !localStorage.getItem("client")) {
      //redirect to login
      history.push("/login");
    } else {
      getOrderById();

      if (
        JSON.parse(localStorage.getItem("client")).sn_cmm_tipo_impuesto ===
        1000084
      ) {
        setTaxRate(0.08);
      }
    }
  }, []);

  useEffect(() => {}, [order]);

  return (
    <div className={classes.root}>
     

      <div className={classes.spaceDiv}>
        <SectionBreadcrumb route={props.dataBreadCrumb} />
        <Typography className={classes.title2}>
          PEDIDO NO. {order.cf_compra_numero_orden}{" "}
          {order.cf_referencia}
        </Typography>
        {isMobile ? <Divider className={classes.titleDivider} /> : ""}
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <div className={classes.formData}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Grid container>
                  <Grid item xs={isMobile ? 6 : 12}>
                    <Typography className={classes.formDataSubTitleStatus}>
                      {!isMobile ? "Información pedido   " : ""}
                      <span>{statusOrder}</span>
                    </Typography>
                  </Grid>
                  {!isMobile ? (
                    <Grid item xs={12}>
                      <Typography className={classes.formDataSubTitleDes}>
                        {`No. de referencia interno `}
                        <span className={classes.formDataSubTitleResp}>
                          {order.cf_compra_numero_orden}
                        </span>{" "}
                        <span className={classes.formDataSubTitleResp}>
                          {order.cf_referencia}
                        </span>
                      </Typography>
                    </Grid>
                  ) : (
                    ""
                  )}
                  {isMobile ? (
                    <Fragment>
                      <Grid item xs={6}>
                        <Typography className={classes.formDataSubTitleResp}>
                          {order.cf_total_compra !== null
                            ? getPriceSapFormat(order.cf_total_compra)
                            : "$0.00"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.formDataSubTitleDes}>
                          Fecha creación
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.formDataSubTitleResp}>
                          {order.cf_compra_fecha}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.formDataSubTitleDes}>
                          Fecha entrega
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.formDataSubTitleResp}>
                          {"-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.formDataSubTitleDes}>
                          Dirección
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography  className={classes.formDataSubTitleResp}>
                          {order.tipo_envio_id
                            ? order.tipo_envio_id.cmm_valor
                            : ""}
                           
                        </Typography>
                        {order.cf_cmm_tipo_envio_id === 16 ? (
                          <Fragment>
                            {order.DireccionEnvio &&
                            order.DireccionEnvio.length > 0 ? (
                              <Fragment>
                                <Typography  className={classes.formDataSubTitleResp}>
                                  {`${order.DireccionEnvio[0].snd_direccion} Ext.${order.DireccionEnvio[0].snd_direccion_num_ext} Int.${order.DireccionEnvio[0].snd_direccion_num_int} e/ ${order.DireccionEnvio[0].snd_calle1} y ${order.DireccionEnvio[0].snd_calle2} CP ${order.DireccionEnvio[0].snd_codigo_postal}`}
                                 
                                </Typography>
                              </Fragment>
                            ) : (
                              ""
                            )}
                          </Fragment>
                        ) : (
                          <Fragment>
                            {order.DireccionRecoleccion &&
                            order.DireccionRecoleccion.length > 0 ? (
                              <Fragment>
                                <Typography  className={classes.formDataSubTitleResp}>
                                  {`${order.DireccionRecoleccion[0].alm_nombre}: ${order.DireccionRecoleccion[0].alm_direccion} CP.${order.DireccionRecoleccion[0].alm_codigo_postal} `}
                                </Typography>
                              </Fragment>
                            ) : (
                              ""
                            )}
                          </Fragment>
                        )}
                      </Grid>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Grid xs={4} md={4} className={classes.dataOrderDiv}>
                        <Typography className={classes.productName}>
                          Datos personales
                        </Typography>
                        {order.SocioDeNegocio ? (
                          <Fragment>
                            <Typography>
                              {order.SocioDeNegocio[0].sn_cardcode}
                            </Typography>
                            <Typography>
                              {order.SocioDeNegocio[0].sn_razon_social}
                            </Typography>
                            <Typography>
                              {order.SocioDeNegocio[0].sn_rfc}
                            </Typography>
                            <Typography>
                              {order.SocioDeNegocio[0].sn_email_facturacion}
                            </Typography>
                            <Typography>
                              {order.SocioDeNegocio[0].sn_telefono_empresa}
                            </Typography>
                          </Fragment>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid xs={4} md={4} className={classes.dataOrderDiv}>
                        <Typography className={classes.productName}>
                          Dirección de entrega
                        </Typography>
                        <Typography>
                          {order.tipo_envio_id
                            ? order.tipo_envio_id.cmm_valor
                            : ""}
                        </Typography>
                        {order.cf_cmm_tipo_envio_id === 16 ? (
                          <Fragment>
                            {order.DireccionEnvio &&
                            order.DireccionEnvio.length > 0 ? (
                              <Fragment>
                                <Typography>
                                  {`${order.DireccionEnvio[0].snd_direccion} Ext.${order.DireccionEnvio[0].snd_direccion_num_ext} Int.${order.DireccionEnvio[0].snd_direccion_num_int} e/ ${order.DireccionEnvio[0].snd_calle1} y ${order.DireccionEnvio[0].snd_calle2} CP ${order.DireccionEnvio[0].snd_codigo_postal}`}
                                </Typography>
                              </Fragment>
                            ) : (
                              ""
                            )}
                          </Fragment>
                        ) : (
                          <Fragment>
                            {order.DireccionRecoleccion &&
                            order.DireccionRecoleccion.length > 0 ? (
                              <Fragment>
                                <Typography>
                                  {`${order.DireccionRecoleccion[0].alm_nombre}: ${order.DireccionRecoleccion[0].alm_direccion} CP.${order.DireccionRecoleccion[0].alm_codigo_postal} `}
                                </Typography>
                              </Fragment>
                            ) : (
                              ""
                            )}
                          </Fragment>
                        )}
                      </Grid>
                      <Grid xs={4} md={4} className={classes.dataOrderDiv}>
                        <Typography className={classes.productName}>
                          Método de pago
                        </Typography>
                        {metodoDePago && (
                          <Fragment>
                            {metodoDePago == "03" && "Transferencia bancaria"}
                            {metodoDePago == "04" && "Tarjeta de crédito"}
                            {metodoDePago == "28" && "Tarjeta de débito"}
                            {metodoDePago == "99" && "Mi crédito con Dielsa"}
                          </Fragment>
                        )}
                      </Grid>
                      <Grid xs={4} md={4} className={classes.dataOrderDiv}>
                        <Typography className={classes.productName}>
                          Uso de CFDI
                        </Typography>
                        {order.cf_cfdi}
                      </Grid>
                    </Fragment>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>

        {isMobile ? <Divider className={classes.titleDivider} /> : ""}

        {/* totales */}

        <Grid item xs={12} sm={12} className={classes.content}>
          <Fragment>
            <Grid >
              <Grid item xs={12} sm={12}>
                <Paper className={clsx(classes.paperTitle)}>
                  <Typography>Detalle de tu compra</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Fragment>
                  <div className={classes.productsList}>
                    {orderData.ProductoCompraFinalizada
                      ? orderData.ProductoCompraFinalizada.map(
                          (prod, index) => {
                            let imgSrc =
                              "http://wws.com.pa/wp-content/plugins/wordpress-ecommerce/marketpress-includes/images/default-product.png";
                            if (prod.imagenes && prod.imagenes !== null) {
                              imgSrc =prod.imagenes.imgprod_ruta_archivo.includes('https://ftp3.syscom.mx')?prod.imagenes.imgprod_ruta_archivo : `${API_URL}${prod.imagenes.imgprod_ruta_archivo.split("public")[1]}`;
                            }
                            let _txtEntrega =
                              order.cf_cmm_tipo_envio_id === 16
                                ? "Envío en "
                                : "Disponible para recolección en ";
                            return (
                              <Grid
                                container
                                className={classes.prodRow}
                                key={index}
                              >
                                <Grid item xs={2} sm={3} md={1}>
                                  <Avatar alt="Product" src={imgSrc} />
                                </Grid>
                                <Grid item xs={10} sm={9} md={3}>
                                  <Typography className={classes.productName}>
                                    {`${prod.prod_nombre} [${prod.prod_nombre_extranjero}]`}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={2}>
                                  <Typography className={classes.listPrice}>
                                    {_txtEntrega}
                                  </Typography>
                                  <Typography className={classes.listPrice}>
                                    {convertDate(prod.pcf_fecha_entrega)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={2}>
                                  <Typography className={classes.listPrice}>
                                    {prod.pcf_linea_estatus_sap}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={1}>
                                  <Typography className={classes.listPrice}>
                                    {getPriceSapFormat(prod.pcf_precio)}
                                  </Typography>
                                  <Typography className={classes.listPriceTxt}>
                                    precio&nbsp;unidad
                                  </Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={1}>
                                  <Typography className={classes.prodQty}>
                                    {prod.pcf_cantidad_producto}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={2}>
                                  <Typography className={classes.totalPrice}>
                                    {getPriceSapFormat(
                                      prod.pcf_precio *
                                        prod.pcf_cantidad_producto
                                    )}
                                    {prod.pcf_order_dividida_sap ? (
                                      <span> USD</span>
                                    ) : (
                                      <span> MXN</span>
                                    )}
                                  </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                  <Divider
                                    style={{
                                      backgroundColor: "#EAEAEA",
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            );
                          }
                        )
                      : ""}
                  </div>
                </Fragment>
              </Grid>
            </Grid>
            {metodoDePago === "99" && orderData.cf_resume_usd !== undefined && (
              <Grid container style={{ justifyContent: "flex-end" }}>
                <Grid item xs={12} sm={6}>
                  <div className={classes.costData}>
                    <Grid container>
                      <Grid item xs={12}>
                        <span className={classes.divisaTitle}>Divisa USD</span>
                      </Grid>
                      <Grid item xs={6}>
                        Subtotal
                      </Grid>
                      <Grid item xs={6} className={"costResponse"}>
                        {getPriceSapFormat(orderData.cf_resume_usd.precioTotal_usd)}
                      </Grid>
                      <Grid item xs={6}>
                        Descuento
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className={"costResponse"}
                      >{`- ${getPriceSapFormat(
                        orderData.cf_resume_usd.totalDescuentos_usd
                      )}`}</Grid>
                      <Grid item xs={6}>
                        Subtotal aplicado descuento
                      </Grid>
                      <Grid item xs={6} className={"costResponse"}>
                        {getPriceSapFormat(
                          orderData.cf_resume_usd.precioFinalTotal_usd
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        Gastos de envío
                      </Grid>
                      <Grid item xs={6} className={"costResponse"}>
                        {getPriceSapFormat(
                          orderData.cf_resume_usd.cdc_costo_envio_usd
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        IVA
                      </Grid>
                      <Grid item xs={6} className={"costResponse"}>
                        {getPriceSapFormat(orderData.cf_resume_usd.TotalImpuesto_usd)}
                      </Grid>

                      <Grid item xs={12}>
                        <Divider style={{ backgroundColor: "#EAEAEA" }} />
                      </Grid>
                      <Grid item xs={6}>
                        <strong>Total</strong>
                      </Grid>
                      <Grid item xs={6} className={"costResponse"}>
                        <strong>
                          {getPriceSapFormat(orderData.cf_resume_usd.TotalFinal_usd)}
                        </strong>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            )}
            <Grid container style={{ justifyContent: "flex-end" }}>
              <Grid item xs={12} sm={6}>
                <div className={classes.costData}>
                  <Grid container>
                    <Grid item xs={12}>
                      <span className={classes.divisaTitle}>Divisa MXN</span>
                    </Grid>
                    <Grid item xs={6}>
                      Subtotal
                    </Grid>
                    <Grid item xs={6} className={"costResponse"}>
                      {order.cf_resume_mxn !== undefined
                        ? getPriceSapFormat(order.cf_resume_mxn.precioTotal)
                        : getPriceSapFormat(order.cf_orden_subtotal)}
                    </Grid>
                    <Grid item xs={6}>
                      Descuento
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className={"costResponse"}
                    >{`- ${getPriceSapFormat(
                      orderData.cf_resume_mxn !== undefined
                        ? orderData.cf_resume_mxn.totalDescuentos
                        : orderData.cf_orden_descuento
                    )}`}</Grid>
                    <Grid item xs={6}>
                      Subtotal aplicado descuento
                    </Grid>
                    <Grid item xs={6} className={"costResponse"}>
                      {getPriceSapFormat(
                        orderData.cf_resume_mxn !== undefined
                          ? orderData.cf_resume_mxn.precioFinalTotal
                          : orderData.cf_orden_subtotal_aplicado
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      Gastos de envío
                    </Grid>
                    <Grid item xs={6} className={"costResponse"}>
                      {getPriceSapFormat(
                        orderData.cf_resume_mxn !== undefined
                          ? orderData.cf_resume_mxn.cdc_costo_envio
                          : orderData.cf_orden_gastos_envio
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      IVA
                    </Grid>
                    <Grid item xs={6} className={"costResponse"}>
                      {getPriceSapFormat(
                        orderData.cf_resume_mxn !== undefined
                          ? orderData.cf_resume_mxn.TotalImpuesto
                          : orderData.cf_order_iva
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Divider style={{ backgroundColor: "#EAEAEA" }} />
                    </Grid>
                    <Grid item xs={6}>
                      <strong>Total</strong>
                    </Grid>
                    <Grid item xs={6} className={"costResponse"}>
                      <strong>
                        {getPriceSapFormat(
                          orderData.cf_resume_mxn !== undefined
                            ? orderData.cf_resume_mxn.TotalFinal
                            : orderData.cf_total_compra
                        )}
                      </strong>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Fragment>
        </Grid>
      </Grid>
    </div>
  );
}
