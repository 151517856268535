import React, { useState, useEffect, useContext, Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((styles) => ({
  container: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 0,
    fontSize: 10,
  },
  skuDiv: {
    display: "inline-block",
  },
  skuDivLine: {
    whiteSpace: "nowrap",
    display: "block",
  },
  partDiv: {
    display: "inline-block",
    marginLeft: "25px",
  },
  partDivLine: {
    display: "block",
  },
  title: {
    color: "#0B3196",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "12px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "14px",
    display: "inline-block",
  },
  text: {
    color: "#9B9B9B",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "12px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "14px",
    display: "inline-block",
    marginLeft: "5px",
  },
}));

export default function SectionProductSKU(props) {
  const classes = useStyles();
  useEffect(() => {}, []);
  return (
    <div>
      <div className={classes.container}>
        <div
          className={
            props.gridView === false ? classes.skuDiv : classes.skuDivLine
          }
        >
          <Typography className={classes.title}>SKU:</Typography>
          <Typography className={classes.text}>
            {props.productDetails.nombre_extranjero}
          </Typography>
        </div>
      </div>
    </div>
  );
}
