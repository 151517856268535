import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Grid, Menu, MenuItem, Popover } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CategoriesMenu from "components/CategoriesMenu/CategoriesMenu";

const useStyles = makeStyles((styles) => ({
    menuContainer: {
        ["@media (max-width: 1080px)"]: {
            display: "none",
        },
    },
}));

const ButtonCategoriesMenu = () => {
    const classes = useStyles();
    const [menuVisible, setMenuVisible] = useState(false);
    const [anchoTotalGrid, setAnchoTotalGrid] = useState(0);

    useEffect(() => {
        const withTotal = window.innerWidth;
        if(withTotal > 0) {
            setAnchoTotalGrid(((withTotal / 12) * 3) - 6);
        }
    }, [window.innerWidth]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (menuVisible && !event.target.closest('#menu-container')) {
              setMenuVisible(false);
            }
        };
    
        document.addEventListener('click', handleOutsideClick);
    
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [menuVisible]);

    const handleOpenMenu = () => {
        setMenuVisible(!menuVisible);
    }

    return (
        <div>
            <IconButton
                aria-label="Categorias"
                onClick={handleOpenMenu}
            >
                <MenuIcon fontSize="inherit" />
            </IconButton>
            {menuVisible &&
            <div style={{ marginLeft: '-5rem' }}>
                <Grid 
                    container
                    sx={{
                        paddingTop: 2,
                        paddingBottom: 4,
                        position: 'absolute',
                        zIndex: 1,
                    }}
                >
                    <Grid
                        item
                        md={3}
                        lg={3}
                        className={classes.menuContainer}
                        sx={{
                            marginLeft: '2.5rem',
                            marginTop: '-1rem',
                        }}
                    >
                        <CategoriesMenu anchoTotalGrid={anchoTotalGrid} />
                    </Grid>
                </Grid>
            </div>}
        </div>
    )
}

export default ButtonCategoriesMenu;
