import React, { useEffect, useState, useContext } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((styles) => ({
    title: {
        color: "#0b3196",
        margin: 0,
        minHeight: 32,
        fontSize: "16px",
        fontFamily: "'Centrale Sans Regular'",
        fontWeight: 400,
        textDecoration: "none",
        [styles.breakpoints.down("sm")]: {
            fontSize: "16px",
        },
    },
    container: {
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: 15,
        paddingRight: 5,
        display: "inline-block",
    }
}));


export default function SectionProductBrand(props) {
    const classes = useStyles();
    const [name, setName] = useState(props.productDetails.name);
    useEffect(() => {
      
        setName(props.productDetails.brandName);
    }, []);
    return (
        <div className={classes.container}>
            <Typography className={classes.title}>{name}</Typography>
        </div>
    );
}
