import React, { useState, useEffect, useContext, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { API_URL } from "_services/api.url.back.img";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import toast from "react-hot-toast";
import "../../../assets/custom/ProductInput.css";
import { useAuth } from "hooks/useAuth";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { productsService } from "_services/products.services";
import { checkoutService } from "_services/checkout.service";
import { addCartBusinnesPartner } from "utils/addCartBusinnesPartner";
import { addCartLocal } from "utils/addCartLocal";
import { accountService } from "services/account/account.service";
const useStyles = makeStyles((styles) => ({
  container: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  btnCartSub: {
    height: 40,
    color: "#003773",
    border: "1px solid #EAEAEA",
    borderTopLeftRadius: 2,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 2,
    width: 40,
    minWidth: 40,
  },
  inpCartQty: {
    marginLeft: -1,
    width: "45%",
    borderRadius: 0,
    height: 40,
    paddingTop: 0,
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      border: "1px solid #EFEFEF",
      borderRadius: 2,
    },
  },
  btnCartAdd: {
    marginLeft: -1,
    height: 40,
    backgroundColor: "#0C2D9C",
    color: "#fff",
    borderRadius: 3,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    borderBottomLeftRadius: 0,
    borderRadius: 3,
    boxShadow: "none",
    width: 40,
    minWidth: 40,
    "&:hover": {
      backgroundColor: "#09287d",
    },
  },
  favSaveDiv: {
    width: "100%",
    marginTop: 18,
    marginBottom: 9,
  },
  btnAdd: {
    height: 40,
    backgroundColor: "#ffffff",
    color: "#0C2D9C",
    border: "1px solid #0C2D9C",
    borderRadius: 3,
    fontSize: 14,
    width: "-webkit-fill-available",
    "&:hover": {
      backgroundColor: "#0C2D9C",
      color: "#fff",
    },
    textTransform: "none",
    boxShadow: "none",
  },
  favSave: {
    width: "100%",
    textAlign: "center",
    color: "#0b3196",
    marginTop: "15px",
    marginBottom: "15px",
    cursor: "pointer",
  },
  alert: {
    marginBottom: "15px",
  },
}));

export default function SectionProductInputAdd(props) {
  const classes = useStyles();
  const history = useHistory();
  const {
    isLogged,
    getUserData,
    setOpenAlertF,
    setCarritoItems,
    carritoItems,
    getAccountIsActive,
  } = useAuth();
  const dataUser = getUserData();
  const isActive = isLogged ? getAccountIsActive() : false;
  const [cantidad, setCantidad] = useState(1);
  const [wishlist, setWishlist] = useState(null);
  const [add, setAdd] = useState(true);
  const [miss, setMiss] = useState([]);
  const [open, setOpen] = useState(false);

  const itemQuantityDecrease = () => {
    let _c = cantidad;
    if (_c > 1) {
      _c -= 1;
    }
    setCantidad(_c);
  };
  const itemQuantityIncrease = () => {
    let _c = cantidad;
    _c += 1;
    setCantidad(_c);
  };
  const inputCantidad = (val) => {
    let _v = parseInt(val);
    if (_v > 0) {
      setCantidad(_v);
    }
  };

  const addToWishlist = async () => {
    let _w = await productsService.addProductoToWishlist(
      props.productDetails.product_id,
      "Lista Basica",
      JSON.parse(localStorage.getItem("user")).usuario
        .snu_sn_socio_de_negocio_id
    );
    setWishlist(
      <Typography className={classes.favSave}>{_w.message}</Typography>
    );
  };

  const addToCart = (idProduct, prod) => {
    if (isLogged) {
      //TODO trabajar este metodo
      accountService.addCartClientes({ type: 2, userId: dataUser.usuario.snu_usuario_snu_id, productId: prod.id})
      //accountService.addNewUserInteractions({ type: 2, userId: dataUser.usuario.snu_usuario_snu_id, productId: prod.skuId})
     // window.alert(JSON.stringify(prod))
      //accountService.getClientsInteractionsProduct({type:2, productId: prod.id})
      prod.aa = addCartBusinnesPartner(
        cantidad,
        prod,
        dataUser.usuario.snu_sn_socio_de_negocio_id,
        idProduct
      ).then(({ message, type, isAmount }) => {
        if (!isAmount) {
          setCarritoItems(carritoItems + 1);
        }
        setOpenAlertF({ open: true, type: type, message: message });
      });
    } else {
      setOpenAlertF({ open: false, type: "", message: "" });
      const imagen =
        prod.imgs.length > 0
          ? `${API_URL}${prod.imgs[0].imgprod_ruta_archivo.replace(
              "./public/",
              ""
            )}`
          : "";
      const skuData = {
        nombre: prod.name,
        nombre_extranjero: prod.foreignName,
        stock: prod.stock,
        imagen: imagen,
        sku: prod.sku,
        skuId: prod.id,
      };
      addCartLocal(cantidad, skuData).then(({ message, type, isAmount }) => {
        if (!isAmount) {
          setCarritoItems(carritoItems + 1);
        }
        setOpenAlertF({ open: true, type: type, message: message });
      });
      //
    }
  };
  useEffect(() => {
    console.log(props.productDetails);
    if (JSON.parse(localStorage.getItem("user")) !== null) {
      //setWishlist(<Typography className={classes.favSave} onClick={() => { addToWishlist(); }}>Agregar a Wishlist</Typography>);
    }
    let _add = true;
    let _missing = [];
    for (let i = 0; i < props.productDetails.attributes_selected.length; i++) {
      if (props.productDetails.attributes_selected[i] === "-1") {
        _add = false;
        //_missing += (i === 0) ? "<br />" : "";
        _missing.push(props.productDetails.attributes[i].attribute);
      }
    }
    //_missing += " PARA CONTINUAR";
    setAdd(_add);
    setMiss(_missing);
  }, []);

  //

  return (
    <div>
      <div className={classes.container}>
        {add === true ? (
          <div>
            <Button
              className={classes.btnCartSub}
              variant="outlined"
              onClick={() => {
                itemQuantityDecrease();
              }}
            >
              <RemoveIcon />
            </Button>
            <TextField
              id="qty-add-cart"
              size="small"
              className={classes.inpCartQty}
              label="Cantidad"
              variant="outlined"
              value={cantidad}
              onChange={(ev) => {
                inputCantidad(ev.target.value);
              }}
            />
            <Button
              className={classes.btnCartAdd}
              variant="contained"
              onClick={() => {
                itemQuantityIncrease();
              }}
            >
              <AddIcon />
            </Button>
            <div className={classes.favSaveDiv}>
              {isLogged ? (isActive?
                <Button
                  className={classes.btnAdd}
                  variant="contained"
                  onClick={() => {
                    addToCart(
                      props.productDetails.skus[
                        props.productDetails.sku_selected
                      ].id,
                      props.productDetails.skus[
                        props.productDetails.sku_selected
                      ]
                    );
                  }}
                >
                  {props.productDetails.skus[
                        props.productDetails.sku_selected
                      ].peso == 0 ||
                      props.productDetails.skus[
                        props.productDetails.sku_selected
                      ].volumen == 0 ||
                      props.productDetails.skus[
                        props.productDetails.sku_selected
                      ].precioDescuento == 0 ||
                      (props.productDetails.skus[
                        props.productDetails.sku_selected
                      ].stock == 0 &&
                        props.productDetails.skus[
                          props.productDetails.sku_selected
                        ].backorder === false)
                      ? "Añadir a cotización"
                      : "Añadir al carrito"
                      }
                </Button> :""
              ) : (
                <Button
                  className={classes.btnAdd}
                  variant="contained"
                  onClick={() => {
                    addToCart(
                      props.productDetails.skus[
                        props.productDetails.sku_selected
                      ].id,
                      props.productDetails.skus[
                        props.productDetails.sku_selected
                      ]
                    );
                  }}
                >
                  {"Añadir a cotización"}
                </Button>
              )}
              {wishlist}
            </div>
          </div>
        ) : (
          <div>
            SELECCIONA
            <br />
            {miss.map((m) => (
              <div key={m}>
                &bull; {m} <br />
              </div>
            ))}
            PARA CONTINUAR
          </div>
        )}
      </div>
    </div>
  );
}
