
import { authHeader } from "_helpers";
import { API_URL } from "_services/api.url.back";

export const filtersServices = {
    getProductsFilters,getProductsAccesories,getProductsRelated
};

function getProductsFilters(page, pageSize, searchWord = null, category = null, subCategory = null, brand = null, business, type, variant = null, 
    isDollar=false,minPrice=0,maxPrice=0, discount=null, available=null, orderBy = null
) {
    const params = new URLSearchParams({
        p: page,
        rpp: pageSize,
        t: type,
        cr: isDollar ? 'USD':'MXN'
    });

    // cr=USD&min=8.05&max=15.8   -> Precios
    //wd=true  Por descuento
    //Filtro para especificar la disponibilidad a=d es Disponible a=bp es Bajo pedido, se pueden mandar juntos.
    if (searchWord) params.append('s', encodeURI(searchWord));
    if (brand) params.append('b', brand);
    if (category) params.append('c', category);
    if (subCategory) params.append('sc', subCategory);
    if (business) params.append('pi', business);
    if (variant) params.append('vv', encodeURI(variant));
    if (minPrice>0) params.append('min', minPrice)
    if (maxPrice>0) params.append('max', maxPrice)
    if (discount) params.append('wd', discount)
    if (available) params.append('a', available)
    if (orderBy) params.append('sb',orderBy)

    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(`${API_URL}/filters/products?${params.toString()}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return {
                totalResults: response.totalResults,
                data: response.data,
            };
        });
}


function getProductsAccesories(idProduct,business, isDollar=false) {
    const params = new URLSearchParams({
        cr: isDollar ? 'USD':'MXN',
        pi: business
    });
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${API_URL}/productos/getAccesories/${idProduct}?${params.toString()}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return {
                totalResults: response.totalResults,
                data: response.data,
            };
        });
}

function getProductsRelated(idProduct,business, isDollar=false) {
    const params = new URLSearchParams({
        cr: isDollar ? 'USD':'MXN',
        pi: business
    });
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${API_URL}/productos/related/${idProduct}?${params.toString()}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return {
                totalResults: response.totalResults,
                data: response.data,
            };
        });
}


function getProductsFilters1(page, pageSize, searchWord = null, category = null, subCategory = null, brand = null, business, type, variant=null) {
    const requestOptions = {
        method: "POST",
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(
            Object.assign(
                {},
                { p: page },
                { rpp: pageSize },
                { t: type },
                { pi: business },
                searchWord ? { s: searchWord } : {},
                category ? { c: category } : {},
                subCategory ? { sc: subCategory } : {},
                brand ? { b: brand } : {},
                variant ? { vv: variant } : {}
               
            )
        )
    };
    
    return fetch(`${API_URL}/filters/products`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return {
                totalResults: response.totalResults,
                data: response.data,
            };
        });
}


function handleResponse(response) {
  // Inspecciona todos los encabezados en la respuesta
  for (let pair of response.headers.entries()) {
      console.log(pair[0]+ ': '+ pair[1]);
  }

  return response.text().then((text) => {
      const data = text && JSON.parse(text);
      const totalResults = response.headers.get('X-Total-Records'); // Ajusta el nombre según tu API
      
      if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
      }
      
      return {
          totalResults: totalResults,
          data: data,
      };
  });
}
