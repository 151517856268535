import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import LastProduct from "components/LastProduct/LastProduct";

const LastExistenceComponent = ({ collectionData, isMobile }) => {
    const history = useHistory();

    const goToLastProduct = () => {
        history.push(`/ultimos_productos`);
    };

    return (
        <Box>
            <Box
                sx={{
                    "&:hover": {
                        cursor: "pointer",
                    },
                }}
                onClick={goToLastProduct}
            >
                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: 600,
                        color: "#0b3196",
                        paddingTop: 1,
                        paddingBottom: 2,
                        fontFamily: "Centrale Sans Medium",
                    }}
                >
                    {collectionData?.coleccion?.nombre}
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {collectionData.productosColeccion &&
                collectionData.productosColeccion.map((item) => 
                    <Grid key={item.id} item  xs={6} sm={4} md={3} lg={2.4} >
                        <LastProduct item={item} isMobile={isMobile}/>
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}

export default LastExistenceComponent