import { authHeader } from "../_helpers";
import { API_URL } from "./api.url.back";

export const userService = {
  login,
  logout,
  recoveryPassword,
  recoveryPasswordSuccess,
  registerClient,
  requestCreditClient,
  loadAnexosZip,
  contactSend,
  updateUserClientData,
  updateUserClientPassword,
  updateUserClientEmailBilling,
  getAddressByClientCardCode,
  deleteClientAddress,
  updateClientDefaultAddress,
  createAddressClient,
  updateAddressClient,
  getBills,
  getClientById, //consultar API para resibir el saldo y el credito de cliente logeado
  getClientUsers,
  createUserProfile,
  updateUserProfile,
  updateUserProfileStatus,
  getCotizacionesBySN,
  getCotizacionDetalle,
  getProyectosBySN,getDetalleProyectosBySN,
  getJsonPermisosUsuario,
  deleteCot,
  sendCotToCart,
  finishCotizacionCart,
  registerNewsletter,
  getProyectsByCardcodeSn,
  createProyect,
  addProductToQuotesService,
  deleteProductOfQuotesService,
  updateDeliberyOfQuoteService,
  updateProductQuantityOfQuoteService,
  deleteCotizacion,
  createDuplicateQuote,
  registerUserB2C
};

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    const responseData = {
      error: false,
      data: data,
    };
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
      }

      //responseData.error = true;
      //return responseData;
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function handleResponseAlternative(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    const responseData = {
      error: false,
      data: data,
    };
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
      }

      responseData.error = true;
      return responseData;
    }
    return responseData;
  });
}

function login(email, password) {
  let cartid = "";
  if (
    localStorage.getItem("shoppingcart") &&
    localStorage.getItem("shoppingcart") !== undefined
  ) {
    cartid = JSON.parse(localStorage.getItem("shoppingcart")).carrito_de_compra
      .cdc_carrito_de_compra_id;
  }

  const requestOptions = {
    method: "POST", 
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      snu_correo_electronico: email,
      snu_contrasenia: password,
      cart_id: cartid,
    }),
  };

  return fetch(`${API_URL}/socio_negocio_usuarios/login`, requestOptions)
    .then(handleResponseAlternative)
    .then((user) => {
      if (user.data.usuario) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(user.data));
      }
      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("client");
  localStorage.removeItem("cart");
}

function recoveryPassword(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ snu_correo_electronico: email }),
  };

  return fetch(`${API_URL}/socio_negocio_usuarios/recovery`, requestOptions)
    .then(handleResponseAlternative)
    .then((response) => {
      return response;
    });
}

function recoveryPasswordSuccess(
  usu_contrasenia,
  usu_contrasenia_confirm,
  tokenChange
) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", token: tokenChange },
    body: JSON.stringify({
      snu_contrasenia: usu_contrasenia,
      snu_contrasenia_confirm: usu_contrasenia_confirm,
    }),
  };
  return fetch(
    `${API_URL}/socio_negocio_usuarios/valid_recovery`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((response) => {
      return response;
    });
}

function registerClient(
  razonsocial,
  nombrecomercial,
  sitioweb,
  rfc,
  email,
  codigopostal,
  calle,
  numero,
  colonia,
  municipio,
  paisSelected,
  estadoSelected,
  formapagoSelected,
  banco,
  numerocuenta,
  mediopagoSelected,
  cfdiSelected
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      up_razon_social: razonsocial,
      up_nombre_comercial: nombrecomercial,
      up_rfc: rfc,
      up_email_facturacion: email,
      up_direccion_facturacion: `${calle} ${numero} ${colonia} ${municipio} ${codigopostal}`,
      up_codigo_postal: codigopostal,
      up_direccion: calle,
      up_direccion_num_ext: numero,
      up_direccion_num_int: "",
      up_colonia: colonia,
      up_ciudad: municipio,
      up_pais_id: paisSelected,
      up_estado_id: estadoSelected,
      up_cfdi: cfdiSelected,
      up_usu_usuario_creador_id: 1,
      upd_calle1: null,
      upd_calle2: null,
      upd_alias: null,
      upd_contacto: null,
      upd_telefono: null,
      up_datos_b2b: {
        up_sitio_web: sitioweb,
        up_numero_cuenta_banco: numerocuenta,
        up_nombre_banco: banco,
        up_forma_pago: formapagoSelected,
        up_medio_pago: mediopagoSelected,
      },
      up_cmm_estatus_id: 1000188,
    }),
  };

  return fetch(`${API_URL}/usuarios_prospectos/add`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function registerUserB2C(name, lastName, motherLastName, email, password) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body:JSON.stringify({name, lastName, motherLastName, email, password})
  };
  return fetch(`${API_URL}/account/sign-up`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function requestCreditClient(
  razonsocial,
  rfc,
  sitioweb,
  giroempresa,
  nombreinforma,
  quienInforma,
  tipoquieninforma,
  antiguedadpuesto,
  codigopostal,
  calle,
  numero,
  colonia,
  municipio,
  estadoSelected,
  paisSelected,
  telefonopago,
  telefonocontabilidad,
  telefonodireccion,
  telefonofacturacion,
  contactopago,
  contactocontabilidad,
  contactodireccion,
  contactofacturacion,
  emailpago,
  emailcontabilidad,
  emaildireccion,
  emailfacturacion,
  tipoEmpresa,
  tieneSucursales,
  inputFields,
  perteneceGrupo,
  cualgrupo,
  antiguedadempresa,
  fechaoperacioninicio,
  capitalfijo,
  capitalvariable,
  frentedim,
  fondodim,
  superficiedim,
  codigopostalapodlegal,
  calleapodlegal,
  numeroapodlegal,
  coloniaapodlegal,
  municipioapodlegal,
  estadoapodlegal,
  telefonoapodlegal,
  emailapodlegal,
  tipoDomicilioApodLegal,
  rentaapodlegal,
  tipoRamaVentas,
  empresaramaventas,
  territorioinflu,
  volumencompra,
  promedioventas,
  ventasanuales,
  utilidadesanoprev,
  utilidadesproyectadas,
  tipoMonedaFactura,
  empleadosqty,
  //empleadosqtyexacta,
  pagosfijosmensuales,
  tipoLocal,
  montorentamensual,
  limitecredito,
  plazocredito,
  nombreref1,
  nombreref2,
  nombreref3,
  montoref1,
  montoref2,
  montoref3,
  antiguedadref1,
  antiguedadref2,
  antiguedadref3,
  plazoref1,
  plazoref2,
  plazoref3,
  telefonoref1,
  telefonoref2,
  telefonoref3,
  bancoref1,
  bancoref2,
  numerocuentaref1,
  numerocuentaref2,
  sucursalbancoref1,
  sucursalbancoref2,
  ejecutivoref1,
  ejecutivoref2,
  telefonobancoref1,
  telefonobancoref2,
  nombrerecoge1,
  nombrerecoge2,
  nombrerecoge3,
  tieneVehiculosReparto,
  qtyvehiculosreparto,
  inputFieldsVehiculos,
  userid
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      sdc_nombre_razon_social: razonsocial,
      sdc_rfc: rfc,
      sdc_sito_web: sitioweb,
      sdc_giro_empresa: giroempresa,
      sdc_nombre_quien_informa: nombreinforma,
      sdc_tipo_informa: tipoquieninforma,
      sdc_quien_informa: quienInforma,
      sdc_antiguedad_puesto: antiguedadpuesto,
      sdc_codigo_postal: codigopostal,
      sdc_calle: calle,
      sdc_numero: numero,
      sdc_colonia: colonia,
      sdc_municipio_delegacion: municipio,
      sdc_pais: paisSelected,
      sdc_estado: estadoSelected,
      sdc_pagos_telefono: telefonopago,
      sdc_pagos_contacto: contactopago,
      sdc_pagos_correo_electronico: emailpago,
      sdc_contabilidad_telefono: telefonocontabilidad,
      sdc_contabilidad_contacto: contactocontabilidad,
      sdc_contabilidad_correo_electronico: emailcontabilidad,
      sdc_direccion_general_telefono: telefonodireccion,
      sdc_direccion_general_contacto: contactodireccion,
      sdc_direccion_general_correo_electronico: emaildireccion,
      sdc_facturacion_telefono: telefonofacturacion,
      sdc_facturacion_contacto: contactofacturacion,
      sdc_facturacion_correo_electronico: emailfacturacion,
      sdc_tipo_empresa: tipoEmpresa,
      sdc_tiene_sucursales: tieneSucursales,
      sdc_pertenece_grupo: perteneceGrupo,
      sdc_grupo_pertenece: cualgrupo,
      sdc_antiguedad_empresa: antiguedadempresa,
      sdc_fecha_inicio_operacion: fechaoperacioninicio,
      sdc_capital_fijo: capitalfijo,
      sdc_capital_variable: capitalvariable,
      sdc_dimension_frente: frentedim,
      sdc_dimension_fondo: fondodim,
      sdc_dimension_superficie: superficiedim,
      sdc_apoderado_legal_codigo_postal: codigopostalapodlegal,
      sdc_apoderado_legal_calle: calleapodlegal,
      sdc_apoderado_legal_numero: numeroapodlegal,
      sdc_apoderado_legal_colonia: coloniaapodlegal,
      sdc_apoderado_legal_municipio: municipioapodlegal,
      sdc_apoderado_legal_estado: estadoapodlegal,
      sdc_apoderado_legal_telefono: telefonoapodlegal,
      sdc_apoderado_legal_correo_electronico: emailapodlegal,
      sdc_apoderado_legal_tipo_domicilio: tipoDomicilioApodLegal,
      sdc_apoderado_legal_renta: rentaapodlegal,
      sdc_rama_empresa_tipo: tipoRamaVentas,
      sdc_rama_empresa_especifica: empresaramaventas,
      sdc_rama_empresa_territorio_influencia: territorioinflu,
      sdc_rama_empresa_volumen_compra: volumencompra,
      sdc_rama_empresa_promedio_ventas_mensuales: promedioventas,
      sdc_rama_empresa_promedio_ventas_anuales: ventasanuales,
      sdc_rama_empresa_utilidades_anio_previo: utilidadesanoprev,
      sdc_rama_empresa_utilidades_anio_presente: utilidadesproyectadas,
      sdc_rama_empresa_tipo_moneda: tipoMonedaFactura,
      sdc_rama_empresa_moneda_facturar: tipoMonedaFactura,
      sdc_rama_empresa_empleados_laborando: empleadosqty,
      sdc_rama_empresa_pagos_fijos_mensuales: pagosfijosmensuales,
      sdc_rama_empresa_tipo_local: tipoLocal,
      sdc_rama_empresa_monto_renta_local_mensual: montorentamensual,
      sdc_rama_empresa_limite_de_credito_deseado: limitecredito,
      sdc_rama_empresa_plazo_credito: plazocredito,
      sdc_referencia_comercial_uno_nombre: nombreref1,
      sdc_referencia_comercial_uno_monto: montoref1,
      sdc_referencia_comercial_uno_antiguedad: antiguedadref1,
      sdc_referencia_comercial_uno_plazo: plazoref1,
      sdc_referencia_comercial_uno_telefonos: telefonoref1,
      sdc_referencia_comercial_dos_nombre: nombreref2,
      sdc_referencia_comercial_dos_monto: montoref2,
      sdc_referencia_comercial_dos_antiguedad: antiguedadref2,
      sdc_referencia_comercial_dos_plazo: plazoref2,
      sdc_referencia_comercial_dos_telefonos: telefonoref2,
      sdc_referencia_comercial_tres_nombre: nombreref3,
      sdc_referencia_comercial_tres_monto: montoref3,
      sdc_referencia_comercial_tres_antiguedad: antiguedadref3,
      sdc_referencia_comercial_tres_plazo: plazoref3,
      sdc_referencia_comercial_tres_telefonos: telefonoref3,
      sdc_referencia_bancaria_uno_banco: bancoref1,
      sdc_referencia_bancaria_uno_no_cuenta: numerocuentaref1,
      sdc_referencia_bancaria_uno_sucursal: sucursalbancoref1,
      sdc_referencia_bancaria_uno_ejecutivo_cuenta: ejecutivoref1,
      sdc_referencia_bancaria_uno_telefonos: telefonobancoref1,
      sdc_referencia_bancaria_dos_banco: bancoref2,
      sdc_referencia_bancaria_dos_no_cuenta: numerocuentaref2,
      sdc_referencia_bancaria_dos_sucursal: sucursalbancoref2,
      sdc_referencia_bancaria_dos_ejecutivo_cuenta: ejecutivoref2,
      sdc_referencia_bancaria_dos_telefonos: telefonobancoref2,
      sdc_recoleccion_de_mercancia_uno: nombrerecoge1,
      sdc_recoleccion_de_mercancia_dos: nombrerecoge2,
      sdc_recoleccion_de_mercancia_tres: nombrerecoge3,
      sdc_vehiculos: tieneVehiculosReparto,
      sdc_cantidad_vehiuculo: qtyvehiculosreparto,
      sdc_sn_socio_de_negocio_id: userid,
      sucursales: inputFields,
      vehiculos: inputFieldsVehiculos,
    }),
  };

  return fetch(`${API_URL}/solicitudes_de_credito/create`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function loadAnexosZip(formData, token) {
  const requestOptions = {
    method: "POST",
    headers: { token: token },
    body: formData,
  };

  return fetch(`${API_URL}/files/documentos_solicitud_credito`, requestOptions)
    .then(handleResponseAlternative)
    .then((jsonData) => {
      return jsonData;
    });
}

function contactSend(name, mobile, email, company, message) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      c_nombre: name,
      c_telefono: mobile,
      c_correo_electronico: email,
      c_empresa: company,
      c_mensaje: message,
    }),
  };

  return fetch(`${API_URL}/contacto/send`, requestOptions)
    .then(handleResponseAlternative)
    .then((response) => {
      return response;
    });
}

function updateUserClientData(
  userid,
  name,
  firstlastname,
  secondlastname,
  email,
  contact,
  update_by
) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({
      snu_usuario_snu_id: userid,
      snu_nombre: name,
      snu_primer_apellido: firstlastname,
      snu_segundo_apellido: secondlastname,
      snu_correo_electronico: email,
      snu_telefono: contact,
      snu_usu_usuario_modificado_id: update_by,
    }),
  };

  return fetch(
    `${API_URL}/socio_negocio_usuarios/update_SN_token`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((response) => {
      return response;
    });
}

function updateUserClientPassword(
  userid,
  currentpassword,
  newpassword,
  update_by
) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({
      snu_usuario_snu_id: userid,
      old_password: currentpassword,
      new_password: newpassword,
    }),
  };

  return fetch(
    `${API_URL}/socio_negocio_usuarios/ChangePassword`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((response) => {
      return response;
    });
}

function getAddressByClientCardCode(cardcode) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      snd_cardcode: cardcode,
    }),
  };
  return fetch(
    `${API_URL}/socio_negocio_direcciones/getByCardCode_snd_ST`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((response) => {
      return response;
    });
}

function deleteClientAddress(addressid) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    body: JSON.stringify({
      snd_direcciones_id: addressid,
    }),
  };
  return fetch(
    `${API_URL}/socio_negocio_direcciones/delete_snd_ST`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((response) => {
      return response;
    });
}

function updateClientDefaultAddress(addressid, value) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({
      snd_direcciones_id: addressid,
      snd_direccion_envio_default: value,
    }),
  };
  return fetch(
    `${API_URL}/socio_negocio_direcciones/update_snd_ST`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((response) => {
      return response;
    });
}

function createAddressClient(
  alias,
  codigopostal,
  calle,
  calle1,
  calle2,
  numexterior,
  numinterior,
  colonia,
  paisSelected,
  estadoSelected,
  city,
  contacto,
  telefono,
  cardcode
) {
  let body = {
    snd_pais_id: paisSelected,
    snd_ciudad: city,
    snd_direccion: calle,
    snd_direccion_num_ext: numexterior,
    snd_direccion_num_int: numinterior,
    snd_direccion_telefono: "",
    snd_calle1: calle1,
    snd_calle2: calle2,
    snd_codigo_postal: codigopostal,
    snd_cardcode: cardcode,
    snd_cmm_estatus_id: 1000051,
    snd_tipoDir: "S",
    snd_alias: alias,
    snd_colonia: colonia,
    snd_contacto: contacto,
    snd_telefono: telefono,
    snd_direccion_envio_default: "false",
  };

  if (estadoSelected !== 0) {
    body.snd_estado_id = estadoSelected;
  }

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };
  return fetch(
    `${API_URL}/socio_negocio_direcciones/add_snd_ST`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((response) => {
      return response;
    });
}

function updateAddressClient(
  selectedAddress,
  alias,
  codigopostal,
  calle,
  calle1,
  calle2,
  numexterior,
  numinterior,
  colonia,
  paisSelected,
  estadoSelected,
  city,
  contacto,
  telefono,
  cardcode,
  update_by
) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({
      snd_direcciones_id: selectedAddress.snd_direcciones_id,
      snd_pais_id: paisSelected,
      snd_estado_id: estadoSelected || 0,
      snd_ciudad: city,
      snd_direccion: calle,
      snd_direccion_num_ext: numexterior,
      snd_direccion_num_int: numinterior,
      snd_direccion_telefono: selectedAddress.snd_direccion_telefono,
      snd_calle1: calle1,
      snd_calle2: calle2,
      snd_codigo_postal: codigopostal,
      snd_cardcode: cardcode,
      snd_cmm_estatus_id: selectedAddress.snd_cmm_estatus_id,
      snd_usu_usuario_modificado_id: update_by,
      snd_tipoDir: selectedAddress.snd_tipoDir,
      snd_contacto: contacto,
      snd_telefono: telefono,
      snd_alias: alias,
      snd_colonia: colonia,
      snd_direccion_envio_default: selectedAddress.snd_direccion_envio_default,
    }),
  };
  return fetch(
    `${API_URL}/socio_negocio_direcciones/update_snd_ST`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((response) => {
      return response;
    });
}

function getBills(cardcode) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      sn_cardcode: cardcode,
    }),
  };
  return fetch(`${API_URL}/facturas/getListByCardCode_SnToken`, requestOptions)
    .then(handleResponseAlternative)
    .then((response) => {
      return response;
    });
}

function getClientById(clientid) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${API_URL}/socio_negocio/getListSociosNegocioByID_SN_TOKEN/${clientid}`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((client) => {  
      
      localStorage.setItem("client", JSON.stringify(client.data));
      return client;
    });
}

function updateUserClientEmailBilling(emailBilling, idClient) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({
      sn_socios_negocio_id: idClient,
      sn_email_facturacion: emailBilling,
    }),
  };
  return fetch(`${API_URL}/socio_negocio/update_sn_token`, requestOptions)
    .then(handleResponseAlternative)
    .then((client) => {
      return client;
    });
}

function getClientUsers(clientid) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${API_URL}/socio_negocio_usuarios/list_usuarios_SN/${clientid}`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((json) => {
      return json;
    });
}

function createUserProfile(
  nombre,
  apellido1,
  apellido2,
  correo,
  telefono,
  usuario,
  cardcode,
  clientid,
  create_by,
  area,
  puesto,
  menu_permisos
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      snu_nombre: nombre,
      snu_primer_apellido: apellido1,
      snu_segundo_apellido: apellido2,
      snu_correo_electronico: correo,
      snu_direccion: "",
      snu_telefono: telefono,
      snu_usuario: usuario,
      snu_cmm_estatus_id: 1000048,
      snu_cardcode: cardcode,
      snu_sn_socio_de_negocio_id: clientid,
      snu_snu_usuario_snu_creador_id: create_by,
      snu_super_usuario: false,
      snu_area: area,
      snu_puesto: puesto,
      snu_menu_roles: menu_permisos,
    }),
  };
  return fetch(`${API_URL}/socio_negocio_usuarios/add_SN`, requestOptions)
    .then(handleResponseAlternative)
    .then((json) => {
      return json;
    });
}

function updateUserProfile(
  userid,
  nombre,
  apellido1,
  apellido2,
  correo,
  telefono,
  usuario,
  cardcode,
  clientid,
  create_by,
  area,
  puesto,
  menu_permisos
) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({
      snu_usuario_snu_id: userid,
      snu_nombre: nombre,
      snu_primer_apellido: apellido1,
      snu_segundo_apellido: apellido2,
      snu_correo_electronico: correo,
      snu_direccion: "",
      snu_telefono: telefono,
      snu_usuario: usuario,
      snu_cmm_estatus_id: 1000048,
      snu_cardcode: cardcode,
      snu_sn_socio_de_negocio_id: clientid,
      snu_snu_usuario_snu_creador_id: create_by,
      snu_super_usuario: false,
      snu_area: area,
      snu_puesto: puesto,
      snu_menu_roles: menu_permisos,
    }),
  };
  return fetch(
    `${API_URL}/socio_negocio_usuarios/update_SN_token`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((json) => {
      return json;
    });
}

function updateUserProfileStatus(
  userid,
  cardcode,
  clientid,
  update_by,
  status
) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({
      snu_usuario_snu_id: userid,
      snu_cmm_estatus_id: status,
      snu_cardcode: cardcode,
      snu_sn_socio_de_negocio_id: clientid,
      snu_snu_usuario_snu_creador_id: update_by,
    }),
  };
  return fetch(
    `${API_URL}/socio_negocio_usuarios/update_SN_token`,
    requestOptions
  )
    .then(handleResponseAlternative)
    .then((json) => {
      return json;
    });
}

function getCotizacionesBySN(sn_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${API_URL}/cotizaciones/list_sn/${sn_id}`, requestOptions)
    .then(handleResponseAlternative)
    .then((client) => {
      return client;
    });
}

function getProyectosBySN(carcode) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cardcodeSocioNegocio: carcode
    }),
  };

  return fetch(
    `${API_URL}/proyectos/listaproyectossocionegocio`,
    requestOptions
  )
  .then(response => response.json())
    .then((response) => {
      return response;
    });
}
function getDetalleProyectosBySN(carcode, idProyecto) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cardcodeSocioNegocio: carcode,
      idProyecto: idProyecto
    }),
  };

  return fetch(
    `${API_URL}/proyectos/listaproductosproyecto`,
    requestOptions
  )
  .then(response => response.json())
    .then((response) => {
      return response;
    });
}

async function addProductToQuotesService(socio_negocio_id, idCotizacion, prod_sku, cantidad,externalProductId ) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      socio_negocio_id,
      idCotizacion,
      prod_sku,
      cantidad,
      externalProductId
    }),
  };

  return await fetch(
    `${API_URL}/cotizaciones/addProductToQuotes`,
    requestOptions
  )
  .then(response => response.json())
    .then((response) => {
      return response;
    });
}

async function updateProductQuantityOfQuoteService(socio_negocio_id, idCotizacion, idProductoCotizacion, cantidad, prod_sku) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      socio_negocio_id,
      idCotizacion,
      idProductoCotizacion,
      cantidad,
      prod_sku
    }),
  };

  return await fetch(
    `${API_URL}/cotizaciones/updateProductQuantityOfQuote`,
    requestOptions
  )
  .then(response => response.json())
    .then((response) => {
      return response;
    });
}

async function deleteProductOfQuotesService(socio_negocio_id, idCotizacion, idProductoCotizacion) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      socio_negocio_id,
      idCotizacion,
      idProductoCotizacion,
    }),
  };

  return await fetch(
    `${API_URL}/cotizaciones/deleteProductOfQuotes`,
    requestOptions
  )
  .then(response => response.json())
    .then((response) => {
      return response;
    });
}

async function updateDeliberyOfQuoteService(socio_negocio_id, idCotizacion, idTipoEnvio, idUbicacion) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      socio_negocio_id,
      idCotizacion,
      idTipoEnvio,
      idUbicacion
    }),
  };

  return await fetch(
    `${API_URL}/cotizaciones/updateDeliberyOfQuotes`,
    requestOptions
  )
  .then(response => response.json())
    .then((response) => {
      return response;
    });
}

function getCotizacionDetalle(idCot, body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body)
  };

  return fetch(
    `${API_URL}/cotizaciones/cotizacion_detalle/${idCot}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}


function finishCotizacionCart(body) {
  const requestOptions = {
    method: "POST", 
    headers: authHeader(),
    body: JSON.stringify(body)
  };

  return fetch(
    `${API_URL}/finish-quotes/finish-quotes-cart`,
    requestOptions
  )
    .then((response)=> response.json())
    .then((response) => {
      return response;
    });
}

function sendCotToCart(idCot, idUser) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cot_cotizacion_id: idCot,
      cot_usu_usuario_modificador_id: idUser,
    }),
  };

  return fetch(
    `${API_URL}/cotizaciones_proyectos/to_car_shop_sn`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function deleteCot(id, idUser) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    body: JSON.stringify({
      cot_cotizacion_id: id,
      cot_usu_usuario_modificador_id: idUser,
    }),
  };

  return fetch(`${API_URL}/cotizaciones_proyectos/delete_sn`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getJsonPermisosUsuario() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(`${API_URL}/menu_usuario`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

/*  */

function registerNewsletter(email) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      nlt_email: email,
    }),
  };

  return fetch(`${API_URL}/newsletter/add/`, requestOptions)
    .then(handleResponseAlternative)
    .then((response) => {
      return response;
    });
}

function getProyectsByCardcodeSn(cardcode) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cardcodeSocioNegocio: cardcode
    }),
  };

  return fetch(`${API_URL}/proyectos/listaproyectossocionegocio/`, requestOptions)
    .then(handleResponseAlternative)
    .then((response) => {
      return response;
    });
}

function createProyect(cardcode,contacto, telefono, email, final_user, ciudad) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      cardcodeSocioNegocio: cardcode,
      contacto: contacto,
      telefono: telefono,
      correo: email,
      usuario_final: final_user,
      ciudad: ciudad
    }),
  };

  return fetch(`${API_URL}/proyectos/nuevoproyecto/`, requestOptions)
    .then(handleResponseAlternative)
    .then((response) => {
      return response;
    });
}

function deleteCotizacion(id, idUser) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      idCotizacion: id,
      socio_negocio_id: idUser,
    }),
  };

  return fetch(`${API_URL}/cotizaciones/quotation_delete`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function createDuplicateQuote(id, idUser) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      idCotizacion: id,
      socio_negocio_id: idUser,
    }),
  };

  return fetch(`${API_URL}/cotizaciones/duplicate_quote`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}