import React, {useEffect,useState, useRef} from "react"
import { Style } from "./Style"
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Container from '@material-ui/core/Container';
import SectionSpaceTop from "views/Home/Sections/SectionSpaceTop.js";
import { Title } from "ui/title";
import { useAuth } from "hooks/useAuth";
import { Register } from "./Register";
import useCheckMobileScreen from "components/Mobile/Mobile.js";

export const RegisterUser = () =>{
    const classes  = Style()
    const history = useHistory();
    const {setOpenAlert, isFormCompany, setIsFormCompany} = useAuth();
  
    let isMobile = useCheckMobileScreen();
    return (
        <div className={classes.mainBackground}>
        <Container fixed  style={{paddingTop: isMobile ? 20 : 0 }}>
        <SectionSpaceTop />
        {isMobile ? (
          <div className={classes.spaceDiv}>
            <Link className={classes.linkBack} onClick={() => history.goBack()}>
              <ChevronLeftIcon className={classes.linkBackArrow} /> Regresar
            </Link>
            <Typography className={classes.titleMobile}>Nueva cuenta</Typography>
            <Divider className={classes.titleDivider} />
          </div>
        ) : (
          ""
        )}
  
        <div className={classes.accesoCliente} >
          {isMobile ? "" : <Title title="Nueva cuenta" />}
          <div className={classes.clienteNuevoTextDiv}>
            <Typography className={classes.nuevoClienteText}>
              {isMobile
                ? ""
                : `Ya eres cliente Dielsa? Compártenos tus datos para crear tu cuenta. Se dará de alta tu usuario y te llegará via correo electrónico`}
            </Typography>
          </div>
          <Register />
          
        </div>
        </Container>
       
      </div>
    )
}