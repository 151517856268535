import { authHeader } from "../_helpers/auth-header.js";
import { API_URL } from "./api.url.back";

export const productsService = {
  getProductNoUser,
  getReviews,
  createComment,
  getSKUattrib,
  addProductoToWishlist,
  getProductData,
  getProductDataParentAttributes,
  getProductDataOnlyChild,
  getProductFatherID,
  getProductDataSheetLength,
  getProductDataSheet,
};

function getProductNoUser(idProd) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/productos/producto_father_detalle/${idProd}`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getProductData(idProd, idSocioNegocio) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      prod_producto_id: idProd,
      idSocioNegocio: idSocioNegocio,
    }),
  };
 
  //return fetch(`${API_URL}/productos/getProductoPadreDetalleV3`, requestOptions)
  return fetch(`${API_URL}/product/get-product-childs-details`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getProductDataParentAttributes(idProd, idSocioNegocio) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      prod_producto_id: idProd,
      idSocioNegocio: idSocioNegocio,
    }),
  };

  return fetch(`${API_URL}/productos/getProductoPadreDetalleV3OnlyAttributes`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getProductDataOnlyChild(idProd, idSocioNegocio, idProdChild) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      prod_producto_id: idProd,
      idSocioNegocio: idSocioNegocio,
      prod_producto_id_child: idProdChild,
    }),
  };

  return fetch(
   // `${API_URL}/productos/getProductoPadreDetalleV3OneChild`,
   `${API_URL}/product/get-product-details`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    const responseData = {
      error: false,
      data: data,
    };
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function getReviews(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/resenas_productos/list/${id}`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function createComment(idProd, idUser, rating, comment, idUser2) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      rep_prod_producto_id: idProd,
      rep_snu_usuario_snu_id: idUser,
      rep_calificacion: rating,
      rep_comentario: comment,
      rep_usu_usuario_creador_id: idUser2,
    }),
  };

  return fetch(`${API_URL}/resenas_productos/add`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getSKUattrib(idSKU) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      prod_producto_id: idSKU,
    }),
  };

  return fetch(
    `${API_URL}/atributos/getSKUAtributosProductosValoresST`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function addProductoToWishlist(idProduct, wishlist, idUser) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      wl_nombre: wishlist,
      wl_sn_socios_negocio_id: idUser,
      wlp_prod_producto_id: idProduct,
    }),
  };

  return fetch(
    `${API_URL}/wish_list/add_producto_to_wish_SN_Token`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getProductFatherID(idSKU) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(
    `${API_URL}/productos/get_product_father_from_child/${idSKU}`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getProductDataSheetLength(idSKU) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(
    `${API_URL}/files/data_sheet_length/${idSKU}`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return jsonData;
    });
}

function getProductDataSheet(prod_sku, position) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(
    `${API_URL}/files/data_sheet/${prod_sku}/${position}`,
    requestOptions
  )
    .then(handleResponse)
    .then((jsonData) => {
      return `${API_URL.replace("/api", "")}${jsonData.pds_ruta_archivo.replace("./public", "")}`;
    });
}