import React, {
    useState,
    useEffect,
    Fragment,
  } from "react";
  import { userService } from "_services/user.service";
  import { useHistory, useLocation } from "react-router-dom";  
  // @material-ui/core components
  import Container from '@material-ui/core/Container';
  
  import "assets/custom/Login.css";
  // @material-ui/
  import TextField from "@material-ui/core/TextField";
  import Button from "@material-ui/core/Button";
  import Typography from "@material-ui/core/Typography";
  import { Divider } from "@material-ui/core";
  import Link from "@material-ui/core/Link";
  import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
  // core components
  import useCheckMobileScreen from "components/Mobile/Mobile.js";
  import SectionSpaceTop from "views/Home/Sections/SectionSpaceTop.js";  
  // sections for this page
  import { useAuth } from "hooks/useAuth";
  import { Title } from "ui/title";
  import {Style} from "./Style"
export const Recovery =  () =>{
    const classes = Style()
    const history = useHistory();
    const location = useLocation();
    const {setOpenAlert} = useAuth();
    let isMobile = useCheckMobileScreen();  
    const [token, setToken] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    useEffect(() => {
      userService.logout();
      setToken(location.search.split("?")[1]);
    }, []);
  
    const handleChange = (event) => {
      if (event.target.name === "email") {
        setEmail(event.target.value);
      } else if (event.target.name === "password") {
        setPassword(event.target.value);
      } else {
        setConfirmPassword(event.target.value);
      }
    };
  
    const recoveryPassword = async (ev) => {
      ev.preventDefault();
      const pass_recovery_response = await userService.recoveryPassword(email);
  
      if (pass_recovery_response.error) {
        setOpenAlert({
          message:
            "El email introducido no esta asociado a ningún usuario activo de la tienda.",
          type: "error",
          open: true,
        });
      } else {
        setOpenAlert({
          message:
            "Un email ha sido enviado al correo proporcionado. Verifiquelo y siga los pasos indicados para finalmente poder acceder a su cuenta.",
          type: "info",
          open: true,
        });
      }
    };
  
    const changePassword = async (ev) => {
      ev.preventDefault();
  
      if (password !== confirmPassword) {
        setOpenAlert({
          message: "Las contraseñas introducidas no coinciden.",
          type: "error",
          open: true,
        });
      } else {
        const pass_update_response = await userService.recoveryPasswordSuccess(
          password,
          confirmPassword,
          token
        );
        if (pass_update_response.error) {
          setOpenAlert({
            message: "Hubo un error al intentar actualizarce la contraseña.",
            type: "error",
            open: true,
          });
        } else {
          setOpenAlert({
            message: "La contraseña fue actualizada exitosamente.",
            type: "info",
            open: true,
          });
          setTimeout(() => {
            //success in change password? then redirect to login
            history.push("/login");
          }, 7000);
        }
      }
    };
  
    return (
      <div className={classes.mainBackground}>
        <Container fixed  style={{paddingTop: isMobile ? 20 : 0 }}>
        <SectionSpaceTop />
        {isMobile ? (
          <div className={classes.spaceDiv}>
            <Link href="/" className={classes.linkBack} onClick={() => history.goBack()}>
              <ChevronLeftIcon className={classes.linkBackArrow} /> Regresar
            </Link>
            <Typography className={classes.titleMobile}>
              Recuperación de contraseña
            </Typography>
            <Divider className={classes.titleDivider} />
          </div>
        ) : (
          ""
        )}
        {token ? (
          <form className={classes.accesoCliente} onSubmit={changePassword}>
            {isMobile ? "" : <Title title="Recuperación de contraseña" />}
            <div className={classes.clienteNuevoTextDiv}>
              <Typography className={classes.nuevoClienteText}></Typography>
            </div>
            <div className={classes.spaceDiv}>
              <div className={classes.inputsDiv}>
                <TextField
                  required
                  variant="outlined"
                  className={classes.inputs}
                  size="small"
                  id="password"
                  name="password"
                  type="password"
                  label="Contrase&ntilde;a"
                  value={password}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.inputsDiv}>
                <TextField
                  required
                  variant="outlined"
                  className={classes.inputs}
                  size="small"
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  label="Confirmar contrase&ntilde;a"
                  value={confirmPassword}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={classes.buttonDiv}>
              <Button
                variant="contained"
                className={classes.buttons}
                type="submit"
              >
                Actualizar
              </Button>
            </div>
          </form>
        ) : (
          <form className={classes.accesoCliente} onSubmit={recoveryPassword}>
            {isMobile ? "" : <Title title="Recuperación de contraseña" />}
            <div className={classes.clienteNuevoTextDiv}>
              <Typography className={classes.nuevoClienteText}>
                Escribe tu correo electrónico y te enviaremos un enlace para
                reestablecer tu contraseña.
              </Typography>
            </div>
            <div className={classes.spaceDiv}>
              <div className={classes.inputsDiv}>
                <TextField
                  required
                  variant="outlined"
                  className={classes.inputs}
                  size="small"
                  id="email"
                  name="email"
                  type="email"
                  label="Correo electrónico"
                  value={email}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={classes.buttonDiv}>
              <Button
                variant="contained"
                className={classes.buttons}
                type="submit"
              >
                Enviar
              </Button>
            </div>
          </form>
        )}
  </Container>
        {isMobile ? (
          <Fragment>
          
          
          </Fragment>
        ) : (
          <Fragment>
          
         
          </Fragment>
        )}
      </div>
    );
}