import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Container from '@material-ui/core/Container';

import useCheckMobileScreen from "components/Mobile/Mobile.js";
import SectionProductDescription from "../SectionProductDescription.js";
import SectionProductDataSheet from "../SectionProductDataSheet.js";
import SectionProductClientsComments from "../SectionProductClientsComments/SectionProductClientsComments.js";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const useStyles = makeStyles((styles) => ({
  container: {
    backgroundColor: "#fff",
  },
  tabHeader: {
    backgroundColor: "#fff",
    color: "#0b3196",
    "& div.MuiTabs-scroller": {
      position: "absolute",
      width: "calc(75% - 2rem)",
    },
    
    [styles.breakpoints.down("md")]: {
      "& div.MuiTabs-scroller": {
        position: "relative",
      },
    },
  },
  indicatorBkg: {
    //background: "#40df00",
    background: "transparent",
    borderTop: "15px solid #40df00",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    width: "0 !important",
    height: 0,
    marginLeft: 70,
    position: "relative",
    [styles.breakpoints.down("md")]: {
      position: "absolute",
      top:"35px"
    },
    bottom: "-10px",
    zIndex: "02",
  },
  indicatorBkgMob: {
    //background: "#40df00",
    background: "transparent",
    borderTop: "15px solid #40df00",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    width: "0 !important",
    height: 0,
    marginLeft: 70,
    position: "relative",
    [styles.breakpoints.down("md")]: {
      position: "absolute",
      marginTop:"15px !important"
    },
    bottom: "-10px",
    zIndex: "02",
  },
  tabBarDiv: {
    backgroundColor: "#fff",
    boxShadow: "none",
    borderBottom: "4px solid #40df00",
  },
  tabTitle: {
    fontSize: "22px",
    fontFamily: "'Centrale Sans Medium'",
    color: "#444444",
    paddingLeft: 0,
    paddingRight: 50,
    maxWidth: "none",
    [styles.breakpoints.down("sm")]: {
      fontSize: "18px",
      padding: "10px 0px",
    },
  },

  descrDiv: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 30,
    paddingLeft: 15,
    paddingRight: 15,

    textAlign: "justify",
    fontWeight: 400,
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "24px",
  },
}));

export default function SectionProductTabs(props) {
  const classes = useStyles();
  let isMobile = useCheckMobileScreen();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.container}>
      <Container fixed>
      <AppBar position="static" className={classes.tabBarDiv}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="elementos"
          className={classes.tabHeader}
          classes={{ indicator: window.innerWidth <= 959 ? classes.indicatorBkgMob:classes.indicatorBkg }}
          orientation={window.innerWidth <= 959 ? "vertical" : "horizontal"}
        >
          <Tab
            label="DESCRIPCIÓN"
            className={classes.tabTitle}
            {...a11yProps(0)}
          />
          <Tab
            label="ESPECIFICACIONES"
            className={classes.tabTitle}
            {...a11yProps(1)}
          />
          <Tab
            label="RESEÑAS Y COMENTARIOS"
            className={classes.tabTitle}
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <TabPanel
        value={value}
        index={0}
        style={{ marginTop: 15, textAlign: "justify" }}
      >
        <SectionProductDescription
          productDetails={props.productDetails}
          className={classes.descrDiv}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SectionProductDataSheet productDetails={props.productDetails} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SectionProductClientsComments productDetails={props.productDetails} />
      </TabPanel>


     
    



</Container>
    </div>
  );
}
