import React, {
  useState,
  useEffect,
  Fragment,
} from "react";
import { API_URL } from "../../../_services/api.url.back.img";
import { useHistory } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import { getPriceSapFormat } from "helpers/formatPrice";
import iconEliminar from "../../../assets/img/Dielsa/Icon_Eliminar.svg";

// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SectionBreadcrumb from "views/Home/Sections/SectionBreadcrumb.js";

// @material-ui/
import Typography from "@material-ui/core/Typography";
import { Divider, Hidden } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Button } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
// core components
import useCheckMobileScreen from "components/Mobile/Mobile.js";
import { wishlistService } from "_services/wishlist.service";
import { shoppingcartServices } from "_services/shoppingcart.services";
import { useAuth } from "hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    margin: "auto",
    width: "95%",
    paddingBottom: 30,
  },
  spaceDiv: {
    marginTop: 15,
    marginBottom: 15,
  },
  linkBack: {
    fontFamily: "'Centrale Sans Medium'",
    color: "#0B3196",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "19px",
    textAlign: "center",
    paddingLeft: "30px",
    position: "relative",
  },
  linkBackArrow: {
    position: "absolute",
    left: "5px",
    bottom: "-2px",
  },
  title: {
    fontFamily: "'Centrale Sans Bold'",
    textTransform: "uppercase",
    fontSize: 24,
    lineHeight: "29px",
    textAlign: "justify",
    color: "#0b3196",
    paddingTop: 20,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  titleDivider: {
    border: "1px solid #0B3196",
    width: "95%",
    margin: "auto",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: "30px 0",
  },
  //formData
  formData: {
    backgroundColor: "#F5F5F5",
    borderRadius: 2,
    margin: "10px 0",
    border: "1px solid #EAEAEA",
    maxHeight: 500,
    overflowY: "auto",
  },
  formDataTitle: {
    color: "#0C2D9C",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 22,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "27px",
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  formDataSubTitleDes: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 18,
    textAlign: "justify",
  },
  gridContent: {
    paddingBottom: 30,
  },
  buttonDelete: {
    border: "none",
    color: "#8e8a8a",
    backgroundColor: "transparent",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    margin: "auto",
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  buttonBack: {
    border: "1px solid",
    color: "#0C2D9C",
    backgroundColor: "transparent",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    margin: 0,
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  buttonUpdate: {
    border: "1px solid",
    color: "#0C2D9C",
    backgroundColor: "transparent",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    margin: "auto",
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  buttonSemaforo: {
    border: "none",
    backgroundColor: "#0C2D9C",
    color: "#ffffff",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    margin: 10,
    marginLeft: 0,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 5,
    },
  },
  inputs: {
    width: "100%",
    border: "1px solid #EAEAEA",
    borderRadius: "2px",
    backgroundColor: "#FFFFFF",
  },
  //alerts
  alerts: {
    color: "#FFFFFF",
    backgroundColor: "#40DF00",
    fontFamily: "'Centrale Sans Light'",
    fontSize: 22,
    "& .MuiAlert-icon": {
      color: "#FFFFFF",
    },
    "&.MuiAlert-standardError": {
      backgroundColor: "#DF2B2B",
    },
  },
  //defaultAddress
  semaforoGrid: {
    margin: "35px 0 0",
  },
  semaforoDiv: {
    border: "none",
    backgroundColor: "rgba(60,219,192,0.14)",
    padding: 20,
    margin: "10px 0",
    borderRadius: 3,
  },
  filterGrid: {
    border: "none",
    backgroundColor: "#0C2D9C",
    borderRadius: 2,
    height: 55,
    "& p": {
      color: "#FFFFFF",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: 16,
      padding: "15px 8px",
    },
  },
  defaultAlias: {
    color: "#0C2D9C",
    fontFamily: "'Centrale Sans Bold'",
    fontSize: 18,
    fontWeight: "bold",
    letterSpacing: 0,
    lineHeight: "23px",
  },
  defaultAddress: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    fontWeight: "500",
    letterSpacing: 0,
    lineHeight: "19px",
  },
  buttonBackDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "10px 0",
  },
  actionButtonsDes: {
    display: "inline-flex",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-around",
    },
  },
  actionButtonsCleanFilter: {
    display: "flex",
    justifyContent: "flex-end",
  },
  actionButtonsMov: {},
  //selecte
  formControl: {
    minWidth: "25%",
    [theme.breakpoints.down("sm")]: {
      minWidth: "50%",
    },
  },
  selects: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #e8e8e8",
    borderRadius: 4,
    height: 40,
    fontFamily: "'Centrale Sans Regular'",
    position: "relative",
    top: 6,
    left: 6,
  },
  dowloadLinks: {
    borderRadius: 2,
    backgroundColor: "#40DF00",
    color: "#ffffff",
    padding: "5px 10px",
    marginRight: 5,
    "&:hover": {
      backgroundColor: "#40DF00",
      color: "#ffffff",
    },
  },
  billStatus: {
    color: "#40DF00",
  },
  //table destopk
  tableHead: {
    backgroundColor: "#0B3196",
    color: "#FFFFFF",
    padding: "10px 5px 0 10px",
    fontFamily: "'Centrale Sans Regular'",
    border: "none",
    fontSize: 16,
  },
  tableRow: {
    margin: "5px 0",
    padding: "5px 10px 0px 10px",
    fontFamily: "'Centrale Sans Regular'",
    border: "none",
    borderBottom: "1px solid #EAEAEA",
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      padding: "2px 0 2px 4px",
    },
  },
  productName: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 14,
    textAlign: "justify",
    paddingRight: 15,
  },
  prodQty: {
    fontFamily: "'Centrale Sans Light'",
    marginLeft: 5,
  },
  basePrice: {
    textDecoration: "line-through",
    fontFamily: "'Centrale Sans Light'",
    fontSize: 12,
  },
  listPrice: {
    fontFamily: "'Centrale Sans Light'",
    fontSize: 14,
  },
  prodRow: {
    padding: 5,
    margin: "10px 0",
  },
  customIconDelete: {
    width: 25,
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonUpdateSecond: {
    border: "1px solid",
    backgroundColor: "#0C2D9C",
    color: "#ffffff",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
}));

export default function ProfileWishList(props) {
  const {setOpenAlertF} = useAuth();
  const classes = useStyles();
  const confirm = useConfirm();
  // const { ...rest } = props;
  let history = useHistory();
  const [user, setUser] = useState({});
  const [client, setClient] = useState({});
  const [permisosUser, setPermisosUser] = useState({});
  const [dataBreadCrumb, setDataBreadCrumb] = useState([]);

  let isMobile = useCheckMobileScreen();

  const getPermisos = async (selectedIndex) => {
    if (JSON.parse(localStorage.getItem("user"))) {
      if (JSON.parse(localStorage.getItem("user")).usuario.snu_menu_roles) {
        let result_permiso;
        result_permiso = await JSON.parse(
          localStorage.getItem("user")
        ).usuario.snu_menu_roles.find((item) => item.key_id === selectedIndex);

        if (result_permiso === undefined) {
          JSON.parse(localStorage.getItem("user")).usuario.snu_menu_roles.map(
            (modulo) => {
              if (modulo.submenu) {
                result_permiso = modulo.submenu.find(
                  (item) => item.key_id === selectedIndex
                );
              }
            }
          );
        }
        setPermisosUser(result_permiso);
      }
    }
  };

  const [wishlist, setWishlist] = useState({});

  useEffect(() => {
    setDataBreadCrumb(() => [
      {
        name: "INICIO",
        link: "/",
      },
      {
        name: "MIS FAVORITOS",
        link: "",
      }
    ]);
  }, []);

  const getWishlist = async () => {
    const wishlist_response = await wishlistService.getWishlistByClientId(
      user.snu_sn_socio_de_negocio_id,
      client.sn_lista_precios || 1
    );
    if (!wishlist_response.error) {
      setWishlist(wishlist_response.data.constWishList);
    }
  };

  useEffect(() => {
    //getWishlist();
  }, []);

  const deleteProduct = async (prod) => {
    const delete_item_response = await wishlistService.deleteItemWishList(
      user.snu_sn_socio_de_negocio_id,
      prod.wlp_prod_producto_id
    );
    if (delete_item_response.error) {
      setOpenAlertF({
        message:
          "Hubo un error eliminando el producto de la lista de favoritos.",
        type: "error",
        open: true,
      });
    } else {
      setOpenAlertF({
        message: "El producto ha sido elimnado de la lista de favoritos.",
        type: "success",
        open: true,
      });
      getWishlist();
    }
  };

  const createCart = async (clientid, prodid) => {
    const cart_create_response = await shoppingcartServices.createCartSN(
      clientid,
      prodid,
      1
    );
  };

  const addProductToCart = async (cartid, productid) => {
    const cart_item_response = await shoppingcartServices.addProduct(
      cartid,
      productid,
      1
    );
  };

  const sendWishlistToCart = async () => {
    let countProducts = wishlist.productos.length;
    let productsAdd = wishlist.productos;

    if (!localStorage.getItem("shoppingcart")) {
      await createCart(
        user.snu_sn_socio_de_negocio_id,
        productsAdd[0].wlp_prod_producto_id
      );
      countProducts--;

      productsAdd.shift();

      productsAdd.map(async (prod) => {
        await addProductToCart(
          JSON.parse(localStorage.getItem("shoppingcart")).carrito_de_compra
            .cdc_carrito_de_compra_id,
          prod.wlp_prod_producto_id
        );
        countProducts--;

        if (countProducts === 0) {
          setOpenAlertF({
            message:
              "Se agregaron los productos de la lista de deseos al carrito.",
            type: "success",
            open: true,
          });
          shoppingcartServices.getCart(
            JSON.parse(localStorage.getItem("shoppingcart")).carrito_de_compra
              .cdc_carrito_de_compra_id
          );
          getWishlist();
        } else {
          setOpenAlertF({
            message: "Hubo un error agregando los productos al carrito.",
            type: "error",
            open: true,
          });
        }
      });
    } else {
      productsAdd.forEach(async (prod) => {
        await addProductToCart(
          JSON.parse(localStorage.getItem("shoppingcart")).carrito_de_compra
            .cdc_carrito_de_compra_id,
          prod.wlp_prod_producto_id
        );
        countProducts--;

        if (countProducts === 0) {
          setOpenAlertF({
            message:
              "Se agregaron los productos de la lista de deseos al carrito.",
            type: "success",
            open: true,
          });
          shoppingcartServices.getCart(
            JSON.parse(localStorage.getItem("shoppingcart")).carrito_de_compra
              .cdc_carrito_de_compra_id
          );
          getWishlist();
        } else {
          setOpenAlertF({
            message: "Hubo un error agregando los productos al carrito.",
            type: "error",
            open: true,
          });
        }
      });
    }
  };

  return (
    <div className={classes.root}>
  

      <div className={classes.spaceDiv}>
        <SectionBreadcrumb route={dataBreadCrumb} />
        <Typography className={classes.title}>Mi lista de favoritos</Typography>
        {isMobile ? <Divider className={classes.titleDivider} /> : ""}
      </div>
      <Grid container>
      <Typography style={{textAlign:'center'}}>
                Por el momento no podemos agregar productos a favoritos.
      </Typography>
      </Grid>
      <Grid container spacing={0} style={{visibility: 'hidden'}}>
        <Grid item xs={12} className={classes.filterGrid}>
          <Typography>
            Cantidad de artículos:{" "}
            {Object.getOwnPropertyNames(wishlist).length !== 0
              ? wishlist.productos
                ? wishlist.productos.length
                : ""
              : ""}
            {/* {wishlist.productos ? wishlist.productos.length : ""} */}
          </Typography>
        </Grid>

        <Grid container className={classes.formData}>
          <Grid item xs={12}>
            <div className={classes.productsList}>
              {wishlist.productos
                ? wishlist.productos.map((prod) => {
                    return (
                      <Grid container className={classes.prodRow}>
                        <Grid xs={2}>
                          <Avatar
                            alt="Product"
                            src={
                              prod.ImagenProducto
                                ? `${API_URL}${
                                    prod.ImagenProducto.imgprod_ruta_archivo.split(
                                      "public"
                                    )[1]
                                  }`
                                : "http://wws.com.pa/wp-content/plugins/wordpress-ecommerce/marketpress-includes/images/default-product.png"
                            }
                          />
                        </Grid>
                        {isMobile ? (
                          <Fragment>
                            <Grid xs={10}>
                              <Grid container>
                                <Grid xs={12}>
                                  <Typography className={classes.productName}>
                                    {`${prod.prod_nombre} [${prod.prod_sku}]`}
                                  </Typography>
                                </Grid>
                                <Grid xs={12}>
                                  <Grid container>
                                    <Grid xs={7}></Grid>
                                    <Grid xs={3}>
                                      <Typography className={classes.listPrice}>
                                        {getPriceSapFormat(prod.pl_precio_producto)}
                                      </Typography>
                                    </Grid>
                                    <Grid xs={2}>
                                      {props.permisos.permisos
                                        ? props.permisos.permisos.map((p) => {
                                            if (
                                              p.key === "edit" &&
                                              p.permiso === true
                                            ) {
                                              return (
                                                <Tooltip title="Eliminar">
                                                  <IconButton
                                                    style={{
                                                      position: "relative",
                                                      bottom: 10,
                                                    }}
                                                    aria-label="delete"
                                                    onClick={() => {
                                                      deleteProduct(prod);
                                                    }}
                                                  >
                                                    {/* <DeleteIcon /> */}
                                                    <img
                                                      src={iconEliminar}
                                                      className={
                                                        classes.customIconDelete
                                                      }
                                                    />
                                                  </IconButton>
                                                </Tooltip>
                                              );
                                            }
                                          })
                                        : ""}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <Grid xs={8}>
                              <Typography className={classes.productName}>
                                {`${prod.prod_nombre} [${prod.prod_sku}]`}
                              </Typography>
                            </Grid>
                            <Grid xs={1}>
                              <Typography className={classes.listPrice}>
                                {getPriceSapFormat(prod.pl_precio_producto)}
                              </Typography>
                            </Grid>
                            <Grid xs={1}>
                              {props.permisos.permisos
                                ? props.permisos.permisos.map((p) => {
                                    if (
                                      p.key === "edit" &&
                                      p.permiso === true
                                    ) {
                                      return (
                                        <Tooltip title="Eliminar">
                                          <IconButton
                                            style={{
                                              position: "relative",
                                              bottom: 10,
                                            }}
                                            aria-label="delete"
                                            onClick={() => {
                                              deleteProduct(prod);
                                            }}
                                          >
                                            {/* <DeleteIcon /> */}
                                            <img
                                              src={iconEliminar}
                                              className={
                                                classes.customIconDelete
                                              }
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      );
                                    }
                                  })
                                : ""}
                            </Grid>
                          </Fragment>
                        )}
                        <Grid xs={12}>
                          <Divider style={{ backgroundColor: "#EAEAEA" }} />
                        </Grid>
                      </Grid>
                    );
                  })
                : ""}
            </div>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          style={
            isMobile
              ? { display: "flex", justifyContent: "center" }
              : { display: "flex", justifyContent: "flex-end" }
          }
        >
          <Button
            variant="outlined"
            color="primary"
            className={classes.buttonUpdateSecond}
            type="submit"
            onClick={() => sendWishlistToCart()}
          >
            Enviar a mi carrito
          </Button>
        </Grid>
      </Grid>

      
    </div>
  );
}
