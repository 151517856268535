import React, {
  useState,
  useRef,
  useEffect
} from "react";
import { userService } from "_services/user.service";
import { useHistory, useLocation } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Button } from "@material-ui/core";
// import TextField from "@material-ui/core/TextField";
import TextField from '@mui/material/TextField';
import { useAuth } from "hooks/useAuth";
// core components
import useCheckMobileScreen from "components/Mobile/Mobile.js";
import SectionBreadcrumb from "views/Home/Sections/SectionBreadcrumb.js";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    margin: "auto",
    width: "95%",
    paddingBottom: 30,
  },
  spaceDiv: {
    marginTop: 15,
    marginBottom: 15,
  },
  linkBack: {
    fontFamily: "'Centrale Sans Medium'",
    color: "#0B3196",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "19px",
    textAlign: "center",
    paddingLeft: "30px",
    position: "relative",
  },
  linkBackArrow: {
    position: "absolute",
    left: "5px",
    bottom: "-2px",
  },
  title: {
    fontFamily: "'Centrale Sans Bold'",
    textTransform: "uppercase",
    fontSize: 24,
    lineHeight: "29px",
    textAlign: "justify",
    color: "#0b3196",
    paddingTop: 20,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  titleDivider: {
    border: "1px solid #0B3196",
    width: "95%",
    margin: "auto",
  },
  //avatar
  avatar: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: "30px 0",
  },
  //formData
  formData: {
    backgroundColor: "#F5F5F5",
    borderRadius: 2,
    margin: "10px 0",
    border: "1px solid #EAEAEA",
    "& *": {
      margin: "3px 8px 2px 3px",
      padding: 0,
      fontFamily: "'Centrale Sans Regular'",
      fontSize: 14,
      border: "none",
    },
  },
  formDataTitle: {
    color: "#0B3196",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "23px",
    textAlign: "center",
  },
  formDataTitleDes: {
    color: "#0C2D9C",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 22,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "27px",
    textAlign: "justify",
  },
  formDataSubTitleDes: {
    color: "#0C2D9C",
    textTransform: "uppercase",
  },
  gridContent: {
    paddingBottom: 30,
  },
  buttonUpdate: {
    border: "1px solid",
    color: "#0C2D9C",
    backgroundColor: "transparent",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  buttonUpdateSecond: {
    border: "1px solid",
    backgroundColor: "#0C2D9C",
    color: "#ffffff",
    textTransform: "none",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "#3e50c5",
      color: "#ffffff",
    },
  },
  inputs: {
    width: "100%",
    // border: "1px solid #EAEAEA",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
  },
  //alerts
  alerts: {
    color: "#FFFFFF",
    backgroundColor: "#40DF00",
    fontFamily: "'Centrale Sans Light'",
    fontSize: 22,
    "& .MuiAlert-icon": {
      color: "#FFFFFF",
    },
    "&.MuiAlert-standardError": {
      backgroundColor: "#DF2B2B",
    },
  },
}));

export default function ProfileDashboard(props) {
  const classes = useStyles();
  let history = useHistory();
  const location = useLocation();
  const {setOpenAlert, getUserData} = useAuth();
  let isMobile = useCheckMobileScreen();
  const [user, setUser] = useState({});
  const [dataBreadCrumb, setDataBreadCrumb] = useState([]);
  const [permisosUser, setPermisosUser] = useState({});
  const [openFormData, setOpenFormData] = useState(false);
  const [openFormDataBilling, setOpenFormDataBilling] = useState(false);
  const [openFormPassword, setOpenFormPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [confirmPass, setConfirmPass] = useState(true);
  const [passWarning, setPassWarning] = useState({
    passDiff: false,
    passConfirm: false,
  });
  let dataUser = getUserData()

  useEffect(() => {
    if(currentPassword !== newPassword && newPassword !== '') {
      setPassWarning((e) => ({ ...e, passDiff: true }))
    }

    if(newPassword === newPasswordConfirmation
      && newPassword !== '' && newPasswordConfirmation !== '') {
      setPassWarning((e) => ({ ...e, passConfirm: true }))
    }

    if(currentPassword !== newPassword && newPassword === newPasswordConfirmation
      && currentPassword !== '' && newPassword !== ''
      && newPasswordConfirmation !== '') {
      setConfirmPass(false);
    } else {
      setConfirmPass(true);
    }
  }, [currentPassword, newPassword, newPasswordConfirmation])

  //declare form inputs
  let name = useRef(null);
  let firstlastname = useRef(null);
  let secondlastname = useRef(null);
  let email = useRef(null);
  let contact = useRef(null);
  let emailBilling = useRef(null);

  useEffect(() => {
    if (!localStorage.getItem("user")) {
        //redirect to login
        history.push("/login");
    } else {
        setUser(JSON.parse(localStorage.getItem("user")).usuario);
    }
    setDataBreadCrumb(() => [
      {
        name: "INICIO",
        link: "/",
      },
      {
        name: "MI CUENTA",
        link: "",
      }
    ]);
    getPermisos();
  }, []);


  const getPermisos = async () => {
    const selectedIndex = location.pathname;
    if (JSON.parse(localStorage.getItem("user"))) {
      if (JSON.parse(localStorage.getItem("user")).usuario.snu_menu_roles) {
        let result_permiso;
        result_permiso = await JSON.parse(
          localStorage.getItem("user")
        ).usuario.snu_menu_roles.find((item) => {
          return `/myprofile/${item.key}` === selectedIndex
        });

        if (result_permiso === undefined) {
          JSON.parse(localStorage.getItem("user")).usuario.snu_menu_roles.map(
            (modulo) => {
              if (modulo.submenu) {
                result_permiso = modulo.submenu.find(
                  (item) => `/myprofile/${item.key}` === selectedIndex
                );
              }
            }
          );
        }
        setPermisosUser(result_permiso);
      }
    }
  };

  const handleSubmitUpdateData = async (event) => {
    event.preventDefault();

    const update_user_client_response = await userService.updateUserClientData(
      user.snu_usuario_snu_id,
      event.target.name.value,
      event.target.firstlastname.value,
      event.target.secondlastname.value,
      event.target.email.value,
      event.target.contact.value,
      user.snu_usuario_snu_id
    );

    if (update_user_client_response.error) {
      setOpenAlert({
        message: "Hubo un error actualizando sus datos.",
        type: "error",
        open: true,
      });
    } else {
      setOpenAlert({
        message:
          "Sus datos fueron actualizados correctamente. Cierre e inicie sesion nuevamente para visualizar los cambios.",
        type: "success",
        open: true,
      });
      const response = await userService.getClientById(
        // user_login_response.data.usuario.snu_sn_socio_de_negocio_id
        user.snu_sn_socio_de_negocio_id
      );

      //if success the open the vie form section
      setOpenFormData(false);
    }
  };

  const handleSubmitUpdatePassword = async (event) => {
    event.preventDefault();

    if (newPassword === newPasswordConfirmation) {
      const update_user_client_response = await userService.updateUserClientPassword(
        user.snu_usuario_snu_id,
        currentPassword,
        newPassword,
        user.snu_usuario_snu_id
      );

      if (update_user_client_response.error) {
        setOpenAlert({
          message: `Hubo un error actualizando sus clave. ${update_user_client_response.data.message}.`,
          type: "error",
          open: true,
        });
      } else {
        setOpenAlert({
          message: "Su clave de acceso fue actualizada correctamente.",
          type: "success",
          open: true,
        });
        //if success the open the vie form section
        setOpenFormPassword(false);
      }
    } else {
      setOpenAlert({
        message: "La clave introducida y su confirmacion deben ser iguales.",
        type: "error",
        open: true,
      });
    }
  };

  const handleSubmitUpdateDataBilling = async (event) => {
    event.preventDefault();
    const update_user_client_response = await userService.updateUserClientEmailBilling(
      event.target.emailBilling.value,
      JSON.parse(localStorage.getItem("client")).listaSnbyid[0]
        .sn_socios_negocio_id
    );

    if (update_user_client_response.error) {
      setOpenAlert({
        message: "Hubo un error actualizando sus datos.",
        type: "error",
        open: true,
      });
    } else {
      setOpenAlert({
        message:
          "Sus datos fueron actualizados correctamente. Cierre e inicie sesion nuevamente para visualizar los cambios.",
        type: "success",
        open: true,
      });
      const response = await userService.getClientById(
        user.snu_sn_socio_de_negocio_id
      );

      //if success the open the vie form section
      setOpenFormDataBilling(false);
    }
  };

  return (
    <div className={classes.root}>
     

      <div className={classes.spaceDiv}>
        <SectionBreadcrumb route={dataBreadCrumb} />
        <Typography className={classes.title}>
          {isMobile ? "Mi cuenta" : "Mi perfil"}
        </Typography>
        {isMobile ? <Divider className={classes.titleDivider} /> : ""}
      </div>

      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.avatar}>
          <Avatar
            alt={`${user.snu_nombre} ${user.snu_primer_apellido}`}
            src="/static/images/avatar/1.jpg"
            className={classes.large}
          />
        </Grid>

        <Grid item xs={12} className={classes.gridContent}>
          {isMobile ? (
            ""
          ) : (
            <Typography className={classes.formDataTitleDes}>
              Datos Personales
            </Typography>
          )}
          <div className={classes.formData}>
            {openFormData ? (
              <form onSubmit={handleSubmitUpdateData}>
                <Grid container spacing={0}>
                  {isMobile ? (
                    <Grid item xs={12}>
                      <Typography className={classes.formDataTitle}>
                        Datos Personales
                      </Typography>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid item xs={12}>
                    {isMobile ? (
                      <Grid container>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            variant="outlined"
                            className={classes.inputs}
                            size="small"
                            inputRef={name}
                            defaultValue={`${user.snu_nombre}`}
                            id="name"
                            name="name"
                            type="text"
                            label=""
                          />
                          <TextField
                            required
                            fullWidth
                            variant="outlined"
                            className={classes.inputs}
                            size="small"
                            inputRef={firstlastname}
                            defaultValue={`${user.snu_primer_apellido}`}
                            id="firstlastname"
                            name="firstlastname"
                            type="text"
                            label=""
                          />
                          <TextField
                            required
                            fullWidth
                            variant="outlined"
                            className={classes.inputs}
                            size="small"
                            inputRef={secondlastname}
                            defaultValue={`${
                              user.snu_segundo_apellido
                                ? user.snu_segundo_apellido
                                : ""
                            }`}
                            id="secondlastname"
                            name="secondlastname"
                            type="text"
                            label=""
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container>
                        <Grid item xs={2}>
                          <Typography className={classes.formDataSubTitleDes}>
                            NOMBRE
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            required
                            fullWidth
                            variant="outlined"
                            className={classes.inputs}
                            size="small"
                            inputRef={name}
                            defaultValue={`${user.snu_nombre}`}
                            id="name"
                            name="name"
                            type="text"
                            label=""
                          />
                          <TextField
                            required
                            fullWidth
                            variant="outlined"
                            className={classes.inputs}
                            size="small"
                            inputRef={firstlastname}
                            defaultValue={`${user.snu_primer_apellido}`}
                            id="firstlastname"
                            name="firstlastname"
                            type="text"
                            label=""
                          />
                          <TextField
                            required
                            fullWidth
                            variant="outlined"
                            className={classes.inputs}
                            size="small"
                            inputRef={secondlastname}
                            defaultValue={`${
                              user.snu_segundo_apellido
                                ? user.snu_segundo_apellido
                                : ""
                            }`}
                            id="secondlastname"
                            name="secondlastname"
                            type="text"
                            label=""
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    {isMobile ? (
                      <Grid container>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            variant="outlined"
                            className={classes.inputs}
                            size="small"
                            inputRef={email}
                            defaultValue={`${user.snu_correo_electronico}`}
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Email"
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container>
                        <Grid item xs={2}>
                          <Typography className={classes.formDataSubTitleDes}>
                            CORREO
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            required
                            fullWidth
                            variant="outlined"
                            className={classes.inputs}
                            size="small"
                            inputRef={email}
                            defaultValue={`${user.snu_correo_electronico}`}
                            id="email"
                            name="email"
                            type="email"
                            label=""
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    {isMobile ? (
                      <Grid container>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            variant="outlined"
                            className={classes.inputs}
                            size="small"
                            inputRef={contact}
                            defaultValue={`${user.snu_telefono}`}
                            id="contact"
                            name="contact"
                            type="text"
                            placeholder="Contacto"
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container>
                        <Grid item xs={2}>
                          <Typography className={classes.formDataSubTitleDes}>
                            CONTACTO
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            required
                            fullWidth
                            variant="outlined"
                            className={classes.inputs}
                            size="small"
                            inputRef={contact}
                            defaultValue={`${user.snu_telefono}`}
                            id="contact"
                            name="contact"
                            type="text"
                            label=""
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  {isMobile ? (
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.buttonUpdateSecond}
                        type="submit"
                      >
                        Guardar mis datos
                      </Button>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.buttonUpdateSecond}
                        type="submit"
                      >
                        Guardar mis datos
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </form>
            ) : (
              <Grid container spacing={0}>
                {isMobile ? (
                  <Grid item xs={12}>
                    <Typography className={classes.formDataTitle}>
                      Datos Personales
                    </Typography>
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={12}>
                  {isMobile ? (
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography>{`${user.snu_nombre} ${
                          user.snu_primer_apellido
                        } ${
                          user.snu_segundo_apellido
                            ? user.snu_segundo_apellido
                            : ""
                        }`}</Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={2}>
                        <Typography className={classes.formDataSubTitleDes}>
                          NOMBRE
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>{`${user.snu_nombre} ${
                          user.snu_primer_apellido
                        } ${
                          user.snu_segundo_apellido
                            ? user.snu_segundo_apellido
                            : ""
                        }`}</Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={12}>
                  {isMobile ? (
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography>{`${user.snu_correo_electronico}`}</Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={2}>
                        <Typography className={classes.formDataSubTitleDes}>
                          CORREO
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>{`${user.snu_correo_electronico}`}</Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={12}>
                  {isMobile ? (
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography>{`${user.snu_telefono}`}</Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={2}>
                        <Typography className={classes.formDataSubTitleDes}>
                          CONTACTO
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>{`${user.snu_telefono}`}</Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                {permisosUser && permisosUser.permisos
                  ? permisosUser?.permisos?.map((p) => {
                      if (p.key === "edit" && p.permiso === true) {
                        return (
                          <Grid
                            key={p.key}
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            {isMobile ? (
                              <Tooltip title="Modificar mis datos">
                                <IconButton
                                  aria-label="update"
                                  onClick={() => {
                                    setOpenFormData(true);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Button
                                variant="outlined"
                                color="primary"
                                className={classes.buttonUpdate}
                                onClick={() => {
                                  setOpenFormData(true);
                                }}
                              >
                                Modificar mis datos
                              </Button>
                            )}
                          </Grid>
                        );
                      }
                    })
                  : ""}
              </Grid>
            )}
          </div>
        </Grid>

        <Grid item xs={12} className={classes.gridContent}>
          {isMobile ? (
            ""
          ) : (
            <Typography className={classes.formDataTitleDes}>
              Contraseña
            </Typography>
          )}

          <div className={classes.formData}>
            {openFormPassword ? (
              <form onSubmit={handleSubmitUpdatePassword}>
                <Grid container spacing={0}>
                  {isMobile ? (
                    <Grid item xs={12}>
                      <Typography className={classes.formDataTitle}>
                        Contraseña
                      </Typography>
                    </Grid>
                  ) : (
                    ""
                  )}

                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" style={{ color: passWarning.passDiff ? "#2e7d32" : "#f44336" }}>
                          1.- La nueva contraseña debe ser difirente a la contraseña actual.
                        </Typography>
                        <Typography variant="subtitle2" style={{ color: passWarning.passConfirm ? "#2e7d32" : "#f44336" }}>
                          2.- Confirmación de contraseña.
                        </Typography>
                      </Grid>
                      {isMobile ? (
                        <>
                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              variant="standard"
                              className={classes.inputs}
                              size="small"
                              value={currentPassword}
                              onChange={(e) => setCurrentPassword(e.target.value)}
                              id="currentPassword"
                              name="currentPassword"
                              type="password"
                              placeholder="Contraseña actual"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              variant="standard"
                              className={classes.inputs}
                              // size="small"
                              value={newPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                              id="newPassword"
                              name="newPassword"
                              type="password"
                              placeholder="Escribe la contraseña nueva "
                              color={!confirmPass && "success"}
                              focused={!confirmPass}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              variant="standard"
                              className={classes.inputs}
                              size="small"
                              value={newPasswordConfirmation}
                              onChange={(e) => setNewPasswordConfirmation(e.target.value)}
                              id="newPasswordConfirmation"
                              name="newPasswordConfirmation"
                              type="password"
                              placeholder="Confirma contraseña nueva"
                              color={!confirmPass && "success"}
                              focused={!confirmPass}
                            />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={3}>
                                <Typography
                                  className={classes.formDataSubTitleDes}
                                >
                                  Contraseña actual
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  required
                                  fullWidth
                                  variant="standard"
                                  className={classes.inputs}
                                  size="small"
                                  value={currentPassword}
                                  onChange={(e) => setCurrentPassword(e.target.value)}
                                  id="currentPassword"
                                  name="currentPassword"
                                  type="password"
                                  label=""
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={3}>
                                <Typography
                                  className={classes.formDataSubTitleDes}
                                >
                                  Escribe la contraseña nueva
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  required
                                  fullWidth
                                  variant="standard"
                                  className={classes.inputs}
                                  size="small"
                                  value={newPassword}
                                  onChange={(e) => setNewPassword(e.target.value)}
                                  id="newPassword"
                                  name="newPassword"
                                  type="password"
                                  label=""
                                  color={!confirmPass && "success"}
                                  focused={!confirmPass}
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={3}>
                                <Typography
                                  className={classes.formDataSubTitleDes}
                                >
                                  Confirma contraseña nueva
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  required
                                  fullWidth
                                  variant="standard"
                                  className={classes.inputs}
                                  size="small"
                                  value={newPasswordConfirmation}
                                  onChange={(e) => setNewPasswordConfirmation(e.target.value)}
                                  id="newPasswordConfirmation"
                                  name="newPasswordConfirmation"
                                  type="password"
                                  label=""
                                  color={!confirmPass && "success"}
                                  focused={!confirmPass}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>

                  {isMobile ? (
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.buttonUpdateSecond}
                        type="submit"
                        disabled={confirmPass}
                      >
                        Confirmar
                      </Button>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.buttonUpdateSecond}
                        type="submit"
                        disabled={confirmPass}
                      >
                        Confirmar
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </form>
            ) : (
              <Grid container spacing={0}>
                {isMobile ? (
                  <Grid item xs={12}>
                    <Typography className={classes.formDataTitle}>
                      Contraseña
                    </Typography>
                  </Grid>
                ) : (
                  ""
                )}

                <Grid item xs={12}>
                  {isMobile ? (
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography>******</Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={2}>
                        <Typography className={classes.formDataSubTitleDes}>
                          Contraseña actual
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>******</Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                {permisosUser && permisosUser.permisos
                  ? permisosUser.permisos.map((p) => {
                      if (p.key === "edit" && p.permiso === true) {
                        return (
                          <Grid
                            key={p.key}
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            {isMobile ? (
                              <Tooltip title="Modificar contraseña">
                                <IconButton
                                  aria-label="update"
                                  onClick={() => {
                                    setOpenFormPassword(true);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Button
                                variant="outlined"
                                color="primary"
                                className={classes.buttonUpdate}
                                onClick={() => {
                                  setOpenFormPassword(true);
                                }}
                              >
                                Modificar contraseña
                              </Button>
                            )}
                          </Grid>
                        );
                      }
                    })
                  : ""}
              </Grid>
            )}
          </div>
        </Grid>

        <Grid item xs={12} className={classes.gridContent}>
         
          {isMobile ? (
            ""
          ) : (
            <div>
              <Typography className={classes.formDataTitleDes}>
                Datos de facturaci&oacute;n
              </Typography>
              <Typography style={{color:"#3c4858"}}>
                Si necesitas actualizar o modificar tu información de facturación favor de enviar tu constancia de situación fiscal actualizada al correo <a style={{textDecoration: "none", color:"#40DF00"}} href="mailto:pagos@dielsa.com">pagos@dielsa.com</a>
              </Typography>
            </div>
            
          )}

          {dataUser.usuario.TypeUser!=2 && 
          <div className={classes.formData}>
            {openFormDataBilling ? (
              <form onSubmit={handleSubmitUpdateDataBilling}>
                <Grid container spacing={0}>
                  {isMobile ? (
                    <Grid item xs={12}>
                      <Typography className={classes.formDataTitle}>
                        Datos de facturaci&oacute;n
                      </Typography>
                      <Typography >
                        Si necesitas actualizar o modificar tu información de facturación favor de enviar tu constancia de situación fiscal actualizada al correo <a style={{textDecoration: "none", color:"#40DF00"}} href="mailto:pagos@dielsa.com">pagos@dielsa.com</a>
                      </Typography>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid item xs={12}>
                    {isMobile ? (
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography>
                            {localStorage.getItem("client") !== undefined &&
                            localStorage.getItem("client") !== null
                              ? `${
                                  JSON.parse(localStorage.getItem("client"))
                                    .listaSnbyid[0].sn_cardcode
                                }`
                              : "Cargando..."}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container>
                        <Grid item xs={2}>
                          <Typography className={classes.formDataSubTitleDes}>
                            CARDCODE
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography>
                            {localStorage.getItem("client") !== undefined &&
                            localStorage.getItem("client") !== null
                              ? `${
                                  JSON.parse(localStorage.getItem("client"))
                                    .listaSnbyid[0].sn_cardcode
                                }`
                              : "Cargando..."}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {isMobile ? (
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography>
                            {localStorage.getItem("client") !== undefined &&
                            localStorage.getItem("client") !== null
                              ? `${
                                  JSON.parse(localStorage.getItem("client"))
                                    .listaSnbyid[0].sn_razon_social
                                }`
                              : "Cargando..."}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container>
                        <Grid item xs={2}>
                          <Typography className={classes.formDataSubTitleDes}>
                            RAZ&Oacute;N SOCIAL
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography>
                            {localStorage.getItem("client") !== undefined &&
                            localStorage.getItem("client") !== null
                              ? `${
                                  JSON.parse(localStorage.getItem("client"))
                                    .listaSnbyid[0].sn_razon_social
                                }`
                              : "Cargando..."}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {isMobile ? (
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography>
                            {localStorage.getItem("client") !== undefined &&
                            localStorage.getItem("client") !== null
                              ? `${
                                  JSON.parse(localStorage.getItem("client"))
                                    .listaSnbyid[0].sn_direccion_facturacion
                                }`
                              : "Cargando..."}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container>
                        <Grid item xs={2}>
                          <Typography className={classes.formDataSubTitleDes}>
                            DIRECCI&Oacute;N
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography>
                            {localStorage.getItem("client") !== undefined &&
                            localStorage.getItem("client") !== null
                              ? `${
                                  JSON.parse(localStorage.getItem("client"))
                                    .listaSnbyid[0].sn_direccion_facturacion
                                }`
                              : "Cargando..."}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    {isMobile ? (
                      <Grid container>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            variant="outlined"
                            className={classes.inputs}
                            size="small"
                            inputRef={emailBilling}
                            defaultValue={`${
                              localStorage.getItem("client") !== undefined &&
                              localStorage.getItem("client") !== null
                                ? `${
                                    JSON.parse(localStorage.getItem("client"))
                                      .listaSnbyid[0].sn_email_facturacion
                                  }`
                                : "Cargando..."
                            }`}
                            id="emailBilling"
                            name="emailBilling"
                            type="text"
                            placeholder="Contacto"
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container>
                        <Grid item xs={2}>
                          <Typography className={classes.formDataSubTitleDes}>
                            EMAIL
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            required
                            fullWidth
                            variant="outlined"
                            className={classes.inputs}
                            size="small"
                            inputRef={emailBilling}
                            defaultValue={`${
                              localStorage.getItem("client") !== undefined &&
                              localStorage.getItem("client") !== null
                                ? `${
                                    JSON.parse(localStorage.getItem("client"))
                                      .listaSnbyid[0].sn_email_facturacion
                                  }`
                                : "Cargando..."
                            }`}
                            id="emailBilling"
                            name="emailBilling"
                            type="email"
                            placeholder="Email"
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  {isMobile ? (
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.buttonUpdateSecond}
                        type="submit"
                      >
                        Guardar mis datos
                      </Button>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.buttonUpdateSecond}
                        type="submit"
                      >
                        Guardar mis datos
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </form>
            ) : (
              <Grid container spacing={0}>
                {isMobile ? (
                  <Grid item xs={12}>
                    <Typography className={classes.formDataTitle}>
                      Datos de facturaci&oacute;n
                    </Typography>
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={12}>
                  {isMobile ? (
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography>
                          {localStorage.getItem("client") !== undefined &&
                          localStorage.getItem("client") !== null
                            ? `${
                                JSON.parse(localStorage.getItem("client"))
                                  .listaSnbyid[0].sn_cardcode
                              }`
                            : "Cargando..."}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={2}>
                        <Typography className={classes.formDataSubTitleDes}>
                          CARDCODE
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>
                          {localStorage.getItem("client") !== undefined &&
                          localStorage.getItem("client") !== null
                            ? `${
                                JSON.parse(localStorage.getItem("client"))
                                  .listaSnbyid[0].sn_cardcode
                              }`
                            : "Cargando..."}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {isMobile ? (
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography>
                          {localStorage.getItem("client") !== undefined &&
                          localStorage.getItem("client") !== null
                            ? `${
                                JSON.parse(localStorage.getItem("client"))
                                  .listaSnbyid[0].sn_razon_social
                              }`
                            : "Cargando..."}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={2}>
                        <Typography className={classes.formDataSubTitleDes}>
                          RAZ&Oacute;N SOCIAL
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography>
                          {localStorage.getItem("client") !== undefined &&
                          localStorage.getItem("client") !== null
                            ? `${
                                JSON.parse(localStorage.getItem("client"))
                                  .listaSnbyid[0].sn_razon_social
                              }`
                            : "Cargando..."}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {isMobile ? (
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography>
                          {localStorage.getItem("client") !== undefined &&
                          localStorage.getItem("client") !== null
                            ? `${
                                JSON.parse(localStorage.getItem("client"))
                                  .listaSnbyid[0].sn_direccion_facturacion
                              }`
                            : "Cargando..."}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={2}>
                        <Typography className={classes.formDataSubTitleDes}>
                          DIRECCI&Oacute;N
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography>
                          {localStorage.getItem("client") !== undefined &&
                          localStorage.getItem("client") !== null
                            ? `${
                                JSON.parse(localStorage.getItem("client"))
                                  .listaSnbyid[0].sn_direccion_facturacion
                              }`
                            : "Cargando..."}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {isMobile ? (
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography>
                          {localStorage.getItem("client") !== undefined &&
                          localStorage.getItem("client") !== null
                            ? `${
                                JSON.parse(localStorage.getItem("client"))
                                  .listaSnbyid[0].sn_email_facturacion
                              }`
                            : "Cargando..."}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={2}>
                        <Typography className={classes.formDataSubTitleDes}>
                          EMAIL
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>
                          {localStorage.getItem("client") !== undefined &&
                          localStorage.getItem("client") !== null
                            ? `${
                                JSON.parse(localStorage.getItem("client"))
                                  .listaSnbyid[0].sn_email_facturacion
                              }`
                            : "Cargando..."}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                {permisosUser && permisosUser.permisos
                  ? permisosUser?.permisos?.map((p) => {
                      if (p.key === "edit" && p.permiso === true) {
                        return (
                          <Grid
                            key={p.key}
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            {isMobile ? (
                              <Tooltip title="Modificar mis datos">
                                <IconButton
                                  aria-label="update"
                                  onClick={() => {
                                    setOpenFormDataBilling(true);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Button
                                variant="outlined"
                                color="primary"
                                className={classes.buttonUpdate}
                                onClick={() => {
                                  setOpenFormDataBilling(true);
                                }}
                              >
                                Modificar email
                              </Button>
                            )}
                          </Grid>
                        );
                      }
                    })
                  : ""}
              </Grid>
            )}
          </div>}
        </Grid>
      </Grid>
    </div>
  );
}
