import React, { useState, useLayoutEffect } from 'react'

import logoBusiness from 'assets/img/logo2.jpg'

import './Loader.css'

export const Loader = () => {
  const [show, setShow] = useState(true)
  const styl= 'container-home container hide'
  const styl2= 'container-home container'


  useLayoutEffect(() => {
    setTimeout(() => {
      setShow(false)
    }, 1000)
  }, [])

  return (
    <div 
      className={ show? styl2 : styl  }
    >
      <img src={logoBusiness} alt='logo' />
      <p>Cargando</p> 
    </div>
  )
}