import React, { useState, useEffect } from "react";
import { userService } from "_services/user.service";
import { checkoutService } from "_services/checkout.service";
import { useHistory } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { signInService } from "auth/services/signInService";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Link from "@material-ui/core/Link";
import useCheckMobileScreen from "components/Mobile/Mobile.js";
import SectionSpaceTop from "views/Home/Sections/SectionSpaceTop.js";
import { Title } from "ui/title";
import { Style } from "./Style";
import { getTotalCartProducts } from "services/getTotalCartProducts";
import { accountService } from "services/account/account.service"; 
export const SignIn = () => {
  const { setIsLogged, setOpenAlert,setCarritoItems } = useAuth();
  const classes = Style();
  const history = useHistory();
  let isMobile = useCheckMobileScreen();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    userService.logout();
  }, []);

  const handleChange = (event) => {
    event.target.name === "email"
      ? setEmail(event.target.value)
      : setPassword(event.target.value);
  };
  const getProducts = async()=>{
      let countProducts = await getTotalCartProducts()
      setCarritoItems(countProducts)
  }
  const getPeticiones = async (idUser) => {
   // const resultAccount = await accountService.addNewUserInteractions({ type: 5, userId: idUser, value: 1 });
    //const resultAccounts = await accountService.addNewUserInteractions({ type: 4, userId: idUser, value: 5 });
    const resultAccount =accountService.addSessionCount({
      type: 2,
      userId: idUser,
    });
    const resultAccountTime =accountService.addSessionCountTime({type: 2,userId: idUser});
    return resultAccount;
}

const handleSignIn = async (username, password) => {
    try {
        const response = await signInService(username, password);
        const { isSuccess, token, data, message } = response;
        
        if (isSuccess) {
            setIsLogged(true);
            await userService.getClientById(data.usuario.snu_sn_socio_de_negocio_id);
            await checkoutService.createOrGetCartByClientId(data.usuario.snu_sn_socio_de_negocio_id);
            const pet = await getPeticiones(data.usuario.snu_usuario_snu_id);

            getProducts();
           
            
            if (pet.message === "Exitoso") {
                history.push("/");
            }
        } else {
            setOpenAlert({
                message: "El usuario y sus credenciales no son correctas.",
                type: "error",
                open: true,
            });
        }
    } catch (error) {
        console.error("Error during sign in:", error);
        setOpenAlert({
            message: "Hubo un error al iniciar sesión. Por favor, inténtelo de nuevo más tarde.",
            type: "error",
            open: true,
        });
    }
};



  const handleRegisterEvent = async(type)=>{
      const result = await accountService.addNewUserInteractions({type})
      history.push("/register-user")
  }

  const login = (ev) => {
    ev.preventDefault();
     handleSignIn(email, password);
  };

 

  return (
    <div className={classes.mainBackground}>
      <SectionSpaceTop />
      <Container fixed>
        <form className={classes.accesoCliente} onSubmit={login}>
          <Title title="Acceso clientes" />
          <div className={classes.spaceDiv}>
            <div className={classes.inputsDiv}>
              <TextField
                required
                variant="outlined"
                className={classes.inputs}
                size="small"
                id="email"
                name="email"
                type="email"
                label="Correo electrónico"
                value={email}
                onChange={handleChange}
              />
            </div>
            <div className={classes.inputsDiv}>
              <TextField
                required
                variant="outlined"
                className={classes.inputs}
                size="small"
                id="password"
                name="password"
                type="password"
                label="Contrase&ntilde;a"
                value={password}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={classes.buttonDiv}>
            <Button
              variant="contained"
              className={classes.buttons}
              type="submit"
            >
              Iniciar sesi&oacute;n
            </Button>
            <Typography className={classes.recPass}>
            <br/>

              <Link style={{textDecoration:"underline", fontSize:'20px'}} onClick={() => history.push("/recovery")}>
                Recuperar contrase&ntilde;a
              </Link>
            </Typography>
          </div>
          <br/>
          <div className={classes.buttonDiv2}>
            
          <Divider>
        <Chip label="O" size="medium" />
      </Divider>
      <br/>
            <Typography className={classes.recPass}>

              <Link style={{textDecoration:"underline", fontSize:'20px'}} onClick={() => handleRegisterEvent(1)}>
                Crear mi usuario
              </Link>
            </Typography>
          </div>
        </form>
        <div className={classes.nuevoCliente}>
          <Title title="Cliente nuevo" />
          <div className={classes.clienteNuevoTextDiv}>
            <Typography className={classes.nuevoClienteText}>
              ¿Eres nuevo en Dielsa? Crea tu cuenta y accede a todo el
              cat&aacute;logo de soluciones.
            </Typography>
          </div>
          <div className={classes.clienteNuevoTextDiv}>
            <Button
              variant={isMobile ? "outlined" : "contained"}
              color="primary"
              onClick={() => history.push("/sign-up")}
              className={classes.buttonRegister}
            >
              {isMobile ? "Crear cuenta" : "Registrarme"}
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};
