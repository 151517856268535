import React, { useContext, createContext } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((styles) => ({
  title: {
    lineHeight:"normal",
    fontSize: 36,
    textAlign: "center",
    width: "100%",
    color: "#0B3196",
    fontFamily: "'Centrale Sans Bold'",
    marginTop: 38,
    [styles.breakpoints.down("sm")]: {
      fontSize: 22,
    },
  },
  container: {
    width: "100%",
    display: "flex",
    // alignItems: "center",
  },
}));

export default function SectionTitleMain(props) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.container}>
        <span className={classes.title}>{props.title}</span>
      </div>
    </div>
  );
}
