import React, { Fragment } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import CheckoutSummary from "./CheckoutSummary";
import CheckoutProductsList2 from "./CheckoutProductsList2";
import { getPriceSapFormat } from "helpers/formatPrice";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  content: {
    margin: "15px 0",
  },
  paper: {
    backgroundColor: "#F8F8F8",
    boxShadow: "none",
    borderRadius: 0,
    padding: "15px",
    fontSize: "15px",
    "& h6": {
      fontFamily: "'Centrale Sans Medium'",
      color: "#000000",
      fontSize: "17px",
    },
  },
  paperTitle: {
    borderRadius: 2,
    backgroundColor: "#F3F3F3",
    padding: 10,
    "& p": {
      color: "#000000",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: 18,
      fontWeight: 500,
      textAlign: "center",
    },
  },
  subTitle: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    fontWeight: 500,
    marginTop: 15,
  },
  divData: {
    paddingRight: 10,
  },
}));

export default function CheckoutOrder4(props) {
  const classes = useStyles();

  return (
    <div>
      <Grid container item xs={12}>
        <Grid item xs={12} className={classes.content}>
          {props.isMobile ? (
            ""
          ) : (
            <Paper className={clsx(classes.paperTitle)}>
              <Typography>Confirmación de datos</Typography>
            </Paper>
          )}

          <Paper className={clsx(classes.paper)}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4} className={classes.divData}>
                <Typography className={classes.subTitle}>
                  Datos personales
                </Typography>
                <Typography>
                  {props.cartCheckoutData.client.sn_razon_social}
                </Typography>
                <Typography>
                  {props.cartCheckoutData.client.sn_email_facturacion}
                </Typography>
                <Typography>
                  {props.cartCheckoutData.client.sn_telefono_empresa}
                </Typography>
                <Typography>
                  {props.cartCheckoutData.client.sn_cardcode}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className={classes.divData}>
                <Typography className={classes.subTitle}>
                  {props.cartCheckoutData.order_shipping_method === "16" ||
                  props.cartCheckoutData.order_shipping_method === 16
                    ? "Dirección de entrega"
                    : "Dirección de recolección"}
                </Typography>
                {props.cartCheckoutData.order_shipping_method === "16" ||
                props.cartCheckoutData.order_shipping_method === 16 ? (
                  <Typography>
                    {` ${
                      props.cartCheckoutData.order_shipping_address_info
                        .snd_direccion
                    } No.Ext ${
                      props.cartCheckoutData.order_shipping_address_info
                        .snd_direccion_num_ext
                    } Ciudad ${
                      props.cartCheckoutData.order_shipping_address_info
                        .snd_ciudad
                    }, ${
                      props.cartCheckoutData.order_shipping_address_info
                        .estado &&
                      props.cartCheckoutData.order_shipping_address_info.estado
                        .estpa_estado_nombre
                    }  ${
                      props.cartCheckoutData.order_shipping_address_info
                        .snd_codigo_postal
                    }`}
                  </Typography>
                ) : (
                  <Typography>
                    {` ${props.cartCheckoutData.order_warehouse_info.alm_nombre} ${props.cartCheckoutData.order_warehouse_info.alm_direccion} CP: ${props.cartCheckoutData.order_warehouse_info.alm_codigo_postal}`}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} className={classes.divData}>
                <Typography className={classes.subTitle}>
                  Método de pago
                </Typography>
                <Typography>
                  {props.cartCheckoutData.order_payment_method_info &&
                    props.cartCheckoutData.order_payment_method_info.sfp_nombre}
                </Typography>
                <Typography>
                  Por la cantidad de{" "}
                  {getPriceSapFormat(props.cartCheckoutData.cart_total)}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} className={classes.content}>
          <Fragment>
            <Grid container>
              <Grid item xs={12}>
                <Paper className={clsx(classes.paperTitle)}>
                  <Typography>Resumen de tu pedido</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <CheckoutProductsList2
                  cartCheckoutData={props.cartCheckoutData}
                />
              </Grid>
            </Grid>
            <Grid container style={{ justifyContent: "flex-end" }}>
              <Grid item xs={12} sm={6}>
                <CheckoutSummary cartCheckoutData={props.cartCheckoutData} stepNumber={props.stepNumber} />
              </Grid>
            </Grid>
          </Fragment>
        </Grid>
      </Grid>
    </div>
  );
}
