import { authHeader } from "_helpers";
import { API_URL } from "_services/api.url.back";

export const accountService = {
 addRegisterBussiness,
 ActivateB2CUser,
 addNewUserInteractions,
 getClientsInteractions,
 getClientsInteractionsProduct,
 addCartClientes,
 addInteractions,
 addSessionCount,
 addAbandonedCarts,
 addSessionCountTime
};

function getClientsInteractionsProduct(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
        headers: authHeader(),

    };

    return fetch(`${API_URL}/analytics/add_user_interactions_product`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
           
            return jsonData;
        })
        .catch((error) => {
            if (error.status === 400) {
                // Manejar el error 400 aquí
               return {status:'fail', message: error.message};
            } else {
                // Otros errores
                return {status:'fail', message: error.message};
            }
            throw error; // Re-lanzar el error para que pueda ser manejado más adelante si es necesario
        });
}
function getClientsInteractions(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
        headers: authHeader(),

    };

    return fetch(`${API_URL}/analytics/add_user_interactions_client`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
           
            return jsonData;
        })
        .catch((error) => {
            if (error.status === 400) {
                // Manejar el error 400 aquí
               return {status:'fail', message: error.message};
            } else {
                // Otros errores
                return {status:'fail', message: error.message};
            }
            throw error; // Re-lanzar el error para que pueda ser manejado más adelante si es necesario
        });
}

function addCartClientes(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
        headers: authHeader(),

    };

    return fetch(`${API_URL}/analytics/add_cart_clientes`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
           
            return jsonData;
        })
        .catch((error) => {
            if (error.status === 400) {
                // Manejar el error 400 aquí
               return {status:'fail', message: error.message};
            } else {
                // Otros errores
                return {status:'fail', message: error.message};
            }
            throw error; // Re-lanzar el error para que pueda ser manejado más adelante si es necesario
        });
}

function addInteractions(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
        headers: authHeader(),

    };

    return fetch(`${API_URL}/analytics/add-interactions`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
           
            return jsonData;
        })
        .catch((error) => {
            if (error.status === 400) {
                // Manejar el error 400 aquí
               return {status:'fail', message: error.message};
            } else {
                // Otros errores
                return {status:'fail', message: error.message};
            }
            throw error; // Re-lanzar el error para que pueda ser manejado más adelante si es necesario
        });
}

function addAbandonedCarts(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
        headers: authHeader(),

    };

    return fetch(`${API_URL}/analytics/add-abandoned-carts`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
           
            return jsonData;
        })
        .catch((error) => {
            if (error.status === 400) {
                // Manejar el error 400 aquí
               return {status:'fail', message: error.message};
            } else {
                // Otros errores
                return {status:'fail', message: error.message};
            }
            throw error; // Re-lanzar el error para que pueda ser manejado más adelante si es necesario
        });
}

function addSessionCount(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
        headers: authHeader(),

    };

    return fetch(`${API_URL}/analytics/add-session-count`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
           
            return jsonData;
        })
        .catch((error) => {
            if (error.status === 400) {
                // Manejar el error 400 aquí
               return {status:'fail', message: error.message};
            } else {
                // Otros errores
                return {status:'fail', message: error.message};
            }
            throw error; // Re-lanzar el error para que pueda ser manejado más adelante si es necesario
        });
}
function addSessionCountTime(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
        headers: authHeader(),

    };

    return fetch(`${API_URL}/analytics/add-session-time`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
           
            return jsonData;
        })
        .catch((error) => {
            if (error.status === 400) {
                // Manejar el error 400 aquí
               return {status:'fail', message: error.message};
            } else {
                // Otros errores
                return {status:'fail', message: error.message};
            }
            throw error; // Re-lanzar el error para que pueda ser manejado más adelante si es necesario
        });
}
function addNewUserInteractions(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
        headers: authHeader(),

    };

    return fetch(`${API_URL}/analytics/add_new_user_interactions`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
           
            return jsonData;
        })
        .catch((error) => {
            if (error.status === 400) {
                // Manejar el error 400 aquí
               return {status:'fail', message: error.message};
            } else {
                // Otros errores
                return {status:'fail', message: error.message};
            }
            throw error; // Re-lanzar el error para que pueda ser manejado más adelante si es necesario
        });
}


function addRegisterBussiness(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
        headers: authHeader(),

    };

    return fetch(`${API_URL}/account/send-request-account`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
           
            return jsonData;
        })
        .catch((error) => {
            if (error.status === 400) {
                // Manejar el error 400 aquí
               return {status:'fail', message: error.message};
            } else {
                // Otros errores
                return {status:'fail', message: error.message};
            }
            throw error; // Re-lanzar el error para que pueda ser manejado más adelante si es necesario
        });
}

function ActivateB2CUser(token) {
    const requestOptions = {
        method: "POST",
        //body: JSON.stringify(data),
        headers: {'token': token , "Content-Type": "application/json"},

    };

    return fetch(`${API_URL}/account/activate`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
           
            return jsonData;
        })
        .catch((error) => {
            if (error.status === 400) {
                // Manejar el error 400 aquí
               return {status:'fail', message: error.message};
            } else {
                // Otros errores
                return {status:'fail', message: error.message};
            }
            throw error; // Re-lanzar el error para que pueda ser manejado más adelante si es necesario
        });
}

function handleResponse(response) {
    return response.json().then((data) => {
        if (!response.ok) {
            const error = {
                status: response.status,
                message: data.message || response.statusText
            };
            return Promise.reject(error);
        }
        return data;
    });
}