import { authHeader } from "../_helpers/auth-header.js";
import { API_URL } from "./api.url.back";

export const searchServices = {
    searchProductsV3,
    getAdvancedSearch,
    getCategoryTree,
    loadCargaMasiva,
    searchSKUs,
    searchAutocomplete,
    searchFilters
};

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        const responseData = {
            error: false,
            data: data,
        };
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function searchProductsV3(orderQty, pagina, searchValue, orderBy, order, idSocioNegocio) {
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        "limite": orderQty,
        "pagina": pagina,
        "palabraBuscar": searchValue,
        "orderBy": orderBy,
        "ASCDESC": order,
        "idSocioNegocio": idSocioNegocio
      }),
    };
  
    return fetch(`${API_URL}/productos/frontGetProductoMainV3`, requestOptions)
      .then(handleResponse)
      .then((jsonData) => {
        return jsonData;
      });
}

function getAdvancedSearch(page, pagesize, prodNombre, nombre_extranjero, idSocioNegocio, prodDescr, prodSKU, marNombre, cateNombre, orderBy, orderByType, minPrice, maxPrice) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            limite: pagesize,
            pagina: page,
            prod_nombre: prodNombre,
            prod_nombre_extranjero: nombre_extranjero,
            idSocioNegocio: idSocioNegocio,
            prod_descripcion: prodDescr,
            prod_sku: prodSKU,
            mar_nombre: marNombre,
            cat_nombre: cateNombre,
            orderBy: orderBy,
            ASCDESC: orderByType,
            precioBajo: minPrice,
            precioAlto: maxPrice
        }),
    };

    return fetch(`${API_URL}/search/get-advanced-search-products`,
        //`${API_URL}/productos/frontGetProductoAdvancedSearchV3OnlyChilds`,
     requestOptions)
    // return fetch(`${API_URL}/productos/frontGetProductoAdvancedSearchV3`, requestOptions)
    // return fetch(`${API_URL}/productos/frontGetProductoAdvancedSearch_Frontk_ST`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
            return jsonData;
        });
}

function getCategoryTree() {
    const requestOptions = {
        method: "GET",
    };

    return fetch(`${API_URL}/categoria/list_public`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
            return jsonData;
        });
}

function loadCargaMasiva(formData, id_sn, token) {
    const requestOptions = {
        method: "POST",
        headers: { token: token},
        body: formData,
    };

    return fetch(`${API_URL}/files/excel_quick_shop`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
            return jsonData;
        });
}

function searchSKUs(
        page, pagesize, buscar, orderBy, orderByType, idSocioNegocio,
        filtroCategoria = null, filtroSubcategoria = null, filtroMarca = null, filtroRangoPrecio,
        filtroDisponibilidad, filtroDescuento, filtroBajoPedido
    ){
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            limite: pagesize,
            pagina: page,
            palabraBuscar: buscar,
            orderBy: orderBy,
            ASCDESC: orderByType,
            idSocioNegocio,
            filtroCategoria,
            filtroSubcategoria,
            filtroMarca,
            filtroRangoPrecio,
            filtroDisponibilidad,
            filtroDescuento,
            filtroBajoPedido,
        }),
    };

    return fetch(`${API_URL}/filters/products-filter-search`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
            return jsonData;
        });
}

function searchFilters(
    page, pagesize, buscar, orderBy, orderByType, idSocioNegocio,
    filtroCategoria = null, filtroSubcategoria = null, filtroMarca = null, filtroRangoPrecio,
    filtroDisponibilidad, filtroDescuento, filtroBajoPedido
){
const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
        limite: pagesize,
        pagina: page,
        palabraBuscar: buscar,
        orderBy: orderBy,
        ASCDESC: orderByType,
        idSocioNegocio,
        filtroCategoria,
        filtroSubcategoria,
        filtroMarca,
        filtroRangoPrecio,
        filtroDisponibilidad,
        filtroDescuento,
        filtroBajoPedido,
    }),
};

return fetch(`${API_URL}/filters/products-filter-search`, requestOptions)
    .then(handleResponse)
    .then((jsonData) => {
        return jsonData;
    });
}

function searchAutocomplete(autocompleteWord){
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            wordToSearch: autocompleteWord
        }),
    };

    return fetch(`${API_URL}/search/prediction`, requestOptions)
        .then(handleResponse)
        .then((jsonData) => {
            return jsonData;
        });
}