import React, { useEffect, useState, Fragment } from "react";
import { homeServices } from "../../_services/home.services";
import { generalService } from "../../_services/general.service";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';

import "../../assets/custom/Sliders.css";
// @material-ui/icons
// core components
import useCheckMobileScreen from "components/Mobile/Mobile.js";

// sections for this page
import SectionSpaceTop from "views/Home/Sections/SectionSpaceTop.js";
import Grid from "@material-ui/core/Grid";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles((styles) => ({
  mainBackground: {
    backgroundColor: "#FFF",
  },
  mainDiv: {
    width: "100%",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    [styles.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  appContainer: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  noPadding: {
    padding: "0 12px !important",
  },
  title: {
    fontFamily: "Rubik, sans-serif",
    fontSize: "30px",
    fontWeight: "500",
    margin: "5px 0",
    ["@media (max-width:898.98px)"]: {
      // eslint-disable-line no-useless-computed-key
      fontSize: "24px",
      marginBottom: "0",
    },
  },
  subtitle: {
    margin: "0",
    fontSize: "24px",
    margin: "5px 0",
    ["@media (max-width:898.98px)"]: {
      // eslint-disable-line no-useless-computed-key
      fontSize: "18px",
      margin: "0",
    },
  },
  addBtn: {
    all: "unset",
    margin: "0",
    padding: "0 3.5rem",
    height: "2.81rem",
    borderRadius: "7px",
    backgroundColor: "#0070f3",
    boxShadow: "0 4px 14px 0 rgb(0 118 255 / 39%)",
    color: "#fff",
    border: "0",
    fontSize: "18px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "700",
    cursor: "pointer",
  },
  cancelBtn: {
    all: "unset",
    margin: "0",
    padding: "0 3.5rem",
    height: "2.81rem",
    borderRadius: "7px",
    backgroundColor: "#c2c2c2",
    boxShadow: "0 4px 14px 0 rgb(100 100 100 / 39%)",
    color: "#fff",
    border: "0",
    fontSize: "18px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "700",
    cursor: "pointer",
  },
  addListBtn: {
    all: "unset",
    margin: "0",
    padding: "0 10px",
    height: "2.81rem",
    borderRadius: "21px",
    backgroundColor: "#0070f3",
    boxShadow: "0 4px 14px 0 rgb(0 118 255 / 39%)",
    color: "#fff",
    border: "0",
    fontSize: "18px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "700",
    cursor: "pointer",
  },
  formatListLabel: {
    fontFamily: "Roboto, sans-serif",
    fontWeight: "700",
  },
  formatListBtn: {
    all: "unset",
    margin: "0",
    marginRight: "10px",
    padding: "0 10px",
    height: "21px",
    borderRadius: "21px",
    color: "#fff",
    backgroundColor: "#c2c2c2",
    border: "0",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "700",
    cursor: "pointer",
    "&.selected": {
      backgroundColor: "#0070f3",
      boxShadow: "0 4px 14px 0 rgb(0 118 255 / 39%)",
    },
  },
  image: {
    width: "100%",
    maxWidth: "100%",
    borderRadius: '10px',
  },
  sectionItem: {
    padding: "5px 10px",
  },
  listItem: {
    padding: "10px",
    paddingLeft: "72px",
    display: "flex",
    fontSize: "18px",
    ["@media (max-width:898.98px)"]: {
      // eslint-disable-line no-useless-computed-key
      paddingLeft: "10px",
    },
  },
  boxItem: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    borderRadius: "7px",
    margin: "5px 0",
    "&:hover": {
      backgroundColor: "#ECF7F4",
      cursor: "pointer",
    },
  },
  deleteItemIcon: {
    position: "absolute",
    right: "10px",
  },
  dot: {
    // fontSize: "small",
    marginRight: "10px",
    paddingTop: "4px",
  },
  dialog: {
    "& .MuiPaper-root": {
      maxWidth: "900px",
      width: "900px",
    },
  },
  dialogContent: {},
  sectionMenu: {
    borderRight: "1px solid #c2c2c2",
  },
  sectionContenido: {
    paddingRight: "10px",
  },
  sectionOptions: {
    paddingRight: "10px",
  },
  sectionOption: {
    padding: "10px 10px",
    borderRadius: "7px",
    cursor: "pointer",
    "&.selected": {
      fontFamily: "Roboto, sans-serif",
      fontWeight: "500",
      backgroundColor: "#f5f2fa",
    },

    "& span": {
      fontFamily: "Roboto, sans-serif",
      fontWeight: "500",
    },
    "&:hover": {
      backgroundColor: "#f5f2fa",
    },
  },
  sectionLink: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "18px",
    color: "#0070f3",
    fontWeight: "600",
  },
  inputForm: {
    all: "unset",
    display: "flex",
    backgroundColor: "#f5f2fa",
    width: "100%",
    height: "54px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "600",
    fontSize: "18px",
    color: "#000",
    padding: "3px 10px",
    borderRadius: "10px",
    marginBottom: "10px",
    marginTop: "10px",
  },
  textareaForm: {
    display: "flex",
    backgroundColor: "#f5f2fa",
    width: "100%",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "400",
    fontSize: "18px",
    color: "#000",
    lineHeight: "25px",
    padding: "3px 10px",
    borderRadius: "10px",
    marginBottom: "10px",
    border: "0",
  },
  relative: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  deleteIcon: {
    position: "absolute",
    right: "10px",
    cursor: "pointer",
    fontSize: "18px",
  },
  overlayConfirm: {
    position: "fixed",
    top: "0",
    width: "100%",
    height: "100%",
    left: "0",
    zIndex: "30000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0,0.225)",
  },
  confirmAlert: {
    width: "377px",
    height: "180px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 4px 14px 0 rgb(0 0 0 / 39%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  boxMessage: {
    marginBottom: "30px",
    padding: "0 20px",
    textAlign: "center",
    "& span": {
      fontFamily: "Rubik, sans-serif",
      fontWeight: "500",
      fontSize: "27px",
      color: "#000",
    },
  },
  acceptConfirmBtn: {
    all: "unset",
    margin: "0",
    padding: "0 3.5rem",
    height: "2.81rem",
    borderRadius: "7px",
    backgroundColor: "#0070f3",
    boxShadow: "0 4px 14px 0 rgb(0 118 255 / 39%)",
    marginRight: "5px",
    color: "#fff",
    border: "0",
    fontSize: "18px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "700",
    cursor: "pointer",
  },
  cancelConfirmBtn: {
    all: "unset",
    margin: "0",
    padding: "0 3.5rem",
    height: "2.81rem",
    borderRadius: "7px",
    backgroundColor: "#c2c2c2",
    color: "#fff",
    border: "0",
    fontSize: "18px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "700",
    cursor: "pointer",
  },
  textTypeBold: {
    fontWeight: "bold",
  },
  textTypeNormal: {
    fontWeight: "normal",
  },
  textPositionLeft: {
    textAlign: "left",
  },
  textPositionCenter: {
    textAlign: "center",
  },
  textPositionRight: {
    textAlign: "right",
  },
}));

export default function Institucional(props) {
  const classes = useStyles();
  const { ...rest } = props;
  let isMobile = useCheckMobileScreen();

  const [dataBanner, setDataBanner] = useState("");
  const [pageContent, setPageContent] = useState("");
  const [instPageList, setInstPageList] = useState([]);
  const [configuracion, setConfiguracion] = useState([]);
  const [sectionName, setSectionName] = useState(null);

  const getDataBanner = async () => {
    let _d = await homeServices.getFranjaInformativa();
    setDataBanner(_d.controles_maestros[0].cmm_valor);
  };

  const findInstPagesByName = (list) => {
    let findInst = list.find((ip) => {
      return (
        ip.pi_nombre_seccion.toLowerCase() ===
        props.match.params.name.toLowerCase()
      );
    });
    if (findInst === undefined) {
      let findInstError = list.find((ip) => {
        return ip.pi_nombre_seccion.toLowerCase() === "Error 404".toLowerCase();
      });
      return findInstError;
    }
    return findInst;
  };

  const getInstitutionalPagesList = async () => {
    let response = await generalService.getInstitutionalPagesList();
    setInstPageList(response.paginas_institucionales);

    let findIP = await findInstPagesByName(response.paginas_institucionales);

    let _inst = await generalService.getInstitutionalPage(
      findIP.pi_pagina_institucional_id
    );
    setPageContent(_inst.pagina_institucional.pi_contenido_html);
    setSectionName(_inst.pagina_institucional.pi_nombre_seccion);
    setConfiguracion(JSON.parse(_inst.pagina_institucional.pi_contenido_html));
  };

  useEffect(() => {
    window.scrollTo(0,0);
    getDataBanner();
    getInstitutionalPagesList();
  }, []);

  const getInstitutionalPage = async () => {
    let findIP = await findInstPagesByName(instPageList);
    let _inst = await generalService.getInstitutionalPage(
      findIP.pi_pagina_institucional_id
    );
    setPageContent(_inst.pagina_institucional.pi_contenido_html);
    setSectionName(_inst.pagina_institucional.pi_nombre_seccion);
    setConfiguracion(JSON.parse(_inst.pagina_institucional.pi_contenido_html));
  };

  useEffect(() => {
    if (instPageList.length > 0) {
      window.scrollTo(0,0);
      getInstitutionalPage();
    }
  }, [props.match.params.name]);

  const sectionItems = configuracion.map((section, index) => (
    <Grid item xs={12} md={12} key={index} className={classes.noPadding}>
      <div className={classes.sectionItem}>
        {section && section.type === "title" && (
          <h1
            className={
              classes.title +
              " " +
              classes[`${section.textType}`] +
              " " +
              classes[`${section.textPosition}`]
            }
          >
            {section.contenido}
          </h1>
        )}
        {section && section.type === "subtitle" && (
          <h2
            className={
              classes.subtitle +
              " " +
              classes[`${section.textType}`] +
              " " +
              classes[`${section.textPosition}`]
            }
          >
            {section.contenido}
          </h2>
        )}
        {section && section.type === "paragraph" && (
          <div dangerouslySetInnerHTML={{ __html: section.contenido }}></div>
        )}
        {section && section.type === "link" && (
          <a href={section.url} className={classes.sectionLink} target="_blank">
            {section.contenido}
          </a>
        )}
        {section && section.type === "image" && (
          <img src={section.url} className={classes.image} />
        )}
        {section &&
          section.type === "list" &&
          section.items.length > 0 &&
          section.items.map((item, index2) => (
            <div key={index2}>
              {section.format == "number" && (
                <div className={classes.listItem}>
                  <strong>{index2 + 1}.- </strong> {item.contenido}
                </div>
              )}
              {section.format == "pointer" && (
                <div className={classes.listItem}>
                  <FiberManualRecordIcon
                    className={classes.dot}
                    fontSize="small"
                  />{" "}
                  {item.contenido}
                </div>
              )}
            </div>
          ))}
      </div>
    </Grid>
  ));

  return (
    <div className={classes.mainBackground}>
       <Container fixed style={{paddingTop: isMobile ? 2 : 0 }}>

      <SectionSpaceTop />
      

      {/* <div className={classes.mainDiv}>
                <div dangerouslySetInnerHTML={{ __html: pageContent }}>
                </div>
            </div>  */}
      <div className={classes.appContainer}>
        <Container maxWidth="lg">
          {/* {
                        sectionName != null && 
                            <h1 className={classes.title}>{sectionName}</h1>
                    } */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Grid container spacing={3}>
                {sectionItems}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
      </Container>
      {/*Main div end*/}
    </div>
  );
}
