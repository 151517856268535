import React, { useState, useEffect, useContext, Fragment } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((styles) => ({
  container: {
    [styles.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  inDiv: {
    padding: "4px 0",
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    padding: "20px 0 0 13px",
  },
  imgDiv: {
    width: "30%",
    display: "inline-block",
    verticalAlign: "top",
  },
  image: {
    width: "48px",
    height: "48px",
  },
  trustyDiv: {
    /* width: "25%",
    display: "flex",
    padding: "9px 16px",
    borderLeft: "1px solid #efefef",
    justifyContent: "center",
    "&:last-of-type": {
      borderRight: "1px solid #efefef",
    },
    [styles.breakpoints.down("sm")]: {
      width: "100%",
    }, */
  },
  trustyDivAux: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 10,
  },
  txtDiv: {
    //marginLeft: "20px",
  },
  txtTitle: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#A4A4A4",
    fontFamily: "'Centrale Sans Medium'",
    [styles.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  txtSubtitle: {
    fontSize: "18px",
    fontWeight: 600,
    color: "#000",
    fontFamily: "'Centrale Sans Bold'",
    lineHeight: "1.2",
  },
}));

export default function SectionSliderTrusty(props) {
  const classes = useStyles();
  const [sliderCate, setSliderCate] = useState([]);
  return (
    <div className="wrapper">
      <div className={classes.container}>
        {props.isMobile === true ? (
          <Splide
            id="splide"
            options={{
              rewind: true,
              perPage: 1,
              perMove: 1,
              padding: 0,
              arrows: false,
              gap: 0,
              fixedWidth: "50%",
            }}
          >
            {props.globalData.images.map((slide) => (
              <SplideSlide key={slide.id} className={classes.sliders}>
                <div className={classes.trustyDiv}>
                  <div className={classes.imgDiv}>
                    <img
                      alt={slide.id}
                      src={slide.img}
                      className={classes.image}
                    />
                  </div>
                  <div className={classes.txtDiv}>
                    <Typography className={classes.txtTitle}>
                      {slide.title}
                    </Typography>
                    <Typography className={classes.txtSubtitle}>
                      {slide.subtitle}
                    </Typography>
                  </div>
                </div>
              </SplideSlide>
            ))}
          </Splide>
        ) : (
          <div className={classes.inDiv}>
            {props.globalData.images.map((slide) => (
              <div className={classes.trustyDiv} key={slide.id + "-trsuty"}>
                <div className={classes.trustyDivAux}>
                  <div className={classes.imgDiv}>
                    <img
                      alt={slide.id}
                      src={slide.img}
                      className={classes.image}
                    />
                  </div>
                  <div className={classes.txtDiv}>
                    <Typography className={classes.txtTitle}>
                      {slide.title}
                    </Typography>
                    <Typography className={classes.txtSubtitle}>
                      {slide.subtitle}
                    </Typography>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
