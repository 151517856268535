import React, { useState, useEffect, useRef, Fragment } from "react";
import clsx from "clsx";
import { API_URL } from "../../_services/api.url.back.img";
import { getPriceSapFormat } from "helpers/formatPrice";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Divider, Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.primary.light,
    boxShadow: "none",
    borderRadius: 0,
    padding: "15px",
    fontSize: "15px",
    "& h6": {
      fontFamily: "'Centrale Sans Medium'",
      color: "#000000",
      fontSize: "17px",
    },
    textAlign: "center",
  },
  personalData: {
    backgroundColor: "#0B3196",
    color: "#ffffff",
    "& h6": {
      fontFamily: "'Centrale Sans Medium'",
      color: "#ffffff",
    },
  },
  productsList: {
    maxHeight: 420,
    overflowY: "auto",
    borderRadius: "0 0 2px 2px",
    backgroundColor: "#F8F8F8",
  },
  prodRow: {
    margin: "auto",
    width: "96%",
    marginTop: 10,
    marginBottom: 10,
  },
  productName: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 14,
    textAlign: "left",
    paddingRight: 15,
  },
  prodQty: {
    fontFamily: "'Centrale Sans Light'",
    marginLeft: 5,
  },
  divPrices: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  basePrice: {
    color: "#979797",
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "'Centrale Sans Light'",
  },
  unitPrice: {
    color: "#0C2D9C",
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "'Centrale Sans Light'",
  },
  promoPrice: {
    color: "#E41212",
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "'Centrale Sans Light'",
  },
  descriptionPrice: {
    color: "#000000",
    fontSize: 12,
    fontWeight: 300,
    fontFamily: "'Centrale Sans Light'",
    paddingLeft: 5,
    paddingRight: 5,
    minWidth: 110,
    textAlign: "left",
  },
  listPrice: {
    fontFamily: "'Centrale Sans Light'",
    fontSize: 14,
  },
  totalPrice: {
    fontFamily: "'Centrale Sans Bold'",
    fontSize: 16,
  },
  usdPrice: {
    color: theme.palette.primary.error,
    fontSize: 16,
    fontWeight: 900,
  },
}));

export default function CheckoutProductsList2(props) {
  const classes = useStyles();

  let _txt =
    props.cartCheckoutData.cart.cdc_cmm_tipo_envio_id === 17
      ? "Disponible para recolección "
      : "Envío ";

  return (
    <Fragment>
      <div className={classes.productsList}>
        {props.cartCheckoutData.cart
          ? props.cartCheckoutData.productsSplit.map((prod) => {
              let imgSrc =
                "http://wws.com.pa/wp-content/plugins/wordpress-ecommerce/marketpress-includes/images/default-product.png";
              let _nombre = "";
              let _precio = 0;
              let _total = 0;
              let _cantidad = 0;
              let _fecha = "";
              let precioBase = prod.detalle.precioBaseFinal;
              let precioUnidad = 0;
              let precioFinal = prod.detalle.precioFinal;
              let precioTipoDescuento = "";
              let _moneda = "";

              if (
                props.cartCheckoutData.cart.cdc_cmm_tipo_envio_id !==
                  undefined &&
                props.cartCheckoutData.cart.cdc_cmm_tipo_envio_id !== null
              ) {
                _nombre =
                  prod.detalle.prod_nombre +
                  "[" +
                  prod.detalle.prod_nombre_extranjero +
                  "]";
                /// Precios
                if (
                  prod.detalle.prod_tipo_cambio_base === "USD" &&
                  props.cartCheckoutData.cart.cdc_forma_pago_codigo == "99"
                ) {
                  _precio = (prod.detalle.projectProduct && (prod.detalle.projectProductPriceUSD < prod.detalle.precioFinal_USD ||prod.detalle.precioFinal_USD===0)
                  ? getPriceSapFormat(prod.detalle.projectProductPriceUSD) + " USD - No. de acuerdo " + prod.detalle.projectProducNoAcuerdo
                  : getPriceSapFormat(prod.detalle.precioFinal_USD) + " USD");
                  _total =
                    getPriceSapFormat(
                      (prod.detalle.projectProduct && (prod.detalle.projectProductPriceUSD < prod.detalle.precioFinal_USD || prod.detalle.precioFinal_USD===0)
                      ? prod.detalle.projectProductPriceUSD
                      : prod.detalle.precioFinal_USD) * prod.pcf_cantidad_producto)
                    + " USD";
                  _moneda = " USD";
                  precioBase = prod.detalle.precioBaseFinal_USD;
                  precioFinal = prod.detalle.precioFinal_USD;
                } else {
                  _precio = (prod.detalle.projectProduct &&(prod.detalle.projectProductPrice < prod.detalle.precioFinal || prod.detalle.precioFinal ===0)
                  ? getPriceSapFormat(prod.detalle.projectProductPrice) + " MXN - No. de acuerdo " + prod.detalle.projectProducNoAcuerdo
                  : getPriceSapFormat(prod.detalle.precioFinal) + " MXN");
                  _total =
                    getPriceSapFormat(
                      (prod.detalle.projectProduct && (prod.detalle.projectProductPrice < prod.detalle.precioFinal || prod.detalle.precioFinal ===0)
                      ? prod.detalle.projectProductPrice
                      : prod.detalle.precioFinal) * prod.pcf_cantidad_producto)
                     + " MXN";
                  _moneda = " MXN";
                }
                /// Imágenes
                if (prod.detalle.imagenes && prod.detalle.imagenes !== null) {
                  imgSrc = prod.detalle.imagenes.imgprod_ruta_archivo.includes('https://ftp3.syscom.mx')? prod.detalle.imagenes.imgprod_ruta_archivo :`${API_URL}${prod.detalle.imagenes.imgprod_ruta_archivo.split("public")[1]}`;
                }
                _cantidad = prod.pcf_cantidad_producto;
                _fecha = _txt + " " + prod.fechaEntrega;
                if (prod.detalle.prod_tipo_precio_base === "Stock Inactivo") {
                  _moneda == " USD"
                    ? (precioBase = prod.detalle.precioFinal_USD)
                    : (precioBase = prod.detalle.precioFinal);
                  precioTipoDescuento = "especial";
                } else {
                  if (prod.detalle.precioPromocionDielsaBool) {
                    // precioUnidad = prod.detalle.DescuentoDielsaFijo;
                    precioUnidad = prod.detalle.DescuentoDielsaFijo_USD;
                  } else if (prod.detalle.precioDespuesDePromocion) {
                    // precioUnidad = parseFloat(prod.detalle.precioDespuesDePromocion);
                    precioUnidad = parseFloat(prod.detalle.precioFinal_USD);
                  }

                  if (prod.detalle.promocion.length > 0) {
                    precioTipoDescuento += "promo ";
                  }
                  if (prod.detalle.cupon.length > 0) {
                    precioTipoDescuento += "cupon";
                  }
                }
              } else {
                _nombre =
                  prod.detalle.prod_nombre +
                  "[" +
                  prod.detalle.prod_nombre_extranjero +
                  "]";
                if (
                  prod.prod_tipo_cambio_base === "USD" &&
                  props.cartCheckoutData.cart.cdc_forma_pago_codigo == "99" 
                ) {
                  _precio = (prod.detalle.projectProduct && prod.detalle.projectProductPriceUSD < prod.detalle.precioFinal_USD)
                  ? getPriceSapFormat(prod.detalle.projectProductPriceUSD) + " USD - No. de acuerdo " + prod.detalle.projectProducNoAcuerdo
                  : getPriceSapFormat(prod.detalle.precioFinal_USD) + " USD";
                  _total =
                    getPriceSapFormat(
                      ((prod.detalle.projectProduct && prod.detalle.projectProductPriceUSD < prod.detalle.precioFinal_USD)
                      ? prod.detalle.projectProductPriceUSD
                      : prod.detalle.precioFinal_USD) * prod.detalle.pcdc_producto_cantidad
                    ) + " USD";
                  _moneda = " USD";
                  precioBase = prod.detalle.precioBaseFinal_USD;
                  precioFinal = prod.detalle.precioFinal_USD;
                } else {
                  _precio = (prod.detalle.projectProduct && prod.detalle.projectProductPrice < prod.detalle.precioFinal)
                    ? getPriceSapFormat(prod.detalle.projectProductPrice) + " MXN - No. de acuerdo " + prod.detalle.projectProducNoAcuerdo
                    : getPriceSapFormat(prod.detalle.precioFinal) + " MXN";
                  _total =
                    getPriceSapFormat(
                      ((prod.detalle.projectProduct && prod.detalle.projectProductPrice < prod.detalle.precioFinal)
                      ? prod.detalle.projectProductPrice
                      : prod.detalle.precioFinal) * prod.detalle.pcdc_producto_cantidad
                    ) + " MXN";
                  _moneda = " MXN";
                }
                if (prod.detalle.imagenes && prod.imagenes !== null) {
                
                  imgSrc = prod.detalle.imagenes.imgprod_ruta_archivo.includes('https://ftp3.syscom.mx')? prod.detalle.imagenes.imgprod_ruta_archivo :`${API_URL}${prod.detalle.imagenes.imgprod_ruta_archivo.split("public")[1]}`;
                }
                _cantidad = prod.detalle.pcdc_producto_cantidad;
                if (prod.detalle.prod_tipo_precio_base === "Stock Inactivo") {
                  _moneda == " USD"
                    ? (precioBase = prod.detalle.precioFinal_USD)
                    : (precioBase = prod.detalle.precioFinal);
                  precioTipoDescuento = "especial";
                } else {
                  if (prod.detalle.precioPromocionDielsaBool) {
                    // precioUnidad = prod.detalle.DescuentoDielsaFijo;
                    precioUnidad = prod.detalle.DescuentoDielsaFijo_USD;
                  } else if (prod.detalle.precioDespuesDePromocion) {
                    // precioUnidad = parseFloat(prod.detalle.precioDespuesDePromocion);
                    precioUnidad = parseFloat(prod.detalle.precioFinal_USD);
                  }

                  if (prod.detalle.promocion.length > 0) {
                    precioTipoDescuento += "promo ";
                  }
                  if (prod.detalle.cupon.length > 0) {
                    precioTipoDescuento += "cupon";
                  }
                }
              }

              /*if (prod.imagenes && prod.imagenes !== null) {
                imgSrc = `${API_URL}${
                  prod.imagenes.imgprod_ruta_archivo.split("public")[1]
                }`;
              }*/

              return (
                <Grid container className={classes.prodRow}>
                  <Grid item sm={2} xs={2}>
                    <Avatar alt="Product" src={imgSrc} />
                  </Grid>
                  <Grid item sm={4} xs={5}>
                    <Typography className={classes.productName}>
                      {_nombre}
                    </Typography>
                    <Fragment>
                      <Typography>{_fecha}</Typography>
                    </Fragment>
                  </Grid>
                  <Grid item sm={3} xs={5}>
                    <Fragment>
                      {precioBase === precioFinal ? (
                        <div className={classes.divPrices}>
                          <span className={classes.descriptionPrice}>
                            {precioTipoDescuento === "especial"
                              ? "Precio especial"
                              : "Precio de lista"}
                          </span>
                          <span className={classes.basePrice}>{_precio}</span>
                        </div>
                      ) : (
                        <Fragment>
                          <div className={classes.divPrices}>
                            <span className={classes.descriptionPrice}>
                              Precio de lista
                            </span>
                            <span
                              className={classes.basePrice}
                              style={{
                                textDecoration: "line-through",
                              }}
                            >
                              {getPriceSapFormat(precioBase)}
                            </span>
                          </div>

                          {precioFinal >= precioUnidad ? (
                            <div className={classes.divPrices}>
                              <span className={classes.descriptionPrice}>
                                Tu precio unitario
                              </span>
                              <span className={classes.unitPrice}>
                                {_precio}
                                {/* {getPriceSapFormat(precioFinal)}
                                {_moneda} - C */}
                              </span>
                            </div>
                          ) : (
                            <Fragment>
                              <div className={classes.divPrices}>
                                <span className={classes.descriptionPrice}>
                                  Tu precio unitario
                                </span>
                                <span
                                  className={classes.unitPrice}
                                  style={{
                                    textDecoration: "line-through",
                                  }}
                                >
                                  {getPriceSapFormat(precioUnidad)}
                                </span>
                              </div>
                              <div className={classes.divPrices}>
                                <span className={classes.descriptionPrice}>
                                  Precio {precioTipoDescuento}
                                </span>
                                <span className={classes.promoPrice}>
                                  {_precio}
                                  {/* {getPriceSapFormat(prod.detalle.precioFinal_USD)}
                                  {_moneda} - B */}
                                </span>
                              </div>
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  </Grid>
                  <Grid item sm={1} xs={2}></Grid>
                  <Grid item  sm={1} xs={5}>
                    <Typography
                      className={classes.productName}
                      style={{ marginTop: 10 }}
                    >
                      <span className={classes.prodQty}>{_cantidad}</span>
                    </Typography>
                  </Grid>
                  <Grid item sm={1} xs={5}>
                    <Typography
                      className={classes.totalPrice}
                      style={{ marginTop: 10 }}
                    >
                      {_total}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider style={{ backgroundColor: "#EAEAEA" }} />
                  </Grid>
                </Grid>
              );
            })
          : ""}
      </div>
    </Fragment>
  );
}
