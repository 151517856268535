import React, { useState, useEffect, useRef, Fragment } from "react";
import { getPriceSapFormat } from "helpers/formatPrice";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Divider, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  costData: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "30px",
    "& .costResponse": {
      textAlign: "end",
    },
    margin: "10px 0",
    padding: 15,
    backgroundColor: "#F5F5F5",
    width: "100%",
  },
  divisaTitle: {
    fontWeight: 600,
  },
}));

export default function CheckoutData(props) {
  const classes = useStyles();
  let _subtotalMXN = props.cartCheckoutData.subtotalsSplit.resume_MXN;
  let _subtotalUSD = props.cartCheckoutData.subtotalsSplit.resume_USD;
  let _tipoPago = props.cartCheckoutData.cart.cdc_forma_pago_codigo;

  let showDataUSD = false;
  const dataCarStorage = JSON.parse(localStorage.getItem("cart"))
    .cdc_carrito_de_compra_id?.productos;
  dataCarStorage.map((item) => {
    if(item.prod_tipo_cambio_base === "USD") {
      showDataUSD = true;
    }
  });

  return (
    <div>
      <div className={classes.costData}>
        <Grid container>
          <Grid item xs={12}>
            <span className={classes.divisaTitle}>Divisa MXN</span>
          </Grid>
          <Grid item xs={6}>
            Subtotal
          </Grid>
          <Grid item xs={6} className={"costResponse"}>
            {getPriceSapFormat(_subtotalMXN.precioTotal)}
          </Grid>
          <Grid item xs={6}>
            Descuento
          </Grid>
          <Grid
            item
            xs={6}
            className={"costResponse"}
            style={{ textDecoration: "underline" }}
          >
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span>{`- ${getPriceSapFormat(_subtotalMXN.totalDescuentos)}`}</span>
          </Grid>

          <Grid item xs={6}>
            Subtotal tras descuento
          </Grid>
          <Grid item xs={6} className={"costResponse"}>
            {getPriceSapFormat(_subtotalMXN.precioFinalTotal)}
          </Grid>
          <Grid item xs={6}>
            Gastos de envío
          </Grid>
          <Grid item xs={6} className={"costResponse"}>{`${getPriceSapFormat(
            _subtotalMXN.cdc_costo_envio
          )}`}</Grid>
          <Grid item xs={6}>
            {/* IVA {`${(props.cartCheckoutData.cart_iva_type * 100).toFixed(0)} %`} */}
            IVA {`${props.cartCheckoutData.cart_iva_type}`}
          </Grid>
          <Grid item xs={6} className={"costResponse"}>{`${getPriceSapFormat(
            _subtotalMXN.TotalImpuesto
          )}`}</Grid>

          <Grid item xs={12}>
            <Divider style={{ backgroundColor: "#EAEAEA" }} />
          </Grid>
          <Grid item xs={6}>
            <strong>Total</strong>
          </Grid>
          <Grid item xs={6} className={"costResponse"}>
            <strong>{getPriceSapFormat(_subtotalMXN.TotalFinal)}</strong>
          </Grid>
        </Grid>
      </div>
      {(_tipoPago == "99" && showDataUSD && (props.stepNumber == 2 || props.stepNumber == 3 || props.stepNumber == 4)) && (
        <div className={classes.costData}>
          <Grid container>
            <Grid item xs={12}>
              <span className={classes.divisaTitle}>Divisa USD</span>
            </Grid>
            <Grid item xs={6}>
              Subtotal
            </Grid>
            <Grid item xs={6} className={"costResponse"}>
              {getPriceSapFormat(_subtotalUSD.precioTotal_usd)}
            </Grid>
            <Grid item xs={6}>
              Descuento
            </Grid>
            <Grid
              item
              xs={6}
              className={"costResponse"}
              style={{ textDecoration: "underline" }}
            >
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span>
                {`- ${getPriceSapFormat(_subtotalUSD.totalDescuentos_usd)}`}
              </span>
            </Grid>

            <Grid item xs={6}>
              Subtotal tras descuento
            </Grid>
            <Grid item xs={6} className={"costResponse"}>
              {getPriceSapFormat(_subtotalUSD.precioFinalTotal_usd)}
            </Grid>
            <Grid item xs={6}>
              Gastos de envío
            </Grid>
            <Grid item xs={6} className={"costResponse"}>{`${getPriceSapFormat(
              _subtotalUSD.cdc_costo_envio_usd
            )}`}</Grid>
            <Grid item xs={6}>
              {/* IVA {`${(props.cartCheckoutData.cart_iva_type * 100).toFixed(0)} %`} */}
              IVA {`${props.cartCheckoutData.cart_iva_type}`}
            </Grid>
            <Grid item xs={6} className={"costResponse"}>{`${getPriceSapFormat(
              _subtotalUSD.TotalImpuesto_usd
            )}`}</Grid>

            <Grid item xs={12}>
              <Divider style={{ backgroundColor: "#EAEAEA" }} />
            </Grid>
            <Grid item xs={6}>
              <strong>Total</strong>
            </Grid>
            <Grid item xs={6} className={"costResponse"}>
              <strong>{getPriceSapFormat(_subtotalUSD.TotalFinal_usd)}</strong>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}
