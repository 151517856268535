import React, { useState, useEffect, Fragment } from "react";
import clsx from "clsx";
import { checkoutService } from "../../_services/checkout.service";
import { useHistory } from "react-router-dom";
import conektaHelper from "../../_helpers/conekta";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
// core components
import useCheckMobileScreen from "components/Mobile/Mobile.js";
import { useAuth } from "hooks/useAuth";
const useStyles = makeStyles((theme) => ({
  grayDiv: {
    marginBottom: 30,
  },
  paper: {
    backgroundColor: "#F8F8F8",
    boxShadow: "none",
    borderRadius: 0,
    padding: 15,
    paddingLeft: 30,
    fontSize: "15px",
    "& h6": {
      fontFamily: "'Centrale Sans Medium'",
      color: "#000000",
      fontSize: "17px",
    },
  },
  buttons: {
    textTransform: "none",
    fontFamily: "'Centrale Sans Bold'",
    fontSize: 16,
    borderRadius: 2,
    marginTop: 10,
  },
  //alerts
  alerts: {
    color: "#FFFFFF",
    backgroundColor: "#40DF00",
    fontFamily: "'Centrale Sans Light'",
    fontSize: 22,
    "& .MuiAlert-icon": {
      color: "#FFFFFF",
    },
    "&.MuiAlert-standardError": {
      backgroundColor: "#DF2B2B",
    },
  },
  labelsForm: {
    fontSize: 16,
    fontFamily: "'Centrale Sans Light'",
    fontWeight: 400,
    lineHeight: "14px",
    color: "#444444",
    margin: "10px 0",
  },
  separatorDate: {
    position: "relative",
    top: 5,
  },
  infoAlert: {
    color: "#DF2B2B",
    fontSize: 12,
    fontFamily: "'Centrale Sans Light'",
    marginTop: 10,
  },
}));

export default function CheckoutConekta(props) {
  const {setOpenAlertF} = useAuth();
  const classes = useStyles();
  const { ...rest } = props;
  let history = useHistory();

  let isMobile = useCheckMobileScreen();


  /* const [cardnumber, setCardnumber] = useState(
    props.cartCheckoutData.cardnumber
  ); */

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.conekta.io/js/latest/conekta.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const successHandler = async (token) => {
    /* token keys: id, livemode, used, object */
    setOpenAlertF({
      message: "Datos validados. Puede proceder a completar su pedido.",
      type: "success",
      open: true,
    });

    await props.handleUpdateCartCheckoutData("token_card", token);
    //checkoutService.conectaTokenSend NOT here because payment is donde in last step, thats way is call in handleSubmit in CheckoutSteps.js
    //const send_token_response = checkoutService.conectaTokenSend(token.id);
  };

  const errorHandler = (err) => {
    /* err keys: object, type, message, message_to_purchaser, param, code */
    if (err.object === "error") {
      setOpenAlertF({
        message: err.message_to_purchaser,
        type: "error",
        open: true,
      });
    }
    props.handleUpdateCartCheckoutData("token_card", null);
  };

  const tokenizar = async (event) => {
    event.preventDefault();

    conektaHelper.initConekta();
    conektaHelper.tokenize(
      event.target.cardNumber.value,
      event.target.cardName.value,
      event.target.expMonth.value,
      event.target.expYear.value,
      event.target.cardCvc.value,
      await successHandler,
      await errorHandler
    );
  };

  return (
    <Fragment>
   
      <Grid item xs={12} className={classes.grayDiv}>
        <Paper className={clsx(classes.paper)}>
          <form action="" method="post" id="card-form" /* onSubmit={tokenizar} */>
            <span className="card-errors"></span>
            <Grid container className={classes.formConekta}>
              <Grid item xs={12}>
                <Typography className={classes.labelsForm}>
                  Número de la tarjeta
                </Typography>
                <TextField
                  /* onChange={(event) => {
                    props.handleUpdateCartCheckoutData(
                      "cardnumber",
                      event.target.value
                    );
                    setCardnumber(event.target.value);
                  }} */
                  onChange={(event) => {
                    props.handleUpdateCartCheckoutData(
                      "cardnumber",
                      event.target.value
                    );
                    props.handleUpdateCartCheckoutData("token_card", null);
                  }}
                  defaultValue={props.cartCheckoutData.cardnumber}
                  required
                  variant="outlined"
                  className={classes.inputs}
                  size="small"
                  data-conekta="card[number]"
                  id="cardNumber"
                  type="text"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.labelsForm}>
                  Nombre y Apellido del tarjetahabiente
                </Typography>
                <TextField
                  onChange={(event) => {
                    props.handleUpdateCartCheckoutData(
                      "cardname",
                      event.target.value
                    );
                    props.handleUpdateCartCheckoutData("token_card", null);
                  }}
                  defaultValue={props.cartCheckoutData.cardname}
                  required
                  variant="outlined"
                  className={classes.inputs}
                  size="small"
                  data-conekta="card[name]"
                  id="cardName"
                  type="text"
                />
              </Grid>

              <Grid container item xs={12}>
                <Typography className={classes.labelsForm}>
                  Fecha de vencimiento (MM/AAAA)
                </Typography>
                <Grid item xs={12} style={{ display: 'flex' }}>
                  <TextField
                    onChange={(event) => {
                      props.handleUpdateCartCheckoutData(
                        "cardexpmonth",
                        event.target.value
                      );
                      props.handleUpdateCartCheckoutData("token_card", null);
                    }}
                    defaultValue={props.cartCheckoutData.cardexpmonth}
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    data-conekta="card[exp_month]"
                    id="expMonth"
                    type="text"
                  />
                  <span className={classes.separatorDate}>/</span>
                  <TextField
                    onChange={(event) => {
                      props.handleUpdateCartCheckoutData(
                        "cardexpyear",
                        event.target.value
                      );
                      props.handleUpdateCartCheckoutData("token_card", null);
                    }}
                    defaultValue={props.cartCheckoutData.cardexpyear}
                    required
                    variant="outlined"
                    className={classes.inputs}
                    size="small"
                    data-conekta="card[exp_year]"
                    id="expYear"
                    type="text"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.labelsForm}>
                  Código de seguridad CVV
                </Typography>
                <TextField
                  onChange={(event) => {
                    props.handleUpdateCartCheckoutData(
                      "cardcvc",
                      event.target.value
                    );
                    props.handleUpdateCartCheckoutData("token_card", null);
                  }}
                  defaultValue={props.cartCheckoutData.cardcvc}
                  required
                  variant="outlined"
                  className={classes.inputs}
                  size="small"
                  data-conekta="card[cvc]"
                  id="cardCvc"
                  type="text"
                />
              </Grid>

              {/* <Grid item xs={12}>
                <Typography className={classes.infoAlert}>
                  Debes validar la tarjeta antes de continuar
                </Typography>
              </Grid>
              <Button
                variant="contained"
                className={classes.buttons}
                type="submit"
                color="primary"
              >
                Validar tarjeta
              </Button> */}
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Fragment>
  );
}
