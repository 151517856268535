import React, { useState, useEffect, useContext, Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components

import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarHalfIcon from "@material-ui/icons/StarHalf";

const useStyles = makeStyles((styles) => ({
  iconColor: {
    width: "0.8em",
    height: "0.8em",
    color: "#ffe720",
    [styles.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  space: {
    paddingLeft: 5,
    display: "inline-block",
    verticalAlign: "middle",
    marginTop: 2,
  },
}));

export default function SectionProductRating(props) {
  const classes = useStyles();
  const [rateStars, setRateStars] = useState(null);
  useEffect(() => {
    setStars();
  }, []);
  const setStars = () => {
    let stars = [];
    for (
      let i = 1;
      i <= Math.floor(props.productDetails.clients_comments.rating);
      i++
    ) {
      stars.push(
        <StarIcon key={"p_r_full" + i} className={classes.iconColor} />
      );
    }
    if (
      props.productDetails.clients_comments.rating >
      Math.floor(props.productDetails.clients_comments.rating)
    ) {
      stars.push(
        <StarHalfIcon key={"p_r_half"} className={classes.iconColor} />
      );
    }
    for (
      let i = Math.ceil(props.productDetails.clients_comments.rating);
      i < 5;
      i++
    ) {
      stars.push(
        <StarBorderIcon key={"p_r_empty" + i} className={classes.iconColor} />
      );
    }
    setRateStars(stars);
  };
  return (
    <div className={classes.space}>
      <div className={classes.container}>{rateStars}</div>
    </div>
  );
}
