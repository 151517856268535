import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/custom/Login.css";
// @material-ui/
import Grid from "@material-ui/core/Grid";

// core components
import useCheckMobileScreen from "components/Mobile/Mobile.js";
import Container from '@material-ui/core/Container';

import CheckoutHeader from "./CheckoutHeader";
import CheckoutFooter from "./CheckoutFooter";
import CheckoutSteps from "./CheckoutSteps";
import { checkoutService } from "_services/checkout.service";
import CartHeader from "views/Cart/CartHeader.js";

const useStyles = makeStyles((theme) => ({
  mainBackground: {
    backgroundColor: "#FFF",
  },
  rootProfile: {
    minHeight: 400,
    width: "90%",
    margin: "auto",
    marginBottom: 50,
  },
}));

export default function CheckoutIndex(props) {
  const classes = useStyles();
  const { ...rest } = props;
  let history = useHistory();
  let isMobile = useCheckMobileScreen();

  const [user, setUser] = useState({});
  const [client, setClient] = useState({});
  const [cart, setCart] = useState({});
  const [cartView, setCartView] = useState(null);

  useEffect(() => {
    window.scrollTo(0,0);
    if (!localStorage.getItem("user")) {
      //redirect to login
    
      
     
      props.history.push("/login");
      setUser({});
      setClient({});
      setCart({});
    } else {
      setUser(JSON.parse(localStorage.getItem("user")));
      if (localStorage.getItem("client")) {
        setClient(JSON.parse(localStorage.getItem("client")).listaSnbyid[0]);
      }
      if (localStorage.getItem("cart")) {
        setCart(
          JSON.parse(localStorage.getItem("cart")).cdc_carrito_de_compra_id
        );
      }
    }
  }, []);

  const closeCartDiv = () => {
    setCartView(null);
  };

  const showCart = async () => {
    let _user = JSON.parse(localStorage.getItem("user"));
    if (_user != null && _user != undefined) {
      let _cart = await checkoutService.createOrGetCartByClientId(
        _user.usuario.snu_sn_socio_de_negocio_id
      );
      _cart = _cart.data.cdc_carrito_de_compra_id;
      setCartView(
        <CartHeader
          closeCartDiv={closeCartDiv}
          cart={_cart}
        />
      );
    } else {
      // setCartView(
      //   <LocalCartHeader closeCartDiv={closeCartDiv} />
      // );
    }
  };

  useEffect(() => {
    const cartBackup = JSON.parse(
      localStorage.getItem("cart")
    ).cdc_carrito_de_compra_id;
    if(!localStorage.getItem("cartBackup")) {
      localStorage.setItem("cartBackup", JSON.stringify(cartBackup));
    } else {
      localStorage.setItem("cartBackup", JSON.stringify(cartBackup));
    }
  }, [localStorage.getItem("cart")]);

  return (
    <>
      {cartView}
      <div className={classes.mainBackground}>   
        {/* <CheckoutHeader history={props.history} /> */}
        <Container fixed>
          <Grid container spacing={2} className={classes.rootProfile}>
            <Grid item xs={12}>
              <CheckoutSteps
                props={props}
                user={user}
                client={client}
                cart={cart}
                isMobile={isMobile}
                showCart={showCart}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
