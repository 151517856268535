import React, { Fragment, useEffect, useState } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import { checkoutService } from "../../_services/checkout.service";
import { orderService } from "../../_services/order.service";
import { API_URL } from "../../_services/api.url.back.img";
import { getPriceSapFormat } from "helpers/formatPrice";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";



export default function CheckoutComplete5(props) {
  const classes = useStyles();

  const [orderData, setOrderData] = useState({});
  const [formasDePago, setFormasDePago] = useState([]);
  const [metodoDePago, setMetodoDePago] = useState(null);

  const createCartByClientId = async () => {
    const response = await checkoutService.createOrGetCartByClientId(
      props.cartCheckoutData.client_id
    );
  };

  const getOrderById = async () => {
    const response = await orderService.getOrderById(props.orderId);
    const formasDePagoResponse = await orderService.getFormasDePago();
    setFormasDePago(formasDePagoResponse.constSapFormasPago);

    for (
      let index = 0;
      index < formasDePagoResponse.constSapFormasPago.length;
      index++
    ) {
      const element = formasDePagoResponse.constSapFormasPago[index];
      if (
        element.sfp_clave ==
        response.constCompraFinalizada.cf_sap_forma_pago_codigo
      ) {
        setMetodoDePago(element.sfp_clave);
        // setMetodoDePagoName(element.sfp_descripcion);
        break;
      }
    }
    setOrderData(response.constCompraFinalizada);
  };

  const convertDate = (dateTime) => {
    let date = dateTime.split("T");
    let _d = date[0].split("-");
    let _m = "";
    switch (_d[1]) {
      case "01":
      case "1":
        _m = "Enero";
        break;
      case "02":
      case "2":
        _m = "Febrero";
        break;
      case "03":
      case "3":
        _m = "Marzo";
        break;
      case "04":
      case "4":
        _m = "Abril";
        break;
      case "05":
      case "5":
        _m = "Mayo";
        break;
      case "06":
      case "6":
        _m = "Junio";
        break;
      case "07":
      case "7":
        _m = "Julio";
        break;
      case "08":
      case "8":
        _m = "Agosto";
        break;
      case "09":
      case "9":
        _m = "Septiembre";
        break;
      case "10":
        _m = "Octubre";
        break;
      case "11":
        _m = "Noviembre";
        break;
      case "12":
        _m = "Diciembre";
        break;
    }
    return _d[2] + "/" + _m + "/" + _d[0];
  };

  useEffect(() => {
    createCartByClientId();
    if (props.orderId) {
      getOrderById();
    }
  }, []);

  return (
    <div>
      <Grid container item xs={12}>
        <Grid item xs={12} className={classes.content}>
          <Paper className={clsx(classes.paperTitle)}>
            <Typography>
              No. de Pedido {orderData.cf_compra_numero_orden}
            </Typography>
          </Paper>
          <Paper className={clsx(classes.paper)}>
            <Grid container>
              <Grid item xs={12} className={classes.divData}>
                <Link
                  to={`/myprofile?orderid=${orderData.cf_compra_finalizada_id}`}
                  target={"_blank"}
                  rel='noopener noreferrer'
                >
                  <Typography className={classes.subTitle}>
                    Tu pedido fue generado exitosamente, recuerda que puedes
                    consultar el estatus de tu pedido en la sección de{" "}
                    <span className={classes.profileLink}>"Mis pedidos"</span>
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={3} className={classes.divData}>
                <Typography className={classes.subTitle}>
                  Datos personales
                </Typography>
                {orderData.SocioDeNegocio && (
                  <Fragment>
                    <Typography>
                      {orderData.SocioDeNegocio[0].sn_razon_social}
                    </Typography>
                    <Typography>
                      {orderData.SocioDeNegocio[0].sn_email_facturacion}
                    </Typography>
                    <Typography>
                      {orderData.SocioDeNegocio[0].sn_telefono_empresa}
                    </Typography>
                    <Typography>
                      {orderData.SocioDeNegocio[0].sn_cardcode}
                    </Typography>
                  </Fragment>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={3} className={classes.divData}>
                <Typography className={classes.subTitle}>
                  Tipo de envío
                </Typography>
                <Typography>
                  {orderData.tipo_envio_id && orderData.tipo_envio_id.cmm_valor}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} className={classes.divData}>
                <Typography className={classes.subTitle}>
                  {orderData.cf_cmm_tipo_envio_id == 16
                    ? "Dirección de entrega"
                    : "Dirección de recolección"}
                </Typography>
                {orderData.cf_cmm_tipo_envio_id == 16 ? (
                  <Fragment>
                    {orderData.DireccionEnvio && (
                      <Fragment>
                        <Typography>
                          {orderData.DireccionEnvio[0].snd_ciudad}
                        </Typography>
                        <Typography>
                          {` ${orderData.DireccionEnvio[0].snd_direccion} No.Ext ${orderData.DireccionEnvio[0].snd_direccion_num_ext} Col. ${orderData.DireccionEnvio[0].snd_colonia} `}
                        </Typography>
                        <Typography>
                          {`CP. ${orderData.DireccionEnvio[0].snd_codigo_postal}`}
                        </Typography>
                      </Fragment>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {orderData.DireccionRecoleccion && (
                      <Fragment>
                        <Typography>
                          {orderData.DireccionRecoleccion[0].alm_nombre}
                        </Typography>
                        <Typography>
                          {` ${orderData.DireccionRecoleccion[0].alm_direccion}`}
                        </Typography>
                        <Typography>
                          {`CP. ${orderData.DireccionRecoleccion[0].alm_codigo_postal}`}
                        </Typography>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={3} className={classes.divData}>
                <Typography className={classes.subTitle}>
                  Método de pago
                </Typography>
                <Typography>
                  {orderData.cf_sap_forma_pago_codigo && (
                    <Fragment>
                      {orderData.cf_sap_forma_pago_codigo == "03" &&
                        "Transferencia bancaria"}
                      {orderData.cf_sap_forma_pago_codigo == "04" &&
                        "Tarjeta de crédito"}
                      {orderData.cf_sap_forma_pago_codigo == "28" &&
                        "Tarjeta de débito"}
                      {orderData.cf_sap_forma_pago_codigo == "99" &&
                        "Mi crédito con Dielsa"}
                    </Fragment>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} className={classes.content}>
          <Fragment>
            <Grid container>
              <Grid item xs={12}>
                <Paper className={clsx(classes.paperTitle)}>
                  <Typography>Detalle de tu compra</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Fragment>
                  <div className={classes.productsList}>
                    {orderData.ProductoCompraFinalizada
                      ? orderData.ProductoCompraFinalizada.map((prod) => {
                          let imgSrc ="http://wws.com.pa/wp-content/plugins/wordpress-ecommerce/marketpress-includes/images/default-product.png";
                          let _txtEntrega =
                            orderData.cf_cmm_tipo_envio_id === 16
                              ? "Envío en "
                              : "Disponible para recolección en ";
                          if (prod.imagenes && prod.imagenes !== null) {
                            imgSrc = prod.imagenes.imgprod_ruta_archivo.includes('https://ftp3.syscom.mx')? prod.imagenes.imgprod_ruta_archivo:`${API_URL}${prod.imagenes.imgprod_ruta_archivo.split("public")[1]}`;
                          }

                          return (
                            <Grid container className={classes.prodRow}>
                              <Grid item xs={2} sm={1}>
                                <Avatar alt="Product" src={imgSrc} />
                              </Grid>
                              <Grid item xs={8} sm={3}>
                                <Typography className={classes.productName}>
                                  {`${prod.prod_nombre} [${prod.prod_nombre_extranjero}]`}
                                </Typography>
                              </Grid>
                              <Grid item xs={2} sm={3}>
                                <Typography className={classes.listPrice}>
                                  {_txtEntrega}
                                  {convertDate(prod.pcf_fecha_entrega)}
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={2}>
                                <Typography className={classes.listPrice}>
                                  {getPriceSapFormat(prod.pcf_precio)}{" "}
                                  {prod.pcf_order_dividida_sap ? "USD" : "MXN"}{" "}
                                  {!props.isMobile && "precio unidad"}
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={2}>
                                <Typography className={classes.prodQty}>
                                  {prod.pcf_cantidad_producto}
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={1}>
                                <Typography className={classes.totalPrice}>
                                  {getPriceSapFormat(
                                    prod.pcf_precio * prod.pcf_cantidad_producto
                                  )}
                                  {prod.pcf_order_dividida_sap
                                    ? " USD"
                                    : " MXN"}{" "}
                                </Typography>
                              </Grid>

                              <Grid item xs={12}>
                                <Divider
                                  style={{
                                    backgroundColor: "#EAEAEA",
                                  }}
                                />
                              </Grid>
                            </Grid>
                          );
                        })
                      : ""}
                  </div>
                </Fragment>
              </Grid>
            </Grid>
            {metodoDePago === "99" && orderData.cf_resume_usd !== undefined && (
              <Grid container style={{ justifyContent: "flex-end" }}>
                <Grid item xs={12} sm={6}>
                  <div className={classes.costData}>
                    <Grid container>
                      <Grid item xs={12}>
                        <span className={classes.divisaTitle}>Divisa USD</span>
                      </Grid>
                      <Grid item xs={6}>
                        Subtotal
                      </Grid>
                      <Grid item xs={6} className={"costResponse"}>
                        {getPriceSapFormat(orderData.cf_resume_usd.precioTotal_usd)}
                      </Grid>
                      <Grid item xs={6}>
                        Descuento
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className={"costResponse"}
                      >{`- ${getPriceSapFormat(
                        orderData.cf_resume_usd.totalDescuentos_usd
                      )}`}</Grid>
                      <Grid item xs={6}>
                        Subtotal aplicado descuento
                      </Grid>
                      <Grid item xs={6} className={"costResponse"}>
                        {getPriceSapFormat(
                          orderData.cf_resume_usd.precioFinalTotal_usd
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        Gastos de envío
                      </Grid>
                      <Grid item xs={6} className={"costResponse"}>
                        {getPriceSapFormat(
                          orderData.cf_resume_usd.cdc_costo_envio_usd
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        IVA
                        {/* {`${props.cartCheckoutData.cart_iva_type}`} */}
                      </Grid>
                      <Grid item xs={6} className={"costResponse"}>
                        {getPriceSapFormat(orderData.cf_resume_usd.TotalImpuesto_usd)}
                      </Grid>

                      <Grid item xs={12}>
                        <Divider style={{ backgroundColor: "#EAEAEA" }} />
                      </Grid>
                      <Grid item xs={6}>
                        <strong>Total</strong>
                      </Grid>
                      <Grid item xs={6} className={"costResponse"}>
                        <strong>
                          {getPriceSapFormat(orderData.cf_resume_usd.TotalFinal_usd)}
                        </strong>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            )}
            <Grid container style={{ justifyContent: "flex-end" }}>
              <Grid item xs={12} sm={6}>
                <div className={classes.costData}>
                  <Grid container>
                    <Grid item xs={12}>
                      <span className={classes.divisaTitle}>Divisa MXN</span>
                    </Grid>
                    <Grid item xs={6}>
                      Subtotal
                    </Grid>
                    <Grid item xs={6} className={"costResponse"}>
                      {orderData.cf_resume_mxn !== undefined
                        ? getPriceSapFormat(orderData.cf_resume_mxn.precioTotal)
                        : getPriceSapFormat(orderData.cf_orden_subtotal)}
                    </Grid>
                    <Grid item xs={6}>
                      Descuento
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className={"costResponse"}
                    >{`- ${getPriceSapFormat(
                      orderData.cf_resume_mxn !== undefined
                        ? orderData.cf_resume_mxn.totalDescuentos
                        : orderData.cf_orden_descuento
                    )}`}</Grid>
                    <Grid item xs={6}>
                      Subtotal aplicado descuento
                    </Grid>
                    <Grid item xs={6} className={"costResponse"}>
                      {getPriceSapFormat(
                        orderData.cf_resume_mxn !== undefined
                          ? orderData.cf_resume_mxn.precioFinalTotal
                          : orderData.cf_orden_subtotal_aplicado
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      Gastos de envío
                    </Grid>
                    <Grid item xs={6} className={"costResponse"}>
                      {getPriceSapFormat(
                        orderData.cf_resume_mxn !== undefined
                          ? orderData.cf_resume_mxn.cdc_costo_envio
                          : orderData.cf_orden_gastos_envio
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      IVA
                      {/* {`${props.cartCheckoutData.cart_iva_type}`} */}
                    </Grid>
                    <Grid item xs={6} className={"costResponse"}>
                      {getPriceSapFormat(
                        orderData.cf_resume_mxn !== undefined
                          ? orderData.cf_resume_mxn.TotalImpuesto
                          : orderData.cf_order_iva
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Divider style={{ backgroundColor: "#EAEAEA" }} />
                    </Grid>
                    <Grid item xs={6}>
                      <strong>Total</strong>
                    </Grid>
                    <Grid item xs={6} className={"costResponse"}>
                      <strong>
                        {getPriceSapFormat(
                          orderData.cf_resume_mxn !== undefined
                            ? orderData.cf_resume_mxn.TotalFinal
                            : orderData.cf_total_compra
                        )}
                      </strong>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Fragment>
        </Grid>
      </Grid>
    </div>
  );
}



const useStyles = makeStyles((theme) => ({
  content: {
    margin: "15px 0",
  },
  paper: {
    backgroundColor: theme.palette.primary.light,
    boxShadow: "none",
    borderRadius: 0,
    padding: "15px",
    fontSize: "15px",
    "& h6": {
      fontFamily: "'Centrale Sans Medium'",
      color: "#000000",
      fontSize: "17px",
    },
  },
  paperTitle: {
    borderRadius: 2,
    backgroundColor: "#0B3196",
    padding: 10,
    "& p": {
      color: "#ffffff",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: 18,
      fontWeight: 500,
      textAlign: "center",
    },
  },
  subTitle: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    fontWeight: 500,
    marginTop: 15,
  },
  divData: {
    paddingRight: 10,
    textAlign: "left",
  },
  profileLink: {
    color: "#0B3196",
    "&:hover, &:focus": {
      color: "#0B3196",
    },
  },
  //style summary data
  costData: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "30px",
    "& .costResponse": {
      textAlign: "end",
    },
    margin: "10px 0",
    padding: 15,
    backgroundColor: "#F5F5F5",
    width: "100%",
  },
  //style product list table
  paper: {
    backgroundColor: "#F8F8F8",
    boxShadow: "none",
    borderRadius: 0,
    padding: "15px",
    fontSize: "15px",
    "& h6": {
      fontFamily: "'Centrale Sans Medium'",
      color: "#000000",
      fontSize: "17px",
    },
    textAlign: "center",
  },
  personalData: {
    backgroundColor: "#0B3196",
    color: "#ffffff",
    "& h6": {
      fontFamily: "'Centrale Sans Medium'",
      color: "#ffffff",
    },
  },
  productsList: {
    maxHeight: 420,
    overflowY: "auto",
    borderRadius: "0 0 2px 2px",
    backgroundColor: "#F8F8F8",
  },
  prodRow: {
    margin: "auto",
    width: "96%",
    marginTop: 10,
    marginBottom: 10,
  },
  productName: {
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: 14,
    textAlign: "justify",
    paddingRight: 15,
    marginBottom: 10,
  },
  prodQty: {
    fontFamily: "'Centrale Sans Light'",
    fontSize: 16,
    textAlign: "center",
  },
  basePrice: {
    textDecoration: "line-through",
    fontFamily: "'Centrale Sans Light'",
    fontSize: 14,
    textAlign: "center",
  },
  listPrice: {
    fontFamily: "'Centrale Sans Light'",
    fontSize: 16,
    textAlign: "center",
  },
  totalPrice: {
    fontFamily: "'Centrale Sans Bold'",
    fontSize: 16,
    textAlign: "center",
  },
  divisaTitle: {
    fontWeight: 600,
  },
}));