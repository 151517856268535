export const addCartLocal = (amount, itemProduct) => {
    return new Promise(resolve => {
    const localCart = JSON.parse(localStorage.getItem("myCart"));
    let currentCart = [];
    currentCart = localCart ? localCart : [];
    if (currentCart.length > 0) {

      const Item = currentCart.find((item) => item.sku === itemProduct.sku);
      if (Item) {
        const itemIndex = currentCart.indexOf(Item);
        currentCart[itemIndex].cantidad = currentCart[itemIndex].cantidad+amount;
        localStorage.setItem("myCart", JSON.stringify(currentCart));
        resolve({type:'success', message:'Se agregó a tu carrito con éxito.', isAmount:true})

      } else {
        currentCart.push({ ...itemProduct, cantidad: amount });
        localStorage.setItem("myCart", JSON.stringify(currentCart));
        resolve({type:'success', message:'Se agregó a tu carrito con éxito.', isAmount:false})
     
      }
    } else {
      currentCart.push({ ...itemProduct, cantidad: amount });
      localStorage.setItem("myCart", JSON.stringify(currentCart));

      resolve({type:'success', message:'Se agregó a tu carrito con éxito.', isAmount:false})
     
    }})
} 