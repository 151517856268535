  import React, {useState,useEffect} from "react";
  import { userService } from "_services/user.service";
  import { API_URL } from "_services/api.url.back";
  
  import { Link, useHistory, useParams, Redirect } from "react-router-dom";
  
  // @material-ui/core components
  import { makeStyles, withStyles } from "@material-ui/core/styles";
  import SectionBreadcrumb from "views/Home/Sections/SectionBreadcrumb.js";
  import ContentCopyIcon from '@mui/icons-material/ContentCopy';
  // @material-ui/
  import Typography from "@material-ui/core/Typography";

  import Collapse from "@material-ui/core/Collapse";
  import Avatar from '@material-ui/core/Avatar';
  import Table from "@material-ui/core/Table";
  import TableBody from "@material-ui/core/TableBody";
  import TableCell from "@material-ui/core/TableCell";
  import TableContainer from "@material-ui/core/TableContainer";
  import TableHead from "@material-ui/core/TableHead";
  import TableRow from "@material-ui/core/TableRow";
  import { getPriceSapFormat } from "helpers/formatPrice";
  import BrokenImageIcon from '@material-ui/icons/BrokenImage';
  import { Button, Grid } from "@mui/material";
  // core components
  import useCheckMobileScreen from "components/Mobile/Mobile.js";

  import { valideProductsBuy } from "utils/valideProductsBuy";
  import { useAuth } from "hooks/useAuth";
  import { addItemCartByIdProduct } from "utils/addCartBusinnesPartner";
  import Loading from "utils/loading/Loading";

  const useStyles = makeStyles((theme) => ({
    hide: {
      display: "none !important",
    },
    //alerts
    alerts: {
      color: "#FFFFFF",
      backgroundColor: "#40DF00",
      fontFamily: "'Centrale Sans Light'",
      fontSize: 22,
      "& .MuiAlert-icon": {
        color: "#FFFFFF",
      },
      "&.MuiAlert-standardError": {
        backgroundColor: "#DF2B2B",
      },
    },
    imageProd:{
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: theme.spacing(7),
        height: theme.spacing(7),
      },
      justifyContent: "center",
    },
    root: {
      backgroundColor: "#FFFFFF",
      margin: "auto",
      width: "95%",
      paddingBottom: 30,
    },
    spaceDiv: {
      marginTop: 15,
      marginBottom: 52,
    },
    linkBack: {
      fontFamily: "'Centrale Sans Medium'",
      color: "#0B3196",
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: "19px",
      textAlign: "center",
      paddingLeft: "30px",
      position: "relative",
    },
    linkBackArrow: {
      position: "absolute",
      left: "5px",
      bottom: "-2px",
    },
    title: {
      fontFamily: "'Centrale Sans Bold'",
      textTransform: "uppercase",
      fontSize: 26,
      lineHeight: "32px",
      textAlign: "justify",
      color: "#0C2D9C",
      paddingTop: "39.8px",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    titleDivider: {
      border: "1px solid #0B3196",
      width: "95%",
      margin: "auto",
    },
    gridContent: {
      paddingBottom: 30,
    },
    buttonUpdate: {
      border: "1px solid",
      color: "#0C2D9C",
      backgroundColor: "transparent",
      textTransform: "none",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: 16,
      "&:hover": {
        backgroundColor: "#3e50c5",
        color: "#ffffff",
      },
    },
    buttonUpdateSecond: {
      border: "1px solid",
      backgroundColor: "#0C2D9C",
      color: "#ffffff",
      textTransform: "none",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: 16,
      "&:hover": {
        backgroundColor: "#3e50c5",
        color: "#ffffff",
      },
    },
    inputs: {
      width: "100%",
      border: "1px solid #EAEAEA",
      borderRadius: "2px",
      backgroundColor: "#FFFFFF",
    },
    // Search
    searchDiv: {
      width: "100%",
      backgroundColor: "#F5F5F5",
      border: "1px solid #EAEAEA",
      borderRadius: "2px",
      marginBottom: "39px",
      paddingTop: "10px",
      paddingBottom: "10px",
      paddingLeft: "6px",
    },
    margin: {
      paddingLeft: "6px",
      paddingRight: "13px",
    },
    inlineDiv: {
      display: "inline-block",
      verticalAlign: "middle",
      [theme.breakpoints.down("sm")]:{
        marginTop: "15px",
      }
    },
    textField: {
      display: "inline-block",
      paddingLeft: "6px",
      paddingRight: "13px",
      [theme.breakpoints.down("sm")]:{
        marginTop: "15px",
      }
    },
    textField2: {
     
      paddingLeft: "6px",
      paddingRight: "13px",
      [theme.breakpoints.down("sm")]:{
        marginTop: "15px",
      }
    },
    btnSearch: {
      height: "44.8px",
      width: "120px",
      borderRadius: "2px",
      backgroundColor: "#40DF00",
      paddingTop: "12px",
      paddingLeft: "35px",
      paddingBottom: "9px",
      paddingRight: "34px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    btnSearchLbl: {
      color: "#FFFFFF",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: "16px",
      fontWeight: "500",
      letterSpacing: "0",
      lineHeight: "19px",
    },
    // Table
    tableHeader: {
      backgroundColor: "#0C2D9C",
      borderRadius: "2px",
      height: "51px",
    },
    tableHeaderTxt: {
      color: "#ffffff",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: "18px",
      lineHeight: "23px",
      fontWeght: "500",
    },
    filterSelect: {
      color: "#0C2D9C",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: "16px",
      fontWeight: "500",
      letterSpacing: "0",
      lineHeight: "19px",
      marginLeft: "6px",
    },
    rowDataProd:{
      color: "#000000",
      fontFamily: "'Centrale Sans Regular'",
      fontSize: "14px",
      letterSpacing: "0",
      lineHeight: "23px",
      width: '11%',
    },
  
    dataCenter:{
      textAlign:"center"
    },
    rowData: {
      color: "#000000",
      fontFamily: "'Centrale Sans Regular'",
      fontSize: "18px",
      letterSpacing: "0",
      lineHeight: "23px",
    },
    iconAction: {
      width: "36px",
      marginRight: "5px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    iconActionImg:{
      width: "36px",
      marginRight: "5px",
      marginBottom: "18px !important",
      "&:hover": {
        cursor: "pointer",
      },
    },
    iconDeleteAction: {
      width: "17px",
      height: "22px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paperModal: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
    },
    modalHeader: {
      width: "540px",
      backgroundColor: "#EF0002",
      textAlign: "center",
      paddingTop: "24.8px",
      paddingBottom: "16px",
    },
    modalHeaderTxt: {
      color: "#FFFFFF",
      fontFamily: "'Centrale Sans Bold'",
      fontSize: "26px",
      fontWeight: "bold",
      letterSpacing: "0",
      lineHeight: "32px",
    },
    modalBody: {
      marginTop: "53.2px",
      marginBottom: "78px",
    },
    modalBodyTxt: {
      color: "#DF2B2B",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: "24px",
      fontWeight: "500",
      letterSpacing: "0",
      lineHeight: "29px",
      textAlign: "center",
    },
    modalBtns: {
      marginLeft: "65px",
      marginBottom: "22px",
    },
    modalBtn: {
      boxSizing: "border-box",
      height: "28px",
      width: "204px",
      border: "1px solid #0C2D9C",
      borderRadius: "2px",
      textAlign: "center",
      color: "#0C2D9C",
      marginRight: "7px",
      display: "inline-block",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#0C2D9C",
        color: "#FFFFFF",
      },
    },
    modalBtnTxt: {
      fontFamily: "'Centrale Sans Regular'",
      fontSize: "14px",
      letterSpacing: "0",
      lineHeight: "18px",
      marginTop: "4px",
    },
    /// Detalles
    titleDiv: {
      display: "block",
    },
    mainDataDiv: {
      backgroundColor: "#F2F2F2",
      paddingTop: "27.6px",
      paddingLeft: "18px",
      paddingRight: "6px",
      paddingBottom: "22px",
    },
    cotDetalleTitle: {
      color: "#0C2D9C",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: "22px",
      fontWeight: "500",
      letterSpacing: "0",
      lineHeight: "27px",
      display: "inline-block",
    },
    iconActionEdit: {
      height: "24px",
      width: "28px",
      float: "right",
      display: "inline-block",
      "&:hover": {
        cursor: "pointer",
      },
    },
    cotDetailsDataDiv: {
      paddingLeft: "2px",
      marginTop: "17px",
      width: "100%",
    },
    cotDetailsDataRow: {
      display: "block",
      marginBottom: "12px",
    },
    cotDetailsDataSmall: {
      display: "inline-block",
      width: "239px",
      verticalAlign: "top",
    },
    cotDetalleDataTitle: {
      color: "#444444",
      fontFamily: "'Centrale Sans Bold'",
      fontSize: "18px",
      fontWeight: "bold",
      letterSpacing: "0",
      lineHeight: "23px",
      display: "inline-block",
    },
    cotDetalleDataTxt: {
      color: "#000000",
      fontFamily: "'Centrale Sans Regular'",
      fontSize: "16px",
      letterSpacing: "0",
      lineHeight: "19px",
      display: "inline-block",
      marginLeft: "110px",
    },
    cotDetallePersonalTitle: {
      color: "#000000",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: "16px",
      fontWeight: "500",
      letterSpacing: "0",
      lineHeight: "16px",
      display: "block",
    },
    cotDetallePersonalData: {
      color: "#000000",
      fontFamily: "'Centrale Sans Regular'",
      fontSize: "16px",
      letterSpacing: "0",
      lineHeight: "20px",
      display: "block",
    },
    divTableProducts: {
      marginTop: "44px",
      marginBottom: "36px",
      backgroundColor: "#F5F5F5",
    },
    divSubtotals: {
      width: "445px",
      border: "1px solid #EAEAEA",
      backgroundColor: "#F5F5F5",
      paddingTop: "12.8px",
      paddingLeft: "18px",
      paddingRight: "24px",
      paddingBottom: "13px",
      float: "right",
      marginBottom: "41px",
      [theme.breakpoints.down("sm")]:{
        width: "325px"
      } 
    },
    divSumatorias: {
      display: "block",
      width: "100%",
      minHeight: "275px",
    },
    divSubtotalesRow: {
      display: "block",
      marginBottom: "17px",
    },
    divLineTotal: {
      height: "1px",
      width: "100%",
      border: "1px solid #EAEAEA",
      marginTop: "-20px",
      marginBottom: "12px",
    },
    subtotalTitle: {
      color: "#000000",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: "16px",
      fontWeight: "500",
      letterSpacing: "0",
      lineHeight: "16px",
      display: "inline-block",
      verticalAlign: "top",
    },
    subtotalData: {
      color: "#000000",
      fontFamily: "'Centrale Sans Medium'",
      fontSize: "16px",
      fontWeight: "500",
      letterSpacing: "0",
      lineHeight: "16px",
      display: "inline-block",
      textAlign: "right",
      float: "right",
      verticalAlign: "top",
    },
    totalTitle: {
      color: "#000000",
      fontFamily: "'Centrale Sans Bold'",
      fontSize: "16px",
      fontWeight: "bold",
      letterSpacing: "0",
      lineHeight: "16px",
      verticalAlign: "top",
      display: "inline-block",
    },
    totalData: {
      color: "#000000",
      fontFamily: "'Centrale Sans Bold'",
      fontSize: "16px",
      fontWeight: "bold",
      letterSpacing: "0",
      lineHeight: "16px",
      verticalAlign: "top",
      display: "inline-block",
      textAlign: "right",
      float: "right",
    },
    btnFinalizarCompra: {
      borderRadius: "3px",
      backgroundColor: "#0C2D9C",
      color: "#FFFFFF",
      float: "right",
      paddingTop: "11.8px",
      paddingBottom: "12px",
      paddingLeft: "60px",
      paddingRight: "54px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    btnFinalizarCompraMob: {
      borderRadius: "3px",
      backgroundColor: "#0C2D9C",
      color: "#FFFFFF",
      //float: "right",
      paddingTop: "11.8px",
      paddingBottom: "12px",
      paddingLeft: "60px",
      paddingRight: "54px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    divBtnFinalizar: {
      float: "right",
      marginBottom: "47px",
      display: "inline-block",
    },
    btnsDiv: {
      display: "block",
      width: "100%",
      marginBottom: "47px",
    },
    btnFinalizarCompraTxt: {
      fontFamily: "'Centrale Sans Medium'",
      fontSize: "16px",
      fontWeight: "500",
      letterSpacing: "0",
      lineHeight: "19px",
      textAlign: "center",
    },
    divBtnRegresar: {
      display: "inline-block",
  
    },
    btnRegresar: {
      borderRadius: "3px",
      backgroundColor: "#FFFFFF",
      color: "#0C2D9C",
      border: "1px solid #0C2D9C",
      paddingTop: "11.8px",
      paddingBottom: "12px",
      paddingLeft: "60px",
      paddingRight: "54px",
      width: "250px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#0C2D9C",
        color: "#FFFFFF",
      },
      [theme.breakpoints.down("sm")]: {
        width: "180px",
      },
    },
  
    btnRegresarMob: {
      borderRadius: "3px",
      backgroundColor: "#FFFFFF",
      color: "#0C2D9C",
      border: "1px solid #0C2D9C",
      paddingTop: "11.8px",
      paddingBottom: "12px",
      paddingLeft: "60px",
      paddingRight: "54px",
      marginTop:"15px",
      //width: "250px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#0C2D9C",
        color: "#FFFFFF",
      },
    },
  
    formData: {
      backgroundColor: "#F5F5F5",
      borderRadius: 2,
      margin: "10px 0",
      border: "1px solid #EAEAEA",
      "& *": {
        margin: "3px 8px 2px 3px",
        padding: 0,
        fontFamily: "'Centrale Sans Regular'",
        border: "none",
      },
    },
  
    formDataSubTitleDes: {
      color: "#0C2D9C",
      textTransform: "uppercase",
    },
    inputs: {
      width: "100%",
      border: "1px solid #EAEAEA",
      borderRadius: "2px",
      backgroundColor: "#FFFFFF",
    },
    dataContainer:{
      marginTop:"15px"
    },
    btnRegistro:{
      marginTop: "15px",
      color: "#FFFFFF",
      with: "100%",
      backgroundColor: "#0C2D9C",
      "& :hover":{
        backgroundColor: "#0C2D9C",
        color: "#FFFFFF",
      },
      "& .MuiButton-root":{
        backgroundColor: "#0C2D9C !important",
        color: "#FFFFFF !important",
        "& :hover":{
          backgroundColor: "#0C2D9C !important",
          color: "#FFFFFF !important",
        }
      } 
    }
  }));

  
  export default function DetailCotizacion(props) {
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams();
    const { getUserData, isLogged, setDataQuote, setOpenAlert } = useAuth();
    const dataUser = getUserData();
    const [dataBreadCrumb, setDataBreadCrumb] = useState([]);
    const [loading, setLoading] = useState(false)
    
  
    let today = new Date();
    let fullDate =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      today.getDate();
    let prevDate = new Date();
    prevDate.setDate(prevDate.getDate() - 30);
    prevDate =
      prevDate.getFullYear() +
      "-" +
      ("0" + (prevDate.getMonth() + 1)).slice(-2) +
      "-" +
      prevDate.getDate();

    const [bool, setBool] = useState(false);
   
  
    const [tituloSeccion, setTituloSeccion] = useState("DETALLE DE LA COTIZACIÓN");
    const [cotDetalle, setCotDetalle] = useState(0);
    const [cotSelected, setCotSelected] = useState([]);
    const [prodCod, setProdCod] = useState([]);
    const [seccionListado, setSeccionListado] = useState(null);
    const [seccionDetalle, setSeccionDetalle] = useState(classes.hide);
    const [buy, setBuy]= useState(false)

    //const [open, setOpen] = React.useState(false);

  
    useEffect(() => {
      setDataBreadCrumb(() => [
        {
          name: "INICIO",
          link: "/",
        },
        {
          name: "MIS COTIZACIONES",
          link: "/myprofile/cotizaciones/",
        },
        {
            name: "DETALLE COTIZACIÓN " + id,
            link: "",
          }
      ]);
      openDetails(id)
    }, []);
  
    let isMobile = useCheckMobileScreen();
  

    const formatDateFull = (date) => {
      let _d = date.split("T");
      let _a = _d[0].split("-");
      let _m = "";
      switch (_a[1]) {
        case "01":
          _m = "Enero";
          break;
        case "02":
          _m = "Febrero";
          break;
        case "03":
          _m = "Marzo";
          break;
        case "04":
          _m = "Abril";
          break;
        case "05":
          _m = "Mayo";
          break;
        case "06":
          _m = "Junio";
          break;
        case "07":
          _m = "Julio";
          break;
        case "08":
          _m = "Agosto";
          break;
        case "09":
          _m = "Septiembre";
          break;
        case "10":
          _m = "Octubre";
          break;
        case "11":
          _m = "Noviembre";
          break;
        case "12":
          _m = "Diciembre";
          break;
      }
      return _a[2] + "/" + _m + "/" + _a[0];
    };

    const openDetails = async (idCot) => {
      setLoading(true)
      const body = {
        socio_negocio_id: isLogged ? dataUser.usuario.snu_sn_socio_de_negocio_id :0
      }
      let _cot = await userService.getCotizacionDetalle(idCot, body);
      setLoading(false)
      setCotDetalle(idCot);
      setCotSelected(_cot.constCotizaciones);
      setProdCod(_cot.constCotizacionesProductos);
      valideProductsBuy(_cot.constCotizacionesProductos).then(({ productsBuy }) => {
       // window.alert(showBuys)
       let notBuy =0;
        for(let i=0; i<productsBuy.length; i++){
          if(productsBuy[i].purchase){
          // isActive= true
          }else{
           notBuy = notBuy +1;
          }
          console.log(productsBuy[i].purchase)
      }
     setBuy(notBuy>0? false: true)
       // setBuy(showBuys)

      })
      setTituloSeccion("DETALLE DE LA COTIZACIÓN");
      setSeccionListado(classes.hide);
      setSeccionDetalle(null);
      // setSeccionEditar(classes.hide);
      setBool(!bool);
    };
 

    const addProductsCart = async ()=>{
     // setLoading(true)
      //let flag= false
      setDataQuote({
        idCotizacion:id,
        idSocioNegocio:isLogged ? dataUser.usuario.snu_sn_socio_de_negocio_id :0,
        productos:prodCod
      })
     await prodCod.map( async (item)=>{
        
          await addItemCartByIdProduct( (isLogged ? dataUser.usuario.snu_sn_socio_de_negocio_id : 0),
            item.cotp_prod_producto_id, item.cotp_producto_cantidad)
            .then(() => {
             
                history.push('/checkout')
              
            });
            
      })
    
    }
  
    
    const handleDuplicateQuote = async () => {
      const respond = await userService.createDuplicateQuote(
        id,
        JSON.parse(localStorage.getItem("client")).listaSnbyid[0]
          .sn_socios_negocio_id
      );
      if(!respond.error){
        setOpenAlert({
          message:
            "Cotización duplicada correctamente",
          type: "success",
          open: true,
        });
        setTimeout(() => {
          setOpenAlert({
            message:
              "Edita tu cotización",
            type: "info",
            open: true,
          });
        }, 3000);
        setTimeout(() => {
          history.push(`/myprofile/cotizaciones/editar/${respond.data}`);
        }, 2500);
      } else {
        setOpenAlert({
          message:
            "Hubo un problema, no se ha podido duplicar la cotización",
          type: "error",
          open: true,
        });
      }
    }
 
    return (
      <div className={classes.root}>
        <Collapse in={alert.open}>
         
        </Collapse>
  
        <div className={classes.spaceDiv}>
        <Loading loading={loading} />
          <SectionBreadcrumb route={dataBreadCrumb} />
          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
            }}
          >
            <Grid item xs={12} sm={8}>
              <Typography className={classes.title}>{tituloSeccion}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                variant="outlined"
                startIcon={<ContentCopyIcon />}
                sx={{
                  backgroundColor: '#0C2D9C',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#1c43c6',
                    color: '#fff',
                  }
                }}
                onClick={handleDuplicateQuote}
              >
                Duplicar cotización
              </Button>
            </Grid>
          </Grid>
        </div> 
  
        <div className={seccionDetalle}>
          <div className={classes.mainDataDiv}>
            <div className={classes.titleDiv}>
              {/* <img src={imgEdit} className={classes.iconActionEdit} /> */}
              {/* <div>
                <ContentCopyIcon />
              </div> */}
              <Typography className={classes.cotDetalleTitle}>
                Cotización {cotDetalle}
              </Typography>
            </div>
            {cotSelected.cot_sn_socios_negocio_id ? (
              <div className={classes.cotDetailsDataDiv}>
                <div className={classes.cotDetailsDataRow}>
                  <Typography className={classes.cotDetalleDataTitle}>
                    Fecha de elab.
                  </Typography>
                  <Typography className={classes.cotDetalleDataTxt}>
                    {formatDateFull(cotSelected.createdAt)}
                  </Typography>
                </div>
                <div className={classes.cotDetailsDataRow}>
                  <Typography className={classes.cotDetalleDataTitle}>
                    Fecha de venc.
                  </Typography>
                  <Typography className={classes.cotDetalleDataTxt}>
                    {formatDateFull(cotSelected.cot_fecha_vencimiento)}
                  </Typography>
                </div>
                <div className={classes.cotDetailsDataSmall}>
                  <Typography className={classes.cotDetallePersonalTitle}>
                    Datos personales
                  </Typography>
                  <Typography className={classes.cotDetallePersonalData}>
                    {cotSelected.InformacionCliente.sn_cardcode}
                  </Typography>
                  <Typography className={classes.cotDetallePersonalData}>
                    {cotSelected.InformacionCliente.sn_nombre_empresa}
                  </Typography>
                </div>
                <div className={classes.cotDetailsDataSmall}>
                  <Typography className={classes.cotDetallePersonalTitle}>
                    Dirección de entrega
                  </Typography>
                  <Typography
                    className={classes.cotDetallePersonalData}>
                      {cotSelected.InformacionCliente.sn_direccion_facturacion}
                  </Typography>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {isMobile ? (
             <Grid item xs={12} className={classes.gridContent}>
            
             {prodCod.map((row) => {
               return (
                 <div className={classes.formData}>
                   <Grid container spacing={0}>
                     <Grid item xs={12}>
                       <Grid container>
                       <Grid item xs={6}>
                           <Typography className={classes.formDataSubTitleDes}>
                             Imagen:
                           </Typography>
                         </Grid>
                         <Grid item xs={5}>
                         <div className={classes.imageProd}>
                           {row.imagenes[0]?.imgprod_ruta_archivo ?  (
                          <Avatar variant="square" src={row.imagenes[0]?.imgprod_ruta_archivo.includes('https://ftp3.syscom.mx')? row.imagenes[0]?.imgprod_ruta_archivo:  `${API_URL.split('api')[0]}${row.imagenes[0]?.imgprod_ruta_archivo.split('public')[1]}`}></Avatar>
                   ): (<Avatar variant="square" src=""> <BrokenImageIcon /></Avatar>
                   )}
                          </div>
                         </Grid>
                       </Grid>
                     </Grid>
                     <Grid item xs={12}>
                       <Grid container>
                         <Grid item xs={6}>
                           <Typography className={classes.formDataSubTitleDes}>
                             Descripción:
                           </Typography>
                         </Grid>
                         <Grid item xs={5}>
                           <Typography>{row.prod_nombre}</Typography>
                         </Grid>
                       </Grid>
                     </Grid>
                     <Grid item xs={12}>
                       <Grid container>
                         <Grid item xs={6}>
                           <Typography className={classes.formDataSubTitleDes}>
                             No. de parte:
                           </Typography>
                         </Grid>
                         <Grid item xs={5}>
                           <Typography> {row.prod_nombre_extranjero}</Typography>
                         </Grid>
                       </Grid>
                     </Grid>
                    
                     <Grid item xs={12}>
                       <Grid container>
                         <Grid item xs={6}>
                           <Typography className={classes.formDataSubTitleDes}>
                             Cantidad:
                           </Typography>
                         </Grid>
                         <Grid item xs={5}>
                           <Typography>{row.cotp_producto_cantidad}</Typography>
                         </Grid>
                       </Grid>
                     </Grid>
                     <Grid item xs={12}>
                       <Grid container>
                         <Grid item xs={6}>
                           <Typography className={classes.formDataSubTitleDes}>
                             Precio :
                           </Typography>
                         </Grid>
                         <Grid item xs={5}>
                         {row.cotp_precio_menos_promociones ? (
                           <div>
                             <div>
                               <typography>
                                 Precio de Lista <span style={{textDecoration:"line-through"}}>{getPriceSapFormat(row.cotp_precio_base_lista)}</span>
                               </typography>
                             </div>
                             <div>
                               <typography>
                                Precio Cotizado <span style={{fontWeight:"bold"}}>{getPriceSapFormat(row.cotp_precio_menos_promociones)}</span>
                               </typography>
                             </div>
                              
                           </div>
                           ): (
                             <typography>
                               `Precio de Lista ${getPriceSapFormat(row.cotp_precio_base_lista)}`
                             </typography>
                           )}
                         </Grid>
                       </Grid>
                     </Grid>
                     <Grid item xs={12}>
                       <Grid container>
                         <Grid item xs={6}>
                           <Typography className={classes.formDataSubTitleDes}>
                             Importe:
                           </Typography>
                         </Grid>
                         <Grid item xs={5}>
                           <Typography> {getPriceSapFormat(
                           row.cotp_precio_base_lista * row.cotp_producto_cantidad
                         )}</Typography>
                         </Grid>
                       </Grid>
                     </Grid>
                     <Grid item xs={12}>
                       <Grid container>
                         <Grid item xs={6}>
                           <Typography className={classes.formDataSubTitleDes}>
                             Comprado:
                           </Typography>
                         </Grid>
                         <Grid item xs={5}>
                           <Typography> {
                           (row.cotp_flag_compra != null ? 'SI': 'No')
                         }</Typography>
                         </Grid>
                       </Grid>
                     </Grid>
                   </Grid>
                 </div>
               );
             })}
             </Grid>
  
          ):(
             <div className={classes.divTableProducts}>
             <TableContainer>
               <Table className={classes.table} aria-label="simple table">
                 <TableHead>
                   <TableRow className={classes.tableHeader}>
                     <TableCell className={classes.tableHeaderTxt}></TableCell>
                     <TableCell  className={classes.tableHeaderTxt}>
                       Descripción
                     </TableCell>
                     <TableCell style={{textAlign:"center"}} className={classes.tableHeaderTxt}>
                       No. Parte
                     </TableCell>
                    
                     <TableCell style={{textAlign:"center"}} className={classes.tableHeaderTxt}>
                       Cant.
                     </TableCell>
                     <TableCell style={{textAlign:"center"}} className={classes.tableHeaderTxt}>
                       Precio 
                     </TableCell>
                     <TableCell style={{textAlign:"center"}} className={classes.tableHeaderTxt}>
                       Importe
                     </TableCell>
                     <TableCell style={{textAlign:"center"}} className={classes.tableHeaderTxt}>
                       Comprado
                     </TableCell>
                   </TableRow>
                 </TableHead>
                 <TableBody>
                   {prodCod.map((row) => (
                     <TableRow key={row.numCot}>
                       <TableCell style={{textAlign:"center"}} className={classes.rowDataProd}>
                         <div className={classes.imageProd}>
                           {row.imagenes[0]?.imgprod_ruta_archivo ?  (
                          <Avatar variant="square" src={row.imagenes[0]?.imgprod_ruta_archivo.includes('https://ftp3.syscom.mx')? row.imagenes[0]?.imgprod_ruta_archivo: `${API_URL.split('api')[0]}${row.imagenes[0]?.imgprod_ruta_archivo.split('public')[1]}`}></Avatar>
                   ): (<Avatar variant="square" src=""> <BrokenImageIcon /></Avatar>
                   )}
                          </div>
                       </TableCell>
                       <TableCell  className={classes.rowDataProd}>
                         {row.prod_nombre}
                       </TableCell>
                       <TableCell style={{textAlign:"center"}} className={classes.rowDataProd}>
                         {row.prod_nombre_extranjero}
                       </TableCell>
                     
                       <TableCell style={{textAlign:"center"}} className={classes.rowDataProd}>
                         {row.cotp_producto_cantidad}
                       </TableCell>
                       <TableCell style={{textAlign:"center"}} className={classes.rowDataProd}>
                         
                         {row.cotp_precio_menos_promociones ? (
                           <div>
                             <div>
                               <typography>
                                 Precio de Lista <span style={{textDecoration:"line-through"}}>{getPriceSapFormat(row.cotp_precio_base_lista)}</span>
                               </typography>
                             </div>
                             <div>
                               <typography>
                                Precio Cotizado <span style={{fontWeight:"bold"}}>{getPriceSapFormat(row.cotp_precio_menos_promociones)}</span>
                               </typography>
                             </div>
                              
                           </div>
                           ): (
                             <typography>
                               `Precio de Lista ${getPriceSapFormat(row.cotp_precio_base_lista)}`
                             </typography>
                           )}
                       </TableCell>
                       <TableCell style={{textAlign:"center"}} className={classes.rowDataProd}>
                         {getPriceSapFormat(
                           row.cotp_precio_base_lista * row.cotp_producto_cantidad
                         )}
                       </TableCell>
                       <TableCell style={{textAlign:"center"}} className={classes.rowDataProd}>
                         {    (row.cotp_flag_compra != null ? 'SI': 'No')}
                       </TableCell>
                     </TableRow>
                   ))}
                 </TableBody>
               </Table>
             </TableContainer>
           </div>
          )}
         
          <div className={classes.divSumatorias}>
            <div className={classes.divSubtotals}>
              <div className={classes.divSubtotalesRow}>
                <Typography className={classes.subtotalTitle}>
                  Subtotal
                </Typography>
                <Typography className={classes.subtotalData}>
                  {getPriceSapFormat(cotSelected && cotSelected.cot_total_base)}
                </Typography>
              </div>
              <div className={classes.divSubtotalesRow}>
                <Typography className={classes.subtotalTitle}>
                  Gastos de envío
                </Typography>
                <Typography className={classes.subtotalData}>
                  {getPriceSapFormat(cotSelected && cotSelected.cot_costo_envio)}
                </Typography>
              </div>
              <div className={classes.divSubtotalesRow}>
                <Typography className={classes.subtotalTitle}>
                  Descuento
                </Typography>
                <Typography className={classes.subtotalData}>
                  -{getPriceSapFormat(cotSelected && cotSelected.cot_descuento_total)}
                </Typography>
              </div>
              <div className={classes.divSubtotalesRow}>
                <Typography className={classes.subtotalTitle}>IVA 16%</Typography>
                <Typography className={classes.subtotalData}>
                  {getPriceSapFormat(cotSelected && cotSelected.cot_iva_cantidad)}
                </Typography>
              </div>
              <div className={classes.divLineTotal}></div>
              <div className={classes.divSubtotalesRow}>
                <Typography className={classes.totalTitle}>Total</Typography>
                <Typography className={classes.totalData}>
                  {getPriceSapFormat(cotSelected && (cotSelected.cot_total_cotizacion + cotSelected.cot_costo_envio))}
                </Typography>
              </div>
            </div>
          </div>
          {isMobile ? (
                <Grid>
                  <Grid Container>
                     {buy ?
                     <Grid item xs={12} className={classes.btnFinalizarCompraMob}>
                            <Typography
                              className={classes.btnFinalizarCompraTxt}
                              //onClick={agregarProductosACarrito}
                              onClick={()=>{
                                addProductsCart()
                              }}
                            >
                              Finalizar compra
                            </Typography>
                      </Grid>

                      :
                      <Grid item xs={12} className={classes.btnFinalizarCompraMob}>
                            <Typography
                              className={classes.btnFinalizarCompraTxt}
                              //onClick={agregarProductosACarrito}
                              onClick={()=>{
                                //window.location.href='/checkoutQuotes'
                              }}
                            >
                              Contactar al vendedor
                            </Typography>
                      </Grid>}
                      

                  </Grid>
                  <Grid Container>
                      <Link to='/myprofile/cotizaciones'><Grid item sx={12} className={classes.btnRegresarMob} >
                            <Typography
                              className={classes.btnFinalizarCompraTxt}
                              onClick={() => {
                                //closeDetails();
                              }}
                            >
                              Regresar
                            </Typography>
                      </Grid></Link>
                  </Grid>
                </Grid>
              ):(
                <div className={classes.btnsDiv}>
          
          <Link to='/myprofile/cotizaciones'><div className={classes.divBtnRegresar} >
                  <div className={classes.btnRegresar} >
                    <Typography
                      className={classes.btnFinalizarCompraTxt}
                      onClick={() => {
                        //closeDetails();
                      }}
                    >
                      Regresar
                    </Typography>
                  </div>
                </div></Link>
                <div className={classes.divBtnFinalizar}>
                  {buy?
                    
                    <div className={classes.btnFinalizarCompra}>
                    <Typography
                      className={classes.btnFinalizarCompraTxt}
                      //onClick={agregarProductosACarrito}
                      onClick={()=>{
                        addProductsCart()
                      }}
                    >
                      Finalizar compra
                    </Typography>
                  </div>
                  :
                  <div className={classes.btnFinalizarCompra}>
                    <Typography
                      className={classes.btnFinalizarCompraTxt}
                      //onClick={agregarProductosACarrito}
                      onClick={()=>{
                       // window.location.href='/checkoutQuotes'
                      }}
                    >
                      Contactar al vendedor
                    </Typography>
                  </div>
                  
                  }
                </div>
              </div>
              )}
        </div>
        
      </div>
    );
  }
  