/*eslint-disable*/
import React, { useState, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// material-ui core components
import { makeStyles } from "@material-ui/core/styles";

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((styles) => ({
    container: {
        backgroundColor: "#40df00",
        height: "24px",
    },
    divBanner: {
        width: "100%",
        textAlign: "center",
        paddingBottom: 4,
        paddingTop: 4,
    },
    textIcon: {
        color: "#0b3196",
        display: "block",
        fontSize: "14px",
        fontFamily: "'Centrale Sans Medium'",
        lineHeight: "1.3",
    }
}));

export default function HeaderBanner(props) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
                
            <div className={classes.divBanner}>
                <Typography variant="caption" className={classes.textIcon}>
                    {props.dataBanner}
                </Typography>
            </div>

        </div>
    );
}